import { UsersActionType } from "src/actions/user/types";

interface UsersElement{
    id: number;
    firstName: string;
    lastName: string;
    roles: string;
    category: string;
    action: any;
}

interface UsersState{
    listUsers: any[];
}

const initialState: UsersState = {
    listUsers: [],
}


const userListReducer = (userState: UsersState = initialState, action: any) => {

    const { type, payload } = action;

    switch (type) {
        case UsersActionType.LIST_USERS:
            let users: any[] = [];
            payload.map((element: any) => {
                users.push({
                    id: element.id,
                    firstName: element.firstName,
                    lastName: element.lastName,
                    birthDate: element.birthDate,
                    roles: element.roles,
                    sex: element.sex,
                    category: element.category,
                    email: element.email,
                    password: element.password
                });
                return users;
            });

            return {
                ...userState,
                listUsers:  users,
            }
        default:
            return userState;
    }
}

export { userListReducer };