import { useQuery } from '@tanstack/react-query';
import { PatientService } from 'src/services/Patients';
import { sortByIds } from 'src/utils/utils';

function useConclusionRQHooks({ patientId }: { patientId: number }) {
    return useQuery({
        queryKey: [`conclusions-${patientId}`],
        queryFn: async () => await PatientService.getConclusions({ patientId }),
        onSuccess: () => {},
        onError: () => {},
        select: (response) => {
            const conslusionsItems = response?.data?.data ?? [];
            return sortByIds(conslusionsItems);
        },
    });
}

function useDraftConclusionRQHooks({ patientId }: { patientId: number }) {
    return useQuery({
        queryKey: [`conclusions-draft-${patientId}`],
        queryFn: async () => await PatientService.getConclusions({ patientId, isDraft: 1 }),
        onSuccess: () => {},
        onError: () => {},
        select: (response) => {
            const draftItems = response?.data?.data;
            const sortedItems = sortByIds(draftItems);
            const draftConclusion = sortedItems?.[0];
            return draftConclusion;
        },
    });
}

export { useConclusionRQHooks, useDraftConclusionRQHooks };
