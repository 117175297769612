import { makeStyles } from '@mui/styles';
import { baseBorderRadius, baseColors, baseFontFamily, baseScreenHeight } from 'src/utils/constants/baseStyles';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        minWidth: 660,
        backgroundColor: baseColors.white,
        ...baseScreenHeight.defaultHeight,
        ...baseBorderRadius.leftOnly,
    },
    wrapper: { padding: 20, justifyContent: 'center', overflowY: 'auto' },
});

export const sxStyles: any = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        paddingX: '25px',
    },
    loading: {
        marginTop: 10,
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        display: 'flex',
        flexDirection: 'column',
    },
    loadingLabel: {
        color: baseColors.green.primary,
        fontSize: '10pt',
        marginTop: 5,
        fontFamily: baseFontFamily.OpenSansRegular,
    },
    title: { color: baseColors.green.primary, fontSize: '1.2em', marginTop: 2, textAlign: 'center' },
};

export default useStyles;
