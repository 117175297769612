import { Dispatch, SetStateAction } from 'react';
import {
    DraftConclusion,
    OptimizedItem,
    PreselectedPatientProgram,
    ProgramContent,
    ProgramGoal,
    ProgramSkill,
} from 'src/interfaces/interfaces';
import { ConclusionPayloadType } from 'src/interfaces/types';
import { uniqByKeyValues } from 'src/utils/utils';
import { CheckBoxItemData } from '../SkillsFormContainer/CheckBoxSkillItem/CheckBoxSkillItem';

const UserProgram = ({
    preSelectedPatientProgram,
    allPrograms,
}: {
    allPrograms: ProgramContent[];
    preSelectedPatientProgram: PreselectedPatientProgram;
}) => {
    const program = allPrograms?.find((p) => p.id === preSelectedPatientProgram?.id);
    return program;
};

const ProgramGoals = ({ payload, skills }: { payload: ConclusionPayloadType; skills: ProgramSkill[] }) => {
    const selectedSkills = payload?.skills?.map((skill) => skill.id);
    const goals = skills?.filter((skill) => selectedSkills?.includes(skill.id));
    const allGoals = goals?.flatMap((goal) => goal?.goals);
    const items = allGoals ?? [];
    return items;
};

const GoalWorkshopsArticles = ({ patientGoals, isArticles }: { patientGoals: ProgramGoal[]; isArticles?: boolean }) => {
    const allWorkshops = patientGoals?.flatMap((goal) => goal?.workshops);
    const allArticles = patientGoals?.flatMap((goal) => goal?.articles);
    return isArticles ? allArticles : allWorkshops ?? [];
};

const handleClickSkill = ({
    setPayload,
    value,
    skills,
    skillData,
}: {
    setPayload: Dispatch<SetStateAction<ConclusionPayloadType>>;
    value: number;
    skills: ProgramSkill[];
    skillData: CheckBoxItemData;
}) => {
    setPayload((prev: ConclusionPayloadType) => {
        // const newSkillGoals = skills?.find((skill) => skill.id === value);
        const newSkillGoalIds = skillData?.goals?.map((goal) => goal.id);
        const newSkill = { id: value, goalIds: newSkillGoalIds ?? [] };
        const newSkillWorkshopIds =
            skillData?.goals?.flatMap((goal: any) => goal?.workshops?.map((item: any) => item.id)) ?? [];
        const newSkillArticleIds =
            skillData?.goals?.flatMap((goal: any) => goal?.articles?.map((item: any) => item.id)) ?? [];
        const prevWrkshops = prev?.conclusionWorkshops ?? [];
        const prevArticles = prev?.conclusionVideos ?? [];

        const conclusionWorkshops = Array.from(new Set([...prevWrkshops, ...newSkillWorkshopIds]));
        const conclusionVideos = Array.from(new Set([...prevArticles, ...newSkillArticleIds]));

        if (prev?.skills?.length > 0) {
            const prevSkills = prev.skills;
            const skill = prevSkills?.find((skill) => skill.id === value);
            if (skill?.id! > 0) {
                const filteredSkills = prevSkills?.filter((skillItem) => skillItem.id !== skill?.id);
                return { ...prev, skills: filteredSkills };
            } else {
                return {
                    ...prev,
                    skills: [...prevSkills, newSkill],
                    conclusionWorkshops,
                    conclusionVideos,
                };
            }
        } else {
            const newPayload = {
                ...prev,
                skills: [newSkill],
                conclusionWorkshops,
                conclusionVideos,
            };
            return newPayload;
        }
    });
};

const handleClickGoal =
    ({ setPayload, value }: { setPayload: Dispatch<SetStateAction<ConclusionPayloadType>>; value: number }) =>
    () => {
        setPayload((prev: ConclusionPayloadType) => {
            const skillIds = prev?.skills?.map((skill) => skill.id);
            const prevSkillIds = new Set(skillIds);
            if (prevSkillIds?.has(value)) {
                prevSkillIds.delete(value);
                return { ...prev, knowledgeIds: Array.from(prevSkillIds) };
            } else {
                prevSkillIds.add(value);
                return { ...prev, knowledgeIds: Array.from(prevSkillIds) };
            }
        });
    };

function goalIds(goals: ProgramGoal[]) {
    return goals?.map((goal) => goal.id) ?? [];
}

const SetDefaultSkillsPayload = ({
    setPayload,
    skills,
}: {
    setPayload: Dispatch<SetStateAction<ConclusionPayloadType>>;
    skills?: ProgramSkill[];
}) => {
    setPayload((prev: ConclusionPayloadType) => {
        const skillItems = skills ?? [];
        // const skillItems = draftConclusion?.id > 0 ? draftConclusion?.conclusionSkills : skills ?? [];
        const skillIds = skillItems?.map((skill) => ({ id: skill.id, goalIds: goalIds(skill.goals) }));
        return { ...prev, skills: skillIds };
    });
};

const SetDefaultUserGoalsPayload = ({
    setExistedGoalIds,
    payload,
}: {
    setExistedGoalIds: Dispatch<SetStateAction<number[]>>;
    payload: ConclusionPayloadType;
}) => {
    const defaultSkillGoals = payload?.skills?.map((skill) => skill.goalIds);
    const filteredGoalIds = new Set(defaultSkillGoals?.flatMap((goalId) => goalId));
    const goalIds = Array.from(filteredGoalIds);

    setExistedGoalIds(goalIds ?? []);
};

function SetToUniqArray(array: Array<number>) {
    const values = new Set(array);
    return Array.from(values);
}

function GoalContent({
    skills,
    selectedGoalId,
    isWorkshop,
}: {
    skills?: ProgramSkill[];
    selectedGoalId: number;
    isWorkshop?: boolean;
}) {
    const goals = skills?.flatMap((skill) => skill?.goals);
    const goalItem = goals?.find((goal) => goal.id === selectedGoalId);
    const goalWorskhopIds = goalItem?.workshops?.map((workshop) => workshop?.id);
    const goalArticleIds = goalItem?.articles?.map((article) => article?.id);

    if (isWorkshop) {
        return goalWorskhopIds ?? [];
    }
    return goalArticleIds ?? [];
}

const handleClickGoalSkill = ({
    setPayload,
    value,
    goals,
    skills,
    goalData,
}: {
    setPayload: Dispatch<SetStateAction<ConclusionPayloadType>>;
    value: number;
    goals?: ProgramGoal[];
    skills?: ProgramSkill[];
    goalData: ProgramGoal;
}) => {
    const goalMainWorkshopIds = GoalContent({ skills, selectedGoalId: value, isWorkshop: true });
    const goalMainArticleIds = GoalContent({ skills, selectedGoalId: value });

    setPayload((prev: ConclusionPayloadType) => {
        const prevGoalSkill = prev?.skills?.find((skill) => skill?.goalIds?.includes(value));
        const goalWorkshopIds =
            goalData?.workshops?.map((workshop) => workshop?.id) ??
            GoalContent({ skills, selectedGoalId: value, isWorkshop: true }) ??
            [];
        const goalArticleIds =
            goalData?.articles?.map((article) => article?.id) ?? GoalContent({ skills, selectedGoalId: value }) ?? [];

        if (prevGoalSkill?.id) {
            const filteredSkill = prev?.skills?.filter((skill) => skill?.id !== prevGoalSkill?.id);
            const newGoalIds = prevGoalSkill?.goalIds?.filter((goalId) => goalId !== value);

            const newGoalWorkshops = prev?.conclusionWorkshops?.filter(
                (workshpId) => !goalMainWorkshopIds?.includes(workshpId),
            );

            const newGoalArticles = prev?.conclusionVideos?.filter((article) => !goalMainArticleIds?.includes(article));

            const newSkill = {
                id: prevGoalSkill?.id,
                goalIds: newGoalIds ?? [],
            };

            return {
                ...prev,
                skills: [...filteredSkill, newSkill],
                conclusionVideos: newGoalArticles ?? [],
                conclusionWorkshops: newGoalWorkshops ?? [],
            };
        } else {
            const skillValue = skills?.find((skill) => skill?.goals?.find((goal) => goal?.id === value));
            const prevGoalSkill = prev?.skills?.find((skill) => skill?.id === skillValue?.id);
            const goalSkillIds = prevGoalSkill?.goalIds ?? [];

            const filteredSkill = prev?.skills?.filter((skill) => skill?.id !== skillValue?.id);

            const prevConclusionWorkshops = prev?.conclusionWorkshops ?? [];
            const prevConclusionVideos = prev?.conclusionVideos ?? [];

            const newGoalWorkshops = SetToUniqArray([...prevConclusionWorkshops, ...goalWorkshopIds]);
            const newGoalArticles = SetToUniqArray([...prevConclusionVideos, ...goalArticleIds]);

            const newSkill = {
                id: skillValue?.id ?? 0,
                goalIds: [...goalSkillIds, value],
            };

            return {
                ...prev,
                skills: [...filteredSkill, newSkill],
                conclusionVideos: newGoalArticles ?? [],
                conclusionWorkshops: newGoalWorkshops ?? [],
            };
        }
    });
};

const SetDefaultUserWorkshopPayload = ({
    setExistedWorkshopIds,
    skills,
    setPayload,
    draftConclusion,
}: {
    setExistedWorkshopIds: Dispatch<SetStateAction<number[]>>;
    skills: ProgramSkill[];
    setPayload: Dispatch<SetStateAction<ConclusionPayloadType>>;
    draftConclusion: DraftConclusion;
}) => {
    const defaultWorkshopIds = skills?.map((skill) =>
        skill.goals?.map((goal) => goal.workshops?.map((workshop) => workshop.id)),
    );
    const ids = new Set(defaultWorkshopIds?.flat(2));
    const workshopIds = Array.from(ids) ?? [];

    // Workshop From Draft
    const workshopFromDraft =
        draftConclusion?.conclusionWorkshops?.map((workshopItem: any) => workshopItem?.workshop) ?? [];
    const workshopUniquedValues = uniqByKeyValues({ items: workshopFromDraft, key: 'id' });
    const workshopFromDraftIds = workshopUniquedValues?.map((workshopItem: any) => workshopItem?.id) ?? [];

    // Workshop Ids to use
    const workshopIdsToUse = draftConclusion?.id ? workshopFromDraftIds : workshopIds;

    // const optimizedGolsFromDraftConclusion = draftConclusion?.conclusionWorkshops?.map((item: any) => ({
    //     workshopId: item?.workshop?.id,
    //     goalIds: item?.workshop?.goals?.map((goal: any) => goal.id),
    // }));

    // const goalWorkshops = optimizedGolsFromDraftConclusion?.filter((item: any) => item?.goalIds.includes(goal));

    setExistedWorkshopIds(workshopIdsToUse ?? []);
    setPayload((prev) => ({ ...prev, conclusionWorkshops: workshopIdsToUse }));
};

const SetDefaultUserArticlePayload = ({
    setExistedArticleIds,
    skills,
    setPayload,
    draftConclusion,
}: {
    setExistedArticleIds: Dispatch<SetStateAction<number[]>>;
    skills: ProgramSkill[];
    setPayload: Dispatch<SetStateAction<ConclusionPayloadType>>;
    draftConclusion: DraftConclusion;
}) => {
    const defaultArticleIds = skills?.map((skill) =>
        skill.goals?.map((goal) => goal.articles?.map((article) => article.id)),
    );
    const ids = new Set(defaultArticleIds?.flat(2));
    const articleIds = Array.from(ids);

    // Articles From Draft
    const articleFromDraft = draftConclusion?.conclusionVideos?.map((articleItem: any) => articleItem?.article) ?? [];
    const articleUniquedValues = uniqByKeyValues({ items: articleFromDraft, key: 'id' });
    const articleFromDraftIds = articleUniquedValues?.map((articleItem: any) => articleItem?.id) ?? [];

    // Article Ids to use
    const workshopIdsToUse = draftConclusion?.id ? articleFromDraftIds : articleIds;

    setExistedArticleIds(workshopIdsToUse ?? []);
    setPayload((prev) => ({ ...prev, conclusionVideos: workshopIdsToUse }));
};

function SetSelectedArticleGoals({
    skills,
    articleGoalIds,
    prevSkills,
}: {
    skills: ProgramSkill[];
    articleGoalIds: number[];
    prevSkills: any[];
}) {
    const articleSkill = skills?.find((skill) => {
        const skillGoalIds = skill?.goals?.map((goal) => goal.id);
        const skillRes = articleGoalIds?.map((articleGoalId: number) => skillGoalIds?.includes(articleGoalId));
        if (skillRes?.some((status: boolean) => status)) {
            return skill;
        }
        return null;
    });

    const newSkillGoals = prevSkills?.map((skill) => {
        const newGoalIds = skill.id === articleSkill?.id ? articleGoalIds : [];
        const goalIds = Array.from(new Set([...skill.goalIds, ...newGoalIds]));

        return {
            ...skill,
            goalIds: goalIds,
        };
    });

    return newSkillGoals ?? [];
}

const handleClickArticleSkill = ({
    setPayload,
    value,
    articles,
    skills,
}: {
    setPayload: Dispatch<SetStateAction<ConclusionPayloadType>>;
    value: number;
    skills: ProgramSkill[];
    articles: any[];
}) => {
    const articleData = articles?.find((article) => article.id === value);
    const articleGoalIds = articleData?.goals?.map((goal: any) => goal?.id);

    setPayload((prev: ConclusionPayloadType) => {
        const articleIds = prev?.conclusionVideos?.map((article) => article);

        const newSkillGoals = SetSelectedArticleGoals({
            skills,
            articleGoalIds,
            prevSkills: prev.skills,
        });

        const prevArticleIds = new Set(articleIds);

        if (prevArticleIds?.has(value)) {
            prevArticleIds.delete(value);
            return { ...prev, conclusionVideos: Array.from(prevArticleIds) };
        } else {
            prevArticleIds.add(value);
            return { ...prev, conclusionVideos: Array.from(prevArticleIds), skills: newSkillGoals };
        }
    });
};

function SetSelectedWorkshopGoals({
    skills,
    workshopGoalIds,
    prevSkills,
}: {
    skills: ProgramSkill[];
    workshopGoalIds: number[];
    prevSkills: any[];
}) {
    const workshopSkill = skills?.find((skill) => {
        const skillGoalIds = skill?.goals?.map((goal) => goal.id);
        const skillRes = workshopGoalIds?.map((wkshopGoalId: number) => skillGoalIds?.includes(wkshopGoalId));
        if (skillRes?.some((status: boolean) => status)) {
            return skill;
        }
        return null;
    });

    const newSkillGoals = prevSkills?.map((skill) => {
        const newGoalIds = skill.id === workshopSkill?.id ? workshopGoalIds : [];
        const goalIds = Array.from(new Set([...skill.goalIds, ...newGoalIds]));

        return {
            ...skill,
            goalIds: goalIds,
        };
    });

    return newSkillGoals ?? [];
}

const handleClickWorkshopSkill = ({
    setPayload,
    value,
    skills,
    workshops,
}: {
    setPayload: Dispatch<SetStateAction<ConclusionPayloadType>>;
    value: number;
    skills: ProgramSkill[];
    workshops: any[];
}) => {
    const workshopData = workshops?.find((workshop) => workshop.id === value);
    const workshopGoalIds = workshopData?.goals?.map((goal: any) => goal?.id);

    setPayload((prev: ConclusionPayloadType) => {
        const workshopIds = prev?.conclusionWorkshops?.map((workshop) => workshop);

        const newSkillGoals = SetSelectedWorkshopGoals({
            skills,
            workshopGoalIds,
            prevSkills: prev.skills,
        });

        const prevWorkshopIds = new Set(workshopIds);

        if (prevWorkshopIds?.has(value)) {
            prevWorkshopIds.delete(value);
            return { ...prev, conclusionWorkshops: Array.from(prevWorkshopIds) };
        } else {
            prevWorkshopIds.add(value);
            return { ...prev, conclusionWorkshops: Array.from(prevWorkshopIds), skills: newSkillGoals };
        }
    });
};

const filterArticlesCarePlan = ({
    articles,
    payload,
}: {
    articles: Array<OptimizedItem>;
    payload: ConclusionPayloadType;
}) => {
    const filterData = articles?.filter((item) => payload?.conclusionVideos?.includes(item?.id));
    const uniquedArray = uniqByKeyValues({ items: filterData ?? [], key: 'id' });
    return uniquedArray ?? [];
};

const filterWorkshopCarePlan = ({
    workshops,
    payload,
}: {
    workshops: Array<OptimizedItem>;
    payload: ConclusionPayloadType;
}) => {
    const filterData = workshops?.filter((item) => payload?.conclusionWorkshops?.includes(item?.id));
    const uniquedArray = uniqByKeyValues({ items: filterData ?? [], key: 'id' });
    return uniquedArray ?? [];
};

function SkillGoalIds(
    skills: {
        id: number;
        goalIds: number[];
    }[],
) {
    const goalIdsFromPayload = skills?.map((skill) => skill?.goalIds);
    const goalIds = goalIdsFromPayload?.flat(1) ?? [];
    const uniqGoalIds = new Set(goalIds);
    return Array.from(uniqGoalIds);
}

const filterGoalCarePlan = ({ goals, payload }: { goals: Array<OptimizedItem>; payload: ConclusionPayloadType }) => {
    const goalIdsFromPayload = SkillGoalIds(payload?.skills);
    const filterData = goals?.filter((item) => goalIdsFromPayload?.includes(item?.id));
    const uniquedArray = uniqByKeyValues({ items: filterData ?? [], key: 'id' });
    return uniquedArray ?? [];
};

const filterSkillCarePlan = ({ skills, payload }: { skills: Array<ProgramSkill>; payload: ConclusionPayloadType }) => {
    const skillIds = payload?.skills?.map((skill) => skill?.id);
    const filterData = skills?.filter((item) => skillIds?.includes(item?.id));
    const uniquedArray = uniqByKeyValues({ items: filterData ?? [], key: 'id' });

    return uniquedArray ?? [];
};

function SetCheckedGoalInPayloadWhileUpdatingArticleWorkshops({
    goalData,
    payload,
    setPayload,
}: {
    goalData: {
        id: number;
        name: string;
        title?: string;
        articles?: { id: number; title?: string }[];
        workshops?: { id: number; title?: string }[];
    };
    payload: ConclusionPayloadType;
    setPayload: Dispatch<SetStateAction<ConclusionPayloadType>>;
}) {
    const goalWorkshopIds = goalData?.workshops?.map((workshop) => workshop?.id);
    const goalArticleIds = goalData?.articles?.map((article) => article?.id);

    const compareToCheckedWrkshopsInPayload = payload.conclusionWorkshops?.filter((workshop) =>
        goalWorkshopIds?.includes(workshop),
    );

    const compareToCheckedArticleInPayload = payload.conclusionVideos?.filter((article) =>
        goalArticleIds?.includes(article),
    );

    const removeGoalFromPayload =
        compareToCheckedWrkshopsInPayload?.length === 0 && compareToCheckedArticleInPayload?.length === 0;

    setPayload((prev) => {
        if (removeGoalFromPayload) {
            const filteredSkillGoal = prev.skills?.map((skill) => ({
                id: skill.id,
                goalIds: skill.goalIds?.filter((goal) => goal !== goalData?.id),
            }));
            return {
                ...prev,
                skills: filteredSkillGoal,
            };
        }
        return prev;
    });
}

function SetCheckedSkillInPayloadWhileUpdatingGoals({
    skills,
    payload,
    setPayload,
}: {
    skills: {
        id: number;
        name: string;
        goals?: { id: number; name?: string }[];
    }[];
    payload: ConclusionPayloadType;
    setPayload: Dispatch<SetStateAction<ConclusionPayloadType>>;
}) {
    const mainSkills = skills?.map((skill) => ({
        id: skill.id,
        goalIds: skill?.goals?.map((goal) => goal.id),
    }));

    if (payload?.skills?.length > 0) {
        setPayload((prev) => {
            const prevSkills = prev.skills;
            const newSkillPayload =
                prevSkills?.filter((prevSkill) => {
                    const skillMainItem = mainSkills?.find((skill: any) => skill.id === prevSkill.id);
                    const hasGoals =
                        skillMainItem?.goalIds?.filter((goalId) => prevSkill.goalIds?.includes(goalId)) ?? [];
                    return hasGoals?.length > 0;
                }) ?? [];
            return { ...prev, skills: [...newSkillPayload] };
        });
    }
}

export {
    SetCheckedSkillInPayloadWhileUpdatingGoals,
    SetCheckedGoalInPayloadWhileUpdatingArticleWorkshops,
    filterSkillCarePlan,
    filterGoalCarePlan,
    filterWorkshopCarePlan,
    filterArticlesCarePlan,
    SetDefaultUserArticlePayload,
    SetDefaultUserWorkshopPayload,
    SetDefaultUserGoalsPayload,
    SetDefaultSkillsPayload,
    GoalWorkshopsArticles,
    ProgramGoals,
    handleClickGoal,
    UserProgram,
    handleClickSkill,
    handleClickGoalSkill,
    handleClickArticleSkill,
    handleClickWorkshopSkill,
    SkillGoalIds,
};
