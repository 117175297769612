import { useQuery, useQueryClient } from '@tanstack/react-query';
import { PatientService } from 'src/services/Patients';
import { Patient } from 'src/interfaces/interfaces';
import { Dispatch, SetStateAction, useContext } from 'react';
import { UserContext } from 'src/providers/UserProvider';
import { fullName } from 'src/utils/utils';
import { uniqBy } from 'lodash';

function optimezedListForPatientsMenu(patients: Patient[]) {
    const items = Array.from(patients, (item: any) => ({
        ...item,
        fullName: fullName(item?.lastName, item?.firstName),
        patientId: item?.id,
    }));

    const filteredData = uniqBy(items, 'patientId');

    return filteredData ?? [];
}

function FilterActivePatientOnly({ patientItems }: { patientItems: Patient[] }) {
    const patientsData = patientItems?.filter((patient: any) => {
        const activePatient = patient.activated === 1;
        return activePatient;
    });

    return optimezedListForPatientsMenu(patientsData);
}

function useFetchPatientRQHooks({
    dateFilter,
    setActiveLoadingIndicator,
}: {
    dateFilter?: string | Date;
    setActiveLoadingIndicator?: Dispatch<SetStateAction<boolean>> | undefined;
}) {
    return useQuery({
        queryKey: ['patients'],
        queryFn: async () => await PatientService.getPatients({ dateFilter }),
        onSuccess: () => {
            setActiveLoadingIndicator?.(false);
        },
        onError: () => {
            setActiveLoadingIndicator?.(false);
        },
        select: (responses) => {
            const patientItems = responses?.data?.data;
            return FilterActivePatientOnly({ patientItems });
        },
    });
}

function useFetchPatientsByProfessionalRQHooks({ dateFilter }: { dateFilter?: string }) {
    const { userInfos } = useContext(UserContext);
    const professionalId = userInfos?.id;

    return useQuery({
        queryKey: ['patients-by-professional'],
        queryFn: async () =>
            professionalId > 0
                ? await PatientService.getAcceptedByProfessional({
                      professionalId,
                      dateFilter,
                  })
                : null,
        onSuccess: () => {},
        onError: () => {},
        select: (responses) => {
            const patientItems = responses?.data?.data;
            const optimezed = patientItems?.map((item: any) => ({
                ...item,
                fullName: fullName(item?.patient?.firstName, item?.patient?.lastName),
            }));
            return optimezed;
        },
    });
}

// PREFETCH
function usePreFetchPatientRQHooks() {
    const queryClient = useQueryClient();

    return queryClient.prefetchQuery({
        queryKey: ['patients'],
        queryFn: async () => await PatientService.getPatients(),
    });
}

function usePreFetchPatientsByProfessionalRQHooks() {
    const { userInfos } = useContext(UserContext);
    const professionalId = userInfos?.id;
    const queryClient = useQueryClient();

    return queryClient.prefetchQuery({
        queryKey: ['patients-by-professional'],
        queryFn: async () =>
            professionalId > 0
                ? await PatientService.getAcceptedByProfessional({
                      professionalId,
                  })
                : undefined,
    });
}

export {
    usePreFetchPatientRQHooks,
    usePreFetchPatientsByProfessionalRQHooks,
    optimezedListForPatientsMenu,
    useFetchPatientRQHooks,
    useFetchPatientsByProfessionalRQHooks,
};
