import { useQuery } from '@tanstack/react-query';
import { PatientService, getUserLastLogin } from 'src/services/Patients';

function useLastSessionRQHooks({ patientId }: { patientId: number }) {
    return useQuery({
        queryKey: [`last-session-${patientId}`],
        queryFn: async () => await getUserLastLogin(patientId),
        onSuccess: () => {},
        onError: () => {},
        select: (response) => {
            const lastSession = response?.data?.data;
            return lastSession ?? null;
        },
    });
}

function usePatientNextRdvRQHooks({ patientId }: { patientId: number }) {
    return useQuery({
        queryKey: [`patient-nextrdv-${patientId}`],
        queryFn: async () => await PatientService.getPatientNextRdv(patientId),
        onSuccess: () => {},
        onError: () => {},
        select: (response) => {
            const nextRdv = response?.data?.data;
            return nextRdv ?? null;
        },
    });
}

export { useLastSessionRQHooks, usePatientNextRdvRQHooks };
