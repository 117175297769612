import { Typography, Box } from '@mui/material';
import { FC } from 'react';
import { NotificationItem as Notification } from 'src/interfaces/interfaces';
import { baseColors } from '../../utils/constants/baseStyles';
import useStyles from './styles';
import CircleIcon from './../CustomIcon/CircleIcon';
import { convertToTimeZoneDate, notificationDate } from 'src/utils/utils';

interface NotificationItemProps {
    item: Notification;
    onClick: Function;
}

const NotificationItem: FC<NotificationItemProps> = (props) => {
    const {
        item: { action, date, activated, content },
        onClick,
    } = props;
    const classes = useStyles({ activated: activated });
    const dateTimeZone = convertToTimeZoneDate(date);

    return (
        <Box className={classes.container} onClick={() => onClick()}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Typography fontSize={13} fontWeight={activated === 1 ? 'bold' : 'light'} className={classes.label}>
                    {activated === 1 ? <CircleIcon /> : ''}
                </Typography>
                <Typography
                    fontSize={13}
                    fontWeight={activated === 1 ? 'bold' : 'light'}
                    className={classes.label}
                    style={{ marginLeft: 15 }}
                >
                    {content}
                </Typography>
            </div>

            <Box className={classes.right}>
                <div className={classes.action}>
                    <Typography
                        color={baseColors.green.primary}
                        fontWeight={activated === 1 ? 'bold' : 'light'}
                        fontSize={10}
                    >
                        {action}
                    </Typography>
                </div>
                <Typography
                    fontSize={12}
                    color={activated === 1 ? baseColors.white : baseColors.black}
                    className={classes.date}
                >
                    {notificationDate(dateTimeZone)}
                </Typography>
            </Box>
        </Box>
    );
};

export default NotificationItem;
