import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { baseColors, baseScreenHeight } from 'src/utils/constants/baseStyles';
import { tableCellHeadText, tableCellText } from '../SecurityManagement/SecurityManagement';

interface RoleTableHeader {
    name: string;
}

interface RoleDataRow {
    id: number;
    name: string;
    description: string;
    code: string;
    action: any;
}

interface RoleTableProps {
    tableHeaders: RoleTableHeader[];
    dataRows: RoleDataRow[];
}

export const RoleActionButtons = () => {
    return (
        <>
            <SettingsOutlinedIcon sx={{ fontSize: 'medium', color: baseColors.green.primary, marginRight: '10px' }} />
            <EditOutlinedIcon sx={{ fontSize: 'medium', color: baseColors.green.primary, marginRight: '10px' }} />
            <VisibilityOutlinedIcon
                sx={{ fontSize: 'medium', color: baseColors.orange.primary, marginRight: '10px' }}
            />
            <DeleteOutlineOutlinedIcon sx={{ fontSize: 'medium', color: 'red', marginRight: '10px' }} />
        </>
    );
};

const RoleTable = (props: RoleTableProps = { tableHeaders: [], dataRows: [] }) => {
    const tableHeaders = props.tableHeaders;
    const dataRows = props.dataRows;

    return (
        <TableContainer
            component={Paper}
            style={{ overflow: 'auto', maxHeight: baseScreenHeight.securitySubMenusHeight }}
        >
            <Table stickyHeader sx={{ minWidth: 650 }} aria-label="role table">
                <TableHead>
                    <TableRow>
                        {tableHeaders.map((header, index) => {
                            return (
                                <TableCell sx={{ color: baseColors.green.primary }}>
                                    {tableCellHeadText(header.name)}
                                </TableCell>
                            );
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {dataRows.map((row) => (
                        <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" scope="row">
                                {tableCellText(row.name)}
                            </TableCell>
                            <TableCell>{tableCellText(row.description)}</TableCell>
                            <TableCell>{tableCellText(row.code)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default RoleTable;
