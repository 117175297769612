import * as React from 'react';
import { baseColors } from 'src/utils/constants/baseStyles';

function Programme(props: any) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
            <g clipPath="url(#clip0_1525:11475)">
                <path
                    fill="#F6B863"
                    d="M5.088 19.215h13.1v1.323h-13.1v-1.323zM13.924 2.7h1.58L15 .61a.768.768 0 10-1.491.363l.415 1.726z"
                />
                <path
                    fill="#F6B863"
                    d="M20.404 23.05l-2.165-8.99H16.66l2.252 9.353a.768.768 0 101.492-.363zm-14.94-8.99L3.3 23.05a.768.768 0 001.492.364l2.252-9.355h-1.58zM9.778 2.7l.415-1.725A.768.768 0 108.702.612L8.199 2.7h1.579z"
                />
                <path
                    fill="#DBD8DD"
                    d="M1.713 1.604h20.573a.82.82 0 01.82.82v13.441a.82.82 0 01-.82.82H1.713a.82.82 0 01-.82-.82V2.425a.82.82 0 01.82-.82z"
                />
                <path fill="#fff" d="M1.903 2.614h20.195v13.061H1.903V2.614z" />
                <path
                    fill={baseColors.green.primary}
                    d="M19.08 7.151v7.52H4.92V11.43l5.275-3.502 2.783 3.217 6.103-3.993z"
                />
                <path
                    fill="#575B6D"
                    d="M4.92 9.145v-.62l5.322-3.533 2.783 3.218 6.056-3.961v.618L12.93 8.89l-2.782-3.216-5.229 3.47z"
                />
                <path
                    fill={baseColors.orange.primary}
                    d="M12.978 9.521a1.034 1.034 0 100-2.069 1.034 1.034 0 000 2.07z"
                />
                <path
                    fill={baseColors.orange.primary}
                    d="M18.912 5.692a1.034 1.034 0 100-2.069 1.034 1.034 0 000 2.069z"
                />
                <path fill="#0D4447" d="M10.216 6.317a1.035 1.035 0 100-2.069 1.035 1.035 0 000 2.07z" />
                <path fill="#2CBFAE" d="M5.088 9.777a1.034 1.034 0 100-2.07 1.034 1.034 0 000 2.07z" />
            </g>
            <defs>
                <clipPath id="clip0_1525:11475">
                    <path fill="#fff" d="M0 0h24v24H0z" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default Programme;
