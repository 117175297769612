import { PlanningAgendaFilters } from 'src/interfaces/interfaces';
import axios from '../AxiosInstance';
import qs from 'qs';
import { convertToZeroIfArrayEmpty } from 'src/utils/utils';

const getEvents = ({ checkedDate, filters }: { checkedDate: string; filters: PlanningAgendaFilters }) => {
    let additionalUrl: string = '';
    let eventApi: string = 'events/created';

    // Array Filters
    const eventFilters = filters?.checkedEvents && filters?.checkedEvents.length > 0 ? filters?.checkedEvents : [];
    const programFilters =
        filters?.checkedPrograms && filters?.checkedPrograms.length > 0 ? filters?.checkedPrograms : [];
    const roomFilters = filters?.checkedRooms && filters?.checkedRooms.length > 0 ? filters?.checkedRooms : [];
    const intervenantsFilters =
        filters?.intervenantsIds && filters?.intervenantsIds.length > 0 ? filters?.intervenantsIds : [];
    const specialityIds = filters?.specialityIds && filters?.specialityIds.length > 0 ? filters?.specialityIds : [];

    // if (checkedDate) {
    //     additionalUrl += `&yearAndMonth=${checkedDate}`;
    // }

    if (intervenantsFilters?.length) {
        additionalUrl += `&isPublic=1`;
    }

    if (filters?.durations?.length && filters?.durations?.length > 0) {
        const minDuration = filters?.durations?.[0] ?? 0;
        const maxDuration = filters?.durations?.[1] ?? 0;
        additionalUrl += `&minDuration=${convertToZeroIfArrayEmpty(minDuration)}&maxDuration=${maxDuration}`;
    }

    if (filters?.checkedLocations) {
        additionalUrl += `&customLocation=${filters?.checkedLocations}`;
    }

    if (filters && filters?.patientId) {
        additionalUrl += `&patientId=${filters?.patientId}`;
    }

    return axios.get(`api/${eventApi}?yearAndMonth=${checkedDate}${additionalUrl}`, {
        params: {
            eventTypeCategoryFilters: eventFilters,
            programIds: programFilters,
            customLocationIds: roomFilters,
            userIds: intervenantsFilters,
            specialityIds: specialityIds,
        },
        paramsSerializer: (params) => qs.stringify(params),
    });
};

const FullCalendarService = {
    getEvents,
};

export default FullCalendarService;
