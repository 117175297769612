import { useQuery, useQueryClient } from '@tanstack/react-query';
import SkillService from 'src/services/Skills/skill.service';

function useFetchSkillsRQHooks() {
    return useQuery({
        queryKey: ['skills'],
        queryFn: async () => await SkillService.GetSkills(),
        onSuccess: () => {},
        onError: () => {},
        select: (response) => {
            const data = response?.data?.data ?? [];
            return data;
        },
    });
}

// PREFETCH
function usePreFetchSkillsRQHooks() {
    const queryClient = useQueryClient();

    return queryClient.prefetchQuery({
        queryKey: ['skills'],
        queryFn: async () => await SkillService.GetSkills(),
    });
}

export { useFetchSkillsRQHooks, usePreFetchSkillsRQHooks };
