import { PostAgendaTimeConfigurations, PostShareAgenda } from 'src/interfaces/interfaces';
import axios from './AxiosInstance';

const postAgendaTimeConfigurations = (payload: PostAgendaTimeConfigurations) =>
    axios.post('api/agendaConfigurations', payload);

const getAgendaTimeConfigurations = (userId: number) => axios.get(`api/agendaConfigurations/current?userId=${userId}`);

const postShareAgenda = (payload: PostShareAgenda) => axios.post('api/agenda/share', payload);

const AgendaSettingsService = {
    postAgendaTimeConfigurations,
    getAgendaTimeConfigurations,
    postShareAgenda,
};

export default AgendaSettingsService;
