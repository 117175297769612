import { Grid } from '@mui/material';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { CustomSelect } from 'src/components/InputField/CustomSelect';
import { InputField } from 'src/components/InputField/InputField';
import { CustomText } from 'src/components/Text/CustomText';
import { isNotBlank } from 'src/utils/helpers/InputValidator';
import { defaultDuration, durations, handleSetPayload, thirtyMinitDuration } from 'src/utils/constants/constants';
import { Spacer } from 'src/components/Spacer/Spacer';

type IProps = {
    lockWorkshopInput: boolean;
    payload: {
        name: string;
    };
    setPayload: Dispatch<SetStateAction<any>>;
    dayHospitalId?: number;
    workshopData: any;
    loadingData?: any;
};

export const NameAndDuration: FC<IProps> = ({ lockWorkshopInput, setPayload, payload, workshopData, loadingData }) => {
    const allDurations = durations?.flat(1);
    const workshopDurations = [{ id: 0, name: 'Sélectionner une durée' }, thirtyMinitDuration, ...allDurations];
    const defaultValue = workshopData?.duration ? defaultDuration(workshopData?.duration) : 0;
    const defaultAxisId = workshopData?.axisId ?? 0;

    // const handleFetchAxis = () => {
    //     setFetchingAxis(true);
    //     getAxisList()
    //         .then((response) => {
    //             const axis = response;
    //             if (axis?.items?.length) {
    //                 axis?.items.splice(6, 1);
    //                 axis?.items.splice(5, 1);
    //                 axis?.items.splice(4, 1);
    //                 setAxis(axis.items);
    //             }
    //             setFetchingAxis(false);
    //         })
    //         .catch(() => setFetchingAxis(false));
    // };

    // useEffect(() => {
    //     handleFetchAxis();
    // }, [defaultAxisId]);

    // const axisItems = [{ id: 0, name: 'Sélectionner un axe' }, ...axis];

    return (
        <>
            <Grid
                style={{ marginTop: 20 }}
                lg={12}
                container
                direction="row"
                alignItems={'center'}
                justifyContent="space-between"
            >
                <Grid item>
                    <CustomText>Nom de l'atelier :</CustomText>
                </Grid>
                <InputField
                    label=""
                    disabled={lockWorkshopInput}
                    onChange={(value: any) => setPayload((old: any) => ({ ...old, name: value }))}
                    validator={isNotBlank}
                    errorMessage="Nom de l'atelier obligatoire"
                    placeholder="Entrer un nom de l'atelier ..."
                    width={360}
                    value={payload.name}
                    idSuffix="nom_atelier"
                />
            </Grid>

            <Spacer height={10} />

            <Grid lg={12} container direction="row" alignItems="center" justifyContent="space-between">
                <Grid item>
                    <CustomText>Durée de l'atelier :</CustomText>
                </Grid>

                <Grid item style={{ width: '60%' }}>
                    <CustomSelect
                        onItemClick={() => {}}
                        onChange={(value: number) => handleSetPayload(value, setPayload)}
                        label=""
                        labelKey="name"
                        items={workshopDurations}
                        defaultValue={defaultValue}
                        idSelect="duration-name"
                        width="100%"
                    />
                </Grid>
            </Grid>

            <Spacer />

            {/* <Grid lg={12} container direction="row" alignItems="center" justifyContent="space-between">
                <Grid item>
                    <CustomText>Axe :</CustomText>
                </Grid>

                <Grid item style={{ width: '60%' }}>
                    {fetchingAxis ? (
                        loadingData
                    ) : (
                        <CustomSelect
                            onItemClick={() => {}}
                            onChange={(value: number) => setPayload((prev: any) => ({ ...prev, axisId: value }))}
                            label=""
                            labelKey="name"
                            items={axisItems}
                            defaultValue={!fetchingAxis ? defaultAxisId : 0}
                            idSelect="axis-name"
                            width="100%"
                        />
                    )}
                </Grid>
            </Grid> */}
        </>
    );
};
