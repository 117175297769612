import { makeStyles } from '@mui/styles';
import {
    baseBorderRadius,
    baseColors,
    baseFontFamily,
    baseMarging,
    baseScreenHeight,
} from 'src/utils/constants/baseStyles';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: baseColors.white,
        ...baseScreenHeight.defaultHeight,
        ...baseBorderRadius.allSide,
    },
    header: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: baseMarging.marginBottom,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    title: {
        fontFamily: baseFontFamily.BreeSerif,
        marginBottom: 15,
    },
    subtitle: {
        color: baseColors.green.secondary,
    },
    filterHeader: {
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: baseColors.green.light,
        borderRadius: 7,
        padding: 7,
        width: 200,
        justifyContent: 'space-between',
    },
    filterLabel: {
        paddingLeft: 10,
    },
});

export const sxStyles: any = {
    flexRow: {
        display: 'flex',
        flexDirection: 'row',
    },
    planningLabel: {
        fontSize: 18,
        color: baseColors.black,
        textAlign: 'center',
        marginBottom: 10,
        marginTop: 10,
    },
    statLabel: {
        fontSize: 15,
        color: baseColors.green.primary,
        textAlign: 'center',
        marginBottom: 10,
    },
    statContainer: {
        flex: 1,
        backgroundColor: baseColors.green.light,
        borderRadius: 3,
        padding: 2,
        marginLeft: 2,
        height: 180,
        width: '100%',
        marginTop: 3,
    },
    programAgendaInfos: {
        marginBottom: 2,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
};

export default useStyles;
