import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { ReportPayload } from 'src/interfaces/interfaces';
import { PatientContext } from 'src/providers/PatientProvider';
import { PatientService } from 'src/services/Patients';
import { OperationMessage } from 'src/utils/constants/constants';

export const defaultReport = {
    patientId: 0,
    programEnteringReason: '',
    medicalAntecedent: '',
    treatments: '',
    educationalDiagnosisConclusion: '',
    followedProgramContent: '',
    interviewConclusion: '',
    tool: '',
    observations: '',
    acquiredSkills: '',
    project: '',
    careToolsId: 1,
    reportText: '',
    recipients: [],
    externalUsersMail: [],
};

const useEvaluationHooks = ({
    toggleForm,
    setListFetching,
}: {
    toggleForm?: Function;
    setListFetching?: Dispatch<SetStateAction<boolean>>;
}) => {
    const [saving, setSaving] = useState(false);
    const [fetching, setFetching] = useState(false);
    const [updateList, setUpdateList] = useState(false);
    const [reports, setReports] = useState([]);
    const { selectedPatient } = useContext(PatientContext);
    const patientId = selectedPatient?.id ?? 0;

    const handlePostReport = ({
        reportPayload,
        setReportPayload,
    }: {
        reportPayload: ReportPayload;
        setReportPayload: Dispatch<SetStateAction<ReportPayload>>;
    }) => {
        setSaving(true);
        PatientService.postReports({ ...reportPayload, patientId })
            .then(() => {
                setReportPayload(defaultReport);
                toast.success(OperationMessage.success);
                setSaving(false);
                toggleForm?.();
                setUpdateList((prev) => !prev);
            })
            .catch(() => {
                setSaving(false);
                toast.error(OperationMessage.error);
            });
    };

    const handleFetchReports = () => {
        setFetching(true);
        setListFetching?.(true);
        PatientService.getReports(patientId)
            .then((response) => {
                const data = response?.data?.data;
                setReports(data);
                setFetching(false);
                setListFetching?.(false);
            })
            .catch(() => {
                setFetching(false);
                setListFetching?.(false);
                toast.error(OperationMessage.error);
            });
    };

    const handleExportReportPdf = (data: any) => {
        PatientService.getReportsPdf(data.id)
            .then((response) => {
                if (response?.data?.data) {
                    const linkSource = `data:application/pdf;base64,${response.data.data}`;
                    const downloadLink = document.createElement('a');
                    const fileName = 'application.pdf';
                    downloadLink.href = linkSource;
                    downloadLink.download = fileName;
                    downloadLink.click();
                    toast.success('Compte rendu exportée avec succès.');
                } else {
                    toast.success('Pas de données à exporter.');
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };

    return {
        handlePostReport,
        saving,

        handleFetchReports,
        fetching,
        reports,

        handleExportReportPdf,
        updateList,
    };
};

export default useEvaluationHooks;
