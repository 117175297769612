import { makeStyles } from '@mui/styles';
import { baseColors, baseFontFamily, baseMarging } from '../../../utils/constants/baseStyles';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
    header: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: baseMarging.marginBottom,
    },
    subheader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    title: {
        fontFamily: baseFontFamily.BreeSerif,
        marginBottom: 15,
    },
    subtitle: {
        color: baseColors.green.secondary,
    },
    filterHeader: {
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: baseColors.green.light,
        borderRadius: 7,
        padding: 7,
        marginBottom: 15,
        width: 120,
        justifyContent: 'space-between',
    },
    filterLabel: {
        paddingLeft: 10,
    },
    searchInput: {
        // cursor: 'pointer',
        // display: 'flex',
        // flexDirection: 'row',
        // alignItems: 'center',
        // backgroundColor: baseColors.green.light,
        // borderRadius: 7,
        // padding: 7,
        // width: 200,
    },
});

export default useStyles;
