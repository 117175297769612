import { FC, useContext } from 'react';
import { CustomText } from 'src/components/Text/CustomText';
import useStyles from './styles';
import { baseFontFamily, baseFontSize } from 'src/utils/constants/baseStyles';
import { Spacer } from 'src/components/Spacer/Spacer';
import { formatDate, getAge } from 'src/utils/helpers/helpers';
import { PatientContext } from 'src/providers/PatientProvider';

interface DESyntheseHeaderProps {
    patientId: number;
}

export const DESyntheseHeader: FC<DESyntheseHeaderProps> = (props) => {
    const classes = useStyles();
    const { selectedPatient } = useContext(PatientContext);

    const items = [
        {
            id: 1,
            label: 'Nom :',
            value: selectedPatient?.firstName,
        },
        {
            id: 2,
            label: 'Prénom(s) :',
            value: selectedPatient?.lastName,
        },
        {
            id: 3,
            label: 'Âge :',
            value:
                '(' +
                getAge(selectedPatient?.birthDate, new Date()) +
                ' ans) ' +
                formatDate(selectedPatient?.birthDate),
        },
        {
            id: 4,
            label: 'Sexe :',
            value: selectedPatient?.sex === 0 ? 'Féminin' : 'Masculin',
        },
    ];

    return (
        <div className={classes.container}>
            <CustomText
                style={{ textAlign: 'center' }}
                fontSize={baseFontSize.sl}
                fontFamily={baseFontFamily.BreeSerif}
            >
                Informations du patient
            </CustomText>

            <Spacer />

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ display: 'flex', flex: 1 / 2, flexDirection: 'column' }}>
                    {items?.slice(0, 2)?.map((item) => (
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: 10,
                            }}
                        >
                            <CustomText
                                style={{ width: 100, textAlign: 'left' }}
                                fontFamily={baseFontFamily.OpenSansSemiBold}
                                fontSize={baseFontSize.sm}
                            >
                                {item.label}
                            </CustomText>

                            <CustomText
                                style={{
                                    marginLeft: 5,
                                }}
                                fontSize={baseFontSize.mini}
                            >
                                {item.value}
                            </CustomText>
                        </div>
                    ))}
                </div>

                <div style={{ display: 'flex', flex: 1 / 2, flexDirection: 'column' }}>
                    {items?.slice(2, 4)?.map((item) => (
                        <div
                            style={{
                                marginBottom: 10,
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <CustomText
                                style={{ width: 80, textAlign: 'left' }}
                                fontFamily={baseFontFamily.OpenSansSemiBold}
                                fontSize={baseFontSize.sm}
                            >
                                {item.label}
                            </CustomText>

                            <CustomText
                                style={{
                                    marginLeft: 5,
                                }}
                                fontSize={baseFontSize.mini}
                            >
                                {item.value}
                            </CustomText>
                        </div>
                    ))}
                </div>
            </div>

            <Spacer />
        </div>
    );
};
