import { Grid } from "@mui/material"
import { FC } from "react"
import { UserAxisItem } from "src/interfaces/interfaces"
import { baseColors } from "src/utils/constants/baseStyles"
import SvgDinerIcon from "../CustomIcon/SvgDinerIcon"
import SvgCoffeIcon from "../CustomIcon/SvgCoffeIcon"
import SvgCollationIcon from "../CustomIcon/SvgCollationIcon"
import SvgDejeunerIcon from "../CustomIcon/SvgDejeunerIcon"
import QuestionCardItem from "./QuestionCardItem"

interface QuestionCardProps {
    userDEResponse: UserAxisItem;
    setPayload : Function;
    questionTitle : JSX.Element;
    responseValue: any;
    index : number;
    precisionAnswer: number | string | undefined;
}
const getIcon = (type : string, active: boolean) => {
    switch (type) {
        case "DINNER":
            return <SvgDinerIcon fill={active ? baseColors.white : baseColors.green.primary} />
        case "MORNING_SNACK":
            return <SvgCollationIcon fill={active ? baseColors.white : baseColors.green.primary} />
        case "LUNCH":
            return <SvgDejeunerIcon fill={active ? baseColors.white : baseColors.green.primary} />
        case "AFTERNOON_SNACK":
            return <SvgCollationIcon fill={active ? baseColors.white : baseColors.green.primary} />
        default:
            return <SvgCoffeIcon fill={active ? baseColors.white : baseColors.green.primary} />
    }
}

const QuestionCard : FC<QuestionCardProps> = ({userDEResponse, index, precisionAnswer, responseValue, setPayload, questionTitle}) => {
    const proposalAnswerSurveys = userDEResponse.survey.proposalAnswerSurveys;
    const hasPrécision = userDEResponse.survey.hasPrecision === 1;
    return (
        <Grid
                style={{ marginTop: 20, marginBottom: 10 }}
                container
                direction="column"
                alignItems={'start'}
                justifyContent="start"
            >
            <Grid item>
                {questionTitle}
            </Grid>
            <Grid item style={{display: 'flex', flexDirection: 'row', gap: '50px'}}>
                {proposalAnswerSurveys?.map((prop, i)=>{
                    const isActive = parseInt(responseValue) === prop.proposalAnswer.id;
                    return <QuestionCardItem
                        key={i}
                        id={prop.proposalAnswer.id}
                        icon={getIcon(prop.proposalAnswer.code, isActive)}
                        title={prop.proposalAnswer.name}
                        setPayload={setPayload}
                        index={index}
                        value={responseValue}
                        isActive={isActive}
                        hasPrécision={hasPrécision}
                        precisionAnswer={precisionAnswer}
                        userDEResponse={userDEResponse}
                    />
                })}
            </Grid>
        </Grid>
    )
}

export default QuestionCard