function SvgCoffeIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={41}
      height={41}
      fill="none"
      viewBox="0 0 41 41"
      {...props}
    >
      <g clipPath="url(#clip0)" fill={props.fill}>
        <path d="M31.604 16.229H7.687a2.566 2.566 0 00-2.563 2.562V20.5a16.19 16.19 0 008.786 14.428.855.855 0 00.784-1.52A14.485 14.485 0 016.832 20.5v-1.708c0-.471.383-.854.855-.854h23.917c.471 0 .854.383.854.854V20.5c0 5.455-3.013 10.4-7.864 12.907a.855.855 0 00.784 1.519A16.182 16.182 0 0034.166 20.5v-1.708a2.566 2.566 0 00-2.562-2.563z" />
        <path d="M39.228 33.838a.856.856 0 00-.789-.528H.855a.856.856 0 00-.605 1.46l1.915 1.916a5.943 5.943 0 004.228 1.751h26.504a5.954 5.954 0 004.232-1.75l1.915-1.918a.853.853 0 00.184-.93zm-3.309 1.64a4.246 4.246 0 01-3.02 1.25H6.393a4.246 4.246 0 01-3.02-1.25l-.458-.458h33.462l-.458.458zm3.429-15.216c-2.301-1.48-5.977.126-6.39.313a.855.855 0 00.71 1.556c.823-.374 3.464-1.26 4.756-.429.582.374.868 1.1.868 2.214 0 3.42-6.899 5.488-9.562 5.997l-.605.12a.854.854 0 10.33 1.673l.6-.117C30.501 31.503 41 29.444 41 23.916c0-1.718-.557-2.947-1.652-3.654zM26.296 9.074c1.389-1.736 1.389-4.454 0-6.19a.853.853 0 10-1.334 1.068c.88 1.098.88 2.956-.002 4.057-1.39 1.736-1.39 4.454 0 6.19a.85.85 0 001.201.133.853.853 0 00.133-1.2c-.881-1.1-.881-2.956.002-4.058zm-5.132 0c1.39-1.736 1.39-4.454 0-6.19a.853.853 0 10-1.334 1.068c.881 1.098.881 2.956-.002 4.057-1.389 1.736-1.389 4.454 0 6.19a.85.85 0 001.201.133.853.853 0 00.133-1.2c-.88-1.1-.88-2.956.002-4.058zm-5.118.002c1.389-1.736 1.389-4.454 0-6.19a.854.854 0 00-1.334 1.066c.88 1.099.88 2.956-.002 4.058-1.39 1.735-1.39 4.453 0 6.19a.85.85 0 001.2.134.853.853 0 00.134-1.2c-.882-1.101-.882-2.956.002-4.058z" />
      </g>  
      <sefs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0h41v41H0z" />
        </clipPath>
      </sefs>
    </svg>
  )
}

export default SvgCoffeIcon
