const styles: any = {
    menuItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingRight: 0,
        marginRight: 0,
        marginBottom: 2,
    },
    itemIcon: {
        alignItems: 'center',
        justifyContent: 'center',
        padding: '7px 0px 7px 0px',
        borderRadius: 10,
    },
    divider: {
        width: 2,
        height: 50,
        borderRadius: 7,
        marginLeft: 'auto',
    },
    label: {
        fontSize: '16px',
        marginLeft: 10,
    },
};

export default styles;
