import * as React from 'react'

function Separator(props: any) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={3}
			height={36}
			fill='none'
			viewBox='0 0 3 36'
			{...props}
		>
			<path
				stroke='#F1F1F3'
				strokeLinecap='round'
				strokeWidth={2.089}
				d='M1.044 1.044v33.912'
			/>
		</svg>
	)
}

export default Separator
