import { baseColors } from 'src/utils/constants/baseStyles';

const IconRolePermission = (props: any) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={27}
            height={27}
            fill={baseColors.green.primary}
            viewBox="0 0 27 27"
            {...props}
        >
            <path
                d="M9.36,6.82c0-.9-.55-1.35-1.5-1.35H6.21V8.2H7.86C8.81,8.2,9.36,7.72,9.36,6.82Z"
                fill={baseColors.green.primary}
            />
            <path
                d="M25,0H2A2,2,0,0,0,0,2V25a2,2,0,0,0,2,2H25a2,2,0,0,0,2-2V2A2,2,0,0,0,25,0ZM4,3.75H7.86c2.27,0,3.57,1.08,3.57,3A2.86,2.86,0,0,1,9.87,9.5l1.81,2.83H9.2L7.92,9.92H6.21v2.41H4ZM4,17.16H14.66v2.78H4Zm16.38,6.09L18.22,20,16,23.25H13l3.63-5.2-3.4-5h3l2,3,2-3h3l-3.36,4.85,3.65,5.32Zm1.23-10.61H19.39L17.8,6.71l-1.62,5.93H13.92l-2.8-8.58h2.33l1.68,6.31,1.64-6.31H18.9l1.68,6.31,1.66-6.31h2.23Z"
                fill={baseColors.green.primary}
            />
            <path
                d="M7.92,9.92H6.21v2.41H4V3.75H7.86c2.27,0,3.57,1.08,3.57,3A2.86,2.86,0,0,1,9.87,9.5l1.81,2.83H9.2ZM7.86,8.2c1,0,1.5-.48,1.5-1.38s-.55-1.35-1.5-1.35H6.21V8.2Z"
                fill="#fff"
            />
            <path
                d="M19.39,12.64,17.8,6.71l-1.62,5.93H13.92l-2.8-8.58h2.33l1.68,6.31,1.64-6.31H18.9l1.68,6.31,1.66-6.31h2.23l-2.83,8.58Z"
                fill="#fff"
            />
            <path d="M23.51,23.25h-3.1L18.22,20,16,23.25H13l3.63-5.2-3.4-5h3l2,3,2-3h3l-3.36,4.85Z" fill="#fff" />
            <rect x="4.03" y="17.16" width="10.64" height="2.78" fill={baseColors.orange.primary} />
        </svg>
    );
};

export default IconRolePermission;
