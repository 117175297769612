import { Box, Grid } from '@mui/material';
import { FC, useEffect, useState, useContext } from 'react';
import { InputField } from 'src/components/InputField/InputField';
import { CustomText } from 'src/components/Text/CustomText';
import { isNotBlank } from 'src/utils/helpers/InputValidator';
import { CustomSelect } from 'src/components/InputField/CustomSelect';
import { getCoordinator } from 'src/services/Attendees';
import HdjConsultation from '../../HdjManagement/HdjConsultation';
import HdjWorkshop from '../../HdjManagement/HdjWorkshop';
import GeneralInfos from './GeneralInfos';
import DocumentForm from './DocumentForm';
import { fullName } from 'src/utils/utils';
import { baseColors } from 'src/utils/constants/baseStyles';
import { HdjContext } from 'src/providers/HdjProvider';
import { calculateDuration } from 'src/utils/constants/constants';
interface HdjFormContentProps {
    fetchedHdjData: any;
    hdj: any;
    setHdj: any;
    createForm?: boolean;
}

interface Coordinator {
    id: number;
    name: string;
    firstName?: string;
    lastName?: string;
}

const HdjFormContent: FC<HdjFormContentProps> = (props) => {
    const { hdj, setHdj, fetchedHdjData, createForm } = props;
    const {hourCount, setHourCount} = useContext(HdjContext);
    const [coordinators, setCoordinators] = useState<Array<Coordinator>>([]);
    const [showCoordinatorOption, setShowCoordinatorOption] = useState<boolean>(false);
    const [fetchingCoordinator, setFetchingCoordinator] = useState<boolean>(false);
    const [hdjName, setHdjName] = useState<string>(createForm ? '' : fetchedHdjData?.name ?? '');
    const [selectedWorkshop,setSelectedWorkshop] = useState<Array<any>>([]);
    const [selectedConsultation,setSelectedConsultation] = useState<Array<any>>([]);

    const handleFetchCoordinator = () => {
        setFetchingCoordinator(true);
        getCoordinator()
            .then((response) => {
                const optimized: Coordinator[] = Array.from(response.data.data, (item: Coordinator) => ({
                    id: item?.id,
                    name: fullName(item?.firstName ?? '', item?.lastName ?? ''),
                }));
                setCoordinators(optimized ?? []);
                setFetchingCoordinator(false);
            })
            .catch(() => setFetchingCoordinator(false));
    };

    const handleCummuleConsultationsAndWorkshopHours = () => {
        const consultaionDuration = Array.from(selectedConsultation,(item:any)=>item.duration);
        const workshopDuration = Array.from(selectedWorkshop,(item:any)=>item.duration);
        const duration = calculateDuration([...consultaionDuration,...workshopDuration]);
        setHdj((old: any) => ({ ...old, hourCount: duration }));
        setHourCount(duration);
    }

    useEffect(() => {
        handleFetchCoordinator();
    }, []);

    useEffect(() => {
        setHdj((old: any) => ({ ...old, coordinatorId: fetchedHdjData?.coordinator?.id ?? 0 }));
    }, [fetchedHdjData]);

    useEffect(() => {
        handleCummuleConsultationsAndWorkshopHours();
    },[selectedConsultation,selectedWorkshop])

    const coordinatorList = [
        {
            id: 0,
            name: fetchingCoordinator ? 'Chargement en cours...' : 'Sélectionner un coordinateur',
        },
        ...coordinators,
    ];

    const currentCoordinator = coordinatorList?.find(
        (coordinator) => coordinator.id === fetchedHdjData?.coordinator?.id,
    );

    return (
        <>
            <Grid item style={{ marginTop: 20 }}>
                <Grid container direction="row" alignItems={'center'}>
                    <Grid item>
                        <CustomText>Nom de l’HDJ :</CustomText>
                    </Grid>
                    <Grid style={{ marginLeft: 40 }} item>
                        <InputField
                            single
                            label={''}
                            placeholder={"Taper le nom de l'HDJ"}
                            validator={isNotBlank}
                            errorMessage="Nom Obligatoire"
                            width={'200%'}
                            value={hdjName}
                            onChange={(value: any) => {
                                setHdjName(value);
                                setHdj((old: any) => ({ ...old, name: value.toString() }));
                            }}
                            idSuffix="nom-hdj"
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Box
                style={{
                    marginTop: 30,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'stretch',
                }}
            >
                <GeneralInfos fetchedHdjData={fetchedHdjData} setHdj={setHdj} hdj={hdj} />

                <HdjConsultation edit setHdj={setHdj} fetchedHdjData={fetchedHdjData} hdj={hdj} setSelectedConsultation={setSelectedConsultation} marginRight />

                <HdjWorkshop edit setHdj={setHdj} fetchedHdjData={fetchedHdjData} hdj={hdj} setSelectedWorkshop={setSelectedWorkshop} />

                <DocumentForm setHdj={setHdj} fetchedHdjData={fetchedHdjData} marginRight />
            </Box>

            <Grid item xs={5} style={{ marginTop: 20 }}>
                <Grid container direction="row" alignItems={'center'}>
                    <Grid item>
                        <CustomText>Choisir le coordonnateur :</CustomText>
                    </Grid>
                    <Grid style={{ marginLeft: 40 }} item>
                        {currentCoordinator?.id && !showCoordinatorOption ? (
                            <Box
                                style={{
                                    backgroundColor: baseColors.green.light,
                                    padding: '10px 20px 10px 20px',
                                    borderRadius: 5,
                                    cursor: 'pointer',
                                    color: baseColors.green.primary,
                                    fontSize: 14,
                                }}
                                onClick={() => setShowCoordinatorOption(true)}
                            >
                                {currentCoordinator.name}
                            </Box>
                        ) : (
                            <CustomSelect
                                defaultValue={currentCoordinator?.id ?? 0}
                                onChange={(value: number) => setHdj((old: any) => ({ ...old, coordinatorId: value }))}
                                label=""
                                labelKey={'name'}
                                items={coordinatorList}
                                idSelect="hdj-firstname"
                            />
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default HdjFormContent;
