import { getAttendeesSpeciality } from 'src/services/Attendees';
import { getProfilePicture } from 'src/services/Patients';

const handleFectchSpecialities = ({ setSpecialites, setFetching }: { setSpecialites: any; setFetching?: any }) => {
    setFetching?.(true);
    getAttendeesSpeciality()
        .then((response) => {
            const responses = response?.data?.data?.items;
            const optimized = Array.from(responses, (item: any) => ({ id: item?.id, label: item?.name }));
            setSpecialites(optimized);
            setFetching?.(false);
        })
        .catch(() => setFetching?.(false));
};

const handleChange =
    ({ setPayload, inputKey }: { setPayload: any; inputKey: string }) =>
    (value: string | number) => {
        if (inputKey) {
            setPayload((prev: any) => ({
                ...prev,
                [inputKey]: value,
            }));
        }
    };

const handleSetDefaultPayload = ({
    setPayload,
    setProfIcon,
    selectedUserData,
}: {
    setPayload: any;
    setProfIcon: any;
    selectedUserData: any;
}) => {
    setPayload({
        civility: 1,
        firstName: selectedUserData?.firstName ?? '',
        lastName: selectedUserData?.lastName ?? '',
        email: selectedUserData?.email ?? '',
        sex: selectedUserData?.sex ?? 1,
        phoneNumber: selectedUserData?.phoneNumber ?? '',
        birthDate: selectedUserData?.birthDate,
        birthPlace: '',
        profession: '',
        address: '',
        category: selectedUserData?.category,
        role: selectedUserData?.roles?.[0],
        specialityTypeId: selectedUserData?.specialityType?.id ?? 0,
        rpps: selectedUserData?.rpps,
    });
    setProfIcon(selectedUserData?.filecontent);
};

const handleFetchUserProfilePicture = ({ userId, setProfIcon }: { userId: number; setProfIcon: any }) => {
    getProfilePicture(userId).then((res) => {
        const profilData = res?.data?.data;
        setProfIcon(profilData?.[0]?.imageurl ?? '');
    });
};

const changeGender =
    ({ setPayload, value }: { setPayload: any; value: number }) =>
    () =>
        setPayload((old: any) => ({ ...old, sex: value }));

const genders = [
    { id: 0, name: 'Féminin' },
    { id: 1, name: 'Masculin' },
    { id: 2, name: 'Autre' },
];

export {
    genders,
    changeGender,
    handleFetchUserProfilePicture,
    handleChange,
    handleFectchSpecialities,
    handleSetDefaultPayload,
};
