import * as React from 'react'

function IconBook(props: any) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={20}
			height={20}
			fill='none'
			viewBox='0 0 20 20'
			{...props}
		>
			<path
				fill='#fff'
				d='M3.06 2.361h-.002a.713.713 0 00-.712.716v10.732c0 .393.321.714.716.715 1.666.004 4.457.351 6.383 2.366V5.659a.686.686 0 00-.099-.362C7.766 2.752 4.73 2.365 3.06 2.36zm14.594 11.447V3.076a.713.713 0 00-.712-.716h-.001c-1.67.004-4.707.39-6.287 2.936a.686.686 0 00-.098.362V16.89c1.925-2.015 4.716-2.362 6.382-2.366a.718.718 0 00.716-.715z'
			/>
			<path
				fill='#fff'
				d='M19.284 4.835h-.518v8.972a1.831 1.831 0 01-1.825 1.827c-1.413.003-3.743.28-5.393 1.841 2.853-.698 5.862-.244 7.576.147a.714.714 0 00.876-.698V5.551a.716.716 0 00-.716-.716zm-18.05 8.972V4.835H.716A.716.716 0 000 5.551v11.373a.714.714 0 00.876.697c1.714-.39 4.723-.845 7.576-.146-1.65-1.561-3.98-1.838-5.393-1.841a1.831 1.831 0 01-1.825-1.827z'
			/>
		</svg>
	)
}

export default IconBook
