import { Typography, Box } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { baseColors } from '../../utils/constants/baseStyles';
import useStyles from './styles';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import AgendaVisioDetails from '../CustomIcon/AgendaVisioDetails';
import AgendaPresentielDetail from '../CustomIcon/AgendaPresentielDetail';
import DashboardDataService from 'src/services/Dashboard/dashboard.service';
import EventModal from 'src/views/MyAgenda/EventModal';
import EventService from 'src/services/Calendar/events.service';
import { AgendaContext } from 'src/providers/AgendaProvider';
import { CustomButton } from '../Button/CustomButton';
import { CustomConfirm } from 'src/components/CustomConfirm/CustomConfirm';
import { UserContext } from 'src/providers/UserProvider';
import { EventPlanningViewType } from 'src/interfaces/interfaces';
import DiagnosticModal from 'src/components/CustomModal/DiagnosticModal';
import WorkshopModal from 'src/components/CustomModal/WorkshopModal';
import { toast } from 'react-toastify';
import { OperationMessage } from 'src/utils/constants/constants';

interface PatientOfDayCardProps {
    item: any;
    setUpdateList?: any;
    isGreen?: boolean;
    isForProgram?: boolean;
    selectedIntervenants?: any;
    viewType?: EventPlanningViewType['viewType'];
    dataForWeeklyEvent?: any;
    patientForAgendaWorkShop?: boolean;
}

const PatientOfDayCard: FC<PatientOfDayCardProps> = (props) => {
    const classes = useStyles();
    const {
        item,
        isForProgram,
        selectedIntervenants,
        viewType,
        dataForWeeklyEvent,
        setUpdateList,
        patientForAgendaWorkShop,
    } = props;
    const { name, label, timeStart, timeEnd } = item;
    const { setUpdateEventList } = useContext(AgendaContext);
    const backgroundColor = !item?.itemData?.isInSitu ? baseColors.orange.light : baseColors.green.light;
    const [open, setOpen] = useState<boolean>(false);
    const toggleModal = () =>
        setOpen((prev: boolean) => {
            if (prev) {
                setUpdateList((prevUpdate: boolean) => !prevUpdate);
                setUpdateEventList((prevContextUpdate: boolean) => !prevContextUpdate);
            }
            return !prev;
        });
    const { isCoordinator } = useContext(UserContext);
    let readOnly = selectedIntervenants?.length > 0 || (isForProgram && !isCoordinator);
    const [canceling, setCanceling] = useState<boolean>(false);
    const [openEventModal, setOpenEventModal] = useState<boolean>(false);
    const toggleModalPop = () => setOpenEventModal((prev: boolean) => !prev);
    const [eventData, setEventData] = useState<any>(false);

    const [modalConfirmationVisibleDelete, setModalConfirmationVisibleDelete] = useState<boolean>(false);
    const toggleModalConfirmationRemove = () => setModalConfirmationVisibleDelete(!modalConfirmationVisibleDelete);

    const icon = item?.itemData?.isInSitu ? (
        <AgendaPresentielDetail width={30} height={30} isGreen={true} />
    ) : (
        <AgendaVisioDetails width={30} height={30} isGreen={false} />
    );

    const btnColor = !item?.itemData?.isInSitu
        ? {
              borderColor: baseColors.orange.primary,
              backgroundColor: baseColors.orange.primary,
              color: baseColors.orange.primary,
          }
        : {
              borderColor: baseColors.green.primary,
              backgroundColor: baseColors.green.primary,
              color: baseColors.green.primary,
          };

    const color = !item?.itemData?.isInSitu ? baseColors.orange.primary : baseColors.green.primary;

    const handleCancel = () => {
        if (item.eventId > 0) {
            setCanceling(true);
            DashboardDataService.cancelAnEvent(item.eventId)
                .then((res: any) => {
                    toast.success(OperationMessage.success);

                    setCanceling(false);
                    setUpdateEventList((prev: boolean) => !prev);
                })
                .catch((err: any) => {
                    console.log(err);
                    setCanceling(false);
                });
            setModalConfirmationVisibleDelete(!modalConfirmationVisibleDelete);
        }
    };

    const handleFetchEventData = () => {
        EventService.getEvent(item.eventId)
            .then((response) => {
                setEventData(response?.data?.data);
            })
            .catch((err: any) => {
                console.log(err);
            });
    };

    useEffect(() => {
        handleFetchEventData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item.eventId]);

    const { userInfos } = useContext(UserContext);
    const isExternalDoctor = Boolean(userInfos?.isExternal);

    return (
        <>
            <EventModal
                setUpdateList={setUpdateEventList}
                modalOpen={openEventModal}
                modalToggle={toggleModalPop}
                eventData={eventData}
                edition
            />

            <Box style={{ backgroundColor: backgroundColor }} className={classes.container}>
                <Box className={classes.left} style={{ cursor: 'pointer' }} onClick={toggleModal}>
                    <Box className={classes.time}>
                        <div>{icon}</div>
                        <Typography color={color} fontSize={10}>{`${timeStart} - ${timeEnd}`}</Typography>
                    </Box>
                    <Box className={classes.title}>
                        <Typography color={baseColors.black} fontSize={14}>
                            {label}
                        </Typography>
                        <Typography style={{ marginTop: 10 }} color={baseColors.black} fontSize={14}>
                            {item.type === 'Workshop' ? item.counts.participants + '  patients inscrits' : name}
                        </Typography>
                    </Box>
                </Box>

                <Stack spacing={2} direction="row">
                    <Button
                        onClick={toggleModalConfirmationRemove}
                        style={{
                            borderWidth: 2,
                            ...btnColor,
                            backgroundColor: undefined,
                        }}
                        variant="outlined"
                        size="large"
                    >
                        <Typography fontSize={12}>{canceling ? 'Annulation...' : 'Annuler'}</Typography>
                    </Button>

                    <CustomButton
                        customStyle={{
                            ...btnColor,
                            color: baseColors.white,
                            fontSize: 12,
                        }}
                        variant={'contained'}
                        onClick={toggleModalPop}
                        width={150}
                        label="Replanifier"
                    />

                    <CustomConfirm
                        modalVisible={modalConfirmationVisibleDelete}
                        updateModalVisible={setModalConfirmationVisibleDelete}
                        callBackconfirm={handleCancel}
                        message="Voulez-vous annuler le programme pour ce patient ?"
                    />
                </Stack>
            </Box>

            {eventData?.eventType?.name.toLowerCase() === 'atelier' ? (
                <WorkshopModal
                    setUpdateList={setUpdateList}
                    isCoordinator={isCoordinator}
                    open={open}
                    toggleModal={toggleModal}
                    workShopData={eventData}
                    dataForWeeklyEvent={dataForWeeklyEvent}
                    viewType={viewType}
                    patientForAgendaWorkShop={patientForAgendaWorkShop}
                    readOnly={readOnly}
                    isExternalDoctor={isExternalDoctor}
                    userInfos={userInfos}
                />
            ) : (
                <DiagnosticModal
                    isCoordinator={isCoordinator}
                    isExternalDoctor={isExternalDoctor}
                    open={open}
                    toggleModal={toggleModal}
                    data={eventData}
                    updateEventList={setUpdateList}
                    dataForWeeklyEvent={dataForWeeklyEvent}
                    viewType={viewType}
                    readOnly={readOnly}
                />
            )}
        </>
    );
};

export default PatientOfDayCard;
