import Box from '@mui/system/Box';
import { FC, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import { Spacer } from 'src/components/Spacer/Spacer';
import CustomModal from 'src/components/CustomModal';
import { useDispatch } from 'react-redux';
import { CustomSelect } from 'src/components/InputField/CustomSelect';
import { HdjContext } from 'src/providers/HdjProvider';
import { InputField } from 'src/components/InputField/InputField';
import CustomDatePicker from 'src/components/CustomDatePicker';
import HdjDataService from 'src/services/Hdj/dayHospital.service';
import { toast } from 'react-toastify';
import { ServerError } from 'src/utils/constants/constants';
import { baseColors, baseFontFamily, baseFontSize } from 'src/utils/constants/baseStyles';
import { Button } from '@mui/material';
import { retrieveDayHospitals } from 'src/actions/dayHospitals/dayHospitals';
import { gotoHDJForm } from '../HdjLogic';
import { CustomText } from 'src/components/Text/CustomText';

interface HdjFooterProps {
    loadingHdjList?: boolean;
}

const defaultHDJClone = {
    id: 0,
    name: '',
    date: new Date(),
};

const HdjFooter: FC<HdjFooterProps> = (props) => {
    const { loadingHdjList } = props;
    const history = useHistory();
    const [modalCloneOpen, setModalCloneOpen] = useState(false);
    const { hdjList, setLoadingHdjList, setHdjList } = useContext(HdjContext);
    const [loading, setLoading] = useState(false);
    const [hdjClone, setHdjClone] = useState(defaultHDJClone);
    const dispatch = useDispatch();

    const performClone = () => {
        setLoading(true);
        HdjDataService.cloneHDJ(hdjClone.id, hdjClone.name, hdjClone.date)
            .then((response) => {
                setModalCloneOpen(false);
                setHdjClone(defaultHDJClone);
                const newHDJId = response.data?.data?.id;
                if (newHDJId) {
                    dispatch(
                        retrieveDayHospitals(setLoadingHdjList, setHdjList, () =>
                            history.push(`hdj/management/${newHDJId}`),
                        ),
                    );
                }

                setLoading(false);
            })
            .catch((e) => {
                toast.error(ServerError.unknown);
                setLoading(false);
            });
    };

    return (
        <Box sx={{ justifyContent: 'center', alignItems: 'center', display: 'flex', marginTop: 1 }}>
            <CustomLoadingButton
                disabled={loadingHdjList}
                loading={false}
                handleClick={() => gotoHDJForm(history)}
                label="Créer une nouvelle HDJ"
            />

            <Spacer width={40} />

            {hdjList && hdjList.length > 0 && (
                <Button
                    disabled={loadingHdjList}
                    onClick={() => setModalCloneOpen(true)}
                    style={{
                        borderWidth: 2,
                        color: baseColors.orange.primary,
                        borderColor: baseColors.orange.primary,
                    }}
                    variant="outlined"
                    size="large"
                >
                    <CustomText
                        style={{ textTransform: 'uppercase' }}
                        fontFamily={baseFontFamily.OpenSansBold}
                        fontSize={baseFontSize.mini}
                        color={baseColors.orange.primary}
                    >
                        Créer à partir d'un modèle
                    </CustomText>
                </Button>
            )}

            {hdjList && (
                <CustomModal title="Choisir un modèle de HDJ" open={modalCloneOpen} toggleModal={setModalCloneOpen}>
                    <Box>
                        <InputField
                            disabled={loading}
                            placeholder="Nom de l'HDJ"
                            label="Taper le nom de l'HDJ"
                            value={hdjClone.name}
                            onChange={(value: any) => {
                                setHdjClone((old) => ({ ...old, name: value }));
                            }}
                            width={'100%'}
                        />
                        <CustomDatePicker
                            disabled={loading}
                            likeSelect
                            value={hdjClone.date}
                            setValue={(value: Date) => setHdjClone((old) => ({ ...old, date: value }))}
                        />
                        <Spacer height={20} />
                        <CustomSelect
                            disabled={loading}
                            items={[
                                { name: 'Sélectionner un HDJ', id: 0 },
                                ...hdjList.filter((item: any) => item.date === null),
                            ]}
                            label=""
                            labelKey={'name'}
                            onChange={(value: any) => setHdjClone((old) => ({ ...old, id: value }))}
                            idSelect="selection-hdj"
                        />
                        <Spacer height={20} />
                        <Box sx={{ justifyContent: 'center', alignItems: 'center', display: 'flex', marginTop: 1 }}>
                            <CustomLoadingButton
                                orange
                                loading={false}
                                handleClick={() => setModalCloneOpen(false)}
                                label="Annuler"
                            />
                            <Spacer width={20} />
                            <CustomLoadingButton
                                loading={loading}
                                disabled={hdjClone.name.length === 0 || hdjClone.id === 0}
                                handleClick={performClone}
                                label="Valider"
                            />
                        </Box>
                    </Box>
                </CustomModal>
            )}
        </Box>
    );
};

export default HdjFooter;
