import { Box } from '@mui/system';
import { FC, useState, useEffect, useContext } from 'react';
import { CustomText } from 'src/components/Text/CustomText';
import { baseFontFamily } from 'src/utils/constants/baseStyles';
import ProgramService from 'src/services/Program/program.service';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import useStyles from './styles';
import { optimizedProgramData, programsWithoutDraft } from './AddProgramLogic';
import ProgramChoice from './ProgramChoice';
import { toast } from 'react-toastify';
import { OperationMessage } from 'src/utils/constants/constants';
import { useHistory } from 'react-router';
import { PatientContext } from 'src/providers/PatientProvider';
import { useFetchUserPrograms } from 'src/hooks/useFetchUserPrograms';
import { useFetchPrograms } from 'src/hooks/ReactQueryHooks/useProgramRQHooks';

interface AddProgramProps {
    setHideEmptyCmpt: Function;
    setUpdate: Function;
}

const AddProgram: FC<AddProgramProps> = (props) => {
    const { setHideEmptyCmpt, setUpdate } = props;
    const classes = useStyles();
    const history = useHistory();

    const [programeId, setProgrameId] = useState<number>(-1);
    const [creating, setCreating] = useState<boolean>(false);
    const { selectedPatient } = useContext(PatientContext);
    const { data: programs, isLoading: fecthingPrograms } = useFetchPrograms({});
    const patientId: number = selectedPatient?.id ?? 0;
    const { refetchPreselectedProgram } = useFetchUserPrograms({ userId: patientId });

    useEffect(() => {
        setHideEmptyCmpt(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const programWithoutDraftItems = programsWithoutDraft(programs ?? []);
    const programItems = optimizedProgramData(programWithoutDraftItems ?? []);

    const disableBtn = programeId === -1 || !patientId;

    const handleAddPatientProgram = () => {
        const finalPayload = {
            programId: programeId,
            attendees: [{ id: patientId, type: 1 }],
        };

        if (patientId > 0) {
            setCreating(true);
            ProgramService.addUserProgram(finalPayload)
                .then((res: any) => {
                    setCreating(false);
                    refetchPreselectedProgram();
                    toast.success(OperationMessage.success);
                    setUpdate((prev: any) => !prev);
                    history.push('addedToNewProgram');
                })
                .catch(() => {
                    toast.error(OperationMessage.error);
                    setCreating(false);
                });
        }
    };

    return (
        <div className={classes.container}>
            <CustomText
                style={{ textAlign: 'center', marginBottom: 25 }}
                fontFamily={baseFontFamily.BreeSerif}
                fontSize={22}
            >
                Ajouter un patient à un programme
            </CustomText>

            <ProgramChoice
                fetchingProgram={fecthingPrograms}
                programItems={programItems}
                setProgrameId={setProgrameId}
            />

            <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 30 }}>
                <CustomLoadingButton
                    disabled={disableBtn || creating}
                    handleClick={handleAddPatientProgram}
                    loading={creating}
                    width={350}
                    label="Inscrire le patient au programme"
                />
            </Box>
        </div>
    );
};

export default AddProgram;
