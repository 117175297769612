import { Dispatch, FC, SetStateAction } from 'react';
import { CustomText } from 'src/components/Text/CustomText';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import CheckIcon from '@mui/icons-material/Check';
import { ColorElementType, ColorType } from 'src/interfaces/types';

type UserThemeColorPalleteProps = {
    isPrimary?: boolean;
    label: string;
    colors: ColorType[];
    selectedTheme: ColorType;
    setSelectedTheme: Dispatch<SetStateAction<ColorType>>;
    setElementColors: Dispatch<SetStateAction<ColorElementType>>;
};

const UserThemeColorPallete: FC<UserThemeColorPalleteProps> = (props) => {
    const { label, colors, selectedTheme, setSelectedTheme, setElementColors, isPrimary } = props;
    const handleChoose = (theme: ColorType) => {
        setSelectedTheme(theme);
        setElementColors((prev: ColorElementType) => {
            prev.btnColors[isPrimary ? 0 : 1].color = theme.value;
            prev.linkColors[isPrimary ? 0 : 1].color = theme.value;
            prev.textColors[isPrimary ? 0 : 1].color = theme.value;
            return { ...prev };
        });
    };

    return (
        <div>
            <CustomText
                style={{ textAlign: 'center' }}
                fontSize={16}
                fontFamily={baseFontFamily.OpenSansSemiBold}
                color={baseColors.black}
            >
                {label}
            </CustomText>

            <div
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 250,
                }}
            >
                {colors?.map((colorItem: ColorType) => (
                    <div
                        key={colorItem.id}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginRight: 1,
                            cursor: 'pointer',
                            borderColor: baseColors.green.pastel,
                            marginBottom: selectedTheme?.id === colorItem.id ? -10 : -30,
                        }}
                        onClick={() => handleChoose(colorItem)}
                    >
                        <p
                            style={{
                                backgroundColor: colorItem.value,
                                height: 50,
                                width: 50,
                            }}
                        />
                        {selectedTheme?.id === colorItem.id && (
                            <CheckIcon
                                style={{
                                    color: baseColors.green.light,
                                    position: 'relative',
                                    marginLeft: 7,
                                    marginTop: -60,
                                    fontSize: 40,
                                }}
                            />
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default UserThemeColorPallete;
