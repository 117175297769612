function SvgDejeunerIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={41}
      height={41}
      fill="none"
      viewBox="0 0 41 41"
      {...props}
    >
      <path
         fill={props.fill}
        d="M20.5 9.61c-6.005 0-10.89 4.885-10.89 10.89 0 6.005 4.885 10.89 10.89 10.89 6.005 0 10.89-4.885 10.89-10.89 0-6.005-4.885-10.89-10.89-10.89zm0 20.5a9.61 9.61 0 119.61-9.61 9.62 9.62 0 01-9.61 9.61zM3.844 7.98a.64.64 0 00.64-.641V3.495a.64.64 0 00-1.28 0V7.34a.64.64 0 00.64.64zm2.562 0a.64.64 0 00.64-.641V3.495a.64.64 0 00-1.28 0V7.34a.64.64 0 00.64.64z"
      />
      <path
        fill={props.fill}
        d="M35.875 2.563a3.848 3.848 0 00-3.844 3.843V8.62a3.846 3.846 0 001.922 3.329v2.544C31.65 9.354 26.486 5.766 20.5 5.766S9.35 9.354 7.047 14.493v-2.547A3.845 3.845 0 008.969 8.62V3.495a.64.64 0 10-1.281 0V8.62a2.562 2.562 0 11-5.125 0V3.495a.64.64 0 10-1.282 0V8.62a3.845 3.845 0 001.922 3.326v19.737a1.922 1.922 0 003.844 0v-5.176c2.303 5.139 7.467 8.727 13.453 8.727s11.15-3.588 13.453-8.727v5.176a1.922 1.922 0 003.844 0V11.949a3.844 3.844 0 001.922-3.329V6.406a3.849 3.849 0 00-3.844-3.843zm-30.11 29.12a.64.64 0 11-1.28 0V12.409a3.8 3.8 0 001.28 0v19.274zm14.735 2.27c-7.418 0-13.453-6.035-13.453-13.453 0-7.418 6.035-13.453 13.453-13.453 7.418 0 13.453 6.035 13.453 13.453 0 7.418-6.035 13.453-13.453 13.453zM38.438 8.62a2.563 2.563 0 01-1.538 2.35.642.642 0 00-.384.587v20.127a.64.64 0 01-1.282 0V11.556a.641.641 0 00-.384-.587 2.564 2.564 0 01-1.538-2.349V6.406a2.563 2.563 0 015.126 0V8.62z"
      />
      <path
        fill={props.fill}
        d="M35.875 4.776a.641.641 0 000 1.282.641.641 0 01.64.64V7.98a.641.641 0 01-.64.641.64.64 0 100 1.281 1.924 1.924 0 001.922-1.922V6.7a1.924 1.924 0 00-1.922-1.923z"
      />
    </svg>
  )
}

export default SvgDejeunerIcon
