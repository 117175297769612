import { Grid } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { CustomSelect } from 'src/components/InputField/CustomSelect';
import { InputField } from 'src/components/InputField/InputField';
import { Spacer } from 'src/components/Spacer/Spacer';
import { CustomText } from 'src/components/Text/CustomText';
import { getRooms } from 'src/services/Rooms';

interface EventRoomAndRemarkProps {
    setConsultation?: any;
    loadingData?: any;
    isVisio?: boolean;
    consultationData?: any;
}

const EventRoomAndRemark: FC<EventRoomAndRemarkProps> = (props) => {
    const { setConsultation, loadingData, isVisio, consultationData } = props;
    const [rooms, setRooms] = useState([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [remark, setRemark] = useState<string>(consultationData?.remark ?? '');
    const handleFecthData = () => {
        setLoading(true);
        getRooms().then((response) => {
            const responses = response.data.data.items;
            setRooms(responses);
            setLoading(false);
        });
    };

    useEffect(() => {
        handleFecthData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const roomsData = [{ id: 0, name: 'Sélectionner une salle' }, ...rooms];
    const defaultRoom = consultationData?.location?.id ?? undefined;

    console.log('here', consultationData);

    return (
        <>
            {!isVisio && (
                <>
                    <Grid container direction="row" alignItems={'center'} justifyContent={'space-between'}>
                        <Grid item>
                            <CustomText> Salle :</CustomText>
                        </Grid>
                        <Grid item style={{ width: '60%' }}>
                            {loading ? (
                                loadingData
                            ) : (
                                <CustomSelect
                                    onChange={(value: any) =>
                                        setConsultation((old: any) => ({ ...old, roomId: Number(value) }))
                                    }
                                    label="salle"
                                    items={roomsData}
                                    labelKey="name"
                                    defaultValue={defaultRoom}
                                    idSelect="selection-salle"
                                    width="100%"
                                />
                            )}
                        </Grid>
                    </Grid>

                    <Spacer />
                </>
            )}

            <Grid container direction="row" alignItems={'self-start'} justifyContent="space-between">
                <Grid item>
                    <CustomText> Remarques :</CustomText>
                </Grid>
                <Grid item style={{ marginBottom: 10 }}>
                    <InputField
                        height={100}
                        label=""
                        placeholder={'Taper votre texte'}
                        onChange={(value: any) => {
                            setRemark(value);
                            setConsultation((old: any) => ({ ...old, remark: value }));
                        }}
                        width={360}
                        value={remark}
                        idSuffix="remarque"
                        multiline={true}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default EventRoomAndRemark;
