import React,{useState} from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import IvehteCptsLogo from 'src/components/IvehteCptsLogo/IvehteCptsLogo';
import { ROUTES } from 'src/routes/routes';
import { CustomText } from 'src/components/Text/CustomText';
import { baseColors, baseFontFamily, baseFontSize } from 'src/utils/constants/baseStyles';
import IconMail from './../../components/CustomIcon/IconMail';
import { Spacer } from './../../components/Spacer/Spacer';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import ContactAdminModal from 'src/views/Login/ContactAdminModal';
import { useHistory } from 'react-router';

const MailVerification = () => {
    const history = useHistory();
    const [openContactAdminModal, setOpenContactModal] = useState(false);
    const toggleContactAdminModal = () => setOpenContactModal((prev) => !prev);
    const performLogin = () => {
        history.push('/loadingInitialPage');
    };

    return (
        <>
        <Grid>
            <Link to={ROUTES.login}>
                <Box style={{ padding: 10 }}>
                    <IvehteCptsLogo width={105} height={80} />
                </Box>
            </Link>
            <Grid
                container
                justifyContent={'center'}
                justifyItems={'center'}
                alignItems={'center'}
                alignContent={'center'}
            >
                <Grid
                    item
                    sm={8}
                    xs={8}
                    md={4}
                    style={{
                        borderRadius: 10,
                        padding: 20,
                        background: baseColors.green.light,
                        maxWidth: '50%',
                    }}
                >
                    <Grid style={{ textAlign: 'center', marginTop: 20 }}>
                        <IconMail bg={baseColors.green.primary}/>
                    </Grid>
                    <Spacer height={50} />
                    <CustomText fontFamily={baseFontFamily.BreeSerif} fontSize={22} style={{ textAlign: 'center' }}>
                        Vérifiez votre boîte mail !
                    </CustomText>
                    <Spacer height={20} />
                    <div style={{ marginLeft: 80, marginRight: 80 }}>
                        <CustomText
                            style={{ textAlign: 'center' }}
                            fontFamily={baseFontFamily.OpenSansRegular}
                            fontSize={baseFontSize.sl}
                        >
                            Nous vous avons envoyé un email avec les instructions pour réinitialiser votre mot de passe.
                        </CustomText>
                    </div>
                    <Spacer height={30} />
                    <Grid style={{ textAlign: 'center' }}>
                        <CustomLoadingButton handleClick={performLogin} width={223} label="RETOUR" />
                    </Grid>
                    <Spacer height={90} />
                    <Grid style={{ textAlign: 'center' }}>
                        <CustomText
                            fontFamily={baseFontFamily.OpenSansSemiBold}
                            fontSize={13}
                            color={baseColors.green.primary}
                        >
                            Vous n’avez pas reçu d’email ?
                        </CustomText>
                        <CustomText
                            fontFamily={baseFontFamily.OpenSansSemiBold}
                            fontSize={13}
                            color={baseColors.green.primary}
                            style={{ textDecoration: 'underline' }}
                            
                        >
                            <Typography 
                                onClick={toggleContactAdminModal} 
                                style={{
                                    cursor:"pointer",
                                    fontSize: "13px",
                                    fontWeight:"600",
                                    textDecoration: "none"
                                }
                            }>
                                Vérifiez vos spams ou contactez-nous !
                            </Typography>
                        </CustomText>
                    </Grid>
                    <Spacer height={20} />
                </Grid>
            </Grid>
        </Grid>
        <ContactAdminModal
            openContactAdminModal={openContactAdminModal}
            toggleContactAdminModal={toggleContactAdminModal}
        />
        </>
    );
};

export default MailVerification;
