import { 
  RETRIEVE_EVENTS, 
  RETRIEVE_EVENT_INFORMATIONS, 
  RETRIEVE_PLANNING_EVENTS 
} from "src/actions/calendar/types";

const initialState: any = [];

const calendarEventReducer = (events = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case RETRIEVE_EVENTS:
      return payload;

    default:
      return events;
  }
};

const planningEventReducer = (plannings = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case RETRIEVE_PLANNING_EVENTS:
      return payload;

    default:
      return plannings;
  }
};

const eventInformationReducer = (informations = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case RETRIEVE_EVENT_INFORMATIONS:
      return payload;

    default:
      return informations;
  }
};

export {
  calendarEventReducer, 
  planningEventReducer,
  eventInformationReducer
};