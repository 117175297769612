import { FC, useEffect, useState } from 'react';
import useStyles from './styles';
import { Route, Switch, useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import EvaluationQuizz from './EvaluationQuizz/EvaluationQuizz';
import { getPatientObservations } from '../../../../services/Patients';
import { Patient } from 'src/interfaces/interfaces';
import { useFetchUserPrograms } from 'src/hooks/useFetchUserPrograms';
import { AXIS_IDENTIFICATION } from 'src/utils/constants/enums';
import EvaluationGoalChart from './EvaluationObjectifs/EvaluationGoalChart';
import { EvaluationTab } from './EvaluationTab/EvaluationTab';
import EvaluationAxisContent from './EvaluationAxisContent/EvaluationAxisContent';
import EvaluationReport from '../EducatifDiagnostic/EvaluationReport/EvaluationReport';

interface EvaluationsProps {
    patientInfo?: Patient;
    defaultPatient?: any;
    isActor?: any;
    isInterview?:boolean;
}

export const Evaluations: FC<EvaluationsProps> = (props) => {
    const {isInterview} = props;
    const classes = useStyles();
    const { path } = useRouteMatch();
    const params: any = useParams();
    const userId: number = +params?.id || 0;
    const history = useHistory();
    const { pathname } = useLocation();

    const [selectedAxis, setSelectedAxis] = useState<number>(1);
    const [fetchingObservations, setFetchingObservations] = useState(false);

    const [updating, setUpdating] = useState(false);
    const [observations, setObservations] = useState<any[]>([]);

    const patientId: number = params?.id ?? 0;
    const { fetchUserPrograms } = useFetchUserPrograms({ userId: +patientId });

    useEffect(() => {
        fetchUserPrograms();
        fetchObservation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patientId, updating]);

    const fetchObservation = () => {
        setFetchingObservations(true);
        getPatientObservations(patientId)
            .then((response) => {
                const observationsData = response.data.data;
                if (observationsData?.length > 0) {
                    setObservations(observationsData);
                }
                setFetchingObservations(false);
            })
            .catch(() => {
                setFetchingObservations(false);
            });
    };

    useEffect(() => {
        history.push(`${pathname}/apa`);
    }, [isInterview]);

    return (
        <div className={classes.container}>
            <EvaluationTab setSelectedAxis={setSelectedAxis} />

            <div
                style={{
                    height: 'calc(100vh - 350px)',
                    paddingTop: 15,
                }}
                className={classes.content}
            >
                <Switch>
                    <Route path={`${path}/apa`}>
                        <EvaluationAxisContent
                            patientId={patientId}
                            observations={observations}
                            loading={fetchingObservations}
                            axisId={AXIS_IDENTIFICATION.APA}
                            setUpdating={setUpdating}
                            isInterview={isInterview}
                        />
                    </Route>
                    <Route path={`${path}/dietetic`}>
                        <EvaluationAxisContent
                            patientId={patientId}
                            observations={observations}
                            loading={fetchingObservations}
                            axisId={AXIS_IDENTIFICATION.DIET}
                            setUpdating={setUpdating}
                            isInterview={isInterview}
                        />
                    </Route>
                    <Route path={`${path}/psychologique`}>
                        <EvaluationAxisContent
                            patientId={patientId}
                            observations={observations}
                            loading={fetchingObservations}
                            axisId={AXIS_IDENTIFICATION.PSYCHO}
                            setUpdating={setUpdating}
                            isInterview={isInterview}
                        />
                    </Route>
                    <Route path={`${path}/medical`}>
                        <EvaluationAxisContent
                            patientId={patientId}
                            observations={observations}
                            loading={fetchingObservations}
                            axisId={AXIS_IDENTIFICATION.MEDICAL}
                            setUpdating={setUpdating}
                            isInterview={isInterview}
                        />
                    </Route>
                    <Route path={`${path}/goal`}>
                        <EvaluationGoalChart patientId={userId} selectedAxis={selectedAxis} />
                    </Route>
                    <Route path={`${path}/quizz`}>
                        <EvaluationQuizz patientId={userId} />
                    </Route>

                    {!isInterview && 
                        <Route path={`${path}/compterendu`}>
                            <EvaluationReport />
                        </Route>
                    }
                    
                    <Route path="*"></Route>
                </Switch>
            </div>
        </div>
    );
};
