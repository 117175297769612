import { RootState } from './../../stores/store';
import { ThunkAction } from 'redux-thunk';
import DashboardDataService from 'src/services/Dashboard/dashboard.service';
import { DashboardActionType } from './types';
import { AnyAction } from 'redux';

export const retrieveMyDayToNumber = (): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
    try {
        const data = await DashboardDataService.getMyDayToNumber();
        dispatch({
            type: DashboardActionType.MY_DAY_TO_NUMBER,
            payload: data,
        });
    } catch (err) {
        console.log('RetrieveMyDayToNumber Error', err);
    }
};

export const retrieveDailyPatients =
    (setPatientOfTheDayLoading?: Function): ThunkAction<void, RootState, unknown, AnyAction> =>
    async (dispatch) => {
        setPatientOfTheDayLoading?.(true);
        try {
            const data = await DashboardDataService.getDailyPatients();

            if (data) {
                setPatientOfTheDayLoading?.(false);
            }

            dispatch({
                type: DashboardActionType.DAILY_PATIENTS,
                payload: data,
            });
        } catch (err) {
            console.log('RetrieveDailyPatients Error', err);
            setPatientOfTheDayLoading?.(false);
        }
    };

export const retrieveDailyPatientsByDate =
    (selectDate?: string, setPatientOfTheDayLoading?: Function): ThunkAction<void, RootState, unknown, AnyAction> =>
    async (dispatch) => {
        setPatientOfTheDayLoading?.(true);
        try {
            const data = await DashboardDataService.getDailyPatientsByDate(selectDate);

            if (data) {
                setPatientOfTheDayLoading?.(false);
            }

            dispatch({
                type: DashboardActionType.DAILY_PATIENTS_BY_DATE,
                payload: data,
            });
        } catch (err) {
            console.log('RetrieveDailyPatients Error', err);
            setPatientOfTheDayLoading?.(false);
        }
    };

export const retrieveProgrammedWorkshops =
    (setLoadingWorkshops: any, threeWeeksView: boolean): ThunkAction<void, RootState, unknown, AnyAction> =>
    async (dispatch) => {
        setLoadingWorkshops(true);
        try {
            const data = await DashboardDataService.getProgrammedWorkshops(threeWeeksView);
            dispatch({
                type: DashboardActionType.PROGRAMMED_WORKSHOPS,
                payload: data,
            });
            if (data) {
                setLoadingWorkshops(false);
            }
        } catch (err) {
            setLoadingWorkshops(false);
            console.log('RetrieveProgrammedWorkshops Error', err);
        }
    };

export const retrieveMyScheduledWorkshops =
    (selectDate?: string, setLoadingWorkshops?: any): ThunkAction<void, RootState, unknown, AnyAction> =>
    async (dispatch) => {
        setLoadingWorkshops?.(true);
        try {
            const data = await DashboardDataService.getMyScheduledWorkshops(selectDate);
            dispatch({
                type: DashboardActionType.MY_SCHEDULED_WORKSHOPS,
                payload: data,
            });

            if (data) {
                setLoadingWorkshops?.(false);
            }
        } catch (err) {
            console.log('RetrieveMyScheduledWorkshops Error', err);
            setLoadingWorkshops?.(false);
        }
    };

export const retrieveAppointments =
    (selectedDate: string, setPatientOfTheDayLoading: any): ThunkAction<void, RootState, unknown, AnyAction> =>
    async (dispatch) => {
        setPatientOfTheDayLoading(true);
        try {
            const data = await DashboardDataService.getAppointments(selectedDate);

            if (data) {
                setPatientOfTheDayLoading(false);
            }
            dispatch({
                type: DashboardActionType.APPOINTMENTS,
                payload: data,
            });
        } catch (err) {
            console.log('RetrieveAppointments Error', err);
            setPatientOfTheDayLoading(false);
        }
    };
