import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { CustomSelect } from 'src/components/InputField/CustomSelect';
import { knowledges } from 'src/utils/constants/constants';
import useStyles from './styles';
import { HeaderPayload } from 'src/interfaces/types';
import { InputField } from 'src/components/InputField/InputField';
import { isValidQuestion } from 'src/utils/helpers/InputValidator';
import { CustomText } from 'src/components/Text/CustomText';
import QuizService from 'src/services/Quiz';
import ProgramChoice from 'src/views/Patients/PatientsDetails/Programs/AddProgam/ProgramChoice';
import { Grid, Box } from '@mui/material';
import { useFetchPrograms } from 'src/hooks/ReactQueryHooks/useProgramRQHooks';
import {
    optimizedProgramData,
    programsWithoutDraft,
} from 'src/views/Patients/PatientsDetails/Programs/AddProgam/AddProgramLogic';
import ListItemFormCheckBox from 'src/components/ListItemFormCheckBox';
import { baseFontFamily, baseFontSize, baseScreenHeight } from 'src/utils/constants/baseStyles';
import { searchElementByName } from 'src/utils/utils';
import { Spacer } from 'src/components/Spacer/Spacer';

type QuizItemHeaderProps = {
    headerPayload : HeaderPayload;
    updateHeaderInput: boolean;
    setHeaderPayload: Dispatch<SetStateAction<HeaderPayload>>;
};

const initialOptionValue = {
    programIds: [],
    knowledgeId: 0,
};

const QuizItemHeader: FC<QuizItemHeaderProps> = (props) => {
    const { setHeaderPayload, updateHeaderInput, headerPayload } = props;
    const classes = useStyles();
    const [value, setValue] = useState<string>('');
    const [programIds, setProgramIds] = useState<number[]>([]);
    const [categories, setCategories] = useState<any>([]);
    const [searchkey, setSearchkey] = useState<string>('');
    const [searchedItems, setSearchedItems] = useState<Array<any>>([]);
    const [selectedFilterIds, setSelectedFilterIds] = useState<Array<number>>([]);

    const { data: programs, isLoading: fecthingPrograms } = useFetchPrograms({});
    const programWithoutDraftItems = programsWithoutDraft(programs ?? []);
    const programItems = optimizedProgramData(programWithoutDraftItems ?? []);

    const [loading, setLoading] = useState(false);

    const handleChange = (text: string) => {
        setValue(text);
        setHeaderPayload((prev: HeaderPayload) => ({ ...prev, title: text }));
    };
    const knowledgeData = [{ id: 0, label: ' Sélectionner un type de savoir' }, ...knowledges];
    
    const handleChangeKnowledge = (knowledgeId: number) => {
        const knowledge: any = knowledgeData?.find((item: any) => item?.id === knowledgeId);
        let category = categories.find((item: any) => item.id === knowledgeId);
        setHeaderPayload((prev: HeaderPayload) => ({ ...prev, quizzType: category?.id ?? 0 }));
    };

    const handleSearchProgram = (value: string) => setSearchkey(value);

    useEffect(() => {
        setSearchedItems(searchElementByName(programItems ?? [], searchkey, 'label'));
    }, [searchkey]);

    const finalData = searchkey?.trim()?.length > 0 ? searchedItems : programItems;
    const dataWithFilter =
        selectedFilterIds?.length > 0 ? finalData?.filter((item) => selectedFilterIds?.includes(item.id)) : finalData;

    useEffect(()=>{
        if(headerPayload?.title) setValue(headerPayload?.title)
    },[])

    const fetchAllCategories = () => {
        setLoading(true);
        QuizService.getCategories()
            .then((response) => {
                const data = response.data.data?.items;
                if (data) {
                    setCategories(data);
                }
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchAllCategories();
    }, []);

    useEffect(() => {
        setValue('');
    }, [updateHeaderInput]);

    useEffect(() => {
        setHeaderPayload((prev: HeaderPayload) => ({ ...prev, programIds }));
    }, [programIds]);

    return (
        <>
            <Spacer />



            <Spacer />



            <Grid container spacing={2}>
                <Grid item xs={11} md={11} style={{display:"flex", flexDirection:"row",alignItems:"center"}}>
                    <CustomText style={{marginBottom:"20px"}}>Label du quiz :</CustomText>
                    <div className={classes.quizInput} >
                        <InputField
                            multiline
                            label=""
                            disabled={false}
                            validator={isValidQuestion}
                            errorMessage="Question trop court"
                            placeholder="Taper votre texte"
                            height={50}
                            width="100%"
                            onChange={handleChange}
                            value={headerPayload?.title}
                        />
                    </div>
                </Grid>

                <Grid item xs={11} sm={11} md={6} lg={6}>
                    <Grid lg={11} alignItems={'center'}>
                        <Grid item>
                            <CustomText style={{ minWidth: '200px' }}>Type de savoir :</CustomText>
                        </Grid>
                        <Grid item>
                            <div>
                                <CustomSelect
                                    onChange={(value: number) => handleChangeKnowledge(value)}
                                    label=""
                                    labelKey="label"
                                    items={knowledgeData}
                                    defaultValue={headerPayload?.quizzType}
                                    idSelect="quiz-knowledge-type"
                                    width="100%"
                                />
                            </div>
                        </Grid>
                    </Grid> 
                </Grid>

                <Grid item xs={11} sm={11} md={6} lg={6}>
                    <Grid lg={11} alignItems={'center'}>
                        <Grid item>
                            <CustomText style={{ minWidth: '200px' }}>Programme(s) :</CustomText>
                        </Grid>
                        <Grid item style={{ width: '100%' }}>
                            <ListItemFormCheckBox
                                minHeight={baseScreenHeight.checkBoxItems}
                                selectedRows={new Set(headerPayload?.programIds)}
                                handleChange={(value: any) =>
                                    setProgramIds(Array.from(value))
                                }
                                items={dataWithFilter ?? []}
                                loading={fecthingPrograms}
                                labelKey="label"
                                handleSearch={(value: string) => handleSearchProgram(value)}
                                maxItems={programItems?.length}
                                /*selectedFilterIds={[1,2]}
                                setSelectedFilterIds={setSelectedFilterIds}*/
                            />
                        </Grid>
                    </Grid> 
                </Grid>
            </Grid>
        </>
    );
};

export default QuizItemHeader;
