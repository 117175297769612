import { FC, useContext, useEffect, useState } from 'react';
import useStyles from './styles';
// @ts-ignore: Unreachable code error
import ReactWeeklyDayPicker from 'src/components/WeeklyDayPickerMultilangue';
import { Box } from '@mui/material';
import { DashboardContext } from 'src/providers/DashboardProvider';

interface DashboardWeeklyDayPickerProps {
    daysCount?: number;
    setSelectedDate?: any;
    handleDateChanged?: any;
}

const DashboardWeeklyDayPicker: FC<DashboardWeeklyDayPickerProps> = (props) => {
    const { daysCount, setSelectedDate, handleDateChanged } = props;
    const classes = useStyles();
    const [defaultSelectedDate, setdefaultSelectedDate] = useState<any>(new Date());
    const [clickedDay, setClickedDay] = useState('');
    const [startDay, setStartDay] = useState<any>(new Date());
    const { setCurrentDate } = useContext(DashboardContext);

    useEffect(() => {
        handleDateChanged && handleDateChanged(defaultSelectedDate.toISOString().substring(0, 10));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleEditDate = async (day: string) => {
        setClickedDay(day);
        setSelectedDate && setSelectedDate(day);
        setdefaultSelectedDate(day);
        setCurrentDate(new Date(day));
    };

    return (
        <Box className={classes.container}>
            <ReactWeeklyDayPicker
                daysCount={daysCount ?? 3} //How many days will be shown
                startDay={startDay} // First day as Date Object or 22 June 2016
                selectedDays={[defaultSelectedDate]}
                multipleDaySelect={false} //enables multiple day selection
                selectDay={function (day: any) {
                    handleEditDate(day[0]);
                    // handleDateChanged && handleDateChanged(day[0]);
                }}
                unselectDay={function (day: any) {}}
                onPrevClick={function (startDay: any, selectedDays: any) {
                    setStartDay(startDay?._d);
                }} // called with the new startDay
                onNextClick={function (startDay: any, selectedDays: any) {
                    setStartDay(startDay?._d);
                }} // called with the new startDay
                unselectable={false} // if true allows to unselect a date once it has been selected. Only works when multipleDaySelect={false}
                format={'YYYY-MM-DD'} //format of dates that handled in selectDay and unselectDay functions
                firstLineFormat={'ddd'} // format for the first line of the day button
                secondLineFormat={'D'} // format for the second line of the day button
                firstLineMobileFormat={'dddd'} // format for the first line of the day button mobile
                secondLineMobileFormat={'D'} // format for the second line of the day button mobile
                thirdLineMobileFormat={'MMM'}
                unavailables={{
                    dates: [], //unavailable dates list
                    relative: [], //unavailable dates list relative to today (0:today, 1:tomorrow, -1:yesterday)
                    weekly: [], //unavailable dates list for each week (0:Sunday, 1:Monday ...)
                }}
                mobilView={false} // enables mobil view
                beforeToday={true} // all dates before today set as unavailable (default:true)
                hiddens={{
                    // makes dates invisible
                    dates: [], //absolute dates list
                    relative: [], // relative to today (0:today, 1:tomorrow, -1:yesterday)
                    weekly: [], //each week (0:Sunday, 1:Monday ...)
                }}
            />
        </Box>
    );
};

export default DashboardWeeklyDayPicker;
