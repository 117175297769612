import { Grid } from "@mui/material"
import { FC, useState } from "react"
import { baseColors, baseFontFamily, baseFontSize } from "src/utils/constants/baseStyles"
import { CustomText } from "../Text/CustomText"
import CustomModal from 'src/components/CustomModal';
import QuestionPrecision from "./QuestionPrecision";
import { UserAxisItem } from "src/interfaces/interfaces";
import CustomLoadingButton from "../Button/CustomLoadingButton";

interface QuestionCardItemProps {
    icon: JSX.Element;
    title: string;
    setPayload : Function;
    index: number;
    value: any;
    isActive: boolean;
    id: number;
    hasPrécision: boolean;
    userDEResponse: UserAxisItem;
    precisionAnswer: number | string | undefined;
}
const QuestionCardItem : FC<QuestionCardItemProps> = ({icon, title, userDEResponse, setPayload, index, isActive, hasPrécision, precisionAnswer, id}) => {
    const [openModalPrecision, setOpenModalPrecision] = useState<boolean>(false)

    const updatePayload = (value: number) => {
        setPayload((prev: any) => {
            const updatedPayload = { ...prev };
            if (updatedPayload.responses && updatedPayload.responses.length > index) {
                updatedPayload.responses[index].responseValue = value;
            }
            return updatedPayload; // Return the updated state
        });
        setOpenModalPrecision(!openModalPrecision)
    }

    return ( 
        <>
        <Grid
            style={{ 
                marginTop: 20, 
                marginBottom: 10, 
                cursor: 'pointer',
                backgroundColor: isActive ? baseColors.green.primary : baseColors.white,
                padding:'5px',
                borderRadius: '5px'
            }}
            container
            direction="column"
            alignItems={'center'}
            justifyContent="space-between"
            onClick={() => setOpenModalPrecision(!openModalPrecision)}
            >
            <Grid item>
                {icon}
            </Grid>
            <Grid item>
            <CustomText
                    style={{ textAlign: 'left' }}
                    fontSize={baseFontSize.mini}
                    color={isActive ? baseColors.white : baseColors.green.primary}
                    fontFamily={baseFontFamily.OpenSansSemiBold}
                >
                {title}
            </CustomText>
            </Grid>
        </Grid>
        {hasPrécision &&
            <CustomModal
                width={300}
                height={200}
                title="Ajouter une précision"
                open={openModalPrecision}
                toggleModal={() => setOpenModalPrecision(!openModalPrecision)}
                footer={
                    <CustomLoadingButton
                        label="Valider"
                        handleClick={() => updatePayload(id)}
                    />
                }
            >
                <Grid item>
                    {<QuestionPrecision
                        precisionAnswer={precisionAnswer}
                        index={index} 
                        setPayload={setPayload} 
                        userDEResponse={userDEResponse}
                    />}
                </Grid>
            </CustomModal>
        }
        </>
    )
}

export default QuestionCardItem