import { makeStyles } from '@mui/styles';
import { baseColors } from 'src/utils/constants/baseStyles';

const useStyles = makeStyles({
    container: {
        backgroundColor: baseColors.green.light,
    },
    content: {
        padding: 20,
    },
});

export const sxStyles : any = {
    intervenantContainer: {
        backgroundColor: baseColors.green.light,
        padding: 15,
        borderRadius: 7,
        cursor: 'pointer',
    }
}

export default useStyles;
