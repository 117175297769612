import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import CustomModal from 'src/components/CustomModal';
import { SpecialityItem } from 'src/interfaces/interfaces';
import { sxStyles } from '../styles';
import { CustomText } from 'src/components/Text/CustomText';
import { baseFontFamily, baseScreenHeight } from 'src/utils/constants/baseStyles';
import { Spacer } from 'src/components/Spacer/Spacer';
import { defaultSpecialityPaylopad, handleSetSpecialityPayload } from '../Speciality.utils';
import { PostSpecialitiesPayload } from 'src/services/Speciality.service';
import { SpecialityFormFooter } from '../SpecialityForms/SpecialityFormFooter';
import { InputField } from 'src/components/InputField/InputField';
import { isNotBlank } from 'src/utils/helpers/InputValidator';

type IProps = {
    speciality: SpecialityItem;
    setSpeciality: Dispatch<SetStateAction<SpecialityItem>>;
    open: boolean;
    toggleModal: any;
};

export const SpecialityModal: FC<IProps> = (props) => {
    const { setSpeciality, open, toggleModal, speciality } = props;
    const [payload, setPayload] = useState<PostSpecialitiesPayload>(defaultSpecialityPaylopad);
    const clearFormData = () => setPayload(defaultSpecialityPaylopad);

    useEffect(() => {
        if (speciality?.id > 0) {
            handleSetSpecialityPayload({ setPayload, speciality });
        }
        // speciality?.duration === 1 ? setHideDuration(true) : setHideDuration(false);
    }, [speciality]);

    const title = speciality?.id > 0 ? 'Modifier la spécialité' : 'Créer une nouvelle spécialité';

    return (
        <CustomModal
            disableBackdropClick
            title={title}
            open={open}
            toggleModal={toggleModal}
            footer={
                <SpecialityFormFooter
                    speciality={speciality}
                    setPayload={setPayload}
                    setSpeciality={setSpeciality}
                    payload={payload}
                    toggleModal={toggleModal}
                />
            }
            width={700}
            // height={baseScreenHeight.modalForm}
            clearFormData={clearFormData}
        >
            <Spacer />

            <div style={sxStyles.left}>
                <CustomText fontFamily={baseFontFamily.OpenSansSemiBold} style={{ width: 270 }}>
                    Intitulé de la spécialité :
                </CustomText>

                <div style={{ width: '100%', marginBottom: -20 }}>
                    <InputField
                        label=""
                        width="100%"
                        value={payload.name}
                        onChange={(text: any) => setPayload((prev) => ({ ...prev, name: text }))}
                        placeholder="Intitulé de la spécialité"
                        validator={isNotBlank}
                    />
                </div>
            </div>

            <Spacer />

            <div style={sxStyles.left}>
                <CustomText fontFamily={baseFontFamily.OpenSansSemiBold} style={{ width: 270 }}>
                    Abréviation :
                </CustomText>

                <div style={{ width: '100%', marginBottom: -20 }}>
                    <InputField
                        label=""
                        width="100%"
                        value={payload.abbreviation}
                        onChange={(text: any) => setPayload((prev) => ({ ...prev, abbreviation: text }))}
                        placeholder="Abréviation"
                        //validator={isNotBlank}
                    />
                </div>
            </div>

            <Spacer height={50} />
        </CustomModal>
    );
};
