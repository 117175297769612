export type AddWorkshop = {
    name: string;
    duration: string;
    consultationTypeId: number;
    attendees: Array<any>;
    roomId?: number | undefined;
    remark?: string;
    dayHospitalId: number | null;
    axisId: number;
    isDraft: number;
    goalsIds?: Array<any>;
};

const valideAddWorkshopForm = (payload: AddWorkshop) => {
    if (
        payload.name?.trim().length >= 3 &&
        payload.duration !== '' &&
        payload.duration !== '00:00' &&
        payload.consultationTypeId > 0
        //payload.attendees?.length > 0 &&
        // payload.axisId > 0
    ) {
        if (
            (payload.consultationTypeId === 1 && payload.roomId && payload.roomId > 0) ||
            payload.consultationTypeId === 2
        ) {
            return true;
        } else {
            return false;
        }
    }

    return false;
};

const valideAddDraftWorkshopForm = (payload: AddWorkshop) => {
    if (
        payload.name?.trim().length >= 3 ||
        (payload.duration !== '' && payload.duration !== '00:00') ||
        payload.attendees?.length > 0 ||
        payload.roomId != 0 ||
        (payload?.remark && payload?.remark?.trim().length > 0 ) ||
        (payload?.goalsIds && payload?.goalsIds?.length > 0 )
        //payload.consultationTypeId > 0
        //payload.attendees?.length > 0 &&
        // payload.axisId > 0
    ) {
        return true;
    }

    return false;
};

export { valideAddWorkshopForm,valideAddDraftWorkshopForm };
