import { Box, Typography } from '@mui/material';
import { Dispatch, FC, SetStateAction, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PatientCardItem } from 'src/components/Cards/PatientItemCard';
import { SearchInputField } from 'src/components/InputField/SearchInputField';
import PatientListLoader from 'src/components/PatientPageLoader/PatientListLoader';
import { CustomText } from 'src/components/Text/CustomText';
import { PatientContext } from 'src/providers/PatientProvider';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import CustomDatePicker from 'src/components/CustomDatePicker';
import useStyles from './styles';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { UserCoActorStatus, userCoActorStatusDefaultAccepted, UserContext } from 'src/providers/UserProvider';
import { Spacer } from 'src/components/Spacer/Spacer';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import { SearchElementByBirthDate, fullName, isNull, searchElementByName } from 'src/utils/utils';
import { EducationalDiagnosisContext } from 'src/providers/EducationalDiagnosisProvider';
import { Patient } from 'src/interfaces/interfaces';

interface PatientListCptsProps {
    onSelectedPatient?: Function;
    defaultActivePatient: number;
    onPatientListLoaded?: Function;
    setListPatient?: any;
    setIsCoActor?: any;
    loading: boolean;
    setLoading: any;
    setFetchingPatientData: Dispatch<SetStateAction<boolean>>;
    selectedPatient: any;
    patients: any[];
}

const PatientListCpts: FC<PatientListCptsProps> = (props) => {
    const { loading, patients, onSelectedPatient, setIsCoActor, selectedPatient, setLoading, setListPatient } = props;
    const classes = useStyles();
    const history = useHistory();
    const { setCurrentLocationPathname, setCurrentPatientId, setSelectedPatient, setJustAddedPatient } =
        useContext(PatientContext);
    const { userInfos: userInfosFromContext, isDoctor, setUserCoActorStatus } = useContext(UserContext);
    const { setActiveDefaultTab } = useContext(EducationalDiagnosisContext);
    const [dateFilter, setDateFilter] = useState<any>(null);
    const [clearDate, setClearDate] = useState<boolean>(false);
    const data = patients;

    const handleClearDate = () => {
        setDateFilter(null);
        setClearDate((prev: boolean) => !prev);
    };

    const handleClickPatientCard = (patient: Patient, _userCoActorStatus: UserCoActorStatus) => () => {
        setUserCoActorStatus(_userCoActorStatus);
        if (patient?.id) {
            onSelectedPatient?.(patient);
            setIsCoActor(patient);
            setSelectedPatient(patient);
            setCurrentPatientId(patient?.id);
            history.push(`/patients/list/${patient?.id}/profile`);
        }
        setActiveDefaultTab(false);
        setCurrentLocationPathname(history.location.pathname);
    };

    const handleSetDate = (value: any) => {
        if (value !== null) {
            let date = `${value.getFullYear()}-${('0' + (value.getMonth() + 1)).slice(-2)}-${(
                '0' + value.getDate()
            ).slice(-2)}`;
            const isValidDate = /^\d{4}-\d{2}-\d{2}$/.test(date);
            if (isValidDate) {
                setDateFilter(date);
            }
        }
    };

    const createNewPatient = () => {
        setSelectedPatient({} as Patient);
        setJustAddedPatient(null);
        history.push('/patients/add');
    };

    const [searchkey, setSearchkey] = useState<string>('');
    const [searchedItems, setSearchedItems] = useState<Array<any>>([]);
    const handleSearch = (value: string) => setSearchkey(value);

    useEffect(() => {
        setSearchedItems(searchElementByName(data ?? [], searchkey, 'fullName'));
    }, [searchkey, data]);

    useEffect(() => {
        setSearchedItems(SearchElementByBirthDate(data ?? [], dateFilter));
    }, [dateFilter, data]);

    const finalData =
        searchkey?.trim()?.length > 0 || (!isNull(dateFilter) && dateFilter !== '') ? searchedItems : data;

    const addUserBtn = (userInfosFromContext?.hasEtp === 0 && isDoctor) || userInfosFromContext?.isExternal === 1;

    return (
        <Box className={classes.container}>
            <div style={{ padding: '20px 18px 5px 18px' }}>
                <Typography fontFamily={baseFontFamily.BreeSerif} color={baseColors.black} fontSize={24}>
                    Patients
                </Typography>

                <Spacer height={10} />

                <SearchInputField
                    iconMarginRight={12}
                    fontSize="12px"
                    onChange={(value: any) => handleSearch(value)}
                    placeholder="Rechercher par nom, prénom"
                    value={searchkey}
                />

                <Spacer height={10} />

                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                    <CustomDatePicker
                        likeSelect
                        validator
                        height={42}
                        fontSize="12px"
                        value={dateFilter}
                        setValue={handleSetDate}
                        clear={clearDate}
                        alignItems="flex-start"
                        placeholder="Rechercher par date"
                    />

                    {dateFilter !== null && (
                        <IconButton
                            aria-label="close"
                            onClick={handleClearDate}
                            sx={{
                                color: baseColors.green.primary,
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    )}
                </div>
            </div>

            <div className={classes.patientListContainer}>
                {loading ? (
                    <PatientListLoader />
                ) : finalData?.length ? (
                    finalData?.map((patientItem: any) => {
                        const patient = patientItem;
                        const userCoActorStatus = userCoActorStatusDefaultAccepted;

                        return (
                            <PatientCardItem
                                key={patient?.id}
                                photo={patient?.profilePicture}
                                active={selectedPatient?.id === patient?.id}
                                onClick={handleClickPatientCard(patient, userCoActorStatus)}
                                id={patient?.id}
                                fullName={fullName(patient?.firstName, patient?.lastName)}
                                description={patient?.chronicPathology}
                            />
                        );
                    })
                ) : (
                    <div>
                        <CustomText style={{ textAlign: 'center' }}>Aucun patient trouvé</CustomText>
                    </div>
                )}
            </div>

            {!addUserBtn && (
                <div className={classes.buttonContainer}>
                    <CustomLoadingButton
                        disabled={loading}
                        width="90%"
                        label="Inscrire nouveau patient"
                        handleClick={createNewPatient}
                    />
                </div>
            )}
        </Box>
    );
};

export default PatientListCpts;
