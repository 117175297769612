type KeepCurrentScreenAfterPageRefreshType = {
    history: any;
    customScreens?: string[];
};

const patientAllowRefreshScreens = ['diagnostic', 'program', 'agenda', 'observation', 'evaluation'];

const KeepCurrentScreenAfterPageRefresh = ({ history, customScreens }: KeepCurrentScreenAfterPageRefreshType) => {
    const screens = customScreens ?? patientAllowRefreshScreens;
    const currentScreenPathName = history.location.pathname;
    const keepAfterRefresh = screens.some((pathname: string) => currentScreenPathName?.includes(pathname));
    return keepAfterRefresh;
};

const checkPatientPathname = ({
    currentPathname,
    pathname,
    history,
}: {
    currentPathname?: string;
    pathname: string;
    history?: any;
}) => {
    if (currentPathname !== '') {
        return currentPathname?.includes('add');
    }
    return false;
};

const defaultPatientPayload = {
    firstName: '',
    lastName: '',
    birthPlace: '',
    birthDate: new Date(),
    sex: 1,
    email: '',
    phoneNumber: '',
    profession: '',
    relation: '',
    address: '',
    civility: 1,
    rpps: '',
    professionals: [],
    assistants: [],
    filecontent: '',
    finishedDE: false,
    weight: 0,
    height: 0,
    bloodPressure: '',
    additionalAdressInfo: '',
    postalCode: '',
    city: '',
    specialityTypeId: 0,
    medecinTraitantId: 0,
};

const handleRedirectPatientUrl = ({
    setSelectedPatient,
    history,
    patients,
    params,
    justAddedPatient,
    location,
}: {
    setSelectedPatient: (value: any) => void;
    history: any;
    patients: Array<any>;
    params: any;
    justAddedPatient: any;
    location: any;
}) => {
    const patientIdInParams = params?.id ? parseInt(params?.id) : 0;
    const currentPathname = window?.location?.pathname;
    const addPatient = checkPatientPathname({ currentPathname, pathname: 'patients/add' });

    if (justAddedPatient?.id > 0 && patients?.length > 0 && patientIdInParams !== justAddedPatient?.id) {
        setSelectedPatient(justAddedPatient);
        history.push(`/patients/list/${justAddedPatient?.id}/profile`);
    } else {
        if (patientIdInParams === 0 && patients?.length > 0) {
            const firstPatient: any = patients?.[0];
            setSelectedPatient(firstPatient);
            if (addPatient) {
                setSelectedPatient({});
            } else {
                history.push(`/patients/list/${firstPatient?.id}/profile`);
            }
        }

        if (patientIdInParams > 0 && patients?.length > 0) {
            const patientFromParams = patients?.find((patient) => patient?.id === patientIdInParams);
            setSelectedPatient(patientFromParams);
        }
    }

    if (KeepCurrentScreenAfterPageRefresh({ history })) {
        return;
    }
};

export { defaultPatientPayload, handleRedirectPatientUrl, checkPatientPathname, KeepCurrentScreenAfterPageRefresh };
