import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import CustomCircleLoader from '../CustomLoader/CustomCircleLoader';

type IProps = {
    loading: boolean;
    url: string;
    title?: string;
};

const CustomVisioIframe: FC<IProps> = ({ loading, url, title }) => {
    if (loading) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    marginTop: '15%',
                }}
            >
                <CustomCircleLoader color={baseColors.green.secondary} height={150} width={150} userInfos />
                <Typography
                    style={{
                        color: baseColors.green.pastel,
                    }}
                    fontSize={20}
                    fontFamily={baseFontFamily.BreeSerif}
                    textAlign="center"
                >
                    {title ?? 'Préparation du visio en cours...'}
                </Typography>
            </Box>
        );
    }

    return (
        <iframe
            allow="camera; microphone"
            style={{
                height: '100%',
                width: '100%',
                border: '0 solid transparent',
                borderRadius: 2,
            }}
            src={url}
            title="VISIO IVEHTE"
            allowFullScreen
        />
    );
};

export default CustomVisioIframe;
