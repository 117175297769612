import { makeStyles } from '@mui/styles';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';

const useStyles = makeStyles({
    container: {
        padding: 10,
    },
    loading: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
    row: { display: 'flex', flexDirection: 'row' },
    title: {
        flex: 1,
        textAlign: 'center',
        color: baseColors.white,
        fontFamily: baseFontFamily.BreeSerif,
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: baseColors.orange.primary,
        borderRadius: 10,
        padding: '1px 10px',
    },
    right: {
        display: 'flex',
        flex: 1 / 4,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    btnContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
});

export const sxStyles: any = {
    content: {},
    answer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    circularProgressBar: {
        rotation: 0.25,
        strokeLinecap: 'butt',
        textSize: '25px',
        pathTransitionDuration: 0.5,
        pathColor: baseColors.green.primary,
        textColor: '#fff',
        trailColor: '#d6d6d6',
        backgroundColor: '#3e98c7',
    },

    title: {
        flex: 1,
        textAlign: 'center',
        color: baseColors.white,
        fontFamily: baseFontFamily.BreeSerif,
    },
    footerContainer: {
        display: 'flex',
        flexDirection: 'row',

        alignItems: 'center',
        padding: 10,
    },

    center: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
};

export default useStyles;
