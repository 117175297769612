import { Box } from '@mui/material';
import { FC, useContext } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { CustomText } from 'src/components/Text/CustomText';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import { PatientContext } from 'src/providers/PatientProvider';
import { useFetchUserPrograms } from 'src/hooks/useFetchUserPrograms';
import CustomCircleLoader from 'src/components/CustomLoader/CustomCircleLoader';

interface ProgramEmptyProps {
    setIsEmpty?: Function;
}

export const ProgramEmpty: FC<ProgramEmptyProps> = (props) => {
    const { selectedPatient } = useContext(PatientContext);
    const patientId = selectedPatient?.id ?? 0;

    const history = useHistory();
    const { url } = useRouteMatch();

    const { preSelectedPatientProgram, fetchingPreselectedProgram } = useFetchUserPrograms({
        userId: patientId,
    });

    const gotoProgramForm = () => history.push(`${url}/add`);

    const inProgressProgram = `Ce patient est inscrit dans le programme *${
        preSelectedPatientProgram?.name ?? ''
    }*, en attente de diagnostic éducatif.`;
    const hasProgramInProgress = preSelectedPatientProgram?.id > 0;

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '25%',
            }}
        >
            {fetchingPreselectedProgram ? (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: -20,
                        marginBottom: 10,
                    }}
                >
                    <CustomCircleLoader height={70} width={70} />
                </Box>
            ) : (
                <>
                    <CustomText
                        style={{ marginBottom: 20 }}
                        fontFamily={baseFontFamily.OpenSansBold}
                        color={baseColors.orange.primary}
                    >
                        {hasProgramInProgress ? inProgressProgram : "Ce patient n'est inscrit dans aucun programme."}
                    </CustomText>
                </>
            )}

            <CustomLoadingButton
                handleClick={gotoProgramForm}
                disabled={hasProgramInProgress || fetchingPreselectedProgram}
                width={330}
                label="Inscrire dans un programme"
            />
        </Box>
    );
};
