import { FC, useEffect, useContext, useState } from 'react';
import useStyles from './styles';
import { Box, Divider } from '@mui/material';
import { baseColors } from 'src/utils/constants/baseStyles';
import RdvWorkShopItem from 'src/components/RdvWorkShopItem';
import { useDispatch } from 'react-redux';
import { retrieveDailyPatientsByDate, retrieveMyScheduledWorkshops } from 'src/actions/dashboard/dashboard';
import CustomCircleLoader from 'src/components/CustomLoader/CustomCircleLoader';
import { EventPlanningViewType } from 'src/interfaces/interfaces';
import WorkshopModal from 'src/components/CustomModal/WorkshopModal';
import { UserContext } from 'src/providers/UserProvider';
import { AgendaContext } from 'src/providers/AgendaProvider';
import CustomEmptyData from 'src/components/CustomEmptyData';

interface PlannifiedWorkShopsProps {
    selectedDate: string;
    viewType?: EventPlanningViewType['viewType'];
    selectedIntervenants?: any;
    isForProgram?: boolean;
    setUpdateList?: any;
    dataForWeeklyEvent?: any;
    patientForAgendaWorkShop?: boolean;
    dataWorkshop: any;
}

const PlannifiedWorkShops: FC<PlannifiedWorkShopsProps> = (props) => {
    const {
        selectedDate,
        viewType,
        selectedIntervenants,
        patientForAgendaWorkShop,
        dataForWeeklyEvent,
        isForProgram,
        setUpdateList,
        dataWorkshop,
    } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const patientAtelier = dataWorkshop.filter((item: any) => item.type === 'Workshop');
    const [loadingWorkshops, setLoadingWorkshops] = useState<boolean>(false);
    const [eventData, setEventData] = useState<any>(false);
    const { updateEventList } = useContext(AgendaContext);
    const [open, setOpen] = useState<boolean>(false);
    const toggleModal = () => setOpen(!open);
    const { isCoordinator } = useContext(UserContext);
    let readOnly = selectedIntervenants?.length > 0 || (isForProgram && !isCoordinator);

    const countedData = patientAtelier.map((d: any) => {
        const counts = d.itemData.attendees.reduce(
            (acc: any, attendee: any) => {
                if (attendee.type === 'Participants') {
                    acc.participants += 1;
                } else if (attendee.type === 'Intervenants') {
                    acc.intervenants += 1;
                }
                return acc;
            },
            { participants: 0, intervenants: 0 },
        );

        return { ...d, counts };
    });

    useEffect(() => {
        dispatch(retrieveMyScheduledWorkshops('', setLoadingWorkshops));
        dispatch(retrieveDailyPatientsByDate(selectedDate, setLoadingWorkshops));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDate, updateEventList]);

    const { userInfos } = useContext(UserContext);
    const isExternalDoctor = Boolean(userInfos?.isExternal);
    const isEmpty = countedData?.length === 0;

    return (
        <Box className={classes.container}>
            <WorkshopModal
                setUpdateList={setUpdateList}
                open={open}
                toggleModal={toggleModal}
                workShopData={eventData}
                dataForWeeklyEvent={dataForWeeklyEvent}
                viewType={viewType}
                patientForAgendaWorkShop={patientForAgendaWorkShop}
                readOnly={readOnly}
                isCoordinator={isCoordinator}
                isExternalDoctor={isExternalDoctor}
                userInfos={userInfos}
            />

            <Box className={classes.content}>
                {loadingWorkshops ? (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'row',
                            marginTop: 50,
                        }}
                    >
                        <CustomCircleLoader height={40} width={40} />
                    </div>
                ) : isEmpty ? (
                    <CustomEmptyData marginTop={50} height={70} width={70} />
                ) : (
                    countedData?.map((item: any, index: number) => (
                        <>
                            <Box
                                key={index}
                                onClick={() => {
                                    setEventData(item);
                                    toggleModal();
                                }}
                            >
                                <RdvWorkShopItem
                                    orange={item.oranged}
                                    label={item.label}
                                    date={item.timeStart + ' - ' + item.timeEnd}
                                    progressValue={item.counts.participants}
                                    progressMaxValue={10}
                                />

                                {patientAtelier?.length - 1 !== index && (
                                    <Divider
                                        style={{
                                            width: '100%',
                                            marginBottom: 20,
                                            background: baseColors.green.secondary,
                                        }}
                                    />
                                )}
                            </Box>
                        </>
                    ))
                )}
            </Box>
        </Box>
    );
};

export default PlannifiedWorkShops;
