import { useContext, useState } from 'react';
import { Time } from 'src/interfaces/interfaces';
import { UserContext } from 'src/providers/UserProvider';
import AgendaSettingsService from 'src/services/AgendaSettings';
import { defaultAgendaHours, planningHours } from 'src/utils/constants/constants';

const useAgendaHours = () => {
    const { userInfos } = useContext(UserContext);
    const [fetching, setFetching] = useState<boolean>(true);
    const [time, setTime] = useState<Time>(defaultAgendaHours);
    const [hours, setHours] = useState<Array<{ label: string; value: string }>>([]);
    const [timeSlot, setTimeSlot] = useState<any>(15);
    const handleFetchHoursData = () => {
        setFetching(true);
        AgendaSettingsService.getAgendaTimeConfigurations(userInfos.id)
            .then((response) => {
                const data = response?.data?.data;
                const minuteInterval = data?.timeSlot ?? 15;
                const hours = {
                    startHour: data?.startHour ?? defaultAgendaHours?.startHour,
                    endHour: data?.endHour ?? defaultAgendaHours?.endHour,
                };
                setTimeSlot(data?.timeSlot);
                setTime((prev: Time) => {
                    if (data === null) {
                        return prev;
                    }
                    return data;
                });
                setHours(planningHours(hours, minuteInterval));
                setFetching(false);
            })
            .catch(() => setFetching(false));
    };

    return {
        handleFetchHoursData,
        fetching,
        hours,
        time,
        timeSlot,
        setTime,
    };
};

export default useAgendaHours;
