import React, { FocusEventHandler, useEffect, useState } from 'react';
import { Grid, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { baseColors, baseFontSize } from '../../utils/constants/baseStyles';
import { makeStyles } from '@mui/styles';
import { specialCharToUnderscore } from 'src/utils/utils';
import { CustomText } from '../Text/CustomText';
type AppProps = {
    defaultValue?: string;
    label: string;
    regexLabel?: string;
    placeholder: string;
    onChange: Function;
    onBlur?: FocusEventHandler<HTMLInputElement> | undefined;
    width: any;
    height?: any;
    value?: string;
    inversed?: boolean;
    validator?: Function;
    errorMessage?: string;
    disabled?: boolean;
    multiline?: boolean;
    single?: boolean;
    idSuffix?: string;
    passwordInput?: boolean;
    defaultInputId?: string;
    onKeyPress?: Function;
    withoutLabel?: boolean;
    upper?: boolean;
    prefixUpper?: boolean;
    maxLength?: number;
    labelFontSize?: string | number;
    required?: boolean
};
const CustomTextField = styled(TextField)({});

const useStyles = makeStyles({
    input: {
        marginTop: 10,
        width: '100%',

        '& label.Mui-focused': {
            color: baseColors.green.primary,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: baseColors.green.primary,
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: baseColors.grey,
                borderRadius: 10,
            },
            '&:hover fieldset': {
                borderColor: baseColors.black,
            },
            '&.Mui-focused fieldset': {
                borderColor: baseColors.green.primary,
            },
        },
    },
    inputInversed: {
        marginTop: 10,
        width: '100%',
        '& label.Mui-focused': {
            color: baseColors.green.primary,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: baseColors.green.primary,
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: baseColors.green.primary,
                borderRadius: 10,
            },
            '&:hover fieldset': {
                borderColor: baseColors.black,
            },
            '&.Mui-focused fieldset': {
                borderColor: baseColors.grey,
            },
        },
    },
    inputInvalid: {
        marginTop: 10,
        width: '100%',
        '& label.Mui-focused': {
            color: baseColors.orange.primary,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: baseColors.orange.primary,
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: baseColors.orange.primary,
                borderRadius: 10,
            },
            '&:hover fieldset': {
                borderColor: baseColors.black,
            },
            '&.Mui-focused fieldset': {
                borderColor: baseColors.orange.primary,
            },
        },
    },
});

export const InputField = ({
    defaultValue,
    onBlur,
    value,
    validator,
    height,
    multiline,
    inversed,
    label,
    placeholder,
    onChange,
    width,
    single,
    disabled,
    idSuffix,
    regexLabel,
    passwordInput,
    defaultInputId,
    withoutLabel,
    upper,
    prefixUpper,
    maxLength,
    labelFontSize,
    required
}: AppProps) => {
    const classes = useStyles();
    const [isValid, setIsValid] = useState(false);

    useEffect(() => {
        if (!validator) {
            setIsValid(true);
        } else {
           if (value && value?.length > 0 && !validator(value)) {
                setIsValid(false);
            } else {
                setIsValid(true);
            }
        }

        if(required && value && value?.toString()?.trim()?.length > 0){
            if(!validator){
                setIsValid(true);
            }else if(validator && validator(value)){
                setIsValid(true);
            }else if(validator && !validator(value)){
                setIsValid(false);
            }
        }else if(required && !value){
            setIsValid(false);
        } 

    }, [validator, value]);

    const handleChangeFirstName = (event: any) => {
        const newValue = event.target.value
            .split(' ')
            .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');

        onChange(newValue);
    };

    const onInput = (event: any) => {
        if (maxLength) {
            event.target.value = Math.max(0, parseInt(event.target.value)).toString().slice(0, maxLength);
        }
    };
    
    return (
        <div style={{ width: width }}>
            <div
                style={{
                    display: 'flex',
                    flexGrow: 1,
                    flexDirection: 'column',
                    width: '100%',
                }}
            >
                {label !== '' && !withoutLabel && (
                    <Grid direction="row" container alignItems="center">
                        <Grid container alignItems="center">
                            {' '}
                            <Grid item>
                                <CustomText color={baseColors.black} fontSize={labelFontSize ?? baseFontSize.sl}>
                                    {label}
                                </CustomText>
                            </Grid>
                            <Grid item>
                                {regexLabel !== undefined ? (
                                    <Typography
                                        color={regexLabel === '(obligatoire)' ? '' : baseColors.orange.primary}
                                        style={{ marginLeft: 5, fontStyle: 'italic' }}
                                        fontSize={baseFontSize.sm}
                                    >
                                        {regexLabel}
                                    </Typography>
                                ) : (
                                    ''
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                )}

                <CustomTextField
                    type="text"
                    id={specialCharToUnderscore(`${defaultInputId ?? label}-${idSuffix}`)}
                    defaultValue={defaultValue}
                    onBlur={onBlur}
                    value={value}
                    size="small"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        if (upper) {
                            onChange(event.target.value.toUpperCase());
                        } else if (prefixUpper) {
                            handleChangeFirstName(event);
                        } else {
                            onChange(event.target.value);
                        }
                    }}
                    placeholder={placeholder}
                    multiline={multiline ?? false}
                    className={isValid ? (inversed ? classes.inputInversed : classes.input) : classes.inputInvalid}
                    InputProps={{
                        style: { minHeight: height },
                        type: passwordInput ? 'password' : undefined,
                    }}
                    onInput={onInput}
                    style={{
                        marginTop: !single ? 3 : 0,
                        marginBottom: !single ? 20 : 0,
                    }}
                    disabled={disabled ?? false}
                />
            </div>
        </div>
    );
};
