import { FC, useEffect } from 'react';
import { useHistory } from 'react-router';
import { ROUTES } from '../../routes/routes';
import useStyles from './styles';

const Home: FC = (props) => {
    const classes = useStyles();
    const history = useHistory();

    useEffect(() => {
        history.push(ROUTES.dashboard);
    }, [history]);

    return (
        <div className={classes.container}>
            <p>Home Page</p>
        </div>
    );
};

export default Home;
