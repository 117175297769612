import { FC, useContext, useEffect } from 'react';
import { Patient } from 'src/interfaces/interfaces';
import useStyles from './styles';
import { EducationalDiagnosisTab } from './EducationalDiagnosisTab/EducationalDiagnosisTab';
import { Switch, Route, useRouteMatch, useHistory, useLocation } from 'react-router-dom';
import AxisDietetique from './AxisContainer/AxisDietetique/AxisDietetique';
import AxisPsychologique from './AxisContainer/AxisPsychologique/AxisPsychologique';
import AxisMedical from './AxisContainer/AxisMedical/AxisMedical';
import AxisAPA from './AxisContainer/AxisAPA/AxisAPA';
import { NotFound } from 'src/views/Error/NotFound';
import { EducationalDiagnosisContext } from 'src/providers/EducationalDiagnosisProvider';
import DESynthese from './AxisContainer/DESynthese/DESynthese';
import useEducationalDiagnosis from 'src/hooks/useEducationalDiagnosis';

interface DiagnosticProps {
    patientInfo: Patient;
    defaultPatient?: any;
    isActor?: any;
}

const Diagnostic: FC<DiagnosticProps> = ({ patientInfo }) => {
    const classes = useStyles();
    const { path } = useRouteMatch();
    const { pathname } = useLocation();
    const patientId = patientInfo?.id ?? 0;
    const history = useHistory();
    const { activeDefaultTab } = useContext(EducationalDiagnosisContext);

    const { fetchEducationalDiagnosis, educationalDiagnosis } = useEducationalDiagnosis({
        patientId,
    });

    useEffect(() => {
        fetchEducationalDiagnosis();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patientId, pathname]);

    useEffect(() => {
        if (activeDefaultTab) history.push(`${pathname}/apa`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeDefaultTab]);

    const educationalDiagnosisId = educationalDiagnosis?.id ?? 0;

    return (
        <div className={classes.container}>
            <EducationalDiagnosisTab />

            <div className={classes.content}>
                <Switch>
                    <Route path={`${path}/apa`}>
                        <AxisAPA educationalDiagnosisId={educationalDiagnosisId} patientId={patientId} />
                    </Route>
                    <Route path={`${path}/dietetique`}>
                        <AxisDietetique educationalDiagnosisId={educationalDiagnosisId} patientId={patientId} />
                    </Route>
                    <Route path={`${path}/psychologique`}>
                        <AxisPsychologique educationalDiagnosisId={educationalDiagnosisId} patientId={patientId} />
                    </Route>
                    <Route path={`${path}/medical`}>
                        <AxisMedical educationalDiagnosisId={educationalDiagnosisId} patientId={patientId} />
                    </Route>
                    <Route path={`${path}/synthese`}>
                        <DESynthese educationalDiagnosisId={educationalDiagnosisId} patient={patientInfo} />
                    </Route>
                    <Route path="*">
                        <NotFound />
                    </Route>
                </Switch>
            </div>
        </div>
    );
};

export default Diagnostic;
