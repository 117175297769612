import { FC, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import Informations from './Informations';
import Planning from './Planning';
import useAgendaHours from 'src/hooks/useAgendaHours';

const MyAgenda: FC = () => {
    const [modalEvent, setModalEvent] = useState<boolean>(false);
    const [isFromInfos, setIsFromInfos] = useState<boolean>(false);
    const toggleModal = () => setModalEvent(!modalEvent);

    const { handleFetchHoursData } = useAgendaHours();

    useEffect(() => {
        handleFetchHoursData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Grid container spacing={1}>
            <Grid item xs={9} md={9}>
                <Planning
                    modalEvent={modalEvent}
                    toggleModal={toggleModal}
                    isFromInfos={isFromInfos}
                    setIsFromInfos={setIsFromInfos}
                />
            </Grid>

            <Grid item xs={3} md={3}>
                <Informations toggleEventModal={toggleModal} setIsFromInfos={setIsFromInfos} />
            </Grid>
        </Grid>
    );
};

export default MyAgenda;
