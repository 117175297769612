import { Box, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import CustomEmptyData from 'src/components/CustomEmptyData';
import CustomCircleLoader from 'src/components/CustomLoader/CustomCircleLoader';
import { CustomText } from 'src/components/Text/CustomText';
import { DiscussionThemeItem } from 'src/interfaces/types';
import ProgramService from 'src/services/Program/program.service';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';

interface DiscussionThemeListProps {
    programId: number;
    updateList: boolean;
    setSelectedDiscussionTheme: Dispatch<SetStateAction<DiscussionThemeItem>>;
}

const DiscussionThemeList: FC<DiscussionThemeListProps> = (props) => {
    const { programId, updateList, setSelectedDiscussionTheme } = props;
    const [fetching, setFetching] = useState<boolean>(false);
    const [discussionThemes, setDiscussionThemes] = useState<DiscussionThemeItem[]>([]);

    const handleFetchDiscussionThemes = () => {
        setFetching(true);
        ProgramService.getDiscussionThemes(programId)
            .then((res) => {
                const data = res?.data?.data;
                setDiscussionThemes(data);
                setFetching(false);
            })
            .catch(() => {
                setFetching(false);
            });
    };

    useEffect(() => {
        handleFetchDiscussionThemes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [programId, updateList]);

    return (
        <Box style={{}}>
            {fetching ? (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <CustomCircleLoader height={40} width={40} />
                </div>
            ) : (
                <List style={{ overflow: 'hidden', overflowY: 'scroll', maxHeight: 400 }}>
                    <CustomText
                        fontFamily={baseFontFamily.BreeSerif}
                        fontSize={18}
                        style={{ textAlign: 'center', color: baseColors.orange.primary }}
                    >
                        Liste des thèmes
                    </CustomText>

                    {discussionThemes?.length === 0 ? (
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: -50 }}>
                            <CustomEmptyData height={50} width={50} />
                        </div>
                    ) : (
                        <List>
                            {discussionThemes.map((discussionTheme) => (
                                <ListItem
                                    disablePadding
                                    key={discussionTheme.id}
                                    onClick={() => setSelectedDiscussionTheme(discussionTheme)}
                                    style={{
                                        marginTop: 0,
                                        marginBottom: 10,
                                        backgroundColor: baseColors.orange.light,
                                        borderRadius: 5,
                                    }}
                                >
                                    <ListItemButton>
                                        <ListItemText>
                                            <CustomText fontSize={14}>{discussionTheme.name ?? ''}</CustomText>
                                        </ListItemText>
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    )}
                </List>
            )}
        </Box>
    );
};

export default DiscussionThemeList;
