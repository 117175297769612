import * as React from 'react';

function SvgTools(props) {
    return (
        <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M41.2383 6.43359V12.7617C41.2383 13.6478 40.5423 14.3438 39.6562 14.3438C38.7702 14.3438 38.0742 13.6478 38.0742 12.7617V6.43359C38.0742 5.54755 37.3782 4.85156 36.4922 4.85156H17.5078C16.6218 4.85156 15.9258 5.54755 15.9258 6.43359V12.7617C15.9258 13.6478 15.2298 14.3438 14.3438 14.3438C13.4577 14.3438 12.7617 13.6478 12.7617 12.7617V6.43359C12.7617 3.80732 14.8815 1.6875 17.5078 1.6875H36.4922C39.1185 1.6875 41.2383 3.80732 41.2383 6.43359Z"
                fill="#F3F5F9"
            />
            <path
                d="M41.2383 6.43359V12.7617C41.2383 13.6478 40.5423 14.3438 39.6562 14.3438C38.7702 14.3438 38.0742 13.6478 38.0742 12.7617V6.43359C38.0742 5.54755 37.3782 4.85156 36.4922 4.85156H27V1.6875H36.4922C39.1185 1.6875 41.2383 3.80732 41.2383 6.43359Z"
                fill="#F3F5F9"
            />
            <path
                d="M49.2539 14.3438V49.1484L47.6719 52.3125H6.32812L4.74609 49.1484V14.3438L6.32812 11.1797H47.6719L49.2539 14.3438Z"
                fill="#F3F5F9"
            />
            <path d="M49.2539 14.3438V49.1484L47.6719 52.3125H27V11.1797H47.6719L49.2539 14.3438Z" fill="#F3F5F9" />
            <path
                d="M27 20.6719C20.8934 20.6719 15.9258 25.6395 15.9258 31.7461C15.9258 37.8527 20.8934 42.8203 27 42.8203C33.1066 42.8203 38.0742 37.8527 38.0742 31.7461C38.0742 25.6395 33.1066 20.6719 27 20.6719Z"
                fill="#64C7C7"
            />
            <path
                d="M38.0742 31.7461C38.0742 37.8527 33.1066 42.8203 27 42.8203V20.6719C33.1066 20.6719 38.0742 25.6395 38.0742 31.7461Z"
                fill="#64C7C7"
            />
            <path
                d="M49.2539 11.1797H47.6719V52.3125H49.2539C51.871 52.3125 54 50.1835 54 47.5664V15.9258C54 13.3087 51.871 11.1797 49.2539 11.1797Z"
                fill="#1B8E95"
            />
            <path
                d="M4.74609 11.1797C2.12899 11.1797 0 13.3087 0 15.9258V47.5664C0 50.1835 2.12899 52.3125 4.74609 52.3125H6.32812V11.1797H4.74609Z"
                fill="#1B8E95"
            />
            <path
                d="M31.7461 31.7461C31.7461 32.6321 31.0501 33.3281 30.1641 33.3281H28.582V34.9102C28.582 35.7962 27.886 36.4922 27 36.4922C26.114 36.4922 25.418 35.7962 25.418 34.9102V33.3281H23.8359C22.9499 33.3281 22.2539 32.6321 22.2539 31.7461C22.2539 30.8601 22.9499 30.1641 23.8359 30.1641H25.418V28.582C25.418 27.696 26.114 27 27 27C27.886 27 28.582 27.696 28.582 28.582V30.1641H30.1641C31.0501 30.1641 31.7461 30.8601 31.7461 31.7461Z"
                fill="#F3F5F9"
            />
            <path
                d="M31.7461 31.7461C31.7461 32.6321 31.0501 33.3281 30.1641 33.3281H28.582V34.9102C28.582 35.7962 27.886 36.4922 27 36.4922V27C27.886 27 28.582 27.696 28.582 28.582V30.1641H30.1641C31.0501 30.1641 31.7461 30.8601 31.7461 31.7461Z"
                fill="#F3F5F9"
            />
        </svg>
    );
}

export default SvgTools;
