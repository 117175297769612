import { Grid } from '@mui/material';
import { FC } from 'react';
import { CustomText } from 'src/components/Text/CustomText';
import { CustomSelect } from 'src/components/InputField/CustomSelect';
import { consultationType } from 'src/utils/constants/fakeData';
import { Workshop } from 'src/interfaces/interfaces';

interface HdjConsultationTypeCreateProps {
    setConsultation: any;
    loadingData?: any;
    consultationData?: any;
    workshopData?: Workshop;
}

const HdjConsultationTypeCreate: FC<HdjConsultationTypeCreateProps> = (props) => {
    const { setConsultation, workshopData } = props;
    const consultationTypesData = [{ id: 0, name: 'Sélectionner le type voulu' }, ...consultationType];

    return (
        <Grid
            container
            direction="row"
            alignItems={'center'}
            justifyContent="space-between"
            style={{ marginBottom: 10 }}
        >
            <Grid item>
                <CustomText>Type de consulation : </CustomText>
            </Grid>
            <Grid item style={{ width: '60%' }}>
                <CustomSelect
                    onChange={(value: string) => {
                        setConsultation((old: any) => ({
                            ...old,
                            consultationTypeId: value,
                            roomId: 0,
                        }));
                    }}
                    label=""
                    labelKey={'name'}
                    items={consultationTypesData}
                    defaultValue={workshopData?.consultationTypeId ?? 0}
                    consultationType
                    idSelect="consultation-type"
                    width={360}
                />
            </Grid>
        </Grid>
    );
};

export default HdjConsultationTypeCreate;
