import { baseColors } from "src/utils/constants/baseStyles"

function SvgImageIcon(props) {
    return (
        <svg width={40} height={50} viewBox="0 0 40 50" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clipPath="url(#clip0)">
                <path d="M0 5a5 5 0 015-5h30a5 5 0 015 5v40a5 5 0 01-5 5H5a5 5 0 01-5-5V5z" fill="#E6F4F4" />
                <rect
                    x={5.57129}
                    y={28}
                    width={39.4397}
                    height={49.5289}
                    rx={5}
                    transform="rotate(41.12 5.571 28)"
                    fill={baseColors.green.pastel}
                />
                <rect
                    x={5.57129}
                    y={28}
                    width={39.4397}
                    height={49.5289}
                    rx={5}
                    transform="rotate(41.12 5.571 28)"
                    fill="url(#paint0_linear)"
                />
                <rect
                    x={24.5117}
                    y={23}
                    width={43}
                    height={54}
                    rx={5}
                    transform="rotate(41.12 24.512 23)"
                    fill={baseColors.green.primary}
                />
                <circle cx={9.5} cy={17.5} r={3.5} fill={baseColors.green.primary} />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear"
                    x1={4.1087}
                    y1={24.0632}
                    x2={21.8607}
                    y2={44.399}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#fff" />
                    <stop offset={0.644647} stopColor="#fff" stopOpacity={0} />
                </linearGradient>
                <clipPath id="clip0">
                    <path d="M0 5a5 5 0 015-5h30a5 5 0 015 5v40a5 5 0 01-5 5H5a5 5 0 01-5-5V5z" fill="#fff" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default SvgImageIcon
