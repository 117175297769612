import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './stores/store';
import PlateformTypeProvider from './providers/PlateformTypeProvider';

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <PlateformTypeProvider>
                <App />
            </PlateformTypeProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
);

if ('serviceWorker' in navigator) {
    window.addEventListener('load', function () {
        navigator.serviceWorker.register('./firebase-messaging-sw.js');
    });
}

reportWebVitals();
