import moment from 'moment';
import { Dispatch, SetStateAction } from 'react';
import { addZeroBeforeNumber, dbDateFormat } from 'src/utils/utils';

interface IProps {
    setMonth: Dispatch<SetStateAction<number>>;
    setYear: Dispatch<SetStateAction<number>>;
    setYearAndMonth: Dispatch<SetStateAction<any>>;
    year: number;
}

export interface ViewTypes {
    id: number;
    label: string;
    keyValue: string;
    value: number;
}

const handlePrevClick =
    ({ setMonth, setYear, year, setYearAndMonth }: IProps) =>
    () =>
        setMonth((prevMonth: number) => {
            if (prevMonth > 0) {
                const res = prevMonth - 1;
                setYearAndMonth(`${year}-${addZeroBeforeNumber(res)}`);
                return res;
            } else {
                const res = year - 1;
                setYearAndMonth(`${res}-12`);
                setYear(res);
                return 12;
            }
        });

const handleNextClick =
    ({ setMonth, setYear, year, setYearAndMonth }: IProps) =>
    () =>
        setMonth((prevMonth: number) => {
            if (prevMonth < 12) {
                const res = prevMonth + 1;
                setYearAndMonth(`${year}-${addZeroBeforeNumber(res)}`);
                return res;
            } else {
                const res = year + 1;
                setYearAndMonth(`${res}-01`);
                setYear(res);
                return 1;
            }
        });

const viewTypes: ViewTypes[] = [
    {
        id: 1,
        label: 'Jour',
        keyValue: 'daily',
        value: 2,
    },
    {
        id: 2,
        label: 'Semaine',
        keyValue: 'weekly',
        value: 3,
    },
    {
        id: 3,
        label: 'Mois',
        keyValue: 'monthly',
        value: 4,
    },
];

const spliDateFromUrl = (history: any) => {
    const paramDate = history.location.search.split('=');
    const strDate = paramDate[1].split('T');
    const eventDate = moment(strDate?.[0]).format(dbDateFormat);
    return eventDate;
};

export { viewTypes, handlePrevClick, handleNextClick, spliDateFromUrl };
