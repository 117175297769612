import { useContext, useState } from 'react';
import { IconButton } from '@mui/material';
import { VisioContentContext } from 'src/providers/VisioContentProvider';
import CancelIcon from '@mui/icons-material/Cancel';
import { baseColors } from 'src/utils/constants/baseStyles';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

const CustomPlayerVisio = (props) => {
    const { url } = props;
    const { setOpenIframe } = useContext(VisioContentContext);
    const [screenSize, setScreenSize] = useState({
        height: 83,
        width: 27,
        isFullScreen: true,
    });

    const handleCloseVisio = () => setOpenIframe(false);

    const handleResizeScreen = (isMini = false) => {
        setScreenSize((prev) => ({
            height: 83,
            width: isMini ? 27 : 83,
            isFullScreen: !prev.isFullScreen,
        }));
    };

    const resizeScreen = !screenSize.isFullScreen ? (
        <FullscreenExitIcon style={{ height: 50, width: 50 }} htmlColor={baseColors.green.primary} />
    ) : (
        <FullscreenIcon style={{ height: 50, width: 50 }} htmlColor={baseColors.green.primary} />
    );

    return (
        <div
            style={{
                position: 'fixed',
                width: `${screenSize.width}%`,
                height: `${screenSize.height}%`,
                bottom: '10px',
                left: '5px',
                background: '#000',
                borderRadius: 10,
                padding: 5,
                zIndex: 9999,
            }}
        >
            <IconButton
                style={{
                    position: 'absolute',
                    left: '46%',
                }}
                onClick={() => handleResizeScreen(!screenSize.isFullScreen)}
            >
                {resizeScreen}
            </IconButton>

            <IconButton
                style={{
                    position: 'absolute',
                    right: '7%',
                }}
                onClick={handleCloseVisio}
            >
                <CancelIcon style={{ height: 50, width: 50 }} htmlColor={baseColors.orange.primary} />
            </IconButton>

            <iframe
                allow="camera; microphone"
                style={{
                    height: '100%',
                    width: '100%',
                    border: '0 solid transparent',
                    borderRadius: 2,
                }}
                src={url}
                title="VISIO IVEHTE"
                allowFullScreen
            />
        </div>
    );
};

export default CustomPlayerVisio;
