import {
  RETRIEVE_CONSULTATIONS, RETRIEVE_PATIENTS, RETRIEVE_WORKSHOPS
} from "./types";
import ManagementService from "src/services/Hdj/management.service";

export const retrieveConsultations = (setLoadingConsultation: (val: boolean) => void, dayHospitalId: number, searchKey: string) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
  setLoadingConsultation(true)
  try {
    const res = await ManagementService.getConsultations(dayHospitalId, searchKey);

    dispatch({
      type: RETRIEVE_CONSULTATIONS,
      payload: res.data,
    });

    if(res.data){
      setLoadingConsultation(false)
    }
  } catch (err) {
    console.log(err);
    setLoadingConsultation(false)
  }
};

export const retrieveWorkshops = (setLoadingWorkshops: (val: boolean) => void, dayHospitalId: number, searchKey:string) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
  setLoadingWorkshops(true)
  try {
    const res = await ManagementService.getWorkshops(dayHospitalId, searchKey);
    dispatch({
      type: RETRIEVE_WORKSHOPS,
      payload: res.data,
    });
    if(res.data){
      setLoadingWorkshops(false)
    }
  } catch (err) {
    console.log(err);
    setLoadingWorkshops(false)
  }
};

export const retrievePatients = (setLoadingPatients: (val: boolean) => void, dayHospitalId: number, searchKey: string) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
  setLoadingPatients(true)
  try {
    const res = await ManagementService.getPatients(dayHospitalId, searchKey);
    dispatch({
      type: RETRIEVE_PATIENTS,
      payload: res.data,
    });
    if(res.data){
      setLoadingPatients(false)
    }
  } catch (err) {
    console.log(err);
    setLoadingPatients(false)
  }
};