import { Box } from '@mui/material';
import { FC, useState } from 'react';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import { EventPlanningViewType } from 'src/interfaces/interfaces';
import EventService from 'src/services/Calendar/events.service';
import EventModal from 'src/views/MyAgenda/EventModal';
import ModalAddMorePatients from '../ModalAddMorePatients/ModalAddMorePatients';
import { CustomConfirm } from 'src/components/CustomConfirm/CustomConfirm';
import { toast } from 'react-toastify';
import { OperationMessage } from 'src/utils/constants/constants';

interface ModalFooterProps {
    eventData: any;
    toggleModal?: any;
    updateMainEventList?: any;
    viewType?: EventPlanningViewType['viewType'];
    dataForWeeklyEvent?: any;
    setUpdateList?: any;
    loadingEvent?: boolean;
    readOnly?: boolean;
    isExternalDoctor?: boolean;
    isMyEvent?: boolean;
}

const ModalFooter: FC<ModalFooterProps> = (props) => {
    const {
        readOnly,
        eventData,
        toggleModal,
        updateMainEventList,
        viewType,
        dataForWeeklyEvent,
        setUpdateList,
        loadingEvent,
        isExternalDoctor,
        isMyEvent,
    } = props;
    const [open, setOpen] = useState<boolean>(false);
    const [openAddPatientModal, setOpenAddPatientModal] = useState<boolean>(false);
    const [deleting, setDeleting] = useState<boolean>(false);
    const [openDeleteEventModal, setOpenDeleteEventModal] = useState<boolean>(false);
    const toggleEditEventModal = () => setOpen(!open);
    const toggleRemoveEventModal = () => setOpenDeleteEventModal(!openDeleteEventModal);
    const toggleAddPatientModal = () => setOpenAddPatientModal(!openAddPatientModal);

    const handleDeleteEvent = () => {
        setDeleting(true);
        EventService.deleteEvent(eventData?.id)
            .then((res: any) => {
                toast.success(OperationMessage.success);
                setDeleting(false);
                updateMainEventList((prev: any) => !prev);
                setDeleting(false);
                toggleRemoveEventModal();
                toggleModal();
            })
            .catch(() => {
                setDeleting(false);
            });
    };

    const disableBtn = loadingEvent || deleting || readOnly || !isMyEvent || isExternalDoctor;

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 3,
            }}
        >
            <EventModal
                setUpdateList={updateMainEventList}
                modalOpen={open}
                modalToggle={toggleEditEventModal}
                eventData={eventData}
                toggleEventModal={toggleModal}
                viewType={viewType}
                dataForWeeklyEvent={dataForWeeklyEvent}
                edition
            />

            <ModalAddMorePatients
                eventData={eventData}
                toggleModal={toggleAddPatientModal}
                modalOpen={openAddPatientModal}
                setUpdateList={setUpdateList}
            />

            <CustomConfirm
                title="Supprimer l'événement"
                defaultLoading={deleting}
                yesLabel="Supprimer"
                noLabel="ANNULER"
                modalVisible={openDeleteEventModal}
                updateModalVisible={setOpenDeleteEventModal}
                message={deleting ? 'Suppression en cours...' : "Voulez-vous vraiment supprimer l'événement ?"}
                callBackconfirm={handleDeleteEvent}
                disable={disableBtn}
            />

            <CustomLoadingButton
                orange
                disabled={disableBtn}
                handleClick={toggleAddPatientModal}
                loading={false}
                width={300}
                marginRight={30}
                label="AJOUTER DES PATIENTS"
            />

            <CustomLoadingButton
                outlined
                disabled={disableBtn}
                handleClick={toggleEditEventModal}
                loading={false}
                width={150}
                marginRight={30}
                label="MODIFIER"
            />

            <CustomLoadingButton
                disabled={disableBtn}
                handleClick={toggleRemoveEventModal}
                loading={deleting}
                width={150}
                label="SUPPRIMER"
            />
        </Box>
    );
};

export default ModalFooter;
