import { Box } from '@mui/material';
import { FC } from 'react';
import CustomCircleLoader from 'src/components/CustomLoader/CustomCircleLoader';
import PatientRdvCard from 'src/components/PatientRdvCard';
import { isOdd } from 'src/utils/utils';
import useStyles from './styles';
import CustomEmptyData from 'src/components/CustomEmptyData';
interface SelectedDayRdvProps {
    selectedDate: string;
    dataRdv: any;
    loading: boolean;
}

const SelectedDayRdv: FC<SelectedDayRdvProps> = (props) => {
    const { dataRdv, loading } = props;
    const classes = useStyles();
    const isEmpty = dataRdv?.length === 0;

    return (
        <Box className={classes.container}>
            <Box className={classes.content}>
                {loading ? (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'row',
                            marginTop: 50,
                        }}
                    >
                        <CustomCircleLoader height={40} width={40} />
                    </div>
                ) : isEmpty ? (
                    <CustomEmptyData marginTop={50} height={70} width={70} />
                ) : (
                    dataRdv?.map((patient: any, i: number) => (
                        <PatientRdvCard key={i} item={patient} isGreen={isOdd(i)} />
                    ))
                )}
            </Box>
        </Box>
    );
};

export default SelectedDayRdv;
