import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import '../chart.css';
import { CustomText } from 'src/components/Text/CustomText';
import { Spacer } from 'src/components/Spacer/Spacer';

export const PieChart = (props) => {
    const { data, withoutLabel } = props;
    const [series, setSeries] = useState(data?.series ?? [0]);

    useEffect(() => {
        setSeries(data?.series);
    }, [data]);

    const options = {
        chart: {
            width: 380,
            type: 'pie',
        },
        labels: data?.labels,
        colors: [baseColors.green.primary, baseColors.orange.primary],
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200,
                    },
                    legend: {
                        position: 'bottom',
                    },
                },
            },
        ],
    };

    return (
        <div style={{ background: baseColors.green.light, padding: 15, borderRadius: 10 }}>
            {/* <CustomText fontFamily={baseFontFamily.OpenSansSemiBold}>Nom graphique</CustomText> */}
            <Spacer height={20} />
            {!withoutLabel && (
                <CustomText color={baseColors.green.primary} fontSize={12}>
                    Type de valeur ?
                </CustomText>
            )}
            <ReactApexChart options={options} series={series} type="pie" height={'100%'} width={'100%'} />
        </div>
    );
};
