import { getHourAndMinutes } from 'src/utils/constants/constants';
import { addZeroBeforeNumber } from 'src/utils/utils';

export interface Minute {
    id: number;
    label: string;
    value: string | number;
}

const hours = Array.from(Array(24).keys(), (item) => ({
    id: item,
    value: addZeroBeforeNumber(item),
    label: addZeroBeforeNumber(item),
}));

const getDefaultHour = (timeToCheck: string) => {
    const hour = getHourAndMinutes(timeToCheck)?.hour;

    if (hour === 0) {
        return;
    } else {
        return `${addZeroBeforeNumber(hour)}`;
    }
};

const getDefaultMinutes = (timeToCheck: string) => {
    const minutes = getHourAndMinutes(timeToCheck)?.minutes;

    if (minutes === 0) {
        return;
    } else {
        return `${addZeroBeforeNumber(minutes)}`;
    }
};

const timeSlots: Minute[] = [
    { id: 0, label: 'Sélectionner le créneau', value: 0 },
    { id: 1, label: '15', value: 15 },
    { id: 2, label: '30', value: 30 },
    { id: 3, label: '45', value: 45 },
    { id: 4, label: '60', value: 60 },
];

const minutes: Minute[] = [
    {
        id: 1,
        label: '00',
        value: '00',
    },
    {
        id: 2,
        label: '15',
        value: '15',
    },
    {
        id: 3,
        label: '30',
        value: '30',
    },
    {
        id: 4,
        label: '45',
        value: '45',
    },
];

export { hours, timeSlots, minutes, getDefaultHour, getDefaultMinutes };
