import { Box, Grid } from '@mui/material';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { EventSpecialityWithProfessionals } from 'src/views/MyAgenda/EventModal/EventForms/EventSpecialityWithProfessionals';
import { Spacer } from 'src/components/Spacer/Spacer';
import { CustomText } from 'src/components/Text/CustomText';
import { Workshop } from 'src/interfaces/interfaces';
import { getAttendeesType } from 'src/services/Attendees';
import { baseFontFamily, baseFontSize } from 'src/utils/constants/baseStyles';
import { fullName } from 'src/utils/utils';
import { sxStyles } from '../styles';

type IProps = {
    payload: any;
    attendeeTypeId: number;
    lockWorkshopInput: boolean;
    setAttendeeTypeId: Dispatch<SetStateAction<any>>;
    setPayload: Dispatch<SetStateAction<any>>;
    workshopData?: Workshop;
    loadingData?: any;
    selectedWorkshopData?:any;
    professionalIds?:any;
    setProfessionalIds?:any;
};

export const WorkshopIntervenants: FC<IProps> = (props) => {
    const { loadingData, setAttendeeTypeId, setPayload, attendeeTypeId, workshopData,payload,selectedWorkshopData,professionalIds,setProfessionalIds } = props;
    const [attendesName, setAttendesName] = useState([]);

    const [checkedSpecialityIds, setCheckedSpecialityIds] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);
    const toggleModal = () => setOpen(!open);

    const fetchData = () => {
        setLoading(true);
        getAttendeesType().then((response) => {
            const attendeesType = response?.data?.data?.items;
            const intervenant = attendeesType.find((item: any) => item.name === 'Intervenants');
            setAttendeeTypeId(intervenant.id);
            setLoading(false);
        });
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <Grid lg={12} container direction="row" alignItems={'center'} justifyContent="space-between">
                <Grid item>
                    <CustomText>Intervenant(s) :</CustomText>
                </Grid>

                <Grid item style={{ width: '60%' }}>
                {
                        attendesName?.length > 0 && (
                        <Box onClick={toggleModal} style={sxStyles.intervenantContainer}>
                            {attendesName?.map((attende: any) => (
                                <CustomText
                                    key={attende.id}
                                    style={{
                                        paddingRight: 20,
                                        marginBottom: 7,
                                    }}
                                    fontSize={baseFontSize.mini}
                                    fontFamily={baseFontFamily.OpenSansSemiBold}
                                >
                                    {fullName(attende?.firstName, attende?.lastName)}
                                </CustomText>
                            ))}
                        </Box>
                    )
                }
                    
                    <EventSpecialityWithProfessionals
                        open={open}
                        toggleModal={toggleModal}
                        setPayload={setPayload}
                        payload={payload}
                        attendeeTypeId={attendeeTypeId}
                        setAttendesName={setAttendesName}
                        professionalIds={professionalIds}
                        setProfessionalIds={setProfessionalIds}
                        loadingData={loadingData}
                        selectedWorkshopData={selectedWorkshopData}
                        workshopData={workshopData}
                        checkedSpecialityIds={checkedSpecialityIds}
                        setCheckedSpecialityIds={setCheckedSpecialityIds}
                    />
                </Grid>
            </Grid>

            <Spacer />
        </>
    );
};
