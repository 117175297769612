import { makeStyles } from '@mui/styles';
import { baseColors, baseFontFamily, baseMarging } from 'src/utils/constants/baseStyles';

const useStyles = makeStyles({
    container: {
        '& .css-1yhpg23-MuiTableCell-root': {
            fontWeight: 400,
            fontSize: '0.875rem',
            lineHeight: '1.43',
            letterSpacing: '0.01071em',
            display: 'table-cell',
            verticalAlign: 'inherit',
            borderBottom: 0,
            textAlign: 'center',
        }
    },
    header: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: baseMarging.marginBottom,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    title: {
        color: baseColors.black,
        fontFamily: baseFontFamily.BreeSerif,
        marginBottom: 10,
    },
    subtitle: {
        color: baseColors.green.primary,
        fontSize: '12px',
    },
});

export default useStyles;
