import { Dispatch, SetStateAction } from 'react';
import { PostProgramSkillPayload } from 'src/interfaces/interfaces';

const defaultProgramSkillPaylopad = {
    skillIds: [],
};

const handleSetSkillPayload = ({
    setPayload,
    preselectedSkillIds,
}: {
    setPayload: Dispatch<SetStateAction<PostProgramSkillPayload>>;
    preselectedSkillIds: Set<number>;
}) => {
    setPayload(() => {
        const skillIds = Array.from(preselectedSkillIds);
        return {
            skillIds: skillIds,
        };
    });
};

export { defaultProgramSkillPaylopad, handleSetSkillPayload };
