import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import WarningOutlinedIcon from '@mui/icons-material/WarningOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { baseColors } from 'src/utils/constants/baseStyles';

interface RiskIconProps {
    level: number;
}

const RiskIcon = (props: RiskIconProps = { level: 1 }) => {
    const { level } = props;

    const handleRiskIcon = (level: number) => {
        let riskIcon;

        switch (level) {
            case 1:
                riskIcon = <CheckCircleOutlinedIcon sx={{ color: baseColors.green.primary }} />;
                break;
            case 2:
                riskIcon = <ReportProblemOutlinedIcon sx={{ color: baseColors.orange.primary }} />;
                break;
            case 3:
                riskIcon = <WarningOutlinedIcon sx={{ color: 'red' }} />;
                break;
            default:
                riskIcon = <CheckCircleOutlinedIcon sx={{ color: baseColors.green.primary }} />;
        }

        return riskIcon;
    };

    return <> { handleRiskIcon(level) }</>;
};

export default RiskIcon;
