import { configureStore } from '@reduxjs/toolkit';
import dashboardReducer from 'src/reducer/Dashboard/DashboardReducer';
import { calendarEventReducer, planningEventReducer, eventInformationReducer } from 'src/reducer/Calendar/events';
import consultationReducer from 'src/reducer/Hdj/consultations';
import dayHospitalReducer, { hdjReducer } from 'src/reducer/Hdj/dayHospitals';
import {
    managementConsultationReducer,
    managementPatientsReducer,
    managementWorkshopReducer,
} from 'src/reducer/Hdj/management';
import { authReducer } from 'src/reducer/Auth/AuthReducer';
import { userReducer } from 'src/reducer/User/user';
import { programReducer } from 'src/reducer/Program/programs';
import notificationReducer from 'src/reducer/Notification/NotificationReducer';
import permissionReducer from 'src/reducer/Permission/PermissionReducer';
import authorizationReducer from 'src/reducer/Authorization/AuthorizationReducer';
import featureReducer from 'src/reducer/Feature/FeatureReducer';
import roleReducer from 'src/reducer/Role/RoleReducer';
import userHistoryReducer from 'src/reducer/UserHistory/UserHistoryReducer';
import { userListReducer } from 'src/reducer/User/userList';
import connexionReducer from 'src/reducer/Connexion/ConnexionReduicer';

const dayHospitalReducers = {
    dayHospitals: dayHospitalReducer,
    hdj: hdjReducer,
};

const managementReducers = {
    managementPatients: managementPatientsReducer,
    managementConsultations: managementConsultationReducer,
    managementWorkshops: managementWorkshopReducer,
};

const calendarReducers = {
    calendarEvent: calendarEventReducer,
    planningEvents: planningEventReducer,
    eventInformations: eventInformationReducer,
};

const programReducers = {
    programs: programReducer,
};

export const store = configureStore({
    reducer: {
        auth: authReducer,
        user: userReducer,
        userList: userListReducer,
        consultations: consultationReducer,
        ...dayHospitalReducers,
        dashboard: dashboardReducer,
        ...managementReducers,
        ...calendarReducers,
        ...programReducers,
        notifications: notificationReducer,
        permissions: permissionReducer,
        authorizations: authorizationReducer,
        features: featureReducer,
        userHistories: userHistoryReducer,
        roles: roleReducer,
        connexions: connexionReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
