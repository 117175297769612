import * as React from 'react';
import { baseColors } from 'src/utils/constants/baseStyles';

function DashboardInactif(props: any) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={26} height={26} fill="none" viewBox="0 0 26 26" {...props}>
            <path
                stroke={baseColors.green.primary}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.654}
                d="M18.143 1h3.92C23.683 1 25 2.327 25 3.964v3.953c0 1.636-1.316 2.964-2.938 2.964h-3.92c-1.622 0-2.938-1.328-2.938-2.964V3.964C15.204 2.327 16.52 1 18.143 1zM3.939 1h3.918c1.623 0 2.94 1.327 2.94 2.964v3.953c0 1.636-1.317 2.964-2.94 2.964H3.94C2.316 10.881 1 9.553 1 7.917V3.964C1 2.327 2.316 1 3.939 1zm0 14.119h3.918c1.623 0 2.94 1.327 2.94 2.965v3.952c0 1.637-1.317 2.964-2.94 2.964H3.94C2.316 25 1 23.673 1 22.036v-3.952c0-1.638 1.316-2.965 2.939-2.965zm14.204 0h3.92c1.621 0 2.937 1.327 2.937 2.965v3.952C25 23.673 23.684 25 22.062 25h-3.92c-1.622 0-2.938-1.327-2.938-2.964v-3.952c0-1.638 1.316-2.965 2.939-2.965z"
            />
        </svg>
    );
}

export default DashboardInactif;
