import { Grid } from '@mui/material';
import moment from 'moment';
import { FC } from 'react';
import { CustomText } from 'src/components/Text/CustomText';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import { localDateFormat } from 'src/utils/utils';

interface ProgramDateRowProps {
    checkedProgram: any;
}

const ProgramDateRow: FC<ProgramDateRowProps> = (props) => {
    const { checkedProgram } = props;
    const programDate = (date: string) => moment(date).format(localDateFormat);

    return (
        <Grid container justifyContent={'center'} style={{ marginTop: 10 }}>
            <Grid xs={7} item>
                <Grid container justifyContent={'space-evenly'} direction={'row'}>
                    <CustomText
                        fontSize={14}
                        fontFamily={baseFontFamily.OpenSansSemiBold}
                        color={baseColors.green.primary}
                    >
                        Début : {checkedProgram?.startDate ?? checkedProgram?.program?.startDate}
                    </CustomText>

                    <CustomText
                        fontSize={14}
                        fontFamily={baseFontFamily.OpenSansSemiBold}
                        color={baseColors.green.primary}
                    >
                        Fin : {checkedProgram?.endDate ?? checkedProgram?.program?.endDate}
                    </CustomText>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ProgramDateRow;
