import React, { FC } from 'react';

interface SpacerProps {
    height?: any;
    width?: any;
}
export const Spacer: FC<SpacerProps> = (props) => {
    const { height, width } = props;
    return <div style={{ width: width ? width : 0, height: height ? height : 20 }}></div>;
};
