import { useContext, useEffect, useState } from 'react';
import { UserContext } from 'src/providers/UserProvider';
import { PatientService } from 'src/services/Patients';
import useCheckUserRole from './useCheckUserRole';
import { fullName } from 'src/utils/utils';
import { uniqBy } from 'lodash';

interface UsePatientHooksProps {
    setActivePatient?: any;
    onSelectedPatient?: any;
    onPatientListLoaded?: any;
    searchQuery?: string;
    dateFilter?: any;
    setListPatient?: any;
    currentPatientId?: any;
    updateList?: any;
    setLoading?: any;
    isCoordinator?: any;
    defaultActivePatient?: number;
}

const usePatientHooks = ({
    onSelectedPatient,
    onPatientListLoaded,
    dateFilter,
    setListPatient,
    currentPatientId,
    updateList,
    setLoading,
    defaultActivePatient,
}: UsePatientHooksProps) => {
    const { roles } = useCheckUserRole();
    const [patients, setPatients] = useState<Array<any>>([]);
    const [fetching, setFetching] = useState<boolean>(true);
    const { userInfos: userInfosFromContext } = useContext(UserContext);
    const isCoordinator = roles?.isCoordinator ?? false;
    const professionalId = userInfosFromContext?.id;

    const stopLoading = () => {
        setFetching?.(false);
        setLoading?.(false);
    };

    const successfull = (data: any) => {
        stopLoading();

        /*const patientsData = data?.filter((p: any, i: number) => {
            const patinetActive = p.activated === 1;
            const patient = p.isAcceptedByPatient === 1 && p.patient.actived === 1;
            return isCoordinator ? patinetActive : patient;
        });*/
        const patientsData = data;

        const selectedPatient = patientsData?.filter((item: any) =>
            !isCoordinator ? item?.patient?.id === currentPatientId : item?.id === currentPatientId,
        );

        onSelectedPatient?.(
            currentPatientId && selectedPatient?.length > 0
                ? !isCoordinator
                    ? selectedPatient[0]?.patient
                    : selectedPatient[0]
                : !isCoordinator
                ? patientsData[0]?.patient
                : patientsData[0],
        );
        setPatients?.(patientsData);
        setListPatient?.(patientsData);
        onPatientListLoaded?.(patientsData);
    };

    const handleFetchPatientsByProfessional = (isExternal = 0) => {
        if (professionalId > 0) {
            PatientService.getAcceptedByProfessional({
                professionalId,
                dateFilter,
            })
                .then((response) => {
                    const responses = response?.data?.data;
                    if (responses.length > 0) {
                        const lengthPatient = responses?.length;
                        let dataPatient = [];
                        for (let i = 0; i < lengthPatient; i++) {
                            if (responses[i]['patient']?.roles[0] === 'ROLE_PATIENT') {
                                let dataPat = responses[i];
                                dataPatient.push(dataPat);
                            }
                        }
                        if (isExternal) {
                            dataPatient = dataPatient.filter((pt) => pt.isAcceptedByPatient);
                        }
                        successfull(dataPatient);
                    } else {
                        successfull([]);
                    }
                })
                .catch(() => stopLoading());
        }
    };

    const handleFetchPatients = () => {
        PatientService.getPatients({ dateFilter })
            .then((response) => {
                const responses = response?.data?.data;
                successfull(responses);
            })
            .catch(() => stopLoading());
    };

    useEffect(() => {
        /*!isCoordinator ? handleFetchPatientsByProfessional(userInfosFromContext?.isExternal) :*/
        handleFetchPatients();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        isCoordinator,
        dateFilter,
        onSelectedPatient,
        onPatientListLoaded,
        currentPatientId,
        updateList,
        professionalId,
        defaultActivePatient,
    ]);

    const optimezedPatients = () => {
        const items = Array.from(patients, (item: any) => ({
            id: item?.patient ? item?.patient?.id : item?.id,
            lastName: item?.patient ? item?.patient?.lastName : item?.lastName,
            firstName: item?.patient ? item?.patient?.firstName : item?.firstName,
        }));

        const filteredById = uniqBy(items, 'id');

        return filteredById ?? [];
    };

    const handleOptimizePatientsData = (patientData: any) => {
        const items = Array.from(patientData, (item: any) => ({
            id: item?.patient ? item?.patient?.id : item?.id,
            lastName: item?.patient ? item?.patient?.lastName : item?.lastName,
            firstName: item?.patient ? item?.patient?.firstName : item?.firstName,
        }));

        const filteredById = uniqBy(items, 'id');

        return filteredById ?? [];
    };

    const optimezedListForPatientsMenu = () => {
        const items = Array.from(patients, (item: any) => ({
            ...item,
            fullName: fullName(
                item?.patient ? item?.patient?.lastName : item?.lastName,
                item?.patient ? item?.patient?.firstName : item?.firstName,
            ),
            patientId: item?.patient ? item?.patient?.id : item?.id,
        }));

        const filteredData = uniqBy(items, 'patientId');

        return filteredData ?? [];
    };

    return {
        patients,
        fetching,
        optimezedPatients,
        optimezedListForPatientsMenu,
        handleFetchPatients,
        handleFetchPatientsByProfessional,
        handleOptimizePatientsData,
    };
};

export { usePatientHooks };
