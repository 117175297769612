import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    container: {
        paddingRight: 20,
    },
    quizItemContainer: {
        padding: 10,
    },
    titleContainer: {
        marginBottom: 20,
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
    },
    headerContainer: {},
    content: {},
    remove: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    addAction: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    removeBtn: {
        alignItems: 'flex-end',
    },
    addMoreRadioInput: {
        alignItems: 'flex-start',
    },
    addMoreRadio: {
        marginLeft: 125,
    },
    validateResponses: {
        marginLeft: 20,
    },
    footerBtn: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 70,
    },
    textInputContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    textInput: {
        flex: 1 / 2,
    },
    radioTextInput: {
        flex: 1 / 2,
        marginLeft: 20,
        marginTop: 5,
    },
    questionItem: {
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: 25,
        paddingRight: 25,
        paddingTop: 15,
        paddingBottom: 10,
    },
    rowContent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    quizInput: {
        marginLeft: 20,
        width: '80%',
    },
    quizItems: {
        overflow: 'hidden',
        overflowY: 'scroll',
        height: 'calc(70vh )',
    },
    radio: {},
    radioItem: {
        display: 'flex',
        flexDirection: 'row',
        // justifyContent: 'space-around',
        alignItems: 'center',
    },
}));

const sxStyles: any = {
    container: {},
};

export { sxStyles };

export default useStyles;
