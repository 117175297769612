import { FC, useContext, useEffect } from 'react';
import useStyles from './styles';
import { Box } from '@mui/system';
import HdjHeader from './HdjHeader';
import HdjContent from './HdjContent';
import HdjFooter from './HdjFooter';
import { HdjContext } from 'src/providers/HdjProvider';
import useFetchHdj from 'src/hooks/useFetchHdj';
import { UserContext } from '../../providers/UserProvider';
import useAdminRedirection from 'src/hooks/useAdminRedirection';

const Hdj: FC = () => {
    const classes = useStyles();
    const { loadingHdjList, searchQuery, setSearchQuery } = useContext(HdjContext);
    const { handleFetchHdj, dayHospitals, setNameFilter, setSpecialityId, nameFilter, specialityId } = useFetchHdj();
    const { isCoordinator } = useContext(UserContext);

    useEffect(() => {
        handleFetchHdj('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [specialityId]);

    useEffect(() => {
        setSearchQuery('');
    }, []);

    useAdminRedirection();

    return (
        <Box flex={1} marginRight={1} className={classes.container}>
            <Box sx={{ flex: 1, padding: 1 }}>
                <HdjHeader
                    nameFilter={nameFilter}
                    setNameFilter={setNameFilter}
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    setSpecialityId={setSpecialityId}
                    handleFetchHdj={handleFetchHdj}
                />
            </Box>
            <Box
                sx={{ flex: 6, padding: 1 }}
                style={{
                    overflow: 'hidden',
                    overflowY: 'scroll',
                }}
            >
                <HdjContent
                    loadingHdjList={loadingHdjList}
                    specialityId={specialityId}
                    searchQuery={searchQuery}
                    nameFilter={nameFilter}
                    dayHospitals={dayHospitals}
                />
            </Box>
            {isCoordinator && (
                <Box sx={{ flex: 1 }}>
                    <HdjFooter loadingHdjList={loadingHdjList} />
                </Box>
            )}
        </Box>
    );
};

export default Hdj;
