import { EventPlanningViewType, PlanningAgendaFilters } from 'src/interfaces/interfaces';
import axios from '../AxiosInstance';
import qs from 'qs';

const getEvent = (eventId: number) => {
    let additionalUrl: string = '';

    if (eventId > 0) {
        additionalUrl = additionalUrl + `${eventId}`;
    }

    return axios.get(`api/events/${additionalUrl}`);
};

const getEventTypes = () => {
    let additionalUrl: string = '';
    
    if(additionalUrl.trim().length > 0){
        additionalUrl+= '/'+additionalUrl
    }

    return axios.get(`api/eventTypes${additionalUrl}`);
};

const getPlanningEvent = (
    checkedDate: string,
    viewType: EventPlanningViewType['viewType'],
    filters: PlanningAgendaFilters,
) => {
    let additionalUrl: string = '';
    let eventApi: string = 'events/planning';
    //&viewType=daily&customLocation=45
    const eventFilters = filters?.checkedEvents && filters?.checkedEvents.length > 0 ? filters?.checkedEvents : [];
    const programFilters =
        filters?.checkedPrograms && filters?.checkedPrograms.length > 0 ? filters?.checkedPrograms : [];
    const roomFilters = filters?.checkedRooms && filters?.checkedRooms.length > 0 ? filters?.checkedRooms : [];
    const intervenantsFilters =
        filters?.intervenantsIds && filters?.intervenantsIds.length > 0 ? filters?.intervenantsIds : [];

    if (checkedDate !== '') {
        additionalUrl += `&date=${checkedDate}`;
    }

    if (viewType) {
        additionalUrl += `&viewType=${viewType}`;
    }

    if (filters && filters.checkedSpecialitytId && filters.checkedSpecialitytId > 0) {
        additionalUrl += `&specialityId=${filters?.checkedSpecialitytId}`;
    }

    if (intervenantsFilters?.length > 0) {
        additionalUrl += `&isPublic=1`;
    }

    if (filters?.durations?.length && filters?.durations?.length > 0) {
        additionalUrl += `&minDuration=${filters?.durations?.[0]}&maxDuration=${filters?.durations?.[1]}`;
    }

    if (filters?.yearAndMonth) {
        additionalUrl += `&yearAndMonth=${filters?.yearAndMonth}`;
    }
    if (filters?.checkedLocations) {
        additionalUrl += `&customLocation=${filters?.checkedLocations}`;
    }

    return axios.get(`api/${eventApi}?paginated=false${additionalUrl}`, {
        params: {
            eventTypeCategoryFilters: eventFilters,
            programIds: programFilters,
            roomIds: roomFilters,
            userIds: intervenantsFilters,
        },
        paramsSerializer: (params) => qs.stringify(params),
    });
};

const createNewEvent = (data: any) => axios.post('api/events', data);

const getEventInformation = (checkedDate: string) => {
    let additionalUrl: string = '';

    if (checkedDate !== '') {
        additionalUrl = additionalUrl + `?date=${checkedDate}`;
    }

    return axios.get(`api/events/information${additionalUrl}`);
};

const updatePresence = (eventId: number, data: any) => axios.put(`api/events/${eventId}/presence`, data);

const specificCaregiverEvent = (caregiverId: number, viewType?: EventPlanningViewType['viewType']) => {
    let additionalUrl: string = '';

    if (viewType === 'weekly') {
        additionalUrl += '&viewType=weekly';
    }

    return axios.get(`api/users/${caregiverId}/events?paginated=false${additionalUrl}`);
};

const updateEventViaDnd = (eventId: number, data: any) => axios.put(`api/events/${eventId}`, data);

const deleteEvent = (eventId: number) => axios.delete(`api/events/${eventId}`);

const getSharedAgendaReceivers = (id: number) => axios.get(`api/users/${id}/agendaSharers`);

const getCalendars = () => axios.get(`api/calendars`);

const getFreeSlotDuration = () => axios.get(`api/freeslotDuration`);

const EventService = {
    getEvent,
    getEventTypes,
    createNewEvent,
    getPlanningEvent,
    getEventInformation,
    updatePresence,
    specificCaregiverEvent,
    updateEventViaDnd,
    deleteEvent,
    getSharedAgendaReceivers,
    getCalendars,
    getFreeSlotDuration
};

export default EventService;
