import { makeStyles } from '@mui/styles';
import { baseColors } from 'src/utils/constants/baseStyles';

const useStyles = makeStyles({
    container: {
        '& .rwdpDayPickerContainer': {
            padding: '0 10px',
        },
        '& .rwdp-DayCircle': {
            width: '45px',
            height: '55px',
            borderRadius: '5px',
            color: baseColors.black,
            backgroundColor: baseColors.green.light,
            borderColor: baseColors.green.light,
        },
        '& .rwdp-DayCircle-selected': {
            color: baseColors.white,
            borderRadius: '5px',
            backgroundColor: baseColors.green.primary,
            width: '45px',
            height: '55px',
            borderColor: baseColors.green.primary,
        },
        '& .rwdp-DayCircle-today': {
            display: 'none',
        },
        '& .rwdpDayBoxDeskto': {
            display: 'none',
        },
        '& .rwdpPrevWeekArrow': {
            display: 'inline-block',
            border: `solid ${baseColors.green.primary}`,
            borderWidth: '0 3px 3px 0',
            padding: '5px',
            transform: 'rotate(135deg)',
            position: 'absolute',
            top: 'calc(52% + 3px)',
        },
        '& .rwdpNextWeekArrow': {
            display: 'inline-block',
            border: `solid ${baseColors.green.primary}`,
            borderWidth: '0 3px 3px 0',
            padding: '5px',
            transform: 'rotate(-45deg)',
            position: 'absolute',
            top: 'calc(52% + 3px)',
        },
        '& .rwdp-DayCircle-unavailable-text': {
            display: 'none',
        },
    },
});

export default useStyles;
