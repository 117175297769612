import { Box, Grid } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import CustomModal from 'src/components/CustomModal';
import { CustomSelect } from 'src/components/InputField/CustomSelect';
import { InputField } from 'src/components/InputField/InputField';
import { CustomText } from 'src/components/Text/CustomText';
import { Consultation } from 'src/interfaces/interfaces';
import { getAttendeesBySpeciality, getAttendeesSpeciality, getAttendeesType } from 'src/services/Attendees';
import { durations } from 'src/utils/constants/constants';
import { isNotBlank } from 'src/utils/helpers/InputValidator';
import { postConsultations } from 'src/services/Consultations';
import CustomCircleLoader from '../../../components/CustomLoader/CustomCircleLoader';
import HdjConsultationTypeCreate from './HdjFormContent/HdjConsultationForm/HdjConsultationTypeCreate';
import HdjRoomAndRemark from './HdjFormContent/HdjConsultationForm/HdjRoomAndRemark';
import { toast } from 'react-toastify';
import { fullName } from 'src/utils/utils';
import { checkFormValidity } from '../HdjLogic';
import WorkshopService from 'src/services/Workshop/workshop.service';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import { defaultDuration, handleSetPayload, thirtyMinitDuration } from 'src/utils/constants/constants';

interface HDJItemFormProps {
    modalOpen: boolean;
    modalToggle: Function;
    dayHospitalId?: number;
    setUpdateList?: any;
    consultationData?: any;
    setConsultation?: any;
    isWorkshop?: boolean;
    validateBtnLabel?: string;
}

const defaultConsultationValue: Consultation = {
    name: '',
    specialityTypeId: 0,
    consultationTypeId: 0,
    duration: '',
    remark: '',
    roomId: 0,
    attendees: [],
    dayHospitalId: undefined,
};

export const HDJConsultationAndWorkshopForm: FC<HDJItemFormProps> = (props) => {
    const { modalOpen, modalToggle, dayHospitalId, setUpdateList, consultationData, isWorkshop, validateBtnLabel } =
        props;
    const [loadingConsultationForm, setLoadingConsultationForm] = useState(false);
    const [lockConsultationInput, setLockConsultationInput] = useState(false);
    const [payload, setPayload] = useState<any>({
        ...defaultConsultationValue,
        dayHospitalId: dayHospitalId ?? undefined,
    });
    const [attendes, setAttendes] = useState<{ id: number; name: string }[]>([]);
    const [attendeeTypeId, setAttendeeTypeId] = useState(null);
    const [specialites, setSpecialites] = useState([] as any);
    const [fetchingAttendeesSpeciality, setFetchingAttendeesSpeciality] = useState<boolean>(false);
    const [fetchingAttendeesType, setFetchingAttendeesType] = useState<boolean>(false);
    const [fetchingAttendeesBySpeciality, setFetchingAttendeesBySpeciality] = useState<boolean>(false);

    const handleFetchAttendeesSpeciality = () => {
        setFetchingAttendeesSpeciality(true);
        getAttendeesSpeciality()
            .then((response) => {
                const responses = response?.data?.data?.items;
                setSpecialites(responses);
                if (responses?.length > 0) {
                    setPayload((old: any) => ({
                        ...old,
                        specialityTypeId: responses[0].id,
                    }));
                }
                setFetchingAttendeesSpeciality(false);
            })
            .catch(() => setFetchingAttendeesSpeciality(false));
    };

    const handleFecthAttendeesType = () => {
        setFetchingAttendeesType(true);
        getAttendeesType()
            .then((response) => {
                let attendeesType = response?.data?.data?.items;
                let intervenant = attendeesType.filter((item: any) => item.name === 'Intervenants')[0];
                setAttendeeTypeId(intervenant.id);
                setFetchingAttendeesType(false);
            })
            .catch(() => setFetchingAttendeesType(false));

        if (consultationData?.id == 1) {
            const presentiel = consultationData?.consultationTypeId ? 1 : 2;
            setPayload((prev: any) => ({
                ...prev,
                consultationTypeId: presentiel,
            }));
        }
    };

    const handleFetchAttendeesBySpeciality = (specialityTypeId: number) => {
        setFetchingAttendeesBySpeciality(true);
        getAttendeesBySpeciality(specialityTypeId,true,true,true)
            .then((response) => {
                if (response?.data?.data?.length > 0) {
                    const responses = Array.from(response?.data?.data, (item: any) => ({
                        id: item?.id,
                        name: fullName(item?.firstName, item?.lastName),
                    }));
                    setAttendes(responses);
                }
                setFetchingAttendeesBySpeciality(false);
            })
            .catch(() => setFetchingAttendeesBySpeciality(false));
    };
    const handleFetchData = () => {
        handleFetchAttendeesSpeciality();
        handleFecthAttendeesType();
    };

    useEffect(() => {
        handleFetchData();
    }, []);

    const successfull = (message: string) => {
        toast.success(message);
        setLoadingConsultationForm(false);
        setLockConsultationInput(false);
        setUpdateList((prev: any) => !prev);
        setPayload(defaultConsultationValue);
        modalToggle(false);
    };

    const failed = (message: string) => {
        toast.error(message);
        setLoadingConsultationForm(false);
        setLockConsultationInput(false);
    };

    const performAddConsultation = () => {
        setLoadingConsultationForm(true);
        setLockConsultationInput(true);
        const finalPayload = { ...payload };

        if (isWorkshop) {
            WorkshopService.postWorkshop(finalPayload)
                .then((response) => {
                    successfull('Atelier ajouté avec succès');
                })
                .catch(() => {
                    failed("Erreur d'ajout atelier");
                });
        } else {
            postConsultations(finalPayload)
                .then((response) => {
                    successfull('Consultation ajouté avec succès');
                })
                .catch(() => {
                    failed("Erreur d'ajout consultation");
                });
        }
    };

    const newDurations = durations.flatMap((item: any) => item);

    const intervenantSpecialities =
        specialites?.length > 0
            ? [{ id: 0, name: "Sélectionner la spécialté de l'intervenant" }, ...specialites]
            : [{ id: 0, name: "Sélectionner la spécialté de l'intervenant" }];
    const attendesData = [{ id: 0, name: "Sélectionner l'intervenant" }, ...attendes];
    const durationsData = [{ id: 0, name: 'Sélectionner une durée' }, ...newDurations];
    const isVisio = payload?.consultationTypeId === 2;
    const loadingData = <CustomCircleLoader height={25} width={25} />;
    const canNotSubmit = checkFormValidity(payload);

    return (
        <CustomModal
            width={600}
            title={isWorkshop ? 'Nouvel atelier' : 'Nouvelle consultation'}
            toggleModal={() => modalToggle(false)}
            open={modalOpen}
            footer={
                <Box display="flex" justifyContent="center">
                    <CustomLoadingButton
                        disabled={canNotSubmit}
                        loading={loadingConsultationForm}
                        handleClick={performAddConsultation}
                        width={300}
                        label={validateBtnLabel ?? 'Valider'}
                    />
                </Box>
            }
        >
            <Grid
                style={{ marginTop: 20, marginBottom: 10 }}
                lg={12}
                container
                direction="row"
                alignItems={'center'}
                justifyContent="space-between"
            >
                <Grid item>
                    <CustomText>Nom de {isWorkshop ? "l'atelier" : 'la consultation'} :</CustomText>
                </Grid>
                <InputField
                    label={''}
                    disabled={lockConsultationInput}
                    onChange={(value: any) => {
                        setPayload((old: any) => ({ ...old, name: value }));
                    }}
                    validator={isNotBlank}
                    errorMessage={`Nom de ${isWorkshop ? "l'atelier" : 'la consultation'} obligatoire`}
                    placeholder={`Entrer un nom de ${isWorkshop ? "l'atelier" : 'la consultation'}...`}
                    width={360}
                    value={payload?.name}
                    idSuffix={isWorkshop ? 'nom_workshop' : 'nom_consultation'}
                />
            </Grid>

            <Grid
                lg={12}
                container
                direction="row"
                alignItems={'center'}
                justifyContent="space-between"
                style={{ marginBottom: 10 }}
            >
                <Grid item>
                    <CustomText> {isWorkshop ? "Durée d'atelier" : 'Durée de la consultation'} : </CustomText>
                </Grid>
                <Grid item style={{ width: '60%' }}>
                    <CustomSelect
                        disabled={lockConsultationInput}
                        onChange={(value: number) => handleSetPayload(value, setPayload)}
                        label=""
                        labelKey={'name'}
                        items={durationsData}
                        idSelect="hdj-duration"
                        width={360}
                    />
                </Grid>
            </Grid>

            <Grid
                lg={12}
                container
                direction="row"
                alignItems={'center'}
                justifyContent="space-between"
                style={{ marginBottom: 10 }}
                
            >
                <Grid item>
                    <CustomText>Spécialité de l'intervenant :</CustomText>
                </Grid>
                <Grid item style={{ width: '60%' }}>
                    <CustomSelect
                        isLoading={fetchingAttendeesSpeciality}
                        disabled={lockConsultationInput}
                        onChange={(value: any) => {
                            handleFetchAttendeesBySpeciality(+value);
                            setPayload((old: any) => ({
                                ...old,
                                specialityTypeId: parseInt(value),
                                attendees: [],
                            }));
                        }}
                        label=""
                        labelKey={'name'}
                        items={intervenantSpecialities}
                        idSelect="hdj-name-3"
                        width={360}
                    />
                </Grid>
            </Grid>

            <Grid
                lg={12}
                container
                direction="row"
                alignItems={'center'}
                justifyContent="space-between"
                style={{ marginBottom: 10 }}
            >
                <Grid item>
                    <CustomText>Intervenant(s) :</CustomText>
                </Grid>
                <Grid item style={{ width: '60%' }}>
                    <CustomSelect
                        isLoading={fetchingAttendeesBySpeciality}
                        disabled={lockConsultationInput}
                        onChange={(value: any) => {
                            setPayload((old: any) => ({
                                ...old,
                                attendees: value === 0 ? [] : [{ id: parseInt(value), type: attendeeTypeId }],
                            }));
                        }}
                        labelKey={'name'}
                        label=""
                        items={attendesData}
                        idSelect="hdj-name-2"
                        width={360}
                    />
                </Grid>
            </Grid>

            <HdjConsultationTypeCreate
                loadingData={loadingData}
                setConsultation={setPayload}
                consultationData={consultationData}
            />

            <HdjRoomAndRemark
                isVisio={isVisio}
                loadingData={loadingData}
                setConsultation={setPayload}
                consultationData={consultationData}
            />
        </CustomModal>
    );
};
