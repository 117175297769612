import { baseColors } from 'src/utils/constants/baseStyles';

function IconCheckList(props: any) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={52} height={70} fill="none" viewBox="0 0 52 70" {...props}>
            <path fill="#fff" d="M0 2h44a5 5 0 015 5v63H5a5 5 0 01-5-5V2z" />
            <path fill="#64C7C7" d="M4 6h36a5 5 0 015 5v55H9a5 5 0 01-5-5V6z" />
            <rect width={24} height={2} x={14} y={22} fill="#fff" rx={1} />
            <rect width={26} height={2} x={14} y={32} fill="#fff" rx={1} />
            <rect width={17} height={2} x={14} y={42} fill="#fff" rx={1} />
            <rect width={26} height={2} x={14} y={52} fill="#fff" rx={1} />
            <circle cx={9.525} cy={23.164} r={1} fill="#fff" />
            <circle cx={9.525} cy={33.164} r={1} fill="#fff" />
            <circle cx={9.525} cy={43.164} r={1} fill="#fff" />
            <circle cx={9.525} cy={53.164} r={1} fill="#fff" />
            <path
                fill="#1B8E95"
                d="M17.049 5.115c0-.471.381-.853.852-.853h13.64c.47 0 .852.382.852.853v3.41H17.05v-3.41zM19.607.852c0-.47.382-.852.853-.852h8.524c.471 0 .853.382.853.852v3.41h-10.23V.852z"
            />
            <circle cx={42} cy={57} r={10} fill={baseColors.orange.primary} />
            <path
                fill="#fff"
                d="M39.577 56.021a.75.75 0 10-1.154.958l1.154-.958zM41.074 59l-.577.479.474.571.576-.468-.473-.582zm5.399-3.418a.75.75 0 00-.946-1.164l.946 1.164zm-8.05 1.397l2.074 2.5 1.154-.958-2.074-2.5-1.154.958zm3.124 2.603l4.926-4-.946-1.164-4.926 4 .946 1.164z"
            />
        </svg>
    );
}

export default IconCheckList;
