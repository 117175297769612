import { Box } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import CustomModal from 'src/components/CustomModal';
import ListItemFormCheckBox from 'src/components/ListItemFormCheckBox';

interface AddIntervenantsProps {
    toggleModal: any;
    modalOpen: boolean;
    intervenants: Array<any>;
    setEventPayload?: any;
    setSelectedIntervenants?: any;
    patientsIds?: any;
    setPatientsIds?: any;
    setValidated?:any;
    loadingAttendees?: boolean;
}

const AddIntervenants: FC<AddIntervenantsProps> = (props) => {
    const {
        modalOpen,
        toggleModal,
        intervenants,
        setEventPayload,
        setSelectedIntervenants,
        patientsIds,
        setPatientsIds,
        setValidated,
        loadingAttendees,
    } = props;
    const [searchKey, setSearchKey] = useState<string>('');
    const attendeesData = patientsIds.map((intervenant: any) => ({ id: Number(intervenant), type: 2 }));
    const handleChange = (selected: any) => setPatientsIds(Array.from(selected));
    const handleSearch = (value: string) => setSearchKey(value);
    const handleValidate = () => {
        setEventPayload((prev: any) => {
            return { ...prev, attendees: attendeesData };
        });
        toggleModal();
        setValidated(true);
    };

    const disableBtn = loadingAttendees ?? intervenants?.length === 0;

    return (
        <CustomModal title="Sélectionner un ou plusieurs intervenant(s)" toggleModal={toggleModal} open={modalOpen}>
            <Box style={{ marginTop: 20, marginBottom: 25 }}>
                <ListItemFormCheckBox
                    isIntervenants
                    noSearchHeader
                    contentHeight={225}
                    maxItems={50}
                    labelKey="name"
                    items={intervenants}
                    selectedRows={new Set(patientsIds)}
                    handleChange={(selected: Set<number>) => handleChange(selected)}
                    handleSearch={handleSearch}
                    setSelectedIntervenants={setSelectedIntervenants}
                    loading={loadingAttendees}
                />
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <CustomLoadingButton
                    width={350}
                    disabled={patientsIds.length===0}
                    handleClick={handleValidate}
                    loading={false}
                    label="Valider"
                />
            </Box>
        </CustomModal>
    );
};

export default AddIntervenants;
