import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { baseColors, baseFontWeight } from 'src/utils/constants/baseStyles';
import Button from '@mui/material/Button';

interface CustomNewUserProps {
    btnLabel: string;
    toggleModal: any;
    marginRight?: boolean;
    prevSelectedUserId:number[];
}

const CustomNewUserBtn: FC<CustomNewUserProps> = (props) => {
    const { btnLabel, toggleModal, marginRight, prevSelectedUserId } = props;
    const len = prevSelectedUserId.length;
    let disabled = true;
    (len>0 ? disabled=false : disabled=true);
    
    return (
        <Box sx={{ width: '40%' }} marginRight={marginRight ? 2 : undefined}>
            <Button
                onClick={toggleModal}
                style={{
                    width: '100%',
                    color: baseColors.white,
                    backgroundColor: baseColors.green.primary,
                    borderColor: 'transparent',
                    opacity: disabled ? 0.5 : undefined,
                }}
                disabled={disabled}
                variant="outlined"
                size="large"
            >
                <Typography fontWeight={baseFontWeight.semiBold} fontSize={10}>
                    {btnLabel}
                </Typography>
            </Button>
        </Box>
    );
};

export default CustomNewUserBtn;
