const SetUserRole = async (value: string) => {
    try {
        await sessionStorage.setItem('userRole', JSON.stringify(value));
    } catch (error) {
        throw new Error('Error setting user role');
    }
};

const SetPlateformType = async () => {
    try {
        await sessionStorage.setItem('iscpts', JSON.stringify(true));
    } catch (error) {
        throw new Error('Error setting plateform type');
    }
};

const GetUserRole = async () => {
    try {
        const res = (await sessionStorage.getItem('userRole')) ?? '';
        return JSON.parse(res);
    } catch (error) {
        throw new Error('Error getting user role');
    }
};

const GetPlateformType = async () => {
    try {
        const res = (await sessionStorage.getItem('iscpts')) ?? '';
        return JSON.parse(res);
    } catch (error) {
        throw new Error('Error getting plateform type');
    }
};

const ClearStorage = async () => {
    try {
        await sessionStorage.clear();
    } catch (error) {
        throw new Error('Error clearing sessionStorageService');
    }
};

const SetDEVisioStatusByPatient = async ({ visioId }: { visioId: string }) => {
    const value = {
        started: true,
    };

    try {
        await sessionStorage.setItem(`educDiagVisioId_${visioId}`, JSON.stringify(value));
    } catch (error) {
        throw new Error('Error setting user visio status');
    }
};

const GetDEVisioStatusByPatient = async ({ visioId }: { visioId: string }) => {
    try {
        const res = await sessionStorage.getItem(`educDiagVisioId_${visioId}`);
        return JSON.parse(res ?? '');
    } catch (error) {
        throw new Error('Error getting user visio status');
    }
};

const SetIntervierwByAxis = async ({axisId, patientId, value} : {axisId : number,patientId : number, value : string}) => {
    try {
        await sessionStorage.setItem(`interview_${axisId}_${patientId}`, value);
    } catch (error) {
        throw new Error('Error getting interview status');
    }
}

const GetIntervierwByAxis = async ({ axisId, patientId }: { axisId: number,patientId : number }) => {
    try {
        const res = await sessionStorage.getItem(`interview_${axisId}_${patientId}`);
        return res ?? '';
    } catch (error) {
        throw new Error('Error getting interview status');
    }
};

export const LocalStorageService = {
    SetUserRole,
    SetPlateformType,
    GetUserRole,
    GetPlateformType,
    ClearStorage,
    SetDEVisioStatusByPatient,
    GetDEVisioStatusByPatient,
    SetIntervierwByAxis,
    GetIntervierwByAxis
};
