import { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/system';
import { useParams } from 'react-router-dom';
import { defaultConclusionValue, handleSetPayloadWithSavedConclusion } from './Logic';
import useStyles from './styles';
import { ConclusionPayloadType } from 'src/interfaces/types';
import ConclusionFormCardItem from './ConclusionFormItem/ConclusionFormItem';
import { Spacer } from 'src/components/Spacer/Spacer';
import {
    DraftConclusion,
    OptimizedItem,
    PreselectedPatientProgram,
    ProgramContent,
    ProgramSkill,
} from 'src/interfaces/interfaces';
import SkillsFormContainer from './SkillsFormContainer/SkillsFormContainer';
import { ConclusionFormFooter } from './ConclusionFormFooter';
import {
    SetCheckedSkillInPayloadWhileUpdatingGoals,
    UserProgram,
} from './ConclusionCheckBoxForm/ConclusionCheckBoxForm.utils';
import { useFetchPrograms } from 'src/hooks/ReactQueryHooks/useProgramRQHooks';
import { CustomText } from 'src/components/Text/CustomText';
import { CustomSelect } from 'src/components/InputField/CustomSelect';
import { baseFontFamily } from 'src/utils/constants/baseStyles';
import { Motivations } from 'src/utils/constants/constants';
import useWorkshops from 'src/hooks/useWorkshops';
import useArticleHooks from 'src/hooks/useArticleHooks';
import CustomCircleLoader from 'src/components/CustomLoader/CustomCircleLoader';
interface ConclusionFormProps {
    draftConclusion: DraftConclusion;
    educationalDiagnosisId: number;
    preSelectedPatientProgram: PreselectedPatientProgram;
    fetchingPreselectedProgram?: boolean;
    setShowForm: Dispatch<SetStateAction<boolean>>;
    setUpdateList: Dispatch<SetStateAction<boolean>>;
}

export const ConclusionForm: FC<ConclusionFormProps> = ({
    preSelectedPatientProgram,
    draftConclusion,
    setShowForm,
    setUpdateList,
    fetchingPreselectedProgram,
}) => {
    const params = useParams<any>();
    const classes = useStyles();
    const patientId: number = +params?.id || 0;
    const defaultConclusion = { ...defaultConclusionValue, patientId };
    const [payload, setPayload] = useState<ConclusionPayloadType>(defaultConclusion);
    const [lockInput, setLockInput] = useState(false);
    const [isEditMode, setIsEditMode] = useState<boolean>(true);
    const [disabledDefaultPrechecked, setDisabledDefaultPrechecked] = useState(false);
    const [updateGoalIdsInSkills, setUpdateGoalIdsInSkills] = useState(false);

    const [justAddedSkills, setJustAddedSkills] = useState<Array<ProgramSkill>>([]);
    const [skills, setSkills] = useState<Array<ProgramSkill>>([]);
    const [motivation, setMotivation] = useState<OptimizedItem>({
        id: 0,
        name: ' ',
    });

    const { handleFetchWorkshops } = useWorkshops({});
    const { handleFetchArticles } = useArticleHooks();

    const { data: allPrograms, isLoading: fetchingMainProgram } = useFetchPrograms({});

    const patientProgram: ProgramContent | undefined = useMemo(() => {
        return UserProgram({
            preSelectedPatientProgram,
            allPrograms,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allPrograms, preSelectedPatientProgram?.id]);

    const loadingData = fetchingMainProgram || fetchingPreselectedProgram;

    useEffect(() => {
        setSkills(() => {
            const skillFromProgram = [...(patientProgram?.skills ?? []), ...(justAddedSkills ?? [])];
            if (draftConclusion?.id) {
                const skillFromDraft = [...(draftConclusion?.conclusionSkills ?? []), ...(justAddedSkills ?? [])];
                return skillFromDraft;
            }
            return skillFromProgram;
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patientProgram?.skills, justAddedSkills, draftConclusion]);

    useEffect(() => {
        handleSetPayloadWithSavedConclusion({
            draftConclusion,
            setPayload,
            patientId,
            programId: preSelectedPatientProgram?.id ?? 0,
            setMotivation,
        });
    }, [draftConclusion, patientId, preSelectedPatientProgram?.id]);

    useEffect(() => {
        if (updateGoalIdsInSkills) {
            SetCheckedSkillInPayloadWhileUpdatingGoals({
                skills: skills,
                payload,
                setPayload,
            });
            setUpdateGoalIdsInSkills(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [skills, payload.skills, updateGoalIdsInSkills]);

    const MotivationsData = [{ id: 0, name: 'Sélectionner une étape' }, ...Motivations];
    const handleSetMotivation = (motivationId: number) => {
        const selctedMotivation = MotivationsData?.find((motivation: OptimizedItem) => motivation.id === motivationId);
        setMotivation(selctedMotivation as OptimizedItem);
        setPayload((prev: any) => ({
            ...prev,
            motivation: selctedMotivation?.name,
        }));
    };

    useEffect(() => {
        handleFetchWorkshops();
        handleFetchArticles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Box className={classes.container}>
                <ConclusionFormCardItem
                    draftConclusion={draftConclusion}
                    isEditMode={isEditMode}
                    preSelectedPatientProgram={preSelectedPatientProgram}
                    defaultValue={payload?.priorityAxisCare}
                    label="Axe prioritaire de soin"
                    keyValue="priorityAxisCare"
                    setPayload={setPayload}
                />

                <Spacer />

                <ConclusionFormCardItem
                    draftConclusion={draftConclusion}
                    isEditMode={isEditMode}
                    preSelectedPatientProgram={preSelectedPatientProgram}
                    defaultValue={payload?.freins}
                    label="Freins"
                    keyValue="freins"
                    setPayload={setPayload}
                />

                <Spacer />

                <ConclusionFormCardItem
                    draftConclusion={draftConclusion}
                    isEditMode={isEditMode}
                    preSelectedPatientProgram={preSelectedPatientProgram}
                    defaultValue={payload?.levers}
                    label="Leviers"
                    keyValue="levers"
                    setPayload={setPayload}
                />

                <Spacer />

                <ConclusionFormCardItem
                    draftConclusion={draftConclusion}
                    isEditMode={isEditMode}
                    preSelectedPatientProgram={preSelectedPatientProgram}
                    defaultValue={payload?.expectations}
                    label="Attentes"
                    keyValue="expectations"
                    setPayload={setPayload}
                />

                <Spacer />

                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        padding: '0 20px',
                    }}
                >
                    <div>
                        <CustomText style={{ fontFamily: baseFontFamily.OpenSansSemiBold }}>
                            Evaluation de la motivation au changement:
                        </CustomText>
                    </div>

                    <div style={{ width: '50%', marginLeft: 50 }}>
                        <CustomSelect
                            disabled={!isEditMode}
                            onChange={(value: any) => handleSetMotivation(value)}
                            label=""
                            labelKey="name"
                            items={MotivationsData}
                            defaultValue={motivation?.id ?? 0}
                            idSelect="motivation"
                            width="100%"
                        />
                    </div>
                </div>

                <Spacer />

                {loadingData ? (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <CustomCircleLoader height={50} width={50} />
                    </div>
                ) : (
                    <>
                        <SkillsFormContainer
                            isSkills
                            disabledDefaultPrechecked={disabledDefaultPrechecked}
                            setDisabledDefaultPrechecked={setDisabledDefaultPrechecked}
                            draftConclusion={draftConclusion}
                            label="Compétences"
                            keyValue="skills"
                            payload={payload}
                            setPayload={setPayload}
                            setJustAddedSkills={setJustAddedSkills}
                            skills={skills}
                            isEditMode={!isEditMode}
                        />

                        <Spacer />

                        <SkillsFormContainer
                            isGoals
                            withFooterPadding
                            draftConclusion={draftConclusion}
                            label="Objectifs"
                            keyValue="skills"
                            payload={payload}
                            setPayload={setPayload}
                            setJustAddedSkills={setJustAddedSkills}
                            skills={skills}
                            updateGoalIdsInSkills={updateGoalIdsInSkills}
                            setUpdateGoalIdsInSkills={setUpdateGoalIdsInSkills}
                        />

                        <Spacer />

                        <SkillsFormContainer
                            isWorkshops
                            withFooterPadding
                            draftConclusion={draftConclusion}
                            label="Ateliers"
                            keyValue="skills"
                            payload={payload}
                            setPayload={setPayload}
                            setJustAddedSkills={setJustAddedSkills}
                            skills={skills}
                            updateGoalIdsInSkills={updateGoalIdsInSkills}
                            setUpdateGoalIdsInSkills={setUpdateGoalIdsInSkills}
                        />

                        <Spacer />

                        <SkillsFormContainer
                            isArticles
                            withFooterPadding
                            draftConclusion={draftConclusion}
                            label="Articles (Vidéos, images, texte)"
                            keyValue="skills"
                            payload={payload}
                            setPayload={setPayload}
                            setJustAddedSkills={setJustAddedSkills}
                            skills={skills}
                            updateGoalIdsInSkills={updateGoalIdsInSkills}
                            setUpdateGoalIdsInSkills={setUpdateGoalIdsInSkills}
                        />

                        <Spacer />

                        <ConclusionFormCardItem
                            isCarePlan
                            isEditMode={isEditMode}
                            payload={payload}
                            defaultValue={payload?.carePlan}
                            preSelectedPatientProgram={preSelectedPatientProgram}
                            label="Plan de soin"
                            keyValue="carePlan"
                            setPayload={setPayload}
                            skills={skills}
                            draftConclusion={draftConclusion}
                        />
                    </>
                )}
            </Box>

            <Spacer />

            <ConclusionFormFooter
                payload={payload}
                setPayload={setPayload}
                isEditMode={isEditMode}
                setIsEditMode={setIsEditMode}
                draftConclusion={draftConclusion}
                setShowForm={setShowForm}
                setUpdateList={setUpdateList}
                setLockInput={setLockInput}
            />
        </>
    );
};
