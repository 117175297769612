import { Grid } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { CustomText } from 'src/components/Text/CustomText';
import { CustomSelect } from 'src/components/InputField/CustomSelect';
import { eventVisibility } from 'src/utils/constants/constants';
import { Spacer } from 'src/components/Spacer/Spacer';

interface EventVisibilityProps {
    setEventPayload: any;
    loadingData?: any;
    eventData?: any;
}

const EventVisibility: FC<EventVisibilityProps> = (props) => {
    const { setEventPayload, eventData } = props;
    const [isPublic, setIsPublic] = useState(-1);

    useEffect(() => {
        const visibility = eventData?.isPublic ?? -1;
        setEventPayload((prev: any) => ({
            ...prev,
            isPublic: visibility,
        }));
        setIsPublic(visibility);
    }, [eventData]);

    return (
        <>
            <Grid container direction="row" alignItems={'center'} justifyContent="space-between" style={{}}>
                <Grid item>
                    <CustomText>Visibilité : </CustomText>
                </Grid>
                <Grid item style={{ width: '60%' }}>
                    <CustomSelect
                        onChange={(value: string) => setEventPayload((old: any) => ({ ...old, isPublic: value }))}
                        label=""
                        labelKey="name"
                        items={eventVisibility}
                        defaultValue={isPublic}
                        idSelect="selection-type-visibility"
                        width="100%"
                    />
                </Grid>
            </Grid>

            <Spacer />
        </>
    );
};

export default EventVisibility;
