import { Checkbox, Typography } from '@mui/material';
import { FC } from 'react';
import { baseColors, baseFontFamily, baseFontSize } from 'src/utils/constants/baseStyles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { specialCharToUnderscore } from 'src/utils/utils';

interface CustomCheckBoxProps {
    label?: string;
    icon?:any;
    checked?: boolean;
    onClick?: Function;
    disabled?: boolean;
    fontSize?: any;
    readOnly?:boolean;
    lineClamp?: number;
}

export const CustomCheckBox: FC<CustomCheckBoxProps> = (props) => {
    const { label,icon, checked, onClick, disabled, fontSize,readOnly, lineClamp } = props;

    return (
        <FormGroup style={{ marginLeft: 11 }}>
            <FormControlLabel
                control={
                    <Checkbox
                        style={{display : (readOnly ? "none" : "")}}
                        disabled={disabled}
                        onClick={() => (!readOnly && (onClick ? onClick() : {}))}
                        sx={{
                            color: baseColors.green.primary,
                            '&.Mui-checked': {
                                color: baseColors.green.primary,
                            },
                        }}
                        size="small"
                        checked={checked}
                        id={specialCharToUnderscore(label ?? '')}
                    />
                }
                label={
                    <Typography
                        fontFamily={baseFontFamily.OpenSansSemiBold}
                        fontSize={fontSize ? fontSize : baseFontSize.mini}
                        color={baseColors.black}
                        sx={{
                            display: icon ? 'inline-flex':'-webkit-box',
                            overflow: 'hidden',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: lineClamp ? lineClamp : 1,
                            lineHeight:(readOnly ? 2.5 : ""),
                            paddingLeft:(readOnly ? 3 : ""),
                        }}
                    >
                        {label ? label : 'Oui'}
                        {icon ?? ''}
                    </Typography>
                }
            />
        </FormGroup>
    );
};
