import { Dispatch, FC, SetStateAction } from 'react';
import { Box } from '@mui/material';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import { SpecialityItem } from 'src/interfaces/interfaces';
import { sxStyles } from '../styles';
import { checkPayloadState, defaultSpecialityPaylopad } from '../Speciality.utils';
import { PostSpecialitiesPayload } from 'src/services/Speciality.service';
import useSpecialityHooks from 'src/hooks/useSpecialityHooks';

type IProps = {
    speciality: SpecialityItem;
    setSpeciality: Dispatch<SetStateAction<SpecialityItem>>;
    setPayload: Dispatch<SetStateAction<PostSpecialitiesPayload>>;
    payload: PostSpecialitiesPayload;
    toggleModal: any;
};

export const SpecialityFormFooter: FC<IProps> = (props) => {
    const { setSpeciality, speciality, payload, toggleModal, setPayload } = props;
    const { saving, handleCreateNewSpeciality, handleEditSpeciality } = useSpecialityHooks({
        toggleModal,
        setPayload,
        setSpeciality,
    });

    const canSubmit = checkPayloadState(payload);

    const handleCancel = () => {
        setPayload(defaultSpecialityPaylopad);
        setSpeciality({} as SpecialityItem);
        toggleModal();
    };

    const handleClick = () => {
        if (speciality?.id > 0) {
            handleEditSpeciality({ specialityId: speciality.id, payload });
        } else {
            handleCreateNewSpeciality({ payload });
        }
    };

    return (
        <Box style={sxStyles.footer}>
            <CustomLoadingButton
                outlined
                disabled={saving}
                width="100%"
                label="Annuler"
                handleClick={handleCancel}
                style={{ flex: 0.95 / 2 }}
            />
            <CustomLoadingButton
                loading={saving}
                disabled={saving || canSubmit}
                width="100%"
                label={'Enregistrer'}
                handleClick={handleClick}
                style={{ flex: 0.95 / 2 }}
            />
        </Box>
    );
};
