import { FC, useEffect, useState, Dispatch, SetStateAction } from 'react';
import { CustomText } from 'src/components/Text/CustomText';
import { baseColors, baseFontFamily, baseFontSize } from 'src/utils/constants/baseStyles';
import { Spacer } from 'src/components/Spacer/Spacer';
import { ProgramItem } from 'src/interfaces/interfaces';
import ListItemFormCheckBox from 'src/components/ListItemFormCheckBox';
import { searchElementByName } from 'src/utils/utils';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import { PathologyForm } from 'src/views/Settings/Pathology/PathologyForm';
import { getProgramTypesList } from 'src/services/Posts';
import SelectedElement from 'src/components/ListItemFormCheckBox/SelectedElement';
import { Pathology } from 'src/interfaces/interfaces';

interface IProps {
    program: ProgramItem;
    setProgram: Dispatch<SetStateAction<ProgramItem>>;
}

export const ProgramPathologyForm: FC<IProps> = ({ program, setProgram }) => {
    const [searchkey, setSearchkey] = useState<string>('');
    const [searchedItems, setSearchedItems] = useState<Array<any>>([]);
    const [pathologyItems, setPathologyItems] = useState<Array<any>>([]);
    const [pathologyFormOpen, setPathologyFormOpen] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [justAddedPathology, setJustAddedPathology] = useState<Pathology[]>([]);

    const toggleModal = () => {
        setPathologyFormOpen(!pathologyFormOpen);
        setJustAddedPathology([]);
    };

    const handleSearch = (value: string) => setSearchkey(value);

    useEffect(() => {
        fetchPathology();
    }, [justAddedPathology]);

    useEffect(() => {
        setProgram((old: any) => ({
            ...old,
            programTypeIds: Array.from(justAddedPathology, (item: any) => item.id),
        }));
    }, [justAddedPathology]);

    useEffect(() => {
        setSearchedItems(searchElementByName(pathologyItems ?? [], searchkey, 'name'));
    }, [searchkey]);

    const finalData = searchkey?.trim()?.length > 0 ? searchedItems : pathologyItems;

    const fetchPathology = () => {
        setLoading(true);
        getProgramTypesList('?paginated=false').then((response) => {
            const pahologies = response?.data?.data;
            const sortedItems = pahologies?.sort((a: any, b: any) => b.id - a.id);
            setPathologyItems(sortedItems);
            setLoading(false);
        });
    };

    return (
        <div style={{}}>
            <div
                style={{ alignItems: 'center', flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}
            >
                <div style={{ width: '75%' }}>
                    <CustomText fontFamily={baseFontFamily.OpenSansSemiBold} style={{}} fontSize={baseFontSize.mini}>
                        Choisir le(s) pathologie(s) pour ce programme:
                    </CustomText>
                    <SelectedElement finalData={finalData} selectedIds={program?.programTypeIds} />
                </div>
                <CustomLoadingButton
                    style={{ backgroundColor: baseColors.orange.primary }}
                    width={280}
                    label="Ajouter une nouvelle pathologie"
                    handleClick={toggleModal}
                    disabled={program?.title?.trim() === ''}
                />
            </div>

            <Spacer height={10} />

            <ListItemFormCheckBox
                isForProgram
                selectedRows={new Set(program?.programTypeIds)}
                handleChange={(value: any) => setProgram((old: any) => ({ ...old, programTypeIds: Array.from(value) }))}
                items={finalData}
                loading={loading}
                labelKey="name"
                handleSearch={(value: string) => handleSearch(value)}
                maxItems={pathologyItems?.length}
                minHeight={`calc(100vh - 655px)`}
            />

            <PathologyForm
                forAdmin
                modalToggle={toggleModal}
                modalOpen={pathologyFormOpen}
                setJustAddedPathology={setJustAddedPathology}
            />
        </div>
    );
};
