import { makeStyles } from '@mui/styles';
import { baseBorderRadius, baseColors, baseFontFamily, baseScreenHeight } from 'src/utils/constants/baseStyles';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: 10,
        paddingRight: 10,
        backgroundColor: baseColors.white,
        ...baseScreenHeight.defaultHeight,
        ...baseBorderRadius.leftOnly,
    },
    header: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingRight: '10px',
    },
    title: {
        color: baseColors.black,
        fontFamily: baseFontFamily.BreeSerif,
        fontSize: '16px',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    subtitle: {
        color: baseColors.green.primary,
        fontSize: '12px',
    },
});

export default useStyles;
