import { PermissionActionType } from 'src/actions/permission/types';

interface PermissionElement {
    id: number;
    name: string;
    value: number;
    symbol: string;
}

interface PermissionState {
    listPermissions: any[];
}

const initialState: PermissionState = {
    listPermissions: [],
};

const permissionReducer = (permissionState: PermissionState = initialState, action: any) => {
    const { type, payload } = action;

    switch (type) {
        case PermissionActionType.LIST_PERMISSIONS:
            let permissions: any[] = [];
            payload.map((element: any) => {
                permissions.push({
                    id: element.id,
                    name: element.name,
                    value: element.number,
                    symbol: element.symbol,
                });

                return permissions;
            });

            return {
                ...permissionState,
                listPermissions: permissions,
            };
        default:
            return permissionState;
    }
};

export default permissionReducer;
