import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import { Divider, ListItemIcon } from '@mui/material';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { ROUTES } from 'src/routes/routes';
import styles from './styles';
import { FC } from 'react';
import { CustomText } from '../Text/CustomText';

type MenuItemContainerType = {
    items: any;
};

const MenuItemContainer: FC<MenuItemContainerType> = ({ items }) => {
    const { url } = useRouteMatch();
    const history = useHistory();
    const location = useLocation();
    const preselect = location?.pathname === ROUTES.settings;
    const handleClickMenu = (path: string) => history.replace(path);

    return (
        <MenuList style={{ paddingTop: 30 }}>
            {items.map((menu: any) => {
                const isSelected = url.includes(menu.path) || (preselect && menu.id === 1);
                const withOpacity = isSelected ? 1 : 0;
                const selectedMenuColor = isSelected ? baseColors.orange.primary : undefined;
                const iconBackground = isSelected ? baseColors.orange.primary : baseColors.orange.light;
                const icon = isSelected ? (menu.id === 1 ? menu.iconActive : menu.icon) : menu.icon;
                const label = menu.label;
                const labelFontFamily = isSelected ? baseFontFamily.OpenSansSemiBold : baseFontFamily.OpenSansRegular;

                return (
                    <MenuItem
                        key={menu.id.toString()}
                        style={styles.menuItem}
                        onClick={() => handleClickMenu(menu.path)}
                    >
                        <ListItemIcon style={{ ...styles.itemIcon, backgroundColor: iconBackground }}>
                            {icon}
                        </ListItemIcon>

                        <CustomText style={{ marginLeft: 10, marginRight: 10 }} fontFamily={labelFontFamily}>
                            {label}
                        </CustomText>

                        <Divider
                            orientation="vertical"
                            style={{ ...styles.divider, opacity: withOpacity, background: selectedMenuColor }}
                        />
                    </MenuItem>
                );
            })}
        </MenuList>
    );
};

export default MenuItemContainer;
