import { FC, useState } from 'react';
import CustomModal from 'src/components/CustomModal';
import { Grid } from '@mui/material';
import { CustomText } from 'src/components/Text/CustomText';
import { InputField } from 'src/components/InputField/InputField';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import { toast } from 'react-toastify';
import { sendMail } from 'src/services/Patients';
import { Patient, Professional } from 'src/interfaces/interfaces';

interface ContactModalProps {
    doctor: Professional | null;
    userFeched: Patient | null;
    mail: string;
    openContactModal: boolean;
    toggleContactModal: Function;
}

const ContactModal: FC<ContactModalProps> = (props) => {
    const { doctor, userFeched, openContactModal, toggleContactModal, mail } = props;
    const [sendingMail, setSendingMail] = useState(false);
    const [content, setContent] = useState('');
    const [subject, setSubject] = useState('');
    const disableBtn = content?.length === 0 || subject?.length === 0;
    const title = doctor
        ? `Contacter Dr ${doctor.firstName} ${doctor.lastName}`
        : `Contacter ${userFeched?.civility === 0 ? 'Mme' : 'Mr'} ${userFeched?.firstName}`;

    const performSendMail = () => {
        setSendingMail(true);
        if (mail) {
            sendMail({ subject: subject, content: content, recipients: [mail] })
                .then(() => {
                    setSendingMail(false);
                    toggleContactModal();
                    setContent('');
                    setSubject('');
                    toast.success('Message envoyé avec succès.');
                })
                .catch(() => {
                    setSendingMail(false);
                    toggleContactModal();
                    toast.error('Message non envoyé.');
                });
        }
    };

    return (
        <CustomModal width={500} title={title} open={openContactModal} toggleModal={toggleContactModal}>
            <Grid>
                <Grid
                    style={{ marginTop: 20, marginBottom: 10 }}
                    container
                    direction="row"
                    alignItems={'center'}
                    justifyContent="space-between"
                >
                    <Grid item>
                        <CustomText>Adresse e-mail :</CustomText>
                    </Grid>
                    <InputField
                        single
                        disabled
                        label=""
                        onChange={(value: any) => {}}
                        errorMessage="Nom de la consultation obligatoire"
                        placeholder={'Entrer adresse email'}
                        width={'70%'}
                        value={mail}
                        idSuffix="email_patient"
                    />
                </Grid>

                <Grid
                    style={{ marginTop: 20, marginBottom: 10 }}
                    container
                    direction="row"
                    alignItems={'center'}
                    justifyContent="space-between"
                >
                    <Grid item>
                        <CustomText>Objet :</CustomText>
                    </Grid>
                    <InputField
                        single
                        disabled={sendingMail}
                        label=""
                        onChange={(value: any) => setSubject(value)}
                        placeholder={"Objet de l'email"}
                        width={'70%'}
                        value={subject}
                        idSuffix="objet_contact_patient"
                    />
                </Grid>

                <Grid
                    container
                    direction="row"
                    alignItems={'self-start'}
                    justifyContent="space-between"
                    style={{ marginBottom: 10, marginTop: 20 }}
                >
                    <Grid item>
                        <CustomText> Message :</CustomText>
                    </Grid>
                    <Grid item flexGrow={1} style={{ marginBottom: 10, marginLeft: '13%' }}>
                        <InputField
                            height={200}
                            label=""
                            disabled={sendingMail}
                            multiline
                            placeholder="Taper votre texte"
                            onChange={(value: any) => setContent(value)}
                            width={'100%'}
                            value={content}
                            idSuffix="message_contact_patient"
                        />
                    </Grid>
                </Grid>

                <Grid container justifyContent="center">
                    <CustomLoadingButton
                        width="100%"
                        label="Envoyer message"
                        loading={sendingMail}
                        disabled={disableBtn}
                        handleClick={performSendMail}
                    />
                </Grid>
            </Grid>
        </CustomModal>
    );
};

export default ContactModal;
