import { baseColors } from 'src/utils/constants/baseStyles';

const IconRoleFonctionnalite = (props: any) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={27}
            height={27}
            fill={baseColors.green.primary}
            viewBox="0 0 27 27"
            {...props}
        >
            <circle cx="12.75" cy="12.99" r="8.37" fill="#fff" />
            <path
                d="M25.94,15l-.49-.67a1.62,1.62,0,0,1,0-1.87l.48-.67a1.6,1.6,0,0,0-.55-2.34l-.73-.39a1.62,1.62,0,0,1-.82-1.68L24,6.58a1.6,1.6,0,0,0-1.52-1.86l-.83,0a1.59,1.59,0,0,1-1.46-1.15l-.23-.8a1.59,1.59,0,0,0-2.17-1L17,2a1.6,1.6,0,0,1-1.83-.41L14.6,1a1.6,1.6,0,0,0-2.4,0l-.55.63a1.59,1.59,0,0,1-1.81.43l-.77-.31A1.59,1.59,0,0,0,6.92,2.84l-.22.79A1.56,1.56,0,0,1,5.26,4.81l-.83.05A1.6,1.6,0,0,0,3,6.75l.15.81a1.61,1.61,0,0,1-.8,1.69l-.72.4A1.6,1.6,0,0,0,1.06,12l.49.67a1.61,1.61,0,0,1,0,1.87l-.48.68a1.59,1.59,0,0,0,.55,2.33l.73.39a1.61,1.61,0,0,1,.83,1.68l-.15.81a1.6,1.6,0,0,0,1.51,1.87l.83,0a1.6,1.6,0,0,1,1.47,1.16l.23.8a1.6,1.6,0,0,0,2.17,1L10,25a1.62,1.62,0,0,1,1.83.4l.55.61a1.6,1.6,0,0,0,2.4,0l.54-.63a1.6,1.6,0,0,1,1.82-.43l.76.31a1.6,1.6,0,0,0,2.16-1.06l.22-.79a1.6,1.6,0,0,1,1.45-1.18l.83-.05a1.6,1.6,0,0,0,1.48-1.88l-.15-.81a1.59,1.59,0,0,1,.8-1.69l.72-.41A1.6,1.6,0,0,0,25.94,15ZM13.5,21.91a8.4,8.4,0,1,1,8.4-8.4A8.4,8.4,0,0,1,13.5,21.91Z"
                transform="translate(-0.75 -0.48)"
                fill={baseColors.green.primary}
            />
            <path
                d="M11.62,14.36,10.32,13A2,2,0,0,0,7.5,13h0a2,2,0,0,0,0,2.82l1.3,1.32,1.45,1.45a2,2,0,0,0,2.75,0l1.45-1.45,5.06-5a2,2,0,0,0,0-2.82h0a2,2,0,0,0-2.82,0Z"
                transform="translate(-0.75 -0.48)"
                fill={baseColors.orange.primary}
            />
        </svg>
    );
};

export default IconRoleFonctionnalite;
