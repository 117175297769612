import { Box, IconButton } from '@mui/material';
import { useState } from 'react';
import { baseColors, baseScreenHeight } from 'src/utils/constants/baseStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import { CustomConfirm } from 'src/components/CustomConfirm/CustomConfirm';
import styles from './styles';
import CustomEmptyData from 'src/components/CustomEmptyData';
import { CustomText } from 'src/components/Text/CustomText';
import { tableCellHeadText, tableCellText } from 'src/views/Security/SecurityManagement/SecurityManagement';
import SubMenusLoader from 'src/components/SettingsSubMenuBar/SubMenusLoader/SubMenusLoader';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { SpecialityItem } from 'src/interfaces/interfaces';
import { SpecialityModal } from './SpecialityModal/SpecialityModal';
import { useFetchSpecialitiesRQHooks } from 'src/hooks/ReactQueryHooks/useEvenRQtHooks';
import useSpecialityHooks from 'src/hooks/useSpecialityHooks';

export const SpecialityPanel = () => {
    const [modalConfirmVisible, setModalConfirmVisible] = useState(false);
    const [specialityToDeleteId, setSpecialityToDeleteId] = useState(0);
    const [openForm, setOpenForm] = useState(false);
    const [speciality, setSpeciality] = useState<SpecialityItem>({} as SpecialityItem);

    const { data: specialityData, isLoading: loadingSpeciality } = useFetchSpecialitiesRQHooks();

    const toggleModal = () => setOpenForm(!openForm);
    const tableHeaders = [{ name: 'Spécialité' }, { name: 'Action' }];

    const { saving, handleDeleteSpeciality } = useSpecialityHooks({
        setModalConfirmVisible,
    });

    const handleClick = () => toggleModal();

    const handleEdit = (selectedSpeciality: SpecialityItem) => {
        setSpeciality(selectedSpeciality);
        toggleModal();
    };

    const sortedData = specialityData?.sort((a: SpecialityItem, b: SpecialityItem) => b?.id - a?.id) ?? [];

    return (
        <Box sx={styles.container}>
            <Box sx={styles.header}>
                <CustomText isBreefSerif isTitle color={baseColors.green.primary} style={{ marginLeft: 20 }}>
                    Liste des spécialités
                </CustomText>

                <CustomLoadingButton
                    disabled={loadingSpeciality}
                    label="Ajouter une nouvelle spécialité"
                    handleClick={handleClick}
                />
            </Box>

            {loadingSpeciality ? (
                <SubMenusLoader />
            ) : sortedData?.length === 0 ? (
                <div style={{ paddingBottom: 150 }}>
                    <CustomEmptyData />
                </div>
            ) : (
                <TableContainer
                    component={Paper}
                    style={{ overflow: 'auto', maxHeight: baseScreenHeight.tableContainerHeight }}
                >
                    <Table stickyHeader sx={{ minWidth: 650 }} aria-label="role table">
                        <TableHead>
                            <TableRow>
                                {tableHeaders.map((header, index) => (
                                    <TableCell key={index} sx={{ color: baseColors.green.primary }}>
                                        {tableCellHeadText(header.name)}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {sortedData?.map((speciality: SpecialityItem, index: number) => (
                                <TableRow
                                    key={index}
                                    sx={{
                                        '&:last-child td, &:last-child th': { border: 0 },
                                        '& .MuiTableCell-root': {
                                            padding: '5px 15px 5px 15px',
                                        },
                                    }}
                                >
                                    <TableCell component="th" scope="row">
                                        {tableCellText(speciality.name)}
                                    </TableCell>

                                    <TableCell>
                                        <IconButton onClick={() => handleEdit(speciality)}>
                                            <EditOutlinedIcon
                                                sx={{
                                                    fontSize: 'medium',
                                                    color: baseColors.green.primary,
                                                    cursor: 'pointer',
                                                }}
                                            />
                                        </IconButton>
                                        <IconButton
                                            onClick={() => {
                                                setSpecialityToDeleteId(speciality.id);
                                                setModalConfirmVisible(true);
                                            }}
                                        >
                                            <DeleteOutlineOutlinedIcon sx={styles.delete} />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            <SpecialityModal
                open={openForm}
                speciality={speciality}
                setSpeciality={setSpeciality}
                toggleModal={toggleModal}
            />

            <CustomConfirm
                defaultLoading={loadingSpeciality || saving}
                modalVisible={modalConfirmVisible}
                updateModalVisible={setModalConfirmVisible}
                message="Voulez-vous vraiment supprimer la spécialité ? "
                callBackconfirm={() => handleDeleteSpeciality(specialityToDeleteId)}
            />
        </Box>
    );
};
