import { QuizPayloadType } from 'src/interfaces/types';
import axios from './AxiosInstance';

const postNewQuiz = (payload: QuizPayloadType) => axios.post('api/quizz/createWithQuestions', { ...payload });
const putUpdateQuiz = (payload: QuizPayloadType,quizId: number) => axios.put('api/quizz/'+quizId, { ...payload });

const getQuizList = () => axios.get('api/quizz');

const deleteQuiz = (quizId: any) => axios.delete(`api/quizz/${quizId}`);

const getCategories = () => axios.get(`api/postCategories`);

const getQuizById = (quizId: any) => axios.get(`api/quizz/${quizId}`);

const QuizService = {
    postNewQuiz,
    getQuizList,
    deleteQuiz,
    getCategories,
    getQuizById,
    putUpdateQuiz
};

export default QuizService;
