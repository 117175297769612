import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import '../chart.css';
import { CustomText } from 'src/components/Text/CustomText';
import { Spacer } from 'src/components/Spacer/Spacer';
import ChartNamePopover from '../ChartNamePopover/ChartNamePopover';
import QueryStatsIcon from '@mui/icons-material/QueryStats';

export const LineChart = (props) => {
    const { isGoal, data, withoutLabel, isEvaluation, constantNames, selectedConstant, setSelectedConstant } = props;
    const [series, setSeries] = useState([{ name: 'series-1', data: data?.yData }]);
    const fr = require('apexcharts/dist/locales/fr.json');
    useEffect(() => {
        setSeries([{ name: 'series-1', data: data?.yData }]);
    }, [data]);

    const options = {
        chart: {
            id: 'basic-bar',
            locales: [fr],
            defaultLocale: 'fr',
        },
        xaxis: {
            categories: data?.xData,
        },
        fill: {
            type: 'solid',
            colors: [baseColors.orange.primary, baseColors.orange.primary, baseColors.orange.primary],
        },
        stroke: {
            show: true,
            curve: 'straight',
            lineCap: 'butt',
            colors: [baseColors.orange.primary],
            width: 2,
            dashArray: 0,
        },
        dataLabels: {
            enabled: false,
            enabledOnSeries: undefined,
            formatter: function (val, opts) {
                return val;
            },
            textAnchor: 'middle',
            distributed: false,
            offsetX: 0,
            offsetY: 0,
            style: {
                fontSize: '14px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 'bold',
                colors: undefined,
            },
            background: {
                enabled: true,
                foreColor: '#fff',
                padding: 4,
                borderRadius: 2,
                borderWidth: 1,
                borderColor: '#fff',
                opacity: 0.9,
                dropShadow: {
                    enabled: false,
                    top: 1,
                    left: 1,
                    blur: 1,
                    color: '#000',
                    opacity: 0.45,
                },
            },
            dropShadow: {
                enabled: false,
                top: 1,
                left: 1,
                blur: 1,
                color: '#000',
                opacity: 0.45,
            },
        },
        markers: {
            colors: ['#fff'],
            strokeColors: baseColors.green.primary,
            strokeWidth: 3,
            strokeOpacity: 0.9,
            strokeDashArray: 0,
            fillOpacity: 1,
            discrete: [],
            shape: 'circle',
            radius: 4,
            offsetX: 0,
            offsetY: 0,
            onClick: undefined,
            onDblClick: undefined,
            showNullDataPoints: true,
            hover: {
                size: 8,
                sizeOffset: 3,
            },
        },
        annotations: {
            position: 'front',
            yaxis: [
                {
                    y: 0,
                    y2: null,
                    strokeDashArray: 1,
                    borderColor: '#c2c2c2',
                    fillColor: '#c2c2c2',
                    opacity: 0.3,
                    offsetX: 0,
                    offsetY: -3,
                    width: '100%',
                    yAxisIndex: 0,
                    label: {
                        borderColor: '#c2c2c2',
                        borderWidth: 1,
                        borderRadius: 2,
                        text: undefined,
                        textAnchor: 'end',
                        position: 'right',
                        offsetX: 0,
                        offsetY: 0,
                        mouseEnter: undefined,
                        mouseLeave: undefined,
                        style: {
                            background: '#fff',
                            color: '#777',
                            fontSize: '12px',
                            fontWeight: 400,
                            fontFamily: undefined,
                            cssClass: 'apexcharts-yaxis-annotation-label',
                            padding: {
                                left: 20,
                                right: 5,
                                top: 0,
                                bottom: 2,
                            },
                        },
                    },
                },
            ],
            xaxis: [
                {
                    x: 0,
                    x2: null,
                    strokeDashArray: 4,
                    borderColor: baseColors.green.light,
                    fillColor: baseColors.green.light,
                    opacity: 0,
                    offsetX: 0,
                    offsetY: 0,
                    label: {
                        borderColor: '#c2c2c2',
                        borderWidth: 1,
                        borderRadius: 2,
                        text: undefined,
                        textAnchor: 'middle',
                        position: 'top',
                        orientation: 'vertical',
                        offsetX: 0,
                        offsetY: 0,
                        mouseEnter: undefined,
                        mouseLeave: undefined,
                        style: {
                            background: '#fff',
                            color: '#777',
                            fontSize: '12px',
                            fontWeight: 400,
                            fontFamily: undefined,
                            cssClass: 'apexcharts-xaxis-annotation-label',
                        },
                    },
                },
            ],
            points: [
                {
                    x: 0,
                    y: null,
                    yAxisIndex: 0,
                    seriesIndex: 0,
                    mouseEnter: undefined,
                    mouseLeave: undefined,
                    marker: {
                        size: 0,
                        fillColor: '#fff',
                        strokeColor: '#333',
                        strokeWidth: 3,
                        shape: 'circle',
                        radius: 2,

                        cssClass: '',
                    },
                    label: {
                        borderColor: '#c2c2c2',
                        borderWidth: 1,
                        borderRadius: 2,
                        text: undefined,
                        textAnchor: 'middle',
                        offsetX: 0,
                        offsetY: -15,
                        mouseEnter: undefined,
                        mouseLeave: undefined,
                        style: {
                            background: '#fff',
                            color: '#777',
                            fontSize: '12px',
                            fontWeight: 400,
                            fontFamily: undefined,
                            cssClass: 'apexcharts-point-annotation-label',
                            padding: {
                                left: 5,
                                right: 5,
                                top: 0,
                                bottom: 2,
                            },
                        },
                    },
                    image: {
                        path: undefined,
                        width: 20,
                        height: 20,
                        offsetX: 0,
                        offsetY: 0,
                    },
                },
            ],

            texts: [
                {
                    x: 0,
                    y: 0,
                    text: '',
                    textAnchor: 'start',
                    foreColor: undefined,
                    fontSize: '13px',
                    fontFamily: undefined,
                    fontWeight: 400,
                    backgroundColor: 'transparent',
                    borderColor: '#c2c2c2',
                    borderRadius: 0,
                    borderWidth: 0,
                    paddingLeft: 4,
                    paddingRight: 4,
                    paddingTop: 2,
                    paddingBottom: 2,
                },
            ],

            images: [
                {
                    path: '',
                    x: 0,
                    y: 0,
                    width: 20,
                    height: 20,
                },
            ],
        },
        grid: {
            show: true,
            borderColor: baseColors.green.dark,
            strokeDashArray: 4,
            position: 'back',
            xaxis: {
                lines: {
                    show: false,
                },
            },
            yaxis: {
                lines: {
                    show: true,
                },
            },
            row: {
                colors: undefined,
                opacity: 0.2,
            },
            column: {
                colors: undefined,
                opacity: 0.5,
            },
            padding: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 10,
            },
        },

        legend: {
            show: true,
            showForSingleSeries: false,
            showForNullSeries: true,
            showForZeroSeries: true,
            position: 'bottom',
            horizontalAlign: 'center',
            floating: false,
            fontSize: '14px',
            fontFamily: 'Helvetica, Arial',
            fontWeight: 400,
            formatter: undefined,
            inverseOrder: false,
            width: undefined,
            height: undefined,
            tooltipHoverFormatter: undefined,
            customLegendItems: [],
            offsetX: 0,
            offsetY: 0,
            labels: {
                colors: undefined,
                useSeriesColors: false,
            },
            markers: {
                width: 12,
                height: 12,
                strokeWidth: 0,
                strokeColor: '#fff',
                fillColors: undefined,
                radius: 12,
                customHTML: undefined,
                onClick: undefined,
                offsetX: 0,
                offsetY: 0,
            },
            itemMargin: {
                horizontal: 5,
                vertical: 0,
            },
            onItemClick: {
                toggleDataSeries: true,
            },
            onItemHover: {
                highlightDataSeries: true,
            },
        },
        tooltip: {
            enabled: true,
            enabledOnSeries: undefined,
            shared: true,
            followCursor: false,
            intersect: false,
            inverseOrder: false,
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                return (
                    '<div class="arrow_box">' + '<span>' + series[seriesIndex][dataPointIndex] + '</span>' + '</div>'
                );
            },
            fillSeriesColor: false,
            style: {
                fontSize: '12px',
                fontFamily: undefined,
            },
            onDatasetHover: {
                highlightDataSeries: false,
            },
            x: {
                show: false,
                format: 'dd MMM',
                formatter: undefined,
            },
            y: {
                formatter: undefined,
                title: {
                    formatter: (seriesName) => '',
                },
            },
            z: {
                formatter: undefined,
                title: 'Size: ',
            },
            marker: {
                show: false,
            },
        },
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const handleClickName = (event) => setAnchorEl(event.currentTarget);

    return (
        <div
            style={{
                height: isEvaluation ? '100%' : undefined,
                background: baseColors.green.light,
                padding: 15,
                borderRadius: 10,
                width: isEvaluation ? '100%' : 350,
            }}
        >
            <ChartNamePopover
                selectedConstant={selectedConstant}
                setselectedConstant={setSelectedConstant}
                constantNames={constantNames ?? []}
                setAnchorEl={setAnchorEl}
                anchorEl={anchorEl}
            />

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    cursor: 'pointer',
                    backgroundColor: baseColors.orange.primary,
                    padding: '10px 20px 10px 20px',
                    borderRadius: 10,
                }}
                onClick={handleClickName}
            >
                <CustomText
                    style={{ textAlign: 'left' }}
                    color={baseColors.white}
                    fontFamily={baseFontFamily.OpenSansSemiBold}
                >
                    {selectedConstant?.name ?? ''}
                </CustomText>

                <QueryStatsIcon htmlColor={baseColors.white} />
            </div>

            <Spacer height={20} />
            {!withoutLabel && (
                <CustomText color={baseColors.green.primary} fontSize={12}>
                    Type de valeur ?
                </CustomText>
            )}
            <ReactApexChart
                options={options}
                height={isGoal ? '100%' : '80%'}
                series={series}
                type="line"
                width={isEvaluation ? '100%' : 350}
            />
        </div>
    );
};
