import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row',
    },
    left: {
        flex: 0.5,
    },
    leftBtn: {
        flex: 0.5,
        textAlign: 'center',
    },
    right: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row',
        flex: 0.5,
    },
});

export default useStyles;
