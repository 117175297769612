import { FC, useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import { Grid } from '@mui/material';
import { CustomText } from 'src/components/Text/CustomText';
import { baseFontFamily } from 'src/utils/constants/baseStyles';
import notfound from '../../assets/icons/notfound.png';
import CustomCircleLoader from 'src/components/CustomLoader/CustomCircleLoader';

export const NotFound: FC = (props) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let timer = setTimeout(() => {
            setLoading(false);
        }, 3000);

        return () => {
            clearTimeout(timer);
        };
    }, []);

    return (
        <Paper style={{ height: '90vh', alignItems: 'center' }}>
            <Grid
                flexGrow={1}
                direction="column"
                style={{ paddingTop: '25vh' }}
                container
                alignItems={'center'}
                justifyContent={'center'}
                alignContent={'center'}
            >
                <Grid item alignContent={'center'}>
                    {loading ? <CustomCircleLoader /> : <img alt="ok" src={notfound} width={200} height={200} />}
                </Grid>
                <Grid item>
                    <CustomText fontSize={20} fontFamily={baseFontFamily.BreeSerif}>
                        {loading ? 'Chargement en cours... ' : "Le page que vous demandez n'existe pas"}
                    </CustomText>
                </Grid>
            </Grid>
        </Paper>
    );
};
