import { RoleActionType } from "src/actions/role/types";

interface RoleElement{
    id: number;
    name: string;
    description: string;
    code: string;
}

interface RoleState{
    listRoles: any[];
}

const initialState: RoleState = {
    listRoles: [],
}


const roleReducer = (roleState: RoleState = initialState, action: any) => {
    
    const { type, payload } = action;

    switch (type) {
        case RoleActionType.LIST_ROLES:
            let roles: any[] = [];
            payload.map((element: any) => {
                roles.push({
                    id: element.id,
                    name: element.name,
                    description: element.description,
                    code: element.code,
                });
                return roles;
            });

            return {
                ...roleState,
                listRoles:  roles,
            }
        default:
            return roleState;
    }
}

export default roleReducer;