import { Posts } from '../../../interfaces/interfaces';

const tableHeaders = [
    {
        name: '',
    },
    // {
    //     name: 'Id',
    // },
    {
        name: 'Titre',
    },
    {
        name: 'Action',
    },
];

const defaultPostValue: Posts = {
    title: '',
    content: ' ',
    axisId: 1,
    postCategoryId: 1,
    postTypeId: 1,
    programTypeId: 1,
    image: {
        fileContent: '',
    },
    video: {
        fileContent: '',
        uploadedFileName: '',
        thumbnailFileContent: '',
    },
};

const defaultArticlePayload = {
    title: '',
    content: '',
    axisId: 0,
    postCategoryId: 0,
    postTypeId: 0,
    programTypeId: 0,
    video: {
        uploadedFileName: '',
        fileContent: '',
        thumbnailFileContent: '',
    },
    image: {
        fileContent: '',
    },
    goalsIds: [],
};

export { defaultArticlePayload, tableHeaders, defaultPostValue };
