import { toast } from "react-toastify";
import { EventPlanningViewType, PlanningAgendaFilters } from "src/interfaces/interfaces";
import EventService from "src/services/Calendar/events.service";
import { ServerError } from "src/utils/constants/constants";
import {
  RETRIEVE_EVENTS, 
  RETRIEVE_EVENT_INFORMATIONS, 
  RETRIEVE_PLANNING_EVENTS
} from "./types";

export const retrieveCalendarEvent = (setLoadingEvent: (val: boolean) => void, eventId: number) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
  setLoadingEvent(true)
  try {

    const res = await EventService.getEvent(eventId)

    dispatch({
      type: RETRIEVE_EVENTS,
      payload: res.data,
    });
    
    if(res.data){
      setLoadingEvent(false)
    }
  } catch (err) {
    console.log(err);
    setLoadingEvent(false)
  }
};

export const retrievePlanningEvent = 
  ( setLoadingEvent: (val: boolean) => void, 
    checkedDate: string, 
    viewType: EventPlanningViewType['viewType'], 
    filters: PlanningAgendaFilters ) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
  
    setLoadingEvent(true)
  try {

    const res = await EventService.getPlanningEvent(checkedDate, viewType, filters)

    dispatch({
      type: RETRIEVE_PLANNING_EVENTS,
      payload: res.data,
    });
    
    if(res.data){
      setLoadingEvent(false)
    }
  } catch (err) {
    console.log(err);
    toast.error(ServerError.unknown);
    setLoadingEvent(false)
  }
};

export const retrieveEventInformation = (setLoadingEvent: (val: boolean) => void, checkedDate: string) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
  setLoadingEvent(true)
  try {

    const res = await EventService.getEventInformation(checkedDate)

    dispatch({
      type: RETRIEVE_EVENT_INFORMATIONS,
      payload: res.data,
    });
    
    if(res.data){
      setLoadingEvent(false)
    }
  } catch (err) {
    console.log(err);
    setLoadingEvent(false)
  }
};