const strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z0-9]).{8,}$/;

export const isValidMail = (email: string | undefined) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        );
};

export const isSamePassword = (password: string, confirmPassword: string) => {
    const regex = new RegExp(strongPasswordRegex);
    return regex.test(password) && password?.trim() === confirmPassword?.trim();
};

export const isValidPassword = (password: string) => {
    const regex = new RegExp(strongPasswordRegex);
    return regex.test(password);
};

export const isValideName = (name: string) => {
    const regex = new RegExp('^(?=.*[A-Za-z0-9]).{3,50}$');
    return regex?.test(name);
};

export const isNumber = (str: string) => {
    const reg = new RegExp('^[0-9]+$');
    return str && str?.match(reg);
};

export const isPhoneNumber = (str: string | undefined) => {
    const reg = new RegExp(/^\+33[1-9]{1}[0-9]{8}$/);
    return str && str.match(reg) !== null;
};

export const isValidBloodPressure = (str: string) => {
    const reg = new RegExp(/^\d{1,3}\/\d{1,3}$/);
    return str && str.match(reg);
};

export const isValidPostalCode = (str: string) => {
    const reg = new RegExp(/^\d{5}$/);
    return str && str.match(reg);
};

export const isValidWeight = (str: string) => {
    const reg = new RegExp(/^\d{1,3}(?:[.,]\d{1,3})?\s*$/i);
    return str && str.match(reg);
};

export const isNotBlank = (str: string | undefined) => str && str?.trim().length >= 1;

export const isValidQuestion = (str: string | undefined) => str && str?.trim().length > 5;

// export const isSamePassword = (password: string, confirmPassword: string) => {

//     if (password?.trim().length < 7 || confirmPassword?.trim()?.length < 7) {

//     }

//     const lengthCheck = password?.trim()?.length === confirmPassword?.trim()?.length;
//     const matchCharacters = password?.trim() === confirmPassword?.trim();
//     return lengthCheck && matchCharacters;
// };

export const isValidRpps = (str: string) => {
    const reg = /^[0-9]{11}$/;
    return str && str.match(reg);
};
