import { Box } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import useStyles from './styles';
import { CustomText } from 'src/components/Text/CustomText';
import { CustomCheckBox } from 'src/components/InputField/CustomCheckBox';
import { postPostView } from 'src/services/Posts';
import { toast } from 'react-toastify';
import ListItemLoading from 'src/components/CustomLoader/ListItemLoading';

interface ReportManagementFormVideoCardProps {
    items?: Array<any>;
    labelKey?: string;
    searchData?: Function;
    onClick?: Function;
    secondLabelKey?: string;
    loading?: boolean;
    subMenuKey?: string;
    placeholder?: string;
    handleChange?: Function;
    selectedRows?: Set<number>;
    maxItems?: number;
    contentHeight?: number;
    handleSearch?: any;
    disableCheckBox?: boolean;
    isPatients?: boolean;
    isIntervenants?: boolean;
    noSearchHeader?: boolean;
    setSelectedIntervenants?: any;
    isForProgram?: boolean;
    checkedData?: Array<any>;
    patientId: number;
    postView: any[];
    handleFetchPostList?: any;
    handleFetchPostViewPatient?: any;
}

export const ReportManagementFormVideoCard: FC<ReportManagementFormVideoCardProps> = (props) => {
    const {
        items,
        loading,
        labelKey,
        selectedRows,
        disableCheckBox,
        patientId,
        handleFetchPostList,
        handleFetchPostViewPatient,
    } = props;

    const classes = useStyles();
    const [selected, setSelected] = useState<any>(selectedRows);

    const performChangePosts = (i: any) => {
        const value: any | undefined = i;
        const valuePayload: any = {
            postId: value?.id,
            userId: patientId,
        };

        postPostView(valuePayload)
            .then(() => successfull('Status articles modifié'))
            .catch((e) => failed('Erreur de modification du status articles'));
    };

    const successfull = (message: string) => {
        handleFetchPostViewPatient();
        handleFetchPostList();
        toast.success(message);
    };

    const failed = (message: string) => {
        toast.error(message);
    };

    const handleSetSelected = () => {
        if (Array.from(selectedRows ?? [])?.length && Array.from(selected ?? [])?.length === 0) {
            setSelected(selectedRows);
        }
    };

    useEffect(() => {
        handleSetSelected();
    }, [selectedRows]);

    return (
        <Box className={classes.container}>
            <Box>
                {loading ? (
                    <ListItemLoading />
                ) : items && items?.length > 0 ? (
                    <Box
                        sx={{
                            overflow: 'hidden',
                            overflowY: 'auto',
                            overflowX: 'auto',
                            height: 200,
                            width: 400,
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        {items?.map((item: any, i: number) => (
                            <Box key={i} style={{ alignSelf: 'flex-start' }}>
                                <CustomCheckBox
                                    disabled={disableCheckBox}
                                    label={labelKey ? `${items[i]?.name}` : ''}
                                    checked={items[i]?.validated === 1 ? true : false}
                                    onClick={() => performChangePosts(items[i])}
                                />
                            </Box>
                        ))}
                    </Box>
                ) : (
                    <CustomText style={{ textAlign: 'center' }}> Aucun contenu trouvé.</CustomText>
                )}
            </Box>
        </Box>
    );
};
