import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { defaultConclusionValue, performAddConclusion } from './Logic';
import useStyles from './styles';
import { ConclusionPayloadType } from 'src/interfaces/types';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import { DraftConclusion } from 'src/interfaces/interfaces';
import { baseColors } from 'src/utils/constants/baseStyles';
import CustomModal from 'src/components/CustomModal/CustomModal';
import { CustomText } from 'src/components/Text/CustomText';
import { Spacer } from 'src/components/Spacer/Spacer';
import { Box } from '@mui/material';

interface ConclusionFormFooterProps {
    draftConclusion: DraftConclusion;
    setShowForm: Dispatch<SetStateAction<boolean>>;
    setUpdateList: Dispatch<SetStateAction<boolean>>;
    payload: ConclusionPayloadType;
    setPayload: Dispatch<SetStateAction<ConclusionPayloadType>>;
    setLockInput: Dispatch<SetStateAction<boolean>>;
    isEditMode: boolean;
    setIsEditMode: Dispatch<SetStateAction<boolean>>;
}

export const ConclusionFormFooter: FC<ConclusionFormFooterProps> = ({
    draftConclusion,
    payload,
    setShowForm,
    setUpdateList,
    setLockInput,
    setPayload,
    setIsEditMode,
    isEditMode,
}) => {
    const params = useParams<any>();
    const classes = useStyles();
    const patientId: number = +params?.id || 0;
    const defaultConclusion = { ...defaultConclusionValue, patientId };
    const [savingResume, setSavingResume] = useState<boolean>(false);
    const [addingResume, setAddingResume] = useState<boolean>(false);
    const [showConfirm, setShowConfirm] = useState(false);

    const handleClickEdition = () => setIsEditMode(true);

    const saveConclusion = (isDraft: number) =>
        performAddConclusion({
            defaultConclusion,
            payload,
            setPayload,
            setAddingResume,
            setSavingResume,
            setLockInput,
            setUpdateList,
            setShowForm,
            setShowConfirm,
            isDraft,
            draftConclusion,
        });

    const notValidConclusionData = () =>
        payload?.skills?.length === 0 ||
        payload?.programId === 0 ||
        payload?.patientId === 0 ||
        (payload?.conclusionWorkshops?.length === 0 && payload?.conclusionVideos?.length === 0);

    useEffect(() => {
        if (draftConclusion?.id) {
            setIsEditMode(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [draftConclusion]);

    const loading = savingResume || addingResume;
    const disabled = notValidConclusionData() || loading;
    const btnValidationLabel = !isEditMode ? 'Modifier' : 'Sauvegarder';
    const validationAction = () => (!isEditMode ? handleClickEdition() : saveConclusion(1));

    return (
        <>
            <div style={{ flexDirection: 'row', display: 'flex' }} className={classes.flexCenter}>
                <CustomLoadingButton
                    loading={savingResume}
                    disabled={disabled}
                    style={{
                        backgroundColor: baseColors.orange.primary,
                        marginRight: 20,
                    }}
                    width="45%"
                    handleClick={validationAction}
                    label={btnValidationLabel}
                />

                <CustomLoadingButton
                    disabled={disabled}
                    width="45%"
                    handleClick={() => setShowConfirm(true)}
                    label="Valider le diagnostic éducatif"
                />
            </div>
            <CustomModal
                disableBackdropClick
                open={showConfirm}
                toggleModal={setShowConfirm}
                title="Remarque!"
                width={600}
            >
                <Box>
                    <Spacer />
                    <CustomText style={{ textAlign: 'center' }}>
                        La validation du diagnostic éducatif est définitive. <br />
                        Vous ne pourrez plus le modifier.
                    </CustomText>
                    <Spacer height={60} />
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '30px' }}>
                        <CustomLoadingButton
                            orange
                            disabled={loading}
                            label="Fermer"
                            handleClick={() => setShowConfirm(false)}
                            width="45%"
                        />
                        <CustomLoadingButton
                            loading={addingResume}
                            disabled={loading}
                            label="Valider le diagnostic éducatif"
                            handleClick={() => saveConclusion(0)}
                            width="45%"
                        />
                    </div>
                </Box>
            </CustomModal>
        </>
    );
};
