import { baseColors } from 'src/utils/constants/baseStyles';

function FichePatient(props: any) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
            <g clipPath="url(#clip0_1525:11457)">
                <path
                    fill={baseColors.orange.primary}
                    d="M21.615 7.053c.291 0 .527.237.527.528V12l-4.23 4.714-.186-6.433 1.59-3.229h2.299zM4.829 7.76L2.827 12l.734 6.596-3.278 4.533a.105.105 0 01-.19-.061L.002 4.757a.527.527 0 01.527-.53h2.297L4.829 7.76z"
                />
                <path fill="#DCEAEC" d="M19.316.693v12.72H11.07l-1.918-6.8L11.07.694h8.245z" />
                <path fill="#DCEAEC" d="M2.827.693h8.244v12.72H2.827V.694z" />
                <path
                    fill="#00B5BC"
                    d="M12.72 10.642v2.065h-1.649l-.595-2.425.595-2.166.834.73c.518.453.815 1.108.815 1.796z"
                />
                <path
                    fill="#64C7C7"
                    d="M11.071 8.116v4.59H5.653v-2.064c0-.688.298-1.343.815-1.796l1.51-1.322h2.417l.676.592z"
                />
                <path
                    fill="#00B5BC"
                    d="M12.956 4.047c0-.29.236-.527.527-.527h2.95c.29 0 .527.236.527.527v.359a.527.527 0 01-.527.527h-2.95a.527.527 0 01-.527-.527v-.359zm1.413 2.827c0-.291.236-.528.527-.528h1.537c.29 0 .527.237.527.528v.358a.527.527 0 01-.527.528h-1.537a.527.527 0 01-.527-.528v-.358z"
                />
                <path
                    fill="#FFD298"
                    d="M11.542 5.876v.942c0 .529-.175 1.018-.47 1.412l-.596-1.69.595-2.076c.296.393.471.882.471 1.412z"
                />
                <path
                    fill="#FFE3C1"
                    d="M11.071 4.464V8.23a2.354 2.354 0 01-4.24-1.412v-.942a2.354 2.354 0 014.24-1.412z"
                />
                <path
                    fill={baseColors.orange.primary}
                    d="M23.317 12c.345 0 .598.327.51.66l-2.685 10.253a.527.527 0 01-.51.394h-9.56l-1.435-5.088L11.071 12h12.246z"
                />
                <path
                    fill={baseColors.orange.primary}
                    d="M11.071 12v11.307H.338a.264.264 0 01-.256-.328L2.727 12.4a.528.528 0 01.511-.399h7.833z"
                />
            </g>
            <defs>
                <clipPath id="clip0_1525:11457">
                    <path fill="#fff" d="M0 0h24v24H0z" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default FichePatient;
