import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { baseColors, baseScreenHeight } from 'src/utils/constants/baseStyles';
import { FC } from 'react';
import { Checkbox } from '@mui/material';
import { tableCellHeadText, tableCellText } from 'src/views/Security/SecurityManagement/SecurityManagement';

interface UsersTableHeader {
    name: string;
}

interface UsersDataRow {
    id: number;
    firstName: string;
    lastName: string;
    roles: string;
    activated: boolean;
    category: string;
    action: any;
}

interface UsersTableProps {
    tableHeaders: UsersTableHeader[];
    dataRows: UsersDataRow[];
    prevSelectedUserId: number[];
    setPrevSelectedUserId: any;
}
interface UsersTableActionButtonsProps {
    selectedUser: any;
    prevSelectedUserId: number[];
    setPrevSelectedUserId: any;
}

export const UsersActionButtons: FC<UsersTableActionButtonsProps> = (props) => {
    const { selectedUser, prevSelectedUserId, setPrevSelectedUserId } = props;

    const handleChange = () => {
        const newId = selectedUser.id;
        setPrevSelectedUserId((prev: number[]) => {
            if (prev?.length > 0 && prev?.includes(newId)) {
                const res = prev.filter((item) => item !== newId);
                return res;
            }
            return [...prev, newId];
        });
    };

    return (
        <Checkbox
            onChange={handleChange}
            sx={{
                color: baseColors.green.primary,
                '&.Mui-checked': {
                    color: baseColors.green.primary,
                },
            }}
            checked={prevSelectedUserId?.includes(selectedUser.id)}
            size="small"
        />
    );
};

const UsersTable = (props: UsersTableProps) => {
    const { prevSelectedUserId, setPrevSelectedUserId, tableHeaders, dataRows } = props;

    return (
        <TableContainer
            component={Paper}
            style={{ overflow: 'auto', maxHeight: baseScreenHeight.tableContainerHeight }}
        >
            <Table stickyHeader sx={{ minWidth: 650 }} aria-label="role table">
                <TableHead>
                    <TableRow>
                        {tableHeaders.map((header) => (
                            <TableCell sx={{ color: baseColors.green.primary }}>
                                {tableCellHeadText(header.name)}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>

                <TableBody>
                    {dataRows.map((row, index) => (
                        <TableRow
                            key={index}
                            sx={{
                                '&:last-child td, &:last-child th': { border: 0 },
                                '& .MuiTableCell-root': {
                                    padding: '5px 15px 5px 15px',
                                },
                            }}
                        >
                            <TableCell component="th" scope="row">
                                {tableCellText(row.firstName)}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {tableCellText(row.lastName)}
                            </TableCell>
                            <TableCell>{tableCellText(row.roles)}</TableCell>
                            <TableCell>{tableCellText(row.category)}</TableCell>
                            <TableCell>
                                <UsersActionButtons
                                    selectedUser={row}
                                    prevSelectedUserId={prevSelectedUserId}
                                    setPrevSelectedUserId={setPrevSelectedUserId}
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default UsersTable;
