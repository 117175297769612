import { FC } from 'react';
import { ConclusionPayloadType } from 'src/interfaces/types';
import {
    filterArticlesCarePlan,
    filterGoalCarePlan,
    filterSkillCarePlan,
    filterWorkshopCarePlan,
} from '../ConclusionCheckBoxForm/ConclusionCheckBoxForm.utils';
import { DraftConclusion, PreselectedPatientProgram, ProgramSkill } from 'src/interfaces/interfaces';
import { OpenSansRegularText } from 'src/components/Text/OpenSansRegularText';
import { BreeSerifText } from 'src/components/Text/BreeSerifText';
import { SetToUniqDraftData } from '../Logic';

interface IProps {
    payload: ConclusionPayloadType;
    preSelectedPatientProgram: PreselectedPatientProgram;
    skills?: ProgramSkill[];
    draftConclusion: DraftConclusion;
}

const CarePlanInformations: FC<IProps> = ({ draftConclusion, skills, payload }) => {
    const conlusionSkills = skills ?? [];

    const goalsFromProgram = conlusionSkills?.map((skill) => skill?.goals) ?? [];
    const goals = goalsFromProgram?.flat(2);

    const workshopFromProgram = conlusionSkills?.map((skill) => skill?.goals?.map((goal) => goal?.workshops)) ?? [];
    const workshopFromDraft = SetToUniqDraftData({ draftConclusion, isWorkshops: true }) ?? [];
    const workshops: any = draftConclusion?.id ? workshopFromDraft : workshopFromProgram?.flat(2);

    const articleFromProgram = conlusionSkills?.map((skill) => skill?.goals?.map((goal) => goal?.articles)) ?? [];
    const articleFromDraft = SetToUniqDraftData({ draftConclusion }) ?? [];
    const articles: any = draftConclusion?.id ? articleFromDraft : articleFromProgram?.flat(2);

    return (
        <div
            style={{
                overflowX: 'scroll',
                maxHeight: 250,
            }}
        >
            <div
                style={{
                    flexDirection: 'row',
                    display: 'flex',
                    justifyContent: 'space-around',
                    flexWrap: 'wrap',
                }}
            >
                <div>
                    <BreeSerifText style={{ marginBottom: 10 }} fontSize={18}>
                        Compétences :{' '}
                    </BreeSerifText>
                    {filterSkillCarePlan({ skills: conlusionSkills, payload })?.map((skill: any) => (
                        <OpenSansRegularText style={{ marginBottom: 10, paddingLeft: 10 }} key={skill?.id}>
                            {skill?.name}
                        </OpenSansRegularText>
                    ))}
                </div>

                <div>
                    <BreeSerifText style={{ marginBottom: 10 }} fontSize={18}>
                        Objectifs :{' '}
                    </BreeSerifText>
                    {filterGoalCarePlan({ goals, payload })?.map((goal: any) => (
                        <OpenSansRegularText style={{ marginBottom: 10, paddingLeft: 10 }} key={goal?.id}>
                            {goal?.name ?? goal?.goal ?? ''}
                        </OpenSansRegularText>
                    ))}
                </div>

                <div>
                    <BreeSerifText style={{ marginBottom: 10 }} fontSize={18}>
                        Ateliers :{' '}
                    </BreeSerifText>
                    {filterWorkshopCarePlan({ workshops, payload })?.map((workshop: any) => (
                        <OpenSansRegularText style={{ marginBottom: 10, paddingLeft: 10 }} key={workshop?.id}>
                            {workshop?.name}
                        </OpenSansRegularText>
                    ))}
                </div>

                <div>
                    <BreeSerifText style={{ marginBottom: 10 }} fontSize={18}>
                        Articles :{' '}
                    </BreeSerifText>
                    {filterArticlesCarePlan({ articles, payload })?.map((article: any) => (
                        <OpenSansRegularText style={{ marginBottom: 10, paddingLeft: 10 }} key={article?.id}>
                            {article?.title ?? '-'}
                        </OpenSansRegularText>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default CarePlanInformations;
