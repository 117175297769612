import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { baseColors, baseFontFamily, baseFontSize, baseScreenHeight } from 'src/utils/constants/baseStyles';
import CustomEmptyData from '../../../components/CustomEmptyData';
import { Box } from '@mui/system';
import { formatDateDiag } from 'src/utils/utils';
import { tableCellText } from '../SecurityManagement/SecurityManagement';
import { CustomText } from 'src/components/Text/CustomText';

interface ConnexionTableHeader {
    name: string;
}

interface ConnexionDataRow {
    id: number;
    username: string;
    connexion: Date;
    deconnexion: Date;
}

interface ConnexionTableProps {
    tableHeaders: ConnexionTableHeader[];
    dataRows: ConnexionDataRow[];
}

const ConnexionTable = (props: ConnexionTableProps = { tableHeaders: [], dataRows: [] }) => {
    const tableHeaders = props.tableHeaders;
    const connexions: any = props.dataRows;

    return (
        <TableContainer
            component={Paper}
            style={{ overflow: 'auto', maxHeight: baseScreenHeight.securitySubMenusHeight }}
        >
            <Table stickyHeader sx={{ minWidth: 650 }} aria-label="role table">
                <TableHead>
                    <TableRow>
                        {tableHeaders.map((header) => {
                            return (
                                <TableCell sx={{ color: baseColors.green.primary }}>
                                    <CustomText
                                        color={baseColors.green.primary}
                                        fontFamily={baseFontFamily.OpenSansBold}
                                        fontSize={baseFontSize.mini}
                                    >
                                        {header.name}
                                    </CustomText>
                                </TableCell>
                            );
                        })}
                    </TableRow>
                </TableHead>
                {connexions ? (
                    connexions?.map((row: any) => {
                        return (
                            <TableBody>
                                <TableRow key={row?.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell>{tableCellText(row?.username)}</TableCell>
                                    <TableCell>{tableCellText(formatDateDiag(row?.connexion))}</TableCell>
                                    <TableCell>
                                        {tableCellText(
                                            row?.lastLogout
                                                ? formatDateDiag(row?.deconnexion)
                                                : ' Pas encore déconnecté ',
                                        )}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        );
                    })
                ) : (
                    <TableBody>
                        <TableRow>
                            <TableCell>{/*column id*/}</TableCell>
                            <TableCell>
                                <Box sx={{ marginBottom: 20, marginLeft: '50%' }}>
                                    <CustomEmptyData marginTop={50} height={50} width={50} />
                                </Box>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                )}
            </Table>
        </TableContainer>
    );
};

export default ConnexionTable;
