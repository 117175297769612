
export enum RoleActionType{
    LIST_ROLES = 'LIST_ROLES',
}

export interface RoleList{
    type: RoleActionType.LIST_ROLES;
    payload: any;
}

export type RoleAction = RoleList;