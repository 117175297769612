import React,{useState} from 'react';
import Box from '@mui/material/Box';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import QuizSettings from '../QuizSettings';
import { baseColors } from 'src/utils/constants/baseStyles';
import useStyles from '../styles';
import { CustomText } from 'src/components/Text/CustomText';
import QuizList from '../QuizList/QuizList';
import styles from 'src/views/Settings/Workshops/styles';
import CustomModal from 'src/components/CustomModal';
import CustomCircleLoader from 'src/components/CustomLoader/CustomCircleLoader';


export default function QuizTabs() {
    const classes = useStyles();
    const [selectedQuiz, setSelectedQuiz] = useState<any>({});
    const [modalOpen, setModalOpen] = useState(false);
    const [loadingModalContent, setLoadingModalContent] = useState(false);
    const [updateList, setUpdateList] = useState<boolean>(false);

    const handleClick = () => {
        setModalOpen(true);
    };

    const toggleQuizModal = () => {
        setModalOpen(!modalOpen);
        setSelectedQuiz({})
    }

    return (
        <Box sx={{ w: '90%', p: 2 }}>
            <Box sx={styles.header}>
                <CustomText isBreefSerif isTitle color={baseColors.green.primary} style={{ marginLeft: 20 }}>
                    Liste des Quiz
                </CustomText>

                <CustomLoadingButton label="Créer un nouveau Quiz" handleClick={handleClick} />
            </Box>

            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <QuizList 
                    setModalOpen={setModalOpen} 
                    setLoadingModalContent={setLoadingModalContent} 
                    setSelectedQuiz={setSelectedQuiz}
                    setUpdateList={setUpdateList}
                    updateList={updateList}
                />
                
                <CustomModal
                    withMinHeight
                    size={"large"}
                    width={"100%"}
                    title={selectedQuiz?.id ? 'Modifier Quiz': 'Nouvel Quiz'}
                    toggleModal={toggleQuizModal}
                    open={modalOpen}
                >
                    {
                        loadingModalContent ? 
                        (
                            <Box paddingLeft={5} paddingRight={5} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10%' }}>
                                <CustomCircleLoader height={55} width={55} />
                            </Box>
                        ) : 
                        (
                            <QuizSettings 
                                selectedQuiz={selectedQuiz} 
                                setUpdateList={setUpdateList}
                                setModalOpen={setModalOpen}
                            />
                        )
                    }
                </CustomModal>
            </Box>

        </Box>
    );
}
