import axios from './AxiosInstance';

const getFeatures =  (filters?: any) => {

    return axios.get('api/features?paginated=false').then((response:any) => {
         const result = JSON.parse(response.request.response);
        return result.data;
    }).catch((reason) => {
        console.log('Get User Features Error', reason);
    });
}

const FeatureService = {
    getFeatures
};


export default FeatureService;