import { FC, useEffect, useState, useContext } from 'react';
import CustomModal from 'src/components/CustomModal';
import { Grid } from '@mui/material';
import { UserContext } from 'src/providers/UserProvider';
import { baseColors, baseFontSize } from 'src/utils/constants/baseStyles';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import { message } from 'src/utils/helpers/helpers';
import { PatientService } from 'src/services/Patients';
import { InputFieldPassword } from 'src/components/InputField/InputFieldPassword';
import { CustomText } from 'src/components/Text/CustomText';
import { isSamePassword, isValidPassword } from 'src/utils/helpers/InputValidator';

interface EditPasswordProps {
    open: boolean;
    setOpen: Function;
    setUpdate?: Function;
}

const defaultValue = {
    actualPassword: '',
    password: '',
    retypePassword: '',
    showPassword: false,
};

export const EditPassword: FC<EditPasswordProps> = (props) => {
    const { open, setOpen } = props;
    const [lockInput, setLoackInput] = useState(false);
    const { userInfos: userInfosFromContext, setUserInfos } = useContext(UserContext);
    const [soignant] = useState<any>(userInfosFromContext);
    const [loadingUserInfos, setLoadingUserInfos] = useState<boolean>(false);
    const [postTraitmentMessage, setPostTraitementMessage] = useState(message.soignantAddedSuccessfully);
    const [modalPostTraitementVisible, setModalPostTraitementVisible] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [values, setValues] = useState(defaultValue);

    const passed = isValidPassword(values.password);
    const disabled = !isSamePassword(values.password,values.retypePassword) || !passed;

    useEffect(() => {
        !passed
            ? setErrorMessage('Le mot de passe doit contenir au moins une lettre, un chiffre et un caractère spécial !')
            : !(values.password === values.retypePassword)
            ? setErrorMessage('Les mots de passe entrés ne correspondent pas')
            : setErrorMessage('');
    }, [values.password, values.retypePassword]);

    const editPassword = () => {
        setLoadingUserInfos(true);
        setLoackInput(true);
        if (soignant?.id) {
            PatientService.putChangePassword(soignant?.id, values)
                .then((response) => {
                    if (response.data.code === 200) {
                        setPostTraitementMessage(message.soignantAddedSuccessfully);
                        setUserInfos(soignant);
                        setOpen(false);
                        setValues(defaultValue);
                    } else {
                        setPostTraitementMessage(
                            'Utilisez au moins huit caractères avec au moins une lettre, un chiffre et un caractère spécial | Vérifier que les deux mots de passe sont semblabes',
                        );
                        setErrorMessage('Mot de passe actuel incorrect');
                    }
                    setLoadingUserInfos(false);
                    setLoackInput(false);
                })
                .catch(() => {
                    setPostTraitementMessage(message.patientNotInserted);
                    setErrorMessage('Mot de passe actuel incorrect');
                    setLoadingUserInfos(false);
                    setLoackInput(false);
                });
            // .finally(() => {
            //     setModalPostTraitementVisible(true);
            //     setLoadingUserInfos(false);
            //     setLoackInput(false);
            // });
        } else {
            alert('Soignant id undefined');
        }
    };

    return (
        <div>
            <CustomModal
                title="Modification du mot de passe"
                open={open}
                toggleModal={setOpen}
                footer={
                    <Grid container style={{ marginTop: 30 }} justifyContent={'center'}>
                        <CustomLoadingButton
                            width="80%"
                            disabled={disabled}
                            label="Modifier le mot de passe"
                            handleClick={editPassword}
                        />
                    </Grid>
                }
            >
                <Grid style={{ paddingLeft: 30, paddingRight: 30, marginTop: 30 }}>
                    <Grid container direction="row" alignItems="center" justifyContent="space-between">
                        <InputFieldPassword
                            width={360}
                            type="password"
                            onChange={(value: any) => {
                                setValues((old) => ({ ...old, actualPassword: value }));
                            }}
                            label="Mot de passe actuel :"
                            value={values?.actualPassword}
                            placeholder="Minimum 8 caractères"
                        />
                    </Grid>
                    <Grid container direction="row" alignItems="center" justifyContent="space-between">
                        <InputFieldPassword
                            width={360}
                            type="password"
                            onChange={(value: any) => {
                                setValues((old) => ({ ...old, password: value }));
                            }}
                            regexLabel={
                                values.password.trim().length > 0 &&
                                !isValidPassword(values.password)
                                    ? '(Le mot de passe doit comporter au moins 8 caractères, une minuscule, une majuscule, un chiffre et un  caractère spécial.)'
                                    : '(obligatoire)'
                            }
                            label="Nouveau mot de passe :"
                            value={values?.password}
                            placeholder="Minimum 8 caractères"
                        />
                    </Grid>

                    <Grid container direction="row" alignItems={'center'} justifyContent="space-between">
                        <InputFieldPassword
                            width={360}
                            type="password"
                            onChange={(value: any) => setValues((old) => ({ ...old, retypePassword: value }))}
                            regexLabel={
                                values.retypePassword.trim().length > 0 &&
                                !isSamePassword(values.password, values.retypePassword)
                                    ? '(Vérifier que les deux mots de passe sont identiques)'
                                    : '(obligatoire)'
                            }
                            label="Confirmer le mot de passe :"
                            value={values?.retypePassword}
                            placeholder="Minimum 8 caractères"
                        />
                    </Grid>
                </Grid>
                <CustomText
                    style={{ fontSize: baseFontSize.sl, margin: 20, textAlign: 'center' }}
                    color={baseColors.orange.primary}
                >
                    {errorMessage}
                </CustomText>
            </CustomModal>
        </div>
    );
};
