import { makeStyles } from '@mui/styles';
import { baseColors } from '../../utils/constants/baseStyles';

const useStyles = makeStyles({
    container: {
        padding: '12px 12px 25px 12px',
        marginRight: 20,
        width: 110,
        height: 90,
    },
    content: {},
    flexContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    icon: {},
    number: {
        color: baseColors.white,
    },
    label: {
        color: baseColors.white,
    },
});

export default useStyles;
