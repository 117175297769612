import { useContext, useEffect, useState } from 'react';
import { PatientContext } from 'src/providers/PatientProvider';
import { PatientService } from 'src/services/Patients';
import WorkshopService from 'src/services/Workshop/workshop.service';
import { URER_ROLES } from 'src/utils/constants/enums';
import { fullName, isHealthProfessional, isIntervenant } from 'src/utils/utils';

const useProfessionals = () => {
    const [fetchingProfessionals, setFetchingProfessionals] = useState(false);
    const [professionalsBySpecialities, setProfessionalsBySpecialities] = useState<Array<any>>([]);
    const [fetchingProfessionalsBySpecialities, setFetchingProfessionalsBySpecialities] = useState(false);
    const [professionals, setProfessionals] = useState<Array<any>>([]);
    const [professionalsante, setProfessionalsante] = useState<Array<any>>([]);
    const { updateListProfessionnals } = useContext(PatientContext);
    const [professionalProg, setProfessionalProg] = useState<Array<any>>([]);
    const [optimizedIntervenantsBySpecId, setOptimizedIntervenantsBySpecId] = useState<Array<any>>([]);
    const [allIntervenants, setAllIntervenants] = useState<Array<any>>([]);
    const [intervenantSpecialities, setIntervenantSpecialities] = useState<Array<any>>([]);

    const optimizeData = (data: any) => {
        const optimizedData = Array.from(data ?? [], (item: any) => ({
            id: item?.id,
            name: fullName(item?.lastName, item?.firstName),
        }));
        return optimizedData ?? [];
    };

    const handleSetHealthProfessionals = (data: any) => {
        const healthProfessionals = data?.filter((doctor: any) => isHealthProfessional(doctor));
        setProfessionalsante(optimizeData(healthProfessionals));
        setProfessionals(optimizeData(data));
    };

    const handleSetHealthProfessionalProg = (data: any) => {
        //setProfessionalsante(optimizeData(healthProfessionals));
        setProfessionalProg(optimizeData(data));
    };

    const handleFetchProfessionals = () => {
        setFetchingProfessionals(true);
        PatientService.getProfessionalsIsEtp(URER_ROLES.DOCTOR)
            .then((response) => {
                setFetchingProfessionals(false);
                const res = response?.data?.data;
                if (res?.length) {
                    handleSetHealthProfessionals(res);
                }
            })
            .catch(() => setFetchingProfessionals(false));
    };

    const handleFetchProfessionalProg = () => {
        setFetchingProfessionals(true);
        PatientService.getProfessionalsProg(URER_ROLES.DOCTOR)
            .then((response) => {
                setFetchingProfessionals(false);
                const res = response?.data?.data;
                if (res?.length) {
                    handleSetHealthProfessionalProg(res);
                }
            })
            .catch(() => setFetchingProfessionals(false));
    };

    const handleFetchProfessionalGroupedBySpeciality = () => {
        setFetchingProfessionalsBySpecialities(true);
        WorkshopService.getProfessionalGroupedBySpeciality()
            .then((response) => {
                setFetchingProfessionalsBySpecialities(false);
                const res = response?.data?.data;
                const data = res?.map((speciality: any) => ({
                    ...speciality,
                    //items: speciality?.items?.filter((doctor: any) => isIntervenant(doctor)),
                }));

                const intervenantsGroupBySpeciality = data?.filter((item: any) => item?.items?.length > 0);
                const specialities = intervenantsGroupBySpeciality?.map((speciality: any) => ({
                    id: speciality?.id,
                    name: speciality?.name,
                }));
                const flattedData = intervenantsGroupBySpeciality?.flatMap((item: any) => item?.items);

                setAllIntervenants(flattedData ?? []);
                setOptimizedIntervenantsBySpecId(intervenantsGroupBySpeciality ?? []);
                setIntervenantSpecialities(specialities ?? []);
                setProfessionalsBySpecialities(data ?? []);
            })
            .catch(() => setFetchingProfessionalsBySpecialities(false));
    };

    useEffect(() => {
        handleFetchProfessionals();
        handleFetchProfessionalProg();
        handleFetchProfessionalGroupedBySpeciality();
        // eslint-disable-next-line react-hooks/exhaustive-deps
        //eto
    }, [updateListProfessionnals]);

    return {
        fetchingProfessionals,
        professionals,
        handleFetchProfessionals,
        professionalsante,
        professionalProg,
        professionalsBySpecialities,
        fetchingProfessionalsBySpecialities,
        handleFetchProfessionalGroupedBySpeciality,

        optimizedIntervenantsBySpecId,
        setOptimizedIntervenantsBySpecId,

        setAllIntervenants,
        allIntervenants,

        intervenantSpecialities,
        setIntervenantSpecialities,
    };
};

export default useProfessionals;
