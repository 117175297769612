import axios from '../AxiosInstance';

const getMailJetable = () => {
    return axios.get('api/mailJetables');
};

const MailJetableService = {
    getMailJetable,
};

export default MailJetableService;
