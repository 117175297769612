export const handleChange = (event: React.ChangeEvent<HTMLInputElement>, setCheckedItem: any, handleSetSelectedItems: any) => {
    const value = Number(event.target.value);
    let result : any = null;
    setCheckedItem((prev: any) => {
        const exist = prev.length > 0 && prev.includes(value);
        if (exist) {
            result = prev.filter((i: number) => i !== value)
            return result;
        } else {
            result = [...prev, value]
            return result;
        }
    });

    handleSetSelectedItems(result);
};