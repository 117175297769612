import { Box } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import useAdminRedirection from 'src/hooks/useAdminRedirection';
import { ROUTES } from 'src/routes/routes';
import HdjDataService from 'src/services/Hdj/dayHospital.service';
import { ServerError } from 'src/utils/constants/constants';
import DocumentList from './DocumentList';
import HdjConsultation from './HdjConsultation';
import HdjManagementHeader from './HdjManagementHeader';
import HdjWorkshop from './HdjWorkshop';
import PatientsList from './PatientsList';
import useStyles, { sxStyles } from './styles';

const HdjManagement: FC = () => {
    const params = useParams<any>();
    const history = useHistory();
    const classes = useStyles();
    const [fetchedHdjData, setFetchedHdjData] = useState<any>(undefined);
    const [loadingHdjData, setLoadingHdjData] = useState<boolean>(false);

    const handleFectHdjData = () => {
        setLoadingHdjData(true);
        HdjDataService.getDayHospitalById(params?.id)
            .then((response) => {
                let data = response.data?.data;
                if (data) {
                    setFetchedHdjData(data);
                } else {
                    history.replace(ROUTES.notFound);
                    toast.error('HDJ introuvable');
                }
                setLoadingHdjData(false);
            })
            .catch((e) => {
                setLoadingHdjData(false);
                toast.error(ServerError.unknown);
            });
    };

    useEffect(() => {
        handleFectHdjData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params?.id]);

    useAdminRedirection();

    return (
        <Box className={classes.container} marginRight={1}>
            <HdjManagementHeader loadingHdjData={loadingHdjData} fetchedHdjData={fetchedHdjData} />

            <Box style={sxStyles.rowContent}>
                {fetchedHdjData?.date && <PatientsList dayHospitalId={params?.id} fetchedHdjData={fetchedHdjData} />}

                <HdjConsultation marginRight dayHospitalId={params?.id} disableCheckBox />

                <HdjWorkshop dayHospitalId={params?.id} disableCheckBox />

                <DocumentList dayHospitalId={params?.id} fetchedHdjData={fetchedHdjData} withoutBtn />
            </Box>
        </Box>
    );
};

export default HdjManagement;
