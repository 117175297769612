import { makeStyles } from '@mui/styles';
import { baseBorderRadius, baseColors } from 'src/utils/constants/baseStyles';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        minWidth: 660,
        backgroundColor: baseColors.white,
        // ...baseScreenHeight.defaultHeight,
        ...baseBorderRadius.leftOnly,
    },
    flexCenter: { display: 'flex', justifyContent: 'center' },
    rowCenter: { display: 'flex', justifyContent: 'center', flexDirection: 'row' },
});

export default useStyles;
