import { PostNotificationConfigurations } from 'src/interfaces/interfaces';
import axios from './AxiosInstance';

const postNotificationConfigurations = (payload : PostNotificationConfigurations) => axios.post('api/notificationConfigurations', payload);

const getNotificationConfigurations = () => axios.get(`api/notificationConfigurations/current`);

const GeneralSettingService = {
    postNotificationConfigurations,
    getNotificationConfigurations,
}

export default GeneralSettingService;
