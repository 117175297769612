import { Grid, MenuItem, Typography } from '@mui/material';
import { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { useRouteMatch, useLocation } from 'react-router';
import { baseColors, baseFontFamily, baseFontSize } from 'src/utils/constants/baseStyles';
import { useHistory } from 'react-router-dom';
import { EvaluationsTabMenus } from 'src/utils/constants/constants';
import { sxStyles } from './styles';
import useStyles from './styles';

interface IProps {
    setSelectedAxis: Dispatch<SetStateAction<number>>;
}

export const EvaluationTab: FC<IProps> = ({ setSelectedAxis }) => {
    const history = useHistory();
    const { url } = useRouteMatch();
    const location = useLocation();
    const classes = useStyles();

    const onMenuSelected = (menu: any) => {
        setSelectedAxis(menu?.id);
        if (!history.location.pathname.includes(menu.path)) history.push(`${url}/${menu.path}`);
    };

    const patientId: number = +window?.location?.pathname?.split('/')?.[3] || 0;

    useEffect(() => {
        history.push(window?.location?.pathname);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patientId]);

    const checkCurrentLocation = (menuPath: string) => location.pathname.startsWith(`${url}/${menuPath}`);
    let FinalEvaluationsTabMenus = EvaluationsTabMenus;

    if (url.includes('/interview')) {
        FinalEvaluationsTabMenus = EvaluationsTabMenus.filter((menu) => menu.path !== 'compterendu');
    }

    return (
        <div className={classes.container}>
            <div
                style={{
                    display: 'flex',
                    position: 'relative',
                    alignItems: 'stretch',
                    justifyContent: 'space-around',
                    flexDirection: 'row',
                    backgroundColor: baseColors.green.light,
                }}
            >
                {FinalEvaluationsTabMenus.map((menu) => (
                    <Grid item lg={2} xs={2} key={menu.id}>
                        <div
                            style={{
                                height: '100%',
                            }}
                        >
                            <MenuItem
                                style={{
                                    ...sxStyles.menuItem,
                                    borderBottomWidth: checkCurrentLocation(menu.path) ? 3 : 2,
                                    borderBottomStyle: 'solid',
                                    borderBottomColor: checkCurrentLocation(menu.path)
                                        ? baseColors.green.primary
                                        : baseColors.green.light,
                                }}
                                onClick={() => onMenuSelected(menu)}
                            >
                                <Typography
                                    style={sxStyles.label}
                                    color={baseColors.green.primary}
                                    fontSize={baseFontSize.mini}
                                    fontFamily={
                                        checkCurrentLocation(menu.path)
                                            ? baseFontFamily.OpenSansBold
                                            : baseFontFamily.OpenSansRegular
                                    }
                                >
                                    {menu.title}
                                </Typography>
                            </MenuItem>
                        </div>
                    </Grid>
                ))}
            </div>
        </div>
    );
};
