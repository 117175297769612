import { makeStyles } from '@mui/styles';
import { baseBorderRadius, baseColors, baseFontFamily, baseScreenHeight } from 'src/utils/constants/baseStyles';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: baseColors.white,
        ...baseScreenHeight.defaultHeight,
        ...baseBorderRadius.allSide,
    },
    header: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    title: {
        fontFamily: baseFontFamily.BreeSerif,
        marginBottom: 15,
    },
    subtitle: {
        color: baseColors.green.secondary,
    },
    filterHeader: {
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: baseColors.green.light,
        borderRadius: 7,
        padding: 7,
        marginBottom: 15,
        width: 200,
        justifyContent: 'space-between',
    },
    filterLabel: {
        paddingLeft: 10,
    },
});

export const sxStyles : any = { 
    container: { 
        display: 'flex', 
        flexDirection: 'row', 
        alignItems: 'center', 
        marginTop: 2 
    },
    titleContainer: { 
        display: 'flex', 
        flexDirection: 'row', 
        alignItems: 'center' 
    },
    loadingContainer: { 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center', 
        marginTop: 5 
    }
}

export default useStyles;
