import React, { Dispatch, FC, SetStateAction } from 'react';
import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { baseColors, baseFontSize } from '../../utils/constants/baseStyles';
import { makeStyles } from '@mui/styles';
import { SearchRounded } from '@mui/icons-material';
import { specialCharToUnderscore } from 'src/utils/utils';
import FilterListTwoToneIcon from '@mui/icons-material/FilterListTwoTone';
import IconButton from '@mui/material/IconButton';
import FilterPopover from './FilterPopover/FilterPopover';

const CustomTextField = styled(TextField)({
    marginTop: 10,
    width: '100%',
    '& label.Mui-focused': {},
    '& .MuiInput-underline:after': {},
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderRadius: 10,
            borderColor: baseColors.green.light,
        },
        '&:hover fieldset': {
            borderColor: baseColors.green.light,
        },
        '&:disabled fieldset': {
            borderColor: baseColors.green.light,
        },
        '&.Mui-focused fieldset': {
            borderColor: baseColors.green.light,
        },
    },
});

const useStyles = makeStyles({
    root: {
        borderRadius: 10,
        color: baseColors.black,
        background: baseColors.green.light,
        height: 46,
    },
    searchIcon: {
        marginRight: 12,
    },
    filterIcon: {
        cursor: 'pointer',
    },
    input: {
        '&::placeholder': {
            color: baseColors.green.primary,
        },
    },
});

type AppProps = {
    value?: string;
    placeholder?: string;
    onChange?: Function;
    onSearch?: Function;
    disabled?: boolean;
    padding?: number;
    width?: number;
    fontSize?: string;
    iconMarginRight?: number;
    filterItems?: { id: number; name: string }[];
    selectedFilterIds?: number[];
    setSelectedFilterIds?: Dispatch<SetStateAction<number[]>>;
    withFilter?: boolean;
    filterTitle?: string;
};

export const SearchInputField: FC<AppProps> = (props) => {
    const {
        selectedFilterIds,
        setSelectedFilterIds,
        filterItems,
        iconMarginRight,
        fontSize,
        width,
        value,
        placeholder,
        onChange,
        onSearch,
        disabled,
        padding,
        withFilter,
        filterTitle,
    } = props;
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClickFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <div>
            <FilterPopover
                filterTitle={filterTitle}
                filterItems={filterItems as { id: number; name: string }[]}
                selectedFilterIds={selectedFilterIds}
                setSelectedFilterIds={setSelectedFilterIds}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
            />

            <CustomTextField
                disabled={disabled ?? false}
                autoFocus={value !== '' ? true : false}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    onChange && onChange(event.target.value);
                }}
                onKeyPress={(ev: React.KeyboardEvent<HTMLInputElement>) => {
                    if (ev.key === 'Enter') {
                        onSearch?.();
                    }
                }}
                placeholder={placeholder}
                InputProps={{
                    className: classes.root,
                    classes: { input: classes.input },
                    style: {
                        background: baseColors.green.light,
                        fontSize: fontSize ?? baseFontSize.mini,
                        borderRadius: 10,
                        padding: padding ? padding : 4,
                        paddingLeft: 5,
                        color: baseColors.green.primary,
                        width: width ?? undefined,
                        paddingRight: 10,
                    },
                    startAdornment: (
                        <SearchRounded
                            style={{ padding: padding ? padding : 4, marginRight: iconMarginRight ?? undefined }}
                            htmlColor={baseColors.green.primary}
                            className={classes.searchIcon}
                        />
                    ),
                    endAdornment: withFilter ? (
                        <IconButton aria-label="expand row" size="small" onClick={handleClickFilter}>
                            <FilterListTwoToneIcon
                                style={{ padding: 5 }}
                                htmlColor={baseColors.green.primary}
                                className={classes.filterIcon}
                            />
                        </IconButton>
                    ) : undefined,
                }}
                value={value}
                size="small"
                variant="outlined"
                id={specialCharToUnderscore(placeholder ?? '')}
            />
        </div>
    );
};
