import React, { FC } from 'react';
import { baseColors } from 'src/utils/constants/baseStyles';
import { Typography } from '@mui/material';
import useStyles from './styles';
import { Box } from '@mui/system';

interface BlueCardProps {
    title: string;
    children: any;
}

const BlueCard: FC<BlueCardProps> = (props) => {
    const { title, children } = props;
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <Typography color={baseColors.green.primary} fontSize={14} textAlign={'center'}>
                {title}
            </Typography>
            <Box className={classes.content}>{children}</Box>
        </div>
    );
};

export default BlueCard;
