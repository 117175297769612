import { makeStyles } from '@mui/styles';
import { baseColors } from 'src/utils/constants/baseStyles';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        padding: 20,
    },
    titleContainer: {
        marginRight: 10,
    },
    title: {
        textAlign: 'center',
        color: baseColors.green.primary,
    },
    content: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 25,
    },
    duration: {
        marginRight: 15,
    },
    label: { color: baseColors.black },
    timeContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-arround',
    },
    description: {
        margin: 10,
        marginTop: 50,
    },
}));

const sxStyles: any = {
    loading: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 10,
    },
    loadingLabel: { color: baseColors.green.primary, fontSize: '12pt', marginTop: 5 },
};

export { sxStyles };

export default useStyles;
