import { useState, FC, useEffect } from 'react';
import { createContext } from 'react';
import { IProgramFilter, ProgramItem } from 'src/interfaces/interfaces';
import { defaultProgramFilter } from 'src/utils/constants/constants';
import { containsObject } from 'src/utils/helpers/helpers';

type ProgramType = {
    loadingProgramList: boolean;
    setLoadingProgramList: (val: boolean) => void;
    loadingPatientProgram:boolean, 
    setLoadingPatientProgram: (val: boolean) => void,
    programList: any[];
    setProgramList: (val: any[]) => void;
    filteredProgramList: any[];
    setFilteredProgramList: (val: any[]) => void;
    programState: any[];
    setProgramState: (val: any[]) => void;
    programLevel: any[];
    setProgramLevel: (val: any[]) => void;
    programType: any[];
    setProgramType: (val: any[]) => void;
    filter: IProgramFilter;
    setFilter: (val: IProgramFilter) => void;
    currentProgram?: ProgramItem;
    setCurrentProgram: (val: ProgramItem) => void;
    syntheseHasChange?: boolean;
    setSyntheseHasChange: (val: boolean) => void;
    syntheseChangeSaveCallback: Function;
    setsyntheseChangeSaveCallback: (val: Function) => void;

    programHasChange?: boolean;
    setProgramHasChange: (val: boolean) => void;
    programChangeSaveCallback: Function;
    setProgramChangeSaveCallback: (val: Function) => void;

    checkedProgram?: any;
    setCheckedProgram: (val: any) => void;

    checkedModule?: any;
    setCheckedModule: (val: any) => void;

    checkedWorkshop?: any;
    setCheckedWorkshop: (val: any) => void;

    selectedWorkshop?:any;
    setSelectedWorkshop: (val: any) => void;

    programId: number;
    setProgramId: (val: number) => void;

    defaultWorkshops: Array<number>;
    setDefaultWorkshops: (val: any) => void;

    hasFilter?: any;

    selectedDEProgramId: number;
    setSelectedDEProgramId: (val: number) => void;

    preselectedPatientProgram: any;
    setPreSelectedPatientProgram: (val: any) => void;
};
export const ProgramContext = createContext<ProgramType>({
    loadingProgramList: false,
    setLoadingProgramList: () => {},
    loadingPatientProgram: false,
    setLoadingPatientProgram: () => {},
    programList: [],
    setProgramList: () => {},
    filteredProgramList: [],
    setFilteredProgramList: () => {},
    programState: [],
    setProgramState: () => {},
    programLevel: [],
    setProgramLevel: () => {},
    programType: [],
    setProgramType: () => {},
    filter: defaultProgramFilter,
    setFilter: () => {},
    currentProgram: undefined,
    setCurrentProgram: () => {},
    syntheseHasChange: false,
    setSyntheseHasChange: () => {},
    syntheseChangeSaveCallback: () => {},
    setsyntheseChangeSaveCallback: () => {},

    programHasChange: false,
    setProgramHasChange: () => {},
    programChangeSaveCallback: () => {},
    setProgramChangeSaveCallback: () => {},

    checkedProgram: null,
    setCheckedProgram: () => {},

    checkedModule: null,
    setCheckedModule: () => {},

    checkedWorkshop: null,
    setCheckedWorkshop: () => {},

    selectedWorkshop: null,
    setSelectedWorkshop: () => {},

    programId: -1,
    setProgramId: () => {},

    defaultWorkshops: [],
    setDefaultWorkshops: () => {},

    selectedDEProgramId: 0,
    setSelectedDEProgramId: () => {},

    preselectedPatientProgram: null,
    setPreSelectedPatientProgram: () => {},
});

export const ProgramProvider: FC = (props) => {
    const { children } = props;
    const [loadingProgramList, setLoadingProgramList] = useState<boolean>(false);
    const [loadingPatientProgram, setLoadingPatientProgram] = useState<boolean>(false);
    const [programList, setProgramList] = useState<any[]>([]);
    const [programState, setProgramState] = useState<any[]>([]);
    const [programLevel, setProgramLevel] = useState<any[]>([]);
    const [programId, setProgramId] = useState<number>(-1);
    const [programType, setProgramType] = useState<any[]>([]);
    const [filteredProgramList, setFilteredProgramList] = useState<any[]>([]);
    const [currentProgram, setCurrentProgram] = useState<ProgramItem | undefined>(undefined);
    const [hasChange, setHasChange] = useState<boolean>(false);
    const [filter, setFilter] = useState<IProgramFilter>(defaultProgramFilter);
    const [syntheseChangeSaveCallback, setsyntheseChangeSaveCallback] = useState<Function>(() => {});
    const [programChangeSaveCallback, setProgramChangeSaveCallback] = useState<Function>(() => {});
    const [checkedProgram, setCheckedProgram] = useState<any>(null);
    const [checkedModule, setCheckedModule] = useState<any>(null);
    const [checkedWorkshop, setCheckedWorkshop] = useState<any>(null);
    const [selectedWorkshop, setSelectedWorkshop] = useState<any>(null);
    
    const [preselectedPatientProgram, setPreSelectedPatientProgram] = useState<any>(null);

    const [defaultWorkshops, setDefaultWorkshops] = useState<Array<any>>([]);

    const [selectedDEProgramId, setSelectedDEProgramId] = useState<number>(0);

    useEffect(() => {
        if (programList?.length > 0) {
            let state: any[] = [{ name: 'Tous', id: 0 }];
            let level: any[] = [];
            let type: any[] = [];
            programList.map((program) => {
                if (program?.level) {
                    if (!containsObject(program.level, level)) {
                        level.push(program.level);
                    }
                }

                if (program?.state) {
                    if (!containsObject(program.state, state)) {
                        state.push(program.state);
                    }
                }

                if (program?.programType) {
                    if (!containsObject(program.programType, type)) {
                        type.push(program.programType);
                    }
                }
            });
            setProgramState(state);
            setProgramLevel(level);
            setProgramType(type);
            setFilteredProgramList(programList);
            setFilter(defaultProgramFilter);
        }
    }, [programList]);

    const hasFilter = () => {
        if (filter?.date?.start || filter?.date?.end) {
            return true;
        }

        if (filter?.level?.length > 1) {
            return true;
        }

        if (filter?.state > 0) {
            return true;
        }

        if (filter?.type?.length > 0) {
            return true;
        }

        return false;
    };

    useEffect(() => {
        hasFilter();
    }, [filter]);

    return (
        <ProgramContext.Provider
            value={{
                hasFilter,

                loadingProgramList,
                setLoadingProgramList,
                loadingPatientProgram, 
                setLoadingPatientProgram,
                programList,
                setProgramList,
                filteredProgramList,
                setFilteredProgramList,
                programState,
                setProgramState,
                programLevel,
                setProgramLevel,
                programType,
                setProgramType,
                filter,
                setFilter,
                currentProgram,
                setCurrentProgram,
                syntheseHasChange: hasChange,
                setSyntheseHasChange: setHasChange,
                syntheseChangeSaveCallback,
                setsyntheseChangeSaveCallback,

                programHasChange: hasChange,
                setProgramHasChange: setHasChange,
                programChangeSaveCallback,
                setProgramChangeSaveCallback,

                checkedProgram,
                setCheckedProgram,

                checkedModule,
                setCheckedModule,

                checkedWorkshop,
                setCheckedWorkshop,

                selectedWorkshop,
                setSelectedWorkshop,

                programId,
                setProgramId,

                defaultWorkshops,
                setDefaultWorkshops,

                selectedDEProgramId,
                setSelectedDEProgramId,

                preselectedPatientProgram,
                setPreSelectedPatientProgram,
            }}
        >
            {children}
        </ProgramContext.Provider>
    );
};
