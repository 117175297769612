import { Dispatch, FC, SetStateAction } from 'react';
import { Grid } from '@mui/material';
import { InputField } from 'src/components/InputField/InputField';
import { isNotBlank } from 'src/utils/helpers/InputValidator';
import { ReportPayload } from 'src/interfaces/interfaces';

interface IProps {
    label: string;
    keyValue: string;
    payload: ReportPayload | any;
    errorMessage: string;
    setPayload: Dispatch<SetStateAction<ReportPayload>>;
}

export const CustomEvaluationCRInput: FC<IProps> = ({ errorMessage, keyValue, payload, label, setPayload }) => {
    const handleSetPayload = (text: string) => {
        setPayload((prev) => ({ ...prev, [keyValue]: text }));
    };

    return (
        <Grid style={{}} item>
            <InputField
                multiline
                labelFontSize={14}
                label={label + ' :'}
                validator={isNotBlank}
                errorMessage={errorMessage}
                placeholder="Taper votre texte"
                height={100}
                width={'98%'}
                onChange={handleSetPayload}
                value={payload[keyValue]}
            />
        </Grid>
    );
};
