import { Grid } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { CustomText } from 'src/components/Text/CustomText';
import { consultationType } from 'src/utils/constants/fakeData';
import { CustomRadio } from 'src/components/InputField/CustomRadio';
import { Spacer } from 'src/components/Spacer/Spacer';
import useLocations from 'src/hooks/useLocations';
import { LocationType } from './EventRoomAndRemark';

interface EventConsultationTypeProps {
    payload: any;
    setEventPayload: any;
    loadingData?: any;
    eventData?: any;
    isVisio?: boolean;
    selectedWorkshopData?: any;
}

const EventConsultationType: FC<EventConsultationTypeProps> = (props) => {
    const { payload, setEventPayload, eventData, selectedWorkshopData } = props;
    const { customLocation, locations } = useLocations();
    const [location, setLocation] = useState<LocationType>({} as LocationType);
    const [consultationTypeId, setConsultationTypeId] = useState<Number>(1);
    const defaultValue = eventData?.consultationType?.id ?? 1;

    const handleSetConsultationFromWorkshop = () => {
        if (!eventData) {
            setConsultationTypeId(selectedWorkshopData?.consultationType?.id ?? 1);
            setLocation(selectedWorkshopData?.customLocation?.id);
            setEventPayload((old: any) => ({
                ...old,
                consultationTypeId: selectedWorkshopData?.consultationType?.id ?? 1,
                roomId: 0,
                isInSitu: Boolean(selectedWorkshopData?.consultationType?.id === 1),
                customLocation: selectedWorkshopData?.customLocation?.id,
                locationId: selectedWorkshopData?.customLocation?.id
            }));
        }
    };

    useEffect(() => {
        if (eventData) {
            const customLocationValue = eventData?.location;
            setLocation(customLocationValue);
            setConsultationTypeId(eventData?.consultationType?.id);
            setEventPayload((old: any) => ({
                ...old,
                consultationTypeId: eventData?.consultationType?.id,
                roomId: 0,
                isInSitu: Boolean(eventData?.consultationType?.id === 1),
                customLocation: eventData?.consultationType?.id === 1 ? location?.id ?? 0 : 0,
                locationId: eventData?.consultationType?.id === 1 ? location?.id ?? 0 : 0,
            }));
        } else if (Object.values(selectedWorkshopData).length === 0) {
            setConsultationTypeId(1);
            setEventPayload((old: any) => ({
                ...old,
                consultationTypeId: 1,
                roomId: 0,
                isInSitu: Boolean(true),
                customLocation: 0,
            }));
        }
    }, [eventData, locations]);

    useEffect(() => {
        handleSetConsultationFromWorkshop();
    }, [payload?.workshopId]);

    return (
        <>
            <Grid container direction="row" alignItems={'center'} justifyContent="space-between">
                <Grid item>
                    <CustomText>Type de consultation : </CustomText>
                </Grid>
                <Grid item style={{ width: '60%', display: 'flex', flexDirection: 'row' }}>
                    {consultationType.map((type, i) => (
                        <Grid item>
                            <CustomRadio
                                checked={consultationTypeId === type.id}
                                onClick={(value: number) => {
                                    setEventPayload((old: any) => ({
                                        ...old,
                                        consultationTypeId: type.id,
                                        roomId: 0,
                                        isInSitu: Boolean(type.id === 1),
                                        customLocation: type.id === 1 ? location?.id ?? 0 : 0,
                                    }));
                                    setConsultationTypeId(type.id);
                                }}
                                label={type.name}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Grid>

            <Spacer />
        </>
    );
};

export default EventConsultationType;
