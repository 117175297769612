import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import {
    DeleteRequestResponse,
    OptimizedItem,
    PostSkillPayload,
    ProgramSkill,
    SkillItem,
} from 'src/interfaces/interfaces';
import SkillService from 'src/services/Skills/skill.service';
import { defaultSkillPaylopad } from 'src/views/Settings/Skills/Skills.utils';
import { useFetchSkillsRQHooks } from './ReactQueryHooks/useSkillRQHooks';
import { pathologyIds, sortByIds } from 'src/utils/utils';

interface IProps {
    toggleModal?: Function;
    setPayload?: Dispatch<SetStateAction<PostSkillPayload>>;
    setUpdateList?: Dispatch<SetStateAction<boolean>>;
    setModalConfirmVisible?: Dispatch<SetStateAction<boolean>>;
    setSkill?: Dispatch<SetStateAction<SkillItem>>;
    setJustAddedSkills?: Dispatch<SetStateAction<ProgramSkill[]>>;
    setNewSkillLabel?: Dispatch<SetStateAction<string>>;
}

const useSkillHooks = ({
    setJustAddedSkills,
    setSkill,
    toggleModal,
    setPayload,
    setUpdateList,
    setModalConfirmVisible,
    setNewSkillLabel,
}: IProps) => {
    const [saving, setSaving] = useState<boolean>(false);
    const { data, isLoading: fetchingSkills, refetch } = useFetchSkillsRQHooks();
    const skills = sortByIds(data ?? []);

    const successfull = (message: string, response?: DeleteRequestResponse) => {
        if (response?.code === 409) {
            toast.error("Impossible de supprimer la compétence car c'est déjà liée à d'autre donnée");
        } else if (response?.code === 400) {
            toast.error('Compétence existe déjà');
        } else {
            toast.success(message);
            setModalConfirmVisible?.((prev) => !prev);
            setUpdateList?.((prev) => !prev);
            toggleModal?.();
            setSkill?.({} as SkillItem);
            setPayload?.(defaultSkillPaylopad);
        }

        setSaving(false);
    };

    const failed = (message: string) => {
        toast.error(message);
        setSaving(false);
    };

    const optimizedSkills = useMemo(() => {
        if (skills?.length) {
            const optimized = Array.from(skills, (item: { id: number; name: string; pathologie: OptimizedItem[] }) => ({
                id: item?.id,
                name: item?.name,
                pathologyIds: pathologyIds(item?.pathologie) ?? [],
            }));
            return optimized;
        }
        return [];
    }, [skills]);

    const handleCreateNewSkill = ({ payload }: { payload: PostSkillPayload }) => {
        setSaving(true);
        SkillService.PostNewSkill({ payload })
            .then((res) => {
                refetch();
                successfull('Création de compétence réussi');
                const newSkill = res?.data?.data;
                if (newSkill?.id) {
                    setJustAddedSkills?.((prevSkills) => [...prevSkills, newSkill]);
                    setNewSkillLabel?.('');
                }
            })
            .catch(() => failed('Une erreur est survenue lors du création compétence'));
    };

    const handleEditSkill = ({ skillId, payload }: { skillId: number; payload: PostSkillPayload }) => {
        setSaving(true);
        SkillService.EditSkill({ skillId, payload })
            .then(() => {
                refetch();
                successfull('Modification de compétence réussie');
            })
            .catch(() => failed('Une erreur est survenue lors du modification de la compétence'));
    };

    const handleDeleteSkill = ({ skillId }: { skillId: number }) => {
        setSaving(true);
        SkillService.DeleteSkill({ skillId })
            .then((response) => {
                refetch();
                successfull('Compétence supprimée', response?.data);
            })
            .catch(() => failed('Une erreur est survenue lors du suppression de la compétence'));
    };

    return {
        handleCreateNewSkill,
        saving,

        skills,
        optimizedSkills,
        fetchingSkills,

        handleDeleteSkill,

        handleEditSkill,
    };
};

export default useSkillHooks;
