import { FC, useEffect, useState } from 'react';
import ScheduledWorkShopItem from 'src/components/ScheduledWorkShopItem';
import useStyles from './styles';
import { Box } from '@mui/material';
import { baseColors, baseFontSize } from 'src/utils/constants/baseStyles';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/stores/store';
import { retrieveProgrammedWorkshops } from 'src/actions/dashboard/dashboard';
import Switch from '@mui/material/Switch';
import CustomCircleLoader from 'src/components/CustomLoader/CustomCircleLoader';
import CustomEmptyData from 'src/components/CustomEmptyData';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import { CustomText } from 'src/components/Text/CustomText';

const ScheduledWorkshops: FC = (props) => {
    const [switchKey, setSwitchKey] = useState<boolean>(sessionStorage.getItem('switchKey') === 'true');
    const [checked, setChecked] = useState<boolean>(switchKey);
    const [loadingWorkshops, setLoadingWorkshops] = useState<boolean>(false);
    const dispatch = useDispatch();
    const classes = useStyles();
    const handleGetDate = () => dispatch(retrieveProgrammedWorkshops(setLoadingWorkshops, checked));

    useEffect(() => {
        handleGetDate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checked]);

    const scheduledWorkshops = useSelector((state: RootState) => state.dashboard.programmedWorkshops);
    const handleChange = () => {
        setChecked(!checked);
        const val = !switchKey;
        setSwitchKey(!switchKey);
        sessionStorage.setItem('switchKey', val.toString());
    };
    const isEmpty = scheduledWorkshops.length === 0;

    const theme = createTheme({
        components: {
            MuiSwitch: {
                styleOverrides: {
                    switchBase: {
                        color: '#b8bfbe',
                    },
                    colorPrimary: {
                        '&.Mui-checked': {
                            color: baseColors.green.primary,
                        },
                    },
                    track: {
                        opacity: 0.7,
                        backgroundColor: baseColors.green.primary,
                        '.Mui-checked.Mui-checked + &': {
                            opacity: 0.7,
                            backgroundColor: baseColors.green.primary,
                        },
                    },
                },
            },
        },
    });

    return (
        <Box className={classes.container}>
            <Box position="sticky" className={classes.header} style={{paddingLeft:"0px"}}>
                <CustomText fontSize={baseFontSize.md} isBreefSerif>
                    Mes ateliers programmés
                </CustomText>

                <Box className={classes.rightContent}>
                    <ThemeProvider theme={theme}>
                        <FormControlLabel
                            control={
                                <Switch
                                    size="small"
                                    checked={checked}
                                    onChange={handleChange}
                                    sx={{ color: baseColors.green.primary }}
                                />
                            }
                            label=""
                        />
                    </ThemeProvider>

                    <CustomText fontSize={baseFontSize.sm}>
                        Afficher les ateliers programmés jusqu'à 3 semaines
                    </CustomText>
                </Box>

                <CustomText fontSize={baseFontSize.mini}>{}</CustomText>
            </Box>
            <Box className={classes.content}>
                {loadingWorkshops ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 10 }}>
                        <CustomCircleLoader height={50} width={50} />
                    </Box>
                ) : isEmpty ? (
                    <CustomEmptyData marginBottom={5} marginTop={20} height={50} width={50} />
                ) : (
                    scheduledWorkshops?.map((item: any, index: number) => (
                        <Box key={index}>
                            <ScheduledWorkShopItem label={item.label} programme={item.program} date={item.date} checked={checked} />
                        </Box>
                    ))
                )}
            </Box>
        </Box>
    );
};

export default ScheduledWorkshops;
