import { Box, IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import { baseColors, baseScreenHeight } from 'src/utils/constants/baseStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { toast } from 'react-toastify';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import { CustomConfirm } from 'src/components/CustomConfirm/CustomConfirm';
import {deleteConsultations, getConsultations} from 'src/services/Consultations';
import { Consultation } from 'src/interfaces/interfaces';
import styles from './styles';
import CustomEmptyData from 'src/components/CustomEmptyData';
import { fullName, sortByIds, uniqByKeyValues } from 'src/utils/utils';
import { CustomText } from 'src/components/Text/CustomText';
import { tableCellHeadText, tableCellText } from 'src/views/Security/SecurityManagement/SecurityManagement';
import SubMenusLoader from 'src/components/SettingsSubMenuBar/SubMenusLoader/SubMenusLoader';
import { ConsultationForm, defaultConsultationValue } from 'src/views/Settings/Consultations/ConsultationsForm/ConsultationForm';

export const ConsultationPanel = () => {
    const [modalConfirmVisible, setModalConfirmVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [updateList, setUpdateList] = useState(false);
    const [loadingConsultation, setLoadingConsultation] = useState(false);
    const [modalFormOpen, setModalFormOpen] = useState(false);
    const [consultationIdToDelete, setConsultationIdToDelete] = useState(-1);
    const [consultation, setConsultation] = useState<Consultation>(defaultConsultationValue);
    const [consultationData, setConsultationData] = useState<any>(null);
    const [consultations, setConsults] = useState<any[]>([]);
    const tableHeaders = [{ name: 'Nom' }, { name: 'Durée' }, { name: 'Remarque' }, { name: 'Action' }];

    const onEdit = (data: any) => {
        let cloneData = structuredClone(data);

        const optimizedAttendees = uniqByKeyValues({
            items: Array.from(cloneData?.consultationAttendees ?? [], (item: any) => ({
                id: item?.attendeeUser?.id,
                firstName: item?.attendeeUser?.firstName,
                lastName: item?.attendeeUser?.lastName,
                name: fullName(item?.attendeeUser?.firstName, item?.attendeeUser?.lastName),
                specialityId: item?.attendeeUser?.specialityType?.id,
            })),
            key: 'id',
        });
        console.log(optimizedAttendees,"optimizedAttendeesss");
        let consultationData = {
            id: cloneData?.id,
            name: cloneData?.name,
            duration: cloneData?.duration,
            consultationTypeId: cloneData?.consultationType?.id,
            remark: cloneData.remark,
            roomId: cloneData.room?.id ?? 0,
            customLocationId: cloneData.customLocation?.id ?? 0,
            attendees: optimizedAttendees ?? [],
            specialityTypeId: cloneData.specialityType?.id
        };
        setConsultationData({
            remark: cloneData.remark,
            consultationTypeId: cloneData?.consultationType?.id,
            location: cloneData.customLocation,
            data: cloneData,
        });

        setConsultation(consultationData);
        setModalFormOpen(true);
        console.log(consultation,"cloneData.specialityType?.id")
    };

    const fetchConsultations = (query?: string) => {
        setLoadingConsultation(true);
        getConsultations()
            .then((response) => {
                const data = response?.data?.data?.items;
                if (data) {
                    setConsults(sortByIds(data));
                }
                setLoadingConsultation(false);
            })
            .catch((e) => {
                setLoadingConsultation(false);
                toast.error('Une erreur sur la récupération des consultations');
            });
    };

    useEffect(() => {
        fetchConsultations();
    }, [updateList]);

    const performDeleteConsultation = () => {
        return new Promise((res, rej) => {
            if (consultationIdToDelete > 0) {
                deleteConsultations(consultationIdToDelete)
                    .then((response) => {
                        toast.success("Suppression de la consultation réussie !");
                        setUpdateList((prev: any) => !prev);
                        setConsultationIdToDelete(0);
                        setModalConfirmVisible((prev: any) => !prev);
                        setLoading(false);
                        res(response);
                    })
                    .catch((e) => {
                        rej(e);
                        setLoading(false);
                        toast.error("Erreur de suppression de la consultation!");
                        setModalConfirmVisible((prev: any) => !prev);
                    });
            }
        });
    };
    const handleClick = () => {
        setConsultationData(null);
        setConsultation(defaultConsultationValue);
        setModalFormOpen(true);
    };

    return (
        <Box sx={styles.container}>
            <Box sx={styles.header}>
                <CustomText isBreefSerif isTitle color={baseColors.green.primary} style={{ marginLeft: 20 }}>
                    Liste des consultations
                </CustomText>

                <CustomLoadingButton disabled={loading} label="Ajouter une nouvelle consultation" handleClick={handleClick} />
            </Box>

            {loadingConsultation ? (
                <SubMenusLoader />
            ) : consultations?.length === 0 ? (
                <div style={{ paddingBottom: 150 }}>
                    <CustomEmptyData />
                </div>
            ) : (
                <TableContainer
                    component={Paper}
                    style={{ overflow: 'auto', maxHeight: baseScreenHeight.tableContainerHeight }}
                >
                    <Table stickyHeader sx={{ minWidth: 650 }} aria-label="role table">
                        <TableHead>
                            <TableRow>
                                {tableHeaders.map((header, index) => (
                                    <TableCell key={index} sx={{ color: baseColors.green.primary }}>
                                        {tableCellHeadText(header.name)}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {consultations.map((row, index) => (
                                <>
                                    <TableRow
                                        key={index}
                                        sx={{
                                            '&:last-child td, &:last-child th': { border: 0 },
                                            '& .MuiTableCell-root': {
                                                padding: '5px 15px 5px 15px',
                                            },
                                        }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {tableCellText(row.name)}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {tableCellText(row.duration)}
                                        </TableCell>
                                        <TableCell>{tableCellText(row.remark)}</TableCell>
                                        <TableCell>
                                            <IconButton onClick={() => onEdit(row)}>
                                                <EditOutlinedIcon
                                                    sx={{
                                                        fontSize: 'medium',
                                                        color: baseColors.green.primary,
                                                        cursor: 'pointer',
                                                    }}
                                                />
                                            </IconButton>
                                            {!row?.hasLinkedRecord && (
                                                <IconButton
                                                    onClick={() => {
                                                        setConsultationIdToDelete(+row.id);
                                                        setModalConfirmVisible(true);
                                                    }}
                                                >
                                                    <DeleteOutlineOutlinedIcon sx={styles.delete} />
                                                </IconButton>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                </>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            <ConsultationForm
                forAdmin
                consultationData={consultationData}
                consultData={consultation}
                setUpdateList={setUpdateList}
                modalToggle={setModalFormOpen}
                modalOpen={modalFormOpen}
            />

            <CustomConfirm
                defaultLoading={loading}
                modalVisible={modalConfirmVisible}
                updateModalVisible={setModalConfirmVisible}
                message="Voulez-vous vraiment supprimer cette consultation ? "
                callBackconfirm={performDeleteConsultation}
            />
        </Box>
    );
};
