import { ChangeEvent, FC } from 'react';
import { Box, Typography } from '@mui/material';
import { baseColors, baseFontFamily, baseFontSize, baseFontWeight } from 'src/utils/constants/baseStyles';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { toast } from 'react-toastify';
import { handleCheckIfValidTime } from '../FilterLogic';

type DurationIntervalType = {
    label: string;
    setTimeValue: Function;
    isEndTime?: boolean;
    startTime?: number;
    hasError?: any;
    setHasError?: any;
    value: number;
};

const DurationInterval: FC<DurationIntervalType> = ({
    hasError,
    setHasError,
    label,
    startTime,
    setTimeValue,
    isEndTime,
    value,
}) => {
    const handleChange = (newValue: any) => {
        const timeValue = newValue.target.value;
        const convert = Number(timeValue);

        if (isNaN(timeValue)) {
            toast.error("Utiliser des chiffres s'il vous plait");
        } else {
            setTimeValue(convert ?? 0);
            if (isEndTime && startTime) {
                setHasError(handleCheckIfValidTime({ startTime, endTime: convert }));
            }
        }
    };

    return (
        <Box
            sx={{
                borderRadius: 2,
                backgroundColor: baseColors.green.pastel,
                padding: '10px 7px 10px 7px',
                marginBottom: 2,
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-evenly',
            }}
            style={{ width: 140 }}
        >
            <Typography
                style={{
                    color: baseColors.white,
                    textAlign: 'center',
                    fontSize: baseFontSize.sm,
                    fontFamily: baseFontFamily.OpenSansSemiBold,
                }}
            >
                {label} :
            </Typography>
            <TextField
                placeholder={`${isEndTime ? '90' : '15'}`}
                id="filled-start-adornment"
                sx={{ margin: '5px', width: 80 }}
                variant="standard"
                error={hasError}
                value={value === 0 ? '' : value}
                InputProps={{
                    style: { color: baseColors.white, fontWeight: baseFontWeight.semiBold },
                    endAdornment: (
                        <InputAdornment position="start">
                            <p style={{ color: baseColors.white }}>min</p>
                        </InputAdornment>
                    ),
                }}
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
            />
        </Box>
    );
};

export default DurationInterval;
