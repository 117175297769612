import { RootState } from 'src/stores/store';
import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import PermissionService from 'src/services/Permissions';
import { PermissionActionType } from './types';

export const retrievePermissions = (filters?: any, setLoading?: Function): ThunkAction<void, RootState, unknown, AnyAction> => async dispatch => {
    setLoading?.(true)
    try {
        const data = await PermissionService.getPermissions(filters);
        dispatch({
            type: PermissionActionType.LIST_PERMISSIONS,
            payload: data
        });
        setLoading?.(false)
    } catch (error) {
        console.log('Retrieve Permissions Error', error);
        setLoading?.(false)
    }
}