import { Dispatch, FC, SetStateAction } from 'react';
import { Box } from '@mui/material';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import { OptimizedItem } from 'src/interfaces/interfaces';
import { sxStyles } from './styles';

type IProps = {
    payload: any;
    toggleModal: any;
    setGoalNames: Dispatch<SetStateAction<OptimizedItem[]>>;
    goals: OptimizedItem[];
};

export const RelatedGoalModalFooter: FC<IProps> = (props) => {
    const { goals, setGoalNames, payload, toggleModal } = props;

    //const disabled = payload.goalsIds?.length === 0;
    const handleClose = () => {
        toggleModal();
    };

    const handleClick = () => {
        const goalsItems = goals?.filter((goal) => payload.goalsIds.includes(goal.id));
        setGoalNames(goalsItems);
        toggleModal();
    };

    return (
        <Box style={sxStyles.footer}>
            <CustomLoadingButton
                outlined
                width="100%"
                label="Annuler"
                handleClick={handleClose}
                style={{ flex: 0.95 / 2 }}
            />
            <CustomLoadingButton
                //disabled={disabled}
                width="100%"
                label="Enregistrer"
                handleClick={handleClick}
                style={{ flex: 0.95 / 2 }}
            />
        </Box>
    );
};
