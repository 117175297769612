import { Box } from '@mui/system';
import React, { FC } from 'react';

import ContentLoader from 'react-content-loader';
import { baseColors } from 'src/utils/constants/baseStyles';

interface CustomLoaderProps {
    isPlanning?: boolean;
    loadingNumber?: number;
    IsclassicList?: boolean;
}

const CustomLoader: FC<CustomLoaderProps> = (props) => {
    const { isPlanning, loadingNumber, IsclassicList } = props;

    const planningLoad = (key: number) => (
        <ContentLoader
            key={key}
            backgroundColor={baseColors.green.light}
            foregroundColor={baseColors.green.secondary}
            viewBox="0 0 350 40"
        >
            <rect x="0" y="10" rx="4" ry="4" width="65" height="40" />
            <rect x="85" y="10" rx="4" ry="4" width="65" height="50" />
            <rect x="170" y="10" rx="4" ry="4" width="80" height="50" />
         <rect x="270" y="10" rx="4" ry="4" width="80" height="50" />
        </ContentLoader>
    );

    const itemLoad = (key: number) => (
        <ContentLoader
            key={key}
            backgroundColor={baseColors.green.light}
            foregroundColor={baseColors.green.secondary}
            viewBox="0 0 350 35"
        >
            <rect x="0" y="0" rx="25" ry="25" width="25" height="25" />
            <rect x="30" y="2" rx="4" ry="4" width="300" height="8" />
            <rect x="30" y="16" rx="3" ry="3" width="250" height="8" />
        </ContentLoader>
    );

    const ListLoad = (key: number) => (
        <ContentLoader
            key={key}
            backgroundColor={baseColors.green.light}
            foregroundColor={baseColors.green.secondary}
            viewBox="0 0 350 35"
        >
            <rect x="0" y="16" rx="1" ry="1" width="450" height="45" />
        </ContentLoader>
    );

    const listLoader = Array.from(Array(loadingNumber ?? 3).keys());
    const itemToRender = (key: number) =>
        isPlanning ? planningLoad(key) : IsclassicList ? ListLoad(key) : itemLoad(key);

    return <Box>{listLoader.map((item: any, i: number) => itemToRender(i))}</Box>;
};

export default CustomLoader;
