import { FC, useContext, useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import { Box } from '@mui/system';
import { fullName } from 'src/utils/utils';
import { baseColors, baseFontFamily, baseFontSize } from 'src/utils/constants/baseStyles';
import CustomCircleLoader from 'src/components/CustomLoader/CustomCircleLoader';
import IntervenantList from '../PlanningFilters/IntervenantList';
import { EventPlanningViewType } from 'src/interfaces/interfaces';
import { CustomText } from 'src/components/Text/CustomText';
import EventService from 'src/services/Calendar/events.service';
import { UserContext } from 'src/providers/UserProvider';
import useProfessionals from 'src/hooks/useProfessionals';
import useCheckUserRole from 'src/hooks/useCheckUserRole';

interface UserAgendaSharersProps {
    setCaregiverId?: any;
    label?: string;
    width?: number;
    setEventPayload?: any;
    setChoosedPatient?: any;
    defaultList?: any;
    precheckedPatient?: number;
    isAgenda?: boolean;
    viewType?: EventPlanningViewType['viewType'];
    setSelectedIntervenants?: any;
    selectedIntervenants?: any;
    isIntervenant?: boolean;
    isSharedAgenda?: boolean;
    setObservation?: Function;
    marginLeft?: string;
    idsuffixselect?: string;
    isCordinator?: boolean;
    isForEvent?: boolean;
    isAgendaFilter?: boolean;
}

const UserAgendaSharers: FC<UserAgendaSharersProps> = (props) => {
    const { setSelectedIntervenants, selectedIntervenants } = props;

    const { userInfos } = useContext(UserContext);
    const { roles } = useCheckUserRole();
    const { professionals, fetchingProfessionals } = useProfessionals();

    const [loading, setLoading] = useState<boolean>(false);
    const [openIntervenantModal, setOpenIntervenantModal] = useState<boolean>(false);
    const [users, setUsers] = useState([]);

    const handleFetchUserSharedAgenda = () => {
        setLoading(true);
        if (userInfos?.id > 0) {
            EventService.getSharedAgendaReceivers(userInfos.id)
                .then((response) => {
                    setLoading(false);
                    const res = response?.data?.data;
                    const optimized: any = Array.from(res ?? [], (item: any) => ({
                        id: item?.id,
                        name: fullName(item?.lastName, item?.firstName),
                    }));
                    setUsers(optimized);
                })
                .catch(() => setLoading(false));
        }
    };

    useEffect(() => {
        handleFetchUserSharedAgenda();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInfos?.id]);

    const toggleIntervenantModal = () => setOpenIntervenantModal(!openIntervenantModal);
    const isEmpty = roles?.isCoordinator ? !professionals?.length : !users?.length;
    const data = roles?.isCoordinator ? professionals : users;
    const isLoading = roles?.isCoordinator ? fetchingProfessionals : loading;

    if (!isLoading && isEmpty) {
        return null;
    }

    return (
        <Stack spacing={2} sx={{ width: 150 }}>
            <IntervenantList
                toggleModal={toggleIntervenantModal}
                modalOpen={openIntervenantModal}
                intervenants={data}
                selectedIntervenants={selectedIntervenants}
                setSelectedIntervenants={setSelectedIntervenants}
                handleFetchCaregivers={handleFetchUserSharedAgenda}
                loading={isLoading}
            />

            {isLoading ? (
                <CustomCircleLoader height={25} width={25} />
            ) : (
                <Box
                    sx={{
                        padding: 1,
                        borderRadius: 2,
                        cursor: 'pointer',
                        border: `1px solid ${baseColors.green.primary}`,
                    }}
                    onClick={toggleIntervenantModal}
                    id="view-agenda"
                >
                    <CustomText
                        style={{
                            fontSize: baseFontSize.sm,
                            color: baseColors.green.primary,
                            textAlign: 'center',
                            fontFamily: baseFontFamily.OpenSansSemiBold,
                        }}
                    >
                        Voir l'agenda de
                    </CustomText>
                </Box>
            )}
        </Stack>
    );
};

export default UserAgendaSharers;
