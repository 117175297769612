import { Grid } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { CustomBadgeKV } from 'src/components/Badge/CustomBadgeKV';
import { CustomText } from 'src/components/Text/CustomText';
import { baseColors, baseFontFamily, baseFontSize } from 'src/utils/constants/baseStyles';
import { formatDate, getAge } from 'src/utils/helpers/helpers';
import { ProfileDetails } from './ProfileDetails';
import { InfoItem } from './InfoItem';
import { PatientContext } from 'src/providers/PatientProvider';
import PatientDoctor from './PatientDoctor/PatientDoctor';
import { isNull } from 'src/utils/utils';

interface PatientInfosProps {
    antecedantList: any;
    assistant: any;
    professional: any;
    patientId: number;
    onContactClick: any;
}

export const PatientInfos: FC<PatientInfosProps> = (props) => {
    const { assistant } = props;
    const [open, setOpen] = useState(false);
    const { selectedPatient } = useContext(PatientContext);
    const [professionals, setProfessionals] = useState<Array<any>>([]);

    useEffect(() => {
        const data = selectedPatient?.professionals ?? [];
        setProfessionals(data);
    }, [selectedPatient?.id, selectedPatient?.professionals]);

    return (
        <Grid item>
            <Grid container direction="row" justifyContent="space-between">
                <Grid item flex={2}>
                    <CustomText fontSize={baseFontSize.md} fontFamily={baseFontFamily.BreeSerif}>
                        Informations du patient
                    </CustomText>

                    <Grid marginTop={1} container direction="column">
                        <InfoItem label="Civilité" value={selectedPatient?.civility === 0 ? 'Mme' : 'M.'} />
                        <InfoItem
                            label="Nom de naissance"
                            value={
                                selectedPatient?.firstName === null || selectedPatient?.firstName === undefined
                                    ? 'Non renseigné'
                                    : selectedPatient?.firstName
                            }
                        />
                        <InfoItem
                            label="Prénom(s)"
                            value={
                                selectedPatient?.lastName === null || selectedPatient?.lastName === undefined
                                    ? 'Non renseigné'
                                    : selectedPatient?.lastName
                            }
                        />
                        <InfoItem
                            label="Age"
                            value={
                                selectedPatient?.birthDate === null || selectedPatient?.birthDate === undefined
                                    ? 'Non renseigné'
                                    : `(${getAge(selectedPatient?.birthDate, new Date())} ans ) ${formatDate(
                                          selectedPatient?.birthDate,
                                      )}`
                            }
                        />
                        <InfoItem
                            label="Sexe"
                            value={
                                selectedPatient?.sex === null || selectedPatient?.sex === undefined
                                    ? 'Non reseigné'
                                    : selectedPatient?.sex === 0
                                    ? 'Féminin'
                                    : 'Masculin'
                            }
                        />

                        <InfoItem
                            label="Email"
                            value={
                                selectedPatient?.email === null || selectedPatient?.email === undefined
                                    ? 'None renseigné'
                                    : selectedPatient?.email
                            }
                        />
                        <InfoItem
                            label="Téléphone"
                            value={
                                selectedPatient?.phoneNumber === null || selectedPatient?.phoneNumber === undefined
                                    ? 'Non renseigné'
                                    : selectedPatient?.phoneNumber
                            }
                        />
                        <CustomText
                            style={{
                                fontSize: 13,
                                color: baseColors.green.primary,
                                cursor: 'pointer',
                            }}
                        >
                            <p style={{ textDecoration: 'underline' }} onClick={() => setOpen(true)}>
                                Afficher plus
                            </p>
                        </CustomText>

                        <ProfileDetails
                            open={open}
                            setOpen={setOpen}
                            currentPatient={selectedPatient}
                            professionals={professionals}
                            assistants={assistant}
                        />
                    </Grid>
                </Grid>

                <Grid flex={2} item>
                    <CustomText fontSize={baseFontSize.md} fontFamily={baseFontFamily.BreeSerif}>
                        Dernières constantes
                    </CustomText>

                    <Grid container marginTop={1} direction="column" marginLeft={2} spacing={1}>
                        <CustomBadgeKV
                            label="Poids (Kg)"
                            value={
                                selectedPatient?.weight !== '' && !isNull(selectedPatient?.weight)
                                    ? selectedPatient?.weight
                                    : 'Aucun'
                            }
                        />
                        <CustomBadgeKV
                            label="Taille (Cm)"
                            value={
                                selectedPatient?.height !== '' && !isNull(selectedPatient?.height)
                                    ? selectedPatient?.height
                                    : 'Aucune'
                            }
                        />
                        <CustomBadgeKV
                            label="TA"
                            value={
                                selectedPatient?.bloodPressure !== '' && !isNull(selectedPatient?.bloodPressure)
                                    ? selectedPatient?.bloodPressure
                                    : 'Aucun'
                            }
                        />
                    </Grid>
                </Grid>

                <Grid item flex={1.4}>
                    <CustomText fontSize={baseFontSize.md} fontFamily={baseFontFamily.BreeSerif}>
                        Médecin traitant
                    </CustomText>

                    <PatientDoctor professionals={professionals} />
                </Grid>
            </Grid>
        </Grid>
    );
};
