import { makeStyles } from '@mui/styles';
import { baseColors } from 'src/utils/constants/baseStyles';

const useStyles = makeStyles({
    container: {
        padding: 20,
    },
    sideMenu: {
        flex: 1,
        background: baseColors.green.light,
        padding: 15,
        maxWidth: '25%',
        height: '60vh',
        borderRadius: 8,
    },
    flexCenter: { display: 'flex', justifyContent: 'center' },
    scrollContainer: {
        overflow: 'auto',
        position: 'relative',
        overflowY: 'scroll',
    },
    row: { display: 'flex', flexDirection: 'row' },
    col: { display: 'flex', flexDirection: 'column' },
});

export const sxStyles: any = {
    scrollContainer: {
        overflow: 'auto',
        position: 'relative',
        overflowY: 'scroll',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        paddingLeft: 20,
        paddingRight: 20,
        overflowY: 'scroll',
        height: '100%',
    },
};

export default useStyles;
