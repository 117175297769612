import { Grid } from '@mui/material';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import ProgramGoals from './ProgramGoals';
import ProgramQuizz from './ProgramQuizz';

const ProgramObjectifRow: FC = (props) => {
    const params: any = useParams();
    const patientId = +params?.id;

    return (
        <Grid container direction="row" justifyContent={'space-between'} style={{ paddingLeft: 20, marginTop: 20 }}>
            <ProgramGoals patientId={patientId} />
            <ProgramQuizz patientId={patientId} />
        </Grid>
    );
};

export default ProgramObjectifRow;
