import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    container: {
        padding: 20,
    },

    flexCenter: { display: 'flex', justifyContent: 'center', marginVertical: 80 },
    row: { display: 'flex', flexDirection: 'row' },
    col: { display: 'flex', flexDirection: 'column' },
    intervenant: {
        marginLeft: -10,
    },
});

export const sxStyles : any = {
    container : {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 10,
    },
    scrollContainer: {
        overflow: 'hidden',
        overflowY: 'scroll',
        padding: 2,
    }
}

export default useStyles;
