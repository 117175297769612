import * as React from 'react';

function CircleIcon(props: any) {
    return (
        <svg width="10px" height="10px" viewBox="0 0 72 72" id="emoji" xmlns="http://www.w3.org/2000/svg">
            <g id="color">
                <circle cx="36" cy="36.0001" r="28" fill="#d22f27" />
            </g>
            <g id="line">
                <circle
                    cx="36"
                    cy="36.0001"
                    r="28"
                    fill="none"
                    stroke="#000000"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
            </g>
        </svg>
    );
}

export default CircleIcon;
