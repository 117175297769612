import UserService from 'src/services/User/user.service';
import { RETRIEVE_CONNECTED_USER_INFOS } from './types';

export const retrieveConnectedUserInfos =
    (setLoadingInfos: (val: boolean) => void, setUserInfos: any) =>
    async (dispatch: (arg0: { type: string; payload: any }) => void) => {
        setLoadingInfos(true);
        try {
            const res = await UserService.connectedUser();

            dispatch({
                type: RETRIEVE_CONNECTED_USER_INFOS,
                payload: res.data,
            });

            if (res.data) {
                setLoadingInfos(false);
                setUserInfos(res.data.data);
            }
        } catch (err) {
            console.log(err);
            setLoadingInfos(false);
        }
    };
