import { Box, Grid } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import CustomCircleLoader from 'src/components/CustomLoader/CustomCircleLoader';
import { Spacer } from 'src/components/Spacer/Spacer';
import { CustomText } from 'src/components/Text/CustomText';
import UsersSelectList from '../../Planning/UsersSelectList';
import { usePatientHooks } from 'src/hooks/usePatientHooks';
import { useFetchPatientsInEventRQHooks } from 'src/hooks/ReactQueryHooks/useEvenRQtHooks';

interface EventPatientProps {
    setChoosedPatient?: any;
    eventData?: any;
    isPatientAgenda?: boolean;
    patientId?: number;
}

const EventPatient: FC<EventPatientProps> = (props) => {
    const { setChoosedPatient, eventData, isPatientAgenda, patientId } = props;
    const { data: patientData, isFetching:isLoading } = useFetchPatientsInEventRQHooks();
    //const [patients, setPatients] = useState<any[]>([]);
    const { handleOptimizePatientsData } = usePatientHooks({});
    const [precheckedPatient, setPrecheckedPatient] = useState(0);

    /*useEffect(() => {
        if (patientData?.length > 0) {
            setPatients(handleOptimizePatientsData(patientData) ?? []);
            console.log(handleOptimizePatientsData(patientData),"patientsss")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patientData]);*/

    useEffect(() => {
        const value = isPatientAgenda ? patientId : eventData?.patient?.attendeeUser?.id ?? 0;
        setPrecheckedPatient(value);
        setChoosedPatient(value);
    }, [isPatientAgenda, patientId, eventData]);
    
    return (
        <>
            <Grid container direction="row" alignItems="center" justifyContent="space-between">
                <Grid item>
                    <CustomText>Patient :</CustomText>
                </Grid>

                <Box style={{ width: 350 }}>
                    {isLoading ? (
                        <CustomCircleLoader height={25} width={25} />
                    ) : (
                        <div style={{ marginLeft: 10 }}>
                            <UsersSelectList
                                isForEvent
                                isPatientItems
                                loadingPatients={isLoading}
                                disabled={isPatientAgenda}
                                label="Renseigner le nom de votre patient..."
                                defaultList={handleOptimizePatientsData(patientData)}
                                precheckedPatient={precheckedPatient}
                                setChoosedPatient={setChoosedPatient}
                            />
                        </div>
                    )}
                </Box>
            </Grid>

            <Spacer />
        </>
    );
};

export default EventPatient;
