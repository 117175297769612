import { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { baseColors, baseFontFamily, baseFontSize, baseScreenHeight } from 'src/utils/constants/baseStyles';
import RiskIcon from '../Feature/RiskIcon';
import { DetailAuthorization } from './AuthorizationPanel';
import { SettingsModal } from './SettingsModal';
import { ViewModal } from './ViewModal';
import { tableCellText } from '../SecurityManagement/SecurityManagement';
import { CustomText } from 'src/components/Text/CustomText';

interface AuthorizationTableHeader {
    name: string;
}

interface AuthorizationDataRow {
    id: number;
    name: string;
    code: string;
    category: string;
    risk: number;
    roles: any[];
    action: any;
}

interface AuthorizationTableProps {
    tableHeaders: AuthorizationTableHeader[];
    dataRows: AuthorizationDataRow[];
}

interface AuthorizationActionButtonsProps {
    view?: boolean;
    edit?: boolean;
    delete?: boolean;
    settings?: boolean;
    data: DetailAuthorization;
}

export const AuthorizationActionButtons = (
    props: AuthorizationActionButtonsProps = {
        view: true,
        edit: false,
        delete: false,
        settings: true,
        data: {
            featureName: '',
            category: '',
            code: '',
            risk: '',
            userPermission: '',
            patientPermission: '',
            doctorPermission: '',
            coordinatorPermission: '',
            adminPermission: '',
        },
    },
) => {
    const [openSettings, setOpenSettings] = useState(false);
    const [openView, setOpenView] = useState(false);
    const data = props.data;

    const handleSettingsClick = () => setOpenSettings(true);
    const handleViewClick = () => setOpenView(true);

    const settingsIcon = props.settings ? (
        <SettingsOutlinedIcon
            onClick={() => {
                handleSettingsClick();
            }}
            cursor="pointer"
            sx={{ fontSize: 'medium', color: baseColors.green.primary, marginRight: '10px' }}
        />
    ) : (
        ''
    );

    const editIcon = props.edit ? (
        <EditOutlinedIcon
            cursor="pointer"
            sx={{ fontSize: 'medium', color: baseColors.green.primary, marginRight: '10px' }}
        />
    ) : (
        ''
    );

    const visibilityIcon = props.view ? (
        <VisibilityOutlinedIcon
            onClick={() => {
                handleViewClick();
            }}
            cursor="pointer"
            sx={{ fontSize: 'medium', color: baseColors.orange.primary, marginRight: '10px' }}
        />
    ) : (
        ''
    );
    const deleteIcon = props.delete ? (
        <DeleteOutlineOutlinedIcon cursor="pointer" sx={{ fontSize: 'medium', color: 'red', marginRight: '10px' }} />
    ) : (
        ''
    );

    return (
        <>
            {settingsIcon}
            {editIcon}
            {visibilityIcon}
            {deleteIcon}
            <SettingsModal data={data} open={openSettings} />
            <ViewModal data={data} open={openView} />
        </>
    );
};

const AuthorizationTable = (props: AuthorizationTableProps = { tableHeaders: [], dataRows: [] }) => {
    const tableHeaders = props.tableHeaders;
    const dataRows = props.dataRows;

    const parsePrivileges = (roles: any[]) => {
        return roles.map((role, index) => {
            return (
                <TableCell key={index} component="th" scope="row">
                    {tableCellText(role.privilegeName ? role.privilegeName : '-')}
                </TableCell>
            );
        });
    };

    return (
        <TableContainer
            component={Paper}
            style={{
                overflow: 'auto',
                maxHeight: baseScreenHeight.securitySubMenusHeight,
                width: 'calc(100vw - 420px)',
            }}
        >
            <Table stickyHeader sx={{ minWidth: 650 }} aria-label="role table">
                <TableHead>
                    <TableRow>
                        {tableHeaders.map((header, index) => (
                            <TableCell key={index} sx={{ color: baseColors.green.primary }}>
                                <CustomText
                                    color={baseColors.green.primary}
                                    fontFamily={baseFontFamily.OpenSansBold}
                                    fontSize={baseFontSize.mini}
                                >
                                    {header.name}
                                </CustomText>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>

                <TableBody>
                    {dataRows.map((row, index) => (
                        <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" scope="row">
                                {tableCellText(row.name)}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                <RiskIcon level={row.risk} />
                            </TableCell>
                            {parsePrivileges(row.roles)}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default AuthorizationTable;
