import axios from '../AxiosInstance';
import { Activation, Users, Contact, ProfessionalCpts,FeedBack } from '../../interfaces/interfaces';

const connectedUser = () => axios.get(`api/users/me`);

const getDoctorUsers = (searchKey?: string) => {
    let additionalUrl: string = '';
    let pagination: string = '';

    if (searchKey) {
        additionalUrl += `&keywords=${searchKey}`;
    } else if (!searchKey) {
        pagination += `?paginated=false&`;
    }

    return axios.get(`api/users${pagination}?role=doctor${additionalUrl}`);
};

const getUsersList = async (filters?: any) => {
    let additionalUrl: string = '';
    if (filters) {
        additionalUrl += `&excludedRoles[]=${filters}`;
        try {
            const response = await axios.get('api/users?paginated=false' + additionalUrl);
            const result = JSON.parse(response.request.response);
            return result.data;
        } catch (reason) {
            console.log('Get User List Error', reason);
        }
    } else {
        try {
            const response = await axios.get('api/users?paginated=false');
            const result = JSON.parse(response.request.response);
            return result.data;
        } catch (reason) {
            console.log('Get User List Error', reason);
        }
    }
};

const getUsersLists = async (filters?: any) => {
    let additionalUrl: string = '';
    if (filters) {
        additionalUrl += `&excludedRoles[]=${filters}&excludedCategory=ASSISTANT`;
        try {
            const response = await axios.get('api/users?paginated=false' + additionalUrl);
            const result = JSON.parse(response.request.response);
            return result.data;
        } catch (reason) {
            console.log('Get User List Error', reason);
        }
    } else {
        try {
            const response = await axios.get('api/users?paginated=false&excludedCategory=ASSISTANT');
            const result = JSON.parse(response.request.response);
            return result.data;
        } catch (reason) {
            console.log('Get User List Error', reason);
        }
    }
};

export const updateUser = (userId: number, payload: Users) => axios.put(`api/users/${userId}`, { ...payload });

export const postNewUser = (data: Users) => {
    return axios.post('api/users', { ...data });
};

export const getRolesList = async () => {
    const response = await axios.get('api/roles');
    const result = JSON.parse(response.request.response);
    return result.data;
};

export const activationUser = (dataId: number, data: Activation) => {
    return axios.put(`api/users/activation/${dataId}`, data);
};

export const updateETP = (dataId: number, data: any) => {
    return axios.put(`api/users/${dataId}`, data);
};

export const sendMailtoUser = (data: Contact) => {
    return axios.post(`api/sendMailToUser`, { ...data });
};

export const postNewProfessional = (data: ProfessionalCpts) => {
    return axios.post('api/users/register', { ...data });
};

export const activateUserByToken = (token: string | null) => {
    return axios.post(`api/users/activation`, { token: token });
};

export const sendFeedBack = (data: FeedBack) => {
    return axios.post(`api/feedbacks`, { ...data });
};

const UserService = {
    connectedUser,
    updateETP,
    getDoctorUsers,
    getUsersList,
    updateUser,
    activateUserByToken,
    getUsersLists,
    sendFeedBack
};

export default UserService;
