import { makeStyles } from '@mui/styles';
import { baseColors } from '../../../../utils/constants/baseStyles';

const useStyles = makeStyles({
    container: {
        backgroundColor: baseColors.green.light,
        borderRadius: 10,
        width: '46%',
        height: 300,
        padding: 10,
        overflow: 'hidden',
        overflowY: 'scroll',
    },
    content:{
        padding: '0 10px 0 10px',
    }
});

export default useStyles;
