import React, { FC, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { baseColors } from 'src/utils/constants/baseStyles';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import moment from 'moment';
interface CustomTimePickerProps {
    value: Date | null;
    setValue: (val: any) => void;
    type?: 'start' | 'end';
    handleChange?: any;
    idtime?: string;
    validator?:any;
    inversed?:boolean;
}

const CustomTextField = styled(TextField)({});


const useStyles = makeStyles({
    input: {
        marginTop: 10,
        width: '100%',

        '& label.Mui-focused': {
            color: baseColors.green.primary,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: baseColors.green.primary,
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: baseColors.grey,
                borderRadius: 10,
            },
            '&:hover fieldset': {
                borderColor: baseColors.black,
            },
            '&.Mui-focused fieldset': {
                borderColor: baseColors.green.primary,
            },
        },
    },
    inputInversed: {
        marginTop: 10,
        width: '100%',
        '& label.Mui-focused': {
            color: baseColors.green.primary,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: baseColors.green.primary,
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: baseColors.green.primary,
                borderRadius: 10,
            },
            '&:hover fieldset': {
                borderColor: baseColors.black,
            },
            '&.Mui-focused fieldset': {
                borderColor: baseColors.grey,
            },
        },
    },
    inputInvalid: {
        marginTop: 10,
        width: '100%',
        '& label.Mui-focused': {
            color: baseColors.orange.primary,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: baseColors.orange.primary,
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: baseColors.orange.primary,
                borderRadius: 10,
            },
            '&:hover fieldset': {
                borderColor: baseColors.black,
            },
            '&.Mui-focused fieldset': {
                borderColor: baseColors.orange.primary,
            },
        },
    },
});

const CustomTimePicker: FC<CustomTimePickerProps> = (props) => {
    const { value, setValue, handleChange, type, validator, inversed} = props;
    const [internValue, setInternValue] = useState<any>('');
    const classes = useStyles();
    const [isValidTime, setIsValidTime] = useState(false);

    useEffect(() => {
        const momentDate = moment(value);
        if (momentDate.isValid()) {
            setInternValue(momentDate.format('HH:mm'));
        }
    }, [value]);

    useEffect(() => {
        if (!validator) {
            setIsValidTime(true);
        } else {
            setIsValidTime(false);
        }
    }, [validator, value]);

    return (
        <CustomTextField
            type="time"
            defaultValue={internValue}
            value={internValue}
            inputProps={{ style: { fontWeight: 'bold' } }}
            className={isValidTime ? (inversed ? classes.inputInversed : classes.input) : classes.inputInvalid}
            onChange={(newValue: any) => {
                const timeValue = newValue.target.value;
                const formattedTime = moment(timeValue, 'HH:mm').toDate();
                setInternValue(timeValue);
                setValue?.(formattedTime);
                handleChange?.(type, formattedTime);
            }}
        />
    );
};

export default CustomTimePicker;
