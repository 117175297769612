import { Dispatch, FC, SetStateAction, useState } from 'react';
import { baseColors } from 'src/utils/constants/baseStyles';
import useStyles from './styles';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { Box } from '@mui/system';
import { useHistory } from 'react-router';
import IconKitMedical from 'src/components/CustomIcon/IconKitMedical';
import IconCheckList from 'src/components/CustomIcon/IconCheckList';
import IconDiabeto from 'src/components/CustomIcon/IconDiabeto';
import { ProgramItem } from 'src/interfaces/interfaces';
import { OpenSansBoldText } from '../Text/OpenSansBoldText';
import { OpenSansSemiBold } from '../Text/OpenSansSemiBold';
import { Delete } from '@mui/icons-material';
import { CustomConfirm } from '../CustomConfirm/CustomConfirm';
import ProgramService from 'src/services/Program/program.service';
import { toast } from 'react-toastify';

const programIcon = (program: ProgramItem) => {
    const programLevel = program?.level?.id;
    switch (programLevel) {
        case 1:
            return <IconCheckList height={35} width={35} />;

        case 2:
            return <IconKitMedical height={35} width={35} />;
        default:
            return <IconDiabeto height={35} width={35} />;
    }
};

interface ProgramItemCardProps {
    item: ProgramItem;
    isGreen?: boolean;
    setRefetchList: Dispatch<SetStateAction<boolean>>
}

const ProgramItemCard: FC<ProgramItemCardProps> = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const { item, isGreen, setRefetchList } = props;
    const backgroundColor = !isGreen ? baseColors.orange.light : baseColors.green.light;
    const color = !isGreen ? baseColors.orange.primary : baseColors.green.primary;
    const iconStyle = { backgroundColor: color, padding: '7px', borderRadius: 10 };
    const [modalConfirmationVisible, setModalConfirmationVisible] = useState(false);

    const handleSynthese = () => {
        if (item?.id) {
            history.push(`/programmes/${item.id}/synthese`);
        }
    };

    const handleManage = () => {
        if (item?.id) {
            history.push(`/programmes/${item.id}/manage/activity`);
        }
    };

    const handleDeleteProgram = () => {
        if (item?.id) {
            ProgramService.deleteProgram(item?.id).then(response => {
                toast.success("Programme supprimé")
                setModalConfirmationVisible(false);
                setRefetchList(prev => !prev);
            }).catch(error => {
                console.error(error);
                toast.error("Une erreur de suppression se produit")
            })
        }
    }

    return (
        <Box
            style={{ backgroundColor: backgroundColor }}
            className={classes.container}
            sx={{ alignItems: 'center' }}
            flexDirection={{ xs: 'column', lg: 'row' }}
        >
            <Box className={classes.left}>
                <Box sx={iconStyle}>{programIcon(item)}</Box>
                <OpenSansSemiBold style={{ marginLeft: 20 }}> {item?.title}</OpenSansSemiBold>
            </Box>

            <Stack spacing={5} direction={{ xs: 'column', sm: 'row' }}>
                <OpenSansBoldText style={{ marginLeft: 2, alignSelf: 'center' }}> {item?.level?.name}</OpenSansBoldText>

                <Button
                    onClick={handleManage}
                    size="large"
                    style={{
                        width: 250,
                        backgroundColor: color,
                        color: baseColors.white,
                    }}
                    variant="contained"
                >
                    <OpenSansBoldText style={{ fontSize: 11, color: baseColors.white }}>
                        Gestion du programme
                    </OpenSansBoldText>
                </Button>

                <Button
                    onClick={handleSynthese}
                    style={{
                        borderWidth: 2,

                        borderColor: color,
                    }}
                    variant="outlined"
                    size="large"
                >
                    <OpenSansBoldText style={{ fontSize: 11, color: color }}>Synthèse</OpenSansBoldText>
                </Button>
                <Button
                    onClick={() => setModalConfirmationVisible(true)}
                    style={{
                        borderWidth: 2,
                        borderColor: color,
                    }}
                    variant="outlined"
                    size="large"
                >
                    <Delete sx={{color}} />
                </Button>
            </Stack>

            <CustomConfirm
                modalVisible={modalConfirmationVisible}
                updateModalVisible={setModalConfirmationVisible}
                callBackconfirm={handleDeleteProgram}
                message={<>Voulez-vous vraiment supprimer ce programme ?<br/>A noter que cette action est irréversible.</>}
            />
        </Box>
    );
};

export default ProgramItemCard;
