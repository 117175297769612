import { Dispatch, SetStateAction } from 'react';
import { ChoiceRespone, QuestionType, QuizPayloadType } from 'src/interfaces/types';

type SetQuestionDataType = {
    questionIndex: number;
    questionType?: number;
    textValue?: string;
    isResponse?: boolean;
    isQuestion?: boolean;
    setQuestions: Dispatch<SetStateAction<QuestionType[]>>;
};

type SetResponseDataType = {
    responseIndex: number;
    textValue?: string;
    selectedElement?: number;
    isRadiobtn?: boolean;
    setResponses: Dispatch<SetStateAction<ChoiceRespone[]>>;
};

type ValidateRadioResponses = {
    responses: ChoiceRespone[];
    questionIndex: number;
    setQuestions: Dispatch<SetStateAction<QuestionType[]>>;
    setValidating: Dispatch<SetStateAction<boolean>>;
};

const newQuestionContent = {
    textQuestion: '',
    responseType: '',
    responses: [],
};

const newRadioItemContent: ChoiceRespone = {
    textResponse: '',
    isTrue: false,
};

const defaultQuizPayload: QuizPayloadType = {
    title: '',
    programIds: [],
    quizzType: 0,
    questions: [],
};

const defaultHeaderPayload = {
    title: '',
    programIds: [],
    quizzType: 0,
};

const checkQuestionType = (typeId: number) => {
    switch (typeId) {
        case 1:
            return 'text';

        case 2:
            return 'radio';

        case 3:
            return 'checkbox';

        default:
            return '';
    }
};

const checkQuestionTypeId = (typeName: string) : number => {
    switch (typeName) {
        case "text":
            return 1;

        case "radio":
            return 2;

        case "checkbox":
            return 3;

        default:
            return 0;
    }
};

const handleSetQuestionData = ({
    questionIndex,
    setQuestions,
    questionType,
    textValue,
    isResponse,
    isQuestion,
}: SetQuestionDataType) =>
    setQuestions((prev: QuestionType[]) => {
        let newValue = prev;

        if (questionType || questionType === 0) {
            newValue[questionIndex].responseType = checkQuestionType(questionType ?? 0);
        }

        if (isResponse) {
            newValue[questionIndex].responses = [{ textResponse: textValue ?? '' }];
        }

        if (isQuestion) {
            newValue[questionIndex].textQuestion = textValue ?? '';
        }

        return [...newValue];
    });

const handleSetResponseData = ({
    responseIndex,
    setResponses,
    textValue,
    isRadiobtn,
    selectedElement,
}: SetResponseDataType) =>
    setResponses((prev: ChoiceRespone[]) => {
        let newValue = prev;

        const itemValue = newValue[responseIndex];

        if (isRadiobtn) {
            newValue[responseIndex] = { ...itemValue, isTrue: selectedElement === responseIndex };
        }

        if (textValue) {
            newValue[responseIndex] = { ...itemValue, textResponse: textValue ?? '' };
        }

        return [...newValue];
    });

const handleChooseRadioItem = ({
    setSelectedElement,
    responseIndex,
    setResponses,
}: {
    setSelectedElement: Dispatch<SetStateAction<number>>;
    setResponses: Dispatch<SetStateAction<ChoiceRespone[]>>;
    responseIndex: number;
}) => {
    if (responseIndex >= 0) {
        setSelectedElement(responseIndex);
        setResponses((prev: ChoiceRespone[]) => {
            let newState = [...prev];
            const updatedValues = newState.map((response: ChoiceRespone, i: number) => {
                if (i !== responseIndex) {
                    return { ...response, isTrue: false };
                }
                return { ...response, isTrue: true };
            });

            return [...updatedValues];
        });
    }
};

const handleChooseCheckBoxItem = ({
    setSelectedElements,
    responseIndex,
    setResponses,
}: {
    setSelectedElements: Dispatch<SetStateAction<number[]>>;
    setResponses: Dispatch<SetStateAction<ChoiceRespone[]>>;
    responseIndex: number;
}) => {
    function SetResponesCheckedState(isTrue: boolean, responseIndex: number) {
        setResponses((prev: ChoiceRespone[]) => {
            let newState = [...prev];
            newState[responseIndex].isTrue = isTrue;
            return [...newState];
        });
    }

    if (responseIndex >= 0) {
        setSelectedElements((prev: number[]) => {
            if (prev?.length > 0) {
                if (prev?.includes(responseIndex)) {
                    const res = prev.filter((item) => item !== responseIndex);
                    SetResponesCheckedState(false, responseIndex);
                    return [...res];
                } else {
                    SetResponesCheckedState(true, responseIndex);
                    return [...prev, responseIndex];
                }
            } else {
                SetResponesCheckedState(true, responseIndex);
                return [responseIndex];
            }
        });
    }
};

const handleValidateRadioResponses = ({
    responses,
    questionIndex,
    setQuestions,
    setValidating,
}: ValidateRadioResponses) => {
    setValidating(true);
    setTimeout(() => {
        setValidating(false);
    }, 2000);

    setQuestions((prev: QuestionType[]) => {
        let newValue = prev;

        if (responses?.length > 0) {
            newValue[questionIndex].responses = [...responses];
        }

        return [...newValue];
    });
};

export {
    defaultQuizPayload,
    defaultHeaderPayload,
    newQuestionContent,
    handleSetQuestionData,
    newRadioItemContent,
    handleSetResponseData,
    handleChooseRadioItem,
    handleValidateRadioResponses,
    handleChooseCheckBoxItem,
    checkQuestionTypeId
};
