import { FC, useState, useEffect } from 'react';
import { Box } from '@mui/system';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import { InputField } from 'src/components/InputField/InputField';
import { isNotBlank } from 'src/utils/helpers/InputValidator';
import { Medimails } from 'src/interfaces/interfaces';
import { PatientDiscussion } from 'src/interfaces/interfaces';
import { CustomButton } from 'src/components/Button/CustomButton';
import { PatientService } from 'src/services/Patients';
import { ReportList } from './ReportList';
import { TextEditor } from 'src/components/CustomEditor/TextEditor';
import { toast } from 'react-toastify';
import { EditorState } from 'draft-js';
import { CustomText } from 'src/components/Text/CustomText';
import { checMentionnedUsers, optimizePatientsData, checkMailUsers } from './ReportLogic';
import MedimailService from 'src/services/Medimails';
import { optimizeMedimailsData } from 'src/views/Programme/Detail/Discussion/DiscussionLogic';
import { getPostsListByPatient, getPatientWorkshop, getPostsViews } from 'src/services/Posts';
import { ReportManagementFormCard } from './ReportManagementFormCard';
import { ReportManagementFormVideoCard } from './ReportManagementFormVideoCard';
import { OperationMessage } from 'src/utils/constants/constants';

const useStyles = makeStyles({
    container: {
        padding: 10,
        paddingTop: 25,
        flexGrow: 1,
        overflowY: 'scroll',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        marginTop: 10,
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    title: {
        color: baseColors.black,
        fontFamily: baseFontFamily.BreeSerif,
    },
    flexCenter: {
        display: 'flex',
        justifyContent: 'center',
        marginRight: 60,
        marginTop: -30,
    },
    programsWrapper: {
        marginTop: 20,
    },
    programsInnerWorkshop: {
        backgroundColor: 'blue',
        paddingLeft: 20,
    },
    programsInnerCase: {
        backgroundColor: baseColors.green.light,
        paddingLeft: 20,
        borderRadius: 10,
    },
    programsInnerVideo: {
        backgroundColor: 'green',
        paddingLeft: 20,
    },
});

interface ReportFormProps {
    diagEducId?: number;
}

export const ReportForm: FC<ReportFormProps> = ({ diagEducId }) => {
    const params = useParams<any>();
    const patientId: number = params?.id ?? 0;
    const defaultReport = {
        patientId: patientId,
        programEnteringReason: '',
        medicalAntecedent: '',
        treatments: '',
        educationalDiagnosisConclusion: '',
        followedProgramContent: '',
        interviewConclusion: '',
        tool: '',
        observations: '',
        acquiredSkills: '',
        project: '',
        careToolsId: 1,
        reportText: '',
        recipients: [],
        externalUsersMail: [],
    };
    const defaultData: any = [
        {
            text: '',
            value: '',
            url: '',
        },
    ];
    const classes = useStyles();
    const [report, setReport] = useState<any | null>(defaultReport);
    const [addReportLoading, setAddReportLoading] = useState(false);
    const [lockInput, setLockInput] = useState(false);
    const [view, setView] = useState('form');
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const [patients, setPatients] = useState<any[]>([]);
    const [postPatient, setPostPatient] = useState<any[]>([]);
    const [workshopPatient, setWorkshopPatient] = useState<any[]>([]);
    const [postView, setPostView] = useState<any[]>([]);
    const [workshopField, setWorkshopField] = useState<any[]>([]);
    const [message, setMessage] = useState<string>('');

    const [findMedimail, setFindMedimail] = useState<string>('');
    const [mediMailsMentioned, setMedimailsMentioned] = useState<any[]>([]);
    const [dataMedimails, setDataMedimails] = useState<any[]>(defaultData);
    const [filtered, setFiltered] = useState<any[]>([]);

    const validReportData = () => {
        return (
            isNotBlank(report?.programEnteringReason) &&
            isNotBlank(report?.medicalAntecedent) &&
            isNotBlank(report?.treatments) &&
            isNotBlank(report?.educationalDiagnosisConclusion) &&
            isNotBlank(report?.followedProgramContent) &&
            isNotBlank(report?.interviewConclusion) &&
            isNotBlank(report?.tool) &&
            isNotBlank(report?.observations) &&
            isNotBlank(report?.acquiredSkills) &&
            isNotBlank(report?.project)
        );
    };

    const handleFetchMedimails = (value: any) => {
        MedimailService.getMedimails(value).then((res) => {
            const responses = res?.data?.data;
            setDataMedimails(responses);
        });
    };

    useEffect(() => {
        setReport((old: any) => ({ ...old, reportText: message }));
        mediMailsMentioned.map((item) => {
            if (item.length > 0) {
                setFiltered(item);
            }
        });
        const qu = message.split('@');

        qu.map((item) => {
            handleFetchMedimails(item);
        });
    }, [message, findMedimail]);

    const medimailsDataToFilter: Medimails[] = Array.from(dataMedimails, (medimail: any) => ({
        id: medimail.id,
        name: `${medimail.prenom}-${medimail.nom}`.toLocaleLowerCase(),
        mail: medimail.mail,
    }));

    const handleChange = (value: any) => {
        setMessage(value);

        const query = value.split('@');

        const checkMentionString = query.filter((item: any) => item.includes(' '));

        const filteredData = checkMentionString.map((item: any) => {
            const splitedItem = item.split(' ');
            return `${splitedItem?.[0]}-${splitedItem?.[1]}`.toLocaleLowerCase();
        });

        const mentioned =
            medimailsDataToFilter?.length > 0
                ? medimailsDataToFilter?.filter((pat: Medimails) =>
                      filteredData.some((item: string) => pat.name === item),
                  )
                : [];

        setMedimailsMentioned((prev: any) => [...prev, mentioned]);
    };

    const optimizedPatients = optimizePatientsData(patients);
    const optimizedMedimail = optimizeMedimailsData(dataMedimails);
    const optimizedData = optimizedPatients.concat(optimizedMedimail);

    const patientsDataToFilter: PatientDiscussion[] = Array.from(patients, (patient) => ({
        id: patient.id,
        name: `${patient.lastName}-${patient.firstName}`.toLocaleLowerCase(),
    }));

    const mentionedUsers = checMentionnedUsers(report.reportText, patientsDataToFilter);
    const mentionnedUsersMails = checkMailUsers(report.reportText);

    const removeId: any = { ...report };
    delete removeId.id;
    const payload = {
        ...removeId,
        recipients: mentionedUsers?.length > 0 ? mentionedUsers.map((user) => user.id) : [],
        externalUsersMail: mentionnedUsersMails?.length > 0 ? mentionnedUsersMails : [],
    };

    const performAddReport = () => {
        setAddReportLoading(true);
        setLockInput(true);
        PatientService.postReports({ ...payload }).then((response) => {
            if (response.data.code === 200) {
                setReport(defaultReport);
                setView('liste');
                toast.success(OperationMessage.success);
            }
            setAddReportLoading(false);
            setLockInput(false);
        });
    };

    const handleFetchPatients = () => {
        PatientService.getProfessionals()
            .then((res: any) => {
                const responses = res?.data?.data;
                setPatients(responses);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        handleFetchPostsPatient();
    }, [postPatient, postView]);

    useEffect(() => {
        handleFetchPatients();
        handleFetchWorkshopPatient();
        handleFetchPostViewPatient();
    }, [patientId]);

    useEffect(() => {
        handleTransformPatient();
    }, [workshopPatient]);

    const handleFetchPostsPatient = () => {
        getPostsListByPatient(patientId)
            .then((res: any) => {
                const responses = res;

                let data: any[] = [];
                responses.forEach((items: any) => {
                    if (items && items.id && items.video) {
                        const postViewItem = postView.find((pv) => pv.id === items.id);
                        data.push({
                            id: items.id,
                            name: `${items.title} : ${items.video ? items.video.name : items.image.name}`,
                            validated: postViewItem ? 1 : 0,
                        });
                    }
                });

                setPostPatient(data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleFetchWorkshopPatient = () => {
        getPatientWorkshop(patientId)
            .then((res: any) => {
                const responses = res;
                if (responses) {
                    setWorkshopPatient(responses);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleFetchPostViewPatient = () => {
        getPostsViews(patientId)
            .then((res: any) => {
                const responses = res;
                if (responses) {
                    setPostView(responses);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleTransformPatient = () => {
        if (workshopPatient) {
            let data: any[] = [];
            let transformedData: any[] = [];
            workshopPatient.map((item) => {
                const res = item?.programDetails?.[0]?.programDetailWorkshops;
                data.push(res);
                return data;
            });
            let flatedData = data.flat(1);
            if (flatedData) {
                flatedData.map((item) => {
                    transformedData.push({
                        id: `${item?.id}`,
                        workshopId: `${item?.workshop[0]?.id}`,
                        validated: `${item?.validated}`,
                        name: `${item?.workshop[0]?.name}`,
                        programDetailId: `${item?.programDetailId}`,
                    });
                    return transformedData;
                });
                setWorkshopField(transformedData);
            }
        }
    };

    const selectedRows = new Set(
        workshopField?.length > 0 ? Array.from(workshopField, (item: { id: number }) => item.id) : [],
    );

    useEffect(() => {
        setReport((old: any) => ({ ...old, reportText: message }));
    }, [message]);

    return (
        <div>
            {view === 'form' ? (
                <Box className={classes.container}>
                    <Grid container direction={'column'} spacing={7}>
                        <Grid item>
                            <Grid container direction={'row'} justifyContent={'space-between'}>
                                <Grid item flex={2}>
                                    <Grid item>
                                        <InputField
                                            label="Quels sont les motifs d'entrée dans le programme ? :"
                                            disabled={lockInput}
                                            validator={isNotBlank}
                                            errorMessage="Raison d'entrée invalide"
                                            placeholder="Taper votre texte"
                                            height={100}
                                            width={'90%'}
                                            onChange={(value: any) =>
                                                setReport((old: any) => ({
                                                    ...old,
                                                    programEnteringReason: value.toString(),
                                                }))
                                            }
                                            value={report?.programEnteringReason}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <InputField
                                            label="Quels sont les antécédents médicaux ? :"
                                            disabled={lockInput}
                                            validator={isNotBlank}
                                            errorMessage="Antecidents médicaux invalide"
                                            placeholder="Taper votre texte"
                                            height={100}
                                            width={'90%'}
                                            onChange={(value: any) =>
                                                setReport((old: any) => ({
                                                    ...old,
                                                    medicalAntecedent: value.toString(),
                                                }))
                                            }
                                            value={report?.medicalAntecedent}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <InputField
                                            label="Quels sont ses traitements habituels ? :"
                                            disabled={lockInput}
                                            validator={isNotBlank}
                                            errorMessage="Traitements habituels invalide"
                                            placeholder="Taper votre texte"
                                            height={100}
                                            width={'90%'}
                                            onChange={(value: any) =>
                                                setReport((old: any) => ({ ...old, treatments: value.toString() }))
                                            }
                                            value={report?.treatments}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <InputField
                                            label="Quels sont les outils utilisés ? :"
                                            disabled={lockInput}
                                            validator={isNotBlank}
                                            errorMessage="Raison d'entrée invalide"
                                            placeholder="Taper votre texte"
                                            height={100}
                                            width={'90%'}
                                            onChange={(value: any) =>
                                                setReport((old: any) => ({ ...old, tool: value.toString() }))
                                            }
                                            value={report?.tool}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <InputField
                                            label="Quelle est votre compétence acquise ? :"
                                            disabled={lockInput}
                                            validator={isNotBlank}
                                            errorMessage="Compétences acquises invalide"
                                            placeholder="Taper votre texte"
                                            height={100}
                                            width={'90%'}
                                            onChange={(value: any) =>
                                                setReport((old: any) => ({ ...old, acquiredSkills: value.toString() }))
                                            }
                                            value={report?.acquiredSkills}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item flex={2}>
                                    <Grid item>
                                        <InputField
                                            label="Quels sont les conclusions du diagnostic éducatif ? :"
                                            disabled={lockInput}
                                            validator={isNotBlank}
                                            errorMessage="Raison d'entrée invalide"
                                            placeholder="Taper votre texte"
                                            height={100}
                                            width={'90%'}
                                            onChange={(value: any) =>
                                                setReport((old: any) => ({
                                                    ...old,
                                                    educationalDiagnosisConclusion: value.toString(),
                                                }))
                                            }
                                            value={report?.educationalDiagnosisConclusion}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <InputField
                                            label="Quel est le contenu du programme suivi ? :"
                                            disabled={lockInput}
                                            validator={isNotBlank}
                                            errorMessage="Raison d'entrée invalide"
                                            placeholder="Taper votre texte"
                                            height={100}
                                            width={'90%'}
                                            onChange={(value: any) =>
                                                setReport((old: any) => ({
                                                    ...old,
                                                    followedProgramContent: value.toString(),
                                                }))
                                            }
                                            value={report?.followedProgramContent}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <InputField
                                            label="Quelles sont les conclusions de l'entretien d'évaluation ? :"
                                            disabled={lockInput}
                                            validator={isNotBlank}
                                            errorMessage="Raison d'entrée invalide"
                                            placeholder="Taper votre texte"
                                            height={100}
                                            width={'90%'}
                                            onChange={(value: any) =>
                                                setReport((old: any) => ({
                                                    ...old,
                                                    interviewConclusion: value.toString(),
                                                }))
                                            }
                                            value={report?.interviewConclusion}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <InputField
                                            label="Entrer votre observation :"
                                            disabled={lockInput}
                                            validator={isNotBlank}
                                            errorMessage="Observations invalide"
                                            placeholder="Taper votre texte"
                                            height={100}
                                            width={'90%'}
                                            onChange={(value: any) =>
                                                setReport((old: any) => ({
                                                    ...old,
                                                    observations: value.toString(),
                                                }))
                                            }
                                            value={report?.observations}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <InputField
                                            label="Quel est votre projet ? :"
                                            disabled={lockInput}
                                            validator={isNotBlank}
                                            errorMessage="Projet invalide"
                                            placeholder="Taper votre texte"
                                            height={100}
                                            width={'90%'}
                                            onChange={(value: any) =>
                                                setReport((old: any) => ({
                                                    ...old,
                                                    project: value.toString(),
                                                }))
                                            }
                                            value={report?.project}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <CustomText>Details compte rendu :</CustomText>
                                <TextEditor
                                    setEditorState={setEditorState}
                                    editorState={editorState}
                                    handleChange={(val: any) => handleChange(val)}
                                    data={optimizedData}
                                />
                            </Grid>
                            <Grid container direction={'row'} className={classes.programsWrapper}>
                                <Grid direction={'column'}>
                                    <CustomText fontSize={13} fontFamily={baseFontFamily.OpenSansSemiBold}>
                                        Listes des Ateliers
                                    </CustomText>
                                    <Grid item className={classes.programsInnerCase} style={{ marginTop: 20 }}>
                                        <Box sx={{ width: '100%' }} marginRight={2}>
                                            <Box style={{ marginBottom: 25 }}>
                                                <ReportManagementFormCard
                                                    items={workshopField}
                                                    selectedRows={selectedRows}
                                                    labelKey={'name'}
                                                    handleFetchWorkshopPatient={() => handleFetchWorkshopPatient()}
                                                    //setUpdateList={setUpdateList}
                                                />
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid direction={'column'} style={{ marginLeft: 20 }}>
                                    <CustomText
                                        fontSize={13}
                                        fontFamily={baseFontFamily.OpenSansSemiBold}
                                        style={{ marginLeft: 10 }}
                                    >
                                        Listes des Videos
                                    </CustomText>
                                    <Grid item className={classes.programsInnerCase} style={{ marginTop: 20 }}>
                                        <Box sx={{ width: '100%' }} marginRight={2}>
                                            <Box style={{ marginBottom: 25 }}>
                                                <ReportManagementFormVideoCard
                                                    items={postPatient}
                                                    selectedRows={selectedRows}
                                                    labelKey={'name'}
                                                    patientId={patientId}
                                                    postView={postView}
                                                    handleFetchPostList={() => handleFetchPostsPatient()}
                                                    handleFetchPostViewPatient={() => handleFetchPostViewPatient()}
                                                    //checkedData={postView}
                                                />
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/*  <Grid container direction={'row'}>
                                
                            </Grid> */}
                        </Grid>
                        <Grid></Grid>
                        <Grid item>
                            <div className={classes.flexCenter}>
                                <CustomButton
                                    loading={addReportLoading}
                                    disabled={!validReportData()}
                                    width={350}
                                    onClick={() => {
                                        performAddReport();
                                    }}
                                    label={'ENREGISTRER COMPTE-RENDU'}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </Box>
            ) : view === 'liste' ? (
                <ReportList diagEducId={diagEducId ?? 0} patientId={patientId} />
            ) : (
                <></>
            )}
        </div>
    );
};
