import { Grid } from "@mui/material"
import { FC, useEffect, useState } from "react"
import { InputField } from "../InputField/InputField"
import useDebounceWithDelay from "src/hooks/useDebounceWithDelay";

interface QuestionTexteProps {
    setPayload: Function;
    questionTitle: JSX.Element;
    responseValue: any;
    index: number;
}

const QuestionText: FC<QuestionTexteProps> = ({ setPayload, questionTitle, responseValue, index }) => {
    const [inputValue, setInputValue] = useState(responseValue);
    const debouncedInputValue = useDebounceWithDelay(inputValue, 900); // Debounce input value by 500ms

    useEffect(() => {
        setInputValue(responseValue)
    }, [responseValue]);

    useEffect(() => {
        if (debouncedInputValue !== responseValue) {
            updatePayload(debouncedInputValue);
        }
    }, [debouncedInputValue]); // Only update the payload when the debounced value changes

    const updatePayload = (value: string) => {
        setPayload((prev: any) => {
            const updatedPayload = { ...prev };
            if (updatedPayload.responses && updatedPayload.responses.length > index && updatedPayload.responses[index]) {
                updatedPayload.responses[index].responseValue = value;
            }
            return updatedPayload; // Return the updated state
        });
    };

    return (
        <Grid
            style={{ marginTop: 20, marginBottom: 10 }}
            container
            direction="row"
            alignItems={'center'}
            justifyContent="space-between"
        >
            <Grid item>
                {questionTitle}
            </Grid>
            <InputField
                label={''}
                onChange={(value: any) => setInputValue(value)}
                errorMessage=''
                placeholder=''
                height={100}
                width='100%'
                value={inputValue}
            />
        </Grid>
    );
}

export default QuestionText;
