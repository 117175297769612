import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FC, useEffect, useState } from 'react';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import useStyles from './styles';
import { SearchInputField } from 'src/components/InputField/SearchInputField';
import HdjDataService from 'src/services/Hdj/dayHospital.service';
import { CustomSelect } from 'src/components/InputField/CustomSelect';
import useDebounce from 'src/hooks/useDebounce';
import { CustomText } from 'src/components/Text/CustomText';

interface HdjHeaderProps {
    nameFilter: string;
    setNameFilter: any;
    searchQuery: string;
    setSearchQuery: (val: string) => void;
    setSpecialityId?: (val: number) => void;
    handleFetchHdj: Function;
}

const HdjHeader: FC<HdjHeaderProps> = (props) => {
    const { searchQuery, setSearchQuery, setSpecialityId, handleFetchHdj } = props;
    const classes = useStyles();
    const [specialityItems, setSpecialityItems] = useState<any>([]);
    const { debouncedResults } = useDebounce(handleFetchHdj);

    const handleGetSpecialties = () => {
        HdjDataService.getHdjSpecialities().then((res: any) => {
            if (res) {
                setSpecialityItems(res?.data?.data?.items);
            }
        });
    };

    useEffect(() => {
        handleGetSpecialties();
    }, []);

    const handleChangeSpeciality = (id: number, setValue: any) => setValue(id);
    const items =
        specialityItems?.length > 0
            ? [{ id: -1, name: 'Spécialité : Toutes' }, ...specialityItems]
            : [{ id: -1, name: 'Spécialité : Toutes' }];

    return (
        <Box className={classes.container}>
            <CustomText style={{ marginBottom: 2, textAlign: 'center' }} isBreefSerif isTitle>
                Hospitalisation du jour
            </CustomText>

            <Box marginBottom={2} className={classes.subheader}>
                <Typography color={baseColors.black} fontFamily={baseFontFamily.BreeSerif} fontSize={16}>
                    Liste des HDJ
                </Typography>

                <Box className={classes.searchInput}>
                    <SearchInputField
                        padding={7}
                        onChange={(value: string) => {
                            setSearchQuery(value);
                            debouncedResults(value);
                        }}
                        placeholder={'Rechercher une HDJ'}
                        value={searchQuery}
                    />
                </Box>
            </Box>

            <CustomSelect
                onChange={(value: number) => handleChangeSpeciality(value, setSpecialityId)}
                labelKey={'name'}
                label={''}
                items={items}
                idSelect="hdj-selection"
            />
        </Box>
    );
};

export default HdjHeader;
