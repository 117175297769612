import { baseBorderRadius, baseColors, baseScreenHeight } from 'src/utils/constants/baseStyles';

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        ...baseScreenHeight.defaultHeight,
        ...baseBorderRadius.allSide,
    },
    content: {
        backgroundColor: 'white',
        flex: 1.5,
        borderRadius: 2,
        marginRight: 1,
        minWidth: 160,
    },
    panel: {
        backgroundColor: baseColors.white,
        flex: 8,
        borderRadius: 2,
    },
};

export default styles;
