import HDJForm from './../views/Hdj/HdjForm/HdjForm';

const HdjPrivateRoutes = {
    hdjForm: '/hdj/new',
    hdjEdit: '/hdj/:id/edit',
    // hdjPlanning: '/hdj/planning/:id',
    // hdjManagement: '/hdj/management/:id',
};

const PrivateHdjRoutes = [
    {
        key: 'CREATE_HDJ',
        path: HdjPrivateRoutes.hdjForm,
        component: <HDJForm />,
    },
    {
        key: 'EDIT_HDJ',
        path: HdjPrivateRoutes.hdjEdit,
        component: <HDJForm />,
    },
    // {
    //     key: 'HDJ_PLANNING',
    //     path: HdjPrivateRoutes.hdjPlanning,
    //     component: <HDJForm />,
    // },
    // {
    //     key: 'HDJ_MANAGEMENT',
    //     path: HdjPrivateRoutes.hdjManagement,
    //     component: <HDJForm />,
    // },
];

export { HdjPrivateRoutes, PrivateHdjRoutes };
