import { Box } from '@mui/system';
import React, { FC } from 'react';
import ContentLoader from 'react-content-loader';
import { baseColors } from 'src/utils/constants/baseStyles';

interface HDJFormCardLoadingProps {
    loadingNumber?: number;
}

const HDJFormCardLoading: FC<HDJFormCardLoadingProps> = (props) => {
    const { loadingNumber } = props;

    const itemLoad = (key: number) => (
        <ContentLoader
            key={key}
            backgroundColor={baseColors.green.light}
            foregroundColor={baseColors.green.secondary}
            viewBox="0 0 80 10"
        >
            <rect x="3" y="3" rx="0" ry="0" width="5" height="5" />
            <rect x="10" y="4" rx="0" ry="0" width="65" height="3" />
        </ContentLoader>
    );

    const listLoader = Array.from(Array(loadingNumber ?? 3).keys());
    const itemToRender = (key: number) => itemLoad(key);

    return <Box>{listLoader.map((item: any, i: number) => itemToRender(i))}</Box>;
};

export default HDJFormCardLoading;
