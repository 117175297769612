import { makeStyles } from '@mui/styles';
import { baseColors } from 'src/utils/constants/baseStyles';

const useStyles = makeStyles({
    loading: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 150,
    },
});

export default useStyles;
