import axios from './AxiosInstance';

const getRoles =  (filters?: any) => {

    return axios.get('api/roles?paginated=false').then((response:any) => {
         const result = JSON.parse(response.request.response);
        return result.data;
    }).catch((reason) => {
        console.log('Get User Roles Error', reason);
    });
}

const RoleService = {
    getRoles
};


export default RoleService;