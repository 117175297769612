import { FC, useEffect, useState } from 'react';
import CustomModal from 'src/components/CustomModal';
import { Grid } from '@mui/material';
import { CustomText } from 'src/components/Text/CustomText';
import { InputField } from 'src/components/InputField/InputField';
import { sendMedMail } from 'src/services/Patients';
import { toast } from 'react-toastify';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';

interface ContactUsProps {
    open: boolean;
    setOpen: Function;
    professionalInfo: any;
}

export const ContactUs: FC<ContactUsProps> = (props) => {
    const { open, setOpen, professionalInfo } = props;
    const [recipienId, setRecipienId] = useState(professionalInfo?.id ?? 0);
    const [content, setContent] = useState('');
    const [subject, setSubject] = useState('');
    const disableBtn = content?.length === 0 || subject?.length === 0;
    const [sendingMail, setSendingMail] = useState(false);

    const professinalEmail = professionalInfo?.professional?.email;

    useEffect(() => {
        setRecipienId(professionalInfo?.professional?.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [professionalInfo?.professional]);

    const performSendMedMail = () => {
        if (professinalEmail && recipienId > 0) {
            setSendingMail(true);
            sendMedMail({ subject: subject, content: content, recipientId: recipienId })
                .then((response) => {
                    setSendingMail(false);
                    setContent('');
                    setSubject('');
                    setRecipienId(0);
                    toast.success('Message envoyé avec succès.');
                    setOpen(false);
                })
                .catch((err) => {
                    setSendingMail(false);
                    toast.error('Message non envoyé.');
                });
        }
    };

    return (
        <div>
            <CustomModal
                width={500}
                height={520}
                open={open}
                toggleModal={setOpen}
                title={`Contacter Dr ${professionalInfo?.professional?.firstName} ${professionalInfo?.professional?.lastName}`}
            >
                <Grid
                    style={{ marginTop: 20 }}
                    container
                    direction="row"
                    alignItems={'center'}
                    justifyContent="space-between"
                >
                    <Grid style={{}} container direction="row" alignItems={'center'} justifyContent="space-between">
                        <Grid item>
                            <CustomText style={{}}>Adresse e-mail :</CustomText>
                        </Grid>

                        <Grid item style={{ marginTop: 20 }} width={'70%'}>
                            <InputField
                                disabled
                                label={''}
                                onChange={(value: any) => {}}
                                errorMessage="Nom de la consultation obligatoire"
                                placeholder={'Entrer adresse email'}
                                width={'100%'}
                                value={professinalEmail}
                                idSuffix="email_professionel_sante"
                            />
                        </Grid>
                    </Grid>

                    <Grid style={{}} container direction="row" alignItems={'center'} justifyContent="space-between">
                        <Grid item>
                            <CustomText style={{}}>Objet :</CustomText>
                        </Grid>

                        <Grid item style={{ marginTop: 20 }} width={'70%'}>
                            <InputField
                                disabled={sendingMail}
                                label={''}
                                onChange={(value: any) => {
                                    setSubject(value);
                                }}
                                placeholder={"Objet de l'email"}
                                width={'100%'}
                                value={subject}
                                idSuffix="objet_contact_professionel_sante"
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        direction="row"
                        alignItems={'self-start'}
                        justifyContent="space-between"
                        style={{ marginBottom: 10, marginTop: 20 }}
                    >
                        <Grid item>
                            <CustomText> Message :</CustomText>
                        </Grid>
                        <Grid item flexGrow={1} style={{ marginBottom: 10, marginLeft: '13%' }}>
                            <InputField
                                height={200}
                                label=""
                                disabled={sendingMail}
                                multiline
                                placeholder={'Taper votre texte'}
                                onChange={(value: any) => {
                                    setContent(value);
                                }}
                                width={'100%'}
                                value={content}
                                idSuffix="message_contact_professionel_sante"
                            />
                        </Grid>
                    </Grid>

                    <Grid container justifyContent={'center'}>
                        <CustomLoadingButton
                            width={'90%'}
                            label="Envoyer message"
                            loading={sendingMail}
                            disabled={disableBtn}
                            handleClick={performSendMedMail}
                        />
                    </Grid>
                </Grid>
            </CustomModal>
        </div>
    );
};
