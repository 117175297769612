import { FC } from 'react';
import AxisContainer from '../AxisContainer';
import { AXIS_IDENTIFICATION } from 'src/utils/constants/enums';

interface AxisMedicalProps {
    patientId: number;
    educationalDiagnosisId: number;
}

const AxisMedical: FC<AxisMedicalProps> = ({ patientId, educationalDiagnosisId }) => {
    return (
        <AxisContainer
            axisId={AXIS_IDENTIFICATION.MEDICAL}
            patientId={patientId}
            educationalDiagnosisId={educationalDiagnosisId}
            title="Médical"
            position={4}
            prevScreenPath="psychologique"
            nextScreenPath="synthese"
        />
    );
};

export default AxisMedical;
