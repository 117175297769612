import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { ROUTES } from 'src/routes/routes';
import useCheckUserRole from './useCheckUserRole';

const useAdminRedirection = () => {
    const history = useHistory();
    const { roles } = useCheckUserRole();

    useEffect(() => {
        if (roles?.isAdmin) {
            history.replace(ROUTES.security);
        }
    }, [roles]);

    return {};
};

export default useAdminRedirection;
