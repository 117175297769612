import * as React from 'react';

function IconDiabeto(props: any) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={72} height={53} fill="none" viewBox="0 0 72 53" {...props}>
            <g clipPath="url(#clip0_1099_9605)">
                <path
                    fill="#FFB123"
                    d="M46.647 0H24.422c-6.598 0-11.947 3.968-11.947 8.862V30.41c0 2.74 1.116 5.417 3.203 7.678 4.311 4.671 11.452 7.464 19.083 7.464h1.547c7.632 0 14.772-2.793 19.083-7.464 2.087-2.261 3.204-4.938 3.204-7.678V8.862C58.595 3.968 53.245 0 46.647 0z"
                />
                <path
                    fill="#FF901D"
                    d="M51.633.81v32.342c0 .953-.389 1.885-1.115 2.672l-5.647 6.12c-2.084 2.257-5.536 3.607-9.224 3.607h.66c7.632 0 14.772-2.793 19.083-7.464 2.087-2.261 3.204-4.938 3.204-7.678V8.863c0-3.574-2.853-6.652-6.96-8.054z"
                />
                <path
                    fill="#2C5871"
                    d="M23.67 43.447c3.353 1.36 7.157 2.105 11.092 2.105h1.547c3.935 0 7.739-.745 11.092-2.105v-7.605c0-.408-.446-.739-.997-.739H24.668c-.55 0-.997.331-.997.74v7.604z"
                />
                <path
                    fill="#1D4859"
                    d="M46.404 35.103H24.666c-.55 0-.996.33-.996.739v4.513H47.4v-4.513c0-.408-.446-.74-.997-.74z"
                />
                <path
                    fill="#E1EFFB"
                    d="M39.59 52.717h-8.11c-.29 0-.525-.175-.525-.39V35.103h9.16v17.224c0 .215-.235.39-.525.39z"
                />
                <path fill="#BACED8" d="M33.059 35.103v17.614h6.531c.29 0 .525-.175.525-.39V35.103h-7.056z" />
                <path fill="#F9644B" d="M40.115 38.502h-9.16v4.806h9.16v-4.806z" />
                <path fill="#DB3F2E" d="M40.115 38.502h-7.056v4.806h7.056v-4.806z" />
                <path
                    fill="#E1EFFB"
                    d="M51.281 23.7H19.79c-.565 0-1.023-.34-1.023-.759V9.887c0-.42.458-.759 1.023-.759h31.49c.565 0 1.023.34 1.023.759V22.94c0 .42-.458.759-1.023.759z"
                />
                <path
                    fill="#BACED8"
                    d="M51.282 9.128h-6.034V23.7h6.034c.564 0 1.022-.34 1.022-.759V9.887c0-.42-.458-.759-1.023-.759z"
                />
                <path
                    fill="#11B9C1"
                    d="M29.779 15.295c2.084 0 3.71.667 3.71 2.506v.223c0 1.92-1.641 2.635-3.678 2.635-2.416 0-3.727-1.03-3.727-2.12 0-.539.379-.691 1.026-.691.869 0 1.027.258 1.027.586 0 .574.71.89 1.626.89 1.042 0 1.674-.41 1.674-1.3V17.8c0-.89-.632-1.311-1.658-1.311h-2.337c-.648 0-.821-.235-.821-.598 0-.094.015-.2.015-.304l.411-3.08c.031-.294.158-.481.395-.481h4.863c.41 0 .616.34.616.668 0 .34-.205.667-.616.667h-3.553l-.19 1.933h1.217zm6.632 4.566c0 .422-.49.797-1.058.797-.616 0-1.074-.375-1.074-.797 0-.433.458-.796 1.074-.796.569 0 1.058.363 1.058.796zm7.989-5.762c0 .984-.616 1.57-1.516 1.804 1.137.304 1.88.995 1.88 2.202v.14c0 1.745-1.611 2.413-3.696 2.413-2.084 0-3.71-.668-3.71-2.413v-.14c0-1.207.757-1.898 1.879-2.202-.9-.235-1.516-.82-1.516-1.804 0-1.488 1.405-2.073 3.347-2.073 1.926 0 3.332.585 3.332 2.073zm-1.69 3.736c0-.89-.616-1.288-1.642-1.288-1.042 0-1.658.398-1.658 1.288v.235c0 .925.631 1.347 1.658 1.347 1.042 0 1.642-.434 1.642-1.347v-.235zm-3-3.549c0 .727.49 1.02 1.358 1.02.853 0 1.358-.293 1.358-1.02 0-.726-.505-1.018-1.358-1.018-.868 0-1.358.292-1.358 1.018z"
                />
                <path
                    fill="#E1EFFB"
                    d="M52.304 28.256H18.766c-.592 0-1.073-.357-1.073-.796 0-.44.48-.796 1.073-.796h33.538c.593 0 1.073.357 1.073.796 0 .44-.48.796-1.073.796z"
                />
            </g>
            <defs>
                <clipPath id="clip0_1099_9605">
                    <path fill="#fff" d="M0 0h71.069v52.717H0z" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default IconDiabeto;
