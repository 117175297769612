import { FC, useEffect, useState, useContext } from 'react';
import ListItemFormCheckBox from 'src/components/ListItemFormCheckBox';
import { PatientService } from 'src/services/Patients';
import { toast } from 'react-toastify';
import { ServerError } from 'src/utils/constants/constants';
import useDebounce from 'src/hooks/useDebounce';
import { PatientContext } from 'src/providers/PatientProvider';
interface IntervenantCardFormProps {
    handleChange?: Function;
    defaultSelected?: Set<number>;
    rerender?: boolean;
    patientId?: number;
    programId?:number;
}
export const IntervenantCardForm: FC<IntervenantCardFormProps> = (props) => {
    const { handleChange, defaultSelected, patientId, programId } = props;

    const [attendees, setAttendees] = useState([]);
    const [loading, setLoading] = useState(false);
    const { selectedPatient } = useContext(PatientContext);
    const professionalList = attendees.map((item: any) => item?.professional);
    const handleFetchData = (query?: string) => {
        setLoading(true);

        PatientService.getProfessionalObsevations(query, selectedPatient?.id, programId)
            .then((response) => {
                const data = response?.data?.data;
                if (data) {
                    setAttendees(data);
                }
                setLoading(false);
            })
            .catch((e) => {
                toast.error(ServerError.unknown);
                setLoading(false);
            });
    };

    const { debouncedResults } = useDebounce(handleFetchData);

    useEffect(() => {
        handleFetchData();
    }, [programId]);

    return (
        <div style={{ width: '65%' }}>
            <ListItemFormCheckBox
                isPatients
                isForProgram
                selectedRows={defaultSelected}
                handleChange={handleChange}
                handleSearch={(value: string) => debouncedResults(value)}
                items={professionalList}
                loading={loading}
                maxItems={attendees.length}
            />
        </div>
    );
};
