import React from 'react';
import { Button, Typography } from '@mui/material';
import { baseColors, baseFontWeight } from '../../utils/constants/baseStyles';
import LoadingButton from '@mui/lab/LoadingButton';
import { capitalizeFirstLetter } from 'src/utils/helpers/helpers';
import { specialCharToUnderscore } from 'src/utils/utils';

type CustomButtonProps = {
    label: string;
    width: any;
    onClick: Function;
    background?: string;
    size?: any;
    lower?: boolean;
    borderRadius?: number;
    disabled?: boolean;
    variant?: 'contained' | 'outlined' | 'text';
    loading?: boolean;
    fontSize?: number;
    customStyle?: any;
    borderWidth?: number;
    moduleBtn?: boolean;
    defaultId?: string;
    onKeyDown?: Function;
};

export const CustomButton = ({
    disabled,
    borderRadius,
    size,
    label,
    width,
    onClick,
    background,
    variant,
    loading,
    fontSize,
    customStyle,
    lower,
    borderWidth,
    moduleBtn,
    defaultId,
    onKeyDown,
}: CustomButtonProps) => {
    const style = {
        background: background ? background : disabled ? baseColors.grey : baseColors.green.primary,
        color: background ? baseColors.green.primary : baseColors.white,
        borderColor: background ? baseColors.green.primary : baseColors.white,
        width: width,
        borderRadius: borderRadius ? borderRadius : 10,
        marginBottom: 5,
        fontSize: 12,
        alignSelf: 'center',
        borderWidth: borderWidth ?? undefined,
        opacity: disabled ? 0.5 : undefined,
        fontWeight: baseFontWeight.semiBold,
    };

    const btnLabel = moduleBtn ? (
        <Typography fontSize={fontSize} fontWeight={baseFontWeight.semiBold}>
            {label}
        </Typography>
    ) : (
        label
    );

    return loading !== undefined ? (
        <LoadingButton
            loading={loading}
            loadingPosition="center"
            disabled={disabled}
            onClick={() => onClick()}
            style={{ ...style }}
            size={size ? size : 'large'}
            variant={variant ? variant : 'contained'}
            id={specialCharToUnderscore(label ?? '')}
        >
            <Typography
                style={{
                    color: loading ? 'transparent' : baseColors.white,
                    fontSize: fontSize ?? 12,
                    textTransform: lower ? 'none' : 'uppercase',
                }}
                fontWeight={baseFontWeight.semiBold}
            >
                {capitalizeFirstLetter(label)}
            </Typography>
        </LoadingButton>
    ) : (
        <Button
            disabled={disabled}
            onClick={() => onClick()}
            style={customStyle ? customStyle : lower ? { ...style, textTransform: 'none' } : { ...style }}
            size={size ? size : 'large'}
            variant={variant ? variant : 'contained'}
            id={specialCharToUnderscore(defaultId ?? label ?? '')}
        >
            {btnLabel}
        </Button>
    );
};
