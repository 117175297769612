import { Dispatch, SetStateAction } from 'react';
import { PostSkillPayload, SkillItem } from 'src/interfaces/interfaces';

const defaultSkillPaylopad = {
    skill: '',
    goalIds: [],
};

const handleSetSkillPayload = ({
    setPayload,
    skill,
}: {
    setPayload: Dispatch<SetStateAction<PostSkillPayload>>;
    skill: SkillItem;
}) => {
    setPayload(() => {
        const goalIds = skill?.goals?.map((goal) => goal.id);
        return {
            skill: skill.name,
            goalIds: goalIds,
        };
    });
};

export { defaultSkillPaylopad, handleSetSkillPayload };
