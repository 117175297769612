import { FC, useEffect, useState, Dispatch, SetStateAction } from 'react';
import { baseColors } from 'src/utils/constants/baseStyles';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import { ProgramItem } from 'src/interfaces/interfaces';
import ProgramService from 'src/services/Program/program.service';
import { toast } from 'react-toastify';
import { useHistory, useLocation } from 'react-router-dom';
import { dbDateFormat, transformDbDate, transformDbDateTime } from 'src/utils/utils';
import moment from 'moment';
import { isProgramValid, canSaveAsDraft } from './Logic';
import { PROGRAM_STATUS } from 'src/utils/constants/enums';
import { useFetchPrograms } from 'src/hooks/ReactQueryHooks/useProgramRQHooks';
import { LeaveProgramForm } from 'src/views/Programme/Form/Header/LeaveProgramForm';
import { ROUTES } from 'src/routes/routes';

interface IProps {
    defaultProgramItem: ProgramItem;
    rerender: boolean;
    setRerender: Dispatch<SetStateAction<boolean>>;
    program: ProgramItem;
    setProgram: Dispatch<SetStateAction<ProgramItem>>;
    showLeaveConfirm?: boolean;
    setShowLeaveConfirm?: any;
    targetLocation: string;
    setTargetLocation?: any;
}

interface LocationState {
    program: any;
}

export const ProgramFormFooter: FC<IProps> = ({
    program,
    setProgram,
    defaultProgramItem,
    rerender,
    setRerender,
    showLeaveConfirm,
    setShowLeaveConfirm,
    targetLocation,
    setTargetLocation,
}) => {
    const navigate = useHistory();
    const { state } = useLocation<LocationState>();
    const location: any = useLocation();
    const [savingAsDraft, setSavingAsDraft] = useState(false);
    const [savingAsReady, setSavingAsReady] = useState(false);
    const draft = 'Programme: Brouillon  ';
    const day = new Date();
    const { refetch } = useFetchPrograms({});

    const successfull = (message: string, status?: number) => {
        if (status === 200) {
            refetch();
            setProgram(defaultProgramItem);
            setRerender(!rerender);
            !showLeaveConfirm && navigate.replace('/programmes');
            toast.success(message);
        } else {
            if (status === 409) {
                toast.error('Ce nom du programme est déjà utilisé, trouver un autre nom');
            } else {
                toast.error('Erreur du création du nouveau programme');
            }
        }
        setSavingAsDraft(false);
        setSavingAsReady(false);
    };

    const failed = (message: string) => {
        toast.error(message);
        setSavingAsDraft(false);
        setSavingAsReady(false);
    };

    const handleSaveProgram = (saveAsDraft?: boolean) => {
        let data = {};
        let strTitle = '';

        program.title === '' ? (strTitle = draft + transformDbDateTime(day)) : (strTitle = program.title);

        data = {
            icon: program.icon,
            title: strTitle,
            startDate: program.startDate ? transformDbDate(program.startDate) : transformDbDate(day),
            endDate: program.endDate ? transformDbDate(program.endDate) : transformDbDate(day),
            levelId: program.level,
            programTypeIds: program.programTypeIds,
            skillIds: program.skillIds,
            stateId: saveAsDraft ? PROGRAM_STATUS.DRAFT : PROGRAM_STATUS.READY,
            surveyParentId: program?.surveyParentId ?? 0,
        };

        if (saveAsDraft) {
            setSavingAsDraft(true);
            if (program?.id > 0) {
                ProgramService.updateProgram(data, program.id)
                    .then((res: any) =>
                        successfull('Les modifications ont été enregistrées avec succès', res?.data?.code),
                    )
                    .catch(() => failed('Erreur lors du modification'));
            } else {
                ProgramService.createProgram(data)
                    .then((res: any) => successfull('Le programme a été ajouté avec succès', res?.data?.code))
                    .catch(() => failed('Erreur lors du création du nouveau programme'));
            }
        } else {
            if (!isProgramValid(program)) {
                toast.error('Veuillez remplir correctement les champs.');
            } else {
                setSavingAsReady(true);
                if (program?.id > 0) {
                    ProgramService.updateProgram(data, program.id)
                        .then((res: any) =>
                            successfull('Les modifications ont été enregistrées avec succès', res?.data?.code),
                        )
                        .catch(() => failed('Erreur lors du modification'));
                } else {
                    ProgramService.createProgram(data)
                        .then((res: any) => successfull('Le programme a été ajouté avec succès', res?.data?.code))
                        .catch(() => failed('Erreur lors du création du nouveau programme'));
                }
            }
        }
    };

    useEffect(() => {
        if (state?.program) {
            const programTypes = Array.from(state?.program?.programType, (item: any) => item?.id) ?? [];
            setProgram((old) => ({
                ...old,
                id: state?.program?.id,
                title: state?.program?.title,
                startDate: moment(state?.program?.startDate, dbDateFormat).toDate(),
                endDate: moment(state?.program?.endDate, dbDateFormat).toDate(),
                programType: programTypes,
                state: state?.program?.state?.id,
                level: state?.program?.level?.id,
                skillIds: [],
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state?.program]);

    const newProgram = program?.id === 0;
    const disabled = savingAsDraft || savingAsReady || !isProgramValid(program);

    useEffect(() => {
        const unblock = navigate.block((tl, action): any => {
            if (canSaveAsDraft(program) && tl.pathname !== location.pathname && tl.pathname !== ROUTES.programmes) {
                setShowLeaveConfirm(true);
                setTargetLocation(tl.pathname);
                return false;
            }
            return true;
        });

        return () => {
            unblock();
        };
    }, [program]);

    return (
        <>
            {program?.state === 2 ? (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <CustomLoadingButton
                        style={{ marginLeft: newProgram ? 20 : undefined }}
                        disabled={disabled}
                        width={280}
                        loading={savingAsReady}
                        label={'Enregistrer les modifications'}
                        handleClick={handleSaveProgram}
                    />
                </div>
            ) : (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <CustomLoadingButton
                        style={{ backgroundColor: baseColors.orange.primary, marginRight: 20 }}
                        //disabled={disabled}
                        width={280}
                        loading={savingAsDraft}
                        label="Enregistrer comme brouillon"
                        handleClick={() => handleSaveProgram(true)}
                    />

                    <CustomLoadingButton
                        style={{ marginLeft: newProgram ? 20 : undefined }}
                        disabled={disabled}
                        width={280}
                        loading={savingAsReady}
                        label="Valider le programme"
                        handleClick={handleSaveProgram}
                    />
                </div>
            )}
            <LeaveProgramForm
                showLeaveConfirm={showLeaveConfirm}
                setShowLeaveConfirm={setShowLeaveConfirm}
                targetLocation={targetLocation}
                handleSaveProgram={handleSaveProgram}
                disabledAddProgram={disabled}
                program={program}
            />
        </>
    );
};
