import { FC, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { retrieveNotifications } from 'src/actions/notification/notification';
import { SnackBarContext } from 'src/providers/SnackBarProvider';
import { onMessageListener } from 'src/services/Firebase/firebase.service';
toast.configure();

const FirebaseNotification: FC = () => {
    const [notification, setNotification] = useState({ title: '', body: '' });
    const { setSnackBarMessage } = useContext(SnackBarContext);
    const dispatch = useDispatch();

    const notify = (content: string) => {
        setSnackBarMessage(content);
        dispatch(retrieveNotifications());
    };

    useEffect(() => {
        const title = notification?.title;
        if (title) {
            notify(title);
        }
    }, [notification]);

    onMessageListener()
        ?.then((payload: any) => {
            setNotification({ title: payload?.notification?.title, body: payload?.notification?.body });
            dispatch(retrieveNotifications());
        })
        .catch((reason) => {
            console.log('failed', reason);
        });

    return <></>;
};

export default FirebaseNotification;
