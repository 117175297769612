import axios from '../AxiosInstance';

const unionUrl = (dayHospitalId: number, searchKey: string) => {
    let additionalUrl: string = '';

    if (dayHospitalId > 0) {
        additionalUrl += `&dayHospitalId=${dayHospitalId}`
    }

    if (searchKey) {
        additionalUrl += `&keywords=${searchKey}`
    }

    return additionalUrl
}

const getConsultations = (
    dayHospitalId: number,
    searchKey: string
) => {
    return axios.get(`api/consultations?sortId=DESC${unionUrl(dayHospitalId, searchKey)}`);
};

const getWorkshops = (
    dayHospitalId: number, 
    searchKey: string
) => {
    return axios.get(`api/workshops?paginated=false&sortId=DESC${unionUrl(dayHospitalId, searchKey )}&isDraft=false`);
};

const getPatients = (
    dayHospitalId: number,
    searchKey: string
) => {

    let additionalUrl: string = '';

    if (dayHospitalId > 0) {
        additionalUrl += `?dayHospitalId=${dayHospitalId}`
        if (searchKey) {
            additionalUrl += `&keywords=${searchKey}`
        }
    }else{
        if (searchKey) {
            additionalUrl += `?keywords=${searchKey}`
        }
    }

    return axios.get(`api/patients${additionalUrl}`);
};


const ManagementService = {
    getConsultations,
    getWorkshops,
    getPatients
};

export default ManagementService;
