import React, { useState, FC } from 'react';
import { createContext } from 'react';

type PermissionType = {
    permissions: any;
    setPermissions: (val: any) => void;
};

export const PermissionContext = createContext<PermissionType>({
    permissions: null,
    setPermissions: () => {},
});

const PermissionProvider: FC = (props) => {
    const { children } = props;
    const [permissions, setPermissions] = useState([]);

    return (
        <PermissionContext.Provider
            value={{
                permissions,
                setPermissions,
            }}
        >
            {children}
        </PermissionContext.Provider>
    );
};

export default PermissionProvider;
