import { Box } from '@mui/material';
import { FC, useContext } from 'react';
import { AgendaContext } from 'src/providers/AgendaProvider';
import CalendarContent from './CalendarContent';
import FilterContent from './FilterContent';
import InformationsDetails from './InformationsDetails';
import useStyles from './styles';

interface InformationsProps {
    toggleEventModal: Function;
    setIsFromInfos: Function;
}

const Informations: FC<InformationsProps> = (props) => {
    const { toggleEventModal, setIsFromInfos } = props;
    const classes = useStyles();
    const { selectedEvent, currentDate } = useContext(AgendaContext);

    return (
        <Box className={classes.container}>
            <CalendarContent />

            <Box
                style={{
                    paddingRight: 5,
                    paddingLeft: 5,
                    overflow: 'hidden',
                    overflowY: 'scroll',
                }}
                className={classes.content}
            >
                {selectedEvent && (
                    <InformationsDetails
                        toggleEventModal={toggleEventModal}
                        currentDate={currentDate}
                        selectedEvent={selectedEvent}
                        setIsFromInfos={setIsFromInfos}
                    />
                )}

                <FilterContent />
            </Box>
        </Box>
    );
};

export default Informations;
