import { Box, Grid } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { baseColors, baseFontFamily, baseFontSize, baseScreenHeight } from 'src/utils/constants/baseStyles';
import { CustomText } from '../Text/CustomText';
import { CustomCheckBox } from './CustomCheckBox';
import CustomModal from '../CustomModal/CustomModal';
import CustomLoadingButton from '../Button/CustomLoadingButton';
import { fullName, searchElementByName, uniqArrayNumberValues, uniqByKeyValues } from 'src/utils/utils';
import useProfessionals from 'src/hooks/useProfessionals';
import { handleCheckIntervenantByItem, handleSelectAllIntervenantBySpeciality } from './InputFieldUtils';
import { SearchInputField } from './SearchInputField';
import CustomCircleLoader from '../CustomLoader/CustomCircleLoader';

interface CustomSpecialityWithProfessionalsProps {
    setPayload?: any;
    attendeeTypeId?: number;
    toggleModal?: any;
    open: boolean;
    setAttendesName: any;
    professionalIds?: any;
    setProfessionalIds?: any;
    loadingData?: any;
    workshopData?: any;
    checkedSpecialityIds?: any;
    setCheckedSpecialityIds?: any;
}

export const CustomSpecialityWithProfessionals: FC<CustomSpecialityWithProfessionalsProps> = (props) => {
    const {
        loadingData,
        professionalIds,
        setProfessionalIds,
        setAttendesName,
        open,
        toggleModal,
        attendeeTypeId,
        setPayload,
        workshopData,
    } = props;

    const {
        allIntervenants,
        intervenantSpecialities,
        optimizedIntervenantsBySpecId,
        fetchingProfessionalsBySpecialities,
    } = useProfessionals();

    const [checkedSpecialityIds, setCheckedSpecialityIds] = useState<number[]>([]);

    const intervenantsFromSpecFilter = optimizedIntervenantsBySpecId?.filter((spec) =>
        checkedSpecialityIds?.includes(spec?.id),
    );

    const flatMapedInternantsFromSpecFilter = intervenantsFromSpecFilter?.flatMap((item: any) => item.items);

    const intervenants = checkedSpecialityIds?.length > 0 ? flatMapedInternantsFromSpecFilter : allIntervenants;

    const names = Array.from(optimizedIntervenantsBySpecId, (speciality) => speciality.items);
    const allIntervenantsGroupeName = intervenants?.map((item) => ({
        id: item?.id,
        name: fullName(item?.firstName, item?.lastName),
    }));

    function handleSetPayloadOnly() {
        const attendees = Array.from(uniqArrayNumberValues(professionalIds), (id) => ({
            id: id,
            type: attendeeTypeId,
        }));
        setPayload((old: any) => ({
            ...old,
            attendees,
        }));
    }

    const handleValidate = () => {
        const checkedProfessionalNames = names?.flat(1)?.filter((item) => professionalIds.includes(item.id));
        setAttendesName(checkedProfessionalNames);
        handleSetPayloadOnly();
        toggleModal();
    };

    const handleSetAttendees = () => {
        if (workshopData?.attendees?.length > 0) {
            const attendees = workshopData?.attendees;
            const precheckedIds = Array.from(attendees, (item: any) => item.id);
            const precheckedNames =
                Array.from(attendees, (item: any) => ({
                    firstName: item.firstName,
                    lastName: item.lastName,
                    id: item.id,
                })) ?? [];
            const precheckedSpecialityIds = Array.from(attendees, (item: any) => item.specialityId);
            setProfessionalIds(precheckedIds);
            setAttendesName(uniqByKeyValues({ items: precheckedNames }));
            setCheckedSpecialityIds(precheckedSpecialityIds);
        }
    };

    useEffect(() => {
        handleSetAttendees();
    }, [workshopData]);

    useEffect(() => {
        if (workshopData?.id) {
            handleSetPayloadOnly();
        }
    }, [professionalIds, workshopData?.id]);

    const [query, setQuery] = useState<string>('');
    const handleSearch = (value: string) => setQuery(value);
    const [searchedItems, setSearchedItems] = useState<Array<any>>([]);

    useEffect(() => {
        setSearchedItems(searchElementByName(allIntervenantsGroupeName ?? [], query, 'name'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query]);

    const finalData = query?.trim()?.length > 0 ? searchedItems : allIntervenantsGroupeName;

    const filterItems: any = intervenantSpecialities;

    return (
        <Grid container alignItems="center" direction="row" justifyContent="space-between">
            {!professionalIds?.length && (
                <Grid
                    onClick={fetchingProfessionalsBySpecialities ? undefined : toggleModal}
                    item
                    style={{
                        cursor: 'pointer',
                        backgroundColor: baseColors.green.light,
                        padding: 12,
                        width: '100%',
                        borderRadius: 10,
                    }}
                >
                    {fetchingProfessionalsBySpecialities ? (
                        loadingData
                    ) : (
                        <CustomText
                            color={baseColors.green.primary}
                            fontSize={baseFontSize.mini}
                            fontFamily={baseFontFamily.OpenSansSemiBold}
                        >
                            Sélectionner un ou plusieurs intervenant(s)
                        </CustomText>
                    )}
                </Grid>
            )}

            <CustomModal
                //disableBackdropClick
                width={600}
                height={baseScreenHeight.newEventModal}
                title="Sélectionner un ou plusieurs intervenant(s)"
                open={open}
                toggleModal={toggleModal}
                footer={
                    <Grid container style={{ marginTop: 30 }} justifyContent="center">
                        <CustomLoadingButton
                            width="100%"
                            /*disabled={Object.keys(professionalIds).length === 0}*/
                            label="Valider"
                            handleClick={handleValidate}
                        />
                    </Grid>
                }
            >
                <Box style={{ alignSelf: 'flex-start', marginBottom: 5 }}>
                    <SearchInputField
                        withFilter
                        value={query}
                        filterTitle="Filtrer par spécialité"
                        placeholder="Rechercher par nom"
                        onChange={(value: string) => {
                            setQuery(value);
                            handleSearch?.(value);
                        }}
                        filterItems={filterItems}
                        selectedFilterIds={checkedSpecialityIds}
                        setSelectedFilterIds={setCheckedSpecialityIds}
                    />

                    {fetchingProfessionalsBySpecialities ? (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                margin: 50,
                            }}
                        >
                            <CustomCircleLoader height={50} width={50} />
                        </div>
                    ) : (
                        finalData?.map((professional: any) => (
                            <Box key={professional.id} style={{}}>
                                <CustomCheckBox
                                    onClick={handleCheckIntervenantByItem({
                                        setProfessionalIds,
                                        professionalId: professional.id,
                                    })}
                                    checked={professionalIds?.includes(professional.id)}
                                    label={professional?.name}
                                />
                            </Box>
                        ))
                    )}
                </Box>
            </CustomModal>
        </Grid>
    );
};
