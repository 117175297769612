import moment from 'moment';
import { baseColors, planningEventColors } from 'src/utils/constants/baseStyles';
import {
    addZeroBeforeNumber,
    checkIncludedValue,
    dateWithoutTimeZone,
    isOdd,
    timeWithoutTimeZone,
    transformDayNameToFrench,
} from 'src/utils/utils';

const addZeroToTime = (time: number) => ((time + '').length === 3 ? `0${time}` : `${time}`);

const addThirtyToTime = (time: string) => {
    const convertedTime = +time + 30;
    return addZeroToTime(convertedTime);
};

const calculateEndTime = (hour: string) => {
    const endTime: string = String(addZeroToTime(+hour + 30));
    const firstTwoDigit = Number(endTime.slice(0, 2));
    const lastTwoDigit = Number(endTime.slice(-2));
    const finalEndTime = (lastTwoDigit > 59 ? firstTwoDigit + 1 : firstTwoDigit) * 100;

    return finalEndTime;
};

const filteredSpecialistData = (defaultHours: any, specialistArray: any) =>
    defaultHours &&
    defaultHours.length > 0 &&
    defaultHours.map((hour: any) => {
        const emptyHourData = {
            freeEvent: true,
            start: hour.value,
            end: addZeroToTime(calculateEndTime(hour.value)),
        };

        if (!specialistArray) {
            return emptyHourData;
        }
        const findedData = specialistArray.filter((spc: any) =>
            checkIncludedValue(+spc.timeStart, +spc.timeEnd, +hour.value),
        );

        if (findedData?.length > 0) {
            const takedHourData = { freeEvent: false, ...findedData[0] };
            return takedHourData;
        } else {
            return emptyHourData;
        }
    });

const checkIfItemExist = (dataArray: any, id: string) => {
    let newArray: any[] = [];

    dataArray &&
        dataArray.length > 0 &&
        dataArray.map((data: any) => {
            const checkExistance = newArray?.length > 0 && newArray.some((current) => current?.name === data?.name);

            if (!data?.freeEvent && checkExistance) {
                return newArray.push({ ...data, name: null });
            } else {
                return newArray.push(data);
            }
        });
    return newArray;
};

const dataFiltered = (data: any, defaultHours: any) => {
    const result =
        data &&
        data.length > 0 &&
        data.map((specialistArray: any) => {
            const filtered = filteredSpecialistData(defaultHours, specialistArray);
            const finalArray = checkIfItemExist(filtered, specialistArray);
            return finalArray;
        });

    const finalResultData = result?.length > 0 && shareSpecialityId(result);

    return finalResultData ?? [];
};

const getHeaderInfosFromData = (data: any) =>
    data &&
    data.length > 0 &&
    data.map((header: any) => ({
        id: header?.id ?? 0,
        label: header?.name ?? transformDayNameToFrench(header?.dayDate),
        minWidth: 150,
        dayName: header?.dayName,
        dayDate: header?.dayDate,
    }));

const eventItemsData = (data: any) =>
    data && data.length > 0 && data.map((eventItem: any) => createNewPlanningItem(eventItem.eventItems));

const setEventTimeToUse = (event: any) => {
    const substractedTime = addZeroToTime(+timeWithoutTimeZone(event.endDate));
    const splitedEndDateTime = dateWithoutTimeZone(event.startDate).split(' ');

    const eventTime = {
        hour: substractedTime.slice(0, 2),
        minutes: `${substractedTime.slice(-2)}`,
    };

    const endTimeToUse: string = eventTime.hour + '' + eventTime.minutes;

    const endDateTime = {
        date: splitedEndDateTime[0],
        time: `${eventTime.hour}:${eventTime.minutes}:00`,
    };

    const endDateTimeToUse: string = `${endDateTime.date} ${endDateTime.time}`;

    return {
        endTimeToUse: endTimeToUse,
        endDateTimeToUse: endDateTimeToUse,
    };
};

const createNewPlanningItem = (eventItemData: any) => {
    const newEventItemDataFormat =
        eventItemData &&
        eventItemData.length > 0 &&
        eventItemData.map((event: any) => {
            const { endTimeToUse, endDateTimeToUse } = setEventTimeToUse(event);

            return {
                name:
                    !event?.eventType?.isConsulation ||
                    (event?.eventType?.isConsulation && event?.patients?.length === 0)
                        ? event.summary
                        : (event?.patient?.attendeeUser?.civility === 0 ? 'Mme ' : 'Mr ') +
                          ' ' +
                          event?.patient?.attendeeUser?.firstName +
                          ' ' +
                          event?.patient?.attendeeUser?.lastName,
                type: {
                    label: !event?.eventType?.isConsulation ? 'Atelier' : event?.eventType?.name,
                    typeName: !event?.eventType?.isConsulation ? 'atelier' : '',
                },
                timeStart: timeWithoutTimeZone(event.startDate),
                timeEnd: endTimeToUse,
                dayHospitalId: event?.dayHospital?.id ?? 0,
                eventId: event?.id ?? 0,
                patientId: event?.patient?.attendeeUser?.id ?? 0,
                duration: event?.duration ?? '00:00',
                specialityTypeId: event?.specialityType?.id ?? 0,
                startDate: dateWithoutTimeZone(event.startDate),
                endDate: endDateTimeToUse,
                informations: {
                    eventId: event?.id ?? 0,
                    eventType: event?.eventType?.name ?? '',
                    duration: event?.duration ?? '',
                    location: event?.location?.name ?? '',
                    patientCount: event?.eventType?.isConsulation ? event?.patients?.length : event?.presences?.length,
                    intervening: event?.professionals?.length ?? 0,
                    name: event.summary,
                },
                isConsultation: event?.eventType?.isConsulation,
                eventData: event,
            };
        });

    return newEventItemDataFormat ?? [];
};

const eventColor = (hdjList: any, eventDayHospitalId: number, isFontColor?: boolean) => {
    let color: {
        fontColor: string;
        backgroundColor: string;
    } = {
        fontColor: baseColors.black,
        backgroundColor: baseColors.green.light,
    };

    if (hdjList && hdjList.length > 0) {
        hdjList.map((hdj: any, i: number) => {
            if (hdj.id === eventDayHospitalId && isOdd(i)) {
                color.fontColor = planningEventColors.color.orange;
                color.backgroundColor = planningEventColors.backgroundColor.orange;
            }
            return color;
        });
    }
    return isFontColor ? color.fontColor : color.backgroundColor;
};

const shareSpecialityId = (result: any) => {
    const flatMappedData = result.map((arrayItem: any) => {
        const findedSpcId = arrayItem.find((item: any) => item?.name?.length > 0);
        return arrayItem.map((item: any) => ({
            ...item,
            specialityTypeId: findedSpcId?.specialityTypeId,
        }));
    });

    return flatMappedData;
};

const newEventConvertedTime = (timeValue: string, date: string) => {
    if (timeValue?.length > 0) {
        const refomatedTime = timeValue.split('');
        let firstPart: any = refomatedTime.slice(0, 2);
        let secondPart: any = refomatedTime.slice(2);
        if (+secondPart[0] === 6) {
            secondPart = 30;
            firstPart = +firstPart.join('') + 1;
            return `${date} ${firstPart}:${secondPart}:00`;
        } else {
            return `${date} ${firstPart.join('')}:${secondPart.join('')}:00`;
        }
    }
};

const differenceBetweenTimes = (start: string, end: string, editTimeValue: string) => {
    const difference = Number(end) - Number(start);
    let newTimeValue: number = 0;
    newTimeValue = Number(editTimeValue) + difference;
    const finalValue = String(newTimeValue)?.length < 4 ? `0${newTimeValue}` : String(newTimeValue);
    return finalValue;
};

const removeUndefined = (value: string) => {
    const splittedValue = value.split(' ');
    const filteredValue = splittedValue?.filter((item: string) => item !== 'undefined' || item !== undefined);
    const newValue = filteredValue.join(' ');
    return newValue;
};

const transformeTimeFormat = (time: string) => {
    const first = time.split('');
    const second = time.split('');
    const firstPart = first.splice(0, 2).join('');
    const secondPart = second.splice(2, 3).join('');
    const timeValue = `${firstPart}:${secondPart}`;

    return timeValue;
};

const setCurrentData = (eventData: any, valueToSet: any) => {
    const { setCurrentEventData, setCurrentEventSpecialityId, setCurrentEventStartAt, setCurrentEventEndAt } =
        valueToSet;

    setCurrentEventData(eventData?.eventId);
    setCurrentEventSpecialityId(eventData?.specialityTypeId);
    setCurrentEventStartAt(eventData?.timeStart);
    setCurrentEventEndAt(eventData?.timeEnd);
};

const handleRemoveMidnightTime = ({ eventData, hoursValues }: { eventData: any[]; hoursValues: any }) => {
    if (eventData?.length > 0) {
        const filtered = eventData?.map((item: any) => {
            const eventItems = item?.eventItems?.map((event: any) => {
                const start = moment(event.startDate).format('HHmm');
                const startPlage = +hoursValues?.[0]?.value;
                const endPlage = +hoursValues?.[hoursValues?.length - 1]?.value;
                const endPlageHour = `${addZeroBeforeNumber(+endPlage)}`.slice(0, 2);
                const defaultStartHour = +endPlageHour - 1;

                if (start === '0000' || +start < startPlage || +start > endPlage) {
                    const newEvent = {
                        ...event,
                        startDate: moment(event.startDate)
                            .set('hour', +defaultStartHour || 17)
                            .set('minute', 30)
                            .format(),
                        endDate: moment(event.endDate).set('hour', +endPlageHour).format(),
                        outOfPlage: true,
                    };
                    return newEvent;
                }
                return event;
            });
            return { ...item, eventItems: eventItems };
        });

        return filtered;
    }
};

export {
    addZeroToTime,
    addThirtyToTime,
    filteredSpecialistData,
    checkIfItemExist,
    dataFiltered,
    getHeaderInfosFromData,
    eventItemsData,
    createNewPlanningItem,
    removeUndefined,
    newEventConvertedTime,
    shareSpecialityId,
    eventColor,
    transformeTimeFormat,
    differenceBetweenTimes,
    setCurrentData,
    handleRemoveMidnightTime,
};
