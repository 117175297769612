import { FC, useContext, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import DurationItem from './DurationItem';
import { defaultTimeFilters } from '../FilterLogic';
import { baseColors, baseFontSize } from 'src/utils/constants/baseStyles';
import DurationInterval from './DurationInterval';
import { AgendaContext } from 'src/providers/AgendaProvider';

const DurationContent: FC = () => {
    const { setDuration } = useContext(AgendaContext);
    const [startTime, setStartTime] = useState<number>(0);
    const [endTime, setEndTime] = useState<number>(0);
    const [hasError, setHasError] = useState(false);
    const [selectedDuration, setSelectedDuration] = useState<number>(0);
    const clearInput = () => {
        setStartTime(0);
        setEndTime(0);
    };
    const checTimeValidity = () => {
        if (startTime === 0 && endTime === 0) {
            setHasError(false);
            setDuration([[]]);
        } else {
            setDuration([startTime, endTime]);
        }
    };

    useEffect(() => {
        if (endTime > 0 || (startTime === 0 && endTime === 0)) {
            checTimeValidity();
        }
        if (startTime > 0 || endTime > 0) {
            setSelectedDuration(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startTime, endTime]);

    return (
        <Box
            sx={{
                width: '107%',
                borderRadius: 2,
                backgroundColor: baseColors.orange.light,
                paddingTop: 3,
                marginBottom: 2,
                border: `2px solid ${baseColors.green.pastel}`,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-evenly',
                    flexWrap: 'wrap',
                }}
            >
                {defaultTimeFilters.map((timeFilter) => (
                    <DurationItem
                        key={timeFilter.id}
                        selectedDuration={selectedDuration}
                        setSelectedDuration={setSelectedDuration}
                        time={timeFilter}
                        clearInput={clearInput}
                    />
                ))}
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-evenly',
                    flexWrap: 'wrap',
                    padding: 1,
                    marginTop: 1,
                }}
            >
                <DurationInterval value={startTime} setTimeValue={setStartTime} label="Entre" />
                <DurationInterval
                    isEndTime
                    startTime={startTime}
                    value={endTime}
                    setTimeValue={setEndTime}
                    hasError={hasError}
                    setHasError={setHasError}
                    label="et"
                />
            </Box>
            {hasError && (
                <p
                    style={{
                        color: 'red',
                        fontSize: baseFontSize.sm,
                        textAlign: 'center',
                        fontStyle: 'italic',
                        marginTop: -5,
                    }}
                >
                    Intervalle de temps incorrect
                </p>
            )}
        </Box>
    );
};

export default DurationContent;
