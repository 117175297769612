import { makeStyles } from '@mui/styles';
import { baseColors } from '../../utils/constants/baseStyles';

const useStyles = makeStyles({
    container: {
        borderRadius: 10,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '10px 0 10px 10px',
        marginBottom: 10,
        cursor: 'pointer'
    },
    left: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    icon: {
        marginBottom: 18,
    },
    number: {
        color: baseColors.white,
    },
    label: {
        color: baseColors.white,
        display: 'flex',
        alignItems: 'center',
    },
    time: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    title: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 20,
    },
});

export default useStyles;
