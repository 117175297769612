import { FC, useContext, useEffect, useState } from 'react';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import useStyles from './styles';
import { ReportItem } from 'src/components/ReportItem/ReportItem';
import { getReports, getReportsPdf } from 'src/services/Patients';
import { ReportForm } from './ReportForm';
import CustomLoader from 'src/components/CustomLoader/CustomLoader';
import { toast } from 'react-toastify';
import { CustomText } from 'src/components/Text/CustomText';
import { ReportDetails } from './ReportDetails';
import { Patient } from 'src/interfaces/interfaces';
import { baseColors } from 'src/utils/constants/baseStyles';
import { PlateformTypeContext } from 'src/providers/PlateformTypeProvider';

interface ObservationsProps {
    patientId: number;
    patientName?: string;
    diagEducId: number;
    infoPatient?: Patient;
}

export const ReportList: FC<ObservationsProps> = (props) => {
    const classes = useStyles();
    const { patientId, infoPatient, diagEducId } = props;
    const idPatient = patientId;
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [reports, setReports] = useState<any[]>([]);
    const [view, setView] = useState('liste');
    const [reportData, setReportData] = useState(null);
    const { isPlateformCpts } = useContext(PlateformTypeContext);

    const fetchReports = () => {
        setLoading(true);
        getReports(idPatient)
            .then((response) => {
                const reportsData = response.data.data;
                if (reports) {
                    setReports(reportsData);
                }
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };

    const detail = (data: any) => {
        setReportData(data);
        setOpen(true);
    };

    const exportPdf = (data: any) => {
        getReportsPdf(data.id)
            .then((response) => {
                if (response?.data?.data) {
                    const linkSource = `data:application/pdf;base64,${response.data.data}`;
                    const downloadLink = document.createElement('a');
                    const fileName = 'application.pdf';
                    downloadLink.href = linkSource;
                    downloadLink.download = fileName;
                    downloadLink.click();
                    toast.success('Compte rendu exportée avec succès.');
                } else {
                    toast.success('Pas de données à exporter.');
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };

    useEffect(() => {
        fetchReports();
    }, [idPatient]);

    return (
        <div className={classes.container}>
            {view === 'liste' ? (
                <div style={{ marginTop: 20, overflow: 'auto' }}>
                    {isPlateformCpts && (
                        <div>
                            {infoPatient?.enteredHealthProfessionals ? (
                                <CustomText
                                    style={{
                                        backgroundColor: baseColors.green.light,
                                        color: baseColors.green.primary,
                                        padding: 13,
                                        borderRadius: 13,
                                        marginTop: 10,
                                        marginBottom: 10,
                                    }}
                                >
                                    {infoPatient?.enteredHealthProfessionals}
                                </CustomText>
                            ) : (
                                <CustomText
                                    style={{
                                        backgroundColor: baseColors.green.light,
                                        color: baseColors.green.primary,
                                        padding: 13,
                                        borderRadius: 13,
                                        fontSize: 13,
                                        marginTop: 10,
                                        marginBottom: 10,
                                    }}
                                >
                                    Professionel de santé mentionné vide !!
                                </CustomText>
                            )}
                        </div>
                    )}
                    {loading ? (
                        <div style={{ width: '100%', alignContent: 'center' }}>
                            <CustomLoader IsclassicList />
                        </div>
                    ) : reports ? (
                        reports.map((item) => (
                            <ReportItem
                                onPress={() => {
                                    detail(item);
                                }}
                                handlePress={() => exportPdf(item)}
                                title={item.name}
                            />
                        ))
                    ) : (
                        <CustomText style={{ textAlign: 'center', marginTop: '5%', marginBottom: '10%' }} fontSize={15}>
                            Aucun compte rendu trouvé.
                        </CustomText>
                    )}

                    <div className={classes.flexCenter} style={{ marginTop: 60 }}>
                        <CustomLoadingButton
                            //disabled={diagEducId === 0}
                            label="Ajouter un compte rendu"
                            handleClick={() => setView('addReport')}
                        />
                    </div>
                    <ReportDetails patientId={patientId} open={open} reportData={reportData} setOpen={setOpen} />
                </div>
            ) : view === 'addReport' ? (
                <ReportForm diagEducId={diagEducId} />
            ) : (
                <></>
            )}
        </div>
    );
};
