import { 
  RETRIEVE_PROGRAMS
} from "src/actions/program/types";

const initialState: any = [];

const programReducer = (plannings = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case RETRIEVE_PROGRAMS:
      return payload;

    default:
      return plannings;
  }
};

export {
  programReducer,
};