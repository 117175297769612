import { FC, useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import { Box } from '@mui/system';
import UserService from 'src/services/User/user.service';
import { fullName } from 'src/utils/utils';
import { baseColors, baseFontFamily, baseFontSize } from 'src/utils/constants/baseStyles';
import CustomCircleLoader from 'src/components/CustomLoader/CustomCircleLoader';
import IntervenantList from '../PlanningFilters/IntervenantList';
import { CustomText } from 'src/components/Text/CustomText';
import sxStyles from './styles';
import AgendaSettingsService from 'src/services/AgendaSettings';
import { PostShareAgenda } from 'src/interfaces/interfaces';
import { toast } from 'react-toastify';
import { OperationMessage } from 'src/utils/constants/constants';

interface ShareMyAgendaProps {
    myId?: number;
}

const ShareMyAgenda: FC<ShareMyAgendaProps> = (props) => {
    const { myId } = props;
    const [loading, setLoading] = useState<boolean>(false);
    const [posting, setPosting] = useState<boolean>(false);
    const [caregivers, setCaregivers] = useState<Array<any>>([]);
    const [selectedIntervenants, setSelectedIntervenants] = useState([]);
    const [openIntervenantModal, setOpenIntervenantModal] = useState<boolean>(false);
    const toggleIntervenantModal = () => setOpenIntervenantModal(!openIntervenantModal);

    const handleShareNyAgenda = (checkedUsers: number[]) => {
        if (checkedUsers?.length > 0) {
            setPosting(true);
            const payload: PostShareAgenda = { receiverIds: checkedUsers };
            AgendaSettingsService.postShareAgenda(payload)
                .then(() => {
                    setPosting(false);
                    toast.success(OperationMessage.success);
                    toggleIntervenantModal();
                })
                .catch(() => {
                    toast.error(OperationMessage.error);
                    setPosting(false);
                });
        } else {
            toast.warning(OperationMessage.warning);
        }
    };

    const handleFetchCaregivers = (query: string) => {
        setLoading(true);
        UserService.getDoctorUsers(query)
            .then((res: any) => {
                setCaregivers(res?.data?.data);
                setLoading(false);
            })
            .catch(() => setLoading(false));
    };

    useEffect(() => {
        handleFetchCaregivers('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [intervenants, setIntervenants] = useState<Array<any>>([]);

    const handleFetchIntervenants = () => {
        setLoading(true);
        UserService.getDoctorUsers()
            .then((response) => {
                const res = response?.data?.data;
                const filtered = res?.filter((doctor: any) => Boolean(doctor.hasEtp));
                const optimizedData = Array.from(filtered ?? [], (item: any) => ({
                    id: item?.id,
                    name: fullName(item?.lastName, item?.firstName),
                }));

                setIntervenants(optimizedData);
                setLoading(false);
            })
            .catch(() => setLoading(false));
    };

    useEffect(() => {
        handleFetchIntervenants();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const dataToUse =
        myId && myId > 0 && intervenants?.length ? intervenants.filter((item) => item.id !== myId) : intervenants;

    return (
        <Stack spacing={2} sx={{ width: 200 }}>
            <IntervenantList
                posting={posting}
                toggleModal={toggleIntervenantModal}
                modalOpen={openIntervenantModal}
                intervenants={dataToUse}
                selectedIntervenants={selectedIntervenants}
                setSelectedIntervenants={setSelectedIntervenants}
                handleFetchCaregivers={handleFetchCaregivers}
                loading={loading}
                title="Partager votre agenda avec"
                btnLabel="Partager"
                handleShareNyAgenda={handleShareNyAgenda}
                isSharingAgenda
            />

            {loading ? (
                <CustomCircleLoader height={25} width={25} />
            ) : (
                <Box sx={sxStyles.container} onClick={toggleIntervenantModal} id="share-agenda">
                    <CustomText
                        style={{
                            fontSize: baseFontSize.sm,
                            color: baseColors.green.primary,
                            textAlign: 'center',
                            fontFamily: baseFontFamily.OpenSansSemiBold,
                        }}
                    >
                        Partage d'agenda
                    </CustomText>
                </Box>
            )}
        </Stack>
    );
};

export default ShareMyAgenda;
