import * as React from 'react';
import { baseColors } from 'src/utils/constants/baseStyles';

function AgendaVisioDetails(props: any) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} fill="none" viewBox="0 0 40 40" {...props}>
            <rect
                width={40}
                height={40}
                fill={props?.isGreen ? baseColors.green.primary : baseColors.orange.primary}
                rx={6}
            />
            <path
                fill="#fff"
                d="M21.313 14.125h-9.125A2.195 2.195 0 0010 16.313v7.374c0 1.204.985 2.188 2.188 2.188h9.125a2.195 2.195 0 002.188-2.188v-7.374a2.18 2.18 0 00-2.188-2.188zm7.067 1.203a1.114 1.114 0 00-.371.153l-3.414 1.97v5.076l3.435 1.97a1.3 1.3 0 001.795-.482 1.33 1.33 0 00.175-.656v-6.762c0-.81-.766-1.466-1.62-1.269z"
            />
        </svg>
    );
}

export default AgendaVisioDetails;
