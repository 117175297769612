import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FC } from 'react';
import { truncateString } from 'src/utils/helpers/helpers';
import { baseColors, baseFontFamily } from '../../utils/constants/baseStyles';

interface CustomBadgeProps {
    title: string;
    maxLength?: number;
    colors?: any;
    fontSize?: number;
    isBold?: boolean;
}

export const CustomBadge: FC<CustomBadgeProps> = (props) => {
    const { title, maxLength, colors, fontSize, isBold } = props;
    const useStyles = makeStyles({
        container: {
            display: 'flex',
            paddingLeft: 20,
            marginBottom: 10,
            marginLeft: 10,
            paddingRight: 20,
            paddingBottom: 10,
            paddingTop: 10,
            justifyContent: 'center',
            background: colors ? colors.background : baseColors.green.light,
            borderRadius: 20,
        },
    });
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <Typography
                color={colors ? colors.fontColor : baseColors.green.primary}
                fontSize={fontSize ?? 11}
                fontFamily={baseFontFamily.OpenSansSemiBold}
                fontWeight={isBold ? 700 : undefined}
            >
                {truncateString(title, maxLength ? maxLength : 20)}
            </Typography>
        </div>
    );
};
