import { FC, useContext, useEffect, useState } from 'react';
import useStyles from './styles';
import { DESyntheseHeader } from './DESyntheseHeader';
import { Patient } from 'src/interfaces/interfaces';
import { DESyntheseConclusionList } from './DESyntheseConclusionList/DESyntheseConclusionList';
import { ConclusionForm } from '../../Conclusion/ConclusionForm';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import { useFetchUserPrograms } from 'src/hooks/useFetchUserPrograms';
import { PatientContext } from 'src/providers/PatientProvider';
import { useConclusionRQHooks, useDraftConclusionRQHooks } from 'src/hooks/ReactQueryHooks/useConclusionRQHooks';

interface DESyntheseProps {
    patient: Patient;
    educationalDiagnosisId: number;
}

const DESynthese: FC<DESyntheseProps> = ({ patient, educationalDiagnosisId }) => {
    const classes = useStyles();
    const [showForm, setShowForm] = useState(false);
    const [updateList, setUpdateList] = useState(false);
    const toggleForm = () => setShowForm(!showForm);
    const { selectedPatient } = useContext(PatientContext);

    const patientId = selectedPatient?.id ?? 0;

    const { preSelectedPatientProgram, fetchPreselectedProgram, fetchingPreselectedProgram } = useFetchUserPrograms({
        userId: patientId,
    });

    const {
        data: conclusions,
        isLoading: isLoadingConclusions,
        isFetching: isFetchingConclusions,
        refetch: refetchConclusions,
    } = useConclusionRQHooks({
        patientId,
    });

    const {
        data: draftConclusion,
        isLoading: isLoadingDraftConclusions,
        isFetching: isFetchingDraftConclusion,
        refetch: refetchDraftConclusions,
    } = useDraftConclusionRQHooks({
        patientId,
    });

    useEffect(() => {
        fetchPreselectedProgram();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patientId]);

    useEffect(() => {
        if (draftConclusion?.id > 0) {
            setShowForm(true);
        }
    }, [draftConclusion]);

    useEffect(() => {
        refetchConclusions();
        refetchDraftConclusions();
    }, [updateList]);

    const loading =
        isLoadingConclusions || isFetchingConclusions || isLoadingDraftConclusions || isFetchingDraftConclusion;
    const containsNullAccepted = conclusions?.some((item: any) => item.isAccepted === null);
    const showBtn = !loading;
    const disabled = containsNullAccepted || educationalDiagnosisId === 0;

    return (
        <div className={classes.container}>
            {showForm ? (
                <ConclusionForm
                    fetchingPreselectedProgram={fetchingPreselectedProgram}
                    draftConclusion={draftConclusion}
                    preSelectedPatientProgram={preSelectedPatientProgram}
                    setShowForm={setShowForm}
                    educationalDiagnosisId={educationalDiagnosisId}
                    setUpdateList={setUpdateList}
                />
            ) : (
                <>
                    <DESyntheseHeader patientId={patientId} />
                    <DESyntheseConclusionList
                        loading={loading}
                        conclusions={conclusions}
                        patientId={patientId}
                        diagEducId={educationalDiagnosisId}
                        updateList={updateList}
                    />
                    {showBtn && (
                        <div className={classes.flexCenter}>
                            <CustomLoadingButton
                                disabled={disabled}
                                label="Ajouter une conclusion"
                                handleClick={toggleForm}
                            />
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default DESynthese;
