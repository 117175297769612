import { makeStyles } from '@mui/styles';
import { baseColors } from 'src/utils/constants/baseStyles';

const useStyles = makeStyles({
    container: {
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: baseColors.green.light,
        borderRadius: 7,
        padding: 7,
        marginBottom: 15,
        justifyContent: 'space-between'
    },
    label:{
        paddingLeft: 10,
    },
});

export default useStyles;
