import React, { FC, useEffect, useState } from 'react';
import { LineChart } from 'src/components/Chart/Line/LineChart';
import { PieChart } from 'src/components/Chart/Pie/PieChart';
import CustomCircleLoader from 'src/components/CustomLoader/CustomCircleLoader';
import { Spacer } from 'src/components/Spacer/Spacer';
import { ChartData } from 'src/interfaces/interfaces';
import { getGoalDoneDetails, getGoalStatistics } from 'src/services/Patients';
import { getMonthAndYear } from 'src/utils/utils';
import useStyles from '../styles';
import { CustomText } from 'src/components/Text/CustomText';
import { baseFontFamily } from 'src/utils/constants/baseStyles';
import EvaluationObjectifs from './EvaluationObjectifs';
import useConstantHooks from 'src/hooks/useConstantHooks';
import { BarChart } from 'src/components/Chart/Bar/BarChart';

interface EvaluationGoalChartProps {
    patientId: number;
    selectedAxis: number;
}

const EvaluationGoalChart: FC<EvaluationGoalChartProps> = (props) => {
    const { patientId, selectedAxis } = props;
    const classes = useStyles();
    const [loadingDistances, setLoadingDistances] = useState<boolean>(false);
    const [loadingDurations, setLoadingDurations] = useState<boolean>(false);

    const { fetchConstants, setSelectedConstant, fetchingChartData, chartData, selectedConstant, constantNames } =
        useConstantHooks();
    const [goalStatistics, setGoalStatistics] = useState<any>({
        inProgress: {},
        done: {},
    });
    const [goalDoneDetails, setGoalDoneDetails] = useState<ChartData>({
        xData: [],
        yData: [],
    });

    const handleFetchChartData = async () => {
        setLoadingDistances(true);
        setLoadingDurations(true);

        await fetchConstants();

        await getGoalStatistics({ patientId: patientId, axisId: selectedAxis })
            .then((response) => {
                if (response?.data?.data) {
                    const chartData = response?.data?.data;
                    setGoalStatistics({
                        series: [chartData.done, chartData.progress],
                        labels: ['Termine', 'En cours'],
                    });
                    setLoadingDistances(false);
                }
            })
            .catch(() => {
                setLoadingDistances(false);
            });

        await getGoalDoneDetails({ patientId })
            .then((response) => {
                if (response?.data?.data) {
                    const chartData = response?.data?.data;
                    const durationsDataOptimized: ChartData = {
                        xData: Array.from(chartData?.categories, (date: string) => getMonthAndYear(date)),
                        yData: chartData?.data,
                    };
                    setGoalDoneDetails(durationsDataOptimized);
                    setLoadingDurations(false);
                }
            })
            .catch(() => {
                setLoadingDurations(false);
            });
    };

    useEffect(() => {
        handleFetchChartData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patientId]);

    const isLoading: boolean = loadingDistances || loadingDurations;
    const series = chartData?.find((constant) => constant?.id === selectedConstant.id);
    const useBarChart = series?.useBarChart;

    return (
        <>
            {isLoading ? (
                <div className={classes.loading}>
                    <CustomCircleLoader />
                </div>
            ) : (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}
                >
                    <EvaluationObjectifs />

                    <div
                        style={{
                            display: 'flex',
                            flex: 0.8 / 2,
                            flexDirection: 'column',
                            marginLeft: 10,
                            height: 'calc(100vh - 350px)',
                            overflowY: 'scroll',
                            width: '100%',
                        }}
                    >
                        <CustomText
                            style={{ textAlign: 'center', marginBottom: 25 }}
                            fontFamily={baseFontFamily.OpenSansSemiBold}
                        >
                            Progression par type d'objectif :
                        </CustomText>

                        <Spacer height={40} />

                        <PieChart withoutLabel data={goalStatistics} />

                        <div style={{ marginBottom: 40 }} />

                        <div
                            style={{
                                display: 'flex',
                                flex: 1 / 2,
                            }}
                        >
                            {fetchingChartData ? (
                                <div style={{ paddingTop: 55 }} className={classes.loading}>
                                    <CustomCircleLoader height={50} width={50} />
                                </div>
                            ) : useBarChart ? (
                                <BarChart
                                    isGoal
                                    selectedConstant={selectedConstant}
                                    setSelectedConstant={setSelectedConstant}
                                    constantNames={constantNames}
                                    data={series?.series ?? []}
                                />
                            ) : (
                                <LineChart
                                    isGoal
                                    selectedConstant={selectedConstant}
                                    setSelectedConstant={setSelectedConstant}
                                    constantNames={constantNames}
                                    isEvaluation
                                    withoutLabel
                                    data={series?.series ?? []}
                                />
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default EvaluationGoalChart;
