import { Box } from '@mui/system';
import { FC } from 'react';
import ContentLoader from 'react-content-loader';
import { baseColors } from 'src/utils/constants/baseStyles';

interface PatientPageLoaderProps {
    loadingNumber?: number;
}

const PatientPageLoader: FC<PatientPageLoaderProps> = (props) => {
    const { loadingNumber } = props;

    const itemLoad = (key: number) => (
        <ContentLoader
            key={key}
            backgroundColor={baseColors.green.light}
            foregroundColor={baseColors.green.secondary}
            viewBox="0 0 340 228"
        >
            <rect x="10" y="10" rx="50" ry="50" width="40" height="40" />
            <rect x="65" y="22" rx="4" ry="4" width="270" height="8" />
            <rect x="65" y="32" rx="3" ry="3" width="270" height="8" />
            <rect x="10" y="55" rx="3" ry="3" width="320" height="160" />
        </ContentLoader>
    );

    const listLoader = Array.from(Array(loadingNumber ?? 1).keys());
    const itemToRender = (key: number) => itemLoad(key);

    return (
        <Box sx={{ backgroundColor: baseColors.white, paddingBottom: 10 }}>
            {listLoader.map((i: number) => itemToRender(i))}
        </Box>
    );
};

export default PatientPageLoader;
