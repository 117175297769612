import { CustomText } from 'src/components/Text/CustomText';
import { baseColors, baseFontFamily, baseFontSize } from 'src/utils/constants/baseStyles';
import useStyles from './styles';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import { UserContext } from 'src/providers/UserProvider';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { PostNotificationConfigurations } from 'src/interfaces/interfaces';
import GeneralSettingService from 'src/services/GeneralSettings';
import { OperationMessage } from 'src/utils/constants/constants';
import { toast } from 'react-toastify';
import SubMenusLoader from 'src/components/SettingsSubMenuBar/SubMenusLoader/SubMenusLoader';

const GeneralSettings = () => {
    const classes = useStyles();
    const { userInfos } = useContext(UserContext);
    const [posting, setPosting] = useState<boolean>(false);
    const [fetching, setFetching] = useState<boolean>(false);
    const [disable, setDisable] = useState<boolean>(true);
    const [time, setTime] = useState<number>(0);
    const [existedConfig, setExistedConfig] = useState<number>(0);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => setTime(+event.target.value);
    const handleFetchNotificationConfigurations = () => {
        setFetching(true);
        GeneralSettingService.getNotificationConfigurations()
            .then((res) => {
                const response = res?.data?.data;
                setExistedConfig(response?.maxLastLoginDuration);
                setFetching(false);
            })
            .catch(() => {
                setFetching(false);
            });
    };

    const handlePost = () => {
        const payload: PostNotificationConfigurations = {
            maxLastLoginDuration: time,
        };

        if (userInfos?.id > 0) {
            setPosting(true);
            GeneralSettingService.postNotificationConfigurations(payload)
                .then(() => {
                    setPosting(false);
                    toast.success(OperationMessage.success);
                })
                .catch(() => {
                    setPosting(false);
                    toast.error(OperationMessage.error);
                });
        }
    };

    useEffect(() => {
        handleFetchNotificationConfigurations();
    }, []);
    useEffect(() => {
        time === 0 ? (setDisable(true)):(setDisable(false))
    }, [time])
    

    return (
        <div className={classes.container}>
            <div className={classes.titleContainer}>
                <CustomText isBreefSerif isTitle color={baseColors.green.primary} style={{ marginLeft: 20 }}>
                    Paramétrage
                </CustomText>
            </div>

            <div className={classes.description}>
                <CustomText fontSize={baseFontSize.sm} fontFamily={baseFontFamily.OpenSansRegular}>
                    Définir la période sur laquelle le soignant devrait recevoir une notification en cas d'absence d'un
                    patient.
                </CustomText>
            </div>

            {fetching ? (
                <SubMenusLoader />
            ) : (
                <div className={classes.content}>
                    <div className={classes.duration}>
                        <p className={classes.label}>Durée : </p>
                    </div>

                    <div className={classes.timeContainer}>
                        <TextField
                            id="notifcation-time-reminder"
                            disabled={posting}
                            defaultValue={existedConfig}
                            type="number"
                            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
                            sx={{ m: 1, width: '20ch', marginRight: 20 }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">Jour(s)</InputAdornment>,
                                inputProps: { min: 0, max: 30 },
                            }}
                        />

                        <CustomLoadingButton
                            loading={posting}
                            disabled={disable}
                            label="Enregistrer"
                            handleClick={handlePost}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default GeneralSettings;
