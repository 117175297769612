
export enum FeatureActionType{
    LIST_FEATURES = 'LIST_FEATURES',
}

export interface FeatureList{
    type: FeatureActionType.LIST_FEATURES;
    payload: any;
}

export type FeatureAction = FeatureList;