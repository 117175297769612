import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { retrieveWorkshops } from 'src/actions/dayHospitals/management';
import CustomHdjCheckListContent from 'src/components/CustomHdjCheckListContent';
import useDebounce from 'src/hooks/useDebounce';
import { HDJConsultationAndWorkshopForm } from '../../HdjForm/HDJConsultationAndWorkshopForm';

interface HdjWorkshopProps {
    dayHospitalId?: number;
    fetchedHdjData?: any;
    hdj?:any
    setHdj?: any;
    disableCheckBox?: boolean;
    edit?: boolean;
    marginRight?: boolean;
    setSelectedWorkshop?: any;
    
}

const HdjWorkshop: FC<HdjWorkshopProps> = (props) => {
    const { dayHospitalId, fetchedHdjData, hdj, setHdj, disableCheckBox, edit, marginRight, setSelectedWorkshop } = props;
    const dispatch = useDispatch();
    const [loadingWorkshops, setLoadingWorkshops] = useState<boolean>(false);
    const [modalWorkshopVisible, setModalWorkshopVisible] = useState<boolean>(false);
    const [updateList, setUpdateList] = useState<boolean>(false);
    const [searchKey, setSearchKey] = useState<string>('');
    const defaultSelectedItems = fetchedHdjData?.workshops ?? [];
    const handleFetchData = (query: string) => {
        dispatch(retrieveWorkshops(setLoadingWorkshops, dayHospitalId ?? 0, query));
    };

    const { debouncedResults } = useDebounce(handleFetchData);

    useEffect(() => {
        handleFetchData('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateList]);

    const fetchedData = useSelector((state: any) => state?.managementWorkshops);
    const workshops = fetchedData?.data ?? [];

    const toggleModal = () => setModalWorkshopVisible(!modalWorkshopVisible);
    const handleSearch = (value: string) => {
        setSearchKey(value);
        debouncedResults(value);
    };

    const getSelectedWorkshop = () => {
        if(setSelectedWorkshop){
            const selectedWorkshop = workshops.filter((item:any)=> hdj?.workshopIds.includes(item.id));
            setSelectedWorkshop(selectedWorkshop);
        }
    }

    useEffect(()=>{
        getSelectedWorkshop();
    },[hdj?.workshopIds]);

    return (
        <>
            {/* <HDJWorkshopForm
                dayHospitalId={dayHospitalId}
                modalToggle={setModalWorkshopVisible}
                modalOpen={modalWorkshopVisible}
                setUpdateList={setUpdateList}
            /> */}

            <HDJConsultationAndWorkshopForm
                isWorkshop
                dayHospitalId={dayHospitalId}
                modalToggle={setModalWorkshopVisible}
                modalOpen={modalWorkshopVisible}
                setUpdateList={setUpdateList}
                validateBtnLabel="Valider l'atelier"
            />

            <CustomHdjCheckListContent
                disableCheckBox={disableCheckBox}
                edit={edit}
                loading={loadingWorkshops}
                items={workshops}
                selectedItems={defaultSelectedItems}
                label="Liste des ateliers"
                btnLabel="Créer nouvel atelier"
                toggleModal={toggleModal}
                handleSearch={handleSearch}
                setHdj={setHdj}
                isWorkshop
                withoutBtn
                marginRight
            />
        </>
    );
};

export default HdjWorkshop;
