import React, { FC, useEffect, useState } from 'react';
import { PieChart } from 'src/components/Chart/Pie/PieChart';
import CustomCircleLoader from 'src/components/CustomLoader/CustomCircleLoader';
import { getResponseStatistics } from 'src/services/Patients';
import useStyles from '../styles';

interface EvaluationQuizzProps {
    patientId: any;
}

const EvaluationQuizz: FC<EvaluationQuizzProps> = (props) => {
    const { patientId } = props;
    const classes = useStyles();
    const [loadingResponses, setLoadingResponses] = useState<boolean>(false);
    const [responseStatistics, setResponseStatistics] = useState<any>({
        series: [],
        labels: [],
    });

    const handleFetchChartData = () => {
        setLoadingResponses(true);
        getResponseStatistics(patientId)
            .then((response) => {
                if (response?.data?.data) {
                    const chartData = response?.data?.data;
                    setResponseStatistics({
                        series: [chartData.goodAnswers, chartData.badAnswers],
                        labels: ['Bonnes', 'Mauvaises'],
                    });
                    setLoadingResponses(false);
                }
            })
            .catch(() => {
                setLoadingResponses(false);
            });
    };

    useEffect(() => {
        handleFetchChartData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patientId]);

    return (
        <>
            {loadingResponses ? (
                <div className={classes.loading}>
                    <CustomCircleLoader />
                </div>
            ) : (
                <>
                    <PieChart data={responseStatistics} />
                </>
            )}
        </>
    );
};

export default EvaluationQuizz;
