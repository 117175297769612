import moment from 'moment';
import {
    AxisDiagEdu,
    IProgramFilter,
    CreateProgramThemeDiscussionPayload,
    CreateThemeDiscussionPayload,
    PostProgramSkillPayload,
} from 'src/interfaces/interfaces';
import axios from '../AxiosInstance';

const getPrograms = (searchKey?: string) => {
    let additionalUrl: string = '';

    if (searchKey) {
        additionalUrl += `?keywords=${searchKey}`;
    }

    return axios.get(`api/programs?paginated=false` + additionalUrl);
};

const getProgramsByPatientId = (idPatient?: number) => {
    return axios.get(`api/programs/patients/` + idPatient);
};

const getProgramById = (id: number) => {
    let additionalUrl: string = '';

    if (id > 0) {
        additionalUrl += id;
    }

    return axios.get(`api/programs/${additionalUrl}`);
};

const getProgramsFiltered = (filter: IProgramFilter) => {
    let state = `${filter?.state !== 0 ? 'programStateId=' + filter?.state : ''}`;
    let level = ``;
    let type = ``;
    let startDate = filter?.date.start ? moment(filter?.date.start).format('yyyy-MM-DD') : null;
    let endDate = filter?.date.end ? moment(filter?.date.end).format('yyyy-MM-DD') : null;
    const hasLevels = Boolean(filter?.level?.length > 1);
    const hasTypes = Boolean(filter?.type?.length > 1);

    if (hasLevels) {
        filter?.level.map((lvl, i) => (level += 'levelIds[]=' + lvl + (i !== filter?.level.length - 1 ? '&' : '')));
    }

    if (hasTypes) {
        filter?.type?.map((tp, i) => (type += 'programTypeIds[]=' + tp + (i !== filter?.type?.length - 1 ? '&' : '')));
    }

    const url = `api/programs?${state}${type !== '' ? '&' + type : ''}${level !== '' ? '&' + level : ''}${
        startDate ? '&startDate=' + startDate : ''
    }${endDate ? '&endDate=' + endDate : ''}`;
    return axios.get(url);
};

const createProgram = (data: any) => axios.post('api/programs', data);
const updateProgram = (data: any, id: number) => axios.put(`api/programs/${id}`, data);
const deleteProgram = (id: number) => axios.delete(`api/programs/${id}`);

const getProgramsActivity = (id: number) => {
    return axios.get(`api/programs/${id}/activite`);
};

const getProgramsAttendees = (id: number) => axios.get(`api/programs/${id}/intervenants`);

const addUserProgram = (programData: any) => axios.post(`api/programsTemp/users`, programData);

const postObjective = (data: any) => axios.post(`api/programObjectives`, data);
const getObjective = (id: number) => axios.get(`api/programs/${id}/objective`);
const putObjective = (id: number, data: any) => axios.put(`api/programObjectives/${id}`, data);

const postDispensation = (data: any) => axios.post(`api/programDispensations`, data);
const putDispensation = (id: number, data: any) => axios.put(`api/programDispensations/${id}`, data);
const getDispensation = (id: any) => axios.get(`api/programs/${id}/dispensation`);

const postEvaluation = (data: any) => axios.post(`api/programEvaluations`, data);
const putEvaluation = (id: number, data: any) => axios.put(`api/programEvaluations/${id}`, data);
const getEvaluation = (id: any) => axios.get(`api/programs/${id}/evaluation`);

const postProcess = (data: any) => axios.post(`api/deroulement`, data);
const putProcess = (id: number, data: any) => axios.put(`api/deroulement/${id}`, data);
const getProcess = (id: any) => axios.get(`api/programs/${id}/deroulement`);

const deleteDiscussion = (id: number) => axios.delete(`api/programDiscussions/${id}`);
const postDiscussion = (data: any) => axios.post(`api/programDiscussion`, data);
const postMails = (data: any) => axios.post(`api/medimails/mss`, data);
const getDiscussionThemes = (programId: number) => axios.get(`api/themes?programId=${programId}`);
const putDiscussion = ({ discussionId, payload }: { discussionId: number; payload: CreateThemeDiscussionPayload }) =>
    axios.put(`api/programDiscussions/${discussionId}`, { ...payload });

const getDiscussion = (programId: number, themeId?: number) => {
    let url = '';
    if (themeId) {
        url = `&themeId=${themeId}`;
    }
    return new Promise((resolve, reject) => {
        axios
            .get(`api/programDiscussions?programId=${programId + url}`)
            .then((response) => {
                const data = response.data?.data?.items;

                if (data && data.length > 0) {
                    let found = data.filter((d: any) => d.programId === programId);
                    if (found.length > 0) {
                        resolve(found);
                    } else {
                        resolve([]);
                    }
                } else {
                    resolve([]);
                }
            })
            .catch((e) => reject(e));
    });
};

const getProgramStatistic = (programId: number) => axios.get(`/api/programs/${programId}/statistics`);

const createDiscussionTheme = (payload: CreateProgramThemeDiscussionPayload) =>
    axios.post('api/themes', { ...payload });

const getProgramAxis = (patientId: number) => axios.get(`api/programsList?patientId=${patientId}`);

export const postQuest = (data: AxisDiagEdu) => axios.post('api/CaretoolDiageduAxis', { ...data });

const addSkillToProgram = ({ payload, programId }: { payload: PostProgramSkillPayload; programId: number }) =>
    axios.put(`api/addSkillsToProgram/${programId}`, { ...payload });

const GetProgramSkills = ({ programId }: { programId: number }) => axios.get(`/api/program/${programId}/skills`);

const GetSurveyParent = () => axios.get('api/surveyParent?paginated=false');

const ProgramService = {
    GetSurveyParent,
    GetProgramSkills,
    addSkillToProgram,
    getProgramAxis,
    getPrograms,
    getProgramsFiltered,
    getProgramById,
    getProgramsActivity,
    getProgramsAttendees,
    addUserProgram,
    createProgram,
    updateProgram,
    deleteProgram,
    getObjective,
    putObjective,
    postObjective,
    getDispensation,
    putDispensation,
    postDispensation,
    getEvaluation,
    putEvaluation,
    postEvaluation,
    postProcess,
    getProcess,
    putProcess,
    getDiscussion,
    postDiscussion,
    putDiscussion,
    deleteDiscussion,
    getProgramStatistic,
    postMails,
    getDiscussionThemes,
    createDiscussionTheme,
    getProgramsByPatientId,
};

export default ProgramService;
