import { Typography, Box, Divider, Checkbox } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { retrievePrograms } from 'src/actions/program/program';
import CustomCircleLoader from 'src/components/CustomLoader/CustomCircleLoader';
import { AgendaContext } from 'src/providers/AgendaProvider';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import { allOptions, checkValueExistance, handleAddAllValue, handleSearchItem } from '../FilterLogic';
import { specialCharToUnderscore } from 'src/utils/utils';
import { SearchInputField } from 'src/components/InputField/SearchInputField';
import CustomEmptyData from 'src/components/CustomEmptyData';
import { programsWithoutDraft } from 'src/views/Patients/PatientsDetails/Programs/AddProgam/AddProgramLogic';

const styles = {
    divider: {
        width: '80%',
        marginBottom: 10,
        background: baseColors.green.secondary,
    },
    item: {},
};

type ProgramContentType = {
    selected: number[];
    setSelected: Function;
    selectedItems: number[];
};

const ProgramContent: FC<ProgramContentType> = ({ selected, setSelected, selectedItems }) => {
    const dispatch = useDispatch();
    const [loadingProgram, setLoadingProgram] = useState<boolean>(false);
    const { setCheckedPrograms } = useContext(AgendaContext);
    const [filteredItems, setFilteredItems] = useState<Array<any>>([]);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const handleFetchData = () => dispatch(retrievePrograms(setLoadingProgram));

    useEffect(() => {
        handleFetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const programs = useSelector((state: any) => programsWithoutDraft(state?.programs?.data?.items ?? []));
    const handleClick = (programId: number) =>
        setSelected((prev: any) => checkValueExistance({ prev, programId, setCheckedPrograms, allItems: programs }));
    const hasSearchkey = searchQuery?.trim()?.length > 0;
    const combinedData = hasSearchkey
        ? [allOptions, ...filteredItems]
        : programs?.length > 0
        ? [allOptions, ...programs]
        : [];
    const showSecondTitle = selected?.includes(-1);

    useEffect(() => {
        if (selected?.length <= selectedItems?.length) {
            handleAddAllValue({ setSelected, selectedItems, combinedData });
        }
    }, [selected]);

    return (
        <>
            <SearchInputField
                onChange={(value: any) => {
                    setSearchQuery(value);
                    handleSearchItem({ items: programs, searchkey: value, setItems: setFilteredItems });
                }}
                placeholder="Rechercher par nom"
                value={searchQuery}
            />
            {loadingProgram ? (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 5 }}>
                    <CustomCircleLoader height={50} width={50} />
                </Box>
            ) : hasSearchkey && filteredItems?.length === 0 ? (
                <CustomEmptyData labelFontSize={12} marginTop={10} height={40} width={40} />
            ) : (
                programs?.length > 0 &&
                combinedData.map((program: any, i: number) => (
                    <Box key={program.id}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                paddingBottom: i === combinedData.length - 1 ? 5 : undefined,
                            }}
                        >
                            <Checkbox
                                onClick={() => handleClick(program.id)}
                                sx={{
                                    color: baseColors.green.primary,
                                    '&.Mui-checked': {
                                        color: baseColors.green.primary,
                                    },
                                }}
                                size="small"
                                checked={selected?.length > 0 && selected.includes(program.id)}
                                id={specialCharToUnderscore(program.title ?? '')}
                            />
                            <Typography
                                style={{
                                    fontFamily: baseFontFamily.OpenSansSemiBold,
                                }}
                                fontSize={12}
                                color={baseColors.black}
                                marginBottom={1}
                                marginTop={1}
                            >
                                {showSecondTitle && program.id === -1 ? program.secondTitle : program.title}
                            </Typography>
                        </Box>
                        {i === 0 && <Divider style={styles.divider} />}
                    </Box>
                ))
            )}
        </>
    );
};

export default ProgramContent;
