import { makeStyles } from '@mui/styles';
import { baseBorderRadius, baseColors, baseScreenHeight } from 'src/utils/constants/baseStyles';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',

        backgroundColor: baseColors.white,
        ...baseScreenHeight.defaultHeight,
        ...baseBorderRadius.allSide,
    },
    content: {
        padding: 20,
    },
});

export const sxStyles: any = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 10,
    },
    scrollContainer: {
        overflow: 'hidden',
        overflowY: 'scroll',
        padding: 2,
    },
    typo: {
        padding: '15px 20px 15px 10px',
        backgroundColor: baseColors.green.light,
        borderRadius: 5,
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
};

export default useStyles;
