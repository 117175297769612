import { Grid } from "@mui/material"
import { FC } from "react"
import { CustomText } from "../Text/CustomText"
import { baseColors, baseFontFamily, baseFontSize } from "src/utils/constants/baseStyles"

interface QuestionTitleProps {
    questionNumber: number,
    questionTitle: string,
}
const QuestionTitle : FC<QuestionTitleProps> = ({questionNumber, questionTitle}) => {

    return (
        <Grid
                container
                direction="row"
                alignItems={'center'}
                justifyContent="space-between"
            >
            <Grid item style={{}}>
                <CustomText
                    style={{ textAlign: 'left'}}
                    fontSize={baseFontSize.sm}
                    color={baseColors.orange.primary}
                    fontFamily={baseFontFamily.OpenSansSemiBold}
                >
                {`QUESTION N° ${questionNumber}`}
                </CustomText>
                
                <CustomText
                    style={{width: '100%', whiteSpace : 'break-spaces'}}
                    fontSize={baseFontSize.sm}
                    fontFamily={baseFontFamily.OpenSansSemiBold}
                >{questionTitle}</CustomText>
            </Grid>
        </Grid>
    )
}

export default QuestionTitle