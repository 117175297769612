import {
  RETRIEVE_DAYHOSPITALS,
  RETRIEVE_DAYHOSPITALS_WITH_FILTERS,
  RETRIEVE_DAYHOSPITAL_BY_ID,
  RETRIEVE_PLANNING_BY_SPECIALITY
} from "src/actions/dayHospitals/types";

const initialState: any = [];

const dayHospitalReducer = (dayHospitals = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case RETRIEVE_DAYHOSPITALS:
      return payload;

    case RETRIEVE_DAYHOSPITALS_WITH_FILTERS:
      return payload;

    case RETRIEVE_PLANNING_BY_SPECIALITY : 
      return payload;

    default:
      return dayHospitals;
  }
};

export const hdjReducer = (hdj = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case RETRIEVE_DAYHOSPITAL_BY_ID:
      return payload;

    default:
      return hdj;
  }
};


export default dayHospitalReducer;