import { Grid } from '@mui/material';
import { FC } from 'react';
import { InfoItem } from '../InfoItem';
import { emptyContent } from '../PatientProgram';

interface IProps {
    professionals: Array<
        Partial<{
            patient: any;
            professional: {
                id: number;
                email: string;
                firstName: string;
                lastName: string;
                phoneNumber: string;
                isMedecinTraitant?: boolean;
            };
            isAcceptedByPatient: boolean;
            isAcceptedByProfessional: boolean;
        }>
    >;
}

const PatientDoctor: FC<IProps> = ({ professionals }) => {
    const doctor = professionals?.find((doctor) => doctor?.professional?.isMedecinTraitant);
    const hasDoctor = doctor?.professional?.id! > 0;

    return (
        <Grid marginTop={1} container>
            {hasDoctor ? (
                <>
                    <InfoItem label="Nom" value={doctor?.professional?.firstName} />
                    <InfoItem label="Prénom(s)" value={doctor?.professional?.lastName} />
                    <InfoItem label="Téléphone" value={doctor?.professional?.phoneNumber ?? 'Non renseigné'} />
                    <InfoItem label="Mail" value={doctor?.professional?.email ?? 'Non renseigné'} />
                </>
            ) : (
                <>{emptyContent('Aucun médecin traitant trouvé')}</>
            )}
        </Grid>
    );
};

export default PatientDoctor;
