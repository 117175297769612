import React , { useEffect, useState, FC } from 'react';
import CustomEmptyData from 'src/components/CustomEmptyData';
import { Box, Typography } from '@mui/material';
import CustomCircleLoader from 'src/components/CustomLoader/CustomCircleLoader';
import { QuizItemType } from 'src/interfaces/types';
import QuizService from 'src/services/Quiz';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Paper from '@mui/material/Paper';
import { baseColors } from 'src/utils/constants/baseStyles';
import useStyles from '../styles';
import { toast } from 'react-toastify';
import { OperationMessage } from 'src/utils/constants/constants';
import CustomTagItem from 'src/components/CustomTagItem/CustomTagItem';
import { CustomConfirm } from 'src/components/CustomConfirm/CustomConfirm';
import TableHead from '@mui/material/TableHead';
import { tableCellHeadText,tableCellText } from 'src/views/Security/SecurityManagement/SecurityManagement';

interface QuizListProps {
    setLoadingModalContent:any;
    setModalOpen : any;
    setSelectedQuiz : any;
    setUpdateList : any;
    updateList : boolean;
}

const QuizList:FC<QuizListProps> = (props) => {
    const {setModalOpen,setSelectedQuiz,setLoadingModalContent,setUpdateList,updateList} = props;
    const classes = useStyles();
    const [fetching, setFetching] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [modalConfirmVisible, setModalConfirmVisible] = useState(false);
    const [toBeDeletedQuiz,seToBeDeletedQuiz] = useState<number>(0);
    const [quizzes, setQuizzes] = useState([]);

    const handleFetchQuizList = () => {
        setFetching(true);
        QuizService.getQuizList()
            .then((res) => {
                const data = res?.data?.data;
                setQuizzes(data);
                setFetching(false);
            })
            .catch((error) => {
                setFetching(false);
            });
    };

    useEffect(() => {
        handleFetchQuizList();
    }, [updateList]);

    const handleDeleteQuiz = (quizId: any) => {
        setModalConfirmVisible(false);
        setDeleting(true);
        QuizService.deleteQuiz(quizId)
            .then((res) => {
                setDeleting(false);
                setUpdateList((prev: any) => !prev);
                toast.success(OperationMessage.success);
                seToBeDeletedQuiz(0);
            })
            .catch(() => {
                setDeleting(false);
                toast.error(OperationMessage.error);
            });
    };

    const handleEditQuiz = (quizId: any) => {
        setModalOpen(true);
        setLoadingModalContent(true);
        QuizService.getQuizById(quizId)
        .then((res) => {
            if(res?.data?.data){
                setSelectedQuiz(res?.data?.data);
            }
            setLoadingModalContent(false);
        })
        .catch(() => {
            setDeleting(false);
            toast.error(OperationMessage.error);
        });
    }

    const isEmpty = quizzes?.length === 0;

    if (fetching) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10%' }}>
                <CustomCircleLoader />
            </Box>
        );
    }

    if (isEmpty) {
        return (
            <Box sx={{}}>
                <CustomEmptyData marginTop={100} height={50} width={50} />
            </Box>
        );
    }
    const tableHeaders = [{ name: 'Nom quiz' }, { name: 'Programme(s)' }, { name: 'Action' }];

    return (
        <>
        <TableContainer component={Paper} style={{ overflow: 'auto', maxHeight: 'calc(100vh - 300px)' }}>
            <Table stickyHeader sx={{ minWidth: 650 }} aria-label="role table">
                <TableHead>
                    <TableRow>
                        {tableHeaders.map((header, index) => {
                            return (
                                <TableCell key={index} sx={{ color: baseColors.green.primary }}>
                                    {tableCellHeadText(header.name)}
                                </TableCell>
                            );
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {quizzes?.map((quiz: QuizItemType, index) => (
                        <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" scope="row">
                                <Typography>
                                <div style={{ marginRight: 50 }}>{tableCellText(quiz?.title)}</div>
                                </Typography>
                            </TableCell>

                            <TableCell align="left">
                                <CustomTagItem programs={quiz?.program} />
                            </TableCell>
                            <TableCell>
                                <IconButton  onClick={() => handleEditQuiz(quiz.id)}>
                                    <EditIcon sx={{
                                            fontSize: 'medium',
                                            color: baseColors.green.primary,
                                            cursor: 'pointer',
                                        }} />
                                </IconButton>
                                <IconButton className={classes.removeBtn} 
                                    onClick={() => {
                                        seToBeDeletedQuiz(Number(quiz?.id));
                                        setModalConfirmVisible(true);
                                    }}>
                                    <DeleteIcon sx={{
                                            fontSize: 'medium',
                                            color: 'red',
                                            cursor: 'pointer',
                                        }} />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>

        <CustomConfirm
            defaultLoading={deleting}
            modalVisible={modalConfirmVisible}
            updateModalVisible={setModalConfirmVisible}
            message="Voulez-vous vraiment supprimer ce quiz ? "
            callBackconfirm={() => handleDeleteQuiz(toBeDeletedQuiz)}
        />
        </>
    );
};

export default QuizList;
