import { FC } from 'react';
import { Spacer } from 'src/components/Spacer/Spacer';
import { CustomText } from 'src/components/Text/CustomText';
import { baseColors, baseFontFamily, baseFontWeight } from 'src/utils/constants/baseStyles';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import { ColorElement } from 'src/interfaces/types';

type UserThemeButtonsProps = {
    items: ColorElement[];
};

const UserThemeButtons: FC<UserThemeButtonsProps> = (props) => {
    const { items } = props;

    return (
        <div>
            <CustomText
                style={{ textAlign: 'center' }}
                fontSize={16}
                fontFamily={baseFontFamily.OpenSansSemiBold}
                color={baseColors.black}
            >
                Boutons
            </CustomText>

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                }}
            >
                {items?.map((button: ColorElement, i) => (
                    <div key={i}>
                        <Spacer />

                        <Button
                            onClick={() => {}}
                            style={{
                                borderWidth: 2,
                                color: 'white',
                                backgroundColor: button.color,
                                borderColor: button.color,
                                width: 200,
                            }}
                            variant={'contained'}
                            size="large"
                        >
                            <Typography fontWeight={baseFontWeight.semiBold} fontSize={12}>
                                {button.label}
                            </Typography>
                        </Button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default UserThemeButtons;
