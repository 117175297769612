import { FC } from 'react';
import AxisContainer from '../AxisContainer';
import { AXIS_IDENTIFICATION } from 'src/utils/constants/enums';

interface AxisPsychologiqueProps {
    patientId: number;
    educationalDiagnosisId: number;
}

const AxisPsychologique: FC<AxisPsychologiqueProps> = ({ patientId, educationalDiagnosisId }) => {
    return (
        <AxisContainer
            axisId={AXIS_IDENTIFICATION.PSYCHO}
            patientId={patientId}
            educationalDiagnosisId={educationalDiagnosisId}
            title="Psychologique"
            position={3}
            prevScreenPath="dietetique"
            nextScreenPath="medical"
        />
    );
};

export default AxisPsychologique;
