import { useContext, useEffect, useState } from 'react';
import { PlateformTypeContext } from 'src/providers/PlateformTypeProvider';
import { URER_ROLES } from 'src/utils/constants/enums';

const useCheckUserRole = () => {
    const { isCoordonatorFromPlateform } = useContext(PlateformTypeContext);
    const userRole = sessionStorage.getItem('user-role') ?? '-';
    const [roles, setRoles] = useState({
        isCoordinator: isCoordonatorFromPlateform || false,
        isDoctor: false,
        isAdmin: false,
    });

    const handleGet = () => {
        try {
            const isCoordinator = userRole === URER_ROLES.COORDINATOR;
            const isDoctor = userRole === URER_ROLES.DOCTOR;
            const isAdmin = userRole === URER_ROLES.ADMIN;
            setRoles({
                isCoordinator,
                isDoctor,
                isAdmin,
            });
        } catch (error) { }
    };

    useEffect(() => {
        handleGet();
    }, [userRole]);

    return {
        roles,
        userRole,
        handleGet,
    };
};

export default useCheckUserRole;
