import * as React from 'react';
import { SVGProps } from 'react';
import { baseColors } from 'src/utils/constants/baseStyles';

const IconObesite = (props: SVGProps<SVGSVGElement>) => (
    <svg width={105} height={105} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <rect width={105} height={105} rx={52.5} fill={baseColors.orange.primary} />
        <g clipPath="url(#a)">
            <path
                d="M52.5 29.644H33.343c-4.122 0-7.375 3.467-7.074 7.537l2.524 34.036c.272 3.67 3.358 6.51 7.074 6.51h33.266c3.716 0 6.802-2.84 7.074-6.51l2.523-34.036c.302-4.07-2.952-7.537-7.074-7.537H52.5Z"
                fill="#FFDB76"
            />
            <path
                d="m78.731 37.181-2.524 34.036c-.272 3.67-3.358 6.51-7.074 6.51h-5.652c3.715 0 6.801-2.84 7.073-6.51l2.523-34.036c.302-4.07-2.952-7.537-7.073-7.537h5.652c4.122 0 7.376 3.466 7.075 7.537Z"
                fill="#FFC764"
            />
            <path
                d="M52.5 49.635c6.175 0 11.181-5.006 11.181-11.18 0-6.176-5.006-11.182-11.18-11.182-6.176 0-11.182 5.006-11.182 11.181 0 6.175 5.006 11.181 11.181 11.181Z"
                fill="#FFEFD8"
            />
            <path
                d="M63.68 38.454c0 6.175-5.005 11.18-11.18 11.18-.819 0-1.617-.088-2.386-.256 5.029-1.092 8.796-5.567 8.796-10.924 0-5.356-3.767-9.833-8.796-10.925a11.195 11.195 0 0 1 2.385-.256c6.176 0 11.18 5.007 11.18 11.18Z"
                fill="#EDDDC8"
            />
            <path
                d="M52.5 32.696a.769.769 0 0 1-.77-.769V30.18a.769.769 0 1 1 1.539 0v1.747a.77.77 0 0 1-.77.77ZM52.5 47.498a.769.769 0 0 1-.77-.77v-1.746a.769.769 0 1 1 1.539 0v1.747a.77.77 0 0 1-.77.769ZM49.236 33.57a.769.769 0 0 1-.667-.384l-.873-1.513a.77.77 0 0 1 1.332-.769l.873 1.513a.77.77 0 0 1-.665 1.154ZM56.637 46.39a.769.769 0 0 1-.666-.385l-.874-1.513a.769.769 0 0 1 1.332-.769l.874 1.513a.769.769 0 0 1-.666 1.154ZM46.846 35.96a.766.766 0 0 1-.384-.103l-1.513-.873a.77.77 0 0 1 .77-1.332l1.512.873a.77.77 0 0 1-.385 1.436ZM59.665 43.361a.766.766 0 0 1-.384-.103l-1.513-.874a.77.77 0 0 1 .77-1.332l1.512.874a.77.77 0 0 1-.385 1.435ZM45.972 39.224h-1.747a.769.769 0 1 1 0-1.538h1.747a.769.769 0 0 1 0 1.538ZM60.775 39.224h-1.747a.769.769 0 1 1 0-1.538h1.747a.769.769 0 0 1 0 1.538ZM45.334 43.36a.769.769 0 0 1-.385-1.434l1.513-.874a.77.77 0 1 1 .769 1.332l-1.513.874a.765.765 0 0 1-.384.103ZM58.154 35.96a.769.769 0 0 1-.386-1.435l1.513-.873a.769.769 0 1 1 .77 1.332l-1.514.873a.766.766 0 0 1-.383.104ZM48.361 46.389a.77.77 0 0 1-.665-1.154l.873-1.513a.77.77 0 0 1 1.332.77l-.873 1.512a.769.769 0 0 1-.667.385ZM55.763 33.57a.769.769 0 0 1-.665-1.154l.873-1.512a.769.769 0 0 1 1.332.77l-.874 1.512a.769.769 0 0 1-.666.385ZM54.355 37.276a2.082 2.082 0 0 0-1.13-.985v-1.537a.769.769 0 1 0-1.538 0v1.57a2.086 2.086 0 0 0-1.194 1.317 2.086 2.086 0 0 0 .151 1.599 2.107 2.107 0 0 0 1.858 1.119c.338 0 .673-.083.98-.245.495-.263.86-.702 1.024-1.238a2.086 2.086 0 0 0-.15-1.6Zm-1.319 1.147a.557.557 0 0 1-.701.371.557.557 0 0 1-.372-.701.557.557 0 0 1 .702-.371.558.558 0 0 1 .371.701Z"
                fill="#666363"
            />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" transform="translate(26.25 26.25)" d="M0 0h52.5v52.5H0z" />
            </clipPath>
        </defs>
    </svg>
);

export default IconObesite;
