import axiosInstance from "../AxiosInstance";
import TokenService from "../Token.service";
// import { refreshToken } from "src/actions/token/token";

const setup = (store: any) => {
  axiosInstance.interceptors.request.use(
    (config: any) => {
      const token = TokenService.getLocalAccessToken();

      if (token) {
        config.headers["Authorization"] = 'Bearer ' + token;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  let isRefreshing = false;
  let failedQueue: any = [];

  const processQueue = (error: any, token = null) => {
    failedQueue.forEach((prom: { reject: (arg0: any) => void; resolve: (arg0: null) => void; }) => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    })

    failedQueue = [];
  }

  axiosInstance.interceptors.response.use(function (response) {
    return response;
  }, function (error) {

    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry && originalRequest?.url !== "api/login_check") {

      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject })
        }).then(token => {
          originalRequest.headers['Authorization'] = 'Bearer ' + token;
          return axiosInstance(originalRequest);
        }).catch(err => {
          return Promise.reject(err);
        })
      }

      originalRequest._retry = true;
      isRefreshing = true;

      const refreshToken = TokenService.getLocalRefreshToken();
      if (!refreshToken) {
        TokenService.removeUser()
      }

      return new Promise(function (resolve, reject) {
        axiosInstance.post('api/token/refresh', { refreshToken })
          .then(({ data }) => {
            TokenService.updateLocalAccessToken(data.token)
            TokenService.updateLocalRefreshToken(data.refreshToken);
            axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + data.token;
            originalRequest.headers['Authorization'] = 'Bearer ' + data.token;
            processQueue(null, data.token);
            resolve(axiosInstance(originalRequest));
          })
          .catch((err) => {
            processQueue(err, null);
            reject(err);
          })
          .finally(() => { isRefreshing = false })
      })
    }

    return Promise.reject(error);
  });
};

export default setup;