/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { CustomRadio } from 'src/components/InputField/CustomRadio';
import { NumberSelector } from 'src/components/InputField/NumberSelector';
import { Spacer } from 'src/components/Spacer/Spacer';
import { CustomText } from 'src/components/Text/CustomText';
import ProgramService from 'src/services/Program/program.service';
import { baseFontFamily } from 'src/utils/constants/baseStyles';
import { ServerError } from 'src/utils/constants/constants';
import { QuestionWithRadio, renderContent, renderTitle } from '../utils';
import useStyles from './styles';
import _ from 'lodash';
import CustomCircleLoader from 'src/components/CustomLoader/CustomCircleLoader';
import { ProgramContext } from 'src/providers/ProgramProvider';
interface GoalsProps {
    programId: number;
}

interface DimensionProps {
    item: any;
    onChange: Function;
}
interface Goals {
    programId: number;
    objChangeBehavior: number;
    usefulLearning: number;
    helpSatisfaction: boolean;
    speakerSatisfaction: boolean;
    customerSatisfaction: boolean;
    homeSize: boolean;
    organizationSize: boolean;
    methodSize: boolean;
    utilitySize: boolean;
    contentSize: boolean;
}
interface GoalsSize {
    id: number;
    title: string;
    key: string;
    value: any;
}

export const Goals: FC<GoalsProps> = (props) => {
    const { setSyntheseHasChange, setsyntheseChangeSaveCallback } = useContext(ProgramContext);
    const { programId } = props;
    const defaultGoals = {
        programId: programId,
        id: 0,
        objChangeBehavior: 0,
        usefulLearning: 0,
        helpSatisfaction: 0,
        speakerSatisfaction: 0,
        customerSatisfaction: 0,
        homeSize: 0,
        organizationSize: 0,
        methodSize: 0,
        utilitySize: 0,
        contentSize: 0,
    };

    const [loading, setLoading] = useState(false);
    const classes = useStyles();
    const [goals, setGoals] = useState<any>(defaultGoals);
    const [oldGoalsValues, setOldGoalsValues] = useState<any>(defaultGoals);
    const defaultDimensions = [
        {
            id: 1,
            title: 'd’accueil',
            key: 'homeSize',
            value: goals.homeSize,
        },
        {
            id: 2,
            title: 'd’organisation',
            key: 'organizationSize',
            value: goals.organizationSize,
        },
        {
            id: 3,
            title: 'de méthode',
            key: 'methodSize',
            value: goals.methodSize,
        },
        {
            id: 4,
            title: 'd’utilité',
            key: 'utilitySize',
            value: goals.utilitySize,
        },
        {
            id: 5,
            title: 'de contenu',
            key: 'contentSize',
            value: goals.contentSize,
        },
    ];
    const [dimensions, setDimensions] = useState<GoalsSize[]>(defaultDimensions);

    const updateDimension = () => {
        setDimensions(defaultDimensions);
    };

    useEffect(() => {
        const hasChangeMade = !_.isEqual(goals, oldGoalsValues);
        setSyntheseHasChange(hasChangeMade);
        if (hasChangeMade) {
            setsyntheseChangeSaveCallback(() => performSaveProgramsObjective);
        }
        updateDimension();
    }, [goals, oldGoalsValues]);

    const getProgramsObjective = () => {
        setLoading(true);
        ProgramService.getObjective(programId)
            .then((response) => {
                let data = response.data?.data;
                if (data) {
                    data.programId = programId;

                    if (data) {
                        setGoals(data);
                        setOldGoalsValues(data);
                    }
                }
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
            });
    };

    const performSaveProgramsObjective = () => {
        return new Promise((resolve, reject) => {
            setLoading(true);

            if (goals.id !== 0) {
                //put
                ProgramService.putObjective(goals.id, goals)
                    .then((response) => {
                        setGoals(response.data?.data);
                        setOldGoalsValues(response.data?.data);
                        setLoading(false);

                        resolve(response);
                    })
                    .catch((e) => {
                        setLoading(false);
                        toast.error(ServerError.unknown);
                        reject(e);
                    });
            } else {
                //post
                ProgramService.postObjective(goals)
                    .then((response) => {
                        setGoals(response.data?.data);
                        setOldGoalsValues(response.data?.data);
                        setLoading(false);

                        resolve(response);
                    })
                    .catch((e) => {
                        setLoading(false);
                        toast.error(ServerError.unknown);
                        reject(e);
                    });
            }
        });
    };

    useEffect(() => {
        getProgramsObjective();
    }, []);

    const renderPatientSatisfaction = () => {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div>
                    <QuestionWithRadio
                        title={'Satisfaction patient'}
                        content={'Un questionnaire de satisfaction patient est-il utilisé ?'}
                        radioValue={!!goals.customerSatisfaction}
                        setRadioValue={(value: any) =>
                            setGoals((old: any) => ({ ...old, customerSatisfaction: value ? 1 : 0 }))
                        }
                    />
                </div>
                {goals.customerSatisfaction > 0 && (
                    <>
                        <div style={{ marginTop: 55 }}>
                            {renderContent('Explore-t-il les dimensions :')}
                            <RenderDimension
                                item={{ title: "d'accueil", value: goals.homeSize }}
                                onChange={(value: any) => setGoals((old: any) => ({ ...old, homeSize: value ? 1 : 0 }))}
                            />
                            <RenderDimension
                                item={{ title: "d'organisation", value: goals.organizationSize }}
                                onChange={(value: any) =>
                                    setGoals((old: any) => ({ ...old, organizationSize: value ? 1 : 0 }))
                                }
                            />
                            <RenderDimension
                                item={{ title: 'de méthode', value: goals.methodSize }}
                                onChange={(value: any) =>
                                    setGoals((old: any) => ({ ...old, methodSize: value ? 1 : 0 }))
                                }
                            />
                            <RenderDimension
                                item={{ title: "d'utilité", value: goals.utilitySize }}
                                onChange={(value: any) =>
                                    setGoals((old: any) => ({ ...old, utilitySize: value ? 1 : 0 }))
                                }
                            />
                            <RenderDimension
                                item={{ title: 'de contenu', value: goals.contentSize }}
                                onChange={(value: any) =>
                                    setGoals((old: any) => ({ ...old, contentSize: value ? 1 : 0 }))
                                }
                            />
                        </div>
                    </>
                )}
            </div>
        );
    };

    const renderPatientFormulation = () => {
        return (
            <div>
                {renderTitle('Formulation patient')}
                <Spacer height={20} />
                {renderContent('Combien de patients ont-ils pu, à la fin du programme, formuler au moins :')}
                <Spacer height={20} />
                <div className={classes.rowSimple}>
                    <div>{renderContent('Un objectif de changement de comportement :')}</div>
                    <Spacer height={20} />
                    <div style={{ maxWidth: 80 }}>
                        <NumberSelector
                            value={goals.objChangeBehavior}
                            handleChange={(value: any) => {
                                setGoals((old: any) => ({ ...old, objChangeBehavior: value }));
                            }}
                        />
                    </div>
                </div>
                <div className={classes.rowSimple}>
                    <div>{renderContent('Un apprentissage utile :')}</div>
                    <Spacer height={20} />
                    <div style={{ maxWidth: 80 }}>
                        <NumberSelector
                            value={goals.usefulLearning}
                            handleChange={(value: any) => {
                                setGoals((old: any) => ({ ...old, usefulLearning: value }));
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    };
    return (
        <div className={classes.container}>
            {loading ? (
                <div className={classes.row} style={{ justifyContent: 'center', marginTop: 40 }}>
                    <CustomCircleLoader />
                </div>
            ) : (
                <div className={classes.wrapper}>
                    <div className={classes.processLine}>
                        <div style={{ flex: 4 }}>{renderPatientFormulation()}</div>
                        <div style={{ flex: 1 }}></div>
                        <div style={{ flex: 4, marginTop: 40 }}>
                            {renderContent(
                                'Ratio avec numérateur : Nb patients satisfaisants à 1 de ces 2 items Dénominateur : Nb de patients ayant fini le programme (en %)',
                            )}
                            <Spacer height={20} />
                            {renderContent(
                                'Ratio avec numérateur : Nb patients satisfaisants à ces 2 items Dénominateur : Nb de patients ayant fini le programme (en %)',
                            )}
                        </div>
                        <div style={{ flex: 3 }}></div>
                    </div>
                    <div className={classes.processLine}>
                        <div style={{ flex: 2 }}>
                            <QuestionWithRadio
                                title={'Satisfaction aidant'}
                                content={'Un questionnaire de satisfaction aidant est-il utilisé ?'}
                                radioValue={!!goals.helpSatisfaction}
                                setRadioValue={(value: any) =>
                                    setGoals((old: any) => ({ ...old, helpSatisfaction: value ? 1 : 0 }))
                                }
                            />
                            <Spacer height={20} />
                            <QuestionWithRadio
                                title={'Satisfaction intervenant'}
                                content={
                                    'Un recueil de perception de l’utilité des séances et de leur contenu (pour les intervenants internes et externes) est-il utilisé ?'
                                }
                                radioValue={!!goals.speakerSatisfaction}
                                setRadioValue={(value: any) =>
                                    setGoals((old: any) => ({ ...old, speakerSatisfaction: value ? 1 : 0 }))
                                }
                            />
                        </div>
                        <div style={{ flex: 3 }}>{renderPatientSatisfaction()}</div>
                        <div style={{ flex: 1 }}></div>
                    </div>
                    {/* {hasChange && (
                        <div className={classes.row} style={{ justifyContent: 'center' }}>
                            <Spacer height={40} />
                            <CustomLoadingButton
                                loading={loading}
                                label="Enregistrer"
                                handleClick={() => performSaveProgramsObjective()}
                            ></CustomLoadingButton>
                        </div>
                    )} */}
                </div>
            )}
        </div>
    );
};

const RenderDimension: FC<DimensionProps> = (props) => {
    const classes = useStyles();
    const { item, onChange } = props;
    return (
        <div className={classes.row}>
            <div style={{ minWidth: 190 }}>
                <CustomText fontFamily={baseFontFamily.OpenSansSemiBold} fontSize={13}>
                    {item.title}
                </CustomText>
            </div>
            <div className={classes.row}>
                <div>
                    <CustomRadio
                        fontFamily={baseFontFamily.OpenSansSemiBold}
                        disabled={!!item.value}
                        checked={!!item.value}
                        onClick={() => {
                            onChange(1);
                        }}
                        paddingTop={0}
                        label="Oui"
                    />
                </div>
                <div>
                    <CustomRadio
                        fontFamily={baseFontFamily.OpenSansSemiBold}
                        checked={!!!item.value}
                        disabled={!!!item.value}
                        onClick={() => onChange(0)}
                        paddingTop={0}
                        label="Non"
                    />
                </div>
            </div>
        </div>
    );
};
