import { Box } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { retrieveMyDayToNumber } from 'src/actions/dashboard/dashboard';
import IconBook from 'src/components/CustomIcon/IconBook';
import IconDiagnostic from 'src/components/CustomIcon/IconDiagnostic';
import IconPerson from 'src/components/CustomIcon/IconPerson';
import DayNumberCard from 'src/components/DayNumberCard';
import { CustomText } from 'src/components/Text/CustomText';
import { AgendaContext } from 'src/providers/AgendaProvider';
import { RootState } from 'src/stores/store';
import { baseColors, baseFontSize } from 'src/utils/constants/baseStyles';
import useStyles from './styles';

const DayInNumbers: FC = (props) => {
    const [workshopCount, setWorkshopCount] = useState(0);
    const [individualInterviewCount, setIndividualInterviewCount] = useState(0);
    const [educationalDiagnosisCount, setEducationalDiagnosisCount] = useState(0);
    const data = useSelector((state: RootState) => state.dashboard);
    const mdtn = data.myDayToNumber;
    const { updateEventList } = useContext(AgendaContext);
    const dispatch = useDispatch();
    const getMyDayToNumber = () => dispatch(retrieveMyDayToNumber());

    useEffect(() => {
        getMyDayToNumber();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateEventList]);

    useEffect(() => {
        // Get Data From store using useSelector Hook
        setWorkshopCount(mdtn.workshopCount);
        setIndividualInterviewCount(mdtn.individualInterviewCount);
        setEducationalDiagnosisCount(mdtn.educationalDiagnosisCount);
    }, [mdtn]);

    const classes = useStyles();
    const dayNumberCards = [
        {
            icon: <IconBook />,
            number: workshopCount,
            label: 'ateliers',
        },
        {
            icon: <IconPerson />,
            number: individualInterviewCount,
            label: 'entretiens individuels',
        },
        {
            icon: <IconDiagnostic />,
            number: educationalDiagnosisCount,
            label: 'diagnostics éducatifs',
        },
    ];

    return (
        <Box className={classes.container}>
            <Box className={classes.header}>
                <CustomText fontSize={baseFontSize.md} isBreefSerif>
                    Ma journée en chiffres
                </CustomText>
            </Box>

            <Box className={classes.content}>
                {dayNumberCards?.map((dayNumber, i) => (
                    <DayNumberCard
                        key={i}
                        color={i === 1 ? baseColors.green.primary : baseColors.green.pastel}
                        icon={dayNumber.icon}
                        number={dayNumber.number}
                        label={dayNumber.label}
                    />
                ))}
            </Box>
        </Box>
    );
};

export default DayInNumbers;
