import { makeStyles } from '@mui/styles';
import { baseBorderRadius, baseColors, baseScreenHeight } from 'src/utils/constants/baseStyles';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: baseColors.white,
        ...baseScreenHeight.defaultHeight,
        ...baseBorderRadius.allSide,
    },
});

export const sxStyles : any = {
    rowContent: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        /*alignItems: 'center',*/
        overflowY: 'scroll',
        paddingLeft: 15,
        paddingRight: 15,
    }
}

export default useStyles;
