import axios from './AxiosInstance';


const getNotifications =  (filters?: any) => {

    const filterByDate = filters?.filterDate
    const filterByType = filters?.filterType


    let additionalUrl: string = ''

    if (filterByDate) {
        additionalUrl += `?createdAt=${filterByDate}`
    }
    
    if (filterByType > -1){
        if( !filterByDate) {
            additionalUrl += `?actionTypeId=${filterByType}`
        }else{
            additionalUrl += `&actionTypeId=${filterByType}`
        }
    }

    return axios.get('api/notifications'+ additionalUrl).then((response:any) => {
         const result = JSON.parse(response.request.response);
        return result.data;
    }).catch((reason) => {
        console.log('Get User Notifications Error', reason);
    });
}


const updateNotificationBehaviour =  (notificationId:number, data:any) => {
    return axios.put(`api/notifications/${notificationId}`,data).then((response:any) => {
        return true;
    }).catch((reason) => {
        console.log('Update  notification behaviour error', reason);
    });
}

const updateNotificationsToFocused = (data:any) => {
    return axios.put(`api/notifications/focus`,data).then((response:any) => {
        return true;
    }).catch((reason) => {
        console.log('update  notification to focus error', reason);
    });
}

const NotificationService = {
    getNotifications,
    updateNotificationBehaviour,
    updateNotificationsToFocused
};

export default NotificationService;