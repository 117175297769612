import { makeStyles } from '@mui/styles';
import { baseBorderRadius, baseColors, baseScreenHeight } from 'src/utils/constants/baseStyles';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        minWidth: 660,
        backgroundColor: baseColors.white,
        ...baseScreenHeight.defaultHeight,
        ...baseBorderRadius.leftOnly,
    },
    wrapper: { padding: '10px 20px 0px 20px', justifyContent: 'center' },
    row: {
        flexDirection: 'row',
        display: 'flex',
        marginTop: 10,
        alignItems: 'center',
    },
    programListContainer: { height: 'calc(55vh + 25px)', overflowY: 'auto', marginTop: 30, marginBottom: 20 },
});

export const sxStyles: any = {
    byNameContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    byLevelContainer: { display: 'flex', flexDirection: 'row', alignItems: 'center' },
};

export default useStyles;
