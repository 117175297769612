import {Dispatch, FC, SetStateAction, useState } from 'react';
import { Box, Collapse, IconButton, Tooltip } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { CustomConfirm } from 'src/components/CustomConfirm/CustomConfirm';
import { tableCellHeadText, tableCellText } from 'src/views/Security/SecurityManagement/SecurityManagement';
import { GoalItem, SkillItem } from 'src/interfaces/interfaces';
import { CustomText } from 'src/components/Text/CustomText';
import { baseColors, baseFontFamily, baseFontSize } from 'src/utils/constants/baseStyles';
import useSkillHooks from 'src/hooks/useSkillHooks';
import { OpenSansRegularText } from 'src/components/Text/OpenSansRegularText';
import CustomTagItem from 'src/components/CustomTagItem/CustomTagItem';
import { GoalModal } from '../../Goals/GoalModal/GoalModal';
import styles from '../styles';
import useGoalHooks from 'src/hooks/useGoalHooks';
import { useFetchSkillsRQHooks } from 'src/hooks/ReactQueryHooks/useSkillRQHooks';

type IProps = {
    isForProgram?: boolean;
    skill: SkillItem;
    toggleModal: Function;
    setSkill: (skill: SkillItem) => void;
    setUpdateProgramList?: Dispatch<SetStateAction<boolean>>;
};

export const SkillItemContent: FC<IProps> = (props) => {
    const { isForProgram, skill, toggleModal: toggleModalFormModal, setSkill, setUpdateProgramList } = props;
    const [open, setOpen] = useState(false);
    const [modalConfirmVisible, setModalConfirmVisible] = useState(false);
    const toggleModal = () => setModalConfirmVisible(!modalConfirmVisible);
    const [skillToDeleteId, setSkillToDeleteId] = useState(0);
    const { handleDeleteSkill, saving: deleting } = useSkillHooks({ setModalConfirmVisible });

    const [openGoalForm, setOpenGoalForm] = useState(false);
    const [modalConfirmGoalVisible, setModalConfirmGoalVisible] = useState(false);
    const [goal, setGoal] = useState<GoalItem>({} as GoalItem);
    const [goalToDeleteId, setGoalToDeleteId] = useState(0);
    const { saving:deletingGoal, handleDeleteGoal } = useGoalHooks({ setModalConfirmVisible : setModalConfirmGoalVisible });
    const { refetch } = useFetchSkillsRQHooks();

    const handleEdit = () => {
        setSkill(skill);
        toggleModalFormModal();
    };

    const handleEditGoal = (goal:any) => {
        setGoal({...goal, goal:goal.name});
        setOpenGoalForm(!openGoalForm)
    }

    const refreshList = () => {
        refetch();
        setUpdateProgramList?.(true)
    }    

    const skillPrograms = skill?.programs ?? [];
    return (
        <>
            <TableRow
                sx={{
                    '& .MuiTableCell-root': {
                        padding: 0,
                        borderBottom: 0,
                        marginTop: '5px',
                    },
                }}
            >
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>

                <TableCell align="left" sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <div style={{ width: '30%', marginRight: 50 }}>{tableCellText(skill.name)}</div>
                    <CustomTagItem programs={skillPrograms} />
                </TableCell>

                <TableCell align="left">
                    <TableCell>
                        {!isForProgram && (
                            <>
                                <IconButton onClick={handleEdit}>
                                    <Tooltip title={`Compétence : ${skill?.name}`}>
                                        <EditOutlinedIcon
                                            sx={{
                                                fontSize: 'medium',
                                                color: baseColors.green.primary,
                                                cursor: 'pointer',
                                            }}
                                        />
                                    </Tooltip>
                                </IconButton>

                                <IconButton
                                    onClick={() => {
                                        setSkillToDeleteId(skill.id);
                                        toggleModal();
                                    }}
                                >
                                    <Tooltip title={`Compétence : ${skill?.name}`}>
                                        <DeleteOutlineOutlinedIcon
                                            sx={{
                                                fontSize: 'medium',
                                                color: 'red',
                                                cursor: 'pointer',
                                            }}
                                        />
                                    </Tooltip>
                                </IconButton>
                            </>
                        )}
                    </TableCell>
                </TableCell>
            </TableRow>

            {skill?.goals?.length > 0 && (
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                                <CustomText isBreefSerif fontSize={baseFontSize.sl}>
                                    Les objectifs
                                </CustomText>
                                <Table size="small" aria-label="purchases">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                <CustomText fontFamily={baseFontFamily.OpenSansSemiBold}>
                                                    Nom
                                                </CustomText>
                                            </TableCell>
                                            <TableCell>
                                                <CustomText fontFamily={baseFontFamily.OpenSansSemiBold}>
                                                    Action
                                                </CustomText>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {skill?.goals?.map((goal: any) => (
                                            <TableRow key={goal.id}>
                                                <TableCell>
                                                    <OpenSansRegularText>{goal.name}</OpenSansRegularText>
                                                </TableCell>
                                                <TableCell>
                                                    <IconButton onClick={() => handleEditGoal(goal)}>
                                                        <Tooltip title={`Objectif : ${goal?.name}`}>
                                                            <EditOutlinedIcon
                                                                sx={{
                                                                    fontSize: 'medium',
                                                                    color: baseColors.green.primary,
                                                                    cursor: 'pointer',
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    </IconButton>
                                                    {/*<IconButton
                                                        onClick={() => {
                                                            setGoalToDeleteId(goal.id);
                                                            setModalConfirmGoalVisible(true);
                                                        }}
                                                    >
                                                        <Tooltip title={`Objectif : ${goal?.name}`}>
                                                            <DeleteOutlineOutlinedIcon sx={styles.delete} />
                                                        </Tooltip>
                                                    </IconButton>*/}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            )}

            <GoalModal 
                open={openGoalForm} 
                goal={goal} setGoal={setGoal} 
                toggleModal={setOpenGoalForm} 
                callBack={refreshList} 
            />

            <CustomConfirm
                defaultLoading={deleting}
                modalVisible={modalConfirmVisible}
                updateModalVisible={setModalConfirmVisible}
                message="Voulez-vous vraiment supprimer cette compétence ? "
                callBackconfirm={() => handleDeleteSkill({ skillId: skillToDeleteId })}
            />

            <CustomConfirm
                defaultLoading={deletingGoal}
                modalVisible={modalConfirmGoalVisible}
                updateModalVisible={setModalConfirmGoalVisible}
                message="Voulez-vous vraiment supprimer l'objectif ? "
                callBackconfirm={() => handleDeleteGoal({ goalId: goalToDeleteId })}
            />
        </>
    );
};
