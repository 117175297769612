import { makeStyles } from '@mui/styles';
import { baseColors } from 'src/utils/constants/baseStyles';

const useStyles = makeStyles({});

export const sxStyles: any = {
    container: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        background: baseColors.green.dark,
        paddingTop: 15,
        paddingBottom: 15,
        overflow: 'hidden',
        borderRadius: 8,
        marginBottom: 8,
        alignItems: 'center',
    },
    icon: {
        background: 'rgba(255,255,255,0.35)',
        width: 105,
        height: 100,
        borderRadius: 45,
        right: -25,
        position: 'absolute',
    },
    right: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        background: baseColors.green.dark,

        alignItems: 'center',
    },
    content: {
        display: 'flex',
        flexDirection: 'row',
        position: 'relative',
        alignItems: 'center',
    },
};

export default useStyles;
