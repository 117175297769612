import { CustomText } from 'src/components/Text/CustomText';
import { baseColors, baseFontFamily, baseFontSize } from 'src/utils/constants/baseStyles';
import useStyles from './styles';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import { PostAgendaTimeConfigurations } from 'src/interfaces/interfaces';
import AgendaSettingsService from 'src/services/AgendaSettings';
import { toast } from 'react-toastify';
import { AgendOperationMessage } from 'src/utils/constants/constants';
import { getDefaultHour, getDefaultMinutes, hours, Minute, minutes, timeSlots } from './AgendaSettingsLogic';
import { addZeroBeforeNumber } from 'src/utils/utils';
import { UserContext } from 'src/providers/UserProvider';
import useAgendaHours from 'src/hooks/useAgendaHours';
import { IsValidStartEndDate } from 'src/utils/helpers/helpers';
import moment from 'moment';
import SubMenusLoader from 'src/components/SettingsSubMenuBar/SubMenusLoader/SubMenusLoader';

interface Time {
    hour: string;
    minutes: string;
}
interface TimeItem {
    id: number;
    label: string;
    time: Time;
    setTime: Dispatch<SetStateAction<Time>>;
}

const AgendaSettings = () => {
    const classes = useStyles();
    const { userInfos } = useContext(UserContext);
    const [posting, setPosting] = useState<boolean>(false);
    const [fetchingTimeSlot, setFetchingTimeSlot] = useState<boolean>(false);
    const { handleFetchHoursData, time, setTime, fetching } = useAgendaHours();
    const [timeSlot, setTimeSlot] = useState<number>(0);
    const [timeValid, setTimeValid] = useState(true);
    const [userId, setUserId] = useState(0);

    const [startTime, setStartTime] = useState<Time>({
        hour: '08',
        minutes: '00',
    });

    const [endTime, setEndTime] = useState<Time>({
        hour: '18',
        minutes: '00',
    });

    const times: TimeItem[] = [
        { id: 1, label: 'Heure de début', time: startTime, setTime: setStartTime },
        { id: 2, label: 'Heure de fin', time: endTime, setTime: setEndTime },
    ];

    const handleSetTimes = () => {
        setStartTime({
            hour: getDefaultHour(time?.startHour) ?? '08',
            minutes: getDefaultMinutes(time?.startHour) ?? '00',
        });

        setEndTime({
            hour: getDefaultHour(time?.endHour) ?? '18',
            minutes: getDefaultMinutes(time?.endHour) ?? '00',
        });
    };

    const validCreneau = () => timeSlot > 0;

    useEffect(() => {
        handleFetchHoursData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId]);

    useEffect(() => {
        handleSetTimes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [time]);

    const handleChange = (e: SelectChangeEvent, setValue: Dispatch<SetStateAction<Time>>) =>
        setValue((prev: Time) => ({ ...prev, [e.target.name]: e.target.value }));

    const handlePost = () => {
        const payload: PostAgendaTimeConfigurations = {
            startHour: `${addZeroBeforeNumber(+startTime.hour)}:${addZeroBeforeNumber(+startTime.minutes)}`,
            endHour: `${addZeroBeforeNumber(+endTime.hour)}:${addZeroBeforeNumber(+endTime.minutes)}`,
            userId: userId ?? 0,
            timeSlot: timeSlot,
        };

        if (userId > 0) {
            setPosting(true);
            AgendaSettingsService.postAgendaTimeConfigurations(payload)
                .then((response) => {
                    const data = response?.data?.data;
                    setTime({
                        startHour: data?.startHour,
                        endHour: data?.endHour,
                    });
                    toast.success(AgendOperationMessage.success);
                    setPosting(false);
                })
                .catch(() => {
                    setPosting(false);
                    toast.error(AgendOperationMessage.error);
                });
        }
    };

    const getAgendaTimeCurrent = async () => {
        if (userId > 0) {
            setFetchingTimeSlot(true);
            await AgendaSettingsService.getAgendaTimeConfigurations(userId)
                .then((response) => {
                    const data = response?.data?.data;
                    setTimeSlot(data?.timeSlot);
                    setFetchingTimeSlot(false);
                })
                .catch(() => setFetchingTimeSlot(false));
        }
    };

    useEffect(() => {
        const startDate = moment(`${startTime.hour}:${startTime.minutes}`, 'HH:mm').toDate();
        const endDate = moment(`${endTime.hour}:${endTime.minutes}`, 'HH:mm').toDate();
        if (IsValidStartEndDate(startDate, endDate)) {
            setTimeValid(true);
        } else {
            setTimeValid(false);
        }
    }, [startTime, endTime]);

    useEffect(() => {
        getAgendaTimeCurrent();
        if (userInfos) {
            setUserId(userInfos?.id);
        }
    }, [userInfos]);

    const invalidTimeSlot = timeSlot === 0 || timeSlot === undefined;

    return (
        <div className={classes.container}>
            <div className={classes.titleContainer}>
                <CustomText isBreefSerif isTitle color={baseColors.green.primary} style={{ marginLeft: 20 }}>
                    Définir la plage horaire de votre agenda
                </CustomText>
            </div>

            {fetching ? (
                <SubMenusLoader />
            ) : (
                <>
                    <div className={classes.content}>
                        {times.map((timeItem: TimeItem) => (
                            <div key={timeItem.id} className={classes.startAt}>
                                <div className={classes.startAtLabel}>
                                    <p className={classes.label}>{timeItem.label} : </p>
                                </div>

                                <div className={classes.timeContainer}>
                                    <div className={classes.hours}>
                                        <FormControl
                                            sx={{
                                                m: 1,
                                                width: 70,
                                            }}
                                            size="small"
                                        >
                                            <Select
                                                id={`hour-${timeItem.id}`}
                                                name="hour"
                                                disabled={posting}
                                                value={timeItem.time.hour}
                                                defaultValue={timeItem.time.hour}
                                                onChange={(e: SelectChangeEvent) => handleChange(e, timeItem.setTime)}
                                            >
                                                {hours.map((hour) => (
                                                    <MenuItem key={hour.id} value={hour.value}>
                                                        {hour.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>

                                    <p className={classes.separator}>:</p>

                                    <div className={classes.minutes}>
                                        <FormControl
                                            sx={{
                                                m: 1,
                                                width: 70,
                                            }}
                                            size="small"
                                        >
                                            <Select
                                                id={`minutes-${timeItem.id}`}
                                                name="minutes"
                                                disabled={posting}
                                                value={timeItem.time.minutes}
                                                defaultValue={timeItem.time.minutes}
                                                onChange={(e: SelectChangeEvent) => handleChange(e, timeItem.setTime)}
                                            >
                                                {minutes.map((minute: Minute) => (
                                                    <MenuItem key={minute.id} value={minute.label}>
                                                        {minute.value}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className={classes.content} style={{ margin: 'auto' }}>
                        {fetchingTimeSlot ? (
                            <CustomText>Chargement ...</CustomText>
                        ) : (
                            <>
                                <>
                                    <div className={classes.startAtLabel}>
                                        <p className={classes.label}> Choisir un créneau : </p>
                                    </div>
                                </>
                                <FormControl
                                    sx={{
                                        m: 1,
                                        width: invalidTimeSlot ? 300 : 200,
                                        borderRadius: 20,
                                    }}
                                    size="small"
                                >
                                    <Select
                                        name="hour"
                                        disabled={posting}
                                        value={timeSlot ?? 0}
                                        defaultValue={timeSlot ?? 0}
                                        onChange={(value: any) => {
                                            setTimeSlot(value?.target?.value ?? 15);
                                        }}
                                    >
                                        {timeSlots.map((intervall) => (
                                            <MenuItem key={intervall.id} value={intervall.value}>
                                                <CustomText
                                                    fontFamily={baseFontFamily.OpenSansSemiBold}
                                                    color={
                                                        intervall.id === 0 || intervall.id === -1
                                                            ? baseColors.green.primary
                                                            : baseColors.black
                                                    }
                                                    fontSize={baseFontSize.sm}
                                                >
                                                    {intervall.label}
                                                </CustomText>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </>
                        )}
                    </div>

                    <div className={classes.footerBtn}>
                        <CustomLoadingButton
                            loading={posting}
                            disabled={posting || !timeValid || invalidTimeSlot || !validCreneau()}
                            label="Enregistrer"
                            handleClick={handlePost}
                        />
                    </div>

                    {!timeValid && (
                        <CustomText style={{ textAlign: 'center', marginTop: 20 }} color={baseColors.orange.primary}>
                            L'heure de fin ne doit pas être avant l'heure de début
                        </CustomText>
                    )}
                </>
            )}
        </div>
    );
};

export default AgendaSettings;
