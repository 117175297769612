import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { retrieveAuthorizations } from 'src/actions/authorization/authorization';
import { RootState } from 'src/stores/store';
import { baseColors } from 'src/utils/constants/baseStyles';
import RiskIcon from '../Feature/RiskIcon';
import AuthorizationTable, { AuthorizationActionButtons } from './AuthorizationTable';
import useStyles from './styles';
import CustomCircleLoader from 'src/components/CustomLoader/CustomCircleLoader';
import CustomEmptyData from 'src/components/CustomEmptyData';
import { CustomText } from 'src/components/Text/CustomText';

export interface DetailAuthorization {
    featureId?: any;
    featureName: string;
    code: string;
    category: string;
    risk: any;
    userPermission: string;
    patientPermission: string;
    doctorPermission: string;
    coordinatorPermission: string;
    adminPermission: string;
}

const AuthorizationPanel = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const authorizations = useSelector((state: RootState) => state.authorizations.listAuthorizations);
    const headers = useSelector((state: RootState) => state.authorizations.listAuthorizationHeaders);
    const [dataRows, setDataRows] = useState<any[]>([]);
    const [tableHeaders, setTableHeaders] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        dispatch(retrieveAuthorizations({}, setLoading));
    }, []);

    useEffect(() => {
        parseAuthorizationData(authorizations);
    }, [authorizations]);

    useEffect(() => {
        parseTableHeaders(headers);
        // console.log(headers);
    }, [headers]);

    const parseAuthorizationData = (authorizations: any[]) => {
        let rows: any[] = [];

        authorizations.forEach((authorization) => {
            const roles = authorization.roles;

            let authInfos = {
                featureId: authorization.id,
                featureName: authorization.name,
                category: authorization.category,
                code: authorization.code,
                risk: <RiskIcon level={authorization.risk} />,
                userPermission: roles[0].privilegeName,
                patientPermission: roles[1].privilegeName,
                doctorPermission: roles[2].privilegeName,
                coordinatorPermission: roles[3].privilegeName,
                adminPermission: roles[4].privilegeName,
            };

            rows.push({
                id: authorization.id,
                name: authorization.name,
                category: authorization.category,
                risk: authorization.risk,
                roles: authorization.roles,
                action: (
                    <AuthorizationActionButtons key={authorization.id} settings={true} view={true} data={authInfos} />
                ),
            });
            return rows;
        });

        setDataRows(rows);
    };

    const parseTableHeaders = (headers: any[]) => {
        const firstHeaders = [
            {
                name: 'Nom de fonctionnalité',
            },
            {
                name: 'Risque',
            },
        ];

        const headersTable: any[] = [];
        headers.forEach((headerElement) => {
            return headersTable.push({
                name: headerElement.name,
            });
        });
        setTableHeaders([...firstHeaders, ...headersTable]);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                paddingX: '25px',
            }}
        >
            <Box
                sx={{
                    height: '50px',
                    marginTop: '25px',
                    marginBottom: '15px',
                    textAlign: 'center',
                }}
            >
                <CustomText isBreefSerif isTitle color={baseColors.green.primary} style={{ marginLeft: 20 }}>
                    Liste des autorisations
                </CustomText>
            </Box>

            {loading ? (
                <div className={classes.loading}>
                    <CustomCircleLoader />
                </div>
            ) : dataRows?.length > 0 ? (
                <AuthorizationTable tableHeaders={tableHeaders} dataRows={dataRows} />
            ) : (
                <CustomEmptyData />
            )}
        </Box>
    );
};

export default AuthorizationPanel;
