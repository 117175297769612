import { makeStyles } from '@mui/styles';
import { baseBorderRadius, baseColors, baseScreenHeight } from 'src/utils/constants/baseStyles';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        minWidth: 660,
        backgroundColor: baseColors.white,
        ...baseBorderRadius.leftOnly,
        height: baseScreenHeight.programSynthese,
    },
    processLine: {
        marginTop: 20,
        flex: 12,
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'row',
    },
    wrapper: {
        processLine: {
            marginTop: 20,
            flex: 12,
            justifyContent: 'space-between',
            display: 'flex',
            flexDirection: 'row',
        },
        padding: 20,
        justifyContent: 'center',
        overflowY: 'auto',
    },
    rowSimple: {
        flexDirection: 'row',
        marginTop: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    rowAlignCenter: {
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
    },
});

export default useStyles;
