import { FC } from 'react';
import AxisContainer from '../AxisContainer';

import { AXIS_IDENTIFICATION } from 'src/utils/constants/enums';

interface AxisAPAProps {
    patientId: number;
    educationalDiagnosisId: number;
}

const AxisAPA: FC<AxisAPAProps> = ({ patientId, educationalDiagnosisId }) => {
    return (
        <AxisContainer
            disablePrev
            axisId={AXIS_IDENTIFICATION.APA}
            patientId={patientId}
            educationalDiagnosisId={educationalDiagnosisId}
            title="APA"
            position={1}
            prevScreenPath=""
            nextScreenPath="dietetique"
        />
    );
};

export default AxisAPA;
