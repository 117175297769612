import { Box } from '@mui/material';
import { FC } from 'react';
import { Dna } from 'react-loader-spinner';
import { Redirect } from 'react-router';
import useCheckUserRole from 'src/hooks/useCheckUserRole';

interface IProps {
    height?: number;
    width?: number;
    userInfos?: boolean;
    color?: string;
}

const InitialPageLoader: FC<IProps> = (props) => {
    const { roles } = useCheckUserRole();

    if (roles?.isAdmin) {
        return <Redirect to="/settings" />;
    }

    if (roles?.isCoordinator || roles?.isDoctor) {
        return <Redirect to="/dashboard" />;
    }

    return (
        <Box
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'transparent',
                alignSelf: 'center',
                alignContent: 'center',
                height: '100vh',
            }}
        >
            <Dna
                visible={true}
                height="200"
                width="200"
                ariaLabel="dna-loading"
                wrapperStyle={{}}
                wrapperClass="dna-wrapper"
            />
        </Box>
    );
};

export default InitialPageLoader;
