import React, { FC, useContext, useState } from 'react';
import { Typography, Box } from '@mui/material';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { HdjContext } from 'src/providers/HdjProvider';

interface RadioItem {
    label: string;
    value: string;
}

const ShowPlanningBy: FC = (props) => {
    const { setShowPlanningBy } = useContext(HdjContext);
    const [value, setValue] = useState<string>('speciality');
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value);
        setShowPlanningBy((event.target as any).value);
    };

    const radios = [
        {
            label: 'Spécialité',
            value: 'speciality',
        },
        {
            label: 'Salle',
            value: 'room',
        },
    ];

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 2 }}>
            <Typography fontFamily={baseFontFamily.OpenSansSemiBold} fontSize={13} color={baseColors.black}>
                Afficher le planning par :
            </Typography>
            <FormControl sx={{ marginLeft: 5 }} component="fieldset">
                <RadioGroup
                    value={value}
                    onChange={handleChange}
                    row
                    aria-label="gender"
                    name="row-radio-buttons-group"
                >
                    {radios.map((radio: RadioItem, i: number) => (
                        <FormControlLabel
                            key={i}
                            value={radio.value}
                            control={
                                <Radio
                                    sx={{
                                        color: baseColors.grey,
                                        '&.Mui-checked': {
                                            color: baseColors.green.primary,
                                        },
                                    }}
                                />
                            }
                            label={
                                <Typography
                                    fontFamily={baseFontFamily.OpenSansSemiBold}
                                    fontSize={13}
                                    color={baseColors.black}
                                >
                                    {radio.label}
                                </Typography>
                            }
                        />
                    ))}
                </RadioGroup>
            </FormControl>
        </Box>
    );
};

export default ShowPlanningBy;
