import { Grid } from "@mui/material"
import { FC } from "react"
import { UserAxisItem } from "src/interfaces/interfaces"
import { CustomRadio } from "../InputField/CustomRadio"
import QuestionPrecision from "./QuestionPrecision"

interface QuestionCheckboxRadioProps {
    userDEResponse: UserAxisItem;
    responseIds: Array<any>;
    index: number;
    setPayload : Function;
    questionTitle: JSX.Element;
    precisionAnswer: number | string | undefined;
}
const QuestionCheckboxRadio : FC<QuestionCheckboxRadioProps> = ({userDEResponse, index, responseIds, precisionAnswer, setPayload, questionTitle}) => {
    const hasPrécision = userDEResponse.survey.hasPrecision === 1;
    const proposalAnswerSurveys = userDEResponse?.survey?.proposalAnswerSurveys;
    
    const updatePayload = (value: number) => {
        setPayload((prev: any) => {
            const updatedPayload = { ...prev };
            if (updatedPayload.responses && updatedPayload.responses.length > index) {
                updatedPayload.responses[index].responseIds = [value];
            }
        
            return updatedPayload; // Return the updated state
        });
    }

    return (
        <Grid
                style={{ marginTop: 20, marginBottom: 10 }}
                container
                direction="column"
                alignItems={'start'}
                justifyContent="start"
            >
            <Grid item>
                {questionTitle}
            </Grid>
            <Grid item style={{ display: 'flex', justifyContent: 'start', alignItems:'start', flexDirection:'column', width: '30%' }}>
                {
                    proposalAnswerSurveys?.map((prop:any) => 
                        <CustomRadio
                            checked={responseIds.includes(prop?.proposalAnswer?.id)}
                            onClick={() => updatePayload(prop?.proposalAnswer?.id)}
                            label={prop?.proposalAnswer?.name}
                        />
                    )
                }
            </Grid>
            {hasPrécision &&
                <Grid item>
                    <QuestionPrecision
                        precisionAnswer={precisionAnswer}
                        index={index} 
                        setPayload={setPayload} 
                        userDEResponse={userDEResponse}
                    />
                </Grid>
            }
        </Grid>
    )
}

export default QuestionCheckboxRadio