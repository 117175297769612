import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import IconRoleUser from '../CustomIcon/IconRoleUser';
import IconRoleFonctionnalite from '../CustomIcon/IconRoleFonctionnalite';
import IconRolePermission from '../CustomIcon/IconRolePermission';
import IconRoleAutorisation from '../CustomIcon/IconRoleAutorisation';
import { Divider, ListItemIcon } from '@mui/material';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import { useContext, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { ROUTES } from 'src/routes/routes';
import { AUTHORIZATION_CODES } from 'src/utils/constants/constants';
import { PermissionContext } from 'src/providers/PermissionProvider';
import { History } from '@mui/icons-material';
import IconConnexion from './../CustomIcon/IconConnexion';
import useProtectedPageRedirection from 'src/hooks/useProtectedPageRedirection';
import { CustomText } from '../Text/CustomText';

const SecuritySubMenuBar = () => {
    const history = useHistory();
    const { url } = useRouteMatch();
    const [selectedMenu, setSelectedMenu] = useState<number>(1);
    const { permissions } = useContext(PermissionContext);

    useProtectedPageRedirection();

    let securitySubMenuData = [
        {
            id: 1,
            icon: <IconRoleUser height={20} width={20} />,
            iconActive: <IconRoleUser height={20} width={20} />,
            label: 'Rôle',
            path: ROUTES.roles,
            code: AUTHORIZATION_CODES.listRole,
        },
        {
            id: 2,
            icon: <IconRoleFonctionnalite height={20} width={20} />,
            iconActive: <IconRoleFonctionnalite height={20} width={20} />,
            label: 'Fonctionnalité',
            path: ROUTES.features,
            code: AUTHORIZATION_CODES.listFeature,
        },
        {
            id: 3,
            icon: <IconRolePermission height={20} width={20} />,
            iconActive: <IconRolePermission height={20} width={20} />,
            label: 'Permission',
            path: ROUTES.permissions,
            code: AUTHORIZATION_CODES.listPermission,
        },
        {
            id: 4,
            icon: <IconRoleAutorisation height={20} width={20} />,
            iconActive: <IconRoleAutorisation height={20} width={20} />,
            label: 'Autorisation',
            path: ROUTES.authorizations,
            code: AUTHORIZATION_CODES.listAuthorization,
        },
        {
            id: 5,
            icon: <History style={{ color: baseColors.green.primary }} />,
            iconActive: <IconRoleAutorisation height={20} width={20} />,
            label: 'Historique de l\'utilisateur',
            path: ROUTES.user_history,
            code: AUTHORIZATION_CODES.listUserHistory,
        },
        {
            id: 6,
            icon: <IconConnexion style={{ color: baseColors.green.primary }} />,
            iconActive: <IconRoleAutorisation height={20} width={20} />,
            label: 'Connexion et déconnexion',
            path: ROUTES.connexions,
            code: AUTHORIZATION_CODES.listUserHistory,
        },
    ];

    return (
        <MenuList style={{ marginTop: 30 }}>
            {securitySubMenuData
                .filter((item) => permissions.includes(item.code))
                .map((menu) => {
                    const isSelected = url.includes(menu.path);
                    const withOpacity = isSelected ? 1 : 0;
                    const selectedMenuColor = isSelected ? baseColors.orange.primary : undefined;
                    const iconBackground = isSelected ? baseColors.orange.primary : baseColors.orange.light;
                    const icon = isSelected ? (menu.id === 1 ? menu.iconActive : menu.icon) : menu.icon;
                    const label = menu.label;
                    const labelFontFamily = isSelected
                        ? baseFontFamily.OpenSansSemiBold
                        : baseFontFamily.OpenSansRegular;

                    const handleClickMenu = (menuId: number, path: string) => {
                        setSelectedMenu(menuId);

                        switch (menuId) {
                            case 6:
                                history.push('/admin/security/connexions');
                                break;
                            case 1:
                                history.push('/admin/security/roles');
                                break;
                            case 2:
                                history.push('/admin/security/features');
                                break;
                            case 3:
                                history.push('/admin/security/permissions');
                                break;
                            default:
                                history.push(path);
                                break;
                        }
                    };

                    return (
                        <MenuItem
                            key={menu.id.toString()}
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                paddingRight: 0,
                                marginRight: 0,
                                marginBottom: 2,
                            }}
                            onClick={() => handleClickMenu(menu.id, menu.path)}
                        >
                            <ListItemIcon style={{ ...styles.itemIcon, backgroundColor: iconBackground }}>
                                {icon}
                            </ListItemIcon>

                            <CustomText
                                style={{ marginLeft: 10, marginRight: 10, whiteSpace: 'pre-wrap' }}
                                fontFamily={labelFontFamily}
                            >
                                {label}
                            </CustomText>

                            <Divider
                                orientation="vertical"
                                style={{ ...styles.divider, opacity: withOpacity, background: selectedMenuColor }}
                            />
                        </MenuItem>
                    );
                })}
        </MenuList>
    );
};

const styles = {
    menuItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingRight: 0,
        marginRight: 0,
        marginBottom: 10,
    },
    itemIcon: {
        alignItems: 'center',
        justifyContent: 'center',
        padding: '7px 0px 7px 0px',
        borderRadius: 10,
    },
    divider: {
        width: 4,
        height: 50,
        borderRadius: 7,
        marginLeft: 'auto',
    },
    label: {
        fontSize: '16px',
        marginLeft: 10,
    },
};

export default SecuritySubMenuBar;
