import { RootState } from 'src/stores/store';
import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import NotificationService from 'src/services/Notifications';
import { NotificationActionType } from './types';

export const retrieveNotifications =
    (filters?: any, setLoading?: Function): ThunkAction<void, RootState, unknown, AnyAction> =>
    async (dispatch) => {
        setLoading?.(true);
        try {
            const data = await NotificationService.getNotifications(filters);

            dispatch({
                type: NotificationActionType.LIST_NOTIFICATIONS,
                payload: data,
            });
            setLoading?.(false);
        } catch (error) {
            console.log('Retrieve Notifications Error', error);
            setLoading?.(false);
        }
    };
