import { Grid } from "@mui/material"
import { FC} from "react"
import { UserAxisItem } from "src/interfaces/interfaces"
import QuestionPrecision from "./QuestionPrecision"
import { CustomCheckBox } from "../InputField/CustomCheckBox"

interface QuestionCheckboxProps {
    userDEResponse: UserAxisItem;
    responseIds: Array<any>;
    index: number;
    setPayload : Function;
    questionTitle: JSX.Element;
    precisionAnswer: number | string | undefined;
}
const QuestionCheckbox : FC<QuestionCheckboxProps> = ({userDEResponse, index, responseIds, precisionAnswer, setPayload, questionTitle}) => {
    const hasPrécision = userDEResponse.survey.hasPrecision === 1;
    const proposalAnswerSurveys = userDEResponse?.survey?.proposalAnswerSurveys;

    const handleCheckboxChange = (number: number) => {
        setPayload((prev: any) => {
            const updatedPayload = { ...prev };
            if (updatedPayload.responses && updatedPayload.responses.length > index && updatedPayload.responses[index])  {          
                if (updatedPayload.responses[index].responseIds.includes(number)) {
                    // remove number if already exist in array
                    updatedPayload.responses[index].responseIds?.filter((item: number) => item !== number);
                } else {
                    // Add in array if not exist
                    updatedPayload.responses[index].responseIds.push(number);
                }
            }
        
            return updatedPayload;
        });
    };

    return (
        <Grid
                style={{ marginTop: 20, marginBottom: 10 }}
                container
                direction="column"
                alignItems={'start'}
                justifyContent="start"
            >
            <Grid item>
                {questionTitle}
            </Grid>
            <Grid item style={{ display: 'flex', justifyContent: 'start', alignItems:'start', flexDirection:'column', width: '30%' }}>
                {
                    proposalAnswerSurveys?.map((prop:any) => 
                        <CustomCheckBox
                            checked={responseIds.includes(prop?.proposalAnswer?.id)}
                            onClick={() => handleCheckboxChange(prop?.proposalAnswer?.id)}
                            label={prop?.proposalAnswer?.name}
                        />
                    )
                }
            </Grid>
            {hasPrécision &&
                <Grid item>
                    <QuestionPrecision
                        precisionAnswer={precisionAnswer}
                        index={index} 
                        setPayload={setPayload} 
                        userDEResponse={userDEResponse}
                    />
                </Grid>
            }
        </Grid>
    )
}

export default QuestionCheckbox