import moment from 'moment';
import FullCalendarService from 'src/services/Calendar/fullcalendar.service';
import { fullCalendarTimeFormat } from 'src/utils/utils';

let eventGuid = 0;
let todayStr = new Date().toISOString().replace(/T.*$/, ''); // YYYY-MM-DD of today
const INITIAL_EVENTS = [];

export function createEventId() {
    return String(eventGuid++);
}

const fullCalendarEventTimeFormat = (date) => moment(date).format(fullCalendarTimeFormat);

const handleFetchEvents = async ({ checkedDate, filters, setFetching, setEvents }) => {
    try {
        setFetching(true);
        await FullCalendarService.getEvents({ checkedDate, filters })
            .then((events) => {
                const res = events?.data?.data;
                setEvents(res);
                setFetching(false);
            })
            .catch(() => {
                setFetching(false);
            });
    } catch (error) {}
};

export { fullCalendarEventTimeFormat, todayStr, INITIAL_EVENTS, handleFetchEvents };
