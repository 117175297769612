import { Typography } from '@mui/material';
import { FC, useContext } from 'react';
import { baseColors, baseFontFamily, baseFontSize, baseFontWeight } from 'src/utils/constants/baseStyles';
import useStyles from './styles';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { HdjItem } from 'src/interfaces/interfaces';
import { Box } from '@mui/system';
import { useHistory } from 'react-router-dom';
import IconKitMedical from 'src/components/CustomIcon/IconKitMedical';
import IconCheckList from 'src/components/CustomIcon/IconCheckList';
import IconDiabeto from 'src/components/CustomIcon/IconDiabeto';
import { HdjContext } from 'src/providers/HdjProvider';
import { CustomText } from '../Text/CustomText';

const hdjIcons = [
    <IconKitMedical height={35} width={35} />,
    <IconCheckList height={35} width={35} />,
    <IconDiabeto height={35} width={35} />,
];
interface HdjItemCardProps {
    item: HdjItem;
    isGreen?: boolean;
    isModel?: boolean;
}

const HdjItemCard: FC<HdjItemCardProps> = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const { setDayHospitalIds, setCurrentHdjInfos } = useContext(HdjContext);
    const {
        item: { id, name },
        item,
        isGreen,
        isModel,
    } = props;
    const backgroundColor = !isGreen ? baseColors.orange.light : baseColors.green.light;
    const color = !isGreen ? baseColors.orange.primary : baseColors.green.primary;
    const iconStyle = { backgroundColor: color, padding: '10px 10px 7px 10px', borderRadius: 10 };
    const handleClick = (isPlanning: boolean) => {
        setDayHospitalIds([id]);
        setCurrentHdjInfos(item);
        history.push(isPlanning ? `/hdj/planning/${id}` : `/hdj/management/${id}`);
    };

    const icon = hdjIcons[Math.floor(Math.random() * hdjIcons.length)];

    return (
        <Box style={{ backgroundColor: backgroundColor }} className={classes.container}>
            <Box className={classes.left}>
                <Box sx={iconStyle}>{icon}</Box>
                <Typography color={baseColors.black} fontWeight={baseFontWeight.medium} fontSize={14} marginLeft={2}>
                    {name}
                </Typography>
            </Box>

            <Stack spacing={5} direction="row">
                <Button
                    onClick={() => handleClick(false)}
                    size="large"
                    style={{
                        width: 250,
                        backgroundColor: color,
                        color: baseColors.white,
                    }}
                    variant="contained"
                >
                    <CustomText
                        color={baseColors.white}
                        fontSize={baseFontSize.mini}
                        fontFamily={baseFontFamily.OpenSansBold}
                    >
                        Gestion de l'HDJ
                    </CustomText>
                </Button>

                <Button
                    onClick={() => handleClick(true)}
                    style={{
                        borderWidth: 2,
                        color: isModel ? '#777' : color,
                        borderColor: color,
                    }}
                    variant={isModel ? 'contained' : 'outlined'}
                    size="large"
                    disabled={isModel}
                >
                    <CustomText
                        color={color}
                        fontSize={baseFontSize.mini}
                        fontFamily={baseFontFamily.OpenSansBold}
                    >
                        Planning
                    </CustomText>
                </Button>
            </Stack>
        </Box>
    );
};

export default HdjItemCard;
