import { Box } from '@mui/material';
import { FC, useEffect, useState, useContext } from 'react';
import HDJFormCardLoading from '../CustomLoader/HDJFormCardLoader';
import { CustomCheckBox } from '../InputField/CustomCheckBox';
import { SearchInputField } from '../InputField/SearchInputField';
import { CustomText } from '../Text/CustomText';
import { checkboxLabel, handleSetSelectedItems } from './HDJManagementLogic';
import useStyles from './styles';
import { UserContext } from 'src/providers/UserProvider';
import { baseFontSize } from 'src/utils/constants/baseStyles';

interface HDJManagementFormCardProps {
    items?: Array<any>;
    labelKey?: string;
    searchData?: Function;
    onClick?: Function;
    secondLabelKey?: string;
    loading?: boolean;
    subMenuKey?: string;
    placeholder?: string;
    handleChange?: Function;
    selectedRows?: Set<number>;
    maxItems?: number;
    contentHeight?: number;
    handleSearch?: any;
    disableCheckBox?: boolean;
    isPatients?: boolean;
    isIntervenants?: boolean;
    noSearchHeader?: boolean;
    setSelectedIntervenants?: any;
    isForProgram?: boolean;
}

export const HDJManagementFormCard: FC<HDJManagementFormCardProps> = (props) => {
    const {
        handleChange,
        items,
        loading,
        placeholder,
        labelKey,
        secondLabelKey,
        subMenuKey,
        selectedRows,
        maxItems,
        contentHeight,
        handleSearch,
        disableCheckBox,
        isPatients,
        isIntervenants,
        noSearchHeader,
        setSelectedIntervenants,
    } = props;
    const classes = useStyles();
    const { isDoctor } = useContext(UserContext);
    const [selected, setSelected] = useState<any>(selectedRows);
    const [forceRerender, setForceRerender] = useState<boolean>(false);
    const [query, setQuery] = useState<string>('');
    const max = maxItems ?? 10;

    /*const handleSetSelected = () => {
        if (Array.from(selectedRows ?? [])?.length && Array.from(selected ?? [])?.length === 0) {
            setSelected(selectedRows);
        }
    };
    
    useEffect(() => {
        handleSetSelected();
    }, [selectedRows]);*/

    return (
        <Box className={classes.container}>
            {!noSearchHeader && (
                <SearchInputField
                    value={query}
                    placeholder={placeholder ? placeholder : 'Rechercher par nom'}
                    onChange={(value: string) => {
                        setQuery(value);
                        handleSearch?.(value);
                    }}
                />
            )}

            {isDoctor ? (
                <Box>
                    {items && items?.length > 0 ? (
                        <Box
                            sx={{
                                overflow: 'hidden',
                                overflowY: 'auto',
                                height: contentHeight ? contentHeight : 200,
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            {items?.slice(0, items?.length > max ? max : items?.length).map((item: any, i: number) => (
                                <Box key={i} style={{ alignSelf: 'flex-start' }}>
                                    <CustomCheckBox
                                        disabled={disableCheckBox}
                                        onClick={handleSetSelectedItems({
                                            selected,
                                            item,
                                            setSelectedIntervenants,
                                            setSelected,
                                            handleChange,
                                            setForceRerender,
                                            forceRerender,
                                        })}
                                        checked={disableCheckBox ? disableCheckBox : selected?.has(item.id)}
                                        label={checkboxLabel({
                                            isPatients,
                                            isIntervenants,
                                            item,
                                            labelKey,
                                            secondLabelKey,
                                            subMenuKey,
                                        })}
                                    />
                                </Box>
                            ))}
                        </Box>
                    ) : (
                        <Box
                            sx={{
                                overflow: 'hidden',
                                overflowY: 'auto',
                                height: contentHeight ? contentHeight : 200,
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <CustomText style={{ textAlign: 'center', fontSize: baseFontSize.sm }}>
                                Aucun contenu trouvé.
                            </CustomText>
                        </Box>
                    )}
                </Box>
            ) : (
                <Box>
                    {loading ? (
                        <HDJFormCardLoading />
                    ) : items && items?.length > 0 ? (
                        <Box
                            sx={{
                                overflow: 'hidden',
                                overflowY: 'auto',
                                height: contentHeight ? contentHeight : 200,
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            {items?.slice(0, items?.length > max ? max : items?.length).map((item: any, i: number) => (
                                <Box key={i} style={{ alignSelf: 'flex-start' }}>
                                    <CustomCheckBox
                                        disabled={disableCheckBox}
                                        onClick={handleSetSelectedItems({
                                            selected,
                                            item,
                                            setSelectedIntervenants,
                                            setSelected,
                                            handleChange,
                                            setForceRerender,
                                            forceRerender,
                                        })}
                                        checked={disableCheckBox ? disableCheckBox : selected?.has(item.id)}
                                        label={checkboxLabel({
                                            isPatients,
                                            isIntervenants,
                                            item,
                                            labelKey,
                                            secondLabelKey,
                                            subMenuKey,
                                        })}
                                    />
                                </Box>
                            ))}
                        </Box>
                    ) : (
                        <Box
                            sx={{
                                overflow: 'hidden',
                                overflowY: 'auto',
                                height: contentHeight ? contentHeight : 200,
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <CustomText style={{ textAlign: 'center', fontSize: baseFontSize.sm }}>
                                Aucun contenu trouvé.
                            </CustomText>
                        </Box>
                    )}
                </Box>
            )}
        </Box>
    );
};
