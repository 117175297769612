import { useState, FC } from 'react';
import { createContext } from 'react';

export type AxeByProgram = { axisId: number; programId: number };

type EducationalDiagnosisProviderType = {
    educationalDiagnosisProgramByAxe: AxeByProgram[];
    setEducationalDiagnosisProgramByAxe: (val: any) => void;

    activeDefaultTab: boolean;
    setActiveDefaultTab: (val: any) => void;
};

export const EducationalDiagnosisContext = createContext<EducationalDiagnosisProviderType>({
    educationalDiagnosisProgramByAxe: [],
    setEducationalDiagnosisProgramByAxe: () => {},

    activeDefaultTab: false,
    setActiveDefaultTab: () => {},
});

const EducationalDiagnosisProvider: FC = (props) => {
    const { children } = props;
    const [educationalDiagnosisProgramByAxe, setEducationalDiagnosisProgramByAxe] = useState<AxeByProgram[]>([]);
    const [activeDefaultTab, setActiveDefaultTab] = useState<boolean>(false);

    return (
        <EducationalDiagnosisContext.Provider
            value={{
                educationalDiagnosisProgramByAxe,
                setEducationalDiagnosisProgramByAxe,

                activeDefaultTab,
                setActiveDefaultTab,
            }}
        >
            {children}
        </EducationalDiagnosisContext.Provider>
    );
};

export default EducationalDiagnosisProvider;
