import axios from './AxiosInstance';
import qs from 'qs';

export const getAttendeesSpeciality = () => {
    return axios.get('api/specialityTypes');
};

export const getAttendeesType = () => { 
    return axios.get('api/attendeeTypes');
};

export const getAttendeesBySpeciality = (specialityId: number, professional?: boolean, hasEtp?: boolean, notExternal?:boolean) => {
    let additionalUrl: string = '';

    if (professional) additionalUrl += '&professional=true';
    
    if (hasEtp) additionalUrl += '&hasEtp=true';

    if (notExternal) additionalUrl += '&isExternal=false';

    return axios.get('api/users?paginated=false&specialityTypeId=' + specialityId + additionalUrl);
};

export const getAttendeesBySpecialityForNewWorkshop = (specialityTypeIds: number[]) => {
    return axios.get(`api/users`, {
        params: {
            specialityTypeIds: specialityTypeIds,
        },
        paramsSerializer: (params) => qs.stringify(params),
    });
};

export const getIntervenants = () => {
    return axios.get('api/users?role=doctor&paginated=false');
};

export const getCoordinator = () => {
    return axios.get('api/users?role=coordinator&paginated=false');
};
