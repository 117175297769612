
export enum DashboardActionType{
    MY_DAY_TO_NUMBER = 'MY_DAY_TO_NUMBER',
    PROGRAMMED_WORKSHOPS = 'PROGRAMMED_WORKSHOPS',
    MY_SCHEDULED_WORKSHOPS = 'MY_SCHEDULED_WORKSHOPS',
    APPOINTMENTS = 'APPOINTMENTS',
    DAILY_PATIENTS = 'DAILY_PATIENTS',
    DAILY_PATIENTS_BY_DATE = 'DAILY_PATIENTS_BY_DATE',
}

export interface MyDayToNumber{
   type: DashboardActionType.MY_DAY_TO_NUMBER;
   payload: any
}
export interface DailyPatients{
   type: DashboardActionType.DAILY_PATIENTS;
   payload: any
}

export interface DailyPatientsByDate{
   type: DashboardActionType.DAILY_PATIENTS_BY_DATE;
   payload: any
}
export interface ProgrammedWorkshops {
   type: DashboardActionType.PROGRAMMED_WORKSHOPS;
   payload: any
}

export interface MyScheduledWorkshops {
   type: DashboardActionType.MY_SCHEDULED_WORKSHOPS;
   payload: any
}


export interface Appointments {
   type: DashboardActionType.APPOINTMENTS;
   payload: any
}

export type DashboardAction = MyDayToNumber | ProgrammedWorkshops | MyScheduledWorkshops | DailyPatients | Appointments;
