import { Consultation } from 'src/interfaces/interfaces';
import axios from './AxiosInstance';

export const getConsultations = () => {
    return axios.get('api/consultations');
};

//should create an api for this
export const searchConsultation = () => {
    return axios.get('api/consultations?paginated=false');
};

export const postConsultations = (data: Consultation) => {
    return axios.post('api/consultations', { ...data });
};

export const editConsultations = (id:number ,data: Consultation) => {
    return axios.put(`api/consultations/${id}`, { ...data });
};

export const getConsultationTypes = () => {
    return axios.get('api/consultationTypes?paginated=false');
}

export const deleteConsultations = (id:number) => {
    return axios.delete(`api/consultations/${id}`);
};
