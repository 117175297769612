import { Typography } from '@mui/material';
import React, { FC } from 'react';
import { baseColors } from '../../utils/constants/baseStyles';
import useStyles from './styles';
import { Box } from '@mui/system';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

interface CustomFilterArrowDownProps {
    label: string;
    width?: number;
}

const CustomFilterArrowDown: FC<CustomFilterArrowDownProps> = (props) => {
    const { label, width } = props;
    const classes = useStyles();
    const handleClick = () => {};

    return (
        <Box onClick={handleClick} marginBottom={2} sx={{ width: width ?? '95%' }} className={classes.container}>
            <Typography fontSize={13} color={baseColors.green.primary} className={classes.label}>
                {label}
            </Typography>
            <KeyboardArrowDownIcon sx={{ color: baseColors.green.primary }} />
        </Box>
    );
};

export default CustomFilterArrowDown;
