import {
    RETRIEVE_CONSULTATION_FILTERS,
    RETRIEVE_DAYHOSPITALS,
    RETRIEVE_DAYHOSPITALS_WITH_FILTERS,
    RETRIEVE_DAYHOSPITAL_BY_ID,
    RETRIEVE_PLANNING_BY_SPECIALITY,
} from './types';
import DayHospitalDataService from 'src/services/Hdj/dayHospital.service';
import { HdjFilters, PlanningFilters, SpecialitySetValues } from 'src/interfaces/interfaces';
import { transformFilters } from 'src/views/MyAgenda/Planning/PlanningDetailsLogic';

export const retrieveDayHospitals =
    (setLoadingHdjList: (val: boolean) => void, setHdjList: any, callBack: Function = () => {}) =>
    async (dispatch: (arg0: { type: string; payload: any }) => void) => {
        setLoadingHdjList(true);
        try {
            const res = await DayHospitalDataService.getAll();
            dispatch({
                type: RETRIEVE_DAYHOSPITALS,
                payload: res.data,
            });
            if (res.data) {
                setHdjList(res?.data?.data);
                setLoadingHdjList(false);
                callBack?.();
            }
        } catch (err) {
            console.log(err);
            setLoadingHdjList(false);
        }
    };

export const retrieveDayHospitalById =
    (setLoadingHdj: (val: boolean) => void, dayHospitalId: number, setHdj?: any) =>
    async (dispatch: (arg0: { type: string; payload: any }) => void) => {
        setLoadingHdj(true);
        try {
            const res = await DayHospitalDataService.getDayHospitalById(dayHospitalId);
            dispatch({
                type: RETRIEVE_DAYHOSPITAL_BY_ID,
                payload: res.data,
            });
            if (res.data) {
                setLoadingHdj(false);
                const hdjData = res?.data?.data;
                if (hdjData && +hdjData?.id > 0) {
                    setHdj &&
                        setHdj({
                            icon: '',
                            name: hdjData?.name ?? '',
                            consultationCount: hdjData?.consultationCount ?? 0,
                            hourCount: hdjData?.hourCount ?? 2,
                            workshopIds: Array.from(hdjData?.workshops, (item: any) => item?.id) ?? [],
                            consultationIds: Array.from(hdjData?.consultations, (item: any) => item?.id) ?? [],
                            documentIds: Array.from(hdjData?.documents, (item: any) => item?.id) ?? [],
                            coordinatorId: hdjData?.coordinator?.id ?? 0,
                        });
                }
            }
        } catch (err) {
            console.log(err);
            setLoadingHdj(false);
        }
    };

export const retrievePlanning =
    (
        planningBy: 'speciality' | 'room',
        checkedDate: string,
        Ids: number[],
        setValues: SpecialitySetValues,
        filters: PlanningFilters,
    ) =>
    async (dispatch: (arg0: { type: string; payload: any }) => void) => {
        const { setLoadingList, setPlanningFilters } = setValues;
        const { filterId, consultationEventTypeId } = filters;

        setLoadingList(true);
        try {
            const filterIds = {
                filterId: filterId,
                consultationEventTypeId: consultationEventTypeId,
            };
            const dayHospitalIds = Ids;

            const res = await DayHospitalDataService.getPlannig(planningBy, checkedDate, dayHospitalIds, filterIds);

            dispatch({
                type: RETRIEVE_PLANNING_BY_SPECIALITY,
                payload: res.data,
            });

            if (res.data) {
                setPlanningFilters && setPlanningFilters(transformFilters(res.data?.data, planningBy));
                setLoadingList(false);
            }
        } catch (err) {
            console.log(err);
            setLoadingList(false);
        }
    };

export const retrieveConsultationFilters =
    (setConsultationFilters: any) => async (dispatch: (arg0: { type: string; payload: any }) => void) => {
        try {
            const res = await DayHospitalDataService.getAllConsultationFilters(1);

            dispatch({
                type: RETRIEVE_CONSULTATION_FILTERS,
                payload: res.data,
            });

            if (res.data) {
                setConsultationFilters(transformFilters(res.data?.data?.items));
            }
        } catch (err) {
            console.log('err, ', err);
        }
    };

export const retrieveDayHospitalsWithFilters =
    (filters: HdjFilters) => async (dispatch: (arg0: { type: string; payload: any }) => void) => {
        const { setLoadingHdjList, setHdjList, searchQuery, nameFilter, specialityTypeId } = filters;

        setLoadingHdjList(true);
        try {
            const res = await DayHospitalDataService.hdjFilter(searchQuery, nameFilter, specialityTypeId);
            dispatch({
                type: RETRIEVE_DAYHOSPITALS_WITH_FILTERS,
                payload: res.data,
            });
            if (res?.data) {
                setHdjList(res?.data?.data);
                setLoadingHdjList(false);
            }
        } catch (err) {
            console.log(err);
            setLoadingHdjList(false);
        }
    };
