import { Dispatch, SetStateAction } from 'react';
import Popover from '@mui/material/Popover';
import { baseColors } from 'src/utils/constants/baseStyles';
import { CustomCheckBox } from '../CustomCheckBox';
import { OpenSansSemiBold } from 'src/components/Text/OpenSansSemiBold';

interface IProps {
    filterTitle?: string;
    filterItems: { id: number; name: string }[];
    anchorEl: HTMLButtonElement | null;
    setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
    selectedFilterIds?: number[];
    setSelectedFilterIds?: Dispatch<SetStateAction<number[]>>;
    defaultTitle?: string;
}

const FilterPopover: React.FC<IProps> = ({
    selectedFilterIds,
    setSelectedFilterIds,
    filterTitle,
    filterItems,
    anchorEl,
    setAnchorEl,
}) => {
    const open = Boolean(anchorEl);
    const id = open ? 'filter-popover' : undefined;
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (selectedItemId: number) => {
        setSelectedFilterIds?.((prev) => {
            let prevIds = new Set(prev);
            if (prevIds.has(selectedItemId)) {
                prevIds.delete(selectedItemId);
            } else {
                prevIds.add(selectedItemId);
            }
            return Array.from(prevIds);
        });
    };

    return (
        <div style={{ backgroundColor: baseColors.white }}>
            <Popover
                id={id}
                style={{ padding: 10 }}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <OpenSansSemiBold
                    fontSize={16}
                    style={{ textAlign: 'center', padding: 10 }}
                    color={baseColors.green.primary}
                >
                    {filterTitle ?? 'Filtre par Pathologie'}
                </OpenSansSemiBold>

                <div style={{ margin: '0 10px', overflowY: 'scroll', maxHeight: 'calc(50vh)' }}>
                    {filterItems?.map((item) => (
                        <CustomCheckBox
                            onClick={() => handleClick(item.id)}
                            checked={selectedFilterIds?.includes(item.id) ?? false}
                            label={item.name}
                        />
                    ))}
                </div>
            </Popover>
        </div>
    );
};

export default FilterPopover;
