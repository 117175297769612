import { Typography, Box } from '@mui/material';
import React, { FC, useContext } from 'react';
import useStyles from './styles';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { baseColors } from 'src/utils/constants/baseStyles';
import { checkIfEditableWorkshop } from 'src/utils/utils';
import { DashboardContext } from 'src/providers/DashboardProvider';

interface RdvWorkShopItemProps {
    label: string;
    programme?: string;
    date: string;
    orange?: boolean;
    progressValue?: number;
    progressMaxValue?: number;
}

const RdvWorkShopItem: FC<RdvWorkShopItemProps> = (props) => {
    const { label, date, orange, progressValue, progressMaxValue } = props;
    const classes = useStyles();
    let trailColor = orange ? baseColors.orange.light : baseColors.green.light;
    let pathColor = orange ? baseColors.orange.primary : baseColors.green.primary;

    const handleClick = () => {};

    return (
        <Box onClick={handleClick} className={classes.container}>
            <Box>
                <Typography fontSize={12} className={classes.label}>
                    {label}
                </Typography>
                <Typography fontSize={10} className={classes.date}>
                    {date}
                </Typography>
            </Box>
            <Box style={{ width: 50, height: 50 }}>
                <CircularProgressbar
                    value={progressValue ? progressValue : 0}
                    maxValue={progressMaxValue}
                    text={progressValue !== null ? `${progressValue} / ${progressMaxValue}` : 'N/A'}
                    styles={buildStyles({
                        strokeLinecap: 'butt',
                        textSize: '25px',
                        pathTransitionDuration: 3,
                        pathColor: pathColor,
                        textColor: baseColors.black,
                        trailColor: trailColor,
                    })}
                />
            </Box>
        </Box>
    );
};

export default RdvWorkShopItem;
