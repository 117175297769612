import { Dispatch, FC, SetStateAction } from 'react';
import { useEffect, useState } from 'react';
import CustomModal from 'src/components/CustomModal';
import ListItemFormCheckBox from 'src/components/ListItemFormCheckBox';
import { searchElementByName } from 'src/utils/utils';
import { PostProgramSkillPayload, SkillItem } from 'src/interfaces/interfaces';
import { sxStyles } from './styles';
import { CustomText } from 'src/components/Text/CustomText';
import { baseColors, baseFontFamily, baseFontSize } from 'src/utils/constants/baseStyles';
import { Spacer } from 'src/components/Spacer/Spacer';
import { defaultProgramSkillPaylopad, handleSetSkillPayload } from '../ProgramSkill.utils';
import { AddSkillProgramFormsFooter } from './AddSkillProgramFormsFooter/AddSkillProgramFormsFooter';
import useSkillHooks from 'src/hooks/useSkillHooks';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import { SkillModal } from 'src/views/Settings/Skills/SkillModal';

type IProps = {
    programId: number;
    open: boolean;
    skill: SkillItem;
    setSkill: Dispatch<SetStateAction<SkillItem>>;
    toggleModal: any;
    setUpdateList: Dispatch<SetStateAction<boolean>>;
    preselectedSkillIds: Set<number>;
};

export const AddSkillProgram: FC<IProps> = (props) => {
    const { preselectedSkillIds, programId, setSkill, open, toggleModal, setUpdateList, skill } = props;
    const { optimizedSkills, fetchingSkills } = useSkillHooks({});
    const [payload, setPayload] = useState<PostProgramSkillPayload>(defaultProgramSkillPaylopad);

    const [moduleFormOpen, setModuleFormOpen] = useState(false);

    const toggleSkillModal = () => {
        setModuleFormOpen(!moduleFormOpen);
    };

    useEffect(() => {
        handleSetSkillPayload({ setPayload, preselectedSkillIds });
    }, [preselectedSkillIds]);

    const [searchkey, setSearchkey] = useState<string>('');
    const [searchedItems, setSearchedItems] = useState<Array<any>>([]);
    const handleSearch = (value: string) => setSearchkey(value);

    useEffect(() => {
        setSearchedItems(searchElementByName(optimizedSkills ?? [], searchkey, 'name'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchkey]);

    const finalData = searchkey?.trim()?.length > 0 ? searchedItems : optimizedSkills;

    const clearFormData = () => {
        setPayload(defaultProgramSkillPaylopad);
    };

    const title = skill?.id > 0 ? 'Modifier la compétence' : 'Lier de(s) compétence(s) au programme';

    return (
        <CustomModal
            disableBackdropClick
            title={title}
            open={open}
            toggleModal={toggleModal}
            footer={
                <AddSkillProgramFormsFooter
                    skill={skill}
                    setSkill={setSkill}
                    setPayload={setPayload}
                    payload={payload}
                    toggleModal={toggleModal}
                    setUpdateList={setUpdateList}
                    programId={programId}
                />
            }
            width={700}
            clearFormData={clearFormData}
        >
            <Spacer />
            <div style={sxStyles.workshops}>
                <div
                    style={{
                        alignItems: 'center',
                        flexDirection: 'row',
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <CustomText fontFamily={baseFontFamily.OpenSansSemiBold} style={{}} fontSize={baseFontSize.mini}>
                        Compétences :
                    </CustomText>

                    <CustomLoadingButton
                        style={{ backgroundColor: baseColors.orange.primary }}
                        width={280}
                        label="Ajouter une nouvelle competence"
                        handleClick={toggleSkillModal}
                    />
                </div>

                <Spacer height={10} />

                <ListItemFormCheckBox
                    selectedRows={new Set(payload?.skillIds)}
                    handleChange={(value: any) => setPayload((old: any) => ({ ...old, skillIds: Array.from(value) }))}
                    items={finalData}
                    loading={fetchingSkills}
                    labelKey="name"
                    handleSearch={(value: string) => handleSearch(value)}
                    maxItems={optimizedSkills?.length}
                />
            </div>

            <SkillModal open={moduleFormOpen} skill={skill} setSkill={setSkill} toggleModal={toggleSkillModal} />
        </CustomModal>
    );
};
