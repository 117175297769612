import { makeStyles } from '@mui/styles';
import { baseBorderRadius, baseColors, baseScreenHeight } from 'src/utils/constants/baseStyles';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        minWidth: 660,
        backgroundColor: baseColors.white,
        ...baseScreenHeight.defaultHeight,
        ...baseBorderRadius.leftOnly,
    },
    wrapper: {
        height: `calc(100vh - 220px)`,
        padding: 20,
        justifyContent: 'center',
        overflow: 'hidden',
        overflowY: 'scroll',
    },
});

export default useStyles;
