import { FC, useEffect, useState } from 'react';
import CustomCircleLoader from 'src/components/CustomLoader/CustomCircleLoader';
import CustomModal from 'src/components/CustomModal';
import { Spacer } from 'src/components/Spacer/Spacer';
import { CustomText } from 'src/components/Text/CustomText';
import { baseFontFamily, baseColors } from 'src/utils/constants/baseStyles';
import useStyles from './styles';
import { Divider } from '@mui/material';
import useEvaluationHooks from 'src/hooks/useEvaluationHooks';

interface EvaluationReportDetailsProps {
    open: boolean;
    setOpen: Function;
    patientId?: number;
    reportData?: any;
}

export const EvaluationReportDetails: FC<EvaluationReportDetailsProps> = (props) => {
    const { open, setOpen, patientId, reportData } = props;
    const defaultReport = {
        patientId: patientId,
        name: '',
        programEnteringReason: '',
        medicalAntecedent: '',
        treatments: '',
        educationalDiagnosisConclusion: '',
        followedProgramContent: '',
        interviewConclusion: '',
        tool: '',
    };

    const [report, setReport] = useState<any>(defaultReport);
    const [loading, setLoading] = useState<boolean>(false);
    const classes = useStyles();

    useEffect(() => {
        setReport((old: any) => ({ ...old, patientId: patientId }));

        if (!reportData) {
            setReport(defaultReport);
        } else {
            setLoading(true);
            setReport({
                name: reportData?.name,
                programEnteringReason: reportData?.programEnteringReason,
                medicalAntecedent: reportData?.medicalAntecedent,
                treatments: reportData?.treatments,
                educationalDiagnosisConclusion: reportData?.educationalDiagnosisConclusion,
                followedProgramContent: reportData?.followedProgramContent,
                interviewConclusion: reportData?.interviewConclusion,
                tool: reportData?.tool,
            });
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportData]);

    const Items = [
        { label: 'Quels est le titre du compte rendu ?', value: report?.name },
        {
            label: "Quels sont les motifs d'entrée dans le programme ?",
            value: report?.programEnteringReason,
        },
        {
            label: 'Quels sont les antécédents médicaux ?',
            value: report?.medicalAntecedent,
        },
        {
            label: 'Quels sont les traitements à appliquer ?',
            value: report?.treatments,
        },
        {
            label: 'Quels sont les conclusions du diagnostique éducatif ?',
            value: report?.educationalDiagnosisConclusion,
        },
        {
            label: 'Quel est le contenu du programme suivi ?',
            value: report?.followedProgramContent,
        },
        {
            label: "Quel est la conclusion de l'entretien d'évaluation ?",
            value: report?.interviewConclusion,
        },
    ];

    return (
        <CustomModal
            title="Détails du compte rendu"
            width="100%"
            height={'75vh'}
            size="large"
            open={open}
            toggleModal={setOpen}
            footer={
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {/* <CustomLoadingButton
                        orange
                        width="48%"
                        label="Exporter en PDF"
                        handleClick={() => handleExportReportPdf(report)}
                    /> */}
                </div>
            }
        >
            <div className={classes.scrollContainer} style={{ marginTop: 30, paddingRight: 15 }}>
                <Spacer height={20} />
                {loading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', padding: 20 }}>
                        <CustomCircleLoader />
                    </div>
                ) : (
                    <div className={classes.col}>
                        {Items.map((detail, index) => (
                            <div key={index}>
                                <div className={classes.row} style={{ alignItems: 'center' }}>
                                    <CustomText
                                        style={{ width: '28%', marginRight: 20 }}
                                        fontFamily={baseFontFamily.OpenSansSemiBold}
                                        color={baseColors.orange.primary}
                                    >
                                        {detail.label} :
                                    </CustomText>
                                    <CustomText style={{ width: '70%' }}>{detail.value}</CustomText>
                                </div>

                                <Divider
                                    style={{
                                        backgroundColor: baseColors.orange.light,
                                        marginTop: 20,
                                        marginBottom: 20,
                                    }}
                                />
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </CustomModal>
    );
};
