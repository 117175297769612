import { PostGoalPayload } from 'src/interfaces/interfaces';
import axios from '../AxiosInstance';

const GetGoals = () => {
    return axios.get('api/objectif');
};

const PostNewGoal = ({ payload }: { payload: PostGoalPayload }) => {
    return axios.post('api/objectif', payload);
};

const EditGoal = ({ goalId, payload }: { goalId: number; payload: PostGoalPayload }) => {
    return axios.put(`api/objectif/${goalId}`, { ...payload });
};

const DeleteGoal = ({ goalId }: { goalId: number }) => {
    return axios.delete(`api/objectif/${goalId}`);
};

const GoalService = {
    GetGoals,
    PostNewGoal,
    DeleteGoal,
    EditGoal,
};

export default GoalService;
