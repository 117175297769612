import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { baseColors } from 'src/utils/constants/baseStyles';
import { Box } from '@mui/material';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import CustomModal from 'src/components/CustomModal/CustomModal';
import { Spacer } from 'src/components/Spacer/Spacer';
import { CustomText } from 'src/components/Text/CustomText';
import { ProgramItem } from 'src/interfaces/interfaces';

interface LeaveProgramFormProps {
    showLeaveConfirm?: boolean;
    setShowLeaveConfirm:any;
    targetLocation:string;
    handleSaveProgram:any;
    disabledAddProgram:boolean;
    program?: ProgramItem
}

export const LeaveProgramForm: FC<LeaveProgramFormProps> = (props) => {
    const { showLeaveConfirm,setShowLeaveConfirm,targetLocation,handleSaveProgram,disabledAddProgram, program} = props;
    const [modalVisible, updateModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const goToProgramList = () => {
        history.block((location, action):any => true);
        history.push(targetLocation);
    };
    const continueModification = async () => {
        setShowLeaveConfirm(false)
    };
    const handleSaveAsDraft =  () => {
        setShowLeaveConfirm(false);
        handleSaveProgram(true);
        history.block((location, action):any => true);
        history.push(targetLocation);
    }

    const handleSaveProgramAsNormal =  () => {
        setShowLeaveConfirm(false);
        handleSaveProgram();
        history.push(targetLocation);
    }

    useEffect(()=>{
        showLeaveConfirm ? updateModalVisible(true) : updateModalVisible(false)
    },[showLeaveConfirm])

    return (
        <>
            <CustomModal disableBackdropClick open={modalVisible} toggleModal={updateModalVisible} title="Attention !">
                <Box>
                    <CustomText style={{ textAlign: 'center' }}>
                        Les modifications n'ont pas été sauvegardées. <br></br>Voulez vous vraiment quitter cette page ?
                    </CustomText>
                    <Spacer height={30} />
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '10px' }}>
                        <CustomLoadingButton
                            orange
                            disabled={loading}
                            label="Quitter"
                            handleClick={goToProgramList}
                        />

                        {program?.state === 1 ?
                            <CustomLoadingButton
                                style={{ backgroundColor: baseColors.orange.primary, marginRight: 20 }}
                                label="Enregistrer comme brouillon"
                                //disabled={disabledAddProgram}
                                loading={loading}
                                handleClick={handleSaveAsDraft}
                            /> :
                            <CustomLoadingButton
                                style={{ backgroundColor: baseColors.green.primary, marginRight: 20 }}
                                label="Continuer la modification"
                                //disabled={disabledAddProgram}
                                loading={loading}
                                handleClick={continueModification}
                            />

                        }
                    </div>
                </Box>
            </CustomModal>
        </>
    );
};
