import { Box } from '@mui/material';
import { useState } from 'react';
import { baseColors, baseScreenHeight } from 'src/utils/constants/baseStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import { SkillModal } from './SkillModal';
import CustomEmptyData from 'src/components/CustomEmptyData';
import { sxStyles } from './styles';
import { CustomText } from 'src/components/Text/CustomText';
import { tableCellHeadText } from 'src/views/Security/SecurityManagement/SecurityManagement';
import SubMenusLoader from 'src/components/SettingsSubMenuBar/SubMenusLoader/SubMenusLoader';
import useSkillHooks from 'src/hooks/useSkillHooks';
import { SkillItemContent } from './SkillItem/SkillItem';
import { SkillItem } from 'src/interfaces/interfaces';

export const defaultModule = {
    id: 0,
    name: '',
    category: '',
    duration: '',
    workshopIds: [],
};

export const SkillsPanel = () => {
    const [moduleFormOpen, setModuleFormOpen] = useState(false);
    const { skills, fetchingSkills } = useSkillHooks({});
    const [skill, setSkill] = useState<SkillItem>({} as SkillItem);

    const toggleModal = () => setModuleFormOpen(!moduleFormOpen);

    const tableHeaders = [{ name: '' }, { name: 'Compétence' }, { name: 'Action' }];

    return (
        <Box sx={sxStyles.container}>
            <Box sx={sxStyles.header}>
                <CustomText isBreefSerif isTitle color={baseColors.green.primary} style={{ marginLeft: 20 }}>
                    Liste des compétences
                </CustomText>

                <CustomLoadingButton
                    disabled={fetchingSkills}
                    label="Ajouter une nouvelle compétence"
                    handleClick={toggleModal}
                />
            </Box>

            {fetchingSkills ? (
                <SubMenusLoader />
            ) : skills?.length === 0 ? (
                <div style={{ paddingBottom: 150 }}>
                    <CustomEmptyData />
                </div>
            ) : (
                <TableContainer
                    component={Paper}
                    style={{ overflow: 'auto', maxHeight: baseScreenHeight.tableContainerHeight }}
                >
                    <Table stickyHeader sx={{ minWidth: 650 }} aria-label="role table">
                        <TableHead>
                            <TableRow>
                                {tableHeaders.map((header, index) => {
                                    return (
                                        <TableCell key={index} sx={{ color: baseColors.green.primary }}>
                                            {tableCellHeadText(header.name)}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {skills?.map((skill: SkillItem, index: Number) => (
                                <SkillItemContent
                                    key={index.toString()}
                                    skill={skill}
                                    toggleModal={toggleModal}
                                    setSkill={setSkill}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            <SkillModal open={moduleFormOpen} skill={skill} setSkill={setSkill} toggleModal={toggleModal} />
        </Box>
    );
};
