import axios from './AxiosInstance';

const programSyntheses = (data: any) => axios.post(`/api/syntheses`, { ...data });

const closePatientProgram = (data: any) => axios.post(`/api/programs/endPatientProgram`, { ...data });

const syntheseDestinations = () => axios.get('api/syntheseDestinations');

const exportSynthese = (programId: number) => axios.get(`/api/programs/${programId}/exportSynthese`);

const programSynthese = (data: any) => axios.post(`/api/programs/endPatientProgram`, { ...data });

export { programSyntheses, syntheseDestinations, closePatientProgram, exportSynthese, programSynthese };
