import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { baseColors, baseScreenHeight } from 'src/utils/constants/baseStyles';
import ModuleService from 'src/services/Module/module.service';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { toast } from 'react-toastify';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import { ModuleItem } from './ModuleItem';
import { ModuleModale } from './ModuleModale';
import CustomEmptyData from 'src/components/CustomEmptyData';
import { sxStyles } from './styles';
import { CustomText } from 'src/components/Text/CustomText';
import { tableCellHeadText } from 'src/views/Security/SecurityManagement/SecurityManagement';
import SubMenusLoader from 'src/components/SettingsSubMenuBar/SubMenusLoader/SubMenusLoader';

export const defaultModule = {
    id: 0,
    name: '',
    category: '',
    duration: '',
    workshopIds: [],
};

export const ModulesPanel = () => {
    const [modules, setModules] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [moduleFormOpen, setModuleFormOpen] = useState(false);
    const [updateList, setUpdateList] = useState(false);
    const [payload, setPayload] = useState(defaultModule);

    const toggleModal = () => {
        setModuleFormOpen(!moduleFormOpen);
        setPayload(defaultModule);
    };
    const tableHeaders = [{ name: '' }, { name: 'Nom' }, { name: 'Action' }];

    const onEdit = (data: any) => {
        let moduleToEdit = structuredClone(data);
        moduleToEdit.workshopIds = moduleToEdit.workshops.map((item: any) => item.id);
        delete moduleToEdit['workshops'];
        moduleToEdit.duration = moduleToEdit?.duration?.substr(0, moduleToEdit?.duration?.length - 1);
        setPayload(moduleToEdit);
        setModuleFormOpen(true);
    };

    const fetchModules = () => {
        setLoading(true);
        ModuleService.getModules()
            .then((response) => {
                const data = response.data?.data;
                if (data) {
                    setModules(data?.filter((item: any) => item.deleted === 0));
                }
                setLoading(false);
            })
            .catch((e) => {
                toast.error('Une erreur est survenue lors de la récupération des modules');
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchModules();
    }, [updateList]);

    return (
        <Box sx={sxStyles.container}>
            <Box sx={sxStyles.header}>
                <CustomText isBreefSerif isTitle color={baseColors.green.primary} style={{ marginLeft: 20 }}>
                    Liste des modules
                </CustomText>

                <CustomLoadingButton disabled={loading} label="AJOUTER UN NOUVEAU MODULE" handleClick={toggleModal} />
            </Box>

            {loading ? (
                <SubMenusLoader />
            ) : modules?.length === 0 ? (
                <div style={{ paddingBottom: 150 }}>
                    <CustomEmptyData />
                </div>
            ) : (
                <TableContainer
                    component={Paper}
                    style={{ overflow: 'auto', maxHeight: baseScreenHeight.tableContainerHeight }}
                >
                    <Table stickyHeader sx={{ minWidth: 650,borderCollapse: "collapse" }} aria-label="role table">
                        <TableHead>
                            <TableRow>
                                {tableHeaders.map((header, index) => {
                                    return (
                                        <TableCell key={index} sx={{ color: baseColors.green.primary }}>
                                            {tableCellHeadText(header.name)}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {modules.map((row: any, index: Number) => (
                                <ModuleItem
                                    key={index.toString()}
                                    onEdit={onEdit}
                                    row={row}
                                    setUpdateList={setUpdateList}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            <ModuleModale
                modules={modules}
                open={moduleFormOpen}
                toggleModal={toggleModal}
                payload={payload}
                setPayload={setPayload}
                setUpdateList={setUpdateList}
            />
        </Box>
    );
};
