import { FC } from 'react';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import { removeUndefined } from './PlanningContentLogic';

interface PlanningHeaderItemProps {
    label: string;
}

const PlanningHeaderItem: FC<PlanningHeaderItemProps> = (props) => {
    const { label } = props;

    return (
        <TableCell
            align="center"
            style={{
                minWidth: 100,
            }}
            sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}
        >
            <Typography fontFamily={baseFontFamily.OpenSansSemiBold} fontSize={14} color={baseColors.black}>
                {removeUndefined(label)}
            </Typography>
        </TableCell>
    );
};

export default PlanningHeaderItem;
