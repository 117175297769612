import axios from './AxiosInstance';

export type UserThemeConfigPayload = {
    items: {
        code: string;
        value: string;
        isFile: number;
    }[];
};

const getColorPalettes = () => axios.get('api/colorPalettes?paginated=false');

const saveUserThemeConfig = (payload: UserThemeConfigPayload) =>
    axios.put('api/globalConfigDatas/changeConfigs', { items: payload.items });

const ColorPaletteService = {
    getColorPalettes,
    saveUserThemeConfig,
};

export default ColorPaletteService;
