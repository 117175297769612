import React, { useState, FC, useEffect, useContext } from 'react';
import { createContext } from 'react';
import { User } from 'src/interfaces/interfaces';
import { URER_ROLES } from 'src/utils/constants/enums';
import { LocalStorageService } from 'src/utils/storage';

type EtpListType = {
    updateList: boolean;
    setUpdateList: (val: any) => void;
};

export const EtpListContext = createContext<EtpListType>({
    updateList: false,
    setUpdateList: () => {},
});

const EtpProvider: FC = (props) => {
    const { children } = props;
    const [updateList, setUpdateList] = useState<boolean>(false);
    return (
        <EtpListContext.Provider
            value={{
                updateList,
                setUpdateList,
            }}
        >
            {children}
        </EtpListContext.Provider>
    );
};

export default EtpProvider;
