import { Box, Grid } from '@mui/material';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { CustomText } from 'src/components/Text/CustomText';
import { OptimizedItem } from 'src/interfaces/interfaces';
import { baseColors, baseFontFamily, baseFontSize } from 'src/utils/constants/baseStyles';
import { RelatedArticleGoalModal } from './RelatedArticleGoalModal/RelatedArticleGoalModal';

type IProps = {
    payload: any;
    article: any;
    lockWorkshopInput: boolean;
    setPayload: Dispatch<SetStateAction<any>>;
    loadingData?: any;
};

export const ArticleRelatedGoals: FC<IProps> = (props) => {
    const { payload, setPayload, article } = props;
    const [goalNames, setGoalNames] = useState<OptimizedItem[]>([]);
    const [open, setOpen] = useState<boolean>(false);
    const toggleModal = () => setOpen(!open);

    useEffect(() => {
        if (article?.id) {
            setGoalNames(article?.goals);
        }
    }, [article]);

    return (
        <Grid lg={12} container direction="row" alignItems="center" marginBottom="20px">
            <Grid item>
                <CustomText style={{ minWidth: '200px' }}>Objectif(s) :</CustomText>
            </Grid>

            <Grid item style={{ width: '60%' }}>
                {goalNames?.length > 0 ? (
                    <Box
                        style={{
                            cursor: 'pointer',
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            backgroundColor: baseColors.green.light,
                            padding: 12,
                            borderRadius: 10,
                        }}
                        onClick={toggleModal}
                    >
                        {goalNames?.map((goal) => (
                            <CustomText
                                key={goal.id}
                                style={{
                                    paddingRight: 20,
                                    marginBottom: 7,
                                }}
                                fontSize={baseFontSize.mini}
                                fontFamily={baseFontFamily.OpenSansSemiBold}
                            >
                                {goal.name}
                            </CustomText>
                        ))}
                    </Box>
                ) : (
                    <Grid
                        onClick={toggleModal}
                        item
                        style={{
                            cursor: 'pointer',
                            backgroundColor: baseColors.green.light,
                            padding: 12,
                            width: '100%',
                            borderRadius: 10,
                        }}
                    >
                        <CustomText
                            style={{}}
                            color={baseColors.green.primary}
                            fontSize={baseFontSize.mini}
                            fontFamily={baseFontFamily.OpenSansSemiBold}
                        >
                            Choisir des objectifs rattachés au contenu
                        </CustomText>
                    </Grid>
                )}
            </Grid>

            <RelatedArticleGoalModal
                setGoalNames={setGoalNames}
                payload={payload}
                setPayload={setPayload}
                open={open}
                toggleModal={toggleModal}
            />
        </Grid>
    );
};
