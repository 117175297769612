import { Dispatch, FC, SetStateAction } from 'react';
import { useEffect, useState } from 'react';
import CustomModal from 'src/components/CustomModal';
import ListItemFormCheckBox from 'src/components/ListItemFormCheckBox';
import { searchElementByName } from 'src/utils/utils';
import { GoalItem, OptimizedItem } from 'src/interfaces/interfaces';
import { sxStyles } from '../styles';
import { CustomText } from 'src/components/Text/CustomText';
import { baseColors, baseFontFamily, baseFontSize } from 'src/utils/constants/baseStyles';
import { Spacer } from 'src/components/Spacer/Spacer';
import useGoalHooks from 'src/hooks/useGoalHooks';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import { GoalModal } from 'src/views/Settings/Goals/GoalModal/GoalModal';
import { RelatedGoalModalFooter } from './RelatedGoalModalFooter';

type IProps = {
    open: boolean;
    toggleModal: any;
    payload: any;
    setPayload: any;
    setGoalNames: Dispatch<SetStateAction<OptimizedItem[]>>;
    customTitle?: string;
};

export const RelatedGoalModal: FC<IProps> = (props) => {
    const { setGoalNames, open, toggleModal, payload, setPayload, customTitle } = props;
    const { optimizedGoals, fetchingGoals } = useGoalHooks({});
    const [openGoalForm, setOpenGoalForm] = useState(false);
    const [goal, setGoal] = useState<GoalItem>({} as GoalItem);
    const [justAddedGoalIds, setJustAddedGoalIds] = useState<number[]>([]);
    const toggleGoalModal = () => setOpenGoalForm(!openGoalForm);

    const [searchkey, setSearchkey] = useState<string>('');
    const [searchedItems, setSearchedItems] = useState<Array<any>>([]);
    const handleSearch = (value: string) => setSearchkey(value);

    useEffect(() => {
        setSearchedItems(searchElementByName(optimizedGoals ?? [], searchkey, 'name'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchkey]);

    useEffect(() => {
        if(payload?.goalsIds){
            setPayload((prev:any) => ({...prev, goalsIds : [...payload.goalsIds,...justAddedGoalIds] }));
        }
    },[justAddedGoalIds])

    const finalData = searchkey?.trim()?.length > 0 ? searchedItems : optimizedGoals;

    const clearFormData = () => {
        // setPayload(defaultSkillPaylopad);
    };

    const title = 'Choisir les objectifs';
    const creatingGoal = (customTitle && customTitle?.length > 0) ? customTitle.split(':')?.[1] : '';
    return (
        <CustomModal
            disableBackdropClick
            title={title}
            open={open}
            toggleModal={toggleModal}
            footer={
                <RelatedGoalModalFooter
                    goals={optimizedGoals}
                    payload={payload}
                    toggleModal={toggleModal}
                    setGoalNames={setGoalNames}
                />
            }
            width={700}
            clearFormData={clearFormData}
        >
            <Spacer />

            <div style={sxStyles.workshops}>
                <div>
                    <CustomText>
                        Vous êtes en train de créer un atelier pour l'objectif <b>{creatingGoal.toUpperCase()}</b>. 
                        Vous pouvez choisir d'autres objectifs ci-dessous pour ce nouvel atelier.
                    </CustomText>
                    <Spacer height={30} />
                </div>
                <div
                    style={{
                        alignItems: 'center',
                        flexDirection: 'row',
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <CustomText
                        fontFamily={baseFontFamily.OpenSansSemiBold}
                        style={{ width: 80 }}
                        fontSize={baseFontSize.mini}
                    >
                        Objectifs :
                    </CustomText>
 
                    <CustomLoadingButton
                        style={{ backgroundColor: baseColors.orange.primary }}
                        width={280}
                        label="Ajouter un nouvel objectif"
                        handleClick={toggleGoalModal}
                    />
                    
                </div>

                <Spacer height={10} />

                <ListItemFormCheckBox
                    selectedRows={new Set(payload?.goalsIds)}
                    handleChange={(value: any) => setPayload((old: any) => ({ ...old, goalsIds: Array.from(value) }))}
                    items={finalData}
                    loading={fetchingGoals}
                    labelKey="name"
                    handleSearch={(value: string) => handleSearch(value)}
                    maxItems={optimizedGoals.length}
                />
            </div>

            <GoalModal 
                open={openGoalForm} 
                goal={goal} 
                setGoal={setGoal} 
                toggleModal={toggleGoalModal} 
                setJustAddedGoalIds={setJustAddedGoalIds}
                optionalWorkshopAndArticle
            />
        </CustomModal>
    );
};
