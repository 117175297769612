import React, { useState } from 'react';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { baseColors, baseFontSize } from '../../utils/constants/baseStyles';
import { makeStyles } from '@mui/styles';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { specialCharToUnderscore } from 'src/utils/utils';
import { CustomText } from '../Text/CustomText';
import { Grid, Typography } from '@mui/material';

type AppProps = {
    label: string;
    placeholder: string;
    onChange: Function;
    width: any;
    type?: string;
    disabled?: boolean;
    value?: string;
    regexLabel?: string;
};

const useStyles = makeStyles({
    root: {
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
    },
});

const CustomTextField = styled(TextField)({
    marginTop: 10,
    '& label.Mui-focused': {
        color: baseColors.green.primary,
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: baseColors.green.primary,
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: baseColors.grey,
            borderRadius: 10,
        },
        '&:hover fieldset': {
            borderColor: baseColors.black,
        },
        '&.Mui-focused fieldset': {
            borderColor: baseColors.green.primary,
        },
    },
});

export const InputFieldPassword = ({
    disabled,
    type,
    value,
    label,
    placeholder,
    onChange,
    width,
    regexLabel,
}: AppProps) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const classes = useStyles();

    return (
        <div style={{ width: width }}>
            <div
                style={{
                    display: 'flex',
                    flexGrow: 1,
                    marginBottom: 20,
                    flexDirection: 'column',
                    width: '100%',
                }}
            >
                <Grid container alignItems="center">
                    {' '}
                    <Grid item>
                        <CustomText color={baseColors.black} fontSize={baseFontSize.sl}>
                            {label}
                        </CustomText>
                    </Grid>
                    <Grid item>
                        {regexLabel !== undefined ? (
                            <Typography
                                color={regexLabel === '(obligatoire)' ? '' : baseColors.orange.primary}
                                style={{ marginLeft: 5, fontStyle: 'italic' }}
                                fontSize={'14px'}
                            >
                                {regexLabel}
                            </Typography>
                        ) : (
                            ''
                        )}
                    </Grid>
                </Grid>

                <CustomTextField
                    value={value}
                    disabled={disabled ?? false}
                    id={specialCharToUnderscore(label ?? '')}
                    size="small"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        onChange(event.target.value);
                    }}
                    type={isPasswordVisible ? 'text' : 'password'}
                    placeholder={placeholder}
                    InputProps={{
                        className: classes.root,
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                                >
                                    {isPasswordVisible ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </div>
        </div>
    );
};
