import { FC, useEffect, useState } from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import frLocale from 'date-fns/locale/fr';
import { baseColors, baseFontSize } from 'src/utils/constants/baseStyles';
import { styled } from '@mui/styles';
import { makeStyles } from '@mui/styles';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import EventIcon from '@mui/icons-material/Event';

interface CustomDatePickerProps {
    value: Date | null;
    setValue: (val: any) => void;
    width?: any;
    likeSelect?: boolean;
    disabled?: boolean;
    idpicker?: string;
    clear?: boolean;
    height?: number;
    inversed?: boolean;
    validator?: any;
    disablePast?: boolean;
    alignItems?: string;
    placeholder?: string;
    fontSize?: string;
}

export const CustomTextField = styled(TextField)({});

const useStyles = makeStyles({
    input: {
        marginTop: 10,
        width: '100%',

        '& label.Mui-focused': {
            color: baseColors.green.primary,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: baseColors.green.primary,
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: baseColors.grey,
                borderRadius: 10,
            },
            '&:hover fieldset': {
                borderColor: baseColors.black,
            },
            '&.Mui-focused fieldset': {
                borderColor: baseColors.green.primary,
            },
        },
    },
    inputInversed: {
        marginTop: 10,
        width: '100%',
        '& label.Mui-focused': {
            color: baseColors.green.primary,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: baseColors.green.primary,
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: baseColors.green.primary,
                borderRadius: 10,
            },
            '&:hover fieldset': {
                borderColor: baseColors.black,
            },
            '&.Mui-focused fieldset': {
                borderColor: baseColors.grey,
            },
        },
    },
    inputInvalid: {
        marginTop: 10,
        width: '100%',
        '& label.Mui-focused': {
            color: baseColors.orange.primary,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: baseColors.orange.primary,
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: baseColors.orange.primary,
                borderRadius: 10,
            },
            '&:hover fieldset': {
                borderColor: baseColors.black,
            },
            '&.Mui-focused fieldset': {
                borderColor: baseColors.orange.primary,
            },
        },
    },
});

const CustomDatePicker: FC<CustomDatePickerProps> = (props) => {
    const {
        value: defaultValue,
        setValue: setDefaultValue,
        width,
        likeSelect,
        disabled,
        idpicker,
        clear,
        validator,
        inversed,
        disablePast,
        alignItems,
        placeholder,
        fontSize,
        height,
    } = props;
    const [value, setValue] = useState<Date | null>(defaultValue);
    const [isValidDate, setIsValidDate] = useState(false);
    const classes = useStyles();

    const handleChange = (newValue: Date | null) => {
        setValue(newValue);
        setDefaultValue(newValue);
    };

    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue, clear]);

    useEffect(() => {
        setIsValidDate(validator);
    }, [validator, value]);

    const localeMap = {
        fr: frLocale,
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={localeMap['fr']}>
            <DesktopDatePicker
                disablePast={disablePast}
                label=""
                toolbarPlaceholder="date heure"
                InputAdornmentProps={{ position: 'start' }}
                inputFormat="dd/MM/yyyy"
                value={value}
                disabled={disabled ?? false}
                onChange={handleChange}
                autoFocus={false}
                className={isValidDate ? (inversed ? classes.inputInversed : classes.input) : classes.inputInvalid}
                InputProps={{ style: { color: baseColors.green.primary }, id: idpicker }}
                components={
                    likeSelect
                        ? {
                              OpenPickerIcon: () => <EventIcon sx={{ color: baseColors.green.primary }} />,
                          }
                        : {}
                }
                renderInput={(params: TextFieldProps) => (
                    <CustomTextField
                        error={true}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: 'transparent',
                                },
                                '&:hover fieldset': {
                                    borderColor: 'transparent',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: 'transparent',
                                },
                                height: height ?? undefined,
                            },
                            '& .MuiInputBase-input': {
                                fontSize: fontSize ?? baseFontSize.sm,
                            },
                            customPlaceholder: {
                                '&::placeholder': {
                                    maxWidth: '200px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                },
                            },
                        }}
                        style={{
                            width: width ? width : '100%',
                            borderWidth: 0,
                            borderRadius: 10,
                            background: baseColors.green.light,
                            display: 'flex',
                            alignItems: alignItems ?? 'center',
                            paddingTop: 4,
                            borderColor: !isValidDate ? baseColors.orange.primary : undefined,
                        }}
                        size="small"
                        {...params}
                        disabled={disabled ?? false}
                        inputProps={{
                            ...params.inputProps,
                            placeholder: placeholder ?? 'jj/mm/aaaa',
                            autoComplete: false,
                        }}
                    />
                )}
            />
        </LocalizationProvider>
    );
};

export default CustomDatePicker;
