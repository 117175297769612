/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useContext, useEffect, useState } from 'react';
import { InputField } from 'src/components/InputField/InputField';
import { NumberSelector } from 'src/components/InputField/NumberSelector';
import { Spacer } from 'src/components/Spacer/Spacer';
import { ProgramContext } from 'src/providers/ProgramProvider';
import { QuestionWithRadio, renderContent, renderTitle } from '../utils';
import useStyles from './styles';
import _ from 'lodash';
import ProgramService from 'src/services/Program/program.service';
import { toast } from 'react-toastify';
import { ServerError } from 'src/utils/constants/constants';
import CustomCircleLoader from 'src/components/CustomLoader/CustomCircleLoader';

interface DispensationProps {
    programId: number;
}
export const Dispensation: FC<DispensationProps> = (props) => {
    const { programId } = props;
    const defaultDispensation = {
        id: 0,
        programId: programId,
        frequentOnWebsite: 0,
        frequencyNumber: 1,
        frequencyPerYear: 1,
        isGlobal: 0,
        sessionsNumber: 1,
        isPresentiel: 0,
        beneficiaryStat: '',
    };
    const classes = useStyles();
    const { setSyntheseHasChange, setsyntheseChangeSaveCallback } = useContext(ProgramContext);
    const [dispensation, setDispensation] = useState<any>(defaultDispensation);
    const [oldDispensation, setOldDispensation] = useState<any>(defaultDispensation);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const hasChangeMade = !_.isEqual(dispensation, oldDispensation);
        setSyntheseHasChange(hasChangeMade);
        if (hasChangeMade) {
            setsyntheseChangeSaveCallback(() => performSaveProgramsDispensation);
        }
    }, [dispensation, oldDispensation]);

    const performSaveProgramsDispensation = () => {
        return new Promise((resolve, reject) => {
            // setLoading(true)
            if (dispensation.id !== 0) {
                //update
                ProgramService.putDispensation(dispensation.id, dispensation)
                    .then((response) => {
                        const data = response.data.data;
                        if (data) {
                            setDispensation(data);
                            setOldDispensation(data);
                        }
                        resolve(response);
                    })
                    .catch((e) => {
                        toast.error(ServerError.unknown);
                        reject(e);
                    });
            } else {
                ProgramService.postDispensation(dispensation)
                    .then((response) => {
                        const data = response.data.data;
                        if (data) {
                            setDispensation(data);
                            setOldDispensation(data);
                        }
                        resolve(response);
                    })
                    .catch((e) => {
                        toast.error(ServerError.unknown);
                        reject(e);
                    });
            }
        });
    };

    const getProgramDispensation = () => {
        setLoading(true);
        ProgramService.getDispensation(programId)
            .then((response) => {
                const data = response.data?.data;
                if (data) {
                    setDispensation(data);
                    setOldDispensation(data);
                }
                setLoading(false);
            })
            .catch(() => {
                toast.error(ServerError.unknown);
                setLoading(false);
            });
    };

    useEffect(() => {
        getProgramDispensation();
    }, []);

    const renderPresentiel = () => {
        return (
            <div style={{ flex: 8 }}>
                <QuestionWithRadio
                    radioValue={!!dispensation.frequentOnWebsite}
                    setRadioValue={(value: any) =>
                        setDispensation((old: any) => ({ ...old, frequentOnWebsite: value ? 1 : 0 }))
                    }
                    title=""
                    content="Est-il proposé en présentiel sur plusieurs sites ? :"
                />
                {dispensation.frequentOnWebsite === 1 && (
                    <div className={classes.row} style={{ justifyContent: 'space-between' }}>
                        <div className={classes.rowAlignCenter}>
                            {renderContent('Si oui, combien ? :')}
                            <Spacer height={20} />
                            <div style={{ maxWidth: 80 }}>
                                <NumberSelector
                                    value={dispensation.frequencyNumber}
                                    handleChange={(value: any) => {
                                        setDispensation((old: any) => ({ ...old, frequencyNumber: value }));
                                    }}
                                />
                            </div>
                        </div>
                        <div className={classes.rowAlignCenter}>
                            <div>{renderContent('Combien de fois dans l’année ? :')}</div>
                            <Spacer height={20} />
                            <div style={{ maxWidth: 80 }}>
                                <NumberSelector
                                    value={dispensation.frequencyPerYear}
                                    handleChange={(value: any) => {
                                        setDispensation((old: any) => ({ ...old, frequencyPerYear: value }));
                                    }}
                                />
                            </div>
                        </div>
                        <div></div>
                        <div></div>
                    </div>
                )}
            </div>
        );
    };

    return (
        <div className={classes.container}>
            {loading ? (
                <div className={classes.row} style={{ justifyContent: 'center', marginTop: 40 }}>
                    <CustomCircleLoader />
                </div>
            ) : (
                <div className={classes.wrapper}>
                    <Spacer height={10} />
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        {renderTitle('Focus sur la dispensation du programme')}
                    </div>
                    <div className={classes.row}>
                        {renderPresentiel()}
                        <div style={{ flex: 2 }}></div>
                    </div>
                    <div>
                        <QuestionWithRadio
                            radioValue={!!dispensation.isGlobal}
                            setRadioValue={(value: any) =>
                                setDispensation((old: any) => ({ ...old, isGlobal: value ? 1 : 0 }))
                            }
                            yesLabel="Globalité"
                            noLabel="Quelques séances"
                            title=""
                            content="Sur les différents sites, est-ce une dispensation de la globalité du programme (du DE, à la synthèse finale) ou de quelques séances ? :"
                        />
                        {dispensation.isGlobal === 0 && (
                            <div className={classes.rowAlignCenter}>
                                <div>{renderContent('Si quelques séances, combien ? :')}</div>
                                <Spacer height={20} />
                                <div style={{ maxWidth: 80 }}>
                                    <NumberSelector
                                        value={dispensation.sessionsNumber}
                                        handleChange={(value: any) => {
                                            setDispensation((old: any) => ({ ...old, sessionsNumber: value }));
                                        }}
                                    />
                                </div>
                            </div>
                        )}

                        <QuestionWithRadio
                            radioValue={!!dispensation.isPresentiel}
                            setRadioValue={(value: any) =>
                                setDispensation((old: any) => ({ ...old, isPresentiel: value ? 1 : 0 }))
                            }
                            title=""
                            content="Ce programme d’ETP est-il proposé au patient (et éventuellement à son aidant) en distanciel (technologie du numérique) ? :"
                        />
                        <Spacer height={20} />
                        {renderContent(
                            'Nb de bénéficiaires ayant eu au moins une séance en distanciel / Nb total de bénéficiaires :',
                        )}
                        <Spacer height={20} />
                        <InputField
                            width={350}
                            single
                            label=""
                            value={dispensation.beneficiaryStat}
                            multiline
                            height={200}
                            placeholder={'Taper votre texte'}
                            onChange={(value: any) => {
                                setDispensation((old: any) => ({ ...old, beneficiaryStat: value }));
                            }}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};
