import { Grid, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import MedimailService from 'src/services/Medimails';
import { baseColors, baseFontFamily, baseFontSize, baseScreenHeight } from 'src/utils/constants/baseStyles';
import { CustomCheckBox } from '../InputField/CustomCheckBox';
import { SearchInputField } from '../InputField/SearchInputField';
import { CustomText } from '../Text/CustomText';
import { searchElementByName } from 'src/utils/utils';
import FormCardLoading from '../CustomLoader/FormCardLoading';

import Switch from '@mui/material/Switch';

interface CustomFormCardProps {
    items?: Array<any>;
    labelKey?: string;
    remoteData?: Function;
    searchData?: Function;
    onClick?: Function;
    secondLabelKey?: string;
    loading?: boolean;
    subMenuKey?: string;
    placeholder?: string;
    handleChange?: Function;
    selectedRows?: Set<number>;
    maxItemNumber?: number;
    setSearchQuery?: Function;
    debounce?: Function;
    loadingNumber?: number;
    readOnly?: boolean;
    isHealthProfessional?: boolean;
    handleSwitchDoctor?: Function;
    medecinTraitantId?: number;
}
interface Medimail {
    email: string;
    firstName: string;
    formation: string;
    hasEtp: string;
    id: number;
    lastName: string;
    rpps: string;
}

export const CustomFormCard: FC<CustomFormCardProps> = (props) => {
    const {
        handleChange,
        items,
        loading,
        labelKey,
        secondLabelKey,
        subMenuKey,
        selectedRows,
        maxItemNumber,
        loadingNumber,
        placeholder,
        readOnly,
        isHealthProfessional,
        handleSwitchDoctor,
        medecinTraitantId,
    } = props;
    const [selected, setSelected] = useState(new Set() as any);
    const [selectedMedimail, setSelectedMedimail] = useState(new Set() as any);
    const [forceRerender, setForceRerender] = useState(false);
    const [data, setData] = useState(items);
    const [query, setQuery] = useState('');
    const [searching, setSearching] = useState(false);
    const max = maxItemNumber ?? 100;
    const [dataMedimails, setDataMedimails] = useState<any>([]);

    useEffect(() => {
        fetchDataMedimails(query);
        setData(items?.concat(medimailsData));
    }, [items, query]);

    const fetchDataMedimails = (value: string) => {
        MedimailService.getMedimails(value).then((response) => {
            const responses = response.data.data;
            setDataMedimails(responses);
        });
    };

    const medimailsData: Medimail[] = Array.from(dataMedimails, (medimail: any) => ({
        id: parseInt(medimail.id),
        email: medimail.mail,
        firstName: `${medimail.nom}`.toLocaleLowerCase(),
        formation: '',
        hasEtp: '',
        lastName: `${medimail.prenom}`.toLocaleLowerCase(),
        rpps: 'medimail',
    }));

    useEffect(() => {
        if (selectedRows) {
            setSelected(selectedRows);
        }
    }, [selectedRows]);

    const [searchkey, setSearchkey] = useState<string>('');
    const [searchedItems, setSearchedItems] = useState<Array<any>>([]);
    const handleSearch = (value: string) => {
        setSearchkey(value);
    };

    useEffect(() => {
        setSearchedItems(searchElementByName(data ?? [], searchkey, 'name'));
    }, [searchkey]);

    const finalData = searchkey?.trim()?.length > 0 ? searchedItems : data;

    return (
        <Grid
            flexGrow={1}
            style={{
                background: baseColors.green.light,
                paddingLeft: 5,
                paddingRight: 5,
                paddingBottom: 10,
                borderRadius: 10,
            }}
        >
            <SearchInputField
                onChange={(value: string) => handleSearch(value)}
                value={searchkey}
                placeholder={placeholder ? placeholder : 'Recherche'}
            />

            {isHealthProfessional && (
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <Typography
                        fontFamily={baseFontFamily.OpenSansSemiBold}
                        fontSize={baseFontSize.mini}
                        color={baseColors.orange.primary}
                        sx={{
                            overflow: 'hidden',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 1,
                            marginRight: 1,
                        }}
                    >
                        (* Médecin traitant)
                    </Typography>
                </div>
            )}

            <Grid item>
                {searching || loading ? (
                    <FormCardLoading loadingNumber={loadingNumber ?? undefined} />
                ) : finalData && finalData?.length ? (
                    <Grid container direction="column" alignContent={'flex-start'}>
                        {finalData?.slice(0, finalData.length > max ? max : finalData.length).map((item, i) => {
                            return (
                                <Grid
                                    container={isHealthProfessional}
                                    item={!isHealthProfessional}
                                    style={{
                                        // alignSelf: 'flex-start',
                                        justifyContent: isHealthProfessional ? 'space-between' : 'flex-start',
                                        flexDirection: 'row',
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <CustomCheckBox
                                        onClick={() => {
                                            let set = selected;
                                            let setMedimail = selectedMedimail;
                                            if (!selected.has(item.id)) {
                                                set.add(item.id);
                                                if (item?.rpps === 'medimail') {
                                                    setMedimail.add(item.email);
                                                }
                                            } else {
                                                set.delete(item.id);
                                                setMedimail.delete(item.email);
                                            }
                                            setSelected(set);
                                            setSelectedMedimail(setMedimail);
                                            handleChange && handleChange(set, setMedimail);
                                            setForceRerender(!forceRerender);
                                        }}
                                        key={i}
                                        checked={selected.has(item.id)}
                                        label={
                                            labelKey && !secondLabelKey && !subMenuKey
                                                ? `${item[labelKey]} `
                                                : labelKey && secondLabelKey && !subMenuKey
                                                ? `${item[labelKey]}  ${item[secondLabelKey]}`
                                                : labelKey && secondLabelKey && subMenuKey
                                                ? `${item[labelKey]}  ${item[secondLabelKey]} (${item[subMenuKey]})`
                                                : ''
                                        }
                                        readOnly={readOnly}
                                    />

                                    {isHealthProfessional && (
                                        <div style={{ marginRight: 20 }}>
                                            <Switch
                                                className="medecin-traitant"
                                                size="small"
                                                checked={medecinTraitantId === item.id}
                                                onClick={() => handleSwitchDoctor?.(item.id)}
                                                disabled={!selected.has(item.id)}
                                                style={{
                                                    color:
                                                        medecinTraitantId === item.id
                                                            ? baseColors.green.primary
                                                            : baseColors.grey,
                                                }}
                                            />
                                        </div>
                                    )}
                                </Grid>
                            );
                        })}
                    </Grid>
                ) : (
                    <CustomText style={{ textAlign: 'center' }}> Aucun proche aidant trouvé.</CustomText>
                )}
            </Grid>
        </Grid>
    );
};
