import { Dispatch, FC, SetStateAction } from 'react';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import ModuleService from 'src/services/Module/module.service';
import { toast } from 'react-toastify';
import CustomModal from 'src/components/CustomModal';
import { InputField } from 'src/components/InputField/InputField';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import ListItemFormCheckBox from 'src/components/ListItemFormCheckBox';
import WorkshopService from 'src/services/Workshop/workshop.service';
import { defaultModule } from './ModulesPanel';
import { sxStyles } from './styles';
import { isNotBlank } from 'src/utils/helpers/InputValidator';
import { searchElementByName, sortByIds } from 'src/utils/utils';
import { ModuleItem } from 'src/interfaces/interfaces';
import { CustomText } from 'src/components/Text/CustomText';
import { baseFontFamily } from 'src/utils/constants/baseStyles';
import { Spacer } from 'src/components/Spacer/Spacer';

type IProps = {
    modules: ModuleItem[];
    setPayload: Dispatch<SetStateAction<any>>;
    payload: any;
    open: boolean;
    toggleModal: any;
    setUpdateList: Dispatch<SetStateAction<boolean>>;
};

export const ModuleModale: FC<IProps> = (props) => {
    const { modules, setPayload, payload, open, toggleModal, setUpdateList } = props;
    const [saving, setSaving] = useState(false);
    const [loadingWorkshops, setLoadingWorkshops] = useState(false);
    const [workshops, setWorkshops] = useState<any[]>([]);

    const successfull = (message: string) => {
        setUpdateList((prev) => !prev);
        toggleModal();
        toast.success(message, { style: { zIndex: 150 } });
        setPayload(defaultModule);
        setSaving(false);
        setSearchkey('');
    };

    const failed = (message: string) => {
        toast.error(message);
        setSaving(false);
    };

    const handleSaveModule = () => {
        const foundTitle: ModuleItem =
            modules.find((item) => item.name === payload?.name?.trim()) ?? ({ id: 0 } as ModuleItem);

        if (foundTitle?.id > 0 && foundTitle?.id !== payload.id) {
            toast.error('Le nom du module existe déjà ');
            setSaving(false);
        } else {
            setSaving(true);
            if (payload.id > 0) {
                ModuleService.updateModule(payload.id, payload)
                    .then(() => successfull('Modification avec succès'))
                    .catch(() => failed('Une erreur est survenue lors de la modification du module'));
            } else {
                ModuleService.createModule(payload)
                    .then(() => successfull('Création du module réussi'))
                    .catch(() => failed('Une erreur est survenue lors du création du module'));
            }
        }
    };
    const handleCancelModule = () =>{
        toggleModal();
        setSearchkey('');
    }

    const fetchWorkshops = (query?: string) => {
        setLoadingWorkshops(true);
        WorkshopService.getWorkshops({ searchKey: query })
            .then((response) => {
                const data = response?.data?.data;
                const removeDraftWorkshops = data?.filter((item: any) => !item.isDraft);

                const optimized = Array.from(removeDraftWorkshops, (item: { id: number; name: string }) => ({
                    id: item.id,
                    name: item.name,
                }));
                setWorkshops(sortByIds(optimized ?? []) ?? []);
                setLoadingWorkshops(false);
            })
            .catch((e) => {
                setLoadingWorkshops(false);
                toast.error('une erreur est survenue lors de la récupération des ateliers');
            });
    };

    const canSubmit = () => isNotBlank(payload.name);

    useEffect(() => {
        fetchWorkshops();
    }, []);

    const [searchkey, setSearchkey] = useState<string>('');
    const [searchedItems, setSearchedItems] = useState<Array<any>>([]);
    const handleSearch = (value: string) => setSearchkey(value);

    useEffect(() => {
        setSearchedItems(searchElementByName(workshops ?? [], searchkey, 'name'));
    }, [searchkey]);

    const finalData = searchkey?.trim()?.length > 0 ? searchedItems : workshops;

    const footer = (
        <Box style={sxStyles.modalContainer}>
            <CustomLoadingButton
                outlined
                disabled={saving || loadingWorkshops}
                width={180}
                label="Annuler"
                handleClick={handleCancelModule}
            />
            <CustomLoadingButton
                loading={saving}
                disabled={saving || !canSubmit() || loadingWorkshops}
                width={180}
                label={payload.id < 0 ? 'Valider' : 'Enregistrer'}
                handleClick={handleSaveModule}
            />
        </Box>
    );

    return (
        <CustomModal
            disableBackdropClick
            title={payload.id <= 0 ? "Création d'un nouveau module" : "Modification d'un module"}
            open={open}
            toggleModal={toggleModal}
            footer={footer}
        >
            <Box>
                <CustomText fontFamily={baseFontFamily.OpenSansSemiBold} style={{}} fontSize={12}>
                    Nom du module:
                </CustomText>

                <Spacer height={10} />

                <InputField
                    label=""
                    width="100%"
                    value={payload.name}
                    onChange={(text: any) => {
                        setPayload((old: any) => ({ ...old, name: text }));
                    }}
                    placeholder="Taper le nom du module"
                    validator={isNotBlank}
                />

                <CustomText fontFamily={baseFontFamily.OpenSansSemiBold} style={{}} fontSize={12}>
                    Atelier(s):
                </CustomText>

                <Spacer />

                <ListItemFormCheckBox
                    isForProgram
                    selectedRows={new Set(payload?.workshopIds)}
                    handleChange={(value: any) =>
                        setPayload((old: any) => ({ ...old, workshopIds: Array.from(value) }))
                    }
                    items={finalData}
                    loading={loadingWorkshops}
                    labelKey="name"
                    handleSearch={(value: string) => handleSearch(value)}
                    maxItems={workshops.length}
                />
            </Box>
        </CustomModal>
    );
};
