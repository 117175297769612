import { FC } from 'react';
type IconMailProps = {
    bg?: string;
}
const IconMail : FC<IconMailProps> = (props) => {
    const {bg} = props;
    return (
        <svg width="104" height="104" viewBox="0 0 104 104" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="104" height="104" rx="10" fill={bg ?? "#0087CB"} />
            <g clip-path="url(#clip0_2645_14484)">
                <path
                    d="M73.2822 47.7634V71.8536C73.2867 72.4546 73.0325 73.0285 72.5847 73.4291L72.5214 73.4923C72.1351 73.8217 71.6436 74.0018 71.1359 74H32.8647C32.3607 73.9999 31.8732 73.8199 31.49 73.4923C31.4638 73.477 31.4421 73.4552 31.4267 73.4291C30.9754 73.0298 30.7173 72.4561 30.7179 71.8536V47.7634C30.7129 47.2279 30.9178 46.7116 31.2888 46.3254C31.4401 46.1469 31.6218 45.9964 31.8254 45.8811C32.1431 45.7073 32.4994 45.6163 32.8615 45.6166H71.1354C71.7362 45.6274 72.3075 45.8787 72.7216 46.3141C73.0814 46.7112 73.2813 47.2276 73.2822 47.7634Z"
                    fill="#F39041"
                />
                <path
                    d="M72.724 46.312C72.5732 46.4982 52.0117 62.8502 52.0117 62.8502L31.2881 46.3302L31.2972 46.3216V46.3125L50.8382 30.416C51.5202 29.8613 52.4979 29.8613 53.18 30.416L72.724 46.312Z"
                    fill="#F39041"
                />
                <path d="M68.3521 35.2277V49.8325L52.0093 62.8502L35.6572 49.8149V35.2277H68.3521Z" fill="#FFF9F5" />
                <path
                    d="M72.5819 73.4279C72.5636 73.4508 72.5426 73.4717 72.5197 73.4902L54.1104 61.2098L55.7952 59.8528L72.5819 73.4279Z"
                    fill="#FFF9F5"
                />
                <path
                    d="M49.8983 61.2007L31.489 73.4885C31.462 73.4752 31.4401 73.4534 31.4268 73.4263L48.2048 59.835L49.8983 61.2007Z"
                    fill="#FFF9F5"
                />
                <path d="M39.2793 39.061H64.7206V40.1648H39.2793V39.061Z" fill="#1B8E95" />
                <path d="M39.2793 43.3184H64.7206V44.4222H39.2793V43.3184Z" fill="#1B8E95" />
                <path d="M39.2793 47.5762H64.7206V48.68H39.2793V47.5762Z" fill="#1B8E95" />
                <path d="M43.5518 51.8335H60.4479V52.9373H43.5518V51.8335Z" fill="#1B8E95" />
            </g>
            <defs>
                <clipPath id="clip0_2645_14484">
                    <rect width="44" height="44" fill="white" transform="translate(30 30)" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default IconMail;
