function SvgDinerIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={41}
      height={41}
      fill="none"
      viewBox="0 0 41 41"
      {...props}
    >
      <g clipPath="url(#clip0)"  fill={props.fill}>
        <path d="M40.872 33.738a.855.855 0 00-.726-.405H.854a.857.857 0 00-.763 1.237 6.995 6.995 0 006.292 3.888h28.235a6.994 6.994 0 006.292-3.89.851.851 0 00-.038-.83zm-6.254 3.012H6.382a5.294 5.294 0 01-3.913-1.708h36.063a5.297 5.297 0 01-3.914 1.708zm-14.12-14.577a.868.868 0 00-.909-.796 13.633 13.633 0 00-11.98 9.087.857.857 0 00.806 1.137c.352 0 .683-.22.806-.57a11.927 11.927 0 0110.481-7.95.855.855 0 00.796-.908z" />
        <path d="M37.56 34.143c-.47-9.076-7.963-16.185-17.06-16.185-9.107 0-16.602 7.119-17.061 16.206a.854.854 0 00.81.896h.044a.855.855 0 00.853-.81c.413-8.177 7.157-14.583 15.354-14.583 8.186 0 14.931 6.397 15.353 14.563a.86.86 0 00.897.81.854.854 0 00.81-.897z" />
        <path d="M20.5 14.541a2.566 2.566 0 00-2.563 2.563 2.566 2.566 0 002.563 2.563 2.566 2.566 0 002.563-2.563 2.566 2.566 0 00-2.563-2.563zm0 3.417a.855.855 0 110-1.709.855.855 0 010 1.71zm7.705-2.91a.554.554 0 01-.371-.966 1.535 1.535 0 00.68-1.229c0-.603-.294-.892-.812-1.35a2.746 2.746 0 01-1.187-2.18 2.757 2.757 0 011.186-2.184 3.77 3.77 0 00.572-.583.555.555 0 01.901.645 4.67 4.67 0 01-.74.767c-.518.46-.812.75-.812 1.356 0 .606.293.892.811 1.35a2.748 2.748 0 011.187 2.18 2.603 2.603 0 01-1.047 2.052.55.55 0 01-.368.142zm-15.515-.001a.554.554 0 01-.371-.965 1.534 1.534 0 00.679-1.229c0-.603-.293-.891-.812-1.35A2.747 2.747 0 0111 9.324a2.757 2.757 0 011.185-2.184c.211-.173.403-.368.572-.583a.554.554 0 11.902.645 4.66 4.66 0 01-.739.767c-.518.46-.812.75-.812 1.356 0 .606.293.891.812 1.35a2.748 2.748 0 011.186 2.18 2.604 2.604 0 01-1.047 2.052.55.55 0 01-.37.141zm7.757-3.324a.555.555 0 01-.37-.966 1.536 1.536 0 00.679-1.228c0-.604-.294-.892-.812-1.35a2.745 2.745 0 01-1.186-2.18 2.758 2.758 0 011.184-2.184 3.77 3.77 0 00.573-.583.555.555 0 01.901.645 4.622 4.622 0 01-.739.767c-.518.46-.812.75-.812 1.356 0 .606.294.891.812 1.349a2.748 2.748 0 011.186 2.18 2.605 2.605 0 01-1.046 2.053.551.551 0 01-.37.141z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0h41v41H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgDinerIcon
