import { FC, useState, useContext } from 'react';
import CustomModal from 'src/components/CustomModal';
import { formatDate, getAge } from 'src/utils/helpers/helpers';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import { Avatar, Grid } from '@mui/material';
import { CustomText } from 'src/components/Text/CustomText';
import { CustomButton } from 'src/components/Button/CustomButton';
import { ContactUs } from './ContactUs';
import CustomLoader from 'src/components/CustomLoader/CustomLoader';
import { InfoItem } from './InfoItem';
import { Spacer } from 'src/components/Spacer/Spacer';
import { padding } from '@mui/system';
import { makeStyles } from '@mui/styles';
import { UserContext } from 'src/providers/UserProvider';
interface ProfileDetailsProps {
    open: boolean;
    loading?: boolean;
    setOpen: Function;
    currentPatient?: any;
    professionals: any;
    assistants: any;
}

const useStyles = makeStyles({
    containerQuestion: {
        height: `calc(50vh + 20px)`,
        overflowY: 'scroll',
        paddingLeft: 30,
        paddingRight: 20,
        // Ajouter la classe CSS personnalisée à la classe générée par makeStyles
        '&.hide-scrollbar': {
            '&::-webkit-scrollbar': {
                width: '0.5em',
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'transparent',
            },
        },
    },
});

export const ProfileDetails: FC<ProfileDetailsProps> = (props) => {
    const { professionals, assistants, loading, open, setOpen, currentPatient } = props;
    const emptyProfessional = professionals?.length === 0;
    const [openContactModal, setOpenContactModal] = useState(false);
    const [infoProfesionnels, setInfoProfesionnels] = useState<any>();
    const { userInfos } = useContext(UserContext);
    const classes = useStyles();

    const containerClass = `${classes.containerQuestion}`;
    const professionalCounter = professionals?.length > 0 && '('+professionals.length+')';
    return (
        <CustomModal width={600} open={open} toggleModal={setOpen}>
            <CustomText
                style={{ textAlign: 'center' }}
                fontFamily={baseFontFamily.BreeSerif}
                fontSize={18}
                marginBottom={5}
                color={baseColors.black}
            >
                {`${currentPatient?.firstName} ${currentPatient?.lastName}`}
            </CustomText>

            <div style={{ display: 'flex', marginLeft: 40, paddingLeft: 10, justifyContent: 'space-between' }}>
                <div>
                    {loading ? (
                        <div style={{ paddingRight: 40 }}>
                            <CustomLoader IsclassicList />
                        </div>
                    ) : (
                        <div>
                            <Grid item>
                                <Avatar
                                    alt={currentPatient?.firstName}
                                    src={currentPatient?.profilePicture}
                                    sx={{ width: 100, height: 100, marginBottom: 5 }}
                                />
                            </Grid>
                            <Grid container direction={'column'}>
                                <InfoItem label={'Civilité'} value={currentPatient?.civility === 0 ? 'Mme' : 'M.'} />
                                <InfoItem
                                    label={'Nom de naissance'}
                                    value={
                                        currentPatient?.firstName === null ? 'Non renseigné' : currentPatient?.firstName
                                    }
                                />
                                <InfoItem label={'Prénom(s)'} value={currentPatient?.lastName} />
                                <InfoItem
                                    label={'Age'}
                                    value={
                                        currentPatient?.birthDate === null || currentPatient?.birthDate === undefined
                                            ? 'Non renseigné'
                                            : `(${getAge(currentPatient?.birthDate, new Date())} ans ) ${formatDate(
                                                  currentPatient?.birthDate,
                                              )}`
                                    }
                                />
                                <InfoItem
                                    label={'Lieu de naissance'}
                                    value={
                                        currentPatient?.birthPlace === null || currentPatient?.birthPlace === undefined
                                            ? 'Non renseigné'
                                            : currentPatient?.birthPlace
                                    }
                                />
                                <InfoItem
                                    label={'Sexe'}
                                    value={
                                        currentPatient?.sex === null || currentPatient?.sex === undefined
                                            ? 'Non reseigné'
                                            : currentPatient?.sex === 0
                                            ? 'Féminin'
                                            : 'Masculin'
                                    }
                                />
                                <InfoItem
                                    label={'Profession'}
                                    value={
                                        currentPatient?.profession === null || currentPatient?.profession === undefined
                                            ? 'Non renseigné'
                                            : currentPatient?.profession
                                    }
                                />
                                <InfoItem
                                    label={'Téléphone'}
                                    value={
                                        currentPatient?.phoneNumber === null ||
                                        currentPatient?.phoneNumber === undefined
                                            ? 'Non renseigné'
                                            : currentPatient?.phoneNumber
                                    }
                                />
                                <InfoItem
                                    label={'Adresse'}
                                    value={
                                        currentPatient?.address === null || currentPatient?.address === undefined
                                            ? 'Non renseigné'
                                            : currentPatient?.address
                                    }
                                />
                                <InfoItem
                                    label={'Mail'}
                                    value={
                                        currentPatient?.email === null || currentPatient?.email === undefined
                                            ? 'None renseigné'
                                            : currentPatient?.email
                                    }
                                />
                            </Grid>
                        </div>
                    )}
                </div>

                <div
                    style={{
                        marginLeft: '35px',
                    }}
                >
                    <Grid
                        direction={'column'}
                        style={{
                            width: '100%',
                            height: 221,
                            borderRadius: 10,
                            paddingTop: 10,
                            paddingBottom: 0,
                            paddingRight: 30,
                            paddingLeft: 30,
                            background: baseColors.green.primary,
                            marginBottom: 20,
                        }}
                    >
                        <div
                            style={{
                                textAlign: 'center',
                            }}
                        >
                            <CustomText style={{ color: baseColors.white }}>
                                Liste des professionnels de santé {professionalCounter}
                            </CustomText>
                        </div>
                        <div
                            style={{
                                height: 160,
                                overflowY: 'scroll',
                                scrollbarColor: baseColors.green.light,
                                padding: 2,
                            }}
                            className={containerClass}
                        >
                            {emptyProfessional ? (
                                <CustomText color={baseColors.white}>Aucun professionnel</CustomText>
                            ) : (
                                Array.isArray(professionals) &&
                                professionals?.map((item: any) => (
                                    <div
                                        style={{
                                            backgroundColor: baseColors.green.primary,
                                            padding: 10,
                                            paddingTop: 10,
                                            marginTop: 15,
                                            borderRadius: 15,
                                            marginRight: 10,
                                        }}
                                    >
                                        <CustomText color={baseColors.white}>
                                            {item?.professional?.firstName} {item?.professional?.lastName}
                                        </CustomText>
                                        <Spacer height={8} />
                                        <CustomText color={baseColors.white}>
                                            {item?.professional?.phoneNumber}
                                        </CustomText>
                                        <Spacer height={8} />
                                        <CustomText
                                            fontFamily={baseFontFamily.OpenSansSemiBold}
                                            color={baseColors.white}
                                        >
                                            {item?.professional?.email}
                                        </CustomText>

                                        {userInfos?.email !== item?.professional?.email && (
                                            <div style={{ marginTop: 10 }}>
                                                <CustomButton
                                                    onClick={() => {
                                                        setInfoProfesionnels(item);
                                                        setOpenContactModal(true);
                                                    }}
                                                    width={100}
                                                    background={baseColors.orange.light}
                                                    label={'Contacter'}
                                                />
                                            </div>
                                        )}

                                        <ContactUs
                                            open={openContactModal}
                                            setOpen={setOpenContactModal}
                                            professionalInfo={infoProfesionnels}
                                        />
                                    </div>
                                ))
                            )}
                        </div>
                    </Grid>
                    <Grid
                        direction={'column'}
                        style={{
                            width: '100%',
                            height: 210,
                            borderRadius: 10,
                            padding: 30,
                            paddingTop: 10,

                            background: baseColors.green.primary,
                            marginBottom: 20,
                        }}
                    >
                        <div
                            style={{
                                textAlign: 'center',
                            }}
                        >
                            <CustomText style={{ color: baseColors.white }}>Liste des proches et aidants</CustomText>
                        </div>
                        <div
                            style={{
                                height: 130,
                                overflowY: 'scroll',
                                scrollbarColor: baseColors.green.primary,
                                padding: 2,
                            }}
                            className={containerClass}
                        >
                            {assistants === null ? (
                                <CustomText color={baseColors.white}>Aucun assistant</CustomText>
                            ) : (
                                Array.isArray(assistants) &&
                                assistants?.map((item: any) => (
                                    <div
                                        style={{
                                            backgroundColor: baseColors.green.light,
                                            padding: 10,
                                            paddingTop: 10,
                                            marginTop: 15,
                                            borderRadius: 15,
                                            marginRight: 10,
                                        }}
                                    >
                                        <CustomText color={baseColors.white}>
                                            {item?.firstName} {item?.lastName}
                                        </CustomText>
                                        <Spacer height={8} />
                                        <CustomText color={baseColors.white}>{item?.phoneNumber}</CustomText>
                                        <Spacer height={8} />
                                        <CustomText
                                            fontFamily={baseFontFamily.OpenSansSemiBold}
                                            color={baseColors.white}
                                        >
                                            {item?.email}
                                        </CustomText>
                                    </div>
                                ))
                            )}
                        </div>
                    </Grid>
                </div>
            </div>
        </CustomModal>
    );
};
