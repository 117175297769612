const programSyntheseMenus = [
    {
        key: 1,
        label: 'Activité globale',
        route: `synthese`,
    },
    {
        key: 2,
        label: 'Déroulement',
        route: `process`,
    },
    {
        key: 3,
        label: 'Atteinte des objectifs du programme',
        route: `goals`,
    },
    {
        key: 4,
        label: 'Dispensation',
        route: `dispense`,
    },
    {
        key: 5,
        label: 'Evaluation',
        route: `evaluation`,
    },
];

export {
    programSyntheseMenus
}