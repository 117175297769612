import { makeStyles } from '@mui/styles';
import { baseColors, baseFontFamily } from '../../../../utils/constants/baseStyles';

const useStyles = makeStyles({
    container: {
        marginTop: 20,
    },
    content:{
        marginTop: 20,
        marginBottom: 20,
        alignItems: 'center'
    },
    header: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 20
    },
    title: {
        color: baseColors.black,
        fontFamily: baseFontFamily.BreeSerif,
    },
    subtitle: {
        color: baseColors.green.primary,
        fontSize: '12px',
    },
});

export default useStyles;
