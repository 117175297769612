import { makeStyles } from '@mui/styles';
import { baseColors } from '../../../../utils/constants/baseStyles';

const useStyles = makeStyles({
    container: {
        marginTop: 20,
        backgroundColor: baseColors.green.light,
        padding: 10,
        borderRadius: 10,
        marginBottom: 20,
    },
    content: {},
    header: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 20,
    },
    details: {
        textDecorationLine: 'underline',
        cursor: 'pointer',
    },
    item: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        marginBottom: 10,
    },
});

export default useStyles;
