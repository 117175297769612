import { KeyboardArrowDown } from '@mui/icons-material';
import { Button } from '@mui/material';
import React, { FC } from 'react';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import { CustomText } from '../Text/CustomText';

interface FakeSelectProps {
    title: string;
    onClick?: Function;
    style?: React.CSSProperties;
    disabled?: boolean;
}

export const FakeSelect: FC<FakeSelectProps> = (props) => {
    const { title, onClick, style, disabled } = props;
    return (
        <Button
            disabled={disabled}
            size="large"
            endIcon={<KeyboardArrowDown />}
            onClick={() => onClick?.()}
            style={
                style
                    ? style
                    : {
                          marginTop: 10,
                          textTransform: 'none',
                          borderRadius: 10,
                          color: baseColors.green.primary,
                          background: baseColors.green.light,
                      }
            }
        >
            <CustomText color={baseColors.green.primary} fontFamily={baseFontFamily.OpenSansBold}>
                {title}
            </CustomText>
        </Button>
    );
};
