import { makeStyles } from '@mui/styles';
import { baseColors, baseFontSize } from 'src/utils/constants/baseStyles';
const useStyles = makeStyles({
    container: {
        paddingTop: 30,
        backgroundColor: baseColors.white,
        // height:'100vh'
    },
    label: {
        marginLeft: 0,
        paddingLeft: 0,
    },
});

export const styles: any = {
    menuContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingRight: 0,
        marginRight: 0,
        marginBottom: 20,
    },
    menuItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingRight: 0,
        marginRight: 0,
        marginBottom: 20,
    },
    itemIcon: {
        alignItems: 'center',
        justifyContent: 'center',
        padding: '7px 0px 7px 0px',
        borderRadius: 10,
        border: '1px solid',
        borderColor: baseColors.white,
    },
    divider: {
        width: 4,
        height: 50,
        borderRadius: 7,
        marginLeft: 'auto',
    },
    label: {
        fontSize: baseFontSize.sl,
        marginLeft: 10,
    },
};

export default useStyles;
