import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    container: {
        flexGrow: 1,
    },
    label: {},
});

const sxStyles: any = {
    mainContainer: {},

    menuItem: {
        alignSelf: 'center',
        justifyContent: 'center',
        paddingTop: 10,
        paddingBottom: 10,
    },

    label: {
        textTransform: 'none',
        textAlign: 'center',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
};

export { sxStyles };

export default useStyles;
