import { makeStyles } from '@mui/styles';
import { baseColors } from '../../../../utils/constants/baseStyles';

const useStyles = makeStyles({
    container: {},
    header: {
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: baseColors.green.light,
        borderRadius: 5,
        padding: 7,
        height: '2rem',
    },
    filterLabel: {
        paddingLeft: 20,
    },
    divider: {
        marginTop: 10,
    },
    content: {
        padding: '10px 0 0 20px',
    },
});

export default useStyles;
