import { Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { retrievePermissions } from 'src/actions/permission/permission';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import CustomModal from 'src/components/CustomModal';
import { CustomSelect } from 'src/components/InputField/CustomSelect';
import { CustomText } from 'src/components/Text/CustomText';
import AuthorizationService from 'src/services/Authorizations';
import { RootState } from 'src/stores/store';
import { DetailAuthorization } from './AuthorizationPanel';

export interface SettingsModalProps {
    open: boolean;
    data: DetailAuthorization;
}
const settingsModalProps: SettingsModalProps = {
    open: false,
    data: {
        featureId: 0,
        featureName: '',
        category: '',
        code: '',
        risk: '',
        userPermission: '',
        patientPermission: '',
        doctorPermission: '',
        coordinatorPermission: '',
        adminPermission: '',
    },
};

export interface AuthSettingsValue {
    featureId: number;
    userPermission: number;
    patientPermission: number;
    doctorPermission: number;
    coordinatorPermission: number;
    adminPermission: number;
}
export const SettingsModal = (props: SettingsModalProps = settingsModalProps) => {
    const data = props.data;
    let open = props.open;
    const [openSettings, setOpenSettings] = useState<boolean>(false);
    const [listPermissions, setListPermissions] = useState<any[]>([]);
    const permissions = useSelector((state: RootState) => state.permissions.listPermissions);
    const dispatch = useDispatch();

    const [authSettingsValue, setAuthSettingsValue] = useState<AuthSettingsValue>({
        featureId: 0,
        userPermission: 0,
        patientPermission: 0,
        doctorPermission: 0,
        coordinatorPermission: 0,
        adminPermission: 0,
    });

    useEffect(() => {
        dispatch(retrievePermissions());
    }, []);

    useEffect(() => {
        parsePermissionData(permissions);
    }, [permissions]);

    const parsePermissionData = (permissions: any[]) => {
        let rows: any[] = [];
        setAuthSettingsValue({ ...authSettingsValue, featureId: data.featureId });
        permissions.forEach((permission) => {
            rows.push({
                id: permission.id,
                name: permission.name,
                value: permission.value,
                symbol: permission.symbol,
            });
            return rows;
        });

        setListPermissions(rows);
    };

    useEffect(() => {
        if (open) {
            setOpenSettings(true);
        } else {
            setOpenSettings(false);
        }
    }, [open]);

    const handleSettingsClose = () => {
        setOpenSettings(false);
    };

    const handleValidate = () => {
        AuthorizationService.updateFeatureAuthorization(authSettingsValue)
            .then((response: any) => {
                const result = JSON.parse(response.request.response);
                handleSettingsClose();
                return result.data;
            })
            .catch((reason) => {
                console.log('Update feature authorization  error', reason);
            });
    };

    return (
        <>
            <CustomModal
                width={500}
                title={'Configuration autorisation'}
                open={openSettings}
                toggleModal={handleSettingsClose}
            >
                <Grid>
                    <Grid
                        style={{ marginTop: 20, marginBottom: 10 }}
                        lg={12}
                        container
                        direction="row"
                        alignItems={'center'}
                        justifyContent="space-between"
                    >
                        <Grid item xs>
                            <CustomText style={{ marginTop: 10 }}>Nom de la fonctionnalité :</CustomText>
                        </Grid>
                        <Grid item xs>
                            <Typography>{data.featureName}</Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        style={{ marginTop: 20, marginBottom: 10 }}
                        lg={12}
                        container
                        direction="row"
                        alignItems={'center'}
                        justifyContent="space-between"
                    >
                        <Grid item xs>
                            <CustomText style={{ marginTop: 10 }}>Categorie :</CustomText>
                        </Grid>
                        <Grid item xs>
                            <Typography> {data.category} </Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        style={{ marginTop: 20, marginBottom: 10 }}
                        lg={12}
                        container
                        direction="row"
                        alignItems={'center'}
                        justifyContent="space-between"
                    >
                        <Grid item xs>
                            <CustomText style={{ marginTop: 10 }}>Code :</CustomText>
                        </Grid>
                        <Grid item xs>
                            <Typography> {data.code} </Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        style={{ marginTop: 20, marginBottom: 10 }}
                        lg={12}
                        container
                        direction="row"
                        alignItems={'center'}
                        justifyContent="space-between"
                    >
                        <Grid item xs>
                            <CustomText style={{ marginTop: 10 }}>Risque :</CustomText>
                        </Grid>
                        <Grid item xs>
                            <Typography> {data.risk}</Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        style={{ marginTop: 20, marginBottom: 10 }}
                        lg={12}
                        container
                        direction="row"
                        alignItems={'center'}
                        justifyContent="space-between"
                    >
                        <Grid item xs>
                            <CustomText style={{ marginTop: 10 }}>Utilisateur :</CustomText>
                        </Grid>
                        <Grid item xs>
                            <CustomSelect
                                onChange={(value: any) => {
                                    setAuthSettingsValue({ ...authSettingsValue, userPermission: value });
                                    return value;
                                }}
                                label=""
                                labelKey={'name'}
                                items={listPermissions}
                                defaultValue={0}
                                idSelect="permission-user"
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        style={{ marginTop: 20, marginBottom: 10 }}
                        lg={12}
                        container
                        direction="row"
                        alignItems={'center'}
                        justifyContent="space-between"
                    >
                        <Grid item xs>
                            <CustomText style={{ marginTop: 10 }}> Patient :</CustomText>
                        </Grid>
                        <Grid item xs>
                            <CustomSelect
                                onChange={(value: any) => {
                                    setAuthSettingsValue({ ...authSettingsValue, patientPermission: value });
                                    return value;
                                }}
                                label=""
                                labelKey={'name'}
                                items={listPermissions}
                                defaultValue={0}
                                idSelect="permission-patient"
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        style={{ marginTop: 20, marginBottom: 10 }}
                        lg={12}
                        container
                        direction="row"
                        alignItems={'center'}
                        justifyContent="space-between"
                    >
                        <Grid item xs>
                            <CustomText style={{ marginTop: 10 }}>Soignant :</CustomText>
                        </Grid>
                        <Grid item xs>
                            <CustomSelect
                                onChange={(value: any) => {
                                    setAuthSettingsValue({ ...authSettingsValue, doctorPermission: value });
                                    return value;
                                }}
                                label=""
                                labelKey={'name'}
                                items={listPermissions}
                                defaultValue={0}
                                idSelect="patient-doctor"
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        style={{ marginTop: 20, marginBottom: 10 }}
                        lg={12}
                        container
                        direction="row"
                        alignItems={'center'}
                        justifyContent="space-between"
                    >
                        <Grid item xs>
                            <CustomText style={{ marginTop: 10 }}>Coordinateur :</CustomText>
                        </Grid>
                        <Grid item xs>
                            <CustomSelect
                                onChange={(value: any) => {
                                    setAuthSettingsValue({ ...authSettingsValue, coordinatorPermission: value });
                                    return value;
                                }}
                                label=""
                                labelKey={'name'}
                                items={listPermissions}
                                defaultValue={0}
                                idSelect="permission-coordinator"
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        style={{ marginTop: 20, marginBottom: 10 }}
                        lg={12}
                        container
                        direction="row"
                        alignItems={'center'}
                        justifyContent="space-between"
                    >
                        <Grid item xs>
                            <CustomText style={{ marginTop: 10 }}>Administrateur :</CustomText>
                        </Grid>
                        <Grid item xs>
                            <CustomSelect
                                onChange={(value: any) => {
                                    setAuthSettingsValue({ ...authSettingsValue, adminPermission: value });
                                    return value;
                                }}
                                label=""
                                labelKey={'name'}
                                items={listPermissions}
                                defaultValue={0}
                                idSelect="permission-admin"
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        style={{ marginTop: 20, marginBottom: 10 }}
                        lg={12}
                        container
                        direction="row"
                        alignItems={'center'}
                        justifyContent="center"
                        alignContent={'center'}
                    >
                        <Grid
                            item
                            xs
                            width={'auto'}
                            paddingTop={'50px'}
                            display="flex"
                            alignItems={'center'}
                            justifyContent="center"
                            alignContent={'center'}
                        >
                            <CustomLoadingButton
                                width={250}
                                disabled={false}
                                handleClick={handleValidate}
                                loading={false}
                                label={'VALIDER'}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </CustomModal>
        </>
    );
};
