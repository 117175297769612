import { makeStyles } from '@mui/styles';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        padding: 20,
    },
    titleContainer: {
        marginRight: 10,
    },
    title: {
        textAlign: 'center',
        color: baseColors.green.primary,
    },
    content: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        marginTop: 50,
    },
    startAt: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 20,
    },
    startAtLabel: {
        marginRight: 15,
    },
    label: { color: baseColors.black, fontFamily: baseFontFamily.OpenSansRegular },

    timeContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    hours: {},
    minutes: {},
    separator: {
        marginLeft: 5,
        marginRight: 5,
    },
    footerBtn: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 70,
    },
}));

const sxStyles: any = {
    loading: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 10,
    },
    loadingLabel: { color: baseColors.green.primary, fontSize: '12pt', marginTop: 5 },
};

export { sxStyles };

export default useStyles;
