import { baseColors } from 'src/utils/constants/baseStyles';

const styles: any = {
    container: {
        padding: 12,
    },
    titleContainer: {
        marginRight: 10,
    },
};

export default styles;
