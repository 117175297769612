import { initializeApp } from '@firebase/app';
import { getMessaging, getToken, onMessage } from '@firebase/messaging';
import firebaseConfig from 'src/utils/constants/firebaseCloudMessaging';
import axios from 'src/services/AxiosInstance';

const isActiveServiceWorker = ('serviceWorker' in navigator);
let messaging: any = null

if(isActiveServiceWorker){
    initializeApp(firebaseConfig);
    messaging = getMessaging();
} 

export const requestForToken = () => {
    if(isActiveServiceWorker){
        const appVapidKey = process.env.REACT_APP_FIREBASE_CERTIFICAT_KEY_PAIR;

        return getToken(messaging, { vapidKey: appVapidKey })
            .then((currentToken) => {
                if (currentToken) {
                    const firebaseToken = sessionStorage.getItem('firebaseToken');
                    if (firebaseToken !== currentToken || !firebaseToken) {
                        sessionStorage.setItem('firebaseToken', currentToken);
                        saveFirebaseToken(currentToken);
                    }
                } else {
                    console.log('Request a permission to generate one');
                    sessionStorage.setItem('firebaseToken', '');
                }
            })
            .catch((reason) => {
                console.warn("Firebase could'nt run locally. Build your project to run and test it correctly");
                console.log(reason);
            });
    }
};

export const onMessageListener = () => {
    if(isActiveServiceWorker){
        return new Promise((resolve) => {
            onMessage(messaging, (payload) => {
                resolve(payload);
            });
        });
    }
};

export const saveFirebaseToken = (token: string) => {
    return axios.post('api/users/firebaseToken', { firebaseToken: token });
};

export default requestForToken;
