import { FC } from 'react';
import { toast } from 'react-toastify';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import { addPatientsObservation, updatePatientsObservation, deleteObservations } from 'src/services/Patients';
import { ServerError } from 'src/utils/constants/constants';
import useStyles from './styles';
import { ObservationType } from 'src/interfaces/interfaces';
import { CustomConfirm } from 'src/components/CustomConfirm/CustomConfirm';
import { useState, useContext } from 'react';
import { PatientContext } from 'src/providers/PatientProvider';
import { EditorState } from 'draft-js';

interface ObservationFormBtnProps {
    view: string;
    setView: Function;
    patientId?: number;
    triggerCallback?: Function;
    observationData?: any;
    observationType?: ObservationType;
    observation: any;
    setObservation: any;
    defaultObservation: any;
    setOpen: any;
    saving: boolean;
    setSaving: Function;
    setUpdateObservationsList: Function;
    setActiveInput?: Function;
    setEditorState?: any;
}

export const ObservationFormBtn: FC<ObservationFormBtnProps> = (props) => {
    const {
        view,
        setView,
        triggerCallback,
        observationData,
        observation,
        setObservation,
        defaultObservation,
        setOpen,
        saving,
        setSaving,
        setUpdateObservationsList,
        setActiveInput,
        setEditorState,
    } = props;

    const [modalConfirmationVisible, setModalConfirmationVisible] = useState<boolean>(false);
    const toggleModalConfirmation = () => setModalConfirmationVisible(!modalConfirmationVisible);
    const [deleting, setDeleting] = useState<boolean>(false);
    const classes = useStyles();
    const changeView = () => setView('update');
    const { read } = useContext(PatientContext);

    const successfull = () => {
        setObservation(defaultObservation);
        setEditorState(() => EditorState.createEmpty());
        setActiveInput?.(false);
        triggerCallback?.();
        setOpen(false);
        toast.success('Observation enregistrée avec succès .');
        setSaving(false);
        setView('details');
        setUpdateObservationsList((prev: boolean) => !prev);
    };
    const failed = () => {
        toast.error(ServerError.unknown);
        setSaving(false);
    };

    const performSave = () => {
        let data = observation;
        setSaving(true);
        if (observationData?.id) {
            updatePatientsObservation(observationData?.id, data)
                .then(() => successfull())
                .catch(() => failed());
        } else {
            addPatientsObservation(data)
                .then(() => successfull())
                .catch(() => failed());
        }
    };

    const deleteObservation = () => {
        setDeleting(true);
        deleteObservations(observationData?.id)
            .then(() => {
                setDeleting(false);
                toast.success('Observation supprimé avec succès');
                setModalConfirmationVisible(false);
                setOpen(false);
                setUpdateObservationsList((prev: boolean) => !prev);
            })
            .catch((err) => {
                console.log(err);
                setDeleting(false);
                toast.error("Erreur lors du suppression d'observation");
                setModalConfirmationVisible(false);
                setOpen(false);
                setUpdateObservationsList((prev: boolean) => !prev);
            });
    };

    const canSubmit = () =>
        observation?.title.length > 0 &&
        observation?.date &&
        //observation?.attendeeIds.length !== 0 &&
        //observation?.programId &&
        observation?.typeId > 0 &&
        observation?.details?.length > 0;

    const handleClick = () => {
        setActiveInput?.(true);
        changeView();
    };

    return (
        <div className={classes.flexCenter}>
            {observationData?.id ? (
                view === 'details' ? (
                    <>
                        <CustomLoadingButton
                            width="80%"
                            loading={saving}
                            disabled={read}
                            label="MODIFIER"
                            handleClick={handleClick}
                        />
                        <CustomLoadingButton
                            width="80%"
                            loading={deleting}
                            disabled={read}
                            orange
                            label="SUPPRIMER"
                            handleClick={toggleModalConfirmation}
                            style={{ marginLeft: 30 }}
                        />
                        <CustomConfirm
                            modalVisible={modalConfirmationVisible}
                            updateModalVisible={setModalConfirmationVisible}
                            callBackconfirm={() => deleteObservation()}
                            message={'Voulez-vous bien supprimer cette observation ?'}
                        />
                    </>
                ) : (
                    <CustomLoadingButton
                        width="80%"
                        loading={saving}
                        disabled={saving || !canSubmit()}
                        label="VALIDER L'OBSERVATION"
                        handleClick={performSave}
                    />
                )
            ) : (
                <CustomLoadingButton
                    width="80%"
                    loading={saving}
                    disabled={saving || !canSubmit()}
                    label="VALIDER L'OBSERVATION"
                    handleClick={performSave}
                />
            )}
        </div>
    );
};
