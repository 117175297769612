import { Dispatch, SetStateAction, useState } from 'react';
import Popover from '@mui/material/Popover';
import { baseColors } from 'src/utils/constants/baseStyles';
import { handleSetColor } from 'src/components/CustomCard/PatientGoalCard';
import { PatientService } from 'src/services/Patients';
import CustomCircleLoader from 'src/components/CustomLoader/CustomCircleLoader';
import { toast } from 'react-toastify';
import { OperationMessage } from 'src/utils/constants/constants';
import { OpenSansBoldText } from 'src/components/Text/OpenSansBoldText';
import { Spacer } from 'src/components/Spacer/Spacer';
import { OpenSansSemiBold } from 'src/components/Text/OpenSansSemiBold';

interface IProps {
    anchorEl: HTMLButtonElement | null;
    setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
    goal: any;
    setUpdating: Dispatch<SetStateAction<boolean>>;
}

const goalStatus = [
    {
        value: 5,
        color: 'green',
        label: 'Atteint',
    },
    {
        value: 4,
        color: 'yellow',
        label: 'Quasi atteint',
    },
    {
        value: 3,
        color: 'orange',
        label: 'Partiellement atteint',
    },
    {
        value: 2,
        color: 'red',
        label: 'En cours',
    },
    {
        value: 1,
        color: 'purple',
        label: 'Non atteint',
    },
];

const GoalColorPopover: React.FC<IProps> = ({ anchorEl, setAnchorEl, goal, setUpdating }) => {
    const open = Boolean(anchorEl);
    const id = open ? 'filter-popover' : undefined;
    const handleClose = () => setAnchorEl(null);
    const [loading, setLoading] = useState(false);

    const handleClick = (sliderValue: number) => {
        setLoading(true);
        const payaload = {
            name: goal?.name ?? '',
            numTimeValidation: goal?.numTimeValidation ?? 0,
            numValidated: sliderValue,
            state: 2,
            patientId: goal?.patient?.id,
            axisId: goal?.axis?.id,
            timeValidationUnit: goal?.timeValidationUnit,
        };

        PatientService.UpdatePatientGoalStatus(goal?.id, payaload)
            .then(() => {
                setUpdating((prev) => !prev);
                setLoading(false);
                toast.success(OperationMessage.success);
                handleClose();
            })
            .catch((err) => {
                setUpdating((prev) => !prev);
                setLoading(false);
            });
    };

    return (
        <div
            style={{
                backgroundColor: baseColors.white,
            }}
        >
            <Popover
                id={id}
                style={{
                    padding: 10,
                }}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <div style={{ margin: '20px 10px 10px 20px', overflowY: 'scroll' }}>
                    {loading ? (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginRight: 10,
                            }}
                        >
                            <CustomCircleLoader height={25} width={25} />
                            <Spacer />
                            <OpenSansBoldText fontSize={10}>Mise à jour en cours...</OpenSansBoldText>
                        </div>
                    ) : (
                        goalStatus.map((item) => (
                            <div
                                onClick={() => handleClick(item.value)}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    marginBottom: 10,
                                    cursor: 'pointer',
                                }}
                            >
                                <div
                                    key={item.value}
                                    style={{
                                        height: 20,
                                        width: 20,
                                        borderRadius: 10,
                                        backgroundColor: handleSetColor(item.value),
                                        marginRight: 10,
                                    }}
                                />

                                <OpenSansSemiBold style={{ fontSize: 12, top: -10 }}>
                                    {item?.label || ''}
                                </OpenSansSemiBold>
                            </div>
                        ))
                    )}
                </div>
            </Popover>
        </div>
    );
};

export default GoalColorPopover;
