import { FC } from 'react';
import { CustomText } from 'src/components/Text/CustomText';
import { baseColors, baseFontFamily, baseFontSize } from 'src/utils/constants/baseStyles';

interface PatientProgramProps {
    label: string;
    value: string | number;
}

const PatientProgram: FC<PatientProgramProps> = ({ label, value }) => (
    <div
        style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        }}
    >
        <CustomText
            style={{ color: baseColors.green.primary, width: 150 }}
            fontSize={baseFontSize.title}
            fontFamily={baseFontFamily.BreeSerif}
        >
            {label}
        </CustomText>

        <CustomText
            style={{ marginLeft: 10, color: baseColors.orange.primary }}
            fontSize={baseFontSize.title}
            fontFamily={baseFontFamily.BreeSerif}
        >
            {value ?? '(Aucune)'}
        </CustomText>
    </div>
);

export default PatientProgram;
