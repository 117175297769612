import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { baseColors, baseFontFamily, baseFontSize } from 'src/utils/constants/baseStyles';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import { CustomText } from '../Text/CustomText';

interface CustomEmptyDataProps {
    label?: string;
    height?: number;
    width?: number;
    marginTop?: number;
    marginBottom?: number;
    labelFontSize?: number;
}

const CustomEmptyData: FC<CustomEmptyDataProps> = (props) => {
    const { label, height, width, marginTop, labelFontSize, marginBottom } = props;

    return (
        <Box style={{ textAlign: 'center' }}>
            <ListOutlinedIcon
                sx={{
                    margin: `${marginTop ?? 70}px 0 ${marginBottom ?? 20}px 0`,
                    height: height ?? 100,
                    width: width ?? 100,
                    color: baseColors.green.primary,
                }}
            />

            <CustomText
                style={{
                    textAlign: 'center',
                }}
                fontFamily={baseFontFamily.OpenSansSemiBold}
                fontSize={labelFontSize ?? baseFontSize.sm}
            >
                {label ?? 'Aucune information disponible.'}
            </CustomText>
        </Box>
    );
};

export default CustomEmptyData;
