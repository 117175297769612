import { FC, useContext, useEffect, useState } from 'react';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import { baseColors, baseFontFamily, baseRgbColors } from 'src/utils/constants/baseStyles';
import { EventPlanningItem, EventPlanningViewType } from 'src/interfaces/interfaces';
import EventService from 'src/services/Calendar/events.service';
import {
    differenceBetweenTimes,
    eventColor,
    newEventConvertedTime,
    setCurrentData,
    transformeTimeFormat,
} from './PlanningContentLogic';
import { AgendaContext } from 'src/providers/AgendaProvider';
import { formatDateFormat, fullName } from 'src/utils/utils';
import { addThirtyMinutesToDroppedTime } from 'src/views/MyAgenda/EventModal/EventModalLogic';
import moment from 'moment';
import { UserContext } from 'src/providers/UserProvider';
import { toast } from 'react-toastify';
import { OperationMessage } from 'src/utils/constants/constants';

interface EventContentProps {
    spcData: EventPlanningItem;
    currentEventStartAt?: string;
    currentEventEndAt?: string;
    currentEventSpecialityId?: any;
    newEventData: any;
    lastEvent?: boolean;
    weeklyEventData?: any;
    isHdj?: boolean;
    currentEventData?: any;
    activeDottedBorder?: any;
    setUpdateList?: Function;
    setSelectedData?: Function;
    toggleModal: Function;
    setSpecialityId: Function;
    toggleCreateEventModal: Function;
    setNewEventData: Function;
    setSelectedTime?: Function;
    setCurrentEventData?: Function;
    setLoadingPlanningEvent?: Function;
    setActiveDottedBorder?: Function;
    setCurrentEventStartAt?: Function;
    setCurrentEventEndAt?: Function;
    setCurrentEventSpecialityId?: Function;
    setNewSpecialityInfos?: Function;
    viewType?: EventPlanningViewType['viewType'];
    selectedIntervenants?: any;
    readOnly?: boolean;
    prevEventData?: any;
}

const EventContent: FC<EventContentProps> = (props) => {
    const {
        prevEventData,
        spcData,
        setUpdateList,
        setSelectedData,
        toggleModal,
        setSpecialityId,
        toggleCreateEventModal,
        newEventData,
        setNewEventData,
        setSelectedTime,
        weeklyEventData,
        isHdj,
        currentEventData,
        setCurrentEventData,
        setLoadingPlanningEvent,
        currentEventStartAt,
        currentEventEndAt,
        setCurrentEventStartAt,
        setCurrentEventEndAt,
        currentEventSpecialityId,
        setCurrentEventSpecialityId,
        setNewSpecialityInfos,
        viewType,
        readOnly,
    } = props;
    const [lastEvent, setLastEvent] = useState(false);
    const { userInfos } = useContext(UserContext);
    const freeEvent = spcData?.freeEvent;
    let intervenants: any = [];
    const [activeDottedBorder, setActiveDottedBorder] = useState<boolean>(false);
    const { currentDate, setSelectedEvent, allRooms } = useContext(AgendaContext);
    const roomColor = allRooms?.find((room: any) => room.id === spcData?.eventData?.location?.id) ?? {
        color: { color: baseColors.black, backgroundColor: baseColors.green.light },
    };

    useEffect(() => {
        if (!spcData?.freeEvent) {
            const last = +spcData?.timeEnd === +prevEventData?.value || +spcData?.timeEnd === +prevEventData?.start;
            setLastEvent(last);
        }
    }, [spcData, prevEventData]);

    const backgroundColor = (spcData: EventPlanningItem) => roomColor?.color?.backgroundColor;

    const fontColor = (spcData: EventPlanningItem) => eventColor([], spcData?.dayHospitalId, true);

    const setSelectedEventData = (data: any) =>
        setSelectedData?.(() => {
            if (weeklyEventData) {
                return { ...data, weeklyEventData: weeklyEventData };
            }
            return data;
        });

    const handleSpcClick = (data: any) => {
        setSelectedEvent(data?.informations);
        setSelectedEventData(data);
        toggleModal();
    };

    const handleCreateEvent = (data: any) => {
        setSelectedTime?.({ timeStart: transformeTimeFormat(data.start), timeEnd: transformeTimeFormat(data.end) });
        setSpecialityId(data?.specialityTypeId);
        setSelectedEventData(data);
        toggleCreateEventModal();
    };

    const firstStyle = (spcData: any) => {
        if (spcData) {
            if (spcData?.name && spcData?.name !== null) {
                if (spcData?.eventData?.professionals?.length > 0) {
                    const attendeeUsers = spcData?.eventData?.professionals?.map(
                        (intervenent: any) => intervenent?.attendeeUser,
                    );
                    intervenants = attendeeUsers;
                }
                return {
                    borderTop: `4px solid ${baseRgbColors.white}`,
                    padding: '7px 0 0 18px',
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10,
                };
            }
        }
    };

    const intervenantContent = () => {
        const showThreeDot = intervenants?.length > 2;
        if (spcData?.name && spcData?.type?.label && intervenants?.length > 0) {
            return (
                <>
                    <p style={{ marginTop: 1 }} />{' '}
                    {intervenants?.slice(0, 3)?.map((item: any, i: number) => (
                        <Typography key={item?.id} sx={{ fontSize: 13, color: roomColor?.color?.color }}>
                            {fullName(item?.firstName, item?.lastName)}
                            {showThreeDot && i === 2 && (
                                <Typography style={{ fontSize: 20, color: roomColor?.color?.color }}>...</Typography>
                            )}
                        </Typography>
                    ))}
                </>
            );
        }
        return null;
    };

    const roomLabel = () => {
        const labelValue = (label: any) => (
            <>
                <p style={{ marginTop: 1 }} />
                <Typography fontFamily={baseFontFamily.OpenSansSemiBold} fontSize={13} color={baseColors.black}>
                    {label}
                    {/* eto */}
                </Typography>
            </>
        );
        if (spcData?.name && spcData?.type?.label) {
            if (spcData?.eventData?.isInSitu) {
                return labelValue(spcData?.eventData?.customLocation);
            }
            return labelValue('Visio');
        }
        return null;
    };

    const handleDragEnter = (e: any) => {
        e.preventDefault();
        setActiveDottedBorder(newEventData?.freeEvent);
        if (spcData?.specialityTypeId) setNewEventData(spcData);
        e.stopPropagation();
    };

    const handleDragStart = (e: any) => {
        e.preventDefault();
        if (!spcData?.freeEvent && spcData?.eventId > 0) {
            setCurrentData(spcData, {
                setCurrentEventData,
                setCurrentEventSpecialityId,
                setCurrentEventStartAt,
                setCurrentEventEndAt,
            });
        }
        e.stopPropagation();
    };

    const handleDragOver = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDragLeave = (e: any) => {
        e.preventDefault();
        setActiveDottedBorder(false);
        e.stopPropagation();
    };

    const handleDrop = (e: any) => {
        e.preventDefault();
        // newEventData?.specialityTypeId > 0 && newEventData?.freeEvent && setLoadingPlanningEvent?.(true);
        handleUpdateEvent();
        e.stopPropagation();
    };

    const handleUpdateEvent = () => {
        const eventPayload: any = {
            startDate: newEventConvertedTime(newEventData?.start, formatDateFormat(currentDate)),
            endDate: newEventConvertedTime(
                differenceBetweenTimes(currentEventStartAt ?? '', currentEventEndAt ?? '', newEventData?.start),
                formatDateFormat(currentDate),
            ),
            specialityTypeId: newEventData?.specialityTypeId ?? 0,
        };

        if (newEventData?.specialityTypeId !== currentEventSpecialityId) {
            setNewSpecialityInfos?.(eventPayload);
            toggleCreateEventModal();
            setActiveDottedBorder(false);
        } else {
            if (newEventData?.specialityTypeId > 0 && newEventData?.freeEvent) {
                const endDate: any = addThirtyMinutesToDroppedTime({
                    timeStart: eventPayload.startDate,
                    timeEnd: eventPayload.endDate,
                });

                const finalPayload = {
                    ...eventPayload,
                    endDate: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
                };

                EventService.updateEventViaDnd(currentEventData, finalPayload)
                    .then((res: any) => {
                        if (res) {
                            toast.success(OperationMessage.success);
                            setUpdateList?.((prev: boolean) => !prev);
                        }
                    })
                    .catch((error: any) => {
                        console.log(error);
                        setLoadingPlanningEvent?.(false);
                    })
                    .finally(() => setActiveDottedBorder(false));
            }
        }
    };

    const handleClickEvent = () => {
        if ((!freeEvent && !lastEvent) || (!freeEvent && lastEvent && spcData?.name !== null)) {
            handleSpcClick(spcData);
        } else {
            if (Boolean(isHdj) || readOnly) {
                return;
            }
            return handleCreateEvent(spcData);
        }
    };

    const isMyEvent = spcData?.eventData?.ownerId === userInfos?.id;
    const disableDnd = viewType !== 'weekly' && !readOnly;

    return (
        <TableCell
            draggable={disableDnd && isMyEvent}
            onDrag={(e) => handleDragStart(e)}
            onDragOver={(e) => handleDragOver(e)}
            onDragEnter={(e) => handleDragEnter(e)}
            onDrop={(e) => handleDrop(e)}
            onDragLeave={(e) => handleDragLeave(e)}
            onDragEnd={() => setActiveDottedBorder(false)}
            sx={{
                borderRight: '1px solid rgba(224, 224, 224)',
                borderLeft: `7px solid ${baseRgbColors.white}`,
                borderBottom: 0,
                cursor: !freeEvent ? 'pointer' : null,
                border: activeDottedBorder ? 'dotted' : undefined,
                backgroundColor: !freeEvent ? backgroundColor(spcData) : baseColors.white,
                borderEndEndRadius: !freeEvent && lastEvent ? '15px' : undefined,
                borderEndStartRadius: !freeEvent && lastEvent ? '15px' : undefined,
                ...firstStyle(spcData),
            }}
            onClick={handleClickEvent}
        >
            <Typography
                fontFamily={baseFontFamily.OpenSansSemiBold}
                fontSize={13}
                color={!freeEvent ? fontColor(spcData) : baseColors.black}
            >
                {spcData?.name ?? ''}
            </Typography>

            <Typography
                fontFamily={baseFontFamily.OpenSansRegular}
                fontSize={13}
                color={!freeEvent ? fontColor(spcData) : roomColor?.color?.color}
            >
                {(spcData?.name && spcData?.type?.label) ?? ''}
            </Typography>

            {intervenantContent()}
            {roomLabel()}
            <p style={{ height: spcData?.name && spcData?.type?.label ? 5 : undefined }} />
        </TableCell>
    );
};

export default EventContent;
