import axios from './AxiosInstance';

const getConnexions = (filters?: any) => {
    return axios
        .get('api/userSession?paginated=false')
        .then((response: any) => {
            const result = JSON.parse(response.request.response);
            return result.data;
        })
        .catch((reason) => {
            console.log('Get User Connexion Error', reason);
        });
};

const ConnexionService = {
    getConnexions,
};

export default ConnexionService;
