import { FC, useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import { Box } from '@mui/material';
import CustomLoadingButton from '../Button/CustomLoadingButton';
import CustomModal from '../CustomModal';
import { Spacer } from '../Spacer/Spacer';
import { CustomText } from '../Text/CustomText';

interface RouteLeavingGuardProps {
    navigate: any;
    when: boolean;
    shouldBlockNavigation: any;
    callBackconfirm: any;
    callBackCancel: any;
    savingData?: boolean;
}

export const RouteLeavingGuard: FC<RouteLeavingGuardProps> = (props) => {
    const { navigate, when, shouldBlockNavigation, callBackconfirm, callBackCancel, savingData } = props;
    const [modalVisible, updateModalVisible] = useState(false);
    const [lastLocation, updateLastLocation] = useState<any>(undefined);
    const [confirmedNavigation, updateConfirmedNavigation] = useState(false);
    const [loading, setLoading] = useState(false);
    const showModal = (location: any) => {
        updateModalVisible(true);
        updateLastLocation(location);
    };

    const closeModal = (cb: any) => {
        updateModalVisible(false);
        if (cb) {
            cb();
        }
    };

    const handleBlockedNavigation = (nextLocation: any) => {
        if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
            showModal(nextLocation);
            return false;
        }
        return true;
    };

    const continueAnyWay = () => {
        callBackCancel();
        closeModal(() => {
            if (lastLocation) {
                updateConfirmedNavigation(true);
            }
        });
    };
    const handleConfirmNavigationClick = async () => {
        setLoading(true);
        try {
            await callBackconfirm().then(() => {
                closeModal(() => {
                    if (lastLocation) {
                        updateConfirmedNavigation(true);
                    }
                });
                setLoading(false);
            });
        } catch (e) {
            closeModal(() => {});
            setLoading(false);
        }
    };

    useEffect(() => {
        if (confirmedNavigation && lastLocation) {
            navigate(lastLocation.pathname);
            updateConfirmedNavigation(false);
        }
    }, [confirmedNavigation]);

    return (
        <>
            <Prompt when={when} message={handleBlockedNavigation} />
            <CustomModal disableBackdropClick open={modalVisible} toggleModal={updateModalVisible} title="Attention !">
                <Box>
                    <CustomText style={{ textAlign: 'center' }}>
                        Les modifications n'ont pas été sauvegardées. <br></br>Voulez vous vraiment quitter cette page ?
                    </CustomText>
                    <Spacer height={30} />
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <CustomLoadingButton
                            orange
                            disabled={loading || savingData}
                            label="Quitter"
                            handleClick={continueAnyWay}
                        />
                        <Spacer width={40} />
                        <CustomLoadingButton
                            label="Enregistrer"
                            disabled={loading || savingData}
                            loading={loading || savingData}
                            handleClick={handleConfirmNavigationClick}
                        />
                    </div>
                </Box>
            </CustomModal>
        </>
    );
};
