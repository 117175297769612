import {
    usePreFetchEventLocationRQHooks,
    usePreFetchEventTypesRQHooks,
    usePreFetchPatientsInEventRQHooks,
    usePreFetchSpecialitiesRQHooks,
} from 'src/hooks/ReactQueryHooks/useEvenRQtHooks';
import { usePreFetchGoalsRQHooks } from 'src/hooks/ReactQueryHooks/useGoalRQHooks';
import {
    usePreFetchPatientRQHooks,
    usePreFetchPatientsByProfessionalRQHooks,
} from 'src/hooks/ReactQueryHooks/usePatientRQHooks';
import { usePreFetchProgramsRQHooks } from 'src/hooks/ReactQueryHooks/useProgramRQHooks';
import { usePreFetchSkillsRQHooks } from 'src/hooks/ReactQueryHooks/useSkillRQHooks';

const HandlePrefetchQueries = () => {
    usePreFetchPatientRQHooks();
    usePreFetchPatientsByProfessionalRQHooks();
    usePreFetchProgramsRQHooks();
    usePreFetchGoalsRQHooks();
    usePreFetchSkillsRQHooks();

    // PREFECTCH EVENTS DATA
    usePreFetchEventTypesRQHooks();
    usePreFetchSpecialitiesRQHooks();
    usePreFetchPatientsInEventRQHooks();
    usePreFetchEventLocationRQHooks();
};

export { HandlePrefetchQueries };
