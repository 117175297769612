import { FC, useContext, Dispatch, SetStateAction } from 'react';
import { Typography } from '@mui/material';
import { AgendaContext } from 'src/providers/AgendaProvider';
import { baseColors, baseFontFamily, baseFontSize, baseFontWeight } from 'src/utils/constants/baseStyles';

type DurationItemType = {
    selectedDuration: number;
    setSelectedDuration: Dispatch<SetStateAction<number>>;
    clearInput: Function;
    time: {
        value: number;
        label?: number;
        isHour?: boolean;
    };
};

const DurationItem: FC<DurationItemType> = ({
    selectedDuration,
    setSelectedDuration,
    clearInput,
    time: { value, label, isHour },
}) => {
    const { setDuration } = useContext(AgendaContext);
    const handleChange = () => {
        setSelectedDuration((prev) => {
            if (prev === value) {
                setDuration([]);
                return 0;
            } else {
                setDuration([value, value]);
                return value;
            }
        });

        clearInput();
    };

    const timeData = {
        value,
        label,
        unit: isHour ? 'h' : 'min',
    };
    const itemBackgroundColor = selectedDuration === value ? baseColors.green.primary : baseColors.green.light;
    const itemTextColor = selectedDuration === value ? baseColors.white : baseColors.green.primary;

    return (
        <div
            style={{
                borderRadius: 5,
                width: 50,
                backgroundColor: itemBackgroundColor,
                padding: '10px 7px 10px 7px',
                marginBottom: 2,
                marginRight: 1,
                cursor: 'pointer',
            }}
            onClick={handleChange}
        >
            <Typography
                style={{
                    color: itemTextColor,
                    textAlign: 'center',
                    fontSize: baseFontSize.sm,
                    fontFamily: baseFontFamily.OpenSansSemiBold,
                }}
            >
                {`${timeData.label} ${timeData.unit}`}
            </Typography>
        </div>
    );
};

export default DurationItem;
