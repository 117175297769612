import { separateHoursAndMinutes } from "src/utils/utils";

const setDefaultTime = (eventTime: string, timeToSet: Date ) => {
    let hours = 0;
    let minutes = 0;
    hours = separateHoursAndMinutes(eventTime)?.hours;
    minutes = separateHoursAndMinutes(eventTime)?.minutes;
    timeToSet.setHours(hours);
    timeToSet.setMinutes(minutes);
}

export { setDefaultTime }