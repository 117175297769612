import { useEffect } from 'react';

const useAvoid404onPageRefresh = ({ history, location }: any) => {
    useEffect(() => {
        setTimeout(() => {
            history.push(location.pathname);
            const hash = location.hash.trim();
            if (hash) {
                window.location.href = `#${location.pathname}${location.hash}`;
            }
        }, 100);
    }, []);

    return null;
};

export default useAvoid404onPageRefresh;
