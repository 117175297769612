interface IProps {
    setSelectedHdj: any;
    selected: Set<number>;
    setHdj: any;
    isConsultation?: boolean;
    isWorkshop?: boolean;
    isDocs?: boolean;
    hdjRelation?: boolean;
}

const handleChange = ({
    setSelectedHdj,
    selected,
    setHdj,
    isConsultation,
    isWorkshop,
    isDocs,
    hdjRelation
} : IProps) => {

    const res = Array.from(selected)
    setSelectedHdj?.(res);
    setHdj?.((prev: any) => {
        let additionalValue = null;

        if (isConsultation) {
            additionalValue = { consultationIds: res };
        }
        if (isWorkshop) {
            additionalValue = { workshopIds: res };
        }
        if (isDocs) {
            additionalValue = { documentIds: res };
        }
        if (hdjRelation) {
            additionalValue = { linkedDayHospitalIds: res };
        }

        return { ...prev, ...additionalValue };
    });
};

export {
    handleChange
}

    