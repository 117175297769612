import { FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import CustomLoadingButton from '../Button/CustomLoadingButton';
import CustomModal from '../CustomModal';
import { Spacer } from '../Spacer/Spacer';
import { CustomText } from '../Text/CustomText';

interface CustomConfirmProps {
    title?: string;
    message: string | JSX.Element;
    callBackconfirm: any;
    yesLabel?: string;
    noLabel?: string;
    modalVisible: boolean;
    updateModalVisible: Function;
    defaultLoading?: boolean;
    disable?: boolean;
}

export const CustomConfirm: FC<CustomConfirmProps> = (props) => {
    const {
        disable,
        callBackconfirm,
        message,
        yesLabel,
        noLabel,
        modalVisible,
        updateModalVisible,
        defaultLoading,
        title,
    } = props;
    const [loading, setLoading] = useState(false);

    const closeModal = () => {
        updateModalVisible(false);
        setLoading(false);
    };

    const handleConfirmNavigationClick = () => {
        setLoading(true);
        try {
            callBackconfirm?.();
            setTimeout(() => {
                setLoading(false);
            }, 2000);
        } catch (e) {
            setLoading(false);
        }
    };

    useEffect(() => {
        setLoading(defaultLoading ?? false);
    }, [defaultLoading]);

    const modalTitle = title ?? 'Attention !';

    return (
        <>
            <CustomModal disableBackdropClick open={modalVisible} toggleModal={updateModalVisible} title={modalTitle}>
                <Box>
                    <CustomText style={{ textAlign: 'center' }}>{message}</CustomText>
                    <Spacer height={30} />
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <CustomLoadingButton
                            disabled={loading || disable}
                            label={noLabel ? noLabel : 'Non'}
                            orange
                            handleClick={() => closeModal()}
                        />
                        <Spacer width={40} />
                        <CustomLoadingButton
                            label={yesLabel ? yesLabel : 'Oui'}
                            loading={loading || disable}
                            handleClick={() => {
                                handleConfirmNavigationClick();
                            }}
                        />
                    </div>
                </Box>
            </CustomModal>
        </>
    );
};
