import React, { FC, useContext } from 'react';
import { Typography } from '@mui/material';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { HdjContext } from 'src/providers/HdjProvider';

interface CombinedViewProps {
    isCombinedView: boolean;
    setValue: (value: boolean) => void;
}

interface RadioItem {
    label: string;
    value: boolean;
}

const CombinedView: FC<CombinedViewProps> = (props) => {
    const { isCombinedView, setValue } = props;
    const { setIsCombinedView } = useContext(HdjContext);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const val = (event.target as HTMLInputElement).value === 'true' ? true : false;
        setValue(val);
        setIsCombinedView(val);
    };

    const radios = [
        {
            label: 'Oui',
            value: true,
        },
        {
            label: 'Non',
            value: false,
        },
    ];

    return (
        <FormControl sx={{ marginBottom: 2 }} component="fieldset">
            <FormLabel component="legend">
                <Typography
                    marginBottom={1}
                    fontFamily={baseFontFamily.OpenSansSemiBold}
                    fontSize={13}
                    color={baseColors.black}
                >
                    Vue combiné :
                </Typography>
            </FormLabel>
            <RadioGroup
                value={isCombinedView}
                onChange={handleChange}
                row
                aria-label="gender"
                name="row-radio-buttons-group"
            >
                {radios.map((radio: RadioItem, i: number) => (
                    <FormControlLabel
                        key={i}
                        value={radio.value}
                        control={
                            <Radio
                                sx={{
                                    color: baseColors.grey,
                                    '&.Mui-checked': {
                                        color: baseColors.green.primary,
                                    },
                                }}
                            />
                        }
                        label={
                            <Typography
                                fontFamily={baseFontFamily.OpenSansRegular}
                                fontSize={12}
                                color={baseColors.black}
                            >
                                {radio.label}
                            </Typography>
                        }
                    />
                ))}
            </RadioGroup>
        </FormControl>
    );
};

export default CombinedView;
