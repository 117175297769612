import { FC, useEffect, useState } from 'react';
import CustomHdjCheckListContent from 'src/components/CustomHdjCheckListContent';
import useDebounce from 'src/hooks/useDebounce';
import { getDocs } from 'src/services/Documents';
import AttachFileModal from '../../AttachFileModal';

interface DocumentFormProps {
    fetchedHdjData: any;
    setHdj: any;
    marginRight?: boolean;
}

const DocumentForm: FC<DocumentFormProps> = (props) => {
    const { setHdj, fetchedHdjData, marginRight } = props;
    const [loadingDocs, setLoadingDocs] = useState(false);
    const [modalDocVisible, setModalDocVisible] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [searchKey, setSearchKey] = useState<string>('');
    const [updateList, setUpdateList] = useState<boolean>(false);
    const defaultSelectedItems = (fetchedHdjData?.documents?.length > 0 && fetchedHdjData?.documents) ?? [];

    const handleFetchDocs = (query: string) => {
        setLoadingDocs(true);
        getDocs(query).then((response) => {
            setDocuments(response.data.data.items);
            setLoadingDocs(false);
        });
    };

    const { debouncedResults } = useDebounce(handleFetchDocs);

    const toggleModal = () => setModalDocVisible(!modalDocVisible);
    const handleSearch = (value: string) => {
        setSearchKey(value);
        debouncedResults(value);
    };
    useEffect(() => {
        handleFetchDocs('');
    }, [updateList]);

    return (
        <>
            <AttachFileModal
                modalDocVisible={modalDocVisible}
                toggleModal={toggleModal}
                setUpdateList={setUpdateList}
            />

            <CustomHdjCheckListContent
                marginRight={marginRight}
                loading={loadingDocs}
                items={documents}
                selectedItems={defaultSelectedItems}
                label="Documents à transmettre au patient en amont de la journée d'hospitalisation"
                btnLabel="Ajouter un document"
                toggleModal={toggleModal}
                handleSearch={handleSearch}
                setHdj={setHdj}
                isDocs
            />
        </>
    );
};

export default DocumentForm;
