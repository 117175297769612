import { makeStyles } from '@mui/styles';
import { baseBorderRadius, baseColors, baseScreenHeight } from 'src/utils/constants/baseStyles';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        minWidth: 660,
        backgroundColor: baseColors.white,
        ...baseBorderRadius.leftOnly,
        height: baseScreenHeight.programSynthese,
    },
    wrapper: { padding: 20, overflowY: 'auto' },
    row: {
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
    },
    rowSimple: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
    },
    processLine: { marginTop: 20, flex: 7, justifyContent: 'space-evenly', display: 'flex', flexDirection: 'row' },
    card: {
        background: baseColors.green.light,
        padding: 20,
        minWidth: '25%',
        marginRight: 40,
        borderRadius: 7,
    },
});

export default useStyles;
