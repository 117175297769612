import { ProgramForm } from 'src/views/Programme/Form/ProgramForm';

const ProgramPrivateRoutes = {
    programForm: '/programmes/new',
    programFormEdit: '/programmes/:id/edit',
};

const PrivateProgramRoutes = [
    {
        key: 'PROGRAMFORM',
        path: ProgramPrivateRoutes.programForm,
        component: <ProgramForm />,
    },
    {
        key: 'PROGRAMFORM-EDIT',
        path: ProgramPrivateRoutes.programFormEdit,
        component: <ProgramForm />,
    },
];

export { ProgramPrivateRoutes, PrivateProgramRoutes };
