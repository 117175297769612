import { Avatar, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { FC, useContext } from 'react';
import { baseColors, baseFontFamily, baseFontSize } from '../../utils/constants/baseStyles';
import { CustomText } from '../Text/CustomText';
import { PatientContext } from 'src/providers/PatientProvider';
import { Spacer } from './../Spacer/Spacer';
import { url } from 'inspector';

interface PatientCardItemProps {
    id: number;
    onClick: Function;
    active: boolean;
    fullName: string;
    description: string;
    photo?: string;
}

const useStyles = makeStyles({
    container: {
        minHeight: 50,
        justifyContent: 'space-evenly',
        alignContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        borderRadius: 10,
        justifyItems: 'center',
        marginTop: 10,
        paddingTop: '10px',
        paddingBottom: '10px',
    },
});

export const PatientCardItem: FC<PatientCardItemProps> = (props) => {
    const { fullName, description, active, onClick, photo } = props;
    const classes = useStyles();
    const { setFetchingPatientData, setJustAddedPatient } = useContext(PatientContext);
    const bgColor = active ? baseColors.green.primary : baseColors.green.light;
    const nameColor = active ? baseColors.white : baseColors.black;
    const descriptionColor = active ? baseColors.white : baseColors.green.primary;
    const handleClick = () => {
        setFetchingPatientData((prev: boolean) => !prev);
        setJustAddedPatient(null);
        onClick();
    };

    const descriptionValue = description?.trim() !== '' ? description : 'Dernière pathologie connue';

    return (
        <Grid
            container
            onClick={handleClick}
            style={{
                background: bgColor,
                borderColor: bgColor,
            }}
            className={classes.container}
        >
            <Grid item justifyContent="center" flex={1.7}>
                <Avatar
                    alt="Patient's avatar"
                    style={{ alignSelf: 'center', marginLeft: '18%' }}
                    sx={{ width: 50, height: 50 }}
                    src={photo}
                />
            </Grid>

            <Grid
                item
                style={{ marginBottom: 5, display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}
                flex={4}
            >
                <CustomText fontFamily={baseFontFamily.OpenSansBold} color={nameColor} fontSize={baseFontSize.sm}>
                    {fullName}
                </CustomText>
                {/* <Spacer height={5} /> */}
                {/* <CustomText
                    fontFamily={baseFontFamily.OpenSansRegular}
                    color={descriptionColor}
                    fontSize={baseFontSize.mini}
                >
                    {descriptionValue}
                </CustomText> */}
            </Grid>
        </Grid>
    );
};
