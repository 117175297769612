import { FC } from 'react';
import { baseColors, baseFontFamily, baseFontSize } from 'src/utils/constants/baseStyles';
import { CustomText } from '../Text/CustomText';
import useStyles from './styles';
import { ProgramSkill } from 'src/interfaces/interfaces';
import { isOdd } from 'src/utils/utils';

interface IProps {
    isGoals?: boolean;
    isArticles?: boolean;
    isWorkshops?: boolean;
    data?: {
        id: number;
        name: string;
        title?: string;
        programs?: { id: number; title?: string }[];
    };
    programs?: ProgramSkill[];
}

const CustomTagItem: FC<IProps> = ({ programs, isGoals, isArticles, isWorkshops, data }) => {
    const classes = useStyles();
    const tagItems = () => {
        if (isGoals) {
            return [];
        } else if (isArticles) {
            return [];
        } else if (isWorkshops) {
            return [];
        } else {
            return data?.id ? data?.programs : programs ?? [];
        }
    };
    const tagLength = tagItems()?.length ?? 0;
    const tagData = tagItems()?.slice(0, 3) ?? [];

    const tagWithColors = tagData.map((tag) => ({
        ...tag,
        color: !isOdd(tag?.id) ? baseColors.orange.primary : baseColors.green.pastel,
    }));

    return (
        <div style={{ marginLeft: 20 }} className={classes.row}>
            {tagWithColors?.map((item: any, i: number) => (
                <div
                    key={item?.id}
                    style={{
                        padding: '3px 15px',
                        backgroundColor: item?.color,
                        margin: '0px 5px',
                        borderRadius: 5,
                    }}
                >
                    <CustomText
                        fontSize={baseFontSize.mini}
                        color={baseColors.white}
                        fontFamily={baseFontFamily.OpenSansSemiBold}
                    >
                        {item?.name ?? item?.title ?? '-'}
                    </CustomText>
                </div>
            ))}

            {tagLength > 2 && (
                <div onClick={() => {}}>
                    <CustomText style={{ width: 70, marginLeft: 10, fontSize: baseFontSize.mini, cursor: 'pointer' }}>
                        {`+${tagLength - 2} autre${tagLength - 2 > 1 ? 's' : ''}`}
                    </CustomText>
                </div>
            )}
        </div>
    );
};

export default CustomTagItem;
