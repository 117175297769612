import { FC, useEffect, useState, useContext } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import useStyles, { sxStyles } from './styles';
import WeeklyDayPicker from 'src/components/WeeklyDayPicker';
import ShowPlanningBy from './ShowPlanningBy';
import { useDispatch, useSelector } from 'react-redux';
import { retrievePlanning } from 'src/actions/dayHospitals/dayHospitals';
import { formatDateFormat } from 'src/utils/utils';
import { HdjContext } from 'src/providers/HdjProvider';
import CustomLoader from 'src/components/CustomLoader/CustomLoader';
import { PlanningFilters, SpecialitySetValues } from 'src/interfaces/interfaces';
import CustomEmptyData from 'src/components/CustomEmptyData';
import CustomPlanningAgendaTimeLine from 'src/components/CustomPlanningAgendaTimeLine';
import useFetchUpstairs from 'src/hooks/useFetchUpstairs';
import EventModal from 'src/views/MyAgenda/EventModal';
import { useParams } from 'react-router-dom';
import DayHospitalDataService from 'src/services/Hdj/dayHospital.service';
import CustomCircleLoader from 'src/components/CustomLoader/CustomCircleLoader';

const Planning: FC = () => {
    const classes = useStyles();
    const params: any = useParams();
    const {
        showPlanningBy,
        specialityFilterId,
        loadingPlanningList,
        consultationEventTypeId,
        dayHospitalIds,
        currentHdjInfos,
        isCombinedView,
        combinedIds,
        checkedHdjWithCombinedView,
        setCheckedHdjWithCombinedView,
        setLoadingPlanningList,
        setPlanningFilters,
        setSpecialityFilterId,
        setConsultationEventTypeId,
        setIsCombinedView,
        setShowPlanningBy,
        setCurrentHdjInfos,
        setDayHospitalIds,
    } = useContext(HdjContext);
    const dispatch = useDispatch();
    const { handleFetchUpstairs } = useFetchUpstairs();
    const [selectedDate, setSelectedDate] = useState<string>('');
    const [modalEvent, setModalEvent] = useState<boolean>(false);
    const [updateList, setUpdateList] = useState<boolean>(false);
    const [createEventFromBtnAction, setCreateEventFromBtnAction] = useState<boolean>(false);
    const [specialityId, setSpecialityId] = useState<number>(0);
    const [selectedTime, setSelectedTime] = useState<any>(null);
    const [selectedEventData, setSelectedEventData] = useState<any>(null);
    const [loadingHdjInfos, setLoadingHdjInfos] = useState<boolean>(false);

    const toggleModal = () => setModalEvent(!modalEvent);
    const defaultDate = formatDateFormat(new Date(currentHdjInfos?.date ?? ''));

    const handleFetchHdjData = () => {
        setLoadingHdjInfos(true);
        if (+params?.id > 0) {
            DayHospitalDataService.getDayHospitalById(+params?.id)
                .then((response) => {
                    const hdjData = response?.data?.data;
                    setCurrentHdjInfos(hdjData);
                    setDayHospitalIds([hdjData?.id]);
                    setSelectedDate(currentHdjInfos?.date ?? '');
                    setLoadingHdjInfos(false);
                })
                .catch((error) => {
                    console.log({ fetchingHdjError: error });
                    setLoadingHdjInfos(false);
                });
        }
    };

    const handleFetchData = () => {
        const filterList = setPlanningFilters;
        const setValues: SpecialitySetValues = {
            setLoadingList: setLoadingPlanningList,
            setPlanningFilters: filterList,
        };

        const filters: PlanningFilters = {
            filterId: specialityFilterId,
            consultationEventTypeId: consultationEventTypeId,
            isCombinedView: isCombinedView,
        };

        dispatch(
            retrievePlanning(
                showPlanningBy,
                selectedDate !== '' ? selectedDate : defaultDate,
                isCombinedView ? combinedIds : dayHospitalIds,
                setValues,
                filters,
            ),
        );
    };

    const initializeFilters = () => {
        setSpecialityFilterId(0);
        setConsultationEventTypeId(0);
        setIsCombinedView(false);
        setShowPlanningBy('speciality');
    };

    useEffect(() => {
        handleFetchData();
        handleFetchUpstairs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        selectedDate,
        showPlanningBy,
        specialityFilterId,
        consultationEventTypeId,
        isCombinedView,
        combinedIds,
        dayHospitalIds,
        updateList,
    ]);

    useEffect(() => {
        initializeFilters();
        setCheckedHdjWithCombinedView([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        handleFetchHdjData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params?.id]);

    const planningData = useSelector((state: any) => state?.dayHospitals?.data);
    const isEmpty = planningData?.length === 0;

    return (
        <Box className={classes.container}>
            <Box sx={{ flex: 2, padding: 2 }}>
                <Box className={classes.header}>
                    <Typography color={baseColors.black} fontFamily={baseFontFamily.BreeSerif} fontSize={18}>
                        {isCombinedView ? 'Planning combiné' : 'Planning'} :{' '}
                        {(!isCombinedView && currentHdjInfos?.name) ?? ''}
                    </Typography>

                    {isCombinedView && (
                        <Box sx={sxStyles.container}>
                            {!checkedHdjWithCombinedView?.length ? (
                                <Typography
                                    color={baseColors.black}
                                    fontFamily={baseFontFamily.BreeSerif}
                                    fontSize={16}
                                >
                                    {currentHdjInfos?.name}
                                </Typography>
                            ) : (
                                checkedHdjWithCombinedView?.map((hdj: any, i: number) => (
                                    <Box key={i} style={sxStyles.titleContainer}>
                                        <Typography marginRight={1} />
                                        <Typography
                                            color={baseColors.black}
                                            fontFamily={baseFontFamily.BreeSerif}
                                            fontSize={16}
                                        >
                                            {` ${hdj.name} ${i !== checkedHdjWithCombinedView?.length - 1 ? '/' : ''}`}
                                        </Typography>
                                        <Typography marginLeft={1} />
                                    </Box>
                                ))
                            )}
                        </Box>
                    )}

                    <Box marginTop={-2}>
                        {loadingHdjInfos ? (
                            <Box sx={sxStyles.loadingContainer}>
                                <CustomCircleLoader height={50} width={50} userInfos />
                            </Box>
                        ) : (
                            <WeeklyDayPicker
                                defaultDate={currentHdjInfos?.date}
                                setSelectedDate={setSelectedDate}
                                daysCount={4}
                            />
                        )}
                    </Box>
                </Box>

                <ShowPlanningBy />
            </Box>

            <Box
                sx={{ flex: 8, padding: 1 }}
                style={{
                    overflow: 'hidden',
                    overflowY: 'scroll',
                }}
            >
                {loadingPlanningList ? (
                    <CustomLoader isPlanning loadingNumber={4} />
                ) : isEmpty ? (
                    <CustomEmptyData />
                ) : (
                    <CustomPlanningAgendaTimeLine
                        isHdj
                        setSpecialityId={setSpecialityId}
                        data={planningData}
                        toggleCreateEventModal={toggleModal}
                        setUpdateList={setUpdateList}
                        setSelectedTime={setSelectedTime}
                        selectedEvent={selectedEventData}
                        setSelectedEvent={setSelectedEventData}
                        patientForAgendaWorkShop
                    />
                )}
            </Box>

            <EventModal
                setUpdateList={setUpdateList}
                specialityId={specialityId}
                modalOpen={modalEvent}
                setSpecialityId={setSpecialityId}
                modalToggle={toggleModal}
                selectedTime={selectedTime}
                selectedEventData={selectedEventData}
                createEventFromBtnAction={createEventFromBtnAction}
                setCreateEventFromBtnAction={setCreateEventFromBtnAction}
            />
        </Box>
    );
};

export default Planning;
