import { Box, Grid } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { baseColors, baseFontFamily, baseFontSize } from 'src/utils/constants/baseStyles';
import { InfoItem } from './InfoItem';
import PdfIcon from 'src/components/CustomIcon/IconPdf';
import { CustomText } from 'src/components/Text/CustomText';
import { getPhysicalActivity } from 'src/services/Patients';
import LoadingSkeleton from 'src/components/CustomLoader/LoadingSkeleton';
import { LevelActivity } from 'src/interfaces/interfaces';
import { PatientContext } from 'src/providers/PatientProvider';
import { CustomBadge } from 'src/components/Badge/CustomBadge';
import { StateProgramUserTemp } from 'src/utils/constants/constants';
import usePrograms from 'src/hooks/usePrograms';

interface PatientProgramProps {
    antecedantList: any;
    emptyAntecedantList: any;
    loadProgram: any;
    emptyProgramList: any;
    programList: any;
    loadAntecedants: any;
    loadTreatments: any;
    emptyTraitmentList: any;
    treatmentsData: any;
}

export const emptyContent = (label: string) => (
    <Grid marginTop={1} container>
        <CustomText fontSize={baseFontSize.mini} fontFamily={baseFontFamily.OpenSansRegular}>
            {label}
        </CustomText>
    </Grid>
);

const PatientProgram: FC<PatientProgramProps> = ({
    antecedantList,
    emptyAntecedantList,
    loadProgram,
    emptyProgramList,
    programList,
    loadAntecedants,
    loadTreatments,
    emptyTraitmentList,
    treatmentsData,
}) => {
    const { selectedPatient } = useContext(PatientContext);
    const patientId: number = selectedPatient?.id ?? 0;
    const { fetchingPdfFile, downloadProgramPdf } = usePrograms({});

    const [loadLevel, setLoadLevel] = useState(false);
    const [levelActivity, setLevelActivity] = useState<LevelActivity | null>();

    const levelPhysicalActivity = () => {
        setLoadLevel(true);
        if (patientId) {
            getPhysicalActivity(patientId)
                .then((res) => {
                    const niveau = res?.data?.data.items;
                    if (niveau) {
                        setLevelActivity(niveau);
                        setLoadLevel(false);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    setLoadLevel(false);
                });
        }
    };

    useEffect(() => {
        if (selectedPatient?.id) {
            levelPhysicalActivity();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPatient]);

    const emptyLevelActivity = levelActivity?.length === 0;

    let finishProgramList = [];
    if (Array.isArray(programList))
        finishProgramList = programList.filter(
            (item: any) => item?.stateProgramUserTemp === StateProgramUserTemp.TERMINE.code,
        );

    return (
        <Grid item>
            <Grid container direction={'row'} justifyContent={'space-between'}>
                <Grid item flex={1 / 4}>
                    <CustomText fontSize={baseFontSize.md} fontFamily={baseFontFamily.BreeSerif}>
                        Derniers programmes
                    </CustomText>

                    {fetchingPdfFile || loadProgram ? (
                        <LoadingSkeleton />
                    ) : (
                        <Grid marginTop={1} container direction={'column'}>
                            {emptyProgramList || finishProgramList.length === 0
                                ? emptyContent('Aucun programme trouvé.')
                                : finishProgramList?.map((item: any, index: number) => {
                                      const code = item?.stateProgramUserTemp;
                                      return (
                                          <InfoItem
                                              isFile
                                              key={index}
                                              valueColor={baseColors.green.primary}
                                              label={item.program.title}
                                              value={StateProgramUserTemp?.[code]?.title}
                                              icon={<PdfIcon />}
                                              handlePress={() => downloadProgramPdf(patientId, item?.program?.id)}
                                          />
                                      );
                                  })}
                        </Grid>
                    )}
                </Grid>

                <Grid item flex={1 / 4}>
                    <CustomText fontSize={baseFontSize.md} fontFamily={baseFontFamily.BreeSerif}>
                        Antécédents
                    </CustomText>

                    {loadAntecedants ? (
                        <LoadingSkeleton />
                    ) : (
                        <Grid marginTop={1} container direction={'column'}>
                            {emptyAntecedantList
                                ? emptyContent('Aucun antécédent trouvé.')
                                : Array.isArray(antecedantList) &&
                                  antecedantList?.map((item: any, index: number) => (
                                      <CustomText key={index} marginBottom={2}>
                                          {item?.name}
                                      </CustomText>
                                  ))}
                        </Grid>
                    )}
                </Grid>

                <Grid item flex={1 / 4}>
                    <CustomText fontSize={baseFontSize.md} fontFamily={baseFontFamily.BreeSerif}>
                        Traitements en cours
                    </CustomText>

                    {loadTreatments ? (
                        <LoadingSkeleton />
                    ) : (
                        <Grid container direction={'column'}>
                            {emptyTraitmentList
                                ? emptyContent('Aucun traitement trouvé.')
                                : Array.isArray(treatmentsData) &&
                                  treatmentsData?.map((item: any) => (
                                      <CustomText key={item.id} marginBottom={2}>
                                          {item.name}
                                      </CustomText>
                                  ))}
                        </Grid>
                    )}
                </Grid>

                <Grid item flex={1 / 4}>
                    <CustomText fontSize={baseFontSize.md} fontFamily={baseFontFamily.BreeSerif}>
                        Niveau d'activité physique
                    </CustomText>

                    {loadLevel ? (
                        <LoadingSkeleton />
                    ) : (
                        <Grid container direction={'column'}>
                            {emptyLevelActivity ? (
                                emptyContent('Aucun niveau détecté.')
                            ) : (
                                <Box sx={{ width: '20%' }}>
                                    {Array.isArray(levelActivity) &&
                                        levelActivity?.map((item: any, index: number) => (
                                            <CustomBadge key={index} title={item?.name}></CustomBadge>
                                        ))}
                                </Box>
                            )}
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default PatientProgram;
