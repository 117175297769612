import { FC } from 'react';
import AppBarHeader from 'src/components/AppBarHeader';
import { loadState } from 'src/reducer/Auth/AuthReducer';
import useStyles from './styles';
import Box from '@mui/material/Box';
import { Redirect } from 'react-router-dom';
import { ROUTES } from 'src/routes/routes';
import CustomizedSnackbars from '../CustomizedSnackbars';
import { baseColors } from 'src/utils/constants/baseStyles';
import useCheckUserRole from 'src/hooks/useCheckUserRole';
import MenuSideBarCpts from './MenuSideBar/MenuSideBarCpts';

type IProps = {
    children: any;
};

export const WrapperComponent: FC<IProps> = (props) => {
    const { children } = props;
    const classes = useStyles();
    const { userRole } = useCheckUserRole();

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100vh"
            className={classes.container}
        >
            <Box height={70} sx={{ backgroundColor: baseColors.green.light }}>
                <AppBarHeader />
            </Box>

            <Box
                justifyContent="space-between"
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    paddingBottom: '7px',
                    marginTop: '75px',
                }}
            >
                {userRole && (
                    <Box
                        sx={{
                            backgroundColor: baseColors.white,
                            marginRight: 1,
                            borderTopRightRadius: 7,
                            borderBottomRightRadius: 7,
                            xl: 2,
                            md: 'none',
                            sm: 'none',
                            xs: 'none',
                        }}
                    >
                        <MenuSideBarCpts />
                    </Box>
                )}

                <Box style={{ width: '100%' }}>{children}</Box>
            </Box>

            <CustomizedSnackbars />
        </Box>
    );
};

const Wrapper = (WrappedComponent: any) => () => {
    const isLogged = loadState()?.isLogged;

    return isLogged ? (
        <WrapperComponent>
            <WrappedComponent />
        </WrapperComponent>
    ) : (
        <Redirect to={ROUTES.login} />
    );
};

export default Wrapper;
