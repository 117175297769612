import { FC } from 'react';
import logo from '../../assets/icons/Mini_logo.png';
import horizontalLogo from '../../assets/icons/Ivehte_Horizontal_Logo.png';
import authLogo from '../../assets/icons/Ivehte_Logo.png';

type IvehteCptsLogoProps = {
    height?: number;
    width?: number;
    useAuthenticationLogo?: boolean;
    isMiniHorizontal?: boolean;
    styles?: any;
};

const IvehteCptsLogo: FC<IvehteCptsLogoProps> = ({
    height,
    width,
    useAuthenticationLogo,
    styles,
    isMiniHorizontal,
}) => {
    if (isMiniHorizontal) {
        return <img alt="icon" style={{ ...styles, width: width ?? 109, height: height ?? 46 }} src={horizontalLogo} />;
    }

    return (
        <>
            <img
                alt="icon"
                style={{ ...styles, width: width ?? 298, height: height ?? 271 }}
                src={useAuthenticationLogo ? authLogo : logo}
            />
        </>
    );
};

export default IvehteCptsLogo;
