import { FC } from 'react';
import { Spacer } from 'src/components/Spacer/Spacer';
import { CustomText } from 'src/components/Text/CustomText';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import CheckIcon from '@mui/icons-material/Check';
import { ColorElement } from 'src/interfaces/types';

type UserThemeTextProps = {
    items: ColorElement[];
};

const UserThemeText: FC<UserThemeTextProps> = (props) => {
    const { items } = props;

    return (
        <div>
            <CustomText
                style={{ textAlign: 'center' }}
                fontSize={16}
                fontFamily={baseFontFamily.OpenSansSemiBold}
                color={baseColors.black}
            >
                Text
            </CustomText>

            <div>
                {items?.map((item: ColorElement, i) => (
                    <div
                        key={i}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <CustomText fontSize={14} color={item.color}>
                            {item.label}
                        </CustomText>

                        <Spacer />

                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                cursor: 'pointer',
                                borderColor: baseColors.green.pastel,
                            }}
                        >
                            <p
                                style={{
                                    backgroundColor: item.color,
                                    height: 20,
                                    width: 20,
                                    borderRadius: 10,
                                    marginLeft: 10,
                                }}
                            />
                            <CheckIcon
                                style={{
                                    color: 'white',
                                    position: 'relative',
                                    marginLeft: -18,
                                    marginTop: 0,
                                    fontSize: 16,
                                }}
                            />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default UserThemeText;
