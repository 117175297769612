import { PhotoCamera } from '@mui/icons-material';
import { Avatar, Grid, IconButton } from '@mui/material';
import { createRef, FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CustomText } from 'src/components/Text/CustomText';
import { baseColors } from 'src/utils/constants/baseStyles';
import { ROUTES } from 'src/routes/routes';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';

interface HdjFormHeaderProps {
    hdjIcon?: any;
    setHdj: any;
}

const HdjFormHeader: FC<HdjFormHeaderProps> = (props) => {
    const { hdjIcon, setHdj } = props;
    const history = useHistory();
    const [icon, setIcon] = useState<string>('');

    const hanleClickHdjList = () => history.push(ROUTES.hdj);
    const iconRef = createRef<HTMLInputElement>();

    const _handleReaderLoaded = (readerEvt: any) => {
        let binaryString = readerEvt.target.result;
        const newIcon = `data:image/jpeg;base64,${btoa(binaryString)}`
        setHdj((old: any) => ({ ...old, icon: newIcon }));
        setIcon(newIcon)
    };

    const handleIconChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let files = event.currentTarget.files;
        if (files && files?.length > 0) {
            let file = files[0];

            let allowedExtension = ['image/png', 'image/jpeg', 'image/jpg'];
            if (!allowedExtension.includes(file.type)) {
                alert('images non supporté (veuiller importe des .jpeg, .jpg, .png');
            } else {
                const reader = new FileReader();
                reader.onload = _handleReaderLoaded;
                reader.readAsBinaryString(file);
            }
        }
    };

    useEffect(()=>{
        setIcon(hdjIcon)
    },[])
    

    return (
        <Grid item>
            <Grid container direction="row" alignItems="center" justifyContent={'space-between'}>
                <Grid item>
                    <IconButton
                        onClick={() => iconRef.current?.click()}
                        style={{
                            marginLeft: '22%',
                            background: baseColors.grey,
                            padding: icon === '' ? 20 : 0,
                            marginBottom: 10,
                        }}
                    >
                        {icon !== '' ? (
                            <Avatar src={`${icon}`} sx={{ width: 100, height: 100 }} />
                        ) : (
                            <PhotoCamera sx={{ width: 50, height: 50 }} />
                        )}

                        <input ref={iconRef} id="icon_input" type="file" hidden onChange={handleIconChange} />
                    </IconButton>

                    <CustomText fontSize={12}>
                        {icon === '' ? 'Cliquer pour ajouter une icône.' : "Cliquer pour modifier l'icône."}
                    </CustomText>
                </Grid>
                <Grid>
                    <CustomLoadingButton loading={false} handleClick={hanleClickHdjList} label={'Liste des HDJ'} />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default HdjFormHeader;
