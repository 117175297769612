function addAllIntervenantBySpeciality({
    intervenantIds,
    setProfessionalIds,
}: {
    intervenantIds: any;
    setProfessionalIds: any;
}) {
    setProfessionalIds((prevIds: any) => {
        return [...prevIds, ...intervenantIds];
    });
}

function removeIntervenantBySpeciality({
    intervenantIds,
    setProfessionalIds,
}: {
    intervenantIds: any;
    setProfessionalIds: any;
}) {
    setProfessionalIds((prevIds: any) => {
        const filtered = prevIds?.filter((existingId: number) => !intervenantIds?.includes(existingId));
        return filtered;
    });
}

const handleSelectAllIntervenantBySpeciality =
    ({
        specialityId,
        setProfessionalIds,
        intervenants,
        setCheckedSpecialityIds,
    }: {
        specialityId: number;
        setProfessionalIds: any;
        intervenants: any;
        setCheckedSpecialityIds: any;
    }) =>
    () => {
        const ids = Array.from(intervenants ?? [], (intervenant: { id: number }) => intervenant.id) ?? [];

        setCheckedSpecialityIds((prevSpecialityIds: any[]) => {
            if (prevSpecialityIds?.length) {
                if (prevSpecialityIds.includes(specialityId)) {
                    const filtered = prevSpecialityIds?.filter((id: number) => id !== specialityId);
                    removeIntervenantBySpeciality({ setProfessionalIds, intervenantIds: ids });
                    return [...filtered];
                } else {
                    addAllIntervenantBySpeciality({ setProfessionalIds, intervenantIds: ids });
                    return [...prevSpecialityIds, specialityId];
                }
            } else {
                addAllIntervenantBySpeciality({ setProfessionalIds, intervenantIds: ids });
                return [specialityId];
            }
        });
    };

const handleCheckIntervenantByItem =
    ({
        setProfessionalIds,
        professionalId,
        setCheckedSpecialityIds,
        specialityId,
    }: {
        setCheckedSpecialityIds?: any;
        setProfessionalIds: any;
        professionalId: number;
        specialityId?: number;
    }) =>
    () => {
        setProfessionalIds((prev: number[]) => {
            if (prev.includes(professionalId)) {
                return prev.filter((id: number) => id !== professionalId);
            } else {
                return [...prev, professionalId];
            }
        });

        setCheckedSpecialityIds?.((prevSpcIds: number[]) => {
            if (prevSpcIds?.length) {
                if (prevSpcIds.includes(specialityId ?? 0)) {
                    return prevSpcIds;
                } else {
                    return [...prevSpcIds, specialityId];
                }
            } else {
                return [specialityId];
            }
        });
    };

export { handleCheckIntervenantByItem, handleSelectAllIntervenantBySpeciality };
