import { makeStyles } from '@mui/styles';
import { baseColors } from '../../../../utils/constants/baseStyles';

const useStyles = makeStyles({
});

export const sxStyles : any = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '30px 0 50px 0',
    },
    left: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    btnContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: 250,
    },
    btnOrange: {
        color: baseColors.white,
        backgroundColor: baseColors.orange.primary,
        borderColor: 'transparent', 
    },
    btnGreen: {
        color: baseColors.white,
        backgroundColor: baseColors.green.primary,
        borderColor: 'transparent',
    }
};

export default useStyles;
