import jwtDecode from 'jwt-decode';
export const USER_KEY = 'user';

const STORAGE_KEY = 'authData';

const userFromStorage = sessionStorage.getItem(USER_KEY) ?? '';
const parsedUser = userFromStorage !== '' && JSON.parse(userFromStorage);
const authDataFromStorage = sessionStorage.getItem(STORAGE_KEY) ?? '';
const parsedAuthData = userFromStorage !== '' && JSON.parse(authDataFromStorage);

const getLocalRefreshToken = () => {
    const user = parsedUser;
    return user?.refreshToken;
};

const updateLocalRefreshToken = (refreshToken: string) => {
    let user = parsedUser;
    user.refreshToken = refreshToken;
    sessionStorage.setItem(USER_KEY, JSON.stringify(user));

    let authData = parsedAuthData;
    authData.refreshToken = refreshToken;
    sessionStorage.setItem(STORAGE_KEY, JSON.stringify(authData));
};

const getLocalAccessToken = () => {
    const user = parsedUser;
    return user?.token;
};

const updateLocalAccessToken = (token: string) => {
    let user = parsedUser;
    user.token = token;
    sessionStorage.setItem(USER_KEY, JSON.stringify(user));

    let authData = parsedAuthData;
    authData.token = token;
    sessionStorage.setItem(STORAGE_KEY, JSON.stringify(authData));
};

const getUser = () => {
    return parsedUser;
};

const setUser = (user: any) => {
    sessionStorage.setItem(USER_KEY, JSON.stringify(user));
};

const removeUser = (url?: string) => {
    const currentUrl = window.location.origin;
    sessionStorage.removeItem(STORAGE_KEY);
    sessionStorage.removeItem(USER_KEY);
    sessionStorage.removeItem(STORAGE_KEY);
    window.location.href = url ? url : `${currentUrl}/login`;
};

const checkTokenExpirationMiddleware = (tokenValue: any) => {
    const jwtToken: any = jwtDecode(tokenValue ?? '');
    const expirationTime = jwtToken?.exp * 1000;
    let currentDate = new Date();

    if (expirationTime < currentDate.getTime()) {
        sessionStorage.clear();
        sessionStorage.clear();
    }
};

const TokenService = {
    checkTokenExpirationMiddleware,
    getLocalRefreshToken,
    getLocalAccessToken,
    updateLocalAccessToken,
    updateLocalRefreshToken,
    getUser,
    setUser,
    removeUser,
};

export default TokenService;
