import { useEffect, useState } from 'react';
import { getRooms } from 'src/services/Rooms';

const useLocations = () => {
    const [fetchingLocations, setFetchingLocations] = useState(true);
    const [locations, setLocations] = useState<any[]>([]);

    const handleFecthLocations = () => {
        setFetchingLocations(true);
        getRooms().then((response) => {
            const responses = response?.data?.data?.items;
            const data = responses?.map((room: any, i: number) => ({
                id: room.id,
                name: room.name,
            }));

            setLocations([{ id: 0, name: 'Sélectionner une salle' }, ...data]);
            setLocations(responses);
            setFetchingLocations(false);
        });
        // getCustomLocationItems().then((response) => {
        //     const data = response?.data?.data?.flat(1)?.map((room: any, i: number) => ({
        //         id: room.id,
        //         name: room.name,
        //     }));
        //     setLocations([{ id: 0, name: 'Sélectionner une salle' }, ...data]);
        //     setFetchingLocations(false);
        // });
    };

    function handleOptimizeLocationData(locationData: any[]) {
        const data = locationData?.flat(1)?.map((room: any, i: number) => ({
            id: room.id,
            name: room.name,
        }));
        return [{ id: 0, name: 'Sélectionner une salle' }, ...data];
    }

    function customLocation(locationName: string) {
        const location = locations?.find((item: any) => item?.name?.toLowerCase() === locationName?.toLowerCase());
        return location;
    }

    // function handleOptimizeLocationData(locationData: any[]) {
    //     const data = locationData?.flat(1)?.map((room: any, i: number) => ({
    //         id: room.id,
    //         name: room.name,
    //     }));
    //     return [{ id: 0, name: 'Sélectionner un lieu' }, ...data];
    // }

    useEffect(() => {
        handleFecthLocations();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        locations,
        fetchingLocations,
        customLocation,
        handleFecthLocations,
        handleOptimizeLocationData,
    };
};

export default useLocations;
