import * as React from 'react';
import { SVGProps } from 'react';
import { baseColors } from 'src/utils/constants/baseStyles';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} fill="none" {...props}>
        <rect width={40} height={40} fill="#fff" rx={10} />
        <path
            fill={baseColors.green.primary}
            d="M18.662 25.456c.239-.186.455-.402.888-.835l5.547-5.547a7.717 7.717 0 0 1-2.495-1.676 7.72 7.72 0 0 1-1.676-2.495L15.38 20.45c-.433.433-.65.65-.835.888-.22.281-.408.586-.562.908-.13.274-.227.564-.42 1.145l-1.021 3.062a.796.796 0 0 0 1.006 1.006l3.062-1.02c.581-.194.871-.291 1.145-.421.322-.154.627-.342.908-.562ZM26.636 17.535a2.95 2.95 0 0 0-4.17-4.171l-.666.665.028.084a6.565 6.565 0 0 0 1.57 2.49A6.566 6.566 0 0 0 25.97 18.2l.665-.665Z"
        />
    </svg>
);
export { SvgComponent as EditProfilIcon };
