import { Box, Grid } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { CustomButton } from 'src/components/Button/CustomButton';
import { CustomText } from 'src/components/Text/CustomText';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import { ROUTES } from 'src/routes/routes';
import { InputFieldPassword } from 'src/components/InputField/InputFieldPassword';
import { resetPassword } from 'src/services/Login';
import { GppBad, GppGood } from '@mui/icons-material';
import IvehteCptsLogo from 'src/components/IvehteCptsLogo/IvehteCptsLogo';
import { isSamePassword, isValidPassword } from 'src/utils/helpers/InputValidator';

const resetPasswordValue = {
    password: '',
    retypePassword: '',
    token: '',
};

export const PasswordReset: FC = (props) => {
    const search = useLocation().search;
    const [password, setPassword] = useState(resetPasswordValue);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    useEffect(() => {
        const token = new URLSearchParams(search).get('token');
        if (token) setPassword((old) => ({ ...old, token: token }));
    }, [search]);

    const performReset = () => {
        setLoading(true);

        resetPassword({ ...password }).then((response) => {
            setLoading(false);
            setSuccess(false);
            setError(false);

            if (response.data.code === 200) {
                setPassword(resetPasswordValue);
                setSuccess(true);
            } else {
                setError(true);
            }
        });
    };
    const disabled = !isSamePassword(password.password,password.retypePassword) || !isValidPassword(password.password)
    return (
        <Grid>
            <Link to={ROUTES.login}>
                <Box style={{ padding: 2 }}>
                    <IvehteCptsLogo width={105} height={80} />
                </Box>
            </Link>
            <Grid
                container
                justifyContent={'center'}
                justifyItems={'center'}
                alignItems={'center'}
                alignContent={'center'}
            >
                <Grid
                    flexGrow={1}
                    item
                    sm={8}
                    xs={8}
                    md={4}
                    lg={4}
                    style={{
                        borderRadius: 10,
                        padding: 20,
                        background: baseColors.green.light,
                        marginTop: '3%',
                    }}
                >
                    {success ? (
                        <Grid>
                            <Grid container justifyContent={'center'}>
                                <GppGood
                                    style={{ alignSelf: 'center', marginBottom: 10 }}
                                    htmlColor={baseColors.green.primary}
                                    fontSize={'large'}
                                ></GppGood>
                            </Grid>
                            <CustomText
                                fontFamily={baseFontFamily.BreeSerif}
                                style={{ textAlign: 'center', marginBottom: 20 }}
                            >
                                Votre mot de passe a été réinitialisé avec succès
                            </CustomText>
                            <Grid container justifyContent={'center'}>
                                <Link to={ROUTES.login} style={{ textDecoration: 'none' }}>
                                    <CustomButton
                                        size={'large'}
                                        lower
                                        width={'100%'}
                                        onClick={() => {}}
                                        label={'Se connecter'}
                                    ></CustomButton>
                                </Link>
                            </Grid>
                        </Grid>
                    ) : error ? (
                        <>
                            <Grid container justifyContent={'center'}>
                                <GppBad
                                    style={{ alignSelf: 'center', marginBottom: 10 }}
                                    htmlColor={baseColors.orange.primary}
                                    fontSize={'large'}
                                ></GppBad>
                            </Grid>
                            <Grid container direction="row" justifyContent={'center'}>
                                <CustomText
                                    color={baseColors.orange.flash}
                                    fontFamily={baseFontFamily.BreeSerif}
                                    style={{ textAlign: 'center', marginBottom: 10 }}
                                >
                                    Il semble que ce lien que vous avez utilisé n'est plus valide.
                                </CustomText>
                                <Link
                                    style={{
                                        fontFamily: baseFontFamily.BreeSerif,
                                        marginLeft: 10,
                                        color: baseColors.green.primary,
                                    }}
                                    to={ROUTES.passwordForgot}
                                >
                                    cliquer ici pour renvoyer un lien vers votre e-mail
                                </Link>
                            </Grid>
                        </>
                    ) : (
                        <>
                            <CustomText
                                style={{ textAlign: 'center', marginBottom: 10 }}
                                fontFamily={baseFontFamily.OpenSansBold}
                                fontSize={16}
                                color={baseColors.green.primary}
                            >
                                Entrer votre nouveau mot de passe
                            </CustomText>
                            <Grid container direction={'row'}>
                                <Grid
                                    item
                                    flexGrow={1}
                                    justifyContent={'center'}
                                    style={{ paddingLeft: '3%', paddingTop: '5%' }}
                                >
                                    <InputFieldPassword
                                        width={'97%'}
                                        placeholder="Entrer votre nouveau mot de passe"
                                        disabled={loading}
                                        label="Mot de passe : "
                                        value={password.password}
                                        regexLabel={
                                            password.password.trim().length > 0 &&
                                            !isValidPassword(password.password)
                                                ? '(Le mot de passe doit comporter au moins 8 caractères, une minuscule, une majuscule, un chiffre et un  caractère spécial.)'
                                                : '(obligatoire)'
                                        }
                                        onChange={(value: any) => {
                                            setPassword((old) => ({ ...old, password: value }));
                                        }}
                                    />
                                    <InputFieldPassword
                                        width={'97%'}
                                        disabled={loading}
                                        placeholder="Confirmer votre mot de passe"
                                        label="Confirmer votre mot de passe :"
                                        value={password.retypePassword}
                                        onChange={(value: any) => {
                                            setPassword((old) => ({ ...old, retypePassword: value }));
                                        }}
                                    />
                                    {password.retypePassword.length > 0 &&
                                        password.password !== password.retypePassword && (
                                            <CustomText
                                                style={{ textAlign: 'center', marginBottom: 10 }}
                                                color={baseColors.orange.primary}
                                            >
                                                Les mots de passe ne sont pas identiques
                                            </CustomText>
                                        )}
                                </Grid>
                            </Grid>
                            <Grid container justifyContent={'center'} style={{ marginTop: 10, marginBottom: 20 }}>
                                <CustomButton
                                    width={'100%'}
                                    lower
                                    disabled={disabled}
                                    loading={loading}
                                    onClick={() => performReset()}
                                    label="Réinitialiser le mot de passe"
                                />
                            </Grid>
                        </>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};
