import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    container: {},
    content: {
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'left',
        flexDirection: 'row',
        marginTop: 1,
  
    },
    left: {
        flex: 0.5,
    },
    right: {
        alignItems: 'center',
    },
    email: {
        marginLeft: 'auto',
    },
});

export default useStyles;
