import { makeStyles } from '@mui/styles';
import { baseColors, baseFontFamily, baseScreenHeight } from 'src/utils/constants/baseStyles';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        marginRight: 2,
        ...baseScreenHeight.defaultHeight,
    },
    header: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: 5,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingTop: 10,
        backgroundColor: baseColors.white,
        paddingLeft: '20px',
        paddingRight: '20px',
    },
    title: {
        color: baseColors.black,
        fontFamily: baseFontFamily.BreeSerif,
        fontSize: '16px',
        marginBottom: 10,
    },
    subtitle: {
        color: baseColors.green.primary,
        fontSize: '12px',
    },
});

export default useStyles;
