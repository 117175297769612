import { FC, useContext, useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import { Box } from '@mui/system';
import UserService from 'src/services/User/user.service';
import { fullName } from 'src/utils/utils';
import { baseColors, baseFontFamily, baseFontSize } from 'src/utils/constants/baseStyles';
import CustomCircleLoader from 'src/components/CustomLoader/CustomCircleLoader';
import IntervenantList from '../PlanningFilters/IntervenantList';
import { EventPlanningViewType } from 'src/interfaces/interfaces';
import { CustomText } from 'src/components/Text/CustomText';
import EventService from 'src/services/Calendar/events.service';
import { UserContext } from 'src/providers/UserProvider';
import { CustomSelect } from 'src/components/InputField/CustomSelect';

interface UsersSelectListProps {
    setCaregiverId?: any;
    label?: string;
    width?: number;
    setEventPayload?: any;
    setChoosedPatient?: any;
    defaultList?: any;
    precheckedPatient?: number;
    isAgenda?: boolean;
    viewType?: EventPlanningViewType['viewType'];
    setSelectedIntervenants?: any;
    selectedIntervenants?: any;
    isIntervenant?: boolean;
    isSharedAgenda?: boolean;
    setObservation?: Function;
    marginLeft?: string;
    idsuffixselect?: string;
    isCordinator?: boolean;
    isForEvent?: boolean;
    isAgendaFilter?: boolean;
    disabled?: boolean;
    loadingPatients?: boolean;
    isPatientItems?: boolean;
}

const UsersSelectList: FC<UsersSelectListProps> = (props) => {
    const {
        setCaregiverId,
        width,
        setEventPayload,
        setChoosedPatient,
        defaultList,
        precheckedPatient,
        isAgenda,
        viewType,
        setSelectedIntervenants,
        selectedIntervenants,
        isIntervenant,
        setObservation,
        marginLeft,
        isSharedAgenda,
        isCordinator,
        isForEvent,
        isAgendaFilter,
        disabled,
        loadingPatients,
        isPatientItems,
    } = props;
    const [loading, setLoading] = useState<boolean>(false);
    const [caregivers, setCaregivers] = useState<Array<any>>([]);
    const [canViewOthersAgenda, setCanViewOthersAgenda] = useState<boolean>(false);
    const [userSharedAgendaList, setUserSharedAgendaList] = useState<any>(null);
    const { userInfos } = useContext(UserContext);

    const handleFetchCaregivers = (query: string) => {
        setLoading(true);
        UserService.getDoctorUsers(query)
            .then((res: any) => {
                setCaregivers(res?.data?.data);
                setLoading(false);
            })
            .catch(() => setLoading(false));
    };

    const handleFetchUserSharedAgenda = () => {
        setLoading(true);
        if (userInfos?.id > 0) {
            EventService.getSharedAgendaReceivers(userInfos.id)
                .then((response) => {
                    const res = response?.data?.data;
                    const hasData = Boolean(res.length);
                    setUserSharedAgendaList(hasData ? res : null);
                    setCanViewOthersAgenda?.(hasData);
                    setLoading(false);
                })
                .catch(() => setLoading(false));
        }
    };

    const handleFetchData = () => handleFetchCaregivers('');

    useEffect(() => {
        handleFetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isCordinator) {
            setCanViewOthersAgenda?.(true);
        }
    }, [isCordinator]);

    useEffect(() => {
        if (isAgenda) handleFetchUserSharedAgenda();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInfos?.id, isAgenda]);

    const optimizedCaregiversData = Array.from(defaultList ?? caregivers, (item: any) => ({
        id: item?.id,
        name: fullName(item?.lastName, item?.firstName),
    }));

    const agendaUsers = Array.from(userSharedAgendaList ?? [], (item: any) => ({
        id: item?.id,
        name: fullName(item?.lastName, item?.firstName),
    }));

    const caregiversData = isSharedAgenda
        ? isCordinator
            ? optimizedCaregiversData
            : agendaUsers
        : optimizedCaregiversData;

    const defaultName = isIntervenant ? 'Sélectionner un intervenant' : 'Sélectionner un patient';
    const caregiversDatas = [{ id: -1, name: defaultName }, ...caregiversData];

    const handlechange = (value: any) => {
        if (value?.id > 0) {
            setCaregiverId?.(value);
        }
        setEventPayload?.((prev: any) => ({ ...prev, attendees: [{ id: Number(value), type: 1 }] }));
        setChoosedPatient?.({ id: Number(value), type: isIntervenant ? 2 : 1 });
        setObservation?.((prev: any) => ({ ...prev, attendeeId: value }));
    };

    useEffect(() => {
        if (precheckedPatient && precheckedPatient > 0) {
            setCaregiverId?.(precheckedPatient);
            setChoosedPatient?.({ id: Number(precheckedPatient), type: isIntervenant ? 2 : 1 });
            setEventPayload?.((prev: any) => ({ ...prev, attendees: [{ id: Number(precheckedPatient), type: 1 }] }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [precheckedPatient]);

    const [openIntervenantModal, setOpenIntervenantModal] = useState<boolean>(false);
    const toggleIntervenantModal = () => setOpenIntervenantModal(!openIntervenantModal);
    const isIntervenantWeekly = isAgenda && viewType !== 'weekly';

    const isLoading = isPatientItems ? loadingPatients : loading;

    const intervenantLabelInput = () => (
        <Box
            sx={{ padding: 1, borderRadius: 2, cursor: 'pointer', border: `1px solid ${baseColors.green.primary}` }}
            onClick={toggleIntervenantModal}
            id="view-agenda"
        >
            <CustomText
                style={{
                    fontSize: baseFontSize.sm,
                    color: baseColors.green.primary,
                    textAlign: 'center',
                    fontFamily: baseFontFamily.OpenSansSemiBold,
                }}
            >
                Voir l'agenda de
            </CustomText>
        </Box>
    );

    if (isSharedAgenda && !canViewOthersAgenda && !isCordinator) {
        return null;
    }

    return (
        <Stack
            spacing={2}
            sx={{ width: width ? width : isIntervenantWeekly ? 150 : 250, marginLeft: marginLeft ?? undefined }}
        >
            <IntervenantList
                toggleModal={toggleIntervenantModal}
                modalOpen={openIntervenantModal}
                intervenants={caregiversData}
                selectedIntervenants={selectedIntervenants}
                setSelectedIntervenants={setSelectedIntervenants}
                handleFetchCaregivers={handleFetchCaregivers}
                loading={loading}
            />

            {isLoading ? (
                <CustomCircleLoader height={25} width={25} />
            ) : caregiversData?.length > 0 && isAgendaFilter ? (
                intervenantLabelInput()
            ) : (
                <CustomSelect
                    disabled={disabled}
                    onChange={(e: any) => handlechange(e)}
                    label=""
                    width={isForEvent ? '135%' : 250}
                    labelKey="name"
                    items={caregiversDatas}
                    defaultValue={precheckedPatient ?? -1}
                    idSelect="selection-intervenant"
                />
            )}
        </Stack>
    );
};

export default UsersSelectList;
