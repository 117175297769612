import { Grid } from '@mui/material';
import { FC, useState, useEffect } from 'react';
import { CustomText } from 'src/components/Text/CustomText';
import { CustomRadio } from 'src/components/InputField/CustomRadio';
import { consultationType } from 'src/utils/constants/fakeData';
import { Consultation } from 'src/interfaces/interfaces';
import { Spacer } from 'src/components/Spacer/Spacer';
interface ConsultationTypeFormProps {
    setConsultation: any;
    loadingData?: any;
    consultationData?: any;
    consultData?: Consultation;
}

const ConsultationTypeForm: FC<ConsultationTypeFormProps> = (props) => {
    const [consultationTypeId, setConsultationTypeId] = useState<Number>(1);
    const { setConsultation, consultData } = props;

    useEffect(() => {
        if (consultData?.consultationTypeId) setConsultationTypeId(consultData?.consultationTypeId);
    }, []);

    return (
        <>
            <Grid container direction="row" alignItems={'center'} justifyContent="space-between">
                <Grid item>
                    <CustomText>Type de consulation : </CustomText>
                </Grid>
                <Grid item style={{ width: '60%', display: 'flex', flexDirection: 'row' }}>
                    {consultationType.map((type, i) => (
                        <Grid item>
                            <CustomRadio
                                checked={consultationTypeId === type.id}
                                onClick={(value: string) => {
                                    setConsultation((old: any) => ({
                                        ...old,
                                        consultationTypeId: type.id,
                                        roomId: 0,
                                    }));
                                    setConsultationTypeId(type.id);
                                }}
                                label={type.name}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Grid>

            <Spacer />
        </>
    );
};

export default ConsultationTypeForm;
