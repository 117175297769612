import React, { FC, createRef, useEffect } from 'react';
import { Grid, Avatar, IconButton } from '@mui/material';
import { PhotoCamera } from '@mui/icons-material';
import { CustomText } from '../../../../components/Text/CustomText';
import { InputField } from '../../../../components/InputField/InputField';
import { isNotBlank } from '../../../../utils/helpers/InputValidator';
import { baseColors } from '../../../../utils/constants/baseStyles';
import { handleChange, handleFetchUserProfilePicture } from '../UserUtiles';

interface UserAvatarAndNameProps {
    selectedUserData?: any;
    payload: any;
    setPayload: any;
    lockUserInput?: boolean;
    profIcon: any;
    setProfIcon: any;
}

export const UserAvatarAndName: FC<UserAvatarAndNameProps> = (props) => {
    const { selectedUserData, payload, setPayload, lockUserInput, profIcon, setProfIcon } = props;
    const iconRef = createRef<HTMLInputElement>();
    const userId = selectedUserData?.id;

    useEffect(() => {
        if (userId > 0) {
            handleFetchUserProfilePicture({ userId, setProfIcon });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId]);

    const handleIconChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let files = event.currentTarget.files;
        if (files && files?.length > 0) {
            let file = files[0];

            let allowedExtension = ['image/png', 'image/jpeg', 'image/jpg'];
            if (!allowedExtension.includes(file.type)) {
                alert('images non supporté (veuiller importe des .jpeg, .jpg, .png');
            } else {
                const reader = new FileReader();
                reader.onload = _handleReaderLoaded;
                reader.readAsBinaryString(file);
            }
        }
    };

    const _handleReaderLoaded = (readerEvt: any) => {
        let binaryString = readerEvt.target.result;
        let filecontent = `data:image/jpeg;base64,${btoa(binaryString)}`;
        setProfIcon(`data:image/jpeg;base64,${btoa(binaryString)}`);
        setPayload((old: any) => ({ ...old, filecontent }));
    };

    return (
        <>
            <Grid item>
                <IconButton
                    onClick={() => iconRef.current?.click()}
                    style={{
                        background: baseColors.grey,
                        padding: profIcon === '' ? 20 : 0,
                        marginLeft: '38%',
                        marginTop: '30px',
                        marginBottom: '30px',
                    }}
                >
                    {profIcon?.length !== 0 ? (
                        <Avatar src={`${profIcon}`} sx={{ width: 150, height: 150 }} />
                    ) : (
                        <PhotoCamera sx={{ width: 150, height: 150 }} />
                    )}
                    <input ref={iconRef} id="icon_input" type="file" hidden onChange={handleIconChange} />
                </IconButton>
            </Grid>

            <Grid lg={12} container direction="row" alignItems={'center'}>
                <Grid item>
                    <CustomText style={{ minWidth: '200px' }}>Nom : </CustomText>
                </Grid>
                <InputField
                    label={''}
                    disabled={lockUserInput}
                    value={payload?.firstName ?? ''}
                    onChange={handleChange({ setPayload, inputKey: 'firstName' })}
                    validator={isNotBlank}
                    errorMessage="Nom obligatoire"
                    placeholder={'Entrer le nom'}
                    width={360}
                    idSuffix="nom-utilisateur"
                    required
                />
            </Grid>

            <Grid lg={12} container direction="row" alignItems={'center'}>
                <Grid item>
                    <CustomText style={{ minWidth: '200px' }}>Prénom(s) :</CustomText>
                </Grid>
                <InputField
                    label={''}
                    disabled={lockUserInput}
                    value={payload?.lastName ?? ''}
                    onChange={handleChange({ setPayload, inputKey: 'lastName' })}
                    validator={isNotBlank}
                    errorMessage="Prénom(s) obligatoire(s)"
                    placeholder={'Entrer le(s) prénom(s)'}
                    width={360}
                    idSuffix="prenom-utilisateur"
                    required
                />
            </Grid>
        </>
    );
};
