import { Dispatch, FC, SetStateAction } from 'react';
import { useEffect, useState } from 'react';
import CustomModal from 'src/components/CustomModal';
import { InputField } from 'src/components/InputField/InputField';
import ListItemFormCheckBox from 'src/components/ListItemFormCheckBox';
import { isNotBlank } from 'src/utils/helpers/InputValidator';
import { searchElementByName } from 'src/utils/utils';
import { GoalItem, PostSkillPayload, ProgramSkill, SkillItem } from 'src/interfaces/interfaces';
import { sxStyles } from './styles';
import { CustomText } from 'src/components/Text/CustomText';
import { baseColors, baseFontFamily, baseFontSize } from 'src/utils/constants/baseStyles';
import { Spacer } from 'src/components/Spacer/Spacer';
import { SkillFormFooter } from './SkillForms/SkillFormFooter';
import useGoalHooks from 'src/hooks/useGoalHooks';
import { defaultSkillPaylopad, handleSetSkillPayload } from './Skills.utils';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import { GoalModal } from '../Goals/GoalModal/GoalModal';
import SelectedElement from 'src/components/ListItemFormCheckBox/SelectedElement';

type IProps = {
    defaultSkillName?: string;
    open: boolean;
    skill: SkillItem;
    setSkill: Dispatch<SetStateAction<SkillItem>>;
    toggleModal: any;
    setUpdateList?: Dispatch<SetStateAction<boolean>>;
    setJustAddedSkills?: Dispatch<SetStateAction<ProgramSkill[]>>;
    setNewSkillLabel?: Dispatch<SetStateAction<string>>;
};

export const SkillModal: FC<IProps> = (props) => {
    const {
        setNewSkillLabel,
        setJustAddedSkills,
        defaultSkillName,
        setSkill,
        open,
        toggleModal,
        setUpdateList,
        skill,
    } = props;
    const { optimizedGoalsNoEmptyWorkshopOrArticle: optimizedGoals, fetchingGoals } = useGoalHooks({});
    const [payload, setPayload] = useState<PostSkillPayload>(defaultSkillPaylopad);
    const [openGoalForm, setOpenGoalForm] = useState(false);
    const [goal, setGoal] = useState<GoalItem>({} as GoalItem);
    const [justAddedGoalIds, setJustAddedGoalIds] = useState<number[]>([]);
    const toggleGoalModal = () => {
        setOpenGoalForm(!openGoalForm);
        setJustAddedGoalIds([])
    }

    useEffect(() => {
        if (skill?.id > 0) {
            handleSetSkillPayload({ setPayload, skill });
        }
    }, [skill]);

    useEffect(() => {
        if (defaultSkillName) {
            setPayload((prev) => ({ ...prev, skill: defaultSkillName }));
        }
    }, [defaultSkillName]);

    const [searchkey, setSearchkey] = useState<string>('');
    const [searchedItems, setSearchedItems] = useState<Array<any>>([]);
    const [selectedFilterIds, setSelectedFilterIds] = useState<Array<number>>([]);
    const handleSearch = (value: string) => setSearchkey(value);

    useEffect(() => {
        setSearchedItems(searchElementByName(optimizedGoals ?? [], searchkey, 'name'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchkey]);

    useEffect(() => {
        setPayload((prev:any) => ({...prev, goalIds : [...payload.goalIds,...justAddedGoalIds] }));
    },[justAddedGoalIds])

    const finalData = searchkey?.trim()?.length > 0 ? searchedItems : optimizedGoals;
    const dataWithFilter =
        selectedFilterIds?.length > 0 ? finalData?.filter((item) => selectedFilterIds?.includes(item.id)) : finalData;

    const clearFormData = () => {
        setPayload(defaultSkillPaylopad);
        setNewSkillLabel?.('');
    };

    const disableAddObjective = payload.skill.trim() === '';
    const title = skill?.id > 0 ? "Modification d'une compétence" : "Création d'une nouvelle compétence";

    return (
        <CustomModal
            disableBackdropClick
            title={title}
            open={open}
            toggleModal={toggleModal}
            footer={
                <SkillFormFooter
                    skill={skill}
                    setSkill={setSkill}
                    setPayload={setPayload}
                    payload={payload}
                    toggleModal={toggleModal}
                    setUpdateList={setUpdateList}
                    setJustAddedSkills={setJustAddedSkills}
                    setNewSkillLabel={setNewSkillLabel}
                />
            }
            width={700}
            clearFormData={clearFormData}
        >
            <Spacer />

            <InputField
                label=""
                width="100%"
                value={payload.skill}
                onChange={(text: any) => {
                    setPayload((old: any) => ({ ...old, skill: text }));
                }}
                placeholder="Intitulé de la compétence..."
                validator={isNotBlank}
            />

            <div style={sxStyles.workshops}>
                <div
                    style={{
                        alignItems: 'center',
                        flexDirection: 'row',
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <div style={{ width: '80%' }}>
                        <CustomText fontFamily={baseFontFamily.OpenSansSemiBold} fontSize={baseFontSize.mini}>
                            Objectifs rattachés à la nouvelle compétence :
                        </CustomText>

                        <SelectedElement finalData={finalData} selectedIds={payload?.goalIds} />
                    </div>

                    <CustomLoadingButton
                        style={{ backgroundColor: baseColors.orange.primary }}
                        width={280}
                        label="Ajouter un nouvel objectif"
                        disabled={disableAddObjective}
                        handleClick={toggleGoalModal}
                    />
                </div>

                <Spacer height={10} />

                <ListItemFormCheckBox
                    withFilter
                    selectedRows={new Set(payload?.goalIds)}
                    handleChange={(value: any) => setPayload((old: any) => ({ ...old, goalIds: Array.from(value) }))}
                    items={dataWithFilter ?? []}
                    loading={fetchingGoals}
                    labelKey="name"
                    handleSearch={(value: string) => handleSearch(value)}
                    maxItems={optimizedGoals.length}
                    selectedFilterIds={selectedFilterIds}
                    setSelectedFilterIds={setSelectedFilterIds}
                />
            </div>

            <GoalModal 
                open={openGoalForm} 
                goal={goal} 
                setGoal={setGoal} 
                toggleModal={toggleGoalModal} 
                setJustAddedGoalIds={setJustAddedGoalIds}
                customTitle={`Créer nouvel objectif pour la compétence : ${payload?.skill}`} 
            />
        </CustomModal>
    );
};
