import { useState, FC } from 'react';
import { createContext } from 'react';

type DashboardType = {
    currentDate: Date;
    setCurrentDate: (val: Date) => void;
    updateData: boolean;
    setUpdateData: (val: boolean) => void;
};

export const DashboardContext = createContext<DashboardType>({
    currentDate: new Date(),
    setCurrentDate: () => { },
    updateData: false,
    setUpdateData: () => { }
});

const DashboardProvider: FC = (props) => {
    const { children } = props;
    const [currentDate, setCurrentDate] = useState<Date>(new Date());
    const [updateData, setUpdateData] = useState<boolean>(false);
    return (
        <DashboardContext.Provider
            value={{
                currentDate,
                setCurrentDate,
                updateData,
                setUpdateData,
            }}
        >
            {children}
        </DashboardContext.Provider>
    );
};

export default DashboardProvider;
