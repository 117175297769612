import { Box } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import useStyles from './styles';
import DayInNumbers from './DayInNumbers';
import PatientsOfTheDay from './PatientsOfTheDay';
import ScheduledWorkshops from './ScheduledWorkshops';
import { baseColors, baseFontFamily, baseFontSize } from 'src/utils/constants/baseStyles';
import { CustomText } from 'src/components/Text/CustomText';

const TodaysProgram: FC = (props) => {
    const classes = useStyles();
    const [todayDate, setTodayDate] = useState(new Date());
    const [today, setToday] = useState('');

    useEffect(() => {
        let date = todayDate.toLocaleDateString('fr-FR', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        });
        date = date.charAt(0).toUpperCase() + date.slice(1);
        return setToday(date);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box className={classes.container}>
            <Box className={classes.header} paddingTop={1}>
                <CustomText isBreefSerif isTitle>
                    Le programme de ma journée
                </CustomText>

                <CustomText
                    style={{ marginTop: 10, marginBottom: 10 }}
                    color={baseColors.green.primary}
                    fontSize={baseFontSize.md}
                    fontFamily={baseFontFamily.OpenSansSemiBold}
                >
                    {today}
                </CustomText>
            </Box>

            <Box
                style={{
                    backgroundColor: baseColors.white,
                    borderRadius: 7,
                    padding: 5,
                    paddingTop: 15,
                    paddingBottom: 30,
                    overflow: 'hidden',
                    overflowY: 'scroll',
                    height: '100%',
                }}
            >
                <Box className={classes.content}>
                    <DayInNumbers />
                    <PatientsOfTheDay />
                    <ScheduledWorkshops />
                </Box>
            </Box>
        </Box>
    );
};

export default TodaysProgram;
