import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import { FC, useContext, useEffect, useState } from 'react';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import { AntecedantList, Treatments } from 'src/interfaces/interfaces';
import { getAntecedentsList, getTreatments, getAssistantList } from 'src/services/Patients';
import { PatientContext } from 'src/providers/PatientProvider';
import PatientProgram from './PatientProgram';
import { PatientInfos } from './PatientInfos';
import { useFetchPatientPrograms } from 'src/hooks/ReactQueryHooks/useProgramRQHooks';

interface ProfilProps {
    patientInfo?: any;
    onContactClick?: Function;
}

const useStyles = makeStyles({
    container: {
        padding: 10,
        paddingTop: 25,
        flexGrow: 1,
        overflowY: 'scroll',
        background: 'white',
        height: 'calc(100vh - 292px)',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        marginTop: 10,
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    title: {
        color: baseColors.black,
        fontFamily: baseFontFamily.BreeSerif,
    },
});

export const Profil: FC<ProfilProps> = (props) => {
    const { onContactClick } = props;
    const [loadAntecedants, setLoadAntecedant] = useState(false);
    const [loadTreatments, setLoadTreatments] = useState(false);
    const { setCurrentPatientProgram, selectedPatient: patientInfo } = useContext(PatientContext);
    const [professional, setProfessional] = useState<any>([]);
    const [assistant, setAssistant] = useState<any>([]);
    const classes = useStyles();

    const patientId = patientInfo?.id ?? 0;
    const { data: userPrograms, isLoading: fetchingUserPrograms } = useFetchPatientPrograms({
        patientId: patientId ?? 0,
    });

    useEffect(() => {
        if (patientInfo?.professionals) {
            if (patientInfo.professionals.length > 0) {
                const lengthProf = patientInfo.professionals.length;
                let dataProfessionals = [];
                for (let i = 0; i < lengthProf; i++) {
                    let dataProf = patientInfo.professionals[i].professional;
                    dataProfessionals.push(dataProf);
                }
                setProfessional(dataProfessionals);
            }
        }
        if (patientInfo?.assistants) {
            if (patientInfo.assistants.length > 0) {
                const lengthAssist = patientInfo.assistants.length;
                let dataAssist = [];
                for (let z = 0; z < lengthAssist; z++) {
                    dataAssist.push(patientInfo.assistants[z].assistant);
                }
                setAssistant(dataAssist);
            } else {
                setAssistant(null);
            }
        }
    }, [patientInfo]);

    const [antecedantList, setAntecedantList] = useState<AntecedantList | null>();
    const fetchAntecedentList = () => {
        setLoadAntecedant(true);
        if (patientId) {
            getAntecedentsList(patientId)
                .then((res) => {
                    const antecedantData = res?.data?.data;
                    if (antecedantData) {
                        setAntecedantList(antecedantData);
                        setCurrentPatientProgram(antecedantData);
                        setLoadAntecedant(false);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    setLoadAntecedant(false);
                });
        }
    };

    const fetchAssistantList = () => {
        setLoadAntecedant(true);
        if (patientId) {
            getAssistantList(patientId)
                .then((res) => {
                    const ante = res?.data?.data;
                    if (ante) {
                    }
                })
                .catch((err) => {
                    console.log(err);
                    setLoadAntecedant(false);
                });
        }
    };

    const [treatmentsData, setTreatmentsData] = useState<Treatments | null>();

    const fetchTreatments = () => {
        setLoadTreatments(true);
        if (patientId) {
            getTreatments(patientId)
                .then((res) => {
                    const treatmentsMyData = res?.data?.data.items;
                    if (treatmentsMyData) {
                        setTreatmentsData(treatmentsMyData);
                        setLoadTreatments(false);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    setLoadTreatments(false);
                });
        }
    };

    useEffect(() => {
        if (patientId > 0) {
            fetchAntecedentList();
            fetchTreatments();
            fetchAssistantList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patientId]);

    const emptyProgramList = userPrograms?.length === 0;
    const emptyAntecedantList = antecedantList?.length === 0;
    const emptyTraitmentList = treatmentsData?.length === 0;

    return (
        <Box className={classes.container}>
            <Grid container direction={'column'} spacing={7} paddingLeft={2}>
                <PatientInfos
                    onContactClick={onContactClick}
                    patientId={patientInfo?.id ?? 0}
                    antecedantList={antecedantList}
                    assistant={assistant}
                    professional={professional}
                />

                <PatientProgram
                    antecedantList={antecedantList}
                    emptyAntecedantList={emptyAntecedantList}
                    loadProgram={fetchingUserPrograms}
                    emptyProgramList={emptyProgramList}
                    programList={userPrograms}
                    loadAntecedants={loadAntecedants}
                    loadTreatments={loadTreatments}
                    emptyTraitmentList={emptyTraitmentList}
                    treatmentsData={treatmentsData}
                />
            </Grid>
        </Box>
    );
};
