import { useState, FC } from 'react';
import { createContext } from 'react';

export type AxeByProgram = { axisId: number; programId: number };

type WorkshopProviderType = {
    workshops: AxeByProgram[];
    setWorkshops: (val: any) => void;
};

export const WorkshopContext = createContext<WorkshopProviderType>({
    workshops: [],
    setWorkshops: () => {},
});

const WorkshopProvider: FC = (props) => {
    const { children } = props;
    const [workshops, setWorkshops] = useState<AxeByProgram[]>([]);

    return (
        <WorkshopContext.Provider
            value={{
                workshops,
                setWorkshops,
            }}
        >
            {children}
        </WorkshopContext.Provider>
    );
};

export default WorkshopProvider;
