import { Dispatch, FC, SetStateAction } from 'react';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import useEvaluationHooks from 'src/hooks/useEvaluationHooks';
import { ReportPayload } from 'src/interfaces/interfaces';
import { baseColors } from 'src/utils/constants/baseStyles';
import { isNotBlank } from 'src/utils/helpers/InputValidator';

interface IProps {
    showForm: boolean;
    fetching: boolean;
    toggleForm: Function;
    reportPayload: ReportPayload;
    setReportPayload: Dispatch<SetStateAction<ReportPayload>>;
}

const EvaluationFooter: FC<IProps> = ({ fetching, showForm, toggleForm, reportPayload, setReportPayload }) => {
    const { handlePostReport, saving } = useEvaluationHooks({ toggleForm });
    const validReportData = () => {
        return (
            isNotBlank(reportPayload?.programEnteringReason) &&
            isNotBlank(reportPayload?.medicalAntecedent) &&
            isNotBlank(reportPayload?.treatments) &&
            isNotBlank(reportPayload?.educationalDiagnosisConclusion) &&
            isNotBlank(reportPayload?.followedProgramContent) &&
            isNotBlank(reportPayload?.interviewConclusion)
        );
    };

    return (
        <div style={{}}>
            {showForm ? (
                <div style={styles.flexCenter}>
                    <CustomLoadingButton
                        style={{ backgroundColor: baseColors.orange.primary }}
                        width="48%"
                        disabled={saving}
                        label="ANNULER"
                        handleClick={toggleForm}
                    />

                    <CustomLoadingButton
                        loading={saving}
                        width="48%"
                        disabled={!validReportData() || saving}
                        label="ENREGISTRER"
                        handleClick={() => handlePostReport({ reportPayload, setReportPayload })}
                    />
                </div>
            ) : (
                <div style={styles.flexCenter}>
                    <CustomLoadingButton
                        width="48%"
                        disabled={saving || fetching}
                        label="Ajouter un compte-rendu"
                        handleClick={toggleForm}
                    />
                </div>
            )}
        </div>
    );
};

export default EvaluationFooter;

const styles = {
    flexCenter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
    },
};
