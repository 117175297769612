import { PasswordResetData } from 'src/interfaces/interfaces';
import axios from './AxiosInstance';

const loginCheck = (username: string, password: string) =>
    axios.post('api/login_check', {
        username,
        password,
    });

const forgotPassword = (mail: string) => axios.put('api/users/forgotPassword', { email: mail });

const resetPassword = (data: PasswordResetData) => axios.put('api/users/resetPassword', { ...data });

const logout = () => axios.get('api/logout');

export { loginCheck, forgotPassword, resetPassword, logout };
