import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { retrievePermissions } from 'src/actions/permission/permission';
import { RootState } from 'src/stores/store';
import { baseColors } from 'src/utils/constants/baseStyles';
import PermissionTable, { PermissionActionButtons } from './PermissionTable';
import useStyles from './styles';
import CustomCircleLoader from 'src/components/CustomLoader/CustomCircleLoader';
import CustomEmptyData from 'src/components/CustomEmptyData';
import { CustomText } from 'src/components/Text/CustomText';

const PermissionPanel = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const permissions = useSelector((state: RootState) => state.permissions.listPermissions);
    const [dataRows, setDataRows] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        dispatch(retrievePermissions(setLoading));
    }, []);

    useEffect(() => {
        parsePermissionData(permissions);
    }, [permissions]);

    const parsePermissionData = (permissions: any[]) => {
        let rows: any[] = [];

        permissions.forEach((role) => {
            rows.push({
                id: role.id,
                name: role.name,
                value: role.value,
                symbol: role.symbol,
                action: <PermissionActionButtons />,
            });
            return rows;
        });

        setDataRows(rows);
    };
    const tableHeaders = [
        {
            name: 'Nom',
        },
        {
            name: 'Valeur',
        },
        {
            name: 'Symbole',
        },
        {
            name: 'Action',
        },
    ];

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                paddingX: '25px',
                overflow: 'scroll',
            }}
        >
            <Box
                sx={{
                    height: '50px',
                    marginTop: '25px',
                    marginBottom: '15px',
                    textAlign: 'center',
                }}
            >
                <CustomText isBreefSerif isTitle color={baseColors.green.primary} style={{ marginLeft: 20 }}>
                    Liste des permissions
                </CustomText>
            </Box>
            {loading ? (
                <div className={classes.loading}>
                    <CustomCircleLoader />
                </div>
            ) : dataRows?.length > 0 ? (
                <PermissionTable tableHeaders={tableHeaders} dataRows={dataRows} />
            ) : (
                <CustomEmptyData />
            )}
        </Box>
    );
};

export default PermissionPanel;
