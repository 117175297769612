import { Grid } from '@mui/material';
import { FC, useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { CustomCheckBox } from 'src/components/InputField/CustomCheckBox';
import { CustomRadio } from 'src/components/InputField/CustomRadio';
import { CustomText } from 'src/components/Text/CustomText';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import { BackButton } from 'src/components/Button/BackButton';
import { ProgramContext } from 'src/providers/ProgramProvider';
import { syntheseDestinations, programSynthese } from 'src/services/Syntheses';
import { getProgramList, getStatePatientProgram } from 'src/services/Patients';
import { UserContext } from 'src/providers/UserProvider';
import { Patient } from 'src/interfaces/interfaces';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import { PatientContext } from 'src/providers/PatientProvider';
import { StateProgramUserTemp } from "src/utils/constants/constants";
import CustomModal from 'src/components/CustomModal/CustomModal';
import { Spacer } from 'src/components/Spacer/Spacer';
import { Box } from '@mui/material';

interface ProgramSynthesisProps {
    patientInfo?: Patient;
    defaultPatient?: any;
    isActor?: any;
}

export const ProgramSynthesis: FC<ProgramSynthesisProps> = (props) => {
    const { isActor, defaultPatient } = props;
    const { selectedPatient } = useContext(PatientContext);
    const [comportmentGoal, setComportmentGoal] = useState(false);
    const [destination, setDestination] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [needLearning, setNeedLearning] = useState(false);
    const [syntheseDest, setSyntheseDest] = useState<Array<number>>([]);
    const [numSeancePresentiel, setNumSeancePresentiel] = useState(0);
    const [numSeanceDistanciel, setNumSeanceDistanciel] = useState(0);
    const [stateProgramUserTemp, setStateProgramUserTemp] = useState("");
    const { checkedProgram } = useContext(ProgramContext);
    const { isDoctor } = useContext(UserContext);
    const params = useParams<any>();
    const history = useHistory();
    const [patientDefaultStatus, setPatientDefaultStatus] = useState();
    const [programState, setProgramState] = useState<boolean>();
    const [showConfirm, setShowConfirm] = useState(false);

    const patientId = selectedPatient?.id ?? 0;

    const fetchStateProgram = () => {
        if (patientId) {
            getStatePatientProgram(patientId).then((res) => {
                const state = res?.data?.data;
                setProgramState(state?.hasCurrentProgram);
            });
        }
    };

    const handleFetchSyntheseDestinations = async () =>
        await syntheseDestinations().then((response) => {
            setDestination(response.data.data);
        });

    const fetchSynthese = () => {
        if (patientId) {
            setLoading(true);
            getProgramList(patientId)
                .then((res) => {
                    const programsData = res?.data?.data;               
                    if (programsData) {
                        programsData?.map((program: any) => {
                            if (program.program.id === checkedProgram.id) {
                                if (program.synthese) {
                                    setComportmentGoal(program.synthese.hasBehaviorChange === 1);
                                    setNeedLearning(program.synthese.hasUsefulLearning === 1);
                                    if (program?.synthese?.finalSyntheseDestinations) {
                                        program?.synthese?.finalSyntheseDestinations.map((dest: any) => {
                                            setSyntheseDest?.((prev: any) => [...prev, dest.id]);
                                        });
                                    }
                                    setNumSeancePresentiel(program.synthese.numSeancePresentiel);
                                    setNumSeanceDistanciel(program.synthese.numSeanceDistanciel);
                                    setStateProgramUserTemp(program?.stateProgramUserTemp);
                                }
                            }
                        });
                    }
                    setLoading(false)
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    useEffect(() => {
        if (isDoctor) {
            if (isActor?.length === 0) {
                setPatientDefaultStatus(defaultPatient[0]?.isAcceptedByProfessional);
            } else {
                setPatientDefaultStatus(isActor?.isAcceptedByProfessional);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patientId]);

    useEffect(() => {
        fetchSynthese();
        handleFetchSyntheseDestinations();
        fetchStateProgram();
    }, [patientId]);

    let dataSynthesis: any = {
        numSeancePresentiel: 0,
        numSeanceDistanciel: 0,
        hasBehaviorChange: 0,
        hasUsefulLearning: 0,
        finalSyntheseDestinationIds: [],
        programUserId: null,
    };

    const createSyntesis = async () => {
        setLoading(true);
        dataSynthesis = { 
            ...dataSynthesis, 
            programUserId: checkedProgram?.id,
            programUser : selectedPatient?.id
        };
        dataSynthesis.numSeancePresentiel = numSeancePresentiel;
        dataSynthesis.numSeanceDistanciel = numSeanceDistanciel;
        if (needLearning) {
            dataSynthesis.hasUsefulLearning = 1;
        } else {
            dataSynthesis.hasUsefulLearning = 0;
        }
        if (comportmentGoal) {
            dataSynthesis.hasBehaviorChange = 1;
        } else {
            dataSynthesis.hasBehaviorChange = 0;
        }

        dataSynthesis.finalSyntheseDestinationIds = syntheseDest;

        await programSynthese(dataSynthesis)
            .then((response) => {
                if (response.data.success && !isNaN(params?.id)) {
                    toast.success('Synthèse enregistrée avec succès.');
                    history.push('/patients/list/' + params?.id + '/profile');
                } else {
                    toast.success("Erreur lors de l'enregistrement de la synthèse.");
                }
                setLoading(false);
                setShowConfirm(false);
            })
            .catch((e) => {
                setLoading(false);
                setShowConfirm(false);
                toast.success("Erreur lors de l'enregistrement de la synthèse.");
            });
    };

    return (
        <div style={{ height: '55vh', overflowY: 'scroll', padding: 20, paddingBottom: 40 }}>
            <Grid>
                <Grid container direction="row" alignItems="center" style={{ marginTop: 20 }}>
                    <Grid xs={3} item alignSelf={'flex-start'}>
                        <BackButton />
                    </Grid>
                    <Grid item alignSelf={'center'} style={{ marginLeft: '10%' }}>
                        <CustomText fontFamily={baseFontFamily.BreeSerif} fontSize={18}>
                            Synthèse du {checkedProgram?.title ?? ''}
                        </CustomText>
                    </Grid>
                </Grid>
                <Grid style={{ marginTop: 35 }} container justifyContent={'space-between'} direction={'row'}>
                    <Grid item flex={2}>
                        <CustomText fontFamily={baseFontFamily.BreeSerif} fontSize={16}>
                            Séances effectuées
                        </CustomText>
                        <Grid container direction="column" style={{ marginBottom: 40 }}>
                            <Grid item>
                                <Grid container direction="row" style={{ paddingTop: 20 }} alignItems="center">
                                    <CustomText fontSize={13}>Présentielle :</CustomText>
                                    <Grid lg={3}>
                                        <Grid
                                            xs={12}
                                            style={{
                                                marginLeft: 8,
                                                paddingLeft: 10,
                                                paddingRight: 5,
                                                borderRadius: 10,
                                                background: baseColors.green.light,
                                            }}
                                            container
                                            direction={'row'}
                                            justifyContent={'space-around'}
                                            alignItems={'center'}
                                        >
                                            <CustomText
                                                fontFamily={baseFontFamily.OpenSansBold}
                                                color={baseColors.green.primary}
                                            >
                                                {numSeancePresentiel}
                                            </CustomText>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container direction="row" style={{ paddingTop: 20 }} alignItems="center">
                                    <CustomText fontSize={13}>Distancielle :</CustomText>
                                    <Grid lg={3}>
                                        <Grid
                                            xs={12}
                                            style={{
                                                marginLeft: 8,
                                                paddingLeft: 10,
                                                paddingRight: 5,
                                                borderRadius: 10,
                                                background: baseColors.green.light,
                                            }}
                                            container
                                            direction={'row'}
                                            justifyContent={'space-around'}
                                            alignItems={'center'}
                                        >
                                            <CustomText
                                                fontFamily={baseFontFamily.OpenSansBold}
                                                color={baseColors.green.primary}
                                            >
                                                {numSeanceDistanciel}
                                            </CustomText>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <CustomText style={{ marginBottom: 15 }} fontFamily={baseFontFamily.BreeSerif} fontSize={16}>
                            Modules suivis
                        </CustomText>
                        <Grid container direction="column">
                            {checkedProgram?.programDetailModules?.map((module: any) => (
                                <CustomText style={{ marginBottom: 5 }}>{module.module.name}</CustomText>
                            ))}
                        </Grid>
                    </Grid>
                    <Grid item>
                        <CustomText fontFamily={baseFontFamily.BreeSerif} fontSize={16}>
                            Le patient a formulé :
                        </CustomText>

                        <Grid container direction="column">
                            <Grid item>
                                <Grid container direction="row" style={{ paddingTop: 20 }} alignItems="center">
                                    <CustomText fontSize={13}>Un objectif de changement comportemental</CustomText>
                                </Grid>
                                <Grid container direction="row" spacing={4}>
                                    <Grid item>
                                        <CustomRadio
                                            onClick={() => setComportmentGoal(false)}
                                            checked={!comportmentGoal}
                                            label={'Non'}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <CustomRadio
                                            onClick={() => {
                                                setComportmentGoal(true);
                                            }}
                                            checked={comportmentGoal}
                                            label={'Oui'}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container direction="row" style={{ paddingTop: 20 }} alignItems="center">
                                    <CustomText fontSize={13}>Un apprentissage utile :</CustomText>
                                </Grid>
                                <Grid container direction="row" spacing={4}>
                                    <Grid item>
                                        <CustomRadio
                                            onClick={() => {
                                                setNeedLearning(false);
                                            }}
                                            checked={!needLearning}
                                            label={'Non'}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <CustomRadio
                                            onClick={() => {
                                                setNeedLearning(true);
                                            }}
                                            checked={needLearning}
                                            label={'Oui'}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* 
                                <Grid container direction="column" style={{ marginTop: 20 }}>
                                    <CustomText
                                        color={baseColors.green.primary}
                                        style={{ marginBottom: 5, textDecoration: 'underline' }}
                                    >
                                        Lien vers le questionnaire de satisfaction
                                    </CustomText>
                                    <CustomText color={baseColors.green.primary} style={{ textDecoration: 'underline' }}>
                                        Lien vers le questionnaire de satisfaction aidant
                                    </CustomText>
                                </Grid>
                            */}
                        </Grid>
                    </Grid>

                    <Grid item>
                        <CustomText fontFamily={baseFontFamily.BreeSerif} fontSize={16}>
                            Synthèse finale à envoyer à :
                        </CustomText>
                        <Grid
                            container
                            alignContent={'flex-start'}
                            justifyItems={'flex-start'}
                            direction="column"
                            justifyContent={'flex-start'}
                        >
                            <Grid item style={{ alignSelf: 'flex-start' }}>
                                <CustomCheckBox
                                    onClick={() => {
                                        if (!syntheseDest.includes(1)) {
                                            setSyntheseDest?.((prev: any) => [...prev, destination[0]?.id]);
                                        } else {
                                            setSyntheseDest?.((prev: any) =>
                                                prev.filter((destId: any) => destId !== destination[0]?.id),
                                            );
                                        }
                                    }}
                                    checked={syntheseDest.includes(1)}
                                    label={destination[0]?.name}
                                ></CustomCheckBox>
                            </Grid>
                            <Grid item style={{ alignSelf: 'flex-start' }}>
                                <CustomCheckBox
                                    onClick={() => {
                                        if (!syntheseDest.includes(2)) {
                                            setSyntheseDest?.((prev: any) => [...prev, destination[1]?.id]);
                                        } else {
                                            setSyntheseDest?.((prev: any) =>
                                                prev.filter((destId: any) => destId !== destination[1]?.id),
                                            );
                                        }
                                    }}
                                    checked={syntheseDest.includes(2)}
                                    label={destination[1]?.name}
                                ></CustomCheckBox>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container direction="row" justifyContent={'space-around'} style={{ marginTop: 20 }}>
                    {stateProgramUserTemp !== StateProgramUserTemp.TERMINE.code && 
                        <CustomLoadingButton
                            handleClick={() => setShowConfirm(true)}
                            width={330}
                            label="TERMINER LE PROGRAMME DU PATIENT"
                            loading={loading}
                        />
                    }       
                </Grid>
            </Grid>

            <CustomModal
                disableBackdropClick
                open={showConfirm}
                toggleModal={setShowConfirm}
                title="Remarque!"
                width={600}
            >
                <Box>
                    <Spacer />
                    <CustomText style={{ textAlign: 'center' }}>
                        La validation du programme du patient est définitive. <br />
                        Vous ne pourrez plus le modifier.
                    </CustomText>
                    <Spacer height={60} />
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '30px' }}>
                        <CustomLoadingButton
                            orange
                            disabled={loading}
                            label="Fermer"
                            handleClick={() => setShowConfirm(false)}
                            width="45%"
                        />
                        <CustomLoadingButton
                            loading={loading}
                            label="Terminer le programme du patient"
                            handleClick={() => createSyntesis()}
                            width="45%"
                        />
                    </div>
                </Box>
            </CustomModal>
        </div>
    );
};
