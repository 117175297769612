import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { retrieveConsultations } from 'src/actions/dayHospitals/management';
import CustomHdjCheckListContent from 'src/components/CustomHdjCheckListContent';
import useDebounce from 'src/hooks/useDebounce';
import { HDJConsultationAndWorkshopForm } from '../../HdjForm/HDJConsultationAndWorkshopForm';
interface HdjConsultationProps {
    dayHospitalId?: number;
    fetchedHdjData?: any;
    hdj?:any;
    setHdj?: any;
    disableCheckBox?: boolean;
    marginRight?: boolean;
    edit?: boolean;
    setSelectedConsultation?:any;
}

const HdjConsultation: FC<HdjConsultationProps> = (props) => {
    const { dayHospitalId, fetchedHdjData, hdj, setHdj, disableCheckBox, edit,setSelectedConsultation } = props;
    const dispatch = useDispatch();
    const [loadingConsultation, setLoadingConsultation] = useState<boolean>(false);
    const [modalConsultationVisible, setModalConsultationVisible] = useState<boolean>(false);
    const [updateList, setUpdateList] = useState<boolean>(false);
    const [searchKey, setSearchKey] = useState<string>('');
    const defaultSelectedItems = fetchedHdjData?.consultations ?? [];

    const handleFetchData = (query: string) => {
        dispatch(retrieveConsultations(setLoadingConsultation, dayHospitalId ?? 0, query));
    };
    const { debouncedResults } = useDebounce(handleFetchData);

    useEffect(() => {
        handleFetchData('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateList]);

    const fetchedData = useSelector((state: any) => state?.managementConsultations);
    const consultations = fetchedData?.data?.itemCount > 0 ? fetchedData?.data?.items : [];
    const toggleModal = () => setModalConsultationVisible(!modalConsultationVisible);
    const handleSearch = (value: string) => {
        setSearchKey(value);
        debouncedResults(value);
    };

    const getSelectedConsultations = () => {
        if(setSelectedConsultation){
            const selectedConsultations = consultations.filter((item:any)=> hdj?.consultationIds?.includes(item.id));
            setSelectedConsultation(selectedConsultations);
        }
    }

    useEffect(()=>{
        getSelectedConsultations();
    },[hdj?.consultationIds]);

    return (
        <>
            <HDJConsultationAndWorkshopForm
                dayHospitalId={dayHospitalId}
                modalOpen={modalConsultationVisible}
                modalToggle={setModalConsultationVisible}
                setUpdateList={setUpdateList}
                validateBtnLabel="Valider la consultation"
            />

            <CustomHdjCheckListContent
                edit={edit}
                disableCheckBox={disableCheckBox}
                loading={loadingConsultation}
                items={consultations}
                selectedItems={defaultSelectedItems}
                label="Liste des consultations"
                btnLabel="Créer nouvelle consultation"
                toggleModal={toggleModal}
                handleSearch={handleSearch}
                setHdj={setHdj}
                isConsultation
                marginRight
                withoutBtn
            />
        </>
    );
};

export default HdjConsultation;
