import { Box, IconButton } from '@mui/material';
import { useState } from 'react';
import { baseColors, baseScreenHeight } from 'src/utils/constants/baseStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import { CustomConfirm } from 'src/components/CustomConfirm/CustomConfirm';
import styles from './styles';
import CustomEmptyData from 'src/components/CustomEmptyData';
import { CustomText } from 'src/components/Text/CustomText';
import { tableCellHeadText, tableCellText } from 'src/views/Security/SecurityManagement/SecurityManagement';
import SubMenusLoader from 'src/components/SettingsSubMenuBar/SubMenusLoader/SubMenusLoader';
import { GoalModal } from './GoalModal/GoalModal';
import useGoalHooks from 'src/hooks/useGoalHooks';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { GoalItem } from 'src/interfaces/interfaces';

export const GoalsPanel = () => {
    const [modalConfirmVisible, setModalConfirmVisible] = useState(false);
    const [goalToDeleteId, setGoalToDeleteId] = useState(0);
    const [openForm, setOpenForm] = useState(false);
    const [goal, setGoal] = useState<GoalItem>({} as GoalItem);

    const toggleModal = () => setOpenForm(!openForm);
    const tableHeaders = [{ name: 'Objectifs' }, { name: 'Axe' }, { name: 'Validation' }, { name: 'Action' }];
    const { goals, fetchingGoals, saving, handleDeleteGoal } = useGoalHooks({
        setModalConfirmVisible,
    });

    const handleClick = () => toggleModal();

    const handleEdit = (selectedGoal: GoalItem) => {
        setGoal(selectedGoal);
        toggleModal();
    };

    return (
        <Box sx={styles.container}>
            <Box sx={styles.header}>
                <CustomText isBreefSerif isTitle color={baseColors.green.primary} style={{ marginLeft: 20 }}>
                    Liste des objectifs
                </CustomText>

                <CustomLoadingButton
                    disabled={fetchingGoals}
                    label="Ajouter un nouvel objectif"
                    handleClick={handleClick}
                />
            </Box>

            {fetchingGoals ? (
                <SubMenusLoader />
            ) : goals?.length === 0 ? (
                <div style={{ paddingBottom: 150 }}>
                    <CustomEmptyData />
                </div>
            ) : (
                <TableContainer
                    component={Paper}
                    style={{ overflow: 'auto', maxHeight: baseScreenHeight.tableContainerHeight }}
                >
                    <Table stickyHeader sx={{ minWidth: 650 }} aria-label="role table">
                        <TableHead>
                            <TableRow>
                                {tableHeaders.map((header, index) => (
                                    <TableCell key={index} sx={{ color: baseColors.green.primary }}>
                                        {tableCellHeadText(header.name)}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {goals?.map((goal: any, index: number) => (
                                <TableRow
                                    key={index}
                                    sx={{
                                        '&:last-child td, &:last-child th': { border: 0 },
                                        '& .MuiTableCell-root': {
                                            padding: '5px 15px 5px 15px',
                                        },
                                    }}
                                >
                                    <TableCell component="th" scope="row">
                                        {tableCellText(goal.goal)}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {tableCellText(goal.axis.name)}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {tableCellText(
                                            ` ${goal.duration > 1 ? `${goal.duration} semaines` : 'Une fois'}`,
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => handleEdit(goal)}>
                                            <EditOutlinedIcon
                                                sx={{
                                                    fontSize: 'medium',
                                                    color: baseColors.green.primary,
                                                    cursor: 'pointer',
                                                }}
                                            />
                                        </IconButton>

                                        <IconButton
                                            onClick={() => {
                                                setGoalToDeleteId(goal.id);
                                                setModalConfirmVisible(true);
                                            }}
                                        >
                                            <DeleteOutlineOutlinedIcon sx={styles.delete} />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            <GoalModal open={openForm} goal={goal} setGoal={setGoal} toggleModal={toggleModal} />

            <CustomConfirm
                defaultLoading={fetchingGoals || saving}
                modalVisible={modalConfirmVisible}
                updateModalVisible={setModalConfirmVisible}
                message="Voulez-vous vraiment supprimer l'objectif ? "
                callBackconfirm={() => handleDeleteGoal({ goalId: goalToDeleteId })}
            />
        </Box>
    );
};
