import { Box, IconButton } from '@mui/material';
import { Dispatch, FC, SetStateAction, useState,useEffect } from 'react';
import { InputField } from 'src/components/InputField/InputField';
import { ChoiceRespone, QuestionType } from 'src/interfaces/types';
import { isValidQuestion } from 'src/utils/helpers/InputValidator';
import { handleSetQuestionData, handleValidateRadioResponses, newRadioItemContent } from './QuizSettingsLogic';
import useStyles from './styles';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { baseColors } from 'src/utils/constants/baseStyles';
import { Spacer } from 'src/components/Spacer/Spacer';
import { CustomText } from 'src/components/Text/CustomText';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import QuizCheckBoxItem from './QuizCheckBoxItem';

type QuizTypeCheckBoxProps = {
    responsesList: ChoiceRespone[];
    textQuestion : string;
    questionIndex: number;
    setQuestions: Dispatch<SetStateAction<QuestionType[]>>;
};

type InputType = {
    question: string;
};

const QuizTypeCheckBox: FC<QuizTypeCheckBoxProps> = ({ questionIndex, setQuestions,responsesList,textQuestion }) => {
    const classes = useStyles();
    const [responses, setResponses] = useState<ChoiceRespone[]>(responsesList);
    const [selectedElements, setSelectedElements] = useState<number[]>([]);
    const [validating, setValidating] = useState(false);
    const [value, setValue] = useState<InputType>({
        question: '',
    });
    const handleChange = (keyValue: string, text: string) =>
        setValue((prev: InputType) => ({ ...prev, [keyValue]: text }));

    const handleAddMoreResponse = () => setResponses((prev: ChoiceRespone[]) => [...prev, { ...newRadioItemContent }]);
    const handleRemoveResponse = (index: number) =>
        setResponses((prev: ChoiceRespone[]) => {
            const res = prev.filter((_, i) => i !== index);
            return [...res];
        });

    useEffect(()=>{
        const selecteds:number[] = [];
        responses?.forEach((res : any,index : number) => res?.isTrue === 1 && selecteds.push(index));
        setSelectedElements(selecteds);
    },[])

    const isEmpty = responses?.length === 0;

    return (
        <div
            onMouseLeave={() => handleValidateRadioResponses({ responses, questionIndex, setQuestions, setValidating })}
            className={classes.textInputContainer}
        >
            <div className={classes.textInput}>
                <InputField
                    multiline
                    label="La question"
                    disabled={false}
                    validator={isValidQuestion}
                    errorMessage="Question trop court"
                    placeholder="Votre question"
                    height={70}
                    width={'70%'}
                    onChange={(text: string) => {
                        handleChange('question', text);
                        handleSetQuestionData({ questionIndex, setQuestions, textValue: text, isQuestion: true });
                    }}
                    value={textQuestion}
                />
            </div>

            <div className={classes.textInput}>
                <CustomText fontSize={18}>Listes des choix </CustomText>
                <Spacer height={10} />
                <CustomText color={baseColors.orange.primary} fontSize={14} fontStyle="italic">
                    (Veuillez sélectionner la ou les bonne(s) réponse(s))
                </CustomText>

                <Box sx={{ overflow: 'hidden', overflowY: 'scroll', maxHeight: 250, marginTop: 1 }}>
                    {responses?.map((response: ChoiceRespone, i: number) => (
                        <QuizCheckBoxItem
                            key={i}
                            response={response}
                            responseIndex={i}
                            selectedElements={selectedElements}
                            setSelectedElements={setSelectedElements}
                            handleRemoveResponse={handleRemoveResponse}
                            setResponses={setResponses}
                        />
                    ))}
                </Box>

                <div className={classes.addMoreRadio}>
                    <IconButton className={classes.addMoreRadioInput} onClick={handleAddMoreResponse}>
                        <AddCircleIcon style={{ color: baseColors.green.pastel, fontSize: 30 }} />
                    </IconButton>
                    <div />
                </div>

                {/* <div className={classes.validateResponses}>
                    <CustomLoadingButton
                        style={{ backgroundColor: baseColors.green.pastel }}
                        loading={validating}
                        disabled={validating || isEmpty}
                        label="Valider la liste"
                        handleClick={() =>
                            handleValidateRadioResponses({ responses, questionIndex, setQuestions, setValidating })
                        }
                    />
                </div> */}
            </div>
        </div>
    );
};

export default QuizTypeCheckBox;
