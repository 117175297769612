import { Dispatch, FC, SetStateAction, useState } from 'react';
import { InputField } from 'src/components/InputField/InputField';
import { ChoiceRespone,QuestionType } from 'src/interfaces/types';
import { isValidQuestion } from 'src/utils/helpers/InputValidator';
import { handleSetQuestionData } from './QuizSettingsLogic';
import useStyles from './styles';

type QuizTypeTextFieldProps = {
    responsesList: ChoiceRespone[];
    textQuestion : string;
    questionIndex: number;
    setQuestions: Dispatch<SetStateAction<QuestionType[]>>;
};

type InputType = {
    question: string;
    response: string;
};

const QuizTypeTextField: FC<QuizTypeTextFieldProps> = ({ questionIndex, setQuestions, responsesList,textQuestion  }) => {
    const classes = useStyles();
    const [value, setValue] = useState<InputType>({
        question: '',
        response: '',
    });
    const handleChange = (keyValue: string, text: string) =>
        setValue((prev: InputType) => ({ ...prev, [keyValue]: text }));

    return (
        <div className={classes.textInputContainer}>
            <div className={classes.textInput}>
                <InputField
                    multiline
                    label="La question"
                    disabled={false}
                    validator={isValidQuestion}
                    errorMessage="Question trop court"
                    placeholder="Votre question"
                    height={70}
                    width={'70%'}
                    onChange={(text: string) => {
                        handleChange('question', text);
                        handleSetQuestionData({ questionIndex, setQuestions, textValue: text, isQuestion: true });
                    }}
                    value={textQuestion}
                />
            </div>

            <div className={classes.textInput}>
                <InputField
                    multiline
                    label="La réponse"
                    disabled={false}
                    validator={isValidQuestion}
                    errorMessage="Réponse trop court"
                    placeholder="La bonne réponse aux question"
                    height={70}
                    width={'100%'}
                    onChange={(text: string) => {
                        handleChange('response', text);
                        handleSetQuestionData({
                            questionIndex,
                            setQuestions,
                            textValue: text,
                            isResponse: true,
                        });
                    }}
                    value={responsesList?.[0]?.textResponse}
                />
            </div>
        </div>
    );
};

export default QuizTypeTextField;
