import { useState,useEffect,FC } from 'react';
import useStyles from './styles';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import QuizItem from './QuizItem';
import { IconButton } from '@mui/material';
import { baseColors } from 'src/utils/constants/baseStyles';
import QuizItemHeader from './QuizItemHeader';
import { CreateQuizParams, HeaderPayload, QuestionType, QuizPayloadType,UpdateQuizParams } from 'src/interfaces/types';
import { Spacer } from 'src/components/Spacer/Spacer';
import { defaultHeaderPayload, newQuestionContent } from './QuizSettingsLogic';
import QuizService from 'src/services/Quiz';
import { toast } from 'react-toastify';
import { CustomText } from 'src/components/Text/CustomText';

interface QuizSettingsProps {
    selectedQuiz ?: any;
    setUpdateList : any;
    setModalOpen : any;
}

const QuizSettings: FC<QuizSettingsProps> = (props) => {
    const {selectedQuiz,setUpdateList,setModalOpen} = props;
    const classes = useStyles();
    const [questions, setQuestions] = useState<QuestionType[]>([]);
    const [creating, setCreating] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [updateHeaderInput, setUpdateHeaderInput] = useState(false);
    const [headerPayload, setHeaderPayload] = useState<HeaderPayload>(defaultHeaderPayload);

    useEffect(()=>{
        if(Object.keys(selectedQuiz).length > 0){
            setHeaderPayload({
                title : selectedQuiz?.title,
                programIds : Array.from(selectedQuiz?.program,(program : any)=>program?.id ),
                quizzType : selectedQuiz?.quizzType,
            });
            setQuestions(selectedQuiz?.questions);
        }
    },[])
    
    const clearInput = () => {
        setHeaderPayload(defaultHeaderPayload);
        setQuestions([]);
    };

    const handleAddMoreQuestion = () => setQuestions((prev: QuestionType[]) => [...prev, { ...newQuestionContent }]);

    const handleRemoveQuestion = (index: number) =>
        setQuestions((prev: QuestionType[]) => {
            const res = prev.filter((_, i) => i !== index);
            return [...res];
        });

    const payload: QuizPayloadType = {
        ...headerPayload,
        questions: questions,
    };
    
    const handleCreateQuiz = ({ payload, setCreating }: CreateQuizParams) => {
        setCreating(true);
        QuizService.postNewQuiz(payload)
            .then(() => {
                setCreating(false);
                setUpdateHeaderInput((prev) => !prev);
                toast.success('Quiz créé avec succès');
                clearInput();
                setUpdateList((prev: any) => !prev);
                setModalOpen(false);
            })
            .catch(() => {
                setCreating(false);
                toast.error('Erreur de création du Quiz');
            });
    };

    const handleUpdateQuiz = ({id, payload, setUpdating }: UpdateQuizParams) => {
        setUpdating(true);
        QuizService.putUpdateQuiz(payload,id)
            .then(() => {
                setUpdating(false);
                setUpdateHeaderInput((prev) => !prev);
                toast.success('Quiz modifié avec succès');
                clearInput();
                setUpdateList((prev: any) => !prev);
                setModalOpen(false);
            })
            .catch(() => {
                setUpdating(false);
                toast.error('Erreur de modification du Quiz');
            });
    };

    const isEmpty = questions?.length === 0;
    const hasInvalideType = questions?.some((question) => question.responseType === '');
    const disableSaveBtn =
        creating ||
        isEmpty ||
        headerPayload?.programIds?.length === 0 ||
        headerPayload?.quizzType === 0 ||
        headerPayload?.title?.trim() === '' ||
        hasInvalideType;

    return (
        <div className={classes.container}>
            <div className={classes.quizItems}>
                <QuizItemHeader updateHeaderInput={updateHeaderInput} setHeaderPayload={setHeaderPayload} headerPayload={headerPayload} />

                <Spacer />

                {questions?.map((question, i) => (
                    <div key={i} style={{ paddingRight: 25, paddingLeft: 25 }}>
                        <QuizItem
                            setQuestions={setQuestions}
                            question={question}
                            questionIndex={i}
                            handleRemoveQuestion={handleRemoveQuestion}
                        />
                        <Spacer />
                    </div>
                ))}

                {isEmpty && (
                    <CustomText
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginTop: 30,
                            marginBottom: 30,
                        }}
                        color={baseColors.orange.primary}
                        fontSize={14}
                        fontStyle="italic"
                    >
                        (Cliquer sur l'icône pour ajouter une nouvelle question)
                    </CustomText>
                )}

                <div className={classes.addAction}>
                    <IconButton onClick={handleAddMoreQuestion}>
                        <AddCircleIcon style={{ color: baseColors.green.primary, fontSize: 50 }} />
                    </IconButton>
                </div>

                <div className={classes.footerBtn}>
                    <CustomLoadingButton
                        loading={creating || updating}
                        disabled={disableSaveBtn}
                        label={selectedQuiz?.id ? "Modifier" : "Enregistrer"}
                        handleClick={() => {
                            if(selectedQuiz?.id){
                                const id = Number(selectedQuiz?.id);
                                handleUpdateQuiz({ id ,payload, setUpdating })
                            }else{
                                handleCreateQuiz({ payload, setCreating })
                            }
                        }}
                    />
                </div>

                <Spacer height={150} />
            </div>
            
        </div>
    );
};

export default QuizSettings;
