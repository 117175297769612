import { Box } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { sxStyles } from './styles';
import { AgendaContext } from 'src/providers/AgendaProvider';
import { frenchDateFormat } from 'src/utils/utils';
import moment from 'moment';
import { EventPlanningViewType } from 'src/interfaces/interfaces';
import { useHistory } from 'react-router-dom';
import { CustomText } from 'src/components/Text/CustomText';
import { spliDateFromUrl } from './PlanningDateLogic';
moment().format('L');

interface PlanningDateProps {
    viewType?: EventPlanningViewType['viewType'];
}

const PlanningDate: FC<PlanningDateProps> = (props) => {
    const { currentDate, setCurrentDate } = useContext(AgendaContext);
    const [date, setDate] = useState<any>('');
    const history = useHistory();

    useEffect(() => {
        setDate(frenchDateFormat(currentDate));
    }, [currentDate]);

    useEffect(() => {
        const parameter = history.location.search;
        if (parameter.length) {
            const eventDate = spliDateFromUrl(history);
            setCurrentDate(new Date(eventDate));
        } else {
            setCurrentDate(new Date());
        }
    }, []);

    return (
        <Box sx={sxStyles.title}>
            <Box sx={sxStyles.titleContent}>
                <CustomText style={{ marginBottom: 1 }} isBreefSerif isTitle>
                    Mon agenda
                </CustomText>
            </Box>
        </Box>
    );
};

export default PlanningDate;
