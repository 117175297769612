import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ArticleContext } from 'src/providers/ArticleProvider';
import ArticleService from 'src/services/Articles/article.service';
import { searchElementByName } from 'src/utils/utils';

const useArticleHooks = () => {
    const [fetchingArticles, setFetchingArticles] = useState<boolean>(false);
    const [articles, setArticles] = useState<any>([]);
    const [optimizedArticles, setOptimizedArticles] = useState<any>([]);
    const [searchedArticles, setSearchedArticles] = useState<Array<any>>([]);
    const [articleSearchKey, setArticleSearchkey] = useState<string>('');

    const { setArticles: setArticlesFromContext } = useContext(ArticleContext);

    const handleFetchArticles = () => {
        setFetchingArticles(true);

        ArticleService.getArticles()
            .then((response) => {
                const data = response?.data?.data;
                const optimized = Array.from(data, (item: { id: number; title: string }) => ({
                    ...item,
                    id: item.id,
                    name: item.title,
                }));

                if (data?.length > 0) {
                    setArticles(data);
                    setArticlesFromContext(data);
                    setOptimizedArticles(optimized);
                }
                setFetchingArticles(false);
            })
            .catch((e) => {
                setFetchingArticles(false);
                toast.error('Une erreur est survenue lors de la récupération des articles');
            });
    };

    useEffect(() => {
        setSearchedArticles(searchElementByName(optimizedArticles ?? [], articleSearchKey, 'name'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [articleSearchKey]);

    const finalArticlesData = articleSearchKey?.trim()?.length > 0 ? searchedArticles : optimizedArticles;

    return {
        fetchingArticles,
        articles,
        optimizedArticles,
        handleFetchArticles,

        searchedArticles,
        setArticleSearchkey,
        finalArticlesData,
    };
};

export default useArticleHooks;
