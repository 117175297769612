import { Grid } from '@mui/material';
import { FC, useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import { ProgramLeftCard } from './ProgramLeftCard/ProgramLeftCard';
import ProgramContent from './ProgramContent/ProgramContent';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import { PatientContext } from 'src/providers/PatientProvider';
import { useFetchUserPrograms } from 'src/hooks/useFetchUserPrograms';

export const ProgramDetails: FC = () => {
    const history = useHistory();
    const { selectedPatient } = useContext(PatientContext);
    const gotoProgramSynthesis = () => history.push('syntesis');
    const gotoProgramForm = () => history.push(`/patients/list/${selectedPatient?.id}/program/add`);
    const { preSelectedPatientProgram, fetchPreselectedProgram } = useFetchUserPrograms({
        userId: selectedPatient?.id,
    });

    useEffect(() => {
        fetchPreselectedProgram();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPatient?.id]);

    const hasProgramInProgress = preSelectedPatientProgram?.id > 0;

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Grid direction="row" container style={{ paddingTop: 20 }}>
                <ProgramLeftCard />

                <ProgramContent />
            </Grid>

            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginTop: 10,
                }}
            >
                <CustomLoadingButton
                    width="45%"
                    disabled={hasProgramInProgress}
                    handleClick={gotoProgramForm}
                    label="Nouveau programme"
                />
                <CustomLoadingButton orange width="45%" handleClick={gotoProgramSynthesis} label="Synthèse" />
            </div>
        </div>
    );
};
