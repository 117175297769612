import { FC, useEffect, useState } from 'react';
import useStyles from './styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { transpose } from 'src/utils/utils';
import { defaultPlanningHeaders } from 'src/utils/constants/fakeData';
import { dataFiltered, eventItemsData, getHeaderInfosFromData } from './PlanningContentLogic';
import DiagnosticModal from 'src/components/CustomModal/DiagnosticModal';
import WorkshopModal from 'src/components/CustomModal/WorkshopModal';
import { Box } from '@mui/material';
import EventContent from './EventContent';
import { EventPlanningItem, EventPlanningViewType } from 'src/interfaces/interfaces';
import PlanningHour from './PlanningHour';
import PlanningHeaderItem from './PlanningHeaderItem';
import useAgendaHours from 'src/hooks/useAgendaHours';

interface Column {
    id: string;
    label: string;
    minWidth?: number;
}
interface CustomPlanningAgendaTimeLineProps {
    actorStatus?: any;
    data?: any;
    hoursValues?: any;
    toggleCreateEventModal?: any;
    setSpecialityId?: any;
    setUpdateList?: Function;
    setSelectedTime?: any;
    selectedEvent?: any;
    setSelectedEvent?: any;
    viewType?: EventPlanningViewType['viewType'];
    dataForWeeklyEvent?: any;
    patientForAgendaWorkShop?: boolean;
    isHdj?: boolean;
    setLoadingPlanningEvent?: any;
    currentEventData?: any;
    setCurrentEventData?: any;
    setNewSpecialityInfos?: any;
    selectedIntervenants?: any;
    isForProgram?: boolean;
    patientId?: number;
    defaultPatient?: any;
}

const CustomPlanningAgendaTimeLine: FC<CustomPlanningAgendaTimeLineProps> = (props) => {
    const classes = useStyles();
    const {
        data,
        setUpdateList,
        setSpecialityId,
        toggleCreateEventModal,
        setSelectedTime,
        selectedEvent,
        setSelectedEvent,
        viewType,
        dataForWeeklyEvent,
        patientForAgendaWorkShop,
        isHdj,
        setLoadingPlanningEvent,
        currentEventData,
        setCurrentEventData,
        setNewSpecialityInfos,
    } = props;
    const [open, setOpen] = useState<boolean>(false);
    const toggleModal = () => setOpen(!open);
    const { handleFetchHoursData, hours: hoursValues } = useAgendaHours();
    const tableHeaders = (data && getHeaderInfosFromData(data)) || defaultPlanningHeaders;
    const eventItems = eventItemsData(data);
    const columns: Column[] = [{ id: 'hours', label: 'Horaire', minWidth: 170 }, ...tableHeaders];
    const spcFiltered = dataFiltered(eventItems, hoursValues) || [];
    const specialistsData = [hoursValues, ...spcFiltered];
    const [newEventData, setNewEventData] = useState<any>(null);
    const [currentEventSpecialityId, setCurrentEventSpecialityId] = useState<any>(0);
    const [currentEventStartAt, setCurrentEventStartAt] = useState<any>(null);
    const [currentEventEndAt, setCurrentEventEndAt] = useState<any>(null);

    // useEffect(() => {
    //     if (isPlateformCpts && isDoctor) {
    //         if (actorStatus === undefined || actorStatus.length == 0) {
    //             setPatientDefaultStatus(defaultPatient);
    //         } else {
    //             setPatientDefaultStatus(actorStatus?.isAcceptedByProfessional);
    //         }
    //     }
    // }, [patientId]);

    // if (!isPlateformCpts) {
    //     readOnly = selectedIntervenants?.length > 0 || (isForProgram && !isCoordinator);
    // } else {
    //     if (isDoctor && patientDefaultStatus == 1) {
    //         readOnly = false;
    //     } else if (isDoctor && patientDefaultStatus == 0) {
    //         readOnly = true;
    //     }
    // }

    let eventData: EventPlanningItem = {} as EventPlanningItem;
    let prevEventData: EventPlanningItem = {} as EventPlanningItem;

    useEffect(() => {
        handleFetchHoursData();
        /* setReadOnly(selectedIntervenants?.length > 0 || (isForProgram && !isCoordinator)); */
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box sx={{ height: '100%' }} className={classes.container}>
            <TableContainer sx={{ height: '100%' }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((header) => (
                                <PlanningHeaderItem key={header.id} label={header.label} />
                            ))}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {transpose(specialistsData).map((row: any, i: number) => (
                            <TableRow key={i}>
                                {row.map((cell: any, j: number) => {
                                    const weeklyEventData = tableHeaders[j - 1];
                                    eventData = cell;

                                    if (eventData?.eventId > 0) {
                                        prevEventData = row[j - 1];
                                    }

                                    return j === 0 ? (
                                        <PlanningHour key={j} hour={cell?.label} />
                                    ) : (
                                        <EventContent
                                            key={j}
                                            isHdj={isHdj}
                                            prevEventData={{ ...prevEventData }}
                                            viewType={viewType}
                                            spcData={eventData}
                                            setUpdateList={setUpdateList}
                                            setSelectedData={setSelectedEvent}
                                            toggleModal={toggleModal}
                                            setSpecialityId={setSpecialityId}
                                            toggleCreateEventModal={toggleCreateEventModal}
                                            newEventData={newEventData}
                                            setNewEventData={setNewEventData}
                                            setSelectedTime={setSelectedTime}
                                            weeklyEventData={weeklyEventData}
                                            currentEventData={currentEventData}
                                            setCurrentEventData={setCurrentEventData}
                                            setLoadingPlanningEvent={setLoadingPlanningEvent}
                                            currentEventStartAt={currentEventStartAt}
                                            currentEventEndAt={currentEventEndAt}
                                            setCurrentEventStartAt={setCurrentEventStartAt}
                                            setCurrentEventEndAt={setCurrentEventEndAt}
                                            currentEventSpecialityId={currentEventSpecialityId}
                                            setCurrentEventSpecialityId={setCurrentEventSpecialityId}
                                            setNewSpecialityInfos={setNewSpecialityInfos}
                                        />
                                    );
                                })}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {selectedEvent?.type?.typeName === 'atelier' ? (
                <WorkshopModal
                    setUpdateList={setUpdateList}
                    open={open}
                    toggleModal={toggleModal}
                    workShopData={selectedEvent}
                    dataForWeeklyEvent={dataForWeeklyEvent}
                    viewType={viewType}
                    patientForAgendaWorkShop={patientForAgendaWorkShop}
                />
            ) : (
                <DiagnosticModal
                    open={open}
                    toggleModal={toggleModal}
                    data={selectedEvent}
                    updateEventList={setUpdateList}
                    dataForWeeklyEvent={dataForWeeklyEvent}
                    viewType={viewType}
                />
            )}
        </Box>
    );
};

export default CustomPlanningAgendaTimeLine;
