import { FC } from 'react';
import { DraftConclusion } from 'src/interfaces/interfaces';
import { OpenSansRegularText } from 'src/components/Text/OpenSansRegularText';
import { BreeSerifText } from 'src/components/Text/BreeSerifText';

interface IProps {
    conclusion: DraftConclusion;
}

const ConclusionsDetailsCarePlanInformations: FC<IProps> = ({ conclusion }) => {
    const skills = conclusion?.conclusionSkills;
    const goals = conclusion?.conclusionSkills?.flatMap((skill) => skill?.goals);
    const workshops = conclusion?.conclusionWorkshops?.flatMap((item: any) => item?.workshop);
    const articles = conclusion?.conclusionVideos?.flatMap((item: any) => item?.article);

    return (
        <div
            style={{
                overflowX: 'scroll',
                maxHeight: 250,
            }}
        >
            <div
                style={{
                    flexDirection: 'row',
                    display: 'flex',
                    justifyContent: 'space-around',
                    flexWrap: 'wrap',
                }}
            >
                <div>
                    <BreeSerifText style={{ marginBottom: 10 }} fontSize={18}>
                        Compétences :{' '}
                    </BreeSerifText>
                    {skills?.map((skill: any) => (
                        <OpenSansRegularText style={{ marginBottom: 10, paddingLeft: 10 }} key={skill?.id}>
                            {skill?.name}
                        </OpenSansRegularText>
                    ))}
                </div>

                <div>
                    <BreeSerifText style={{ marginBottom: 10 }} fontSize={18}>
                        Objectifs :{' '}
                    </BreeSerifText>
                    {goals?.map((goal: any) => (
                        <OpenSansRegularText style={{ marginBottom: 10, paddingLeft: 10 }} key={goal?.id}>
                            {goal?.name ?? goal?.goal}
                        </OpenSansRegularText>
                    ))}
                </div>

                <div>
                    <BreeSerifText style={{ marginBottom: 10 }} fontSize={18}>
                        Ateliers :{' '}
                    </BreeSerifText>
                    {workshops?.map((workshop: any) => (
                        <OpenSansRegularText style={{ marginBottom: 10, paddingLeft: 10 }} key={workshop?.id}>
                            {workshop?.name}
                        </OpenSansRegularText>
                    ))}
                </div>

                <div>
                    <BreeSerifText style={{ marginBottom: 10 }} fontSize={18}>
                        Articles :{' '}
                    </BreeSerifText>
                    {articles?.map((article: any) => (
                        <OpenSansRegularText style={{ marginBottom: 10, paddingLeft: 10 }} key={article?.id}>
                            {article?.title ?? '-'}
                        </OpenSansRegularText>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ConclusionsDetailsCarePlanInformations;
