import { AttachFile } from '@mui/icons-material';
import { Grid, IconButton } from '@mui/material';
import { createRef, FC, useState } from 'react';
import { InputField } from 'src/components/InputField/InputField';
import { CustomText } from 'src/components/Text/CustomText';
import { Document } from 'src/interfaces/interfaces';
import { postDocs } from 'src/services/Documents';
import { isNotBlank } from 'src/utils/helpers/InputValidator';
import CustomModal from 'src/components/CustomModal';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import { toast } from 'react-toastify';

interface AttachFileModalProps {
    modalDocVisible: boolean;
    toggleModal?: any;
    setUpdateList?: any;
}

const defaultValue: Document = { name: '', fileContent: '' };
const defaulDoctype: string = 'application/msword';

const AttachFileModal: FC<AttachFileModalProps> = (props) => {
    const { modalDocVisible, toggleModal, setUpdateList } = props;
    const [docName, setDocName] = useState<string>('');
    const [addingNewDoc, setAddingNewDoc] = useState<boolean>(false);
    const documentRef = createRef<HTMLInputElement>();
    const [docType, setDocType] = useState<string>(defaulDoctype);
    const [document, setDocument] = useState<Document>(defaultValue);

    const handleDocumentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let files = event.currentTarget.files;
        if (files && files?.length > 0) {
            let file = files[0];
            setDocName(file.name);
            setDocType(file.type);
            let allowedExtension = [
                'image/png',
                'image/jpeg',
                'image/jpg',
                'application/pdf',
                'application/msword',
                'text/csv',
                'text/txt',
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            ];
            if (!allowedExtension.includes(file.type)) {
                alert('documents non supporté (veuiller importe des .word, .jpeg, .jpg, .png, .pdf, .csv, .txt');
            } else {
                const reader = new FileReader();

                reader.onload = (readerEvt:any) => {
                    let binaryString = readerEvt.target.result;
                    setDocument((old: Document) => ({ ...old, fileContent: 'data:' + file.type + ';base64,' + binaryString }));
                };

                reader.readAsBinaryString(file);
            }
        }
    };

    /*Commented by Gova because state 'docType' not changing after `setDocType`
    const _handleDocReaderLoaded = (readerEvt: any) => {
        let binaryString = readerEvt.target.result;
        setDocument((old: Document) => ({ ...old, fileContent: 'data:' + docType + ';base64,' + binaryString }));
    };*/

    const clearInput = () => {
        setDocument(defaultValue);
        setDocName('');
        setDocType(defaulDoctype);
    };

    const performAddDoc = () => {
        setAddingNewDoc(true);
        postDocs(document)
            .then(() => {
                toast.success('Document ajouté avec succès');
                setAddingNewDoc(false);
                setUpdateList((prev: any) => !prev);
                clearInput();
                toggleModal();
            })
            .catch(() => setAddingNewDoc(false));
    };

    return (
        <CustomModal title="Ajouter un nouveau document" open={modalDocVisible ?? false} toggleModal={toggleModal}>
            <Grid container direction={'column'} style={{ marginTop: 20 }} justifyContent={'center'}>
                <Grid item alignSelf={'center'}>
                    <IconButton onClick={() => documentRef.current?.click()}>
                        <AttachFile />
                    </IconButton>
                </Grid>
                <input ref={documentRef} type={'file'} hidden onChange={handleDocumentChange} />
                <CustomText style={{ textAlign: 'center' }} fontSize={11}>
                    {docName === '' ? 'Cliquer pour joindre un fichier' : docName}
                </CustomText>
                <InputField
                    label={''}
                    onChange={(value: any) => {
                        setDocument((old) => ({ ...old, name: value }));
                    }}
                    validator={isNotBlank}
                    errorMessage="Nom du document obligatoire"
                    placeholder={'Entrer le nom du document ...'}
                    width={400}
                    value={document.name}
                    idSuffix="nom_document"
                />
                <Grid alignSelf={'center'} item style={{ marginTop: 15 }}>
                    <CustomLoadingButton
                        disabled={document.name.trim().length === 0}
                        handleClick={performAddDoc}
                        loading={addingNewDoc}
                        width={300}
                        label={'Ajouter le document'}
                    />
                </Grid>
            </Grid>
        </CustomModal>
    );
};

export default AttachFileModal;
