import ProgramService from "src/services/Program/program.service";
import {
  RETRIEVE_PROGRAMS
} from "./types";

export const retrievePrograms = (setLoading: (val: boolean) => void ) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
  setLoading(true)
  try {

    const res = await ProgramService.getPrograms()

    dispatch({
      type: RETRIEVE_PROGRAMS,
      payload: res.data,
    });
    
    if(res.data){
      setLoading(false)
    }
  } catch (err) {
    console.log(err);
    setLoading(false)
  }
};