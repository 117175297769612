import { makeStyles } from '@mui/styles';
import {
    baseBorderRadius,
    baseColors,
    baseFontFamily,
    baseMarging,
    baseScreenHeight,
} from 'src/utils/constants/baseStyles';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: baseColors.white,
        ...baseScreenHeight.defaultHeight,
        ...baseBorderRadius.allSide,
    },
    header: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: baseMarging.marginBottom,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    title: {
        fontFamily: baseFontFamily.BreeSerif,
        marginBottom: 15,
    },
    subtitle: {
        color: baseColors.green.secondary,
    },
    filterHeader: {
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: baseColors.green.light,
        borderRadius: 7,
        padding: 7,
        width: 200,
        justifyContent: 'space-between',
    },
    filterLabel: {
        paddingLeft: 10,
    },
});

export const sxStyles: any = {
    th: {},
    td: {},
    tdContent: {},
    scrollContent: {
        overflow: 'hidden',
        overflowY: 'scroll',
        height: 55,
    },
    eventItem: {
        cursor: 'pointer',
        backgroundColor: baseColors.green.light,
        padding: 3,
        borderRadius: 2,
        marginBottom: 2,
        marginTop: 5,
    },
    date: {
        color: baseColors.black,
        fontSize: 14,
        fontWeight: 'bold',
        cursor: 'pointer',
    },
    dayName: {
        color: baseColors.black,
        fontSize: 14,
        fontWeight: 'bold',
        textAlign: 'center',
    },
};

export default useStyles;
