import { useState } from 'react';
import EvaluationFooter from './EvaluationFooter/EvaluationFooter';
import { EvaluationReportForm } from './EvaluationReportForm';
import { EvaluationReportList } from './EvaluationReportList';
import { defaultReport } from 'src/hooks/useEvaluationHooks';
import { ReportPayload } from 'src/interfaces/interfaces';

const EvaluationReport = () => {
    const [showForm, setShowForm] = useState(false);
    const [fetching, setFetching] = useState(false);
    const toggleForm = () => setShowForm((prev) => !prev);
    const [reportPayload, setReportPayload] = useState<ReportPayload>(defaultReport);

    return (
        <div style={{}}>
            <div
                style={{
                    height: 'calc(100vh - 385px)',
                    overflow: 'hidden',
                    overflowY: 'scroll',
                }}
            >
                {showForm ? (
                    <EvaluationReportForm reportPayload={reportPayload} setReportPayload={setReportPayload} />
                ) : (
                    <EvaluationReportList setFetching={setFetching} />
                )}
            </div>

            <EvaluationFooter
                fetching={fetching}
                reportPayload={reportPayload}
                setReportPayload={setReportPayload}
                showForm={showForm}
                toggleForm={toggleForm}
            />
        </div>
    );
};

export default EvaluationReport;
