import { ArrowBack } from '@mui/icons-material';
import { Grid, Link } from '@mui/material';
import { FC } from 'react';
import { useHistory } from 'react-router';
import { baseColors, baseFontFamily, baseFontSize } from 'src/utils/constants/baseStyles';
import { CustomText } from '../Text/CustomText';

interface BackButtonProps {
    size?: number;
}
export const BackButton: FC<BackButtonProps> = ({ size }) => {
    const history = useHistory();
    const goBack = () => {
        history.goBack();
    };
    return (
        <Grid style={{ width: '75%' }} container direction="row" justifyContent={'space-between'}>
            <Grid item>
                <ArrowBack onClick={goBack} htmlColor={baseColors.green.primary} />
            </Grid>
            <Grid item>
                <Link href="#" onClick={goBack}>
                    <CustomText
                        fontSize={size ?? baseFontSize.sm}
                        fontFamily={baseFontFamily.OpenSansSemiBold}
                        color={baseColors.green.primary}
                    >
                        Revenir en arrière
                    </CustomText>
                </Link>
            </Grid>
        </Grid>
    );
};
