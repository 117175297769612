import { Box } from '@mui/system';
import { FC } from 'react';
import HdjItemCard from 'src/components/HdjItemCard';
import { isOdd } from 'src/utils/utils';
import CustomLoader from 'src/components/CustomLoader/CustomLoader';
import CustomEmptyData from 'src/components/CustomEmptyData';

interface HdjContentProps {
    searchQuery: string;
    nameFilter: string;
    specialityId?: number;
    loadingHdjList?: boolean;
    dayHospitals?: any;
}

const HdjContent: FC<HdjContentProps> = (props) => {
    const { loadingHdjList, dayHospitals } = props;
    const hdjList = dayHospitals ?? [];
    const isEmpty = dayHospitals?.length === 0;

    if (isEmpty) {
        return <CustomEmptyData />;
    }

    return (
        <Box style={{}}>
            {loadingHdjList ? (
                <CustomLoader />
            ) : (
                hdjList.length > 0 &&
                hdjList.map((hdj: any, i: number) => (
                    <HdjItemCard key={hdj.id} item={hdj} isModel={hdj.date === null} isGreen={isOdd(i)} />
                ))
            )}
        </Box>
    );
};

export default HdjContent;
