import { FC, useState,useContext } from 'react';
import CustomModal from 'src/components/CustomModal';
import { Grid } from '@mui/material';
import { CustomText } from 'src/components/Text/CustomText';
import { InputField } from 'src/components/InputField/InputField';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import { toast } from 'react-toastify';
import { sendMail } from 'src/services/Patients';
import UserService from 'src/services/User/user.service';
import { UserContext } from 'src/providers/UserProvider';


interface ContactAdminModalProps {
    openContactAdminModal: boolean;
    toggleContactAdminModal: Function;
}

const ContactAdminModal: FC<ContactAdminModalProps> = (props) => {
    const { openContactAdminModal, toggleContactAdminModal } = props;
    const [sendingMail, setSendingMail] = useState(false);
    const [content, setContent] = useState('');
    const [subject, setSubject] = useState('');
    const disableBtn = content?.length === 0 || subject?.length === 0;
    const { resetPasswordEmail,setResetPasswordEmail } = useContext(UserContext);
    const title = "Contacter l'administrateur";
    
    const performSendMail = () => {
        setSendingMail(true);
        UserService.sendFeedBack({ title : subject, message: content, typeId: 1,email: resetPasswordEmail}).then(() => {
            setSendingMail(false);
            toggleContactAdminModal();
            setContent('');
            setSubject('');
            setResetPasswordEmail('');
            toast.success('Message envoyé avec succès.');
        })
        .catch(() => {
            setSendingMail(false);
            toggleContactAdminModal();
            toast.error('Message non envoyé.');
        });
    };

    return (
        <CustomModal width={500} title={title} open={openContactAdminModal} toggleModal={toggleContactAdminModal}>
            <Grid>
                <Grid
                    style={{ marginTop: 20, marginBottom: 10 }}
                    container
                    direction="row"
                    alignItems={'center'}
                    justifyContent="space-between"
                >
                    <Grid item>
                        <CustomText>Titre :</CustomText>
                    </Grid>
                    <Grid item flexGrow={1} style={{ marginLeft: '13%' }}>
                        <InputField
                            single
                            disabled={sendingMail}
                            label=""
                            onChange={(value: any) => setSubject(value)}
                            placeholder={"Objet de l'email"}
                            width={'100%'}
                            value={subject}
                            idSuffix="objet_contact_patient"
                        />
                    </Grid>
                </Grid>

                <Grid
                    container
                    direction="row"
                    alignItems={'self-start'}
                    justifyContent="space-between"
                    style={{ marginBottom: 10, marginTop: 20 }}
                >
                    <Grid item>
                        <CustomText> Message :</CustomText>
                    </Grid>
                    <Grid item flexGrow={1} style={{ marginBottom: 10, marginLeft: '8%' }}>
                        <InputField
                            height={200}
                            label=""
                            disabled={sendingMail}
                            multiline
                            placeholder="Taper votre texte"
                            onChange={(value: any) => setContent(value)}
                            width={'100%'}
                            value={content}
                            idSuffix="message_contact_patient"
                        />
                    </Grid>
                </Grid>

                <Grid container justifyContent="center">
                    <CustomLoadingButton
                        width="100%"
                        label="Envoyer message"
                        loading={sendingMail}
                        disabled={disableBtn}
                        handleClick={performSendMail}
                    />
                </Grid>
            </Grid>
        </CustomModal>
    );
};

export default ContactAdminModal;
