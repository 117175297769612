import { FeatureActionType } from "src/actions/feature/types";

interface FeatureElement{
    id: number;
    name: string;
    code: string;
    category: string;
    featureType: any;
    riskLevel: any;
}

interface FeatureState{
    listFeatures: any[];
}

const initialState: FeatureState = {
    listFeatures: [],
}


const featureReducer = (featureState: FeatureState = initialState, action: any) => {
    
    const { type, payload } = action;

    switch (type) {
        case FeatureActionType.LIST_FEATURES:
            let features: any[] = [];
            payload.map((element: any) => {
                features.push({
                    id: element.id,
                    name: element.name,
                    code: element.code,
                    category: element.category,
                    featureType: element.featureType,
                    riskLevel: element.riskLevel,
                });
                return features;
            });

            return {
                ...featureState,
                listFeatures:  features,
            }
        default:
            return featureState;
    }
}

export default featureReducer;