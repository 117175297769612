import { Box } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import CustomModal from 'src/components/CustomModal';
import HDJManagementFormCard from 'src/components/HDJManagementFormCard';
import useDebounce from 'src/hooks/useDebounce';
import { SnackBarContext } from 'src/providers/SnackBarProvider';
import { getDocs } from 'src/services/Documents';
import HdjDataService from 'src/services/Hdj/dayHospital.service';
import { reorderedData } from 'src/views/Hdj/HdjLogic';

interface ModalAddDocumentProps {
    toggleModal: any;
    modalOpen: boolean;
    hdjDocuments: any;
    fetchedHdjData?: any;
    updateList?: boolean;
    setUpdateList?: any;
    setSearchValue?: any;
}

const ModalAddDocument: FC<ModalAddDocumentProps> = (props) => {
    const { modalOpen, toggleModal, hdjDocuments, fetchedHdjData, updateList, setUpdateList, setSearchValue } = props;
    const [searchKey, setSearchKey] = useState<string>('');
    const [loadingDocs, setLoadingDocs] = useState<boolean>(false);
    const [documents, setDocuments] = useState([]);
    const [documentIds, setDocumentsIds] = useState<Array<any>>([]);
    const handleFetchDocs = async (query: string) => {
        setLoadingDocs(true);
        await getDocs(query).then((response) => {
            setDocuments(response.data.data.items);
            setLoadingDocs(false);
        });
    };

    const { debouncedResults } = useDebounce(handleFetchDocs);

    useEffect(() => {
        handleFetchDocs('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateList]);

    const reorderdItems = hdjDocuments?.length > 0 ? reorderedData(documents, hdjDocuments) : documents;
    const handleChange = (selected: any) => setDocumentsIds(Array.from(selected));
    const [updateHdjLoading, setUpdateHdjLoading] = useState(false);
    const { setShowSnackBar, setSnackBarMessage } = useContext(SnackBarContext);
    const handleSearch = (value: string) => {
        setSearchKey(value);
        setSearchValue(value);
        debouncedResults(value);
    };

    const showSnackBarMessage = () => {
        setSnackBarMessage('Document rajouté avec succès');
        setShowSnackBar(true);
    };

    const newHdjValue = {
        icon: fetchedHdjData?.icon ?? '',
        name: fetchedHdjData?.name ?? '',
        consultationCount: fetchedHdjData?.consultationCount ?? 0,
        hourCount: fetchedHdjData?.hourCount ?? 0,
        workshopIds: fetchedHdjData?.workshops && Array.from(fetchedHdjData?.workshops, (c: any) => c.id),
        consultationIds: fetchedHdjData?.consultations && Array.from(fetchedHdjData?.consultations, (c: any) => c.id),
        patientIds: fetchedHdjData?.patients && Array.from(fetchedHdjData?.patients, (d: any) => d.id),
        coordinatorId: fetchedHdjData?.coordinator?.id ?? '',
        documentIds:
            documentIds?.length > 0
                ? documentIds
                : fetchedHdjData?.patients && Array.from(fetchedHdjData?.documents, (c: any) => c.id),
    };

    const handleClick = async () => {
        setUpdateHdjLoading(true);

        if (+fetchedHdjData?.id > 0) {
            await HdjDataService.update(+fetchedHdjData?.id, newHdjValue)
                .then((response: any) => {
                    if (response) {
                        showSnackBarMessage();
                        setUpdateHdjLoading(false);
                        setUpdateList((prev: boolean) => !prev);
                        toggleModal();
                    }
                })
                .catch((e) => {
                    setUpdateHdjLoading(false);
                });
        }
    };

    return (
        <CustomModal width={300} title={'Nouveaux Documents'} toggleModal={toggleModal} open={modalOpen}>
            <Box style={{ marginTop: 20, marginBottom: 25 }}>
                <HDJManagementFormCard
                    contentHeight={225}
                    maxItems={50}
                    loading={loadingDocs}
                    labelKey={'name'}
                    items={reorderdItems}
                    selectedRows={
                        new Set(hdjDocuments?.length > 0 ? Array.from(hdjDocuments, (item: any) => item.id) : [0])
                    }
                    handleChange={(selected: Set<number>) => handleChange(selected)}
                    handleSearch={handleSearch}
                />
            </Box>

            <CustomLoadingButton
                disabled={updateHdjLoading}
                handleClick={handleClick}
                loading={updateHdjLoading}
                width={300}
                label={'Ajouter les documents'}
            />
        </CustomModal>
    );
};

export default ModalAddDocument;
