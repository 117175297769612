import { IProgramFilter, ProgramContent, ProgramItem, ProgramStatistic } from 'src/interfaces/interfaces';
import { isNull } from 'src/utils/utils';

const programAgendaInfos = (statisticData: ProgramStatistic) => [
    {
        id: 1,
        label: 'Nombre de rendez-vous',
        value: statisticData?.total ?? 0,
    },
    {
        id: 2,
        label: 'Nombre de rendez-vous annulés',
        value: statisticData?.canceled ?? 0,
    },
    {
        id: 3,
        label: 'Nombre de rendez-vous honorés',
        value: statisticData?.honored ?? 0,
    },
];

const checkProgramStateFilter = (filter: IProgramFilter) => {
    const {
        date: { start, end },
        level,
        state,
        type,
    } = filter;
    if (!isNull(start) || !isNull(end) || level.length > 1 || state > 0 || type.length > 1) {
        return true;
    }

    return false;
};

const programDetailsMenus = [
    {
        key: 1,
        label: 'Activité',
        route: `activity`,
    },
    {
        key: 6,
        label: 'Compétences',
        route: `skill`,
    },
    {
        key: 3,
        label: 'Discussions',
        route: `discussion`,
    },
    {
        key: 4,
        label: 'Agenda',
        route: `agenda`,
    },
    {
        key: 5,
        label: 'Synthèse',
        route: `synthese`,
        direct: true,
    },
];

export { programAgendaInfos, checkProgramStateFilter, programDetailsMenus };
