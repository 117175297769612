import { makeStyles } from '@mui/styles';
import { baseBorderRadius, baseColors, baseScreenHeight } from 'src/utils/constants/baseStyles';
import { padding } from '@mui/system';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: baseColors.white,
        ...baseScreenHeight.defaultHeight,
        ...baseBorderRadius.allSide,
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        marginTop: 10,
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    content:{
        marginTop: 9,
        paddingLeft:20,
        paddingBottom:10,
    },
    title: {
        textAlign: 'center',
        paddingTop:10
    },
    filter: {
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: baseColors.green.light,
        width: 300,
        borderRadius: 10,
        padding: 7,
        height:25
    },
    filterLabel:{
        paddingLeft: 20,
    },
    item: {
        // padding: '20px 10px 20px 20px'
    },
    dateContainer: {
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 20,
    },
    circleLoader:{
        marginTop:'20%',
        marginLeft:'45%',

    }
});

export default useStyles;
