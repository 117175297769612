import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { baseColors, baseScreenHeight } from '../../../../utils/constants/baseStyles';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import * as React from 'react';
import { FC, useState } from 'react';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { deletePost } from '../../../../services/Posts';
import { CustomConfirm } from '../../../../components/CustomConfirm/CustomConfirm';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, Collapse, IconButton } from '@mui/material';
import { toast } from 'react-toastify';
import { tableCellHeadText, tableCellText } from 'src/views/Security/SecurityManagement/SecurityManagement';
import CustomTagItem from 'src/components/CustomTagItem/CustomTagItem';

interface ArticleTableHeader {
    name: string;
}

interface ArticleDataRow {
    id: number;
    title: string;
    content: string;
    axis: string;
    postCategory: string;
    postType: string;
    video: string;
    image: string;
    action: any;
}

interface ArticleTableProps {
    tableHeaders: ArticleTableHeader[];
    dataRows: ArticleDataRow[];
}

interface RowProps {
    row?: any;
}

interface ArticleActionButtonsProps {
    toggleModal?: any;
    setSelectedPostData: any;
    postData: any;
    setUpdateList: any;
}

export const ArticleActionButtons: FC<ArticleActionButtonsProps> = (props) => {
    const { toggleModal, setSelectedPostData, postData, setUpdateList } = props;
    const [modalConfirmationVisible, setModalConfirmationVisible] = useState<boolean>(false);
    const [deleting, setDeleting] = useState<boolean>(false);
    const toggleModalConfirmation = () => setModalConfirmationVisible(!modalConfirmationVisible);

    const openEdit = (e: any) => {
        toggleModal();
        setSelectedPostData(postData);
    };

    const handleChange = async () => {
        setDeleting(true);
        deletePost(postData?.id)
            .then((res) => {
                setDeleting(false);
                if(res?.data?.code === 409){
                    const goals = res?.data?.data?.join(", ")
                    toast.error(`Ce contenu est déjà lié à l'objectif : ${goals}`);
                }else{
                    toast.success('Contenu supprimé avec succès');
                    setUpdateList((prev: any) => !prev);
                }
                setModalConfirmationVisible((prev: boolean) => !prev);
            })
            .catch(() => {
                setDeleting(false);
                toast.error("Erreur lors du suppression de l'article");
            });
    };

    return (
        <>
            <EditOutlinedIcon
                sx={{ fontSize: 'medium', color: baseColors.green.primary, marginRight: '10px', cursor: 'pointer' }}
                onClick={openEdit}
            />

            <DeleteOutlineOutlinedIcon
                sx={{ fontSize: 'medium', color: 'red', marginRight: '10px', cursor: 'pointer' }}
                onClick={toggleModalConfirmation}
            />

            <CustomConfirm
                defaultLoading={deleting}
                modalVisible={modalConfirmationVisible || deleting}
                updateModalVisible={setModalConfirmationVisible}
                callBackconfirm={handleChange}
                message="Voulez-vous vraiment supprimer ce contenu ?"
            />
        </>
    );
};

export const Row = (props: RowProps) => {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment>
            <TableRow
                sx={{
                    '& .MuiTableCell-root': {
                        padding: '2px',
                    },
                }}
            >
                <TableCell component="th" scope="row">
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell align="left" sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <div style={{ width: '25%', marginRight: 10 }}>{tableCellText(row.title)}</div>
                    <CustomTagItem programs={row?.goals ?? []} />
                </TableCell>
                <TableCell>{row.action}</TableCell>
            </TableRow>

            <TableRow key={row.id}>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ fontWeight: '700' }}>
                                            {tableCellText('Catégorie')}{' '}
                                        </TableCell>
                                        <TableCell style={{ fontWeight: '700' }}>{tableCellText('Type')}</TableCell>
                                        <TableCell style={{ fontWeight: '700' }}>{tableCellText('Axe')}</TableCell>
                                        <TableCell style={{ fontWeight: '700' }}>{tableCellText('Contenu')}</TableCell>
                                        {row.postType === 'Video' && (
                                            <TableCell style={{ fontWeight: '700' }}>
                                                {tableCellText('Vidéo')}
                                            </TableCell>
                                        )}
                                        {row.postType === 'Image' && (
                                            <TableCell style={{ fontWeight: '700' }}>
                                                {tableCellText('Image')}
                                            </TableCell>
                                        )}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>{tableCellText(row.postCategory)}</TableCell>
                                        <TableCell>{tableCellText(row.postType)}</TableCell>
                                        <TableCell component="th" scope="row">
                                            {tableCellText(row.axis)}
                                        </TableCell>
                                        <TableCell>{tableCellText(row.content)}</TableCell>

                                        {row.postType === 'Video' && (
                                            <TableCell>
                                                <video width="200" height="100" controls>
                                                    <source src={row?.video} type="video/mp4" />
                                                </video>
                                            </TableCell>
                                        )}

                                        {row.postType === 'Image' && (
                                            <TableCell>
                                                <img
                                                    style={{ width: 200, height: 100, objectFit:"contain",objectPosition:"left" }}
                                                    src={row?.image}
                                                    alt="content-imag"
                                                />
                                            </TableCell>
                                        )}
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
};

export const ArticleTable = (props: ArticleTableProps = { tableHeaders: [], dataRows: [] }) => {
    const tableHeaders = props.tableHeaders;
    const dataRows = props.dataRows;

    return (
        <TableContainer
            component={Paper}
            style={{ overflow: 'auto', maxHeight: baseScreenHeight.tableContainerHeight }}
        >
            <Table sx={{ minWidth: 650 }} aria-label="role table">
                <TableHead>
                    <TableRow>
                        {tableHeaders.map((header, index) => {
                            return (
                                <TableCell sx={{ color: baseColors.green.primary }}>
                                    {tableCellHeadText(header.name)}
                                </TableCell>
                            );
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {dataRows.map((row, index) => (
                        <Row key={index} row={row} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
