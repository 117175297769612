import moment from 'moment';
import React, { Dispatch, FC, SetStateAction, useContext, useState } from 'react';
import { OpenSansRegularText } from 'src/components/Text/OpenSansRegularText';
import { baseColors } from 'src/utils/constants/baseStyles';
import { localDateFormat } from 'src/utils/utils';
import { ObservationForm } from '../../Observations/Form/ObservationForm';
import { PatientContext } from 'src/providers/PatientProvider';

interface EvaluationObservationItemProps {
    observation: any;
    setUpdating: Dispatch<SetStateAction<boolean>>;
}

const EvaluationObservationItem: FC<EvaluationObservationItemProps> = (props) => {
    const { observation, setUpdating } = props;
    const [open, setOpen] = useState(false);
    const observationDate = moment(new Date(observation?.date)).format(localDateFormat);
    const { selectedPatient } = useContext(PatientContext);
    const [view, setView] = useState('details');
    const { setRead } = useContext(PatientContext);
    const handleClick = () => {
        setOpen(true);
        setRead(false);
    };

    return (
        <>
            <ObservationForm
                patientId={selectedPatient?.id ?? 0}
                open={open}
                view={view}
                observationData={observation}
                setOpen={setOpen}
                setView={setView}
                setUpdateObservationsList={setUpdating}
                readStatus={false}
            />

            <div
                style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    flexDirection: 'row',
                    padding: 10,
                    backgroundColor: baseColors.orange.light,
                    borderRadius: 10,
                    marginBottom: 10,
                    cursor: 'pointer',
                    width: '95%',
                    justifyContent: 'space-between',
                }}
                onClick={handleClick}
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        flexDirection: 'row',
                    }}
                >
                    <OpenSansRegularText style={{ textAlign: 'center', fontSize: 12 }}>
                        {observation?.title ?? "Pas d'infos"}
                    </OpenSansRegularText>
                </div>

                <OpenSansRegularText style={{ textAlign: 'end', fontSize: 10 }}>
                    <b>{observationDate}</b>
                </OpenSansRegularText>
            </div>
        </>
    );
};

export default EvaluationObservationItem;
