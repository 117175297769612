import axios from './AxiosInstance';
import { Posts } from '../interfaces/interfaces';

const getPostsList = async (filters?: any) => {
    try {
        const response = await axios.get('api/posts?paginated=false');
        const result = JSON.parse(response.request.response);
        return result.data;
    } catch (reason) {
        console.log('Get Posts List Error', reason);
    }
};

const getPostsListByPatient = async (userId?: any) => {
    try {
        const response = await axios.get(`api/posts?userId=${userId}&paginated=false`);
        const result = JSON.parse(response.request.response);
        return result.data;
    } catch (reason) {
        console.log('Get Posts List by patient Error', reason);
    }
};

const getPatientWorkshop = async (userId?: any) => {
    try {
        const response = await axios.get(`/api/programs/patients/${userId}`);
        const result = JSON.parse(response.request.response);
        return result.data;
    } catch (reason) {
        console.log('Get Patient Workshop Error', reason);
    }
};

const getPostsViews = async (userId?: any) => {
    try {
        const response = await axios.get(`api/postViews?userId=${userId}&paginated=false`);
        const result = JSON.parse(response.request.response);
        return result.data;
    } catch (reason) {
        console.log('Get Posts View Error', reason);
    }
};

const postNewPosts = (data: Posts) => {
    return axios.post('api/posts', { ...data });
};

const postPostView = (data: Posts) => {
    return axios.post('api/postViews/addForUser', { ...data });
};

const postWorkshopsView = (data: Posts) => {
    return axios.post('/api/programDetailWorkshops/validate', { ...data });
};

const getProgramTypesList = (query?: string) => {
    let additionalUrl = ''
    if(query && query?.trim()?.length > 0){
        additionalUrl += query
    }
    return axios.get(`api/programTypes${additionalUrl}`);
}

const postVideo = () => {
    return axios.post('api/batchUploads');
};

const getPosts = (postId: number) => {
    if (postId > 0) {
        return axios.get(`api/posts/${postId}`);
    }
};

const updatePost = (dataId: number, data: Posts) => {
    return axios.put(`api/posts/${dataId}`, { ...data });
};

const deletePost = (postId: number) => {
    return axios.delete(`api/posts/${postId}`);
};

const getAxisList = async () => {
    const response = await axios.get('api/axis');
    const result = JSON.parse(response.request.response);
    return result.data;
};

const getCategoryPostsList = async () => {
    const response = await axios.get('api/postCategories');
    const result = JSON.parse(response.request.response);
    return result.data;
};

const getTypePostsList = async () => {
    const response = await axios.get('api/postTypes');
    const result = JSON.parse(response.request.response);
    return result.data;
};

export const PostsService = {
    getAxisList,
    getCategoryPostsList,
    getTypePostsList,
    getPosts,
    getPostsList,
    deletePost,
    postNewPosts,
    updatePost,
    postVideo,
    getProgramTypesList,
    getPostsListByPatient,
    getPatientWorkshop,
    getPostsViews,
    postWorkshopsView,
    postPostView,
};

export {
    getAxisList,
    getCategoryPostsList,
    getTypePostsList,
    getPosts,
    getPostsList,
    deletePost,
    postNewPosts,
    updatePost,
    postVideo,
    getProgramTypesList,
    getPostsListByPatient,
    getPatientWorkshop,
    getPostsViews,
    postWorkshopsView,
    postPostView,
};
