import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { retrieveFeatures } from 'src/actions/feature/feature';
import { RootState } from 'src/stores/store';
import { baseColors } from 'src/utils/constants/baseStyles';
import FeatureTable, { FeatureActionButtons } from './FeatureTable';
import RiskIcon from './RiskIcon';
import useStyles from './styles';
import CustomCircleLoader from 'src/components/CustomLoader/CustomCircleLoader';
import CustomEmptyData from 'src/components/CustomEmptyData';
import { CustomText } from 'src/components/Text/CustomText';

const FeaturePanel = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const features = useSelector((state: RootState) => state.features.listFeatures);
    const [dataRows, setDataRows] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        dispatch(retrieveFeatures({}, setLoading));
    }, []);

    useEffect(() => {
        parseFeatureData(features);
    }, [features]);

    const tableHeaders = [
        {
            name: 'Nom',
        },
        {
            name: 'Code',
        },
        {
            name: 'Catégorie',
        },
        {
            name: 'Type',
        },
        {
            name: 'Risque',
        },
    ];

    const parseFeatureData = (features: any[]) => {
        let rows: any[] = [];

        features.forEach((feature) => {
            rows.push({
                id: feature.id,
                name: feature.name,
                code: feature.code,
                category: feature.category,
                type: feature.featureType.name,
                risk: <RiskIcon level={feature.riskLevel.id} />,
                action: <FeatureActionButtons />,
            });
            return rows;
        });

        setDataRows(rows);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                paddingX: '25px',
            }}
        >
            <Box
                sx={{
                    height: '50px',
                    marginTop: '25px',
                    marginBottom: '15px',
                    textAlign: 'center',
                }}
            >
                <CustomText isBreefSerif isTitle color={baseColors.green.primary} style={{ marginLeft: 20 }}>
                    Liste des fonctionnalités
                </CustomText>
            </Box>
            {loading ? (
                <div className={classes.loading}>
                    <CustomCircleLoader />
                </div>
            ) : dataRows?.length > 0 ? (
                <FeatureTable tableHeaders={tableHeaders} dataRows={dataRows} />
            ) : (
                <CustomEmptyData />
            )}
        </Box>
    );
};

export default FeaturePanel;
