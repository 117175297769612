import { AuthorizationActionType } from "src/actions/authorization/types";

interface AuthorizationElement{
    id: number;
    name: string;
    code: string;
    category: string;
    roles: any[];
    risk: number;
}

interface AuthorizationState{
    listAuthorizations: any[];
    listAuthorizationHeaders: any[];
    listAuthorizedSidebarMenus: any[];
    listAuthorizedContents: any[];

}

const initialState: AuthorizationState = {
    listAuthorizations: [],
    listAuthorizationHeaders: [],
    listAuthorizedSidebarMenus: [],
    listAuthorizedContents: [],

}


const authorizationReducer = (authorizationState: AuthorizationState = initialState, action: any) => {
    
    const { type, payload } = action;

    switch (type) {
        case AuthorizationActionType.LIST_AUTHORIZATIONS:
            let authorizations: any[] = [];
            const datas = payload.authorizations;
            datas.map((element: any) => {
                authorizations.push({
                    id: element.featureId,
                    name: element.featureName,
                    code: element.featureCode,
                    category: element.featureCategory,
                    roles: element.roles,
                    risk: element.featureRisk,
                });
                return authorizations;
            });

            return {
                ...authorizationState,
                listAuthorizations: authorizations,
                listAuthorizationHeaders: payload.roles
            }
        
        case AuthorizationActionType.LIST_AUTHORIZED_SIDEBAR_MENUS:
            let sidebarMenuCodes: any[] = [];
            payload.map((element: any) => {
                sidebarMenuCodes.push(element);
                return sidebarMenuCodes;
            });

            return {
                ...authorizationState,
                listAuthorizedSidebarMenus:  sidebarMenuCodes,
            }
          case AuthorizationActionType.LIST_AUTHORIZED_CONTENTS:
            let contentsCodes: any[] = [];
            payload.map((element: any) => {
                contentsCodes.push(element);
                return contentsCodes;
            });

            return {
                ...authorizationState,
                listAuthorizationContents:  contentsCodes,
            }
        default:
            return authorizationState;
    }
}

export default authorizationReducer;