import { useContext, useState } from 'react';
import { UserAxisItem } from 'src/interfaces/interfaces';
import { EducationalDiagnosis, EducationalDiagnosisVisio } from 'src/interfaces/types';
import { PatientContext } from 'src/providers/PatientProvider';
import EducationalDiagnosisService from 'src/services/DiagnosticEducatif';
import { PatientService } from 'src/services/Patients';

export const defaultVisioValue: EducationalDiagnosisVisio = {
    id: 0,
    duration: 0,
    iframe: '',
    meetingAt: '',
    moderatorToken: '',
    name: '',
    uniqid: '',
    url: '',
};

type IProps = {
    patientId: number;
    axisId?: number;
};

const useEducationalDiagnosis = ({ patientId, axisId }: IProps) => {
    const [fetching, setFetching] = useState<boolean>(false);
    const [fetchingAxis, setFetchingAxis] = useState<boolean>(false);
    const [fetchingDEResponses, setFetchingDEResponses] = useState<boolean>(false);
    const [fetchingConclusions, setFetchingConclusions] = useState<boolean>(true);
    const [fetchingDraftConclusion, setFetchingDraftConclusion] = useState<boolean>(true);
    const [fetchingSurveyByAxis, setFetchingSurveyByAxis] = useState<boolean>(true);

    const [noDEResponsesFound, setNoDEResponsesFound] = useState<boolean>(true);
    const [conclusions, setConclusions] = useState<any[]>([]);
    const [draftConclusion, setDraftConclusion] = useState<any>({});
    const [surveyByAxis, setSurveyByAxis] = useState<any>({});

    const [userDEResponses, setuserDEResponses] = useState<Array<UserAxisItem>>([]);
    const [axis, setAxis] = useState<Array<any>>([]);
    const [slicedAxis, setSlicedAxis] = useState<Array<any>>([]);
    const [educationalDiagnosis, setEducationalDiagnosis] = useState<EducationalDiagnosis>({
        id: 0,
    });

    const { setConclusionList } = useContext(PatientContext);

    const [educationalDiagnosisVisio, setEducationalDiagnosisVisio] =
        useState<EducationalDiagnosisVisio>(defaultVisioValue);

    const fetchEducationalDiagnosis = () => {
        setFetching(true);
        EducationalDiagnosisService.getCurrentEducationalDiagnosis(+patientId)
            .then((response) => {
                const data = response.data?.data?.visioconferenceEvent?.visioconference;
                if (data?.id > 0) {
                    setEducationalDiagnosisVisio({
                        id: data?.id,
                        duration: data?.duration,
                        iframe: data?.iframe,
                        meetingAt: data?.meetingAt,
                        moderatorToken: data?.moderatorToken,
                        name: data?.name,
                        uniqid: data?.uniqid,
                        url: data?.url,
                    });
                }
                setEducationalDiagnosis(response.data?.data);
                setFetching(false);
            })
            .catch((e) => {
                setFetching(false);
            });
    };

    const fetchPatientDEResponses = () => {
        setFetchingDEResponses(true);
        PatientService.getSpecificUserAxis({
            axisId: axisId ?? 1,
            userId: patientId,
            DEId: educationalDiagnosis?.id || undefined,
        })
            .then((res) => {
                const data = res?.data?.data;
                const optimizedData = Array.from(data, (item: UserAxisItem) => ({
                    ...item,
                    id: item.id,
                    survey: item.survey,
                    precision: item.precision,
                    response: item.response,
                    userSurveyId: item.userSurveyId,
                    valueTrame: item.valueTrame,
                    surveyParentId: item?.survey?.id,
                    surveyName: item?.survey?.text,
                }));
                setNoDEResponsesFound(optimizedData?.length === 0);
                setuserDEResponses(optimizedData);
                setFetchingDEResponses(false);
            })
            .catch(() => {
                setFetchingDEResponses(false);
            });
    };

    const fetchAllAxis = () => {
        setFetchingAxis(true);
        PatientService.getAxis()
            .then((response) => {
                const data = response?.data?.data;
                const optimizedData = Array.from(data, (item: { id: number; name: string }) => ({
                    id: item.id,
                    value: item.id,
                    label: item.name,
                }));
                setAxis(optimizedData);
                setSlicedAxis(optimizedData?.slice(0, 4));
                setSurveyByAxis(data);
                setFetchingAxis(false);
            })
            .catch(() => setFetchingAxis(false));
    };

    const fetchSurveyByAxis = (axisId: number) => {
        setFetchingSurveyByAxis(true);
        PatientService.getSurveyByAxis(axisId)
            .then((response) => {
                const data = response?.data?.data;
                setSurveyByAxis(data);
                setFetchingSurveyByAxis(false);
            })
            .catch((err: any) => {
                console.log({ Err: err });
                setFetchingSurveyByAxis(false);
            });
    };

    const fetchConclusions = () => {
        setFetchingConclusions(true);
        PatientService.getConclusions({ patientId })
            .then((response: any) => {
                const conclusionData = response?.data?.data?.items;
                setConclusions(conclusionData);
                setConclusionList(conclusionData);
                setFetchingConclusions(false);
            })
            .catch(() => setFetchingConclusions(false));
    };

    const fetchDraftConclusion = () => {
        setFetchingDraftConclusion(true);
        PatientService.getConclusions({ patientId, isDraft: 1 })
            .then((response: any) => {
                const data = response?.data?.data?.items;
                const draftConclusion = data[data?.length - 1];
                setDraftConclusion(draftConclusion);
                setFetchingDraftConclusion(false);
            })
            .catch(() => setFetchingDraftConclusion(false));
    };

    return {
        fetching,
        fetchEducationalDiagnosis,
        educationalDiagnosis,
        educationalDiagnosisVisio,

        fetchPatientDEResponses,
        fetchingDEResponses,
        userDEResponses,
        noDEResponsesFound,

        fetchAllAxis,
        axis,
        slicedAxis,
        fetchingAxis,

        fetchConclusions,
        fetchingConclusions,
        conclusions,

        fetchDraftConclusion,
        fetchingDraftConclusion,
        draftConclusion,

        fetchSurveyByAxis,
        fetchingSurveyByAxis,
        surveyByAxis,
    };
};

export default useEducationalDiagnosis;
