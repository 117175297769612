import * as React from 'react';
import { baseColors } from 'src/utils/constants/baseStyles';

function IconFilter(props: any) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" viewBox="0 0 20 20" {...props}>
            <path
                fill={baseColors.green.primary}
                d="M19.167 15.833h-2.22a2.91 2.91 0 00-2.78-2.083c-1.317 0-2.42.883-2.781 2.083H.833a.834.834 0 000 1.667h10.553a2.91 2.91 0 002.78 2.083c1.317 0 2.42-.882 2.782-2.083h2.219a.833.833 0 100-1.667zm-5 2.084a1.251 1.251 0 010-2.5 1.251 1.251 0 010 2.5zm5-15.417h-2.22a2.912 2.912 0 00-2.78-2.083A2.91 2.91 0 0011.386 2.5H.833a.834.834 0 000 1.667h10.553a2.912 2.912 0 002.78 2.083c1.317 0 2.42-.882 2.782-2.083h2.219a.833.833 0 100-1.667zm-5 2.083a1.25 1.25 0 110-2.5 1.25 1.25 0 010 2.5zm5 4.584H8.614a2.911 2.911 0 00-2.78-2.083 2.91 2.91 0 00-2.781 2.083H.833a.834.834 0 000 1.667h2.22a2.911 2.911 0 002.78 2.083c1.317 0 2.42-.883 2.781-2.083h10.553a.833.833 0 100-1.667zM5.833 11.25a1.251 1.251 0 010-2.5 1.251 1.251 0 010 2.5z"
            />
        </svg>
    );
}

export default IconFilter;
