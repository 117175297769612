import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { baseColors, baseFontWeight } from 'src/utils/constants/baseStyles';
import Button from '@mui/material/Button';

interface CustomNewUserProps {
    btnLabel: string;
    toggleModal: any;
    marginRight?: boolean;
}

const CustomNewUserBtn: FC<CustomNewUserProps> = (props) => {
    const { btnLabel, toggleModal, marginRight } = props;

    return (
        <Box sx={{ width: '40%' }} marginRight={marginRight ? 2 : undefined}>
            <Button
                onClick={toggleModal}
                style={{
                    width: '100%',
                    color: baseColors.white,
                    backgroundColor: baseColors.green.primary,
                    borderColor: 'transparent',
                }}
                variant="outlined"
                size="large"
            >
                <Typography style={{ textTransform: 'uppercase' }} fontWeight={baseFontWeight.semiBold} fontSize={10}>
                    {btnLabel}
                </Typography>
            </Button>
        </Box>
    );
};

export default CustomNewUserBtn;
