import axios from './AxiosInstance';

const getUserHistories = (filters?: any) => {
        return axios.get('api/userAudits?paginated=false').then((response:any) => {
            const result = JSON.parse(response.request.response);
            return result.data;
        }).catch((reason) => {
            console.log('Get User Histories Error', reason);
        });
}

const UserHistoryService = {
    getUserHistories: getUserHistories
}

export default UserHistoryService;