import { FC } from 'react';
import { toast } from 'react-toastify';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import { sendMailtoUser } from '../../../../services/User/user.service';
import { ServerError } from 'src/utils/constants/constants';
import useStyles from './style';

interface ContactFormBtnProps {
    patientId?: number;
    triggerCallback?: Function;
    contact: any;
    contactData?: any;
    setOpen: any;
    saving: boolean;
    setContact: any;
    setSaving: Function;
    setActiveInput?: Function;
    defaultContact: any;
    setPrevSelectedUserId?: any;
}

export const ContactFormBtn: FC<ContactFormBtnProps> = (props) => {
    const {
        triggerCallback,
        contact,
        contactData,
        setOpen,
        saving,
        setContact,
        defaultContact,
        setSaving,
        setActiveInput,
        setPrevSelectedUserId,
    } = props;
    const classes = useStyles();
    const successfull = () => {
        setActiveInput?.(false);
        setContact(defaultContact);
        triggerCallback?.();
        setOpen(false);
        toast.success('Mail envoyé avec succès .');
        setPrevSelectedUserId([]);
        setSaving(false);
    };
    const failed = () => {
        toast.error(ServerError.unknown);
        setSaving(false);
    };

    const performSendMail = () => {
        setSaving(true);
        sendMailtoUser(contactData)
            .then(() => successfull())
            .catch(() => failed());
    };

    const canSubmit = () => contact?.subject.length > 0 && contact?.content.length > 0;

    return (
        <div className={classes.flexCenter}>
            <CustomLoadingButton
                loading={saving}
                disabled={saving || !canSubmit()}
                label="ENVOYER"
                handleClick={performSendMail}
            />
        </div>
    );
};
