import { FC } from 'react';
import { Typography } from '@mui/material';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';

interface TitleProps {
    title: string;
}

const Title: FC<TitleProps> = (props) => {
    const { title } = props;
    return (
        <Typography
            style={{ marginBottom: 20 }}
            fontFamily={baseFontFamily.BreeSerif}
            fontSize={17}
            color={baseColors.black}
        >
            {title}
        </Typography>
    );
};

export default Title;
