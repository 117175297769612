import { Workshop } from 'src/interfaces/interfaces';
import axios from '../AxiosInstance';

type GetWorkShopParams = {
    forPatientProgram?: boolean;
    searchKey?: string;
    programId?: number;
    isDraft ?: boolean;
};

const getWorkshops = ({ forPatientProgram = false, searchKey, programId,isDraft }: GetWorkShopParams) => {
    let additionalUrl: string = '';

    if (forPatientProgram) {
        additionalUrl += '&regroupIfHasModule=true';
    }

    if (programId) {
        additionalUrl += `&programId=${programId}`;
    }

    if (searchKey) {
        additionalUrl += `&keywords=${searchKey}`;
    }
    if (typeof isDraft !=="undefined" && typeof isDraft === 'boolean') {
        additionalUrl += `&isDraft=${isDraft}`;
    }

    return axios.get('api/workshops?paginated=false&oderBy=DESC' + additionalUrl);
};

const searchWorkshops = (keyword: any) => {
    return axios.get(`api/workshops?paginated=false&keywords=${keyword}`);
};

const postWorkshop = (data: Workshop) => axios.post('api/workshops', { ...data });

const deleteWorkshop = (workshopId: number) => axios.delete(`api/workshops/${workshopId}`);

const updateWorkshop = (payload: Workshop) => axios.put(`api/workshops/${payload?.id}`, { ...payload });

const startLiveWorkshop = (workshopId: number) => axios.post(`api/workshops/${workshopId}/startVisioconference`);

const getProfessionalGroupedBySpeciality = () => axios.get('api/professionals/specialityType?isExternal=0');

const WorkshopService = {
    getProfessionalGroupedBySpeciality,
    postWorkshop,
    getWorkshops,
    searchWorkshops,
    updateWorkshop,
    deleteWorkshop,
    startLiveWorkshop,
};

export default WorkshopService;
