import axios from './AxiosInstance';

export interface PostSpecialitiesPayload {
    name: string;
    abbreviation: string;
}

const GetSpecialities = () => axios.get('api/specialityTypes');

const PostSpecialities = (payload: PostSpecialitiesPayload) =>
    axios.post('api/specialityTypes', {
        ...payload,
    });

const PutSpecialities = ({ specialityId, payload }: { payload: PostSpecialitiesPayload; specialityId: number }) =>
    axios.put(`api/specialityTypes/${specialityId}`, { ...payload });

const DeleteSpecialities = (specialityId: number) => axios.delete(`api/specialityTypes/${specialityId}`);

const SpecialityService = {
    GetSpecialities,
    PostSpecialities,
    PutSpecialities,
    DeleteSpecialities,
};

export default SpecialityService;
