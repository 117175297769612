import { FC, useContext, useEffect, useState } from 'react';
import useStyles from './styles';
import { Box } from '@mui/material';
import { CustomText } from 'src/components/Text/CustomText';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import { ProgramFormHeader } from '../Form/Header/ProgramFormHeader';
import { ProgramContext } from 'src/providers/ProgramProvider';
import { ProgramItem } from 'src/interfaces/interfaces';
import ProgramService from 'src/services/Program/program.service';
import { Switch, useHistory, Route, useRouteMatch } from 'react-router-dom';
import IconObesite from 'src/components/CustomIcon/IconObesite';
import { ProgramNavBar } from '../Navbar/ProgramNavBar';
import { GlobalActivity } from './GlobalActivity/GlobalActivity';
import { Process } from './Process/Process';
import { Goals } from './Goals/Goals';
import { Dispensation } from './Dispensation/Dispensation';
import { Evaluation } from './Evaluation/Evaluation';
import { RouteLeavingGuard } from 'src/components/RouterPrompt/RouteLeavingGuard';
import { toast } from 'react-toastify';
import CustomCircleLoader from 'src/components/CustomLoader/CustomCircleLoader';
import { UserContext } from 'src/providers/UserProvider';
import { programSyntheseMenus } from './ProgramSyntheseLogic';

export const ProgramSynthese: FC = () => {
    const classes = useStyles();
    const history = useHistory();
    const { url } = useRouteMatch();
    const {
        currentProgram,
        syntheseHasChange,
        setSyntheseHasChange,
        syntheseChangeSaveCallback,
        setsyntheseChangeSaveCallback,
    } = useContext(ProgramContext);
    const { isCoordinator } = useContext(UserContext);
    const getSubUrl = () => url.split('/').slice(0, 3).join('/');
    const [loading, setLoading] = useState(false);
    const [selectedMenu, setSelectedMenu] = useState<number>(1);
    const [program, setProgram] = useState<ProgramItem | undefined>(currentProgram);

    useEffect(() => {
        if (!currentProgram) {
            setLoading(true);
            const splitted = history.location.pathname.split('/');
            if (splitted.length === 4) {
                const id = parseInt(splitted[2]);
                ProgramService.getProgramById(id)
                    .then((response) => {
                        const programItem = response.data?.data;
                        if (programItem) {
                            setProgram(programItem);
                        }
                        setLoading(false);
                    })
                    .catch((e) => {
                        setLoading(false);
                        toast.error('Programme introuvable');
                    });
            }
        }
    }, []);

    const onSelect = (menuItem: any) => {
        // setSelectedMenu(menuItem.key);
        history.push(`${menuItem.route}`);
    };

    useEffect(() => {
        const key = url.split('/')[3];
        const found = programSyntheseMenus.filter((m: any) => m.route === key);
        if (found.length > 0) {
            const menuItem = found[0];
            setSelectedMenu(menuItem.key);
        }
    }, [url]);

    return (
        <Box className={classes.container}>
            {loading ? (
                <div className={classes.row} style={{ justifyContent: 'center', marginTop: '25%' }}>
                    <CustomCircleLoader />
                </div>
            ) : (
                <div>
                    <CustomText
                        style={{ textAlign: 'center' }}
                        fontSize={22}
                        fontFamily={baseFontFamily.BreeSerif}
                        color={baseColors.black}
                    >
                        Programmes
                    </CustomText>

                    {program && <ProgramFormHeader isSynthesis icon={<IconObesite />} program={program} />}
                    {program && (
                        <>
                            <ProgramNavBar
                                fullWidth
                                items={programSyntheseMenus}
                                selected={selectedMenu}
                                onSelect={onSelect}
                            />

                            <RouteLeavingGuard
                                callBackCancel={() => {
                                    setSyntheseHasChange(false);
                                    setsyntheseChangeSaveCallback(() => {});
                                }}
                                when={true}
                                navigate={(path: any) => {
                                    history.push(path);
                                }}
                                shouldBlockNavigation={(location: any) => {
                                    if (syntheseHasChange && isCoordinator) {
                                        return true;
                                    }
                                    return false;
                                }}
                                callBackconfirm={syntheseChangeSaveCallback}
                            />
                            <div className={classes.wrapper}>
                                <Switch>
                                    <Route path={`${getSubUrl()}/synthese`}>
                                        <GlobalActivity />
                                    </Route>
                                    <Route path={`${getSubUrl()}/process`}>
                                        <Process programId={program.id} />
                                    </Route>
                                    <Route path={`${getSubUrl()}/goals`}>
                                        <Goals programId={program.id} />
                                    </Route>
                                    <Route path={`${getSubUrl()}/dispense`}>
                                        <Dispensation programId={program.id} />
                                    </Route>
                                    <Route path={`${getSubUrl()}/evaluation`}>
                                        <Evaluation programId={program.id} />
                                    </Route>
                                </Switch>
                            </div>
                        </>
                    )}
                </div>
            )}
        </Box>
    );
};
