import {
  RETRIEVE_CONSULTATION_FILTERS,
} from "src/actions/dayHospitals/types";

const initialState: any = [];

const consultationReducer = (consultations = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {

    case RETRIEVE_CONSULTATION_FILTERS : 
      return payload;

    default:
      return consultations;
  }
};

export default consultationReducer;