import { Box } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import CustomModal from 'src/components/CustomModal';
import ListItemFormCheckBox from 'src/components/ListItemFormCheckBox';
import { searchElementByName } from 'src/utils/utils';
import { allOptionName, allOptions } from 'src/views/MyAgenda/Informations/FilterContent/FilterLogic';

interface SpecialityFiltersProps {
    toggleModal: any;
    open: boolean;
    specialities: any;
    setSelectedSpecialities?: any;
    selectedSpecialities?: any;
    loading?: boolean;
    posting?: boolean;
    title?: string;
}

const SpecialityFilters: FC<SpecialityFiltersProps> = (props) => {
    const { title, open, toggleModal, specialities, selectedSpecialities, setSelectedSpecialities, loading, posting } =
        props;
    const [searchKey, setSearchKey] = useState<string>('');
    const [specialityIds, setSpecialityIds] = useState<Array<any>>([]);
    const [filteredSpecialities, setFilteredSpecialities] = useState<Array<any>>([]);
    const items = searchKey?.trim()?.length ? filteredSpecialities : specialities;

    useEffect(() => {
        if (selectedSpecialities?.length) {
            setSpecialityIds(selectedSpecialities);
        }
    }, [selectedSpecialities]);

    const disabled = !specialityIds?.length;
    const allItems = [{ ...allOptions, name: allOptionName(specialityIds) }, ...items];

    const handleChange = (selected: any) => {
        const checkedIds = Array.from(selected);
        const allIds = Array.from(allItems, (item: any) => item.id);
        const selectAllItems = checkedIds?.includes(-1);
        const value = selectAllItems ? [...allIds] : checkedIds;
        setSpecialityIds(value);
    };

    const handleSearch = (value: string) => {
        setSearchKey(value);
        setFilteredSpecialities(searchElementByName(specialities, value, 'name'));
    };

    const handleValidate = async () => {
        const checkedUsers = specialityIds?.filter((item) => item !== 0) ?? [];
        setSelectedSpecialities?.(checkedUsers);
        toggleModal();
    };

    const resetSpecialityIds = () => {
        setSpecialityIds([])
    }

    const newValues = new Set(specialityIds);

    return (
        <CustomModal
            title={title ?? 'Choisir de(s) spécialité(s)'}
            toggleModal={toggleModal}
            open={open}
            clearFormData={resetSpecialityIds}
            footer={
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <CustomLoadingButton
                        disabled={posting || loading || disabled}
                        handleClick={handleValidate}
                        loading={posting}
                        width={350}
                        label="Valider"
                    />
                </div>
            }
        >
            <Box style={{ marginTop: 20, marginBottom: 25 }}>
                <ListItemFormCheckBox
                    contentHeight={225}
                    maxItems={50}
                    loading={loading}
                    labelKey="name"
                    items={allItems}
                    selectedRows={newValues}
                    handleChange={(selected: Set<number>) => handleChange(selected)}
                    handleSearch={handleSearch}
                />
            </Box>
        </CustomModal>
    );
};

export default SpecialityFilters;
