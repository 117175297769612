import { Typography } from '@mui/material';
import React, { FC } from 'react';
import { baseColors, baseFontFamily, baseFontSize } from 'src/utils/constants/baseStyles';

interface OpenSansRegularTextProps {
    children: any;
    fontSize?: number | string;
    color?: string;
    style?: React.CSSProperties;
}

export const OpenSansRegularText: FC<OpenSansRegularTextProps> = (props) => {
    const { children, fontSize, color, style } = props;

    return (
        <Typography
            style={style}
            fontSize={fontSize ?? baseFontSize.mini}
            color={color ?? baseColors.black}
            fontFamily={baseFontFamily.OpenSansRegular}
        >
            {children}
        </Typography>
    );
};
