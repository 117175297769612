import { IconButton, Radio } from '@mui/material';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { InputField } from 'src/components/InputField/InputField';
import { ChoiceRespone } from 'src/interfaces/types';
import { baseColors } from 'src/utils/constants/baseStyles';
import { handleChooseRadioItem, handleSetResponseData } from './QuizSettingsLogic';
import useStyles from './styles';
import DeleteIcon from '@mui/icons-material/Delete';

type QuizRadioItemProps = {
    response: ChoiceRespone;
    responseIndex: number;
    setSelectedElement: Dispatch<SetStateAction<number>>;
    selectedElement: number;
    setResponses: Dispatch<SetStateAction<ChoiceRespone[]>>;
    handleRemoveResponse: Function;
};

type InputType = {
    textResponse: string;
};

const QuizRadioItem: FC<QuizRadioItemProps> = ({
    response,
    responseIndex,
    setSelectedElement,
    selectedElement,
    setResponses,
    handleRemoveResponse,
}) => {
    const classes = useStyles();

    const [value, setValue] = useState<InputType>({
        textResponse: '',
    });

    useEffect(() => {
        if (response?.textResponse) setValue((prev: InputType) => ({ ...prev, textResponse: response?.textResponse }));
    }, [response]);

    const handleChange = (keyValue: string, text: string) =>
        setValue((prev: InputType) => ({ ...prev, [keyValue]: text }));

    return (
        <div className={classes.radioItem}>
            <Radio
                disabled={false}
                sx={{
                    color: baseColors.green.primary,
                    '&.Mui-checked': {
                        color: baseColors.orange.primary,
                    },
                }}
                className={classes.radio}
                checked={selectedElement === responseIndex || false}
                name="radio-buttons"
                inputProps={{ 'aria-label': 'A' }}
                size="small"
                onChange={() => handleChooseRadioItem({ setSelectedElement, setResponses, responseIndex })}
            />

            <div className={classes.radioTextInput}>
                <InputField
                    withoutLabel
                    label=""
                    disabled={false}
                    errorMessage="Label incorrect"
                    placeholder="Label du choix"
                    height={40}
                    width={200}
                    onChange={(text: string) => {
                        handleChange('textResponse', text);
                        handleSetResponseData({
                            responseIndex,
                            setResponses,
                            textValue: text,
                        });
                    }}
                    value={value?.textResponse}
                />
            </div>

            <IconButton className={classes.removeBtn} onClick={() => handleRemoveResponse(responseIndex)}>
                <DeleteIcon style={{ color: baseColors.orange.primary }} />
            </IconButton>
        </div>
    );
};

export default QuizRadioItem;
