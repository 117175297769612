import { makeStyles } from '@mui/styles';
import { baseColors } from 'src/utils/constants/baseStyles';

const useStyles = makeStyles({
    container: {},
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    valideBtn: {
        backgroundColor: baseColors.green.primary,
        padding: '10px 20px',
        cursor: 'pointer',
        borderRadius: 7,
        marginLeft: 10,
        marginTop: -15,
    },
});

export const sxStyles: any = {
    editIcon: {
        fontSize: 25,
        color: baseColors.green.primary,
        cursor: 'pointer',
        marginLeft: 2,
    },
    delete: {
        fontSize: 25,
        color: 'red',
        cursor: 'pointer',
        marginLeft: 2,
    },
    iconContainer: {
        marginTop: -20,
    },
};

export default useStyles;
