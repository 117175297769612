import { Grid } from '@mui/material';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { InputField } from 'src/components/InputField/InputField';
import { PatientRegistrationPayload } from 'src/interfaces/interfaces';
import { isNotBlank } from 'src/utils/helpers/InputValidator';

interface PatientFormInputProps {
    label: string;
    placeholder: string;
    keyValue: string;
    lockProfessionalInput?: boolean;
    validator?: Function;
    setPatient: Dispatch<SetStateAction<PatientRegistrationPayload>>;
    maxLength?: number;
    errorMessage?: string;
    upper?: boolean;
    defaultValue?: string;
    required?: boolean;
}

const PatientFormInput: FC<PatientFormInputProps> = ({
    label,
    keyValue,
    placeholder,
    setPatient,
    lockProfessionalInput,
    validator,
    maxLength,
    errorMessage,
    upper,
    defaultValue,
    required
}) => {
    const [value, setValue] = useState('');
    const handleChange = (text: string) => {
        const stringValue = text === 'NaN' ? '0' : text;
        setValue(stringValue);
        setPatient((prev) => ({ ...prev, [keyValue]: stringValue }));
    };

    useEffect(() => {
        setValue(defaultValue ?? '');
    }, [defaultValue]);

    return (
        <Grid item style={{ flex: 1 }}>
            <InputField
                upper={upper}
                width="95%"
                label={label + ' :'}
                disabled={lockProfessionalInput}
                validator={validator ?? isNotBlank}
                errorMessage={errorMessage}
                placeholder={placeholder}
                onChange={handleChange}
                value={value}
                maxLength={maxLength ?? undefined}
                idSuffix={'patient' + label}
                required={required ? true : false}
            />
        </Grid>
    );
};

export default PatientFormInput;
