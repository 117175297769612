import { Grid } from '@mui/material';
import { FC, useContext } from 'react';
import { CustomText } from 'src/components/Text/CustomText';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import ProgramDateRow from './ProgramDateRow';
import ProgramWorkshopRow from './ProgramWorkshopRow';
import ProgramObjectifRow from './ProgramObjectifRow';
import { ProgramContext } from 'src/providers/ProgramProvider';

const ProgramContent: FC = (props) => {
    const { checkedProgram, checkedModule } = useContext(ProgramContext);

    return (
        <Grid item xs={8} style={{ height: '100%' }}>
            <Grid container direction="row" justifyContent="center">
                <CustomText fontSize={18} fontFamily={baseFontFamily.BreeSerif} color={baseColors.black}>
                    {checkedProgram?.programName ?? ''}
                </CustomText>
                <CustomText fontSize={18} fontFamily={baseFontFamily.BreeSerif} color={baseColors.green.primary}>
                    {checkedModule?.name ?? ''}
                </CustomText>
            </Grid>

            <Grid item>
                {checkedProgram && <ProgramDateRow checkedProgram={checkedProgram} />}

                <ProgramWorkshopRow />

                <ProgramObjectifRow />
            </Grid>
        </Grid>
    );
};

export default ProgramContent;
