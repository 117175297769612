import { FC, useState } from 'react';
import { baseColors } from 'src/utils/constants/baseStyles';
import { Typography } from '@mui/material';
import useStyles from './styles';
import { Box } from '@mui/system';
import Radio from '@mui/material/Radio';
import EventService from 'src/services/Calendar/events.service';
import CustomCircleLoader from 'src/components/CustomLoader/CustomCircleLoader';
import { toast } from 'react-toastify';
import { OperationMessage } from 'src/utils/constants/constants';
import { CustomRadio } from 'src/components/InputField/CustomRadio';

interface PatientParticipantItemProps {
    data: any;
    header?: boolean;
    eventId?: number;
    setUpdateList?: any;
    participants?: any;
    readOnly?: boolean;
    isExternalDoctor?: boolean;
}

const PatientParticipantItem: FC<PatientParticipantItemProps> = (props) => {
    const { data, header, eventId, setUpdateList, participants, readOnly, isExternalDoctor } = props;
    const classes = useStyles();
    const [updating, setUpdating] = useState<boolean>(false);
    const leftContent = header ? '' : data?.participant;
    const disableBtn = isExternalDoctor || readOnly;
    const presenceHeader = ['Présent', 'Absent'];
    const handleUpdatePresence = async (value: number) => {
        setUpdating(true);
        const prevParticipantsPresence = participants?.filter(
            (participant: any) => +participant.userId !== +data?.userId,
        );

        const unchangedValue = prevParticipantsPresence?.map((item: any) => ({
            id: item.userId,
            present: Number(item.present),
        }));

        const dataForUpdate = {
            presences: [
                {
                    id: data?.userId ?? 0,
                    present: value ?? 0,
                },
                ...unchangedValue,
            ],
        };

        if (eventId) {
            await EventService.updatePresence(eventId, dataForUpdate)
                .then((res) => {
                    toast.success(OperationMessage.success);
                    setUpdating(false);
                    setUpdateList?.((prev: boolean) => !prev);
                })
                .catch((err) => {
                    setUpdating(false);
                });
        }
    };

    return (
        <Box className={classes.container} marginBottom={1}>
            <div className={classes.left}>
                <Typography fontSize={13} color={baseColors.black}>
                    {leftContent}
                </Typography>
            </div>
            <div className={classes.right}>
                {header ? (
                    <>
                        {presenceHeader.map((title, i) => (
                            <div key={i} className={classes.leftBtn}>
                                <Typography fontSize={13} color={baseColors.black}>
                                    {title}
                                </Typography>
                            </div>
                        ))}
                    </>
                ) : updating ? (
                    <Box sx={{ marginLeft: 8 }}>
                        <CustomCircleLoader userInfos height={10} width={50} />
                    </Box>
                ) : (
                    <>
                        <CustomRadio
                            disabled={disableBtn}
                            color={baseColors.orange.primary}
                            checked={data?.present}
                            onClick={() => handleUpdatePresence(1)}
                            style={{flex: 1, textAlign: 'center',}}
                        />
                        <CustomRadio
                            disabled={disableBtn}
                            color={baseColors.orange.primary}
                            checked={!data?.present}
                            onClick={() => handleUpdatePresence(0)}
                            style={{flex: 1, textAlign: 'center',}}
                        />
                    </>
                )}
            </div>
        </Box>
    );
};

export default PatientParticipantItem;
