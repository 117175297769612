import { makeStyles } from '@mui/styles';
import { baseColors } from '../../../../../utils/constants/baseStyles';

const useStyles = makeStyles({
    divider: {
        width: '80%',
        marginBottom: 10,
        background: baseColors.green.secondary,
    },
    item: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
});

export default useStyles;
