import React, { useContext } from 'react';
import { CustomText } from 'src/components/Text/CustomText';
import { useFetchUserPrograms } from 'src/hooks/useFetchUserPrograms';
import { PatientContext } from 'src/providers/PatientProvider';
import { baseColors, baseFontFamily, baseFontSize } from 'src/utils/constants/baseStyles';

interface IProgramItem {
    id: number;
    title: string;
}

const ProgramItem = (props: { program: IProgramItem }) => {
    const { program } = props;
    return (
        <div
            style={{
                padding: 7,
                backgroundColor: baseColors.white,
                borderRadius: 7,
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                marginRight: 5,
            }}
        >
            <CustomText color={baseColors.green.primary} fontSize={10} fontFamily={baseFontFamily.OpenSansSemiBold}>
                {program?.title ?? ''}
            </CustomText>
        </div>
    );
};

const PatientsDetailsHeaderPrograms = () => {
    const { selectedPatient } = useContext(PatientContext);
    const patientId = selectedPatient?.id ?? 0;

    const { preSelectedPatientProgram, userPrograms, fetchingUserPrograms, fetchingPreselectedProgram } =
        useFetchUserPrograms({
            userId: patientId,
        });

    const hasPreselectedProgram = preSelectedPatientProgram?.id > 0;
    const hasProgram = userPrograms?.length > 0;
    const programs = hasProgram
        ? userPrograms
        : hasPreselectedProgram
        ? [
              {
                  id: preSelectedPatientProgram?.id,
                  program: { id: preSelectedPatientProgram?.id, title: preSelectedPatientProgram?.name },
              },
          ]
        : [];
    const loading = fetchingPreselectedProgram || fetchingUserPrograms;
    const isEmpty = !hasProgram && !hasPreselectedProgram;

    return (
        <div>
            {loading ? (
                <CustomText
                    style={{}}
                    color={baseColors.white}
                    fontFamily={baseFontFamily.BreeSerif}
                    fontSize={baseFontSize.mini}
                >
                    Chargement en cours...
                </CustomText>
            ) : isEmpty ? (
                <CustomText
                    style={{
                        textAlign: 'center',
                    }}
                    color={baseColors.white}
                    fontFamily={baseFontFamily.BreeSerif}
                    fontSize={baseFontSize.mini}
                >
                    ( Aucun programme lié au patient )
                </CustomText>
            ) : (
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    {programs?.map((item: { id: number; program: IProgramItem }) => (
                        <ProgramItem key={item.id} program={item?.program} />
                    ))}
                </div>
            )}
        </div>
    );
};

export default PatientsDetailsHeaderPrograms;
