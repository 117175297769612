import { baseColors } from 'src/utils/constants/baseStyles';

function SvgComponent(props: any) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" viewBox="0 0 20 20" {...props}>
            <path
                fill={baseColors.green.primary}
                d="M10.254 10.69a38.541 38.541 0 01-1.988-1.716c-.165.704-.393 1.617-.574 2.332.749-.27 1.615-.461 2.582-.572l-.056-.042.036-.002z"
            />
            <path
                fill={baseColors.green.primary}
                d="M16.97 3.506L13.615.15a.515.515 0 00-.364-.15H3.395a.515.515 0 00-.516.516v18.969c0 .284.23.515.516.515h13.21c.285 0 .516-.23.516-.515V3.87a.515.515 0 00-.151-.364zM14.893 12l-.008.043c-.142.564-.526.884-1.053.884-.334 0-.621-.136-.676-.163l-.027-.015a28.377 28.377 0 01-1.78-1.215c-1.106.058-2.668.253-3.931.844l-.07.267c-.411 1.515-1.218 1.679-1.546 1.679a.698.698 0 01-.146-.013l-.045-.013a.739.739 0 01-.451-.418c-.162-.396.042-.861.084-.95l.067-.102c.354-.405.806-.76 1.346-1.06.247-.962.672-2.638.86-3.508-.218-.215-.346-.348-.373-.376-.515-.572-.707-1.078-.578-1.51.123-.41.503-.61.708-.663a.887.887 0 01.89.239c.305.336.415.985.325 1.93a1.43 1.43 0 01-.01.076c.591.563 1.75 1.624 3.148 2.678.212-.008.425-.011.634-.011.7 0 1.17.039 1.256.046.01 0 .737.022 1.14.495.194.228.275.517.236.836zm-2.098-7.393a.26.26 0 01-.261-.261v-3.12l3.38 3.381h-3.119z"
            />
        </svg>
    );
}

export default SvgComponent;
