import axios from './AxiosInstance';

const getAuthorizations =  (filters?: any) => {

    return axios.get('api/authorizations?withPrivileges=true').then((response:any) => {
         const result = JSON.parse(response.request.response);
        return result.data;
    }).catch((reason) => {
        console.log('Get User Authorizations Error', reason);
    });
}


const getAuthorizedSidebarMenus =  (filters?: any) => {

    return axios.get('api/authorizations/sidebarMenus').then((response:any) => {
         const result = JSON.parse(response.request.response);
        return result.data;
    }).catch((reason) => {
        console.log('Get Authorized sidebar menus error', reason);
    });
}

const getAuthorizedContents =  (filters?: any) => {

    return axios.get('api/authorizations/contents').then((response:any) => {
         const result = JSON.parse(response.request.response);
        return result.data;
    }).catch((reason) => {
        console.log('Get Authorized contents error', reason);
    });
}


const updateFeatureAuthorization =  (data: any) => {

    return axios.put('api/authorizations/features', data);
}
const AuthorizationService = {
    getAuthorizations,
    getAuthorizedSidebarMenus,
    getAuthorizedContents,
    updateFeatureAuthorization
};


export default AuthorizationService;