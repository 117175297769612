import { FC } from 'react';
import { Grid, Typography } from '@mui/material';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';

interface InfoItemProps {
    label: string;
    value: string | undefined | null;
    valueColor?: string;
    icon?: React.ReactNode;
    href?: string;
}

const InfoItem: FC<InfoItemProps> = (props) => {
    const { label, value, valueColor, icon, href } = props;

    return (
        <Grid style={{ marginBottom: 10 }} container direction={'row'}>
            <Typography fontFamily={baseFontFamily.OpenSansSemiBold} fontSize={15} color={baseColors.black}>
                {`${label} : `}
            </Typography>
            <Typography
                style={{ marginLeft: 4 }}
                fontFamily={valueColor ? baseFontFamily.OpenSansSemiBold : baseFontFamily.OpenSansRegular}
                fontSize={15}
                color={valueColor || baseColors.black}
            >
                {` ${value}`}
            </Typography>
            <div style={{ marginLeft: 10 }}>
                <a href={href} download>
                    {icon}
                </a>
            </div>
        </Grid>
    );
};

export default InfoItem;
