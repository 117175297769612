import { useState, FC } from 'react';
import { createContext } from 'react';

type ArticleProviderType = {
    articles: any[];
    setArticles: (val: any) => void;
};

export const ArticleContext = createContext<ArticleProviderType>({
    articles: [],
    setArticles: () => {},
});

const ArticleProvider: FC = (props) => {
    const { children } = props;
    const [articles, setArticles] = useState<any[]>([]);

    return (
        <ArticleContext.Provider
            value={{
                articles,
                setArticles,
            }}
        >
            {children}
        </ArticleContext.Provider>
    );
};

export default ArticleProvider;
