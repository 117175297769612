import axios from '../AxiosInstance';

const getArticles = () => {
    let additionalUrl: string = '';

    return axios.get('api/posts?paginated=false' + additionalUrl);
};

const ArticleService = {
    getArticles,
};

export default ArticleService;
