import { baseColors } from 'src/utils/constants/baseStyles';

function NotificationActif(props: any) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={26} height={26} fill="none" viewBox="0 0 26 26" {...props}>
            <path
                fill={baseColors.green.primary}
                d="M11.9 26a4.068 4.068 0 01-4.063-4.063.813.813 0 011.625 0 2.44 2.44 0 002.438 2.438 2.44 2.44 0 002.437-2.438.813.813 0 011.625 0A4.067 4.067 0 0111.9 26z"
            />
            <path
                fill={baseColors.green.primary}
                d="M20.837 22.75H2.962a1.898 1.898 0 01-1.233-3.337.745.745 0 01.087-.065 7.28 7.28 0 002.5-5.492v-3.023c0-4.181 3.403-7.583 7.584-7.583.173 0 .36.003.534.033a.813.813 0 01.668.934.817.817 0 01-.935.668c-.086-.014-.182-.01-.267-.01a5.965 5.965 0 00-5.959 5.958v3.023a8.91 8.91 0 01-3.144 6.793c-.017.013-.03.025-.048.037a.268.268 0 00-.058.168c0 .147.124.271.271.271h17.875a.274.274 0 00.271-.27.259.259 0 00-.058-.169c-.017-.012-.03-.024-.047-.037a8.908 8.908 0 01-3.145-6.793v-1.18a.813.813 0 011.625 0v1.18c0 2.11.911 4.107 2.504 5.496.029.02.057.04.083.063a1.898 1.898 0 01-1.233 3.335z"
            />
            <path
                fill={baseColors.orange.primary}
                stroke="#fff"
                strokeWidth={1.5}
                d="M24.145 5.415a4.665 4.665 0 11-9.33 0 4.665 4.665 0 019.33 0z"
            />
        </svg>
    );
}

export default NotificationActif;
