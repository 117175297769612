import { FC, useState, useEffect, useContext } from 'react';
import useStyles from './styles';
import { Box } from '@mui/material';
import { baseFontSize } from 'src/utils/constants/baseStyles';
import NotificationItem from 'src/components/NotificationItem';
import NotificationService from 'src/services/Notifications';
import { Spacer } from 'src/components/Spacer/Spacer';
import { CustomSelect } from 'src/components/InputField/CustomSelect';
import { CustomText } from 'src/components/Text/CustomText';
import CustomDatePicker from 'src/components/CustomDatePicker';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/stores/store';
import { retrieveNotifications } from 'src/actions/notification/notification';
import { useHistory } from 'react-router';
import { formatDateFormat } from 'src/utils/utils';
import CustomCircleLoader from 'src/components/CustomLoader/CustomCircleLoader';
import { notificationTypes } from 'src/utils/constants/constants';
import { SearchInputField } from '../../components/InputField/SearchInputField';
import { AgendaContext } from 'src/providers/AgendaProvider';
import { PatientContext } from 'src/providers/PatientProvider';
import { toast } from "react-toastify";

const Notification: FC = () => {
    const [dateFilter, setDateFilter] = useState<Date | null>(null);
    const [notificationTypesData, setnotificationTypesData] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const dispatch = useDispatch();
    const filteredNotifications = useSelector((state: RootState) => state.notifications.listNotifications);
    const notifications = filteredNotifications?.filter((notif) => notif?.action !== undefined);
    let history = useHistory();
    const [searchQuery, setSearchQuery] = useState<any>('');
    const { setCurrentDate } = useContext(AgendaContext);
    const classes = useStyles();
    const { setObservationId } = useContext(PatientContext);

    const notificationClick = (value: any, id: number, eventDate: any, action: any, observationId: number) => {
        if (!id) {
            throw new Error('No Event ID provided');
        }

        if (
            value === 'event' &&
            action !== 'Annulation' &&
            //action !== 'Changement rendez-vous' &&
            action !== 'Suppression évènement'
        ) {
            if(eventDate){
                setCurrentDate(new Date(eventDate));
                history.push(`/agenda?eventDate=${eventDate}&eventId=${id}`);
            }else{
                toast.error("La date de cet événement n'est pas connue")
            }
        } else if (value === 'event' && action === 'Annulation') {
            // Ne rien faire
        } /*else if (value === 'event' && action === 'Changement rendez-vous') {
            // Ne rien faire
        } */else if (value === 'event' && action === 'Suppression évènement') {
            // Ne rien faire
        } else if (value === 'discussion') {
            history.push(`/programmes/${id}/manage/discussion`);
        } else if (value === 'observation') {
            history.replace(`/patients/list/${id}/observation`);
            setObservationId(observationId);
            /* fetchObservation(id); */
        } else if (value === 'program') {
            history.push(`/programmes/${id}/manage/activity`);
        } else if (value === 'report') {
            history.push(`/patients/list/${id}/diagnostic`);
        } else if (value === 'educative_diagnosis') {
            history.push(`/patients/list/${id}/diagnostic`);
        } else {
        }
    };

    const filters = {
        filterDate: dateFilter ? formatDateFormat(dateFilter) : null,
        filterType: notificationTypesData,
        filterText: searchQuery,
    };

    const handleChangeNotificationType = (id: number, setValue: any) => setValue(id);

    useEffect(() => {
        dispatch(retrieveNotifications(filters, setLoading));
    }, [dateFilter, notificationTypesData, searchQuery]);

    const items = [{ id: -1, label: 'Sélectionner le type' }, ...notificationTypes];

    const handleClickNotification = (notification: any) => () => {
        console.log(notification,"notificationss")
        NotificationService.updateNotificationBehaviour(notification.id, {
            activated: 0,
        }).then(() => {
            dispatch(retrieveNotifications());
        });

        notificationClick(
            notification.urlType,
            notification?.urlParameter,
            notification?.eventDate,
            notification.action,
            notification.observationId,
        );
    };

    return (
        <Box className={classes.container}>
            <CustomText style={{ textAlign: 'center' }} isBreefSerif isTitle>
                Notifications
            </CustomText>

            <div className={classes.row}>
                <div className={classes.row}>
                    <Box className={classes.content}>
                        <CustomSelect
                            dontUseBoldFont
                            fontSize={baseFontSize.sm}
                            onChange={(value: number) => handleChangeNotificationType(value, setnotificationTypesData)}
                            labelKey="label"
                            label=""
                            items={items}
                            width={250}
                        />
                    </Box>

                    <Spacer width={50} />

                    <Box className={classes.content}>
                        <CustomDatePicker
                            height={46}
                            likeSelect
                            width={250}
                            validator
                            setValue={setDateFilter}
                            value={dateFilter}
                        />
                    </Box>
                </div>
            </div>
            <Box
                style={{
                    overflowY: 'scroll',
                }}
            >
                {loading ? (
                    <div className={classes.circleLoader}>
                        <CustomCircleLoader />
                    </div>
                ) : notifications.length > 0 ? (
                    <Box
                        className={classes.item}
                        style={{
                            overflow: 'hidden',
                            overflowY: 'scroll',
                            padding: '0 18px 0 18px',
                        }}
                    >
                        {notifications.map((notification: any, i: number) => (
                            <NotificationItem
                                key={i}
                                item={notification}
                                onClick={handleClickNotification(notification)}
                            />
                        ))}
                    </Box>
                ) : (
                    <CustomText style={{ textAlign: 'center', marginTop: '15%' }} fontSize={25}>
                        Aucune notification trouvée.
                    </CustomText>
                )}
            </Box>
        </Box>
    );
};

export default Notification;
