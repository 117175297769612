import { baseColors } from 'src/utils/constants/baseStyles';

const IconRoleAutorisation = (props: any) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={27}
            height={27}
            fill={baseColors.green.primary}
            viewBox="0 0 27 27"
            {...props}
        >
            <path
                d="M19.14,7.82,11.63.92a1.79,1.79,0,0,0-2.32,0L1.64,7.55a1.79,1.79,0,0,0-.28,2.31l5.79,8.39a1.45,1.45,0,0,0,1,.64,1.44,1.44,0,0,0,1.15-.35l3.2-2.78,8.73,10a1.63,1.63,0,0,0,.92.49l2,.23a.74.74,0,0,0,.59-.17.79.79,0,0,0,.25-.56l0-2A1.48,1.48,0,0,0,24.8,23a1.37,1.37,0,0,0-.83-.31l-1.53-.05.16-1.52a1.22,1.22,0,0,0-.14-.81,1.19,1.19,0,0,0-.78-.25L20.15,20v0l.15-1.48a1.49,1.49,0,0,0-.32-1L15.93,12.8,19.13,10a1.45,1.45,0,0,0,0-2.2Zm-7.9,2.7a3.07,3.07,0,1,1,.26-4.3A3,3,0,0,1,11.24,10.52Z"
                transform="translate(-1.06 -0.47)"
                fill={baseColors.green.primary}
            />
            <circle cx="8.16" cy="7.74" r="2.79" fill="#fff" />
        </svg>
    );
};

export default IconRoleAutorisation;
