
export enum AuthorizationActionType{
    LIST_AUTHORIZATIONS = 'LIST_AUTHORIZATIONS',
    LIST_AUTHORIZED_SIDEBAR_MENUS= 'LIST_AUTHORIZED_SIDEBAR_MENUS',
    LIST_AUTHORIZED_CONTENTS= 'LIST_AUTHORIZED_CONTENTS',
}

export interface AuthorizationList{
    type: AuthorizationActionType.LIST_AUTHORIZATIONS;
    payload: any;
}

export interface SidebarMenuCodeList{
    type: AuthorizationActionType.LIST_AUTHORIZED_SIDEBAR_MENUS;
    payload: any;
}

export interface ContentCodeList{
    type: AuthorizationActionType.LIST_AUTHORIZED_CONTENTS;
    payload: any;
}


export type AuthorizationAction = AuthorizationList | SidebarMenuCodeList | ContentCodeList;