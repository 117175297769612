import { Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import CustomModal from 'src/components/CustomModal';
import { CustomText } from 'src/components/Text/CustomText';
import { DetailAuthorization } from './AuthorizationPanel';

export interface ViewModalProps {
    open: boolean;
    data: DetailAuthorization;
}
const viewModalProps: ViewModalProps = {
    open: false,
    data: {
        featureName: '',
        category: '',
        code: '',
        risk: '',
        userPermission: '',
        patientPermission: '',
        doctorPermission: '',
        coordinatorPermission: '',
        adminPermission: '',
    },
};
export const ViewModal = (props: ViewModalProps = viewModalProps) => {
    const data = props.data;
    let open = props.open;
    const [openView, setOpenView] = useState<boolean>(false);

    useEffect(() => {
        if (open) {
            setOpenView(true);
        } else {
            setOpenView(false);
        }
    }, [open]);

    const handleViewOpen = () => {
        setOpenView(true);
    };

    const handleViewClose = () => {
        setOpenView(false);
    };

    return (
        <>
            <CustomModal width={500} title={'Details autorisation'} open={openView} toggleModal={handleViewClose}>
                <Grid>
                    <Grid
                        style={{ marginTop: 20, marginBottom: 10 }}
                        lg={12}
                        container
                        direction="row"
                        alignItems={'center'}
                        justifyContent="space-between"
                    >
                        <Grid item xs>
                            <CustomText style={{ marginTop: 10 }}>Nom de la fonctionnalité :</CustomText>
                        </Grid>
                        <Grid item xs>
                            <Typography>{data.featureName}</Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        style={{ marginTop: 20, marginBottom: 10 }}
                        lg={12}
                        container
                        direction="row"
                        alignItems={'center'}
                        justifyContent="space-between"
                    >
                        <Grid item xs>
                            <CustomText style={{ marginTop: 10 }}>Categorie :</CustomText>
                        </Grid>
                        <Grid item xs>
                            <Typography> {data.category} </Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        style={{ marginTop: 20, marginBottom: 10 }}
                        lg={12}
                        container
                        direction="row"
                        alignItems={'center'}
                        justifyContent="space-between"
                    >
                        <Grid item xs>
                            <CustomText style={{ marginTop: 10 }}>Code :</CustomText>
                        </Grid>
                        <Grid item xs>
                            <Typography> {data.code} </Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        style={{ marginTop: 20, marginBottom: 10 }}
                        lg={12}
                        container
                        direction="row"
                        alignItems={'center'}
                        justifyContent="space-between"
                    >
                        <Grid item xs>
                            <CustomText style={{ marginTop: 10 }}>Risque :</CustomText>
                        </Grid>
                        <Grid item xs>
                            <Typography> {data.risk}</Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        style={{ marginTop: 20, marginBottom: 10 }}
                        lg={12}
                        container
                        direction="row"
                        alignItems={'center'}
                        justifyContent="space-between"
                    >
                        <Grid item xs>
                            <CustomText style={{ marginTop: 10 }}>Utilisateur :</CustomText>
                        </Grid>
                        <Grid item xs>
                            <Typography> {data.userPermission} </Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        style={{ marginTop: 20, marginBottom: 10 }}
                        lg={12}
                        container
                        direction="row"
                        alignItems={'center'}
                        justifyContent="space-between"
                    >
                        <Grid item xs>
                            <CustomText style={{ marginTop: 10 }}> Patient :</CustomText>
                        </Grid>
                        <Grid item xs>
                            <Typography> {data.patientPermission} </Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        style={{ marginTop: 20, marginBottom: 10 }}
                        lg={12}
                        container
                        direction="row"
                        alignItems={'center'}
                        justifyContent="space-between"
                    >
                        <Grid item xs>
                            <CustomText style={{ marginTop: 10 }}>Soignant :</CustomText>
                        </Grid>
                        <Grid item xs>
                            <Typography> {data.doctorPermission} </Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        style={{ marginTop: 20, marginBottom: 10 }}
                        lg={12}
                        container
                        direction="row"
                        alignItems={'center'}
                        justifyContent="space-between"
                    >
                        <Grid item xs>
                            <CustomText style={{ marginTop: 10 }}>Coordinateur :</CustomText>
                        </Grid>
                        <Grid item xs>
                            <Typography> {data.coordinatorPermission} </Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        style={{ marginTop: 20, marginBottom: 10 }}
                        lg={12}
                        container
                        direction="row"
                        alignItems={'center'}
                        justifyContent="space-between"
                    >
                        <Grid item xs>
                            <CustomText style={{ marginTop: 10 }}>Administrateur :</CustomText>
                        </Grid>
                        <Grid item xs>
                            <Typography> {data.adminPermission} </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </CustomModal>
        </>
    );
};
