import { FC, useContext, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { PatientForm } from './PatientForm';
import { ImportPatientsForm } from './ImportPatientsForm';
import { BrowserRouter as Router, Route, Switch, useParams, useHistory, useLocation } from 'react-router-dom';
import { Patient } from 'src/interfaces/interfaces';
import { PatientService } from 'src/services/Patients';
import { PatientContext } from 'src/providers/PatientProvider';
import { sxStyles } from './styles';
import PatientListCpts from './PatientListCpts';
import { PatientDetailsCpts } from './PatientsDetails/PatientsDetailsCpts';
import { CustomText } from 'src/components/Text/CustomText';
import PatientPageLoader from 'src/components/PatientPageLoader/PatientPageLoader';
import { baseFontSize } from 'src/utils/constants/baseStyles';
import { useFetchPatientRQHooks } from 'src/hooks/ReactQueryHooks/usePatientRQHooks';
import { OpenSansSemiBold } from 'src/components/Text/OpenSansSemiBold';
import { Spacer } from 'src/components/Spacer/Spacer';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { handleRedirectPatientUrl } from './PatientsUtils';

interface ParamTypes {
    id: string;
    page: string;
}

const Patients: FC = () => {
    const params = useParams<ParamTypes>();
    const history = useHistory();
    const location = useLocation();
    const [activePatient, setActivePatient] = useState<Patient | undefined>(undefined);
    const [loading, setLoading] = useState(true);
    const [updated, setUpdate] = useState(false);
    const [fetchingPatientData, setFetchingPatientData] = useState(false);
    const [defaultActivePatientId, setDefaultActivePatientId] = useState<number>(0);
    const {
        setSelectedPatient,
        setCurrentPatientId,
        justAddedPatient,
        setAxis,
        currentLocationPathname,
        setActiveLoadingIndicator,
    } = useContext(PatientContext);
    const [listPatientData, setListPatientData] = useState([]);
    const [isActor, setIsActor] = useState([]);
    const [isEditionRoute, setIsEditionRoute] = useState(true);

    const handleFetchAxis = () =>
        PatientService.getAxis().then((response) => {
            const responses = response.data.data;
            if (responses && responses.length > 0) {
                setAxis(responses);
            }
        });

    useEffect(() => {
        handleFetchAxis();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // on blur patients page, clear currentPatientID
    useEffect(() => {
        return () => {
            setCurrentPatientId(0);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (parseInt(params?.id) > 0) {
            setDefaultActivePatientId(parseInt(params?.id));
        }
    }, [params]);

    useEffect(() => {
        if (currentLocationPathname) {
            setIsEditionRoute(currentLocationPathname?.includes('edit'));
        }
    }, [currentLocationPathname]);

    const selectedPatient = {
        ...activePatient,
        id: defaultActivePatientId > 0 ? defaultActivePatientId : activePatient?.id,
    };

    const { data: patients, isLoading: fetchingPatients } = useFetchPatientRQHooks({ setActiveLoadingIndicator });

    const isLoading = fetchingPatients;

    useEffect(() => {
        handleRedirectPatientUrl({
            setSelectedPatient,
            history,
            patients: patients ?? [],
            params,
            justAddedPatient,
            location,
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history, location, params?.id, patients, updated, justAddedPatient]);

    const hasNoSelectedPatient = !selectedPatient?.id;

    return (
        <Box sx={sxStyles.container}>
            <Box flex={2} minWidth={300} marginRight={1}>
                <PatientListCpts
                    loading={isLoading}
                    patients={patients ?? []}
                    setLoading={setLoading}
                    setFetchingPatientData={setFetchingPatientData}
                    onSelectedPatient={setActivePatient}
                    defaultActivePatient={selectedPatient?.id ?? 0}
                    setListPatient={setListPatientData}
                    setIsCoActor={setIsActor}
                    selectedPatient={selectedPatient}
                />
            </Box>

            <Box flex={10}>
                <Router>
                    <Switch>
                        <Route path={`/patients/list/:id`}>
                            {isLoading ? (
                                <PatientPageLoader />
                            ) : !hasNoSelectedPatient ? (
                                <PatientDetailsCpts
                                    setLoading={setLoading}
                                    setFetchingPatientData={setFetchingPatientData}
                                    patientInfo={selectedPatient}
                                    defaultPatient={listPatientData}
                                    isActor={isActor}
                                    loading={isLoading}
                                    defaultActivePatientId={defaultActivePatientId}
                                />
                            ) : (
                                <CustomText
                                    style={{ textAlign: 'center', marginTop: 230, fontSize: baseFontSize.title }}
                                >
                                    {hasNoSelectedPatient ? (
                                        <>
                                            <AssignmentIndIcon style={{ height: 200, width: 200 }} />
                                            <Spacer />
                                            <OpenSansSemiBold fontSize={20}>Sélectionner un patient</OpenSansSemiBold>
                                        </>
                                    ) : (
                                        'Aucun patient trouvé'
                                    )}
                                </CustomText>
                            )}
                        </Route>

                        <Route path={`/patients/add`}>
                            <PatientForm setUpdate={setUpdate} />
                        </Route>
                        <Route path={`/patients/:id/edit`}>
                            {isEditionRoute ? (
                                <PatientForm setUpdate={setUpdate} />
                            ) : (
                                <PatientDetailsCpts
                                    setFetchingPatientData={setFetchingPatientData}
                                    setLoading={setLoading}
                                    loading={loading || fetchingPatientData}
                                    patientInfo={selectedPatient}
                                    defaultPatient={listPatientData}
                                    isActor={isActor}
                                />
                            )}
                        </Route>
                        <Route path={`/patients/import`} component={ImportPatientsForm}></Route>
                    </Switch>
                </Router>
            </Box>
        </Box>
    );
};

export default Patients;
