import { makeStyles } from '@mui/styles';
import {
    baseBorderRadius,
    baseColors,
    baseFontFamily,
    baseFontSize,
    baseMarging,
    baseScreenHeight,
} from 'src/utils/constants/baseStyles';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: baseColors.white,
        ...baseScreenHeight.defaultHeight,
        ...baseBorderRadius.allSide,
    },
    header: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: baseMarging.marginBottom,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    title: {
        fontFamily: baseFontFamily.BreeSerif,
        marginBottom: 15,
    },
    subtitle: {
        color: baseColors.green.secondary,
    },
    filterHeader: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginRight: 15,
        marginBottom: 5,
        marginTop: 5,
    },
    filterLabel: {
        paddingLeft: 10,
        cursor: 'pointer',
    },
    prevBtn: {
        fontWeight: 'bold',
        cursor: 'pointer',
    },
    nextBtn: {
        fontWeight: 'bold',
        cursor: 'pointer',
    },
    dateValue: {
        marginLeft: 5,
        marginRight: 5,
    },
    space: {
        marginTop: 5,
    },
});

export const sxStyles: any = {
    container: { flex: 1 / 2, padding: 1, marginBottom: 0 },
    titleContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    title: {
        flex: 8,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    titleContent: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    createEventBtn: {
        color: baseColors.white,
        backgroundColor: baseColors.green.primary,
        borderColor: 'transparent',
        padding: '15px 30px 15px 30px',
    },
    specialityItems: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        alignItems: 'center',
    },
    viewType: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        // backgroundColor: baseColors.green.light,
        borderRadius: 5,
    },
    viewTypeContent: {
        cursor: 'pointer',
        padding: '8px 5px 8px 5px',
        width: 80,
    },
    viewTypeLabel: {
        fontSize: baseFontSize.sm,
        textAlign: 'center',
    },
    dateContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
};

export default useStyles;
