import { IconButton, Paper } from '@mui/material';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import { QuestionType } from 'src/interfaces/types';
import QuizTypeCheckBox from './QuizTypeCheckBox';
import QuizTypeRadio from './QuizTypeRadio';
import QuizTypeTextField from './QuizTypeTextField';
import useStyles from './styles';
import DeleteIcon from '@mui/icons-material/Delete';
import { CustomSelect } from 'src/components/InputField/CustomSelect';
import { quizTypes } from 'src/utils/constants/constants';
import { Spacer } from 'src/components/Spacer/Spacer';
import { handleSetQuestionData,checkQuestionTypeId } from './QuizSettingsLogic';
import { CustomConfirm } from 'src/components/CustomConfirm/CustomConfirm';

type QuizItemProps = {
    question: QuestionType;
    questionIndex: number;
    setQuestions: Dispatch<SetStateAction<QuestionType[]>>;
    handleRemoveQuestion: Function;
};

const QuizItem: FC<QuizItemProps> = ({ handleRemoveQuestion, questionIndex, question, setQuestions }) => {
    const classes = useStyles();
    const [showConfirm, setShowConfirm] = useState<boolean>(false);
    const defaultResponseType = question?.responseType ? checkQuestionTypeId(question?.responseType) : 0;
    const textQuestion = question?.textQuestion ?? '';
    return (
        <Paper elevation={7} className={classes.questionItem}>
            <div className={classes.remove}>
                <div className={classes.content} style={{}}>
                    <CustomSelect
                        onChange={(value: number) =>
                            handleSetQuestionData({ questionIndex, setQuestions, questionType: value })
                        }
                        label=""
                        labelKey={'label'}
                        items={quizTypes}
                        defaultValue={defaultResponseType}
                        idSelect="quiz-type"
                    />
                </div>
                <IconButton className={classes.removeBtn} onClick={() => setShowConfirm(true)}>
                    <DeleteIcon style={{ color: 'red' }} />
                </IconButton>
            </div>

            <Spacer />

            <div>
                {question?.responseType === 'text' && (
                    <QuizTypeTextField setQuestions={setQuestions} questionIndex={questionIndex} responsesList={question?.responses} textQuestion={textQuestion} />
                )}
                {question?.responseType === 'radio' && (
                    <QuizTypeRadio setQuestions={setQuestions} questionIndex={questionIndex} responsesList={question?.responses} textQuestion={textQuestion} />
                )}
                {question?.responseType === 'checkbox' && (
                    <QuizTypeCheckBox setQuestions={setQuestions} questionIndex={questionIndex} responsesList={question?.responses} textQuestion={textQuestion} />
                )}
            </div>

            <CustomConfirm
                modalVisible={showConfirm}
                updateModalVisible={setShowConfirm}
                callBackconfirm={() => handleRemoveQuestion(questionIndex)}
                message="Etes-vous sûr de vouloir supprimer la quiz ?"
            />
        </Paper>
    );
};

export default QuizItem;
