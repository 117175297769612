import { makeStyles } from '@mui/styles';
import { baseColors } from '../../utils/constants/baseStyles';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: "row",
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 20,
        cursor: 'pointer'
    },
    label: {
        color: baseColors.black,
    },
    programme: {
        color: baseColors.orange.primary,
    },
    date: {
        color: baseColors.green.secondary,
    },
});

export default useStyles;
