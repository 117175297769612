import { Typography, Box, Divider } from '@mui/material';
import { FC } from 'react';
import { checkIfEditableWorkshop } from 'src/utils/utils';
import useStyles from './styles';
import { baseColors } from 'src/utils/constants/baseStyles';

interface ScheduledWorkShopItemProps {
    label: string;
    programme: string;
    date: string;
    checked:boolean;
}

const ScheduledWorkShopItem: FC<ScheduledWorkShopItemProps> = (props) => {
    const { label, programme, date,checked } = props;
    const classes = useStyles();
    const handleClick = () => {
        const clickable = checkIfEditableWorkshop(date);
        if (clickable) {
            console.log('editable');
        } else {
            console.log('not editable');
        }
    };
    const givenDate = new Date(date);
    const currentDate = new Date();
    const isToday = givenDate.toDateString() === currentDate.toDateString();

    const ItemsComponents = () => {
        return <>
            <Box onClick={handleClick} className={classes.container}>
                <Typography fontSize={14} className={classes.label}>
                    {label}
                </Typography>
                <Typography fontSize={14} className={classes.programme}>
                    {programme}
                </Typography>
                <Typography fontSize={12} className={classes.date}>
                    {date}
                </Typography>
                
            </Box>
            <Divider
                    style={{
                        width: '100%',
                        marginBottom: 20,
                        background: baseColors.green.secondary,
                    }}
                />
            </>
    }
    return (
        <>
        {!checked && isToday && (
            <ItemsComponents/>
        )}

        {checked && (
            <ItemsComponents/>
        )}
        </>
    );
};

export default ScheduledWorkShopItem;
