import { Dispatch, SetStateAction } from 'react';
import { ColorElementType, ColorType } from 'src/interfaces/types';
import ColorPaletteService, { UserThemeConfigPayload } from 'src/services/ColorPalettes';
import { OperationMessage } from 'src/utils/constants/constants';

type GetColors = {
    setFetching: Dispatch<SetStateAction<boolean>>;
    setColors: Dispatch<SetStateAction<ColorType[]>>;
};

type SaveThemeConfig = {
    elementColors: ColorElementType;
    setSaving: Dispatch<SetStateAction<boolean>>;
    toast: any;
};

const handleFetchColors = ({ setFetching, setColors }: GetColors) => {
    setFetching(true);
    ColorPaletteService.getColorPalettes()
        .then((res: any) => {
            const colors = res?.data?.data;
            const optimizedColors = Array.from(Object.values(colors), (color: any, i) => ({
                id: i,
                value: color?.[5]?.hexCode,
            }));
            setColors(optimizedColors);
            setFetching(false);
        })
        .catch(() => setFetching(false));
};

const handleSaveTheme = ({ setSaving, elementColors, toast }: SaveThemeConfig) => {
    const removeLabelInBtnColors = elementColors.btnColors.map((item) => ({
        code: item.code,
        value: item.color,
        isFile: 0,
    }));
    const removeLabelInLinkColors = elementColors.linkColors.map((item) => ({
        code: item.code,
        value: item.color,
        isFile: 0,
    }));
    const removeLabelInTextColors = elementColors.textColors.map((item) => ({
        code: item.code,
        value: item.color,
        isFile: 0,
    }));
    const unifiedValues = [...removeLabelInBtnColors, ...removeLabelInLinkColors, ...removeLabelInTextColors];
    const payload: UserThemeConfigPayload = {
        items: unifiedValues,
    };
    setSaving(true);
    ColorPaletteService.saveUserThemeConfig(payload)
        .then((res: any) => {
            toast.success('Thème mise à jour avec succès');
            setSaving(false);
        })
        .catch(() => {
            setSaving(false);
            toast.error('Erreur de modification de thème');
        });
};

export { handleFetchColors, handleSaveTheme };
