import { Grid } from '@mui/material';
import { FC } from 'react';
import { CustomText } from 'src/components/Text/CustomText';
import { CustomSelect } from 'src/components/InputField/CustomSelect';
import { Spacer } from 'src/components/Spacer/Spacer';
import { useFetchPrograms } from 'src/hooks/ReactQueryHooks/useProgramRQHooks';
import {
    optimizedProgramData,
    programsWithoutDraft,
    programsWithoutKeys
} from 'src/views/Patients/PatientsDetails/Programs/AddProgam/AddProgramLogic';
import { PROGRAM_STATUS } from 'src/utils/constants/enums';

interface EventProgramProps {
    setEventPayload: any;
    loadingData?: any;
    eventData?: any;
    isVisio?: boolean;
}

const EventProgram: FC<EventProgramProps> = (props) => {
    const { setEventPayload, eventData, loadingData } = props;
    const defaultValue = eventData?.program?.id ?? 0;
    const { data: programs, isLoading: fecthingPrograms } = useFetchPrograms({});
    const programsWithoutDraftAndDone = programsWithoutKeys(programs,[PROGRAM_STATUS.DONE,PROGRAM_STATUS.DRAFT]);
    const programItems = optimizedProgramData(programsWithoutDraftAndDone ?? []);

    return (
        <>
            <Grid container direction="row" alignItems={'center'} justifyContent="space-between" style={{}}>
                <Grid item>
                    <CustomText>Programme : </CustomText>
                </Grid>

                <Grid item style={{ width: '60%' }}>
                    {fecthingPrograms ? (
                        loadingData
                    ) : (
                        <CustomSelect
                            consultationType
                            onChange={(value: number) =>
                                setEventPayload((old: any) => ({
                                    ...old,
                                    programId: value,
                                }))
                            }
                            label=""
                            labelKey="label"
                            items={[{ id: 0, label: 'Sélectionner un programme', modules: [] }, ...programItems]}
                            defaultValue={defaultValue}
                            idSelect="event_program"
                            width="100%"
                        />
                    )}
                </Grid>
            </Grid>

            <Spacer />
        </>
    );
};

export default EventProgram;
