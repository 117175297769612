import { Box, Grid } from '@mui/material';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { CustomSpecialityWithProfessionals } from 'src/components/InputField/CustomSpecialityWithProfessionals';
import { Spacer } from 'src/components/Spacer/Spacer';
import { CustomText } from 'src/components/Text/CustomText';
import { Workshop } from 'src/interfaces/interfaces';
import { getAttendeesType } from 'src/services/Attendees';
import { baseColors, baseFontFamily, baseFontSize } from 'src/utils/constants/baseStyles';
import { fullName } from 'src/utils/utils';

type IProps = {
    payload: any;
    attendeeTypeId: number;
    lockWorkshopInput: boolean;
    setAttendeeTypeId: Dispatch<SetStateAction<any>>;
    setPayload: Dispatch<SetStateAction<any>>;
    workshopData?: Workshop;
    loadingData?: any;
};

export const WorkshopIntervenants: FC<IProps> = (props) => {
    const { loadingData, setAttendeeTypeId, setPayload, attendeeTypeId, workshopData } = props;
    const [attendesName, setAttendesName] = useState([]);
    const [professionalIds, setProfessionalIds] = useState<any>([]);
    const [checkedSpecialityIds, setCheckedSpecialityIds] = useState<any>([]);
    const [open, setOpen] = useState<boolean>(false);
    const toggleModal = () => setOpen(!open);

    const fetchData = () => {
        getAttendeesType().then((response) => {
            const attendeesType = response?.data?.data?.items;
            const intervenant = attendeesType.find((item: any) => item.name === 'Intervenants');
            setAttendeeTypeId(intervenant.id);
        });
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <Grid lg={12} container direction="row" alignItems={'center'} justifyContent="space-between">
                <Grid item>
                    <CustomText>Intervenant(s) :</CustomText>
                </Grid>

                <Grid item style={{ width: '60%' }}>
                    {attendesName?.length > 0 && (
                        <Box
                            style={{
                                cursor: 'pointer',
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                                backgroundColor: baseColors.green.light,
                                padding: 12,
                                borderRadius: 10,
                            }}
                            onClick={toggleModal}
                        >
                            {attendesName?.map((attende: any) => (
                                <CustomText
                                    key={attende.id}
                                    style={{
                                        paddingRight: 20,
                                        marginBottom: 7,
                                    }}
                                    fontSize={baseFontSize.mini}
                                    fontFamily={baseFontFamily.OpenSansSemiBold}
                                >
                                    {fullName(attende?.firstName, attende?.lastName)}
                                </CustomText>
                            ))}
                        </Box>
                    )}

                    <CustomSpecialityWithProfessionals
                        open={open}
                        toggleModal={toggleModal}
                        setPayload={setPayload}
                        attendeeTypeId={attendeeTypeId}
                        setAttendesName={setAttendesName}
                        professionalIds={professionalIds}
                        setProfessionalIds={setProfessionalIds}
                        loadingData={loadingData}
                        workshopData={workshopData}
                        checkedSpecialityIds={checkedSpecialityIds}
                        setCheckedSpecialityIds={setCheckedSpecialityIds}
                    />
                </Grid>
            </Grid>

            <Spacer />
        </>
    );
};
