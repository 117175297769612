import * as React from 'react';

function IconKitMedical(props: any) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={54} height={54} fill="none" viewBox="0 0 54 54" {...props}>
            <path
                fill="#F3F5F9"
                d="M41.238 6.434v6.328c0 .886-.696 1.582-1.582 1.582a1.567 1.567 0 01-1.582-1.582V6.434c0-.886-.696-1.582-1.582-1.582H17.508c-.886 0-1.582.696-1.582 1.582v6.328c0 .886-.696 1.582-1.582 1.582a1.567 1.567 0 01-1.582-1.582V6.434a4.74 4.74 0 014.746-4.747h18.984a4.74 4.74 0 014.746 4.747z"
            />
            <path
                fill="#F3F5F9"
                d="M41.238 6.434v6.328c0 .886-.696 1.582-1.582 1.582a1.567 1.567 0 01-1.582-1.582V6.434c0-.886-.696-1.582-1.582-1.582H27V1.687h9.492a4.74 4.74 0 014.746 4.747z"
            />
            <path
                fill="#F3F5F9"
                d="M49.254 14.344v34.804l-1.582 3.164H6.328l-1.582-3.164V14.344l1.582-3.164h41.344l1.582 3.164z"
            />
            <path fill="#F3F5F9" d="M49.254 14.344v34.804l-1.582 3.164H27V11.18h20.672l1.582 3.164z" />
            <path
                fill="#64C7C7"
                d="M27 20.672c-6.107 0-11.074 4.968-11.074 11.074 0 6.107 4.967 11.074 11.074 11.074s11.074-4.967 11.074-11.074c0-6.106-4.967-11.074-11.074-11.074z"
            />
            <path
                fill="#64C7C7"
                d="M38.074 31.746c0 6.107-4.967 11.074-11.074 11.074V20.672c6.107 0 11.074 4.968 11.074 11.074z"
            />
            <path
                fill="#1B8E95"
                d="M49.254 11.18h-1.582v41.133h1.582A4.751 4.751 0 0054 47.566v-31.64a4.751 4.751 0 00-4.746-4.746zm-44.508 0A4.751 4.751 0 000 15.926v31.64a4.751 4.751 0 004.746 4.746h1.582V11.18H4.746z"
            />
            <path
                fill="#F3F5F9"
                d="M31.746 31.746c0 .886-.696 1.582-1.582 1.582h-1.582v1.582c0 .886-.696 1.582-1.582 1.582a1.567 1.567 0 01-1.582-1.582v-1.582h-1.582a1.567 1.567 0 01-1.582-1.582c0-.886.696-1.582 1.582-1.582h1.582v-1.582c0-.886.696-1.582 1.582-1.582.886 0 1.582.696 1.582 1.582v1.582h1.582c.886 0 1.582.696 1.582 1.582z"
            />
            <path
                fill="#F3F5F9"
                d="M31.746 31.746c0 .886-.696 1.582-1.582 1.582h-1.582v1.582c0 .886-.696 1.582-1.582 1.582V27c.886 0 1.582.696 1.582 1.582v1.582h1.582c.886 0 1.582.696 1.582 1.582z"
            />
        </svg>
    );
}

export default IconKitMedical;
