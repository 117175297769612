import { makeStyles } from '@mui/styles';
import { baseColors } from 'src/utils/constants/baseStyles';

const useStyles = makeStyles({
    container: {
    },
    
});

const sxStyles : any = {
    btn: {
        width: '100%',
        color: baseColors.white,
        backgroundColor: baseColors.green.primary,
        borderColor: 'transparent',
    }
}

export {
    sxStyles
}

export default useStyles;
