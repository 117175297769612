import { Grid } from "@mui/material"
import { FC } from "react"
import { UserAxisItem } from "src/interfaces/interfaces"
import { CustomRadio } from "../InputField/CustomRadio"
import QuestionPrecision from "./QuestionPrecision"

interface QuestionRadioProps {
    userDEResponse: UserAxisItem;
    responseValue: any;
    index: number;
    setPayload : Function;
    questionTitle: JSX.Element;
    precisionAnswer: number | string | undefined;
}
const QuestionRadio : FC<QuestionRadioProps> = ({userDEResponse, index, responseValue, precisionAnswer, setPayload, questionTitle}) => {
    const hasPrécision = userDEResponse.survey.hasPrecision === 1;

    const updatePayload = (value: number) => {
        setPayload((prev: any) => {
            const updatedPayload = { ...prev };
            if (updatedPayload.responses && updatedPayload.responses.length > index) {
                updatedPayload.responses[index].responseValue = value;
            }
        
            return updatedPayload; // Return the updated state
        });
    }
    
    return (
        <Grid
                style={{ marginTop: 20, marginBottom: 10 }}
                container
                direction="column"
                alignItems={'start'}
                justifyContent="start"
            >
            <Grid item>
                {questionTitle}
            </Grid>
            <Grid item style={{ display: 'flex', justifyContent: 'start', alignItems:'start', flexDirection:'row', width: '30%' }}>
                <CustomRadio
                    checked={parseInt(responseValue) === 1}
                    onClick={() => updatePayload(1)}
                    label={'Oui'}
                />
                <CustomRadio
                    checked={parseInt(responseValue) == 0}
                    onClick={() => updatePayload(0)}
                    label={'Non'}
                />
            </Grid>
            {hasPrécision &&
                <Grid item>
                    <QuestionPrecision
                        precisionAnswer={precisionAnswer} 
                        setPayload={setPayload}
                        index={index} 
                        userDEResponse={userDEResponse}
                    />
                </Grid>
            }
        </Grid>
    )
}

export default QuestionRadio