import { FC } from 'react';
import { OpenSansRegularText } from '../Text/OpenSansRegularText';
import { CheckBoxItem } from 'src/interfaces/interfaces';
import { CheckedItemsLabel } from './ListItemFormCheckBoxLogic';

interface SelectedElementProps {
    finalData: CheckBoxItem[];
    selectedIds: number[];
}

const SelectedElement: FC<SelectedElementProps> = ({ finalData, selectedIds }) => {
    const selectedItems: CheckBoxItem[] = CheckedItemsLabel({ data: finalData, selectedIds });

    if (!selectedItems?.length) {
        return <></>;
    }

    return (
        <div
            style={{
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: 'row',
                marginTop: 10,
                marginLeft: 10,
            }}
        >
            {selectedItems?.map((item, i) => (
                <OpenSansRegularText fontSize={13} style={{ marginRight: 5, fontStyle: 'italic' }}>
                    {item.name} {i !== selectedItems?.length - 1 && ','}
                </OpenSansRegularText>
            ))}
        </div>
    );
};

export default SelectedElement;
