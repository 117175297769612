import { Typography } from '@mui/material';
import { FC } from 'react';
import { baseColors, baseFontWeight } from 'src/utils/constants/baseStyles';
import useStyles, { sxStyles } from './styles';
import { headers } from './WorkshopModalLogic';
import { IconButton } from '@mui/material';
import VideoChatIcon from '@mui/icons-material/VideoChat';

type IProps = {
    calendarEvent: any;
    isVisio: any;
    hasNoParticipant: any;
    toggleModal: any;
    readOnly?: boolean;
    isExternalDoctor?: boolean;
};

const WorkshopModalHeader: FC<IProps> = (props) => {
    const { calendarEvent, isVisio, hasNoParticipant, toggleModal, readOnly, isExternalDoctor } = props;
    const classes = useStyles();
    const disabled = hasNoParticipant || readOnly || isExternalDoctor;

    return (
        <div>
            <div className={classes.header}>
                <div>
                    {headers(calendarEvent).map((item, i) => (
                        <div className={classes.headerItem} key={i}>
                            <Typography fontWeight={baseFontWeight.bold} fontSize={14} color={baseColors.black}>
                                {item.label}{' '}
                            </Typography>
                            <Typography fontSize={14} color={baseColors.black} marginLeft={2}>
                                {item.value}
                            </Typography>
                        </div>
                    ))}
                </div>

                <div style={{ cursor: disabled ? 'not-allowed' : 'pointer', opacity: hasNoParticipant ? 0.5 : 1 }}>
                    {isVisio && (
                        <IconButton disabled={disabled} aria-label="Démarer l'atelier en direct" onClick={toggleModal}>
                            <VideoChatIcon sx={sxStyles.callVisio} />
                        </IconButton>
                    )}
                </div>
            </div>

            {hasNoParticipant && isVisio && (
                <p style={sxStyles.warningVisioText}>
                    ( Ajouter au moins un participant pour pouvoir démarrer la téléconsultation. )
                </p>
            )}
        </div>
    );
};

export default WorkshopModalHeader;
