import { Dispatch, FC, SetStateAction, memo, useContext, useEffect, useRef, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import {
    defaultEventDate,
    handleDateSelect,
    handleDatesSet,
    handleEventClick,
    handleEventDrop,
    handleFetchEventData,
    handleSetEventData,
    initialView,
    slotDurationValue,
} from './calendarUtils';
import EventContent from './EventContent';
import { EventPlanningViewType } from 'src/interfaces/interfaces';
import './style.css';
import WorkshopModal from '../CustomModal/WorkshopModal';
import DiagnosticModal from '../CustomModal/DiagnosticModal';
import frLocale from '@fullcalendar/core/locales/fr';
import useStyles from './styles';
import { Time } from 'src/interfaces/interfaces';
import { AgendaContext } from 'src/providers/AgendaProvider';
import { useHistory } from 'react-router-dom';
import { Box, Dialog, DialogContent } from '@mui/material';
import CustomCircleLoader from '../CustomLoader/CustomCircleLoader';
import useCheckUserRole from 'src/hooks/useCheckUserRole';
import { UserContext } from 'src/providers/UserProvider';

type CustomFullCalendarType = {
    events?: any;
    toggleCreateEventModal?: Function;
    setNewEventDate?: any;
    setNewEventEndDate?: any;
    setUpdateList?: any;
    setDroppedEventId?: Dispatch<SetStateAction<number>>;
    setDroppedEventDateTime?: any;
    timeSlot: number;
    timeInterval: Time;
    isPatientAgenda?: boolean;
    partialLoading?: boolean;
};

const CustomFullCalendar: FC<CustomFullCalendarType> = (props) => {
    const {
        events,
        toggleCreateEventModal,
        setNewEventDate,
        setUpdateList,
        setDroppedEventId,
        setDroppedEventDateTime,
        timeSlot,
        timeInterval,
        isPatientAgenda,
        setNewEventEndDate,
        partialLoading
    } = props;
    const classes = useStyles();
    const history = useHistory();
    const { roles } = useCheckUserRole();
    const calendarComponentRef: any = useRef();

    const [currentEvents, setCurrentEvents] = useState<any>([]);
    const [selectedEvent, setSelectedEvent] = useState<any>({});
    const [viewType, setViewType] = useState<EventPlanningViewType['viewType']>('weekly');
    const [open, setOpen] = useState(false);
    const { allRooms, setCalendarRef, setCalendarValue, setYearAndMonth } = useContext(AgendaContext);
    const [openNotifModal, setOpenNotifModal] = useState<boolean>(false);

    const isCoordinator = roles?.isCoordinator;
    const getHistory = history.location.search;
    const notifModal = getHistory ? getHistory.split('=')[2].split('?')[0] : '1';

    const { hasEtp, userInfos } = useContext(UserContext);
    const isExternalDoctor = Boolean(userInfos?.isExternal);

    const toggleModal = () => setOpen((prev) => !prev);

    useEffect(() => {
        handleSetEventData({ events, setCurrentEvents, allRooms });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [events]);

    const handleFetchEventDataWrapper = (eventNotifId: any) =>
        handleFetchEventData(eventNotifId, setOpenNotifModal, setSelectedEvent, setOpen);

    useEffect(() => {
        if (getHistory.length) {
            handleFetchEventDataWrapper(parseInt(notifModal));
        } else {
            setOpenNotifModal(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notifModal]);

    useEffect(() => {
        setCalendarRef(calendarComponentRef);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [calendarComponentRef]);

    const fcStyles = {
        container: isPatientAgenda ? classes.containerResized : classes.container,
    };

    const cantCreateEvent = (roles?.isDoctor && !hasEtp) || (isPatientAgenda && roles?.isDoctor);

    const handleTodayButtonClick = () => {
        if (calendarComponentRef.current) {
            const calendarApi = calendarComponentRef.current.getApi();
            calendarApi.today();
        }
        const date = new Date();
        setCalendarValue(date);
        setYearAndMonth(defaultEventDate(date.toDateString()));
    };
    type Direction = 'NEXT' | 'PREV';
    const handlePrevNextButtonClick = (key: Direction) => {
        if (calendarComponentRef.current) {
            const calendarApi = calendarComponentRef.current.getApi();
            key === 'NEXT' ? calendarApi.next() : calendarApi.prev();
            const date = calendarApi.view.activeStart;
            setCalendarValue(date);
            setYearAndMonth(defaultEventDate(date));
        }
    };

    const customButtons = {
        today: {
            text: "Aujourd'hui",
            click: handleTodayButtonClick,
        },
        prev: {
            text: '',
            click: () => handlePrevNextButtonClick('PREV'),
        },
        next: {
            text: '',
            click: () => handlePrevNextButtonClick('NEXT'),
        },
    };

    return (
        <div className={fcStyles.container}>

            <div className="container-main">
                <div className="small-loading" style={{ display : !partialLoading ? 'none' : ''}}>
                    <Box paddingLeft={5} paddingRight={5}>
                            <CustomCircleLoader height={80} width={80} />
                    </Box>
                </div>

                <FullCalendar
                    editable
                    selectable
                    selectMirror
                    dayMaxEvents
                    droppable
                    weekends
                    views={{}}
                    initialView={initialView(viewType)}
                    locale={frLocale}
                    ref={calendarComponentRef}
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    customButtons={customButtons}
                    allDaySlot={false}
                    headerToolbar={{
                        left: 'prev,next today',
                        center: 'title',
                        right: 'timeGridDay,timeGridWeek,dayGridMonth',
                    }}
                    datesSet={(event) => handleDatesSet({ event, setViewType })}
                    initialEvents={[]}
                    select={(selectedInfos) =>
                        handleDateSelect({
                            selectedInfos,
                            toggleCreateEventModal,
                            setNewEventDate,
                            setNewEventEndDate,
                            viewType,
                            calendarComponentRef,
                            cantCreateEvent,
                        })
                    }
                    slotMinTime={`${timeInterval?.startHour || '07:00'}:00`}
                    slotMaxTime={`${timeInterval?.endHour || '18:00'}:00`}
                    slotDuration={slotDurationValue(timeSlot)}
                    slotLabelInterval={15}
                    eventContent={(eventInfo) => <EventContent viewType={viewType} eventInfo={eventInfo} />}
                    events={currentEvents}
                    eventClick={(eventInfo) => handleEventClick({ eventInfo, setSelectedEvent, toggleModal })}
                    eventDrop={(info: any) => {
                        handleEventDrop({
                            eventInfos: info,
                            toggleCreateEventModal,
                            setDroppedEventDateTime,
                            setDroppedEventId,
                        });
                    }}
                    dateClick={(e) => setCalendarValue(e.date)}
                />
            </div>
            <Dialog open={openNotifModal} keepMounted aria-describedby="alert-dialog-slide-description">
                <DialogContent>
                    <CustomCircleLoader height={50} width={50} />
                </DialogContent>
            </Dialog>
            {selectedEvent?.eventType === 'workshop' || selectedEvent?.type?.typeName === 'Atelier' ? (
                <WorkshopModal
                    isCoordinator={isCoordinator}
                    isExternalDoctor={isExternalDoctor}
                    setUpdateList={setUpdateList}
                    open={open}
                    toggleModal={toggleModal}
                    workShopevent={selectedEvent}
                    viewType={viewType}
                    eventId={selectedEvent?.id}
                    workShopData={selectedEvent}
                    userInfos={userInfos}
                />
            ) : (
                <DiagnosticModal
                    isCoordinator={isCoordinator}
                    isExternalDoctor={isExternalDoctor}
                    open={open}
                    toggleModal={toggleModal}
                    data={selectedEvent}
                    updateEventList={setUpdateList}
                    viewType={viewType}
                    eventId={selectedEvent?.id}
                />
            )}
        </div>
    );
};

export default CustomFullCalendar;
