import { getDuration } from 'src/utils/constants/constants';
import { fullName } from 'src/utils/utils';

const headers = (calendarEvent: any) => {
    const isVisio = calendarEvent?.consultationType?.id === 2;

    return [
        {
            label: 'Intitulé :',
            value: calendarEvent?.summary ?? '',
        },
        {
            label: isVisio ? 'Visio' : 'Salle :',
            value: isVisio ? '' : calendarEvent?.customLocation ?? '',
        },
        {
            label: 'Durée :',
            value: getDuration(calendarEvent?.startDate, calendarEvent?.endDate),
        },
    ];
};

const checkIntervenants = (calendarEvent: any) =>
    (calendarEvent?.professionals?.length > 0 &&
        Array.from(calendarEvent?.professionals, (intervenant: any) => ({
            name: fullName(intervenant?.attendeeUser?.firstName, intervenant?.attendeeUser?.lastName) ?? '',
            phone: intervenant?.attendeeUser?.phoneNumber ?? '',
            email: intervenant?.attendeeUser?.email ?? '',
        }))) ??
    [];

const checkParticipants = (patientsList: any, isConsultation: boolean) =>
    (patientsList?.length > 0 &&
        Array.from(patientsList, (participant: any) => ({
            participant:
                (isConsultation
                    ? fullName(participant?.attendeeUser?.firstName, participant?.attendeeUser?.lastName)
                    : fullName(participant?.user?.firstName, participant?.user?.lastName)) ?? '',
            present: participant?.present ?? false,
            userId: (isConsultation ? participant?.attendeeUser?.id : participant?.user?.id) ?? 0,
        }))) ??
    [];

const checkParticipantsForAgendaWorkshop = (calendarEvent: any, presences: any) =>
    (calendarEvent?.patients?.length > 0 &&
        Array.from(calendarEvent?.patients, (participant: any) => ({
            participant: fullName(participant?.attendeeUser?.firstName, participant?.attendeeUser?.lastName),
            present: presences?.includes(participant?.attendeeUser?.id),
            userId: participant?.attendeeUser?.id,
        }))) ??
    [];

const checkPresentPatients = (calendarEvent: any) =>
    calendarEvent?.presences?.length > 0
        ? calendarEvent?.presences?.filter((presence: any) => presence?.present === 1)
        : [];

export { headers, checkIntervenants, checkParticipants, checkParticipantsForAgendaWorkshop, checkPresentPatients };
