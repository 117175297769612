import { FC, useEffect, useState } from 'react';
import { Spacer } from 'src/components/Spacer/Spacer';
import { CustomText } from 'src/components/Text/CustomText';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import useStyles from './styles';
import { StateFromMarkdown, TextEditor } from 'src/components/CustomEditor/TextEditor';
import { EditorState } from 'draft-js';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import ProgramService from 'src/services/Program/program.service';
import {
    checMentionnedUsers,
    optimizePatientsData,
    optimizeMedimailsData,
    defaultSelectedDiscussion,
} from './DiscussionLogic';
import { toast } from 'react-toastify';
import { ServerError } from 'src/utils/constants/constants';
import CustomCircleLoader from 'src/components/CustomLoader/CustomCircleLoader';
import { CustomConfirm } from 'src/components/CustomConfirm/CustomConfirm';
import { CreateThemeDiscussionPayload, Medimails, PatientDiscussion } from 'src/interfaces/interfaces';
import { PatientService } from 'src/services/Patients';
import { DiscussionMessageItem } from './DiscussionMessageItem';
import { UserContext } from 'src/providers/UserProvider';
import { useContext } from 'react';
import { PlateformTypeContext } from 'src/providers/PlateformTypeProvider';
import MedimailService from 'src/services/Medimails';
import { DiscussionThemeItem } from 'src/interfaces/types';
import ModalCreateTheme from './ModalCreateTheme/ModalCreateTheme';
import DiscussionThemeList from './DiscussionThemeList/DiscussionThemeList';
import { CustomButton } from 'src/components/Button/CustomButton';
import { ArrowBack } from '@mui/icons-material';

interface DiscussionProps {
    programId: number;
}
export const Discussion: FC<DiscussionProps> = (props) => {
    const { programId } = props;
    const defaultDiscussion: any = {
        id: 0,
        discussionTitle: '',
        discussionText: '',
        recipients: [],
        programId: programId,
    };
    const defaultData: any = [
        {
            text: '',
            value: '',
            url: '',
        },
    ];

    const classes = useStyles();
    const [idToDelete, setIdToDelete] = useState(0);
    const [loading, setLoading] = useState(false);
    const [fetchingDiscussions, setFetchingDiscussions] = useState(false);
    const [open, setOpen] = useState<boolean>(false);
    const toggleModal = () => setOpen((prev) => !prev);

    const [updateList, setUpdateList] = useState<boolean>(false);
    const [selectedDiscussionTheme, setSelectedDiscussionTheme] =
        useState<DiscussionThemeItem>(defaultSelectedDiscussion);

    const [modalConfirmVisible, setModalConfirmVisible] = useState(false);
    const [message, setMessage] = useState<string>('');
    const [discussion, setDiscussion] = useState<any>(defaultDiscussion);
    const [discussions, setDiscussions] = useState<any[]>([]);
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const [patients, setPatients] = useState<any[]>([]);
    const { isPlateformCpts } = useContext(PlateformTypeContext);
    const { isCoordinator } = useContext(UserContext);
    const [findMedimail, setFindMedimail] = useState<string>('');
    const [mediMailsMentioned, setMedimailsMentioned] = useState<any[]>([]);
    const [dataMedimails, setDataMedimails] = useState<any[]>(defaultData);
    const [filtered, setFiltered] = useState<any[]>([]);

    const handleFetchPatients = () => {
        PatientService.getProfessionals()
            .then((res: any) => {
                const responses = res?.data?.data;
                setPatients(responses);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleFetchDiscussions = () => {
        setFetchingDiscussions(true);
        ProgramService.getDiscussion(programId, selectedDiscussionTheme.id)
            .then((response) => {
                const data = response;
                setDiscussions(data as any[]);
                setFetchingDiscussions(false);
            })
            .catch((e) => {
                setFetchingDiscussions(false);
                toast.error(ServerError.unknown);
            });
    };

    const handleFetchMedimails = (value: any) => {
        MedimailService.getMedimails(value).then((res) => {
            const responses = res?.data?.data;
            const arr = dataMedimails.concat(responses);
            const uniqueArray = arr.filter((item, index) => arr.indexOf(item) === index);
            setDataMedimails(responses);
        });
    };

    useEffect(() => {
        handleFetchPatients();
    }, []);

    useEffect(() => {
        setDiscussion((old: any) => ({ ...old, discussionText: message }));
        mediMailsMentioned.map((item) => {
            if (item.length > 0) {
                setFiltered(item);
            }
        });
        const qu = message.split('@');

        qu.map((item) => {
            handleFetchMedimails(item);
        });
    }, [message, findMedimail]);

    const medimailsDataToFilter: Medimails[] = Array.from(dataMedimails, (medimail: any) => ({
        id: medimail.id,
        name: `${medimail.prenom}-${medimail.nom}`.toLocaleLowerCase(),
        mail: medimail.mail,
    }));

    const handleChange = (value: any) => {
        setMessage(value);
        const query = value.split('@');
        const checkMentionString = query.filter((item: any) => item.includes(' '));
        const filteredData = checkMentionString.map((item: any) => {
            const splitedItem = item.split(' ');
            return `${splitedItem?.[0]}-${splitedItem?.[1]}`.toLocaleLowerCase();
        });
        const mentioned =
            medimailsDataToFilter?.length > 0
                ? medimailsDataToFilter?.filter((pat: Medimails) =>
                      filteredData.some((item: string) => pat.name === item),
                  )
                : [];

        setMedimailsMentioned((prev: any) => [...prev, mentioned]);
    };

    useEffect(() => {
        handleFetchDiscussions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDiscussionTheme]);

    const editDiscussion = (item: any) => {
        setDiscussion(item);
        const contentStateFromMarkdown = StateFromMarkdown(item.discussionText);
        setEditorState(() => EditorState.createWithContent(contentStateFromMarkdown));
    };

    const onCancelEdit = () => {
        setDiscussion(defaultDiscussion);
        setEditorState(() => EditorState.createEmpty());
    };

    const deleteDiscussion = () => {
        return new Promise((resolve, reject) => {
            ProgramService.deleteDiscussion(idToDelete)
                .then((response) => {
                    let old = discussions;
                    old = old.filter((d: any) => d.id !== idToDelete);
                    setDiscussions([...old]);
                    setDiscussion(defaultDiscussion);
                    setIdToDelete(0);
                    setModalConfirmVisible(false);
                    toast.success('Message supprimé avec succès !');
                    resolve(response);
                })
                .catch((e) => {
                    setModalConfirmVisible(false);
                    toast.error(ServerError.unknown);
                    reject(e);
                });
        });
    };

    const beforeConfirmDelete = (item: any) => {
        setIdToDelete(item.id);
        setModalConfirmVisible(true);
    };

    const patientsDataToFilter: PatientDiscussion[] = Array.from(patients, (patient) => ({
        id: patient.id,
        name: `${patient.lastName}-${patient.firstName}`.toLocaleLowerCase(),
    }));

    const optimizedPatients = optimizePatientsData(patients);
    const optimizedMedimail = optimizeMedimailsData(dataMedimails);
    const optimizedData = optimizedPatients.concat(optimizedMedimail);
    const mentionedUsers = checMentionnedUsers(discussion.discussionText, patientsDataToFilter);
    const isEmptyMessage = discussion.discussionText?.length === 0;

    const handleSaveDiscussion = () => {
        const removeId: any = { ...discussion };
        delete removeId.id;
        const payload: CreateThemeDiscussionPayload = {
            ...removeId,
            recipients: mentionedUsers?.length > 0 ? mentionedUsers.map((user) => user.id) : [],
            externalUsersMail: filtered?.length > 0 ? filtered.map((medimail) => medimail.mail) : [],
            themeId: selectedDiscussionTheme.id,
            programId,
        };

        if (isEmptyMessage) {
            toast.warning('Aucun contenu à envoyer');
        } else {
            setLoading(true);
            if (discussion.id !== 0) {
                ProgramService.putDiscussion({ discussionId: discussion.id, payload })
                    .then((response) => {
                        let newData = response.data.data;
                        let oldDiscussion = discussions;
                        if (newData) {
                            oldDiscussion.forEach((d: any) => {
                                if (d.id === discussion.id) {
                                    d.discussionText = newData.discussionText;
                                    d.discussionTitle = newData.discussionTitle;
                                }
                                return d;
                            });
                            setDiscussions([...oldDiscussion]);
                        }
                        toast.success('Discussion bien modifié');
                        setDiscussion(defaultDiscussion);
                        setEditorState(() => EditorState.createEmpty());
                        setLoading(false);
                    })
                    .catch((e) => {
                        toast.error(ServerError.unknown);
                        setLoading(false);
                    });
            } else {
                ProgramService.postDiscussion(payload)
                    .then((response) => {
                        let newData = response?.data?.data;
                        setDiscussions((old: any) => [...old, newData]);
                        setDiscussion(defaultDiscussion);
                        setEditorState(() => EditorState.createEmpty());
                        setLoading(false);
                        toast.success('Discussion bien envoyée');
                    })
                    .catch((e) => {
                        toast.error(ServerError.unknown);
                        setLoading(false);
                    });
            }
        }
    };

    const showFooter = selectedDiscussionTheme?.id > 0;

    return (
        <div className={classes.container}>
            <div style={{ alignSelf: 'center' }}>
                <CustomLoadingButton
                    orange
                    handleClick={() => {
                        setSelectedDiscussionTheme(defaultSelectedDiscussion);
                        toggleModal();
                    }}
                    label={'Nouveau Thème'}
                />
            </div>

            <div
                style={{
                    marginTop: 20,
                    padding: 25,
                    borderRadius: 8,
                    height: `calc(100vh - ${showFooter ? 460 : 410}px)`,
                    background: baseColors.green.light,
                    overflowY: 'auto',
                    paddingBottom: 15,
                }}
            >
                {selectedDiscussionTheme.id === 0 ? (
                    <DiscussionThemeList
                        setSelectedDiscussionTheme={setSelectedDiscussionTheme}
                        programId={programId}
                        updateList={updateList}
                    />
                ) : (
                    <>
                        <div
                            className={classes.flexCenter}
                            style={{
                                flexDirection: 'column',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <CustomText color={baseColors.green.primary} fontFamily={baseFontFamily.OpenSansSemiBold}>
                                Informations pour l'équipe soignante
                            </CustomText>

                            <Spacer height={10} />

                            <CustomText
                                color={baseColors.orange.primary}
                                fontFamily={baseFontFamily.OpenSansSemiBold}
                                style={{ fontStyle: 'italic' }}
                            >
                                Thème : {selectedDiscussionTheme.name}
                            </CustomText>
                        </div>
                        <div
                            style={{ cursor: 'pointer' }}
                            onClick={() => setSelectedDiscussionTheme(defaultSelectedDiscussion)}
                        >
                            <CustomText color={baseColors.green.primary} fontFamily={baseFontFamily.OpenSansSemiBold} style={{display: 'flex', gap: '5px'}}>
                                <ArrowBack htmlColor={baseColors.green.primary} />
                                {`Retourner à la liste des thèmes`}
                            </CustomText>
                        </div>

                        <div>
                            {fetchingDiscussions ? (
                                <div className={classes.rowCenter} style={{ marginTop: 40 }}>
                                    <CustomCircleLoader height={35} width={35} />
                                </div>
                            ) : discussions?.length === 0 ? (
                                <CustomText
                                    color={baseColors.black}
                                    fontSize={12}
                                    style={{ fontStyle: 'italic', textAlign: 'center', marginTop: 10 }}
                                >
                                    ( Aucune discussion pour le moment )
                                </CustomText>
                            ) : (
                                discussions?.map((item: any, i: number) => (
                                    <DiscussionMessageItem
                                        key={item?.id}
                                        item={item}
                                        discussionId={discussion.id}
                                        editDiscussion={editDiscussion}
                                        beforeConfirmDelete={beforeConfirmDelete}
                                        isCoordinator={isCoordinator}
                                        isCpts={isPlateformCpts}
                                    />
                                ))
                            )}
                        </div>

                        <div style={{}}>
                            <TextEditor
                                setEditorState={setEditorState}
                                editorState={editorState}
                                handleChange={(val: any) => handleChange(val)}
                                data={optimizedData}
                            />
                        </div>
                    </>
                )}
            </div>

            {showFooter && (
                <div className={classes.flexCenter} style={{ marginTop: 10 }}>
                    {discussion.id !== 0 && (
                        <>
                            <CustomLoadingButton
                                disabled={loading}
                                orange
                                handleClick={() => {
                                    onCancelEdit();
                                }}
                                label={'Annuler'}
                            />
                            <Spacer width={40} />
                        </>
                    )}

                    <CustomLoadingButton
                        loading={loading}
                        handleClick={handleSaveDiscussion}
                        disabled={discussion.discussionText.trim().length < 2 || fetchingDiscussions}
                        label={discussion.id !== 0 ? 'Sauvegarder' : 'Envoyer'}
                    />
                </div>
            )}

            <CustomConfirm
                modalVisible={modalConfirmVisible}
                updateModalVisible={setModalConfirmVisible}
                message="Voulez-vous vraiment supprimer ce message ? "
                callBackconfirm={() => deleteDiscussion()}
            />

            <ModalCreateTheme
                toggleModal={toggleModal}
                open={open}
                setUpdateList={setUpdateList}
                programId={programId}
            />
        </div>
    );
};
