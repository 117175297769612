import { FC, useState, useEffect, useContext } from 'react';
import CustomModal from '../../../../components/CustomModal';
import { Grid, Box } from '@mui/material';
import { CustomText } from '../../../../components/Text/CustomText';
import { CustomSelect } from '../../../../components/InputField/CustomSelect';
import CustomDatePicker from 'src/components/CustomDatePicker';
import moment from 'moment';
import { dbDateFormat } from 'src/utils/utils';
import {
    canSubmit,
    categories,
    currentCategory,
    currentRole,
    handleChangeOptions,
    performAddUser,
    roles,
} from '../UserLogic';
import useStyles from '../../../Programme/Form/styles';
import { Spacer } from 'src/components/Spacer/Spacer';
import { UserContext } from 'src/providers/UserProvider';
import { ProfessionalForm } from './ProfessionalForm';
import { UserAvatarAndName } from './UserAvatarAndName';
import { handleSetDefaultPayload } from '../UserUtiles';
import { EmailAndGender } from './EmailAndGender';
import useCheckUserRole from 'src/hooks/useCheckUserRole';
import { CATEGORIES, URER_ROLES } from 'src/utils/constants/enums';
import { InputField } from 'src/components/InputField/InputField';
import { isValidRpps } from 'src/utils/helpers/InputValidator';
import { baseScreenHeight } from 'src/utils/constants/baseStyles';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import PhoneInput from 'react-phone-input-2';

interface UserFormProps {
    selectedUserData?: any;
    modalOpen: boolean;
    toggleModal: any;
    setUpdateList?: any;
    rolesList: any[];
    payload: any;
    setPayload: any;
    clearInput: any;
}

export const UserForm: FC<UserFormProps> = (props) => {
    const { modalOpen, toggleModal, setUpdateList, selectedUserData, payload, setPayload, clearInput } = props;
    const [lockUserInput, setLockUserInput] = useState(false);
    const classes = useStyles();
    const [loadingUserForm, setLoadingUserForm] = useState(false);
    const { setUserInfos } = useContext(UserContext);
    const edition: boolean = +selectedUserData?.id > 0;
    const { roles: connectedUserRole, userRole } = useCheckUserRole();
    const [profIcon, setProfIcon] = useState<string>();

    useEffect(() => {
        if (edition) {
            handleSetDefaultPayload({
                setPayload,
                setProfIcon,
                selectedUserData,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedUserData, edition]);
    const disableCategoryOption = edition && payload?.category === CATEGORIES.PATIENT;
    const categoriesData = categories(userRole ?? URER_ROLES.DOCTOR, disableCategoryOption);
    const rolesData = roles({ category: payload.category, isPatient: disableCategoryOption });
    const isProfessional = payload?.category === CATEGORIES.PROFESSIONAL;
    const isPatient = payload?.category === CATEGORIES.PATIENT;

    const handleSubmit = () =>
        performAddUser({
            selectedUserData,
            payload,
            setProfIcon,
            clearInput,
            setLoadingUserForm,
            setLockUserInput,
            toggleModal,
            setUserInfos,
            setUpdateList,
            setPayload,
        });

    const handleChange =
        ({ setPayload, inputKey }: { setPayload: any; inputKey: string }) =>
        (value: string | number) => {
            if (inputKey) {
                setPayload((prev: any) => ({
                    ...prev,
                    [inputKey]: value,
                }));
            }
        };

    const checkValidRpps = (checked: boolean) => {
        if (checked) {
            return (
                <CustomText
                    style={{ marginTop: -10, marginBottom: 20 }}
                    fontFamily={baseFontFamily.BreeSerif}
                    fontSize={12}
                    color={baseColors.orange.dark}
                >
                    Le numéro RPPS doit avoir au moins 11 chiffres
                </CustomText>
            );
        }
    };

    return (
        <CustomModal
            withMinHeight
            width={600}
            height={baseScreenHeight.newEventModal}
            title={edition ? 'Profil utilisateur' : 'Nouvel utilisateur'}
            toggleModal={() => {
                toggleModal();
                setProfIcon('');
            }}
            open={modalOpen}
            footer={
                <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 20 }}>
                    <CustomLoadingButton
                        width="80%"
                        disabled={!canSubmit({ payload })}
                        loading={loadingUserForm}
                        label={edition ? 'Valider les modifications' : 'Ajouter utilisateur'}
                        handleClick={handleSubmit}
                    />
                </Box>
            }
        >
            <UserAvatarAndName
                selectedUserData={selectedUserData}
                payload={payload}
                setPayload={setPayload}
                lockUserInput={lockUserInput}
                profIcon={profIcon}
                setProfIcon={setProfIcon}
            />

            <EmailAndGender lockUserInput={lockUserInput} payload={payload} setPayload={setPayload} />

            <Spacer />

            <Grid lg={12} container direction="row" alignItems={'center'}>
                <Grid item>
                    <CustomText style={{ minWidth: '200px' }}>Téléphone :</CustomText>
                </Grid>
                <Grid item style={{ width: '61%' }}>
                    <PhoneInput
                        //isValid={(inputNumber) => inputNumber?.length > 10}
                        country="fr"
                        inputStyle={{
                            backgroundColor: 'transparent',
                            width: '100%',
                            height: 40,
                        }}
                        countryCodeEditable={false}
                        value={payload?.phoneNumber}
                        onChange={
                            (phone: any) => setPayload((old: any) => ({ ...old, phoneNumber: '+' + phone }))
                        }
                    />
                </Grid>
            </Grid>

            <Spacer />

            <Grid lg={12} container direction="row" alignItems={'center'}>
                <Grid item>
                    <CustomText style={{ minWidth: '200px' }}>Catégorie :</CustomText>
                </Grid>
                <Grid item style={{ width: '61%' }}>
                    <CustomSelect
                        onChange={handleChangeOptions({
                            items: categoriesData,
                            optionKey: 'category',
                            setPayload,
                            defaultRole: true,
                        })}
                        label=""
                        labelKey={''}
                        disabled={lockUserInput || disableCategoryOption}
                        items={categoriesData ?? []}
                        defaultValue={currentCategory({ selectedUserData, connectedUserRole }) ?? 0}
                        idSelect="categorie"
                        width="100%"
                        required
                    />
                </Grid>
            </Grid>

            <Spacer />

            <Grid lg={12} container direction="row" alignItems={'center'}>
                <Grid item>
                    <CustomText style={{ minWidth: '200px' }}>Rôle :</CustomText>
                </Grid>
                <Grid item style={{ width: '61%' }}>
                    <CustomSelect
                        onChange={handleChangeOptions({ items: rolesData, optionKey: 'role', setPayload })}
                        label=""
                        labelKey={''}
                        disabled={lockUserInput || disableCategoryOption}
                        items={rolesData}
                        defaultValue={currentRole({ payload, selectedUserData, rolesData }) ?? 0}
                        idSelect="role-user"
                        width="100%"
                    />
                </Grid>
            </Grid>

            {isProfessional && (
                <ProfessionalForm
                    selectedUserData={selectedUserData}
                    payload={payload}
                    setPayload={setPayload}
                    lockUserInput={lockUserInput}
                />
            )}

            <Spacer />

            {/* {!isPatient && (
                <Grid lg={12} container direction="row" alignItems={'center'}>
                    <Grid item>
                        <CustomText style={{ minWidth: '200px' }}>Numéro RPPS :</CustomText>
                    </Grid>
                    <InputField
                        label={''}
                        defaultValue={payload?.rpps ?? ''}
                        disabled={lockUserInput}
                        onChange={handleChange({ setPayload, inputKey: 'rpps' })}
                        validator={isValidRpps}
                        errorMessage="rpps obligatoire"
                        placeholder={'Entrer le numero rpps'}
                        width={360}
                        idSuffix="rpps-utilisateur"
                    />
                </Grid>
            )} */}

            {/* {!isPatient && payload?.rpps?.length < 11 && (
                <Grid item flexGrow={1} justifyContent="center" style={{ paddingLeft: '34%' }}>
                    {payload?.rpps?.length < 11 ? checkValidRpps(true) : checkValidRpps(false)}
                </Grid>
            )} */}

            <Grid lg={12} container direction="row" alignItems={'center'}>
                <div className={classes.inlineFormItem}>
                    <Grid item>
                        <CustomText style={{ minWidth: '200px' }}>Date de naissance :</CustomText>
                    </Grid>

                    <CustomDatePicker
                        likeSelect
                        width={'100%'}
                        validator={true}
                        disabled={lockUserInput}
                        value={payload?.birthDate ? moment(payload?.birthDate, dbDateFormat).toDate() : null}
                        setValue={(value: any) => {
                            setPayload((old: any) => ({ ...old, birthDate: moment(value).format(dbDateFormat) }));
                        }}
                    />
                </div>
            </Grid>

            <Spacer />
        </CustomModal>
    );
};
