import { Box } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import CustomModal from 'src/components/CustomModal';
import ListItemFormCheckBox from 'src/components/ListItemFormCheckBox';
import { SnackBarContext } from 'src/providers/SnackBarProvider';
import EventService from 'src/services/Calendar/events.service';
import moment from 'moment';
import { toast } from 'react-toastify';
import { OperationMessage } from 'src/utils/constants/constants';
import { usePatientHooks } from 'src/hooks/usePatientHooks';
import { searchElementByName } from 'src/utils/utils';

interface ModalAddMorePatientsProps {
    toggleModal: any;
    modalOpen: boolean;
    eventData: any;
    setUpdateList?: any;
}

const ModalAddMorePatients: FC<ModalAddMorePatientsProps> = (props) => {
    const { modalOpen, toggleModal, eventData, setUpdateList } = props;
    const [updating, setUpdating] = useState(false);
    const [patientsIds, setPatientsIds] = useState<Array<any>>([]);
    const { optimezedPatients, fetching } = usePatientHooks({});
    const [searchkey, setSearchkey] = useState<string>('');
    const [searchedItems, setSearchedItems] = useState<Array<any>>([]);
    const handleChange = (selected: any) => setPatientsIds(Array.from(selected).filter(v => v !== 0));
    
    const { setSnackBarMessage } = useContext(SnackBarContext);
    const intervenants =
        eventData?.professionals?.map((intervenant: any) => ({
            id: Number(intervenant.attendeeUser.id),
            type: 2,
        })) ?? [];

    function convertToTimeZoneDate(date: string) {
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return moment.tz(date, timezone).format();
    }

    const attendees = patientsIds?.map((patient: any) => ({ id: Number(patient), type: 1 })) ?? [];

    const finalPayload = {
        calendarId: eventData?.calendarId,
        consultationTypeId: eventData?.consultationType?.id,
        description: eventData?.description,
        isInSitu: Number(eventData?.isInSitu || 0) || 0,
        locationId: eventData?.location?.id,
        specialityTypeId: eventData?.specialityType?.id,
        startDate: convertToTimeZoneDate(eventData?.startDate ?? 0),
        endDate: convertToTimeZoneDate(eventData?.endDate ?? 0),
        summary: eventData?.summary,
        typeId: eventData?.eventType?.id,
        attendees: [...attendees, ...intervenants],
    };

    useEffect(()=>{
        const currentEventPatient = eventData?.patients?.map((patient: any) => patient.attendeeUser.id);
        setPatientsIds(currentEventPatient?.length > 0 ? currentEventPatient : [0]);
    },[eventData?.patients])

    const showSnackBarMessage = () => {
        setSnackBarMessage('Patient rajouté avec succès');
        toast.success(OperationMessage.success);
    };

    const handleClick = async () => {
        setUpdating(true);
        EventService.updateEventViaDnd(eventData?.id, finalPayload)
            .then((res: any) => {
                if (res) {
                    showSnackBarMessage();
                    setUpdating(false);
                    setUpdateList((prev: boolean) => !prev);
                }
                toggleModal();
            })
            .catch(() => {
                setUpdating(false);
            });
    };

    const disabled = patientsIds.length < 1;

    const handleSearch = (value: string) => {
        setSearchkey(value);
        setSearchedItems(searchElementByName(optimezedPatients(), value));
    };

    const items = searchkey?.trim()?.length ? searchedItems : optimezedPatients();

    return (
        <CustomModal width={300} title="Nouveaux Patients" toggleModal={toggleModal} open={modalOpen}>
            <Box style={{ marginTop: 20, marginBottom: 25 }}>
                <ListItemFormCheckBox
                    contentHeight={225}
                    maxItems={50}
                    loading={fetching}
                    labelKey="name"
                    items={items ?? []}
                    selectedRows={new Set(patientsIds?.length > 0 ? patientsIds : [0])}
                    handleChange={(selected: Set<number>) => handleChange(selected)}
                    handleSearch={handleSearch}
                    isPatients
                />
            </Box>

            <CustomLoadingButton
                disabled={disabled}
                handleClick={handleClick}
                loading={updating}
                width={300}
                label="Ajouter les patients"
            />
        </CustomModal>
    );
};

export default ModalAddMorePatients;
