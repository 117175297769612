import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { CustomAutocomplete } from 'src/components/CustomAutocomplete/CustomAutocomplete';
import CustomDatePicker from 'src/components/CustomDatePicker';
import CustomCircleLoader from 'src/components/CustomLoader/CustomCircleLoader';
import CustomModal from 'src/components/CustomModal';
import { InputField } from 'src/components/InputField/InputField';
import { Spacer } from 'src/components/Spacer/Spacer';
import { CustomText } from 'src/components/Text/CustomText';
import { PatientService } from 'src/services/Patients';
import ProgramService from 'src/services/Program/program.service';
import { baseFontFamily, baseColors } from 'src/utils/constants/baseStyles';
import { ServerError } from 'src/utils/constants/constants';
import { renderTitle } from 'src/views/Programme/Synthese/utils';
import { CustomSelect } from '../../../../../components/InputField/CustomSelect';
import useStyles from './styles';
import { ObservationType } from 'src/interfaces/interfaces';
import UsersSelectList from 'src/views/MyAgenda/Planning/UsersSelectList';
import { fullName } from 'src/utils/utils';

interface ReportDetailsProps {
    open: boolean;
    setOpen: Function;
    patientId?: number;
    reportData?: any;
}

export const ReportDetails: FC<ReportDetailsProps> = (props) => {
    const { open, setOpen, patientId, reportData } = props;
    const defaultReport = {
        patientId: patientId,
        name: '',
        programEnteringReason: '',
        medicalAntecedent: '',
        treatments: '',
        educationalDiagnosisConclusion: '',
        followedProgramContent: '',
        interviewConclusion: '',
        tool: '',
    };

    const [report, setReport] = useState<any>(defaultReport);
    const [loading, setLoading] = useState<boolean>(false);
    const classes = useStyles();

    useEffect(() => {
        setReport((old: any) => ({ ...old, patientId: patientId }));

        if (!reportData) {
            setReport(defaultReport);
        } else {
            setLoading(true);
            setReport({
                name: reportData?.name,
                programEnteringReason: reportData?.programEnteringReason,
                medicalAntecedent: reportData?.medicalAntecedent,
                treatments: reportData?.treatments,
                educationalDiagnosisConclusion: reportData?.educationalDiagnosisConclusion,
                followedProgramContent: reportData?.followedProgramContent,
                interviewConclusion: reportData?.interviewConclusion,
                tool: reportData?.tool,
            });
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportData]);

    return (
        <div>
            <CustomModal width={700} open={open} toggleModal={setOpen}>
                <CustomText style={{ textAlign: 'center' }} fontSize={20} fontFamily={baseFontFamily.BreeSerif}>
                    Details du compte rendu
                </CustomText>
                <div className={classes.scrollContainer} style={{ height: 300, marginTop: 30, paddingRight: 15 }}>
                    <Spacer height={20} />
                    {loading ? (
                        <div style={{ display: 'flex', justifyContent: 'center', padding: 20 }}>
                            <CustomCircleLoader />
                        </div>
                    ) : (
                        <div className={classes.col}>
                            <div style={{ maxWidth: '100%' }}>
                                <div
                                    className={classes.row}
                                    style={{ alignItems: 'center', justifyContent: 'space-between' }}
                                >
                                    <CustomText
                                        fontFamily={baseFontFamily.OpenSansSemiBold}
                                        color={baseColors.orange.primary}
                                    >
                                        Quels est le titre du compte rendu ? :
                                    </CustomText>
                                    <CustomText style={{ maxWidth: '50%', marginRight: 20 }}>{report?.name}</CustomText>
                                </div>
                                <Spacer height={20} />
                                <div
                                    className={classes.row}
                                    style={{ alignItems: 'center', justifyContent: 'space-between' }}
                                >
                                    <CustomText
                                        fontFamily={baseFontFamily.OpenSansSemiBold}
                                        color={baseColors.orange.primary}
                                    >
                                        Quels sont les motifs d'entrée dans le programme ? :
                                    </CustomText>
                                    <CustomText style={{ maxWidth: '50%' }}>{report?.programEnteringReason}</CustomText>
                                </div>
                                <Spacer height={20} />
                                <div
                                    className={classes.row}
                                    style={{ alignItems: 'center', justifyContent: 'space-between' }}
                                >
                                    <CustomText
                                        fontFamily={baseFontFamily.OpenSansSemiBold}
                                        color={baseColors.orange.primary}
                                    >
                                        Quels sont les antécédents médicaux ? :
                                    </CustomText>
                                    <CustomText style={{ maxWidth: '50%' }}>{report?.medicalAntecedent}</CustomText>
                                </div>
                                <Spacer height={20} />
                                <div
                                    className={classes.row}
                                    style={{ alignItems: 'center', justifyContent: 'space-between' }}
                                >
                                    <CustomText
                                        fontFamily={baseFontFamily.OpenSansSemiBold}
                                        color={baseColors.orange.primary}
                                    >
                                        Quels sont les traitements à appliquer ? :
                                    </CustomText>
                                    <CustomText style={{ maxWidth: '50%' }}>{report?.treatments}</CustomText>
                                </div>
                                <Spacer height={20} />
                                <div
                                    className={classes.row}
                                    style={{ alignItems: 'center', justifyContent: 'space-between' }}
                                >
                                    <CustomText
                                        fontFamily={baseFontFamily.OpenSansSemiBold}
                                        color={baseColors.orange.primary}
                                    >
                                        Quels sont les conclusions du diagnostique éducatif ? :
                                    </CustomText>
                                    <CustomText style={{ maxWidth: '50%' }}>
                                        {report?.educationalDiagnosisConclusion}
                                    </CustomText>
                                </div>
                                <Spacer height={20} />
                                <div
                                    className={classes.row}
                                    style={{ alignItems: 'center', justifyContent: 'space-between' }}
                                >
                                    <CustomText
                                        fontFamily={baseFontFamily.OpenSansSemiBold}
                                        color={baseColors.orange.primary}
                                    >
                                        Quel est le contenu du programme suivi ? :
                                    </CustomText>
                                    <CustomText style={{ maxWidth: '50%' }}>
                                        {report?.followedProgramContent}
                                    </CustomText>
                                </div>
                                <Spacer height={20} />
                                <div
                                    className={classes.row}
                                    style={{ alignItems: 'center', justifyContent: 'space-between' }}
                                >
                                    <CustomText
                                        fontFamily={baseFontFamily.OpenSansSemiBold}
                                        color={baseColors.orange.primary}
                                    >
                                        Quel est la conclusion de l'entretien d'évaluation ? :
                                    </CustomText>
                                    <CustomText style={{ maxWidth: '50%' }}>{report?.interviewConclusion}</CustomText>
                                </div>
                                <Spacer height={20} />
                                <div
                                    className={classes.row}
                                    style={{ alignItems: 'center', justifyContent: 'space-between' }}
                                >
                                    <CustomText
                                        fontFamily={baseFontFamily.OpenSansSemiBold}
                                        color={baseColors.orange.primary}
                                    >
                                        Quel est l'outil utilisé ? :
                                    </CustomText>
                                    <CustomText style={{ maxWidth: '50%' }}>{report?.tool}</CustomText>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </CustomModal>
        </div>
    );
};
