import { makeStyles } from '@mui/styles';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';

const useStyles = makeStyles({
    container: {
        flexGrow: 1,
        overflowY: 'scroll',
        height: 'calc(100vh - 520px)',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        marginTop: 10,
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    title: {
        color: baseColors.black,
        fontFamily: baseFontFamily.BreeSerif,
    },
    flexCenter: {
        display: 'flex',
        justifyContent: 'center',
    },
    sideMenu: {
        flex: 1,
        background: baseColors.green.light,
        padding: 15,
        maxWidth: '25%',
        height: '60vh',
        borderRadius: 8,
    },
    col: { display: 'flex', flexDirection: 'column' },
    scrollContainer: {
        overflow: 'auto',
        position: 'relative',
        overflowY: 'scroll',
    },
});

export const sxStyles: any = {
    scrollContainer: {
        overflow: 'auto',
        position: 'relative',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        paddingLeft: 20,
        paddingRight: 20,
        overflowY: 'scroll',
        height: '100%',
    },
    goalContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    goalContent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        flex: 2 / 4,
    },
};

export default useStyles;
