import { NotificationActionType } from 'src/actions/notification/types';
interface NotificationState {
    listNotifications: any[];
    notificationIds: any[];
    hasNonFocusedNotifications: boolean;
}

const initialState: NotificationState = {
    listNotifications: [],
    notificationIds: [],
    hasNonFocusedNotifications: false,
};

const notificationReducer = (notificationState: NotificationState = initialState, action: any) => {
    const { type, payload } = action;

    switch (type) {
        case NotificationActionType.LIST_NOTIFICATIONS:
            let notifications: any[] = [];

            payload.forEach((element: any) => {
                notifications.push({
                    label: element.title,
                    action: element.actionType.name,
                    date: element.createdAt,
                    id: element.id,
                    title: element.title,
                    content: element.content,
                    eventDate: element.eventDate,
                    activated: element.activated,
                    actionType: element.actionType,
                    closed: element.closed,
                    focused: element.focused,
                    senderId: element.senderId,
                    recipient: element.recipient,
                    urlType: element.url.type,
                    mobileRoutePath: element.mobileRoutePath,
                    urlParameter: element.url.parameter,
                    observationId: element.url.data.observationId,
                });
            });

            let notificationIds: any[] = [];

            notifications.forEach((notification: any) => {
                if (notification.activated === 1 && notification?.action !== undefined) {
                    notificationIds.push(notification.id);
                }
            });
            
            return {
                ...notificationState,
                listNotifications: notifications,
                notificationIds: notificationIds,
                hasNonFocusedNotifications: notificationIds.length > 0 ? true : false,
            };
        default:
            return notificationState;
    }
};

export default notificationReducer;
