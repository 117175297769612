import { makeStyles } from '@mui/styles';
import { baseColors } from 'src/utils/constants/baseStyles';

const useStyles = makeStyles({
    header: {
        margin: '10px 0 10px 0',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    headerItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    content: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
});

export const sxStyles: any = {
    loading: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 10,
    },
    callVisio: {
        fontSize: 50,
        color: baseColors.orange.primary,
    },
    warningVisioText: {
        fontStyle: 'italic',
        fontSize: 14,
        color: baseColors.orange.primary,
        TextAlign: 'center',
        justifyContent: 'center',
        alignContent: 'center',
        display: 'flex',
    },
};

export default useStyles;
