import { Grid } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { CustomSelect } from 'src/components/InputField/CustomSelect';
import { InputField } from 'src/components/InputField/InputField';
import { Spacer } from 'src/components/Spacer/Spacer';
import { CustomText } from 'src/components/Text/CustomText';
import EventVisibility from './EventVisibility';
import { useFetchEventLocationRQHooks } from 'src/hooks/ReactQueryHooks/useEvenRQtHooks';

interface EventRoomAndRemarkProps {
    payload?: any;
    setEventPayload?: any;
    loadingData?: any;
    isVisio?: boolean;
    eventData?: any;
    selectedWorkshopData?: any;
}

export interface LocationType {
    id: number;
    name: string;
}

const EventRoomAndRemark: FC<EventRoomAndRemarkProps> = (props) => {
    const { payload, setEventPayload, loadingData, isVisio, eventData, selectedWorkshopData } = props;
    const [remark, setRemark] = useState<string>(eventData?.description ?? '');
    const [location, setLocation] = useState<LocationType>({} as LocationType);
    const { data: locationData, isFetching: fetchingLocations } = useFetchEventLocationRQHooks();
    const locations = fetchingLocations ? [] : locationData ?? [];
    const locationDataItems = [{ id: -1, name: 'Sélectionner une salle' }, ...locations];

    const handleChange = (value: number) =>
        setEventPayload((old: any) => ({
            ...old,
            locationId: Number(value),
            customLocation: Number(value),
        }));

    const handleSetConsultationFromWorkshop = () => {
        if (!eventData) {
            setLocation(selectedWorkshopData?.customLocation);
            setEventPayload((old: any) => ({
                ...old,
                customLocation: selectedWorkshopData?.customLocation?.id,
            }));
        }
    };

    useEffect(() => {
        if (eventData) {
            const customLocationValue = eventData?.location ?? '';
            setLocation(customLocationValue);
            customLocationValue?.id > 0 && handleChange(customLocationValue?.id);
        }
    }, [eventData]);

    useEffect(() => {
        if(payload?.workshopId > 0){
            handleSetConsultationFromWorkshop();
        }
    }, [payload?.workshopId]);

    return (
        <>
            {!isVisio && (
                <>
                    <Grid container direction="row" alignItems={'center'} justifyContent={'space-between'} style={{}}>
                        <Grid item>
                            <CustomText>Salle : </CustomText>
                        </Grid>

                        <Grid item style={{ width: '60%' }}>
                            {fetchingLocations ? (
                                loadingData
                            ) : (
                                <CustomSelect
                                    onChange={handleChange}
                                    label=""
                                    items={locationDataItems}
                                    labelKey="name"
                                    defaultValue={location?.id ?? 0}
                                    idSelect="selection-salle"
                                    width="100%"
                                />
                            )}
                        </Grid>
                    </Grid>
                    <Spacer />
                </>
            )}

            <EventVisibility eventData={eventData} loadingData={loadingData} setEventPayload={setEventPayload} />

            <Spacer />

            <Grid container direction="row" alignItems={'self-start'} justifyContent="space-between">
                <Grid item>
                    <CustomText>Remarques :</CustomText>
                </Grid>
                <Grid item style={{ marginBottom: 10 }}>
                    <InputField
                        multiline
                        height={90}
                        label=""
                        placeholder="Taper votre texte"
                        onChange={(value: any) => {
                            setRemark(value);
                            setEventPayload((old: any) => ({ ...old, description: value }));
                        }}
                        width={345}
                        value={remark}
                        defaultInputId="event"
                        idSuffix="remark"
                    />
                </Grid>
            </Grid>

            <Spacer />
        </>
    );
};

export default EventRoomAndRemark;
