import { Dispatch, FC, Fragment, SetStateAction, useState } from 'react';
import { Box, Collapse, IconButton, Tooltip, Typography } from '@mui/material';
import { baseColors } from 'src/utils/constants/baseStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ModuleService from 'src/services/Module/module.service';
import { CustomConfirm } from 'src/components/CustomConfirm/CustomConfirm';
import { toast } from 'react-toastify';
import { tableCellText } from 'src/views/Security/SecurityManagement/SecurityManagement';
import { OpenSansSemiBold } from 'src/components/Text/OpenSansSemiBold';
import { OpenSansRegularText } from 'src/components/Text/OpenSansRegularText';
import { Workshop } from 'src/interfaces/interfaces';
import { WorkshopForm } from 'src/views/WorkshopForm/WorkshopForm/WorkshopForm';
import styles from '../Workshops/styles';
import useWorkshops from 'src/hooks/useWorkshops';
import { fullName, uniqByKeyValues } from 'src/utils/utils';

type IProps = {
    row: any;
    onEdit: any;
    setUpdateList: Dispatch<SetStateAction<boolean>>;
};

export const ModuleItem: FC<IProps> = (props) => {
    const { row, onEdit, setUpdateList } = props;
    const [open, setOpen] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [modalConfirmVisible, setModalConfirmVisible] = useState(false);
    const toggleModal = () => setModalConfirmVisible(!modalConfirmVisible);

    const [openModalWorkshop, setOpenModalWorkshop] = useState(false);
    const [modalConfirmWorkshopVisible, setModalConfirmWorkshopVisible] = useState(false);
    const [workshop, setWorkshop] = useState<any>({});
    const [consultationData, setConsultationData] = useState<any>({});
    const [workshopToDeleteId, setWorkshopToDeleteId] = useState(0);

    const { handleDeleteWorkshop, loading } = useWorkshops({
        setModalConfirmVisible,
        setUpdateList
    })

    const handleEditWorkshop = (workshopRow:any) => {
        const optimizedAttendees = uniqByKeyValues({
            items: Array.from(workshopRow?.attendees ?? [], (item: any) => ({
                id: item?.attendeeUser?.id,
                firstName: item?.attendeeUser?.firstName,
                lastName: item?.attendeeUser?.lastName,
                name: fullName(item?.attendeeUser?.firstName, item?.attendeeUser?.lastName),
                specialityId: item?.attendeeUser?.specialityType?.id,
            })),
            key: 'id',
        });
        setConsultationData({
            remark: workshopRow.remark,
            consultationTypeId: workshopRow?.consultationType?.id,
            location: workshopRow.customLocation,
            data: workshopRow,
        });

        setWorkshop({
            ...workshopRow,
            attendees: optimizedAttendees,
            consultationTypeId: workshopRow?.consultationType?.id
        });
        setOpenModalWorkshop(!openModalWorkshop);
    }

    const performDeleteWorkshop = () => {
        handleDeleteWorkshop(workshopToDeleteId);
    }

    const performDeleteModule = () => {
        if (row.id > 0) {
            setDeleting(true);
            ModuleService.deleteModule(row.id)
                .then((res) => {
                    if (res?.data?.code === 409) {
                        toast.error('Ce module est déjà lié à un autre enregistrement!');
                        toggleModal();
                        setDeleting(false);
                    } else {
                        toast.success('Suppression du module réussie !');
                        setUpdateList((prev) => !prev);
                        setDeleting(false);
                    }
                })
                .catch(() => {
                    toast.error('Une erreur est survenue lors de la suppression du module');
                    setDeleting(false);
                });
        }
    };

    return (
        <>
            <TableRow
                sx={{
                    '& .MuiTableCell-root': {
                        padding: '2px',
                    },
                    borderBottom:"1px solid #e0e0e0"
                }}
            >
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>

                <TableCell align="left" >
                    <div style={{ width: '30%', marginRight: 50 }}>{tableCellText(row.name)}</div>
                    {/* <Fragment>
                        {[1, 2].map((item) => (
                            <OpenSansSemiBold
                                style={{
                                    padding: 7,
                                    backgroundColor: baseColors.green.light,
                                    marginRight: 10,
                                    borderRadius: 5,
                                }}
                                fontSize={10}
                                key={item}
                            >
                                Programme {item}
                            </OpenSansSemiBold>
                        ))}
                    </Fragment> */}
                </TableCell>

                <TableCell align="left">
                    <IconButton onClick={() => onEdit(row)}>
                        <EditOutlinedIcon
                            sx={{
                                fontSize: 'medium',
                                color: baseColors.green.primary,
                                cursor: 'pointer',
                            }}
                            onClick={() => {}}
                        />
                    </IconButton>
                    <IconButton onClick={toggleModal}>
                        <DeleteOutlineOutlinedIcon
                            sx={{
                                fontSize: 'medium',
                                color: 'red',
                                cursor: 'pointer',
                            }}
                        />
                    </IconButton>
                </TableCell>
            </TableRow>

            {row?.workshops?.length > 0 && (
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                                <Typography variant="h6">Ateliers</Typography>
                                <Table size="small" aria-label="purchases">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{width:"70%"}}>Nom</TableCell>
                                            <TableCell align="left">Durée</TableCell>
                                            <TableCell align="left">Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {row.workshops.map((workshopRow: any, index: number) => (
                                            <TableRow key={index}>
                                                <TableCell sx={{width:"70%"}}>
                                                    <OpenSansRegularText>{workshopRow.name}</OpenSansRegularText>
                                                </TableCell>
                                                <TableCell align="left">
                                                    <OpenSansRegularText>{workshopRow.duration}</OpenSansRegularText>
                                                </TableCell>
                                                <TableCell>
                                                    <IconButton onClick={() => handleEditWorkshop(workshopRow)}>
                                                        <Tooltip title={`Atelier : ${workshopRow?.name}`}>
                                                            <EditOutlinedIcon
                                                                sx={{
                                                                    fontSize: 'medium',
                                                                    color: baseColors.green.primary,
                                                                    cursor: 'pointer',
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    </IconButton>
                                                    <IconButton
                                                        onClick={() => {
                                                            setWorkshopToDeleteId(workshopRow.id);
                                                            setModalConfirmWorkshopVisible(true);
                                                        }}
                                                    >
                                                        <Tooltip title={`Atelier : ${workshopRow?.name}`}>
                                                            <DeleteOutlineOutlinedIcon sx={styles.delete} />
                                                        </Tooltip>
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            )}

            <WorkshopForm
                forAdmin
                consultationData={consultationData}
                workshopData={workshop}
                setUpdateList={setUpdateList}
                modalToggle={setOpenModalWorkshop}
                modalOpen={openModalWorkshop}
            />

            <CustomConfirm
                defaultLoading={deleting}
                modalVisible={modalConfirmVisible}
                updateModalVisible={setModalConfirmVisible}
                message="Voulez-vous vraiment supprimer ce module ? "
                callBackconfirm={performDeleteModule}
            />

            <CustomConfirm
                defaultLoading={loading}
                modalVisible={modalConfirmWorkshopVisible}
                updateModalVisible={setModalConfirmWorkshopVisible}
                message="Voulez-vous vraiment supprimer cet atelier ? "
                callBackconfirm={performDeleteWorkshop}
            />
        </>
    );
};
