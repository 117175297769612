import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    container: {
        padding: 20,
    },
})

export const sxStyles: any = {
    time: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 10,
    },
    timePicker: { width: 100 },
    intervenants: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: 10,
    },
};

export default useStyles;
