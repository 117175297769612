import { FC } from 'react';
import AgendaProvider from './AgendaProvider';
import AgendaSettingsProvider from './AgendaSettingsProvider';
import AuthProvider from './AuthProvider';
import DashboardProvider from './DashboardProvider';
import EducationalDiagnosisProvider from './EducationalDiagnosisProvider';
import MenuAuthorizationProvider from './MenuAuthorizationProvider';
import PatientProvider from './PatientProvider';
import PermissionProvider from './PermissionProvider';
import { ProgramProvider } from './ProgramProvider';
import SnackBarProvider from './SnackBarProvider';
import UserProvider from './UserProvider';
import VisioContentProvider from './VisioContentProvider';
import EtpProvider from './EtpProvider';
import HdjProvider from './HdjProvider';
import WorkshopProvider from './WorkshopProvider';
import ArticleProvider from './ArticleProvider';

export const Provider: FC = ({ children }) => (
    <AuthProvider>
        <UserProvider>
            <MenuAuthorizationProvider>
                <AgendaSettingsProvider>
                    <SnackBarProvider>
                        <ProgramProvider>
                            <DashboardProvider>
                                <AgendaProvider>
                                    <PermissionProvider>
                                        <PatientProvider>
                                            <EducationalDiagnosisProvider>
                                                <VisioContentProvider>
                                                    <HdjProvider>
                                                        <EtpProvider>
                                                            <WorkshopProvider>
                                                                <ArticleProvider>{children}</ArticleProvider>
                                                            </WorkshopProvider>
                                                        </EtpProvider>
                                                    </HdjProvider>
                                                </VisioContentProvider>
                                            </EducationalDiagnosisProvider>
                                        </PatientProvider>
                                    </PermissionProvider>
                                </AgendaProvider>
                            </DashboardProvider>
                        </ProgramProvider>
                    </SnackBarProvider>
                </AgendaSettingsProvider>
            </MenuAuthorizationProvider>
        </UserProvider>
    </AuthProvider>
);
