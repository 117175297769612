import { Grid } from '@mui/material';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { CustomSelect } from 'src/components/InputField/CustomSelect';
import { InputField } from 'src/components/InputField/InputField';
import { CustomText } from 'src/components/Text/CustomText';
import { isNotBlank } from 'src/utils/helpers/InputValidator';
import { defaultDuration, durations, handleSetPayload, thirtyMinitDuration } from 'src/utils/constants/constants';
import { Spacer } from 'src/components/Spacer/Spacer';

type IProps = {
    lockConsultationInput: boolean;
    payload: {
        name: string;
    };
    setPayload: Dispatch<SetStateAction<any>>;
    dayHospitalId?: number;
    consultData: any;
    loadingData?: any;
};

export const NameAndDuration: FC<IProps> = ({ lockConsultationInput, setPayload, payload, consultData, loadingData }) => {
    const allDurations = durations?.flat(1);
    const consultationDurations = [{ id: 0, name: 'Sélectionner une durée' }, thirtyMinitDuration, ...allDurations];
    const defaultValue = consultData?.duration ? defaultDuration(consultData?.duration) : 0;

    return (
        <>
            <Grid
                style={{ marginTop: 20 }}
                lg={12}
                container
                direction="row"
                alignItems={'center'}
                justifyContent="space-between"
            >
                <Grid item>
                    <CustomText>Nom de la consultation :</CustomText>
                </Grid>
                <InputField
                    label=""
                    disabled={lockConsultationInput}
                    onChange={(value: any) => setPayload((old: any) => ({ ...old, name: value }))}
                    validator={isNotBlank}
                    errorMessage="Nom de la consultation obligatoire"
                    placeholder="Entrer un nom de la consultation ..."
                    width={360}
                    value={payload.name}
                    idSuffix="nom_atelier"
                />
            </Grid>

            <Spacer height={10} />

            <Grid lg={12} container direction="row" alignItems="center" justifyContent="space-between">
                <Grid item>
                    <CustomText>Durée de la consultation :</CustomText>
                </Grid>

                <Grid item style={{ width: '60%' }}>
                    <CustomSelect
                        onItemClick={() => {}}
                        onChange={(value: number) => handleSetPayload(value, setPayload)}
                        label=""
                        labelKey="name"
                        items={consultationDurations}
                        defaultValue={defaultValue}
                        idSelect="duration-name"
                        width="100%"
                    />
                </Grid>
            </Grid>

            <Spacer />
        </>
    );
};
