import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
});

export const sxStyles: any = {};

export default useStyles;
