import { FC, useContext, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { baseColors, baseFontFamily, baseFontWeight } from 'src/utils/constants/baseStyles';
import Button from '@mui/material/Button';
import { HdjContext } from 'src/providers/HdjProvider';
import CustomEmptyData from 'src/components/CustomEmptyData';
import CustomLoader from 'src/components/CustomLoader/CustomLoader';
import { getHdjDocuments } from 'src/services/Documents';
import ModalAddDocument from './ModalAddDocument';

interface DocumentListProps {
    fetchedHdjData?: any;
    dayHospitalId?: number;
    withoutBtn?: boolean;
}

const DocumentList: FC<DocumentListProps> = (props) => {
    const { fetchedHdjData, dayHospitalId, withoutBtn } = props;
    const [loadingDocuments, setLoadingDocuments] = useState<boolean>(false);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [updateList, setUpdateList] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState<string>('');
    const [documents, setDocuments] = useState<Array<any>>([]);
    const { currentHdjInfos } = useContext(HdjContext);
    const id = currentHdjInfos?.id ?? dayHospitalId;
    const handleFetchData = async () => {
        setLoadingDocuments(true);
        await getHdjDocuments(id, searchValue)
            .then((res: any) => {
                if(res?.data?.data?.items)
                    setDocuments(res.data.data?.items);  
                setLoadingDocuments(false);
            })
            .catch(() => setLoadingDocuments(false));
    };

    useEffect(() => {
        handleFetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, updateList]);

    const isEmpty = Boolean(documents?.length === 0);
    const toggleModal = () => setModalOpen(!modalOpen);

    return (
        <Box sx={{ minWidth: 300, marginBottom: 0 }} marginRight={2}>
                <Typography
                    fontFamily={baseFontFamily.OpenSansSemiBold}
                    fontSize={13}
                >
                    Liste des documents :
                </Typography>
            <Box
                style={{ marginTop: 20, marginBottom: 25 }}
                sx={{
                    backgroundColor: baseColors.green.light,
                    height: 270,
                    marginBottom: 5,
                    borderRadius: 2,
                    padding: 2,
                }}
            >
                <Box
                    style={{
                        overflow: 'hidden',
                        overflowY: 'scroll',
                        height: '86%',
                    }}
                    sx={{ marginTop: 2 }}
                >
                    {loadingDocuments ? (
                        <CustomLoader loadingNumber={10} />
                    ) : !isEmpty ? (
                        documents?.length > 0 &&
                        documents.map((document: any, i: number) => (
                            <Typography
                                key={document?.id}
                                color={baseColors.black}
                                fontFamily={baseFontFamily.OpenSansSemiBold}
                                fontSize={13}
                                marginBottom={2}
                            >
                                {document?.name ?? ''}
                            </Typography>
                        ))
                    ) : (
                        <CustomEmptyData />
                    )}
                </Box>
            </Box>
            {!withoutBtn && (
                <Button
                    onClick={toggleModal}
                    style={{
                        width: '100%',
                        color: baseColors.white,
                        backgroundColor: baseColors.green.primary,
                        borderColor: 'transparent',
                    }}
                    variant="outlined"
                    size="large"
                >
                    <Typography fontWeight={baseFontWeight.semiBold} fontSize={10}>
                        Ajouter des documents
                    </Typography>
                </Button>
            )}

            <ModalAddDocument
                fetchedHdjData={fetchedHdjData}
                hdjDocuments={documents}
                toggleModal={toggleModal}
                modalOpen={modalOpen}
                updateList={updateList}
                setUpdateList={setUpdateList}
                setSearchValue={setSearchValue}
            />
        </Box>
    );
};

export default DocumentList;
