import { Grid, Radio } from '@mui/material';
import React, { FC } from 'react';
import { styled } from '@mui/material/styles';
import { baseColors, baseFontFamily, baseFontSize } from 'src/utils/constants/baseStyles';
import { CustomText } from '../Text/CustomText';

interface CustomRadioProps {
    label?: string;
    checked?: boolean;
    onClick?: Function;
    color?: string;
    disabled?: boolean;
    fontFamily?: string;
    style?: React.CSSProperties;
    paddingTop?: number;
}
export const CustomRadio: FC<CustomRadioProps> = (props) => {
    const { paddingTop, style, label, checked, onClick, color, disabled, fontFamily } = props;
    const BpIcon = styled('span')(({ theme }) => ({
        borderRadius: '50%',
        border: `1px solid ` + baseColors.green.primary,
        width: 20,
        height: 20,
        boxShadow:
            theme.palette.mode === 'dark'
                ? '0 0 0 1px rgb(16 22 26 / 40%)'
                : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
        backgroundImage: baseColors.green.primary,
        '.Mui-focusVisible &': {
            outline: '2px auto blue',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
        },
    }));

    const BpCheckedIcon = styled(BpIcon)({
        backgroundColor: color ? color : baseColors.green.primary,
        border: `1px solid ` + color ? color : baseColors.green.primary,
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 20,
            height: 20,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: color ? color : baseColors.green.primary,
        },
    });

    return (
        <Grid
            direction="row"
            container
            style={
                style
                    ? { ...style, paddingTop: paddingTop ? paddingTop : 5 }
                    : { paddingTop: paddingTop ? paddingTop : 5 }
            }
            alignContent={'flex-start'}
            alignItems="center"
        >
            <Grid item flex={1}>
                <Radio
                    disabled={disabled ?? false}
                    onClick={() => (onClick ? onClick() : {})}
                    icon={<BpIcon />}
                    checkedIcon={<BpCheckedIcon />}
                    checked={checked ? checked : false}
                />
            </Grid>
            {label && (
                <Grid item flex={8}>
                    <CustomText fontSize={baseFontSize.mini} fontFamily={fontFamily ?? baseFontFamily.BreeSerif}>
                        {label}
                    </CustomText>
                </Grid>
            )}
        </Grid>
    );
};
