import { Grid } from '@mui/material';
import React, { FC } from 'react';
import { CustomText } from 'src/components/Text/CustomText';
import { baseColors, baseFontFamily, baseFontSize } from 'src/utils/constants/baseStyles';

interface InfoItemProps {
    label: string;
    value: any;
    valueColor?: string;
    icon?: React.ReactNode;
    href?: string;
    handlePress?: Function;
    isFile?: boolean;
}

export const InfoItem: FC<InfoItemProps> = (props) => {
    const { label, value, valueColor, icon, href, handlePress } = props;

    return (
        <Grid style={{ marginBottom: 10 }} container direction={'row'}>
            <CustomText fontFamily={baseFontFamily.OpenSansSemiBold} fontSize={baseFontSize.sm}>
                {`${label} : `}
            </CustomText>

            <CustomText
                color={valueColor || baseColors.black}
                style={{ marginLeft: 4 }}
                fontFamily={valueColor ? baseFontFamily.OpenSansSemiBold : baseFontFamily.OpenSansRegular}
                fontSize={baseFontSize.sm}
            >
                {` ${value}`}
            </CustomText>

            <div style={{ marginLeft: 10, cursor: 'pointer' }} onClick={() => handlePress?.()}>
                <a href={href} download>
                    {icon}
                </a>
            </div>
        </Grid>
    );
};
