import axios from './AxiosInstance';

const getCurrentEducationalDiagnosis = (userId: number) => {
    return axios.get(`api/patients/${userId}/educationalDiagnosis/current`);
};

const finishVisioCall = (idEducationalDiagnosis: number) =>
    axios.post(`api/educationalDiagnosis/${idEducationalDiagnosis}/endVisioconference`);

const deleteEducationalDiagnosisQuestions = (questionId: number) => {
    return axios.delete(`api/surveys/${questionId}`)
}

const getEducationalDiagnosisQuestions = () => {
    return axios.get(`api/surveys`);
}

const postEducationalDiagnosisQuestions = (data: any) => {
    return axios.post(`api/surveys`, data);
}

const putEducationalDiagnosisQuestions = (questionId: number, data: any) => {
    return axios.put(`api/surveys/${questionId}`, data)
}

const EducationalDiagnosisService = {
    getCurrentEducationalDiagnosis,
    finishVisioCall,
    deleteEducationalDiagnosisQuestions,
    getEducationalDiagnosisQuestions,
    postEducationalDiagnosisQuestions,
    putEducationalDiagnosisQuestions
};

export default EducationalDiagnosisService;
