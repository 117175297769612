import axios from '../AxiosInstance';

const getModules = (searchKey?: string) => {
    let additionalUrl: string = '';

    if (searchKey) {
        additionalUrl += `&keywords=${searchKey}`;
    }

    return axios.get('api/modules?paginated=false&sortId=DESC' + additionalUrl);
};

const searchModules = (keyword: any) => {
    return axios.get(`api/modules?paginated=false&name=${keyword}`);
};
const deleteModule = (moduleId: Number) => axios.delete(`api/modules/${moduleId}`);
const createModule = (data: any) => axios.post(`api/modules`, { ...data });
const updateModule = (moduleId: Number, data: any) => axios.put(`api/modules/${moduleId}`, { ...data });
const linkWorkshopToModule = (moduleId: Number, workshopIds: any) =>
    axios.post(`api/modules/${moduleId}/workshops`, { workshopIds: workshopIds });

const ModuleService = {
    createModule,
    deleteModule,
    updateModule,
    getModules,
    searchModules,
    linkWorkshopToModule,
};

export default ModuleService;
