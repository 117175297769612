export const RETRIEVE_CONNECTED_USER_INFOS = "RETRIEVE_CONNECTED_USER_INFOS";

export enum UsersActionType{
    LIST_USERS = 'LIST_USERS',
}

export interface UsersList {
    type: UsersActionType.LIST_USERS;
    payload: any;
}

export type UsersAction = UsersList;