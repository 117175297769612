import { makeStyles } from '@mui/styles';
import { baseBorderRadius, baseColors } from 'src/utils/constants/baseStyles';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: baseColors.white,
        ...baseBorderRadius.allSide,
    },
    leftContent: {
        height: '92vh',
        width: '96%',
    },
    rightContent: {
        height: '92vh',
        width: '100%',
        minWidth: 825,
    },
    patientListContainer: {
        height: `calc(100vh - 315px)`,
        overflowY: 'scroll',
        padding: '0 18px',
    },
    buttonContainer: {
        marginTop: 10,
        marginBottom: 10,
        alignContent: 'center',
        display: 'flex',
        justifyContent: 'center',
    },
});

export const sxStyles: any = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: '1rem',
    },
};

export default useStyles;
