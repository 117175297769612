import { Box } from '@mui/material';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import CustomModal from 'src/components/CustomModal';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import { InputField } from 'src/components/InputField/InputField';
import { CreateProgramThemeDiscussionPayload } from 'src/interfaces/interfaces';
import { isNotBlank } from 'src/utils/helpers/InputValidator';
import ProgramService from 'src/services/Program/program.service';
import { toast } from 'react-toastify';
import { CustomText } from 'src/components/Text/CustomText';
import { baseColors, baseFontSize } from 'src/utils/constants/baseStyles';

interface ModalCreateThemeProps {
    toggleModal: any;
    open: boolean;
    setUpdateList: Dispatch<SetStateAction<boolean>>;
    programId: number;
}

const ModalCreateTheme: FC<ModalCreateThemeProps> = (props) => {
    const { toggleModal, open, setUpdateList, programId } = props;
    const [themeName, setThemeName] = useState<string>('');
    const [creating, setCreating] = useState<boolean>(false);
    const [payload, setPayload] = useState<CreateProgramThemeDiscussionPayload>({
        name: '',
        programId: programId ?? 0,
    });

    const clearInput = () => {
        setThemeName('');
        setPayload({
            name: '',
            programId: 0,
        });
    };

    const handleChange = (value: string) => {
        setThemeName(value);
        setPayload((prev: CreateProgramThemeDiscussionPayload) => ({ programId, name: value }));
    };

    const handleCreateTheme = () => {
        setCreating(true);
        ProgramService.createDiscussionTheme({ ...payload })
            .then(() => {
                setCreating(false);
                toast.success('Nouveau thème ajouté avec succès.');
                clearInput();
                toggleModal();
                setUpdateList((prev) => !prev);
            })
            .catch(() => {
                toast.error("Erreur d'ajout du nouveau thème.");
                setCreating(false);
            });
    };

    const disableBtn = themeName?.trim()?.length < 5 || creating;
    const showError = themeName?.trim()?.length > 0 && themeName?.trim()?.length < 5;

    return (
        <CustomModal
            width={500}
            height={150}
            title="Créer un nouveau thème de discussion"
            toggleModal={toggleModal}
            open={open}
            footer={
                <div style={{ justifyContent: 'center', display: 'flex' }}>
                    <CustomLoadingButton
                        disabled={disableBtn}
                        handleClick={handleCreateTheme}
                        loading={creating}
                        width="80%"
                        label="CRÉER"
                    />
                </div>
            }
            clearFormData={clearInput}
        >
            <Box
                style={{
                    marginTop: 20,
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <div style={{}}>
                    <InputField
                        multiline
                        label={''}
                        onChange={handleChange}
                        validator={isNotBlank}
                        errorMessage="Nom du nouveau thème trop court"
                        placeholder={'Entrer un nom de votre nouveau thème ...'}
                        width={400}
                        height={80}
                        value={themeName}
                    />
                </div>

                {showError && (
                    <CustomText
                        style={{ fontStyle: 'italic', fontSize: baseFontSize.mini }}
                        color={baseColors.orange.primary}
                    >
                        ( Au moins 5 caractères )
                    </CustomText>
                )}
            </Box>
        </CustomModal>
    );
};

export default ModalCreateTheme;
