import { Typography } from '@mui/material';
import React, { FC } from 'react';
import { baseColors, baseFontFamily, baseFontSize } from 'src/utils/constants/baseStyles';

interface CustomTextProps {
    children: any;
    fontSize?: number | string;
    fontFamily?: string;
    fontStyle?: string;
    color?: string;
    style?: React.CSSProperties;
    marginBottom?: number;
    isBreefSerif?: boolean;
    isTitle?: boolean;
}

export const CustomText: FC<CustomTextProps> = (props) => {
    const { isTitle, isBreefSerif, children, fontSize, fontFamily, color, style, marginBottom, fontStyle } = props;
    const fontFamilyValue = isBreefSerif ? baseFontFamily.BreeSerif : fontFamily ?? baseFontFamily.OpenSansRegular;
    const fontSizeValue = isTitle ? baseFontSize.title : fontSize ?? baseFontSize.sm;

    return (
        <Typography
            style={style}
            fontFamily={fontFamilyValue}
            fontSize={fontSizeValue}
            fontStyle={fontStyle}
            color={color || baseColors.black}
            marginBottom={marginBottom ?? undefined}
        >
            {children}
        </Typography>
    );
};
