import React, { FC, useEffect, useState } from 'react';
import useStyles from './styles';
import { Grid } from '@mui/material';
import { CustomText } from 'src/components/Text/CustomText';
import { baseColors, baseFontFamily, baseFontSize } from 'src/utils/constants/baseStyles';
import { Spacer } from '../Spacer/Spacer';
import { CustomCheckBox } from '../InputField/CustomCheckBox';
import { CustomRadio } from '../InputField/CustomRadio';
export interface ChoiceItem {
    name: any;
    title: any;
    id: any;
    selected?: boolean;
}
interface MultiChoiceProps {
    title: string;
    choices: ChoiceItem[];
    type: 'checkbox' | 'radio';
    onChange?: Function;
    disabled: boolean;
    defaultSelected?: any;
    forceRerender?: boolean;
}
export const MultiChoice: FC<MultiChoiceProps> = (props) => {
    const { choices, title, disabled, type, onChange, defaultSelected, forceRerender } = props;
    const [items, setItems] = useState(choices);
    const [rerender, setRerender] = useState(false);
    const [selected, setSelected] = useState<any[]>(
        defaultSelected && defaultSelected instanceof Array ? [...defaultSelected] : [defaultSelected],
    );
    const classes = useStyles();

    useEffect(() => {
        if (choices) {
            setItems(choices);
            setRerender(!forceRerender);
        }
    }, [choices]);

    const onSelect = (value: any) => {
        if (type === 'checkbox') {
            let oldSelected = selected;
            if (oldSelected.includes(value)) {
                let index = oldSelected.indexOf(value);
                if (index > -1) oldSelected.splice(index, 1);
            } else {
                oldSelected.push(value);
            }
            setSelected(oldSelected);
            onChange && onChange(oldSelected);
        } else {
            setSelected([value]);
            onChange && onChange(value);
        }

        setRerender(!rerender);
    };

    const isEmpty = items?.length === 0;

    return (
        <Grid container direction={'column'} className={classes.container}>
            <CustomText fontSize={baseFontSize.md} fontFamily={baseFontFamily.BreeSerif} color={baseColors.black}>
                {title}
            </CustomText>

            <Spacer height={5} />

            {isEmpty ? (
                <CustomText
                    color={baseColors.orange.primary}
                    fontSize={12}
                    fontFamily={baseFontFamily.OpenSansSemiBold}
                    style={{ textAlign: 'center', marginTop: 10, marginBottom: 10 }}
                >
                    ( Aucune information disponible. )
                </CustomText>
            ) : (
                items?.map((item, index) =>
                    type === 'checkbox' ? (
                        <CustomCheckBox
                            checked={selected?.includes(item.id)}
                            onClick={() => {
                                onSelect(item.id);
                            }}
                            fontSize={baseFontSize.mini}
                            key={index}
                            disabled={disabled}
                            label={item.name ? item.name : item.title}
                        />
                    ) : (
                        <CustomRadio
                            disabled={disabled}
                            checked={selected?.includes(item.id)}
                            onClick={() => {
                                onSelect(item.id);
                            }}
                            key={index}
                            label={item.name ? item.name : item.title}
                        />
                    ),
                )
            )}
        </Grid>
    );
};
