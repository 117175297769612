import { fullName } from "src/utils/utils"

interface CheckBoxLabel { 
    isPatients: boolean | undefined; 
    isIntervenants: boolean | undefined; 
    item : any;
    labelKey:string | undefined;
    secondLabelKey:string | undefined;
    subMenuKey:string | undefined 
}

interface SetSelectedItems { 
    selected: Set<number>; 
    item: {id: number; name: string}; 
    setSelectedIntervenants : any;
    setSelected: Function | undefined;
    handleChange: Function | undefined;
    setForceRerender: Function | undefined
    forceRerender: boolean | undefined; 
}

const handleSetSelectedItems = ({
    selected,
    item,
    setSelectedIntervenants,
    setSelected,
    handleChange,
    setForceRerender,
    forceRerender
} : SetSelectedItems) => () => {
    let set = selected;
    if (!selected?.has(item.id)) {
        set.add(item.id);
        setSelectedIntervenants?.((prev: any) => [...prev, item]);
    } else {
        set.delete(item.id);
        setSelectedIntervenants?.((prev: any) => prev.filter((intervenant: any) => intervenant.id !== item?.id));
    }
    setSelected?.(set);
    handleChange?.(set);
    setForceRerender?.(!forceRerender);
};

const checkboxLabel = ({ 
    isPatients, 
    isIntervenants, 
    item,
    labelKey,
    secondLabelKey, 
    subMenuKey 
} : CheckBoxLabel) => 
    isPatients || isIntervenants
            ? fullName(item.firstName, item.lastName)
            : labelKey && !secondLabelKey && !subMenuKey
            ? `${item[labelKey]} `
            : labelKey && secondLabelKey && !subMenuKey
            ? `${item[labelKey]}  ${item[secondLabelKey]}`
            : labelKey && secondLabelKey && subMenuKey
            ? `${item[labelKey]}  ${item[secondLabelKey]} (${item[subMenuKey]})`
            : ''


export {
    checkboxLabel,
    handleSetSelectedItems
}