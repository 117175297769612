import { Grid } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { CustomText } from 'src/components/Text/CustomText';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import { PatientService } from 'src/services/Patients';
import CustomCircleLoader from 'src/components/CustomLoader/CustomCircleLoader';

interface ProgramQuizzProps {
    patientId: number;
}

const ProgramQuizz: FC<ProgramQuizzProps> = (props) => {
    const { patientId } = props;
    const [quiz, setQuiz] = useState<any[]>([]);
    const [fetchingQuiz, setFetchingQuiz] = useState<boolean>(false);

    const fetchRealizedQuiz = () => {
        setFetchingQuiz(true);
        PatientService.getQuizes(patientId)
            .then((response) => {
                const data = response?.data?.data;
                setQuiz(data);
                setFetchingQuiz(false);
            })
            .catch(() => setFetchingQuiz(false));
    };

    useEffect(() => {
        fetchRealizedQuiz();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patientId]);

    const noQuiz: boolean = quiz?.length === 0;

    type Quiz = {
        id : number,
        title : string
    }

    return (
        <Grid item>
            <CustomText fontSize={16} fontFamily={baseFontFamily.BreeSerif}>
                Quiz réalisés
            </CustomText>
            <Grid container style={{ marginTop: 20 }} direction={'column'}>
                {fetchingQuiz ? (
                    <CustomCircleLoader height={25} width={25} />
                ) : noQuiz ? (
                    <CustomText>Aucun quiz réalisé.</CustomText>
                ) : (
                    <Grid item>
                        {quiz?.length > 0 &&
                            quiz?.map((quizItem: { quizz: Quiz }) => (
                                <Grid key={quizItem?.quizz?.id} container direction={'row'}>
                                    <CustomText
                                        style={{ textDecoration: 'none' }}
                                        color={baseColors.green.primary}
                                        fontSize={14}
                                        fontFamily={baseFontFamily.OpenSansSemiBold}
                                    >
                                        {quizItem?.quizz?.title}
                                    </CustomText>
                                </Grid>
                            ))}
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};

export default ProgramQuizz;
