import EmailIcon from '@mui/icons-material/Email';
import { baseColors } from 'src/utils/constants/baseStyles';
import { ROUTES } from 'src/routes/routes';
import IconSettingsUsers from '../CustomIcon/IconSettingsUsers';
import { FC } from 'react';
import MenuItemContainer from './MenuItemContainer';

const AdminSubMenus: FC = () => {
    const settingsSubMenuData = [
        {
            id: 1,
            icon: <IconSettingsUsers height={20} width={20} />,
            iconActive: <IconSettingsUsers height={20} width={20} />,
            label: 'Utilisateurs',
            path: ROUTES.users,
            featureCode: 'front_display_parameter_users',
        },
        {
            id: 2,
            icon: <EmailIcon sx={{ color: baseColors.green.primary }} height={20} width={20} />,
            iconActive: <IconSettingsUsers height={20} width={20} />,
            label: 'Contact',
            path: ROUTES.contactSettings,
            featureCode: 'front_display_security_contact_sidebar_menu',
        },
    ];

    return <MenuItemContainer items={settingsSubMenuData} />;
};

export default AdminSubMenus;
