
export enum UserHistoryActionType{
    LIST_USER_HISTORY = 'LIST_USER_HISTORY',
}

export interface UserHistoryList{
    type: UserHistoryActionType.LIST_USER_HISTORY;
    payload: any;
}

export type UserHistoryAction = UserHistoryList;