import { FC } from 'react';
import useCheckUserRole from 'src/hooks/useCheckUserRole';
import CustomCircleLoader from '../CustomLoader/CustomCircleLoader';
import AdminSubMenus from './AdminSubMenus';
import CoordinatorSubMenus from './CoordinatorSubMenus';
import DoctorSubMenus from './DoctorSubMenus';

const SettingsSubMenuBar: FC = () => {
    const { roles } = useCheckUserRole();

    if (roles?.isAdmin) {
        return <AdminSubMenus />;
    }

    if (roles?.isCoordinator) {
        return <CoordinatorSubMenus />;
    }

    if (roles?.isDoctor) {
        return <DoctorSubMenus />;
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CustomCircleLoader />
        </div>
    );
};

export default SettingsSubMenuBar;
