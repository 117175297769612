import { Grid } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { CustomSelect } from 'src/components/InputField/CustomSelect';
import { InputField } from 'src/components/InputField/InputField';
import { Spacer } from 'src/components/Spacer/Spacer';
import { CustomText } from 'src/components/Text/CustomText';
import { getRooms } from 'src/services/Rooms';

interface EventRoomAndRemarkProps {
    setConsultation?: any;
    loadingData?: any;
    isVisio?: boolean;
    consultationData?: any;
}

const EventRoomAndRemark: FC<EventRoomAndRemarkProps> = (props) => {
    const { setConsultation, loadingData, isVisio, consultationData } = props;
    const [loading, setLoading] = useState<boolean>(false);
    const [remark, setRemark] = useState<string>(consultationData?.remark ?? '');
    const [customLocations, setCustomLocations] = useState<any[]>([]);
    const handleFecthDataCustom = () => {
        setLoading(true);
        getRooms().then((response) => {
            const data = response?.data?.data?.items?.flat(1)?.map((room: any, i: number) => ({
                id: room.id,
                name: room.name,
            }));
            setCustomLocations([{ id: 0, name: 'Sélectionner une salle' }, ...data]);
            setLoading(false);
        });
    };

    const defaultCustomRoom = consultationData?.data?.customLocation?.id ?? 0;

    useEffect(() => {
        handleFecthDataCustom();
        if (defaultCustomRoom > 0) {
            setConsultation((old: any) => ({ ...old, roomId: Number(defaultCustomRoom) }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        if (defaultCustomRoom) {
            setConsultation((old: any) => ({
                ...old,
                roomId: defaultCustomRoom,
                customLocation: defaultCustomRoom,
            }));
        }
    }, []);

    return (
        <>
            {!isVisio && (
                <>
                    <Grid container direction="row" alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <CustomText>Salle :</CustomText>
                        </Grid>
                        <Grid item style={{ width: '60%' }}>
                            {loading ? (
                                loadingData
                            ) : (
                                <CustomSelect
                                    onChange={(value: any) => {
                                        setConsultation((old: any) => ({ ...old, roomId: Number(value) }));
                                    }}
                                    label="salle"
                                    items={customLocations}
                                    labelKey={'name'}
                                    defaultValue={defaultCustomRoom}
                                    idSelect="selection-salle"
                                    width="100%"
                                />
                            )}
                        </Grid>
                    </Grid>

                    <Spacer />
                </>
            )}

            <Grid container direction="row" alignItems="self-start" justifyContent="space-between">
                <Grid item>
                    <CustomText> Remarques :</CustomText>
                </Grid>
                <Grid item>
                    <InputField
                        multiline
                        height={100}
                        label=""
                        placeholder={'Taper votre texte'}
                        onChange={(value: any) => {
                            setRemark(value);
                            setConsultation((old: any) => ({ ...old, remark: value }));
                        }}
                        width={360}
                        value={remark}
                        idSuffix="remarque"
                    />
                </Grid>
            </Grid>

            <Spacer />
        </>
    );
};

export default EventRoomAndRemark;
