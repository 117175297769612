import { FC } from 'react';
import AxisContainer from '../AxisContainer';
import { AXIS_IDENTIFICATION } from 'src/utils/constants/enums';

interface AxisDietetiqueProps {
    patientId: number;
    educationalDiagnosisId: number;
}

const AxisDietetique: FC<AxisDietetiqueProps> = ({ patientId, educationalDiagnosisId }) => {
    return (
        <AxisContainer
            isDietetic
            axisId={AXIS_IDENTIFICATION.DIET}
            patientId={patientId}
            educationalDiagnosisId={educationalDiagnosisId}
            title="Diététique"
            position={2}
            prevScreenPath="apa"
            nextScreenPath="psychologique"
        />
    );
};

export default AxisDietetique;
