import { baseScreenHeight } from 'src/utils/constants/baseStyles';

const sxStyles: any = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        paddingX: '1rem',
        ...baseScreenHeight.defaultHeight,
    },
    header: {
        // height: '50px',
        marginTop: '25px',
        marginBottom: '15px',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'space-between',
    },
    loading: {
        width: '100%',
        padding: 20,
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        display: 'flex',
        flexGrow: 1,
        flex: 6,
    },
    modalContainer: {
        marginTop: 30,
        justifyContent: 'space-between',
        alignItems: 'center',
        alignContent: 'center',
        display: 'flex',
    },
};

export { sxStyles };
