import { Dispatch, FC, SetStateAction } from 'react';
import { Box } from '@mui/material';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import { GoalItem, PostGoalPayload } from 'src/interfaces/interfaces';
import { sxStyles } from '../styles';
import useGoalHooks from 'src/hooks/useGoalHooks';
import { checkPayloadState, defaultGoalPaylopad } from '../Goals.utils';

type IProps = {
    loading: boolean;
    goal: GoalItem;
    setGoal: Dispatch<SetStateAction<GoalItem>>;
    setPayload: Dispatch<SetStateAction<PostGoalPayload>>;
    payload: PostGoalPayload;
    toggleModal: any;
    optionalWorkshopAndArticle?: boolean;
    setJustAddedGoalIds?: Dispatch<SetStateAction<number[]>>;
    callBack?: Function;
};

export const GoalFormFooter: FC<IProps> = (props) => {
    const { setGoal, goal, payload, optionalWorkshopAndArticle, toggleModal, setPayload,setJustAddedGoalIds, callBack, loading} = props;
    const { saving, handleCreateNewGoal, handleEditGoal } = useGoalHooks({
        toggleModal,
        setPayload,
        setGoal,
        setJustAddedGoalIds,
        callBack
    });

    const canSubmit = checkPayloadState(payload, optionalWorkshopAndArticle);

    const handleCancel = () => {
        setPayload(defaultGoalPaylopad);
        setGoal({} as GoalItem);
        toggleModal();
    };

    const handleClick = () => {
        if (goal?.id > 0) {
            handleEditGoal({ goalId: goal.id, payload });
        } else {
            handleCreateNewGoal({ payload });
        }
    };

    return (
        <Box style={sxStyles.footer}>
            <CustomLoadingButton
                outlined
                disabled={saving}
                width="100%"
                label="Annuler"
                handleClick={handleCancel}
                style={{ flex: 0.95 / 2 }}
            />
            <CustomLoadingButton
                loading={saving}
                disabled={saving || canSubmit || loading}
                width="100%"
                label={'Enregistrer'}
                handleClick={handleClick}
                style={{ flex: 0.95 / 2 }}
            />
        </Box>
    );
};
