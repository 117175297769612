import React, { FC } from 'react';
import { Typography, Box } from '@mui/material';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import CustomFilterArrowDown from 'src/components/CustomFilterArrowDown';

interface CustomFilterContentProps {
    label: string;
    filterValue: string;
    marginBottom?: number;
}

const CustomFilterContent: FC<CustomFilterContentProps> = (props) => {
    const { label, filterValue, marginBottom } = props;

    return (
        <Box marginBottom={marginBottom ?? 5}>
            <Typography
                marginBottom={2}
                fontFamily={baseFontFamily.OpenSansSemiBold}
                fontSize={13}
                color={baseColors.black}
            >
                {label} :
            </Typography>
            <CustomFilterArrowDown label={filterValue} />
        </Box>
    );
};

export default CustomFilterContent;
