import { makeStyles } from '@mui/styles';
import { baseColors } from 'src/utils/constants/baseStyles';

const useStyles = makeStyles({
    container: {
        padding: 10,
    },
    sideMenu: {
        flex: 1,
        background: baseColors.green.light,
        padding: 5,
        maxWidth: 200,
        height: '85vh',
        borderRadius: 8,
    },
    content: {
        // overflowY: 'scroll',
        // height: baseScreenHeight.patientDetailsHeight,
    },
    leftContent: {
        flex: 1,
        padding: 15,
        width: '30%',
        borderRadius: 8,
    },
    leftContentSynthese: {
        padding: 15,
        borderRadius: 8,
        width: '60%',
    },
    flexCenter: { display: 'flex', justifyContent: 'center' },
    row: { display: 'flex', flexDirection: 'row', marginBottom: 10 },
});

export const sxStyles: any = {
    scrollContainer: {
        overflow: 'auto',
        position: 'relative',
        paddingBottom: 200,
    },
    content: {
        flexDirection: 'column',
        flexGrow: 1,
        paddingLeft: 200,
        overflowY: 'scroll',
        width: '50%',
        height: '120%',
    },
};

export default useStyles;
