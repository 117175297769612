import { makeStyles } from '@mui/styles';
import { baseColors } from 'src/utils/constants/baseStyles';

const useStyles = makeStyles({
    row: {
        overflowY: 'scroll',
        overflow: 'hidden',
        flexDirection: 'row',
        display: 'flex',
        alignContent: 'center',
        justifyItems: 'center',
        justifyContent: 'space-around',
    },
    globalActivityRow: {
        overflowY: 'scroll',
        overflow: 'hidden',
        flexDirection: 'row',
        display: 'flex',
        alignContent: 'center',
        justifyItems: 'center',
        justifyContent: 'space-around',
        height: 'calc(100vh - 320px)',
    },
    rowSimple: {
        flexDirection: 'row',
        display: 'flex',
        justifyItems: 'center',
        marginTop: 40,
        justifyContent: 'space-between',
    },

    card: {
        background: baseColors.green.light,
        padding: 20,
        minWidth: '50%',
        marginRight: 40,
        borderRadius: 7,
    },
});

export default useStyles;
