import { makeStyles } from '@mui/styles';
import { baseColors } from 'src/utils/constants/baseStyles';

const useStyles = makeStyles({
    container: {
        '& .rwdpDayPickerContainer':{
            padding: '0 20px'
        },
        '& .rwdp-DayCircle': {
            width: '50px',
            height: '50px',
            borderRadius: 7,
            color: baseColors.black,
            backgroundColor: baseColors.green.light,
            borderColor: baseColors.green.light
        },
        '& .rwdp-DayCircle-selected': {
            color: baseColors.white,
            borderRadius: '7px',
            backgroundColor: baseColors.green.dark,
            width: '50px',
            height: '50px',
            borderColor: baseColors.green.dark
        },
        '& .rwdp-DayCircle-today' : {
            display: 'none'
        },
        '& .rwdpDayBoxDeskto' : {
            display: 'none'
        },
        '& .rwdpPrevWeekArrow' : {
            display: 'inline-block',
            border: `solid ${baseColors.green.primary}`,
            borderWidth: '0 3px 3px 0',
            padding: '7px',
            transform: 'rotate(135deg)',
            position: 'absolute',
            top: 'calc(50% + 3px)',
        }, 
        '& .rwdpNextWeekArrow' : {
            display: 'inline-block',
            border: `solid ${baseColors.green.primary}`,
            borderWidth: '0 3px 3px 0',
            padding: '7px',
            transform: 'rotate(-45deg)',
            position: 'absolute',
            top: 'calc(50% + 3px)',
        }, 
        '& .rwdp-DayCircle-unavailable-text': {
            display: 'none'
        }
    },
    
});

export default useStyles;
