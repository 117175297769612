export enum ConnexionActionType {
    LIST_CONNEXIONS = 'LIST_CONNEXIONS',
}

export interface ConnexionList {
    type: ConnexionActionType.LIST_CONNEXIONS;
    payload: any;
}

export type ConnexionAction = ConnexionList;
