

export enum NotificationActionType{
    LIST_NOTIFICATIONS = 'LIST_NOTIFICATIONS',
}

export interface NotificationList {
    type: NotificationActionType.LIST_NOTIFICATIONS;
    payload: any;
}

export type NotificationAction = NotificationList;