import { makeStyles } from '@mui/styles';
import { baseScreenHeight } from 'src/utils/constants/baseStyles';

const useStyles = makeStyles({
    container: {
        padding: 10,
        paddingTop: 25,
        background: 'white',
    },

    flexCenter: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
});

export default useStyles;
