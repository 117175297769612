import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { SnackBarContext } from 'src/providers/SnackBarProvider';

interface CustomizedSnackbarsProps {}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CustomizedSnackbars: React.FC<CustomizedSnackbarsProps> = (props) => {
    const { showSnackBar, snackBarMessage, setShowSnackBar } = React.useContext(SnackBarContext);

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowSnackBar(false);
    };

    return (
        <Snackbar
            open={showSnackBar}
            autoHideDuration={3000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
            <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                {snackBarMessage}
            </Alert>
        </Snackbar>
    );
};

export default CustomizedSnackbars;
