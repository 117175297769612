import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { retrieveUserHistories } from 'src/actions/userHistory/userHistory';
import { RootState } from 'src/stores/store';
import { baseColors } from 'src/utils/constants/baseStyles';
import UserHistoryTable from './UserHistoryTable';
import useStyles from './styles';
import CustomCircleLoader from 'src/components/CustomLoader/CustomCircleLoader';
import CustomEmptyData from 'src/components/CustomEmptyData';
import { CustomText } from 'src/components/Text/CustomText';

const UserHistoryPanel = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const userHistory = useSelector((state: RootState) => state.userHistories.listUserHistories);
    const [dataRows, setDataRows] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        dispatch(retrieveUserHistories({}, setLoading));
    }, []);

    useEffect(() => {
        parseUserHistoryData(userHistory);
    }, [userHistory]);

    const tableHeaders = [
        {
            name: 'Utilisateur',
        },
        {
            name: 'Titre',
        },
        {
            name: 'Date de modification',
        },
    ];

    const parseUserHistoryData = (userHistories: any[]) => {
        let rows: any[] = [];

        userHistories.forEach((userHistory) => {
            rows.push({
                id: userHistory.id,
                user: userHistory.user,
                title: userHistory.title,
                preview: userHistory.preview,
                current: userHistory.current,
                createdAt: userHistory.createdAt,
            });
            return rows;
        });

        setDataRows(rows);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                paddingX: '25px',
            }}
        >
            <Box
                sx={{
                    height: '50px',
                    marginTop: '25px',
                    marginBottom: '15px',
                    textAlign: 'center',
                }}
            >
                <CustomText isBreefSerif isTitle color={baseColors.green.primary} style={{ marginLeft: 20 }}>
                    Historiques des utilisateurs
                </CustomText>
            </Box>
            {loading ? (
                <div className={classes.loading}>
                    <CustomCircleLoader />
                </div>
            ) : dataRows?.length > 0 ? (
                <UserHistoryTable tableHeaders={tableHeaders} dataRows={dataRows} />
            ) : (
                <CustomEmptyData />
            )}
        </Box>
    );
};

export default UserHistoryPanel;
