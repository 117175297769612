import { FC, useContext, useState, useEffect } from 'react';
import CustomModal from '../../../../components/CustomModal';
import { Grid } from '@mui/material';
import { CustomText } from '../../../../components/Text/CustomText';
import { InputField } from '../../../../components/InputField/InputField';
import { isNotBlank, isValidMail } from '../../../../utils/helpers/InputValidator';
import { SnackBarContext } from '../../../../providers/SnackBarProvider';
import { Contact } from '../../../../interfaces/interfaces';
import { defaultContactValue } from '../UserLogic';
import { ContactFormBtn } from './ContactFormBtn';

interface ContactFormProps {
    open: boolean;
    modalToggle: Function;
    setUpdateList?: any;
    prevSelectedUserId: number[];
    setPrevSelectedUserId: any;
    setOpen: Function;
}

export const ContactForm: FC<ContactFormProps> = (props) => {
    const { open, prevSelectedUserId, setPrevSelectedUserId, setOpen } = props;
    const [lockUserInput, setLockUserInput] = useState(false);
    const [mailContact, setMailContact] = useState<Contact>(defaultContactValue);
    const [saving, setSaving] = useState<boolean>(false);

    useEffect(() => {
        setMailContact((old) => ({ ...old, recipients: prevSelectedUserId }));
    }, [prevSelectedUserId]);

    return (
        <CustomModal width={700} height={350} title={'Envoyer un email'} open={open} toggleModal={setOpen}>
            <Grid lg={12} container direction="row" alignItems={'center'} style={{ marginTop: 30, marginBottom: -15 }}>
                <Grid item>
                    <CustomText style={{ minWidth: '300px' }}>Sujet :</CustomText>
                </Grid>
                <InputField
                    label={''}
                    disabled={lockUserInput}
                    onChange={(value: any) => {
                        setMailContact((old) => ({ ...old, subject: value }));
                    }}
                    validator={isNotBlank}
                    errorMessage="Sujet obligatoire"
                    placeholder={'Entrer le sujet'}
                    width={360}
                    //idSuffix="nom-utilisateur"
                />
            </Grid>

            <Grid lg={12} container direction="row" alignItems={'center'}>
                <Grid item>
                    <CustomText style={{ minWidth: '300px' }}>Message :</CustomText>
                </Grid>
                <InputField
                    label={''}
                    disabled={lockUserInput}
                    onChange={(value: any) => {
                        setMailContact((old) => ({ ...old, content: value }));
                    }}
                    validator={isValidMail}
                    errorMessage="Message obligatoire"
                    placeholder={'Entrer le message'}
                    width={360}
                    height={150}
                    //idSuffix="email-utilisateur"
                />
            </Grid>

            <Grid container justifyContent={'center'} style={{ marginTop: '50px' }}>
                <Grid>
                    <ContactFormBtn
                        contactData={mailContact}
                        contact={mailContact}
                        setOpen={setOpen}
                        setContact={setMailContact}
                        saving={saving}
                        setSaving={setSaving}
                        defaultContact={defaultContactValue}
                        setPrevSelectedUserId={setPrevSelectedUserId}
                    ></ContactFormBtn>
                </Grid>
            </Grid>
        </CustomModal>
    );
};
