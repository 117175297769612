import { CheckBoxItem } from 'src/interfaces/interfaces';
import { fullName } from 'src/utils/utils';

interface CheckBoxLabel {
    isPatients: boolean | undefined;
    isIntervenants?: boolean | undefined;
    item: any;
    labelKey: string | undefined;
    secondLabelKey: string | undefined;
    subMenuKey: string | undefined;
}

interface SetSelectedItems {
    selected: Set<number>;
    item: { id: number; name: string };
    setSelectedIntervenants: any;
    setSelected: Function | undefined;
    handleChange: Function | undefined;
    setForceRerender: Function | undefined;
    forceRerender: boolean | undefined;
    items?: any;
}

function handleChangeOption({ setSelected, handleChange, newValue }: any) {
    setSelected?.(newValue);
    handleChange?.(newValue);
}

function AddNewItem({ setSelectedIntervenants, item, allIds, setSelected, newValue, handleChange }: any) {
    setSelectedIntervenants?.((prev: any) => {
        if (item.id === -1) {
            return [...allIds];
        }
        return [...prev, item];
    });
    handleChangeOption({ setSelected, handleChange, newValue });
}

function RemoveExistedItem({ isAllChecked, setSelectedIntervenants, item, setSelected, newValue, handleChange }: any) {
    setSelectedIntervenants?.((prev: any) => {
        if (isAllChecked) {
            return [];
        }
        return prev?.filter((intervenant: any) => intervenant.id !== item?.id && intervenant.id !== -1);
    });
    handleChangeOption({ setSelected, handleChange, newValue });
}

const handleSetSelectedItems =
    ({
        selected,
        item,
        setSelectedIntervenants,
        setSelected,
        handleChange,
        setForceRerender,
        forceRerender,
        items,
    }: SetSelectedItems) =>
    () => {
        let set = selected;
        const allIds = Array.from(items, (item: any) => item.id);
        const isAllChecked = item.id === -1;

        if (!selected?.has(item.id)) {
            set.add(item.id);
            const selectedLength = Array.from(set)?.length;
            if (!isAllChecked && selectedLength === allIds?.length - 1 && allIds?.includes(-1)) set.add(-1);
            const newValue = isAllChecked ? new Set(allIds) : set;
            AddNewItem({ setSelectedIntervenants, item, allIds, setSelected, newValue, handleChange });
        } else {
            set.delete(item.id);
            const newValue = isAllChecked ? new Set([]) : set;
            if (!isAllChecked) set.delete(-1);
            RemoveExistedItem({
                isAllChecked,
                setSelectedIntervenants,
                item,
                allIds,
                setSelected,
                newValue,
                handleChange,
            });
        }

        setForceRerender?.(!forceRerender);
    };

const checkboxLabel = ({ isPatients, item, isIntervenants, labelKey, secondLabelKey, subMenuKey }: CheckBoxLabel) =>
    isPatients || isIntervenants
        ? fullName(item.firstName, item.lastName)
        : labelKey && !secondLabelKey && !subMenuKey
        ? `${item[labelKey]}`
        : labelKey && secondLabelKey && !subMenuKey
        ? `${item[labelKey]} ${item[secondLabelKey]}`
        : labelKey && secondLabelKey && subMenuKey
        ? `${item[labelKey]} ${item[secondLabelKey]} (${item[subMenuKey]})`
        : '';

const CheckedItemsLabel = ({ data, selectedIds }: { data: CheckBoxItem[]; selectedIds: number[] }) => {
    const uniqIds = Array.from(new Set(selectedIds));
    const results = data?.filter((item) => uniqIds?.includes(item.id));
    return results ?? ([] as CheckBoxItem[]);
};

export { CheckedItemsLabel, checkboxLabel, handleSetSelectedItems };
