import * as React from 'react';
import { baseColors } from 'src/utils/constants/baseStyles';

function CheckIcon(props: any) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" viewBox="0 0 20 20" {...props}>
            <path
                fill={baseColors.green.primary}
                d="M13.592 2.046c.29-.64.006-1.403-.666-1.608a10 10 0 106.914 11.346c.125-.692-.423-1.293-1.125-1.33-.702-.036-1.29.508-1.445 1.193a7.454 7.454 0 11-5.4-8.863c.68.176 1.433-.097 1.722-.738z"
            />
            <path
                fill={baseColors.green.primary}
                d="M9.77 13.674a.514.514 0 01-.73 0L6.127 10.76a.773.773 0 010-1.094l.365-.365a.773.773 0 011.094 0l1.818 1.819 4.914-4.914a.773.773 0 011.094 0l.365.365a.773.773 0 010 1.094l-6.008 6.009z"
            />
        </svg>
    );
}

export default CheckIcon;
