import React, { FC, useContext, useEffect, useState } from 'react';
import CustomCircleLoader from 'src/components/CustomLoader/CustomCircleLoader';
import moment from 'moment';
import useStyles from '../styles';
import { baseColors } from 'src/utils/constants/baseStyles';
import CustomEmptyData from 'src/components/CustomEmptyData/CustomEmptyData';
import { LineChart } from 'src/components/Chart/Line/LineChart';
import EvaluationObservationItem from './EvaluationObservationItem';
import { BreeSerifText } from 'src/components/Text/BreeSerifText';
import { InputField } from 'src/components/InputField/InputField';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import { GetObservationRemark, postObservationStartPrograms } from 'src/services/Observations';
import { ChartData, CreateObservationPayload } from 'src/interfaces/interfaces';
import { toast } from 'react-toastify';
import { ServerError } from 'src/utils/constants/constants';
import { PatientContext } from 'src/providers/PatientProvider';
import { Spacer } from 'src/components/Spacer/Spacer';
import { useHistory } from 'react-router-dom';
import { EvaluationsTabMenus } from 'src/utils/constants/constants';
import useConstantHooks from 'src/hooks/useConstantHooks';
import { BarChart } from 'src/components/Chart/Bar/BarChart';
import ProgramService from 'src/services/Program/program.service';
import { AXIS_IDENTIFICATION } from 'src/utils/constants/enums';
import { addPatientsObservation } from 'src/services/Patients';
import { parseObjectToString } from 'src/utils/helpers/helpers';
import { AxisLabel } from 'src/utils/constants/constants';
import { LocalStorageService } from 'src/utils/storage';

interface EvaluationAxisContentProps {
    axisId: number;
    loading: boolean;
    observations: any[];
    setUpdating: any;
    patientId: number;
    isInterview?:boolean;
}

export interface ChartItem {
    id: number;
    name: string;
    series: ChartData;
    useBarChart?: boolean;
}

const EvaluationAxisContent: FC<EvaluationAxisContentProps> = (props) => {
    const { axisId, loading, observations, setUpdating, isInterview } = props;
    const classes = useStyles();
    const history = useHistory();
    const [observation, setObservation] = useState<string>('');
    const [saving, setSaving] = useState<boolean>(false);
    const [loadingProgram, setLoadingProgram] = useState<boolean>(false);
    const [updateRemark, setUpdateRemark] = useState<boolean>(false);
    const [fetchingRemark, setFetchingRemark] = useState<boolean>(false);
    const { selectedPatient } = useContext(PatientContext);
    const nextAxis = EvaluationsTabMenus.find((axis) => axis?.id === axisId + 1);
    const isMedicalInterview = isInterview && axisId === AXIS_IDENTIFICATION.MEDICAL;

    const [followedProgram, setFollowedProgram] = useState<any>();
    //const [interviewRemarks,setInterviewRemarks] = useState<any>({})
    const patientId = selectedPatient?.id ?? 0;

    const defaultInterviewObservation = {
        date: moment(new Date()).format('YYYY/MM/DD'),
        title: 'Entretien de suivi',
        typeId: axisId,
        attendeeIds: [],
        patientId: selectedPatient?.id,
        programId: followedProgram?.id,
        recipients: [],
        externalUsersMail: [],
    };

    const [interviewObservationPayload,setInterviewObservationPayload] = useState<any>(defaultInterviewObservation)

    const { fetchConstants, setSelectedConstant, fetchingChartData, chartData, selectedConstant, constantNames } =
        useConstantHooks();

    const handleCreateObservation = async () => {
        await LocalStorageService.SetIntervierwByAxis({axisId, patientId, value: observation})
        const afterValidateUrl = isInterview ? "interview" : "evaluation";
        history.push(`/patients/list/${selectedPatient?.id}/${afterValidateUrl}/${nextAxis?.path}`)
    };

    const handleGetRemark = async () => {
        const data =  await LocalStorageService.GetIntervierwByAxis({axisId, patientId});
        setObservation(data);
    };

    const handleCreateInterviewObservation = async () => {
        await LocalStorageService.SetIntervierwByAxis({ axisId, patientId, value: observation });
        let interviewDetails: any = {};
        await Promise.all(
            Object.keys(AxisLabel).map(async (axisKey) => {
                const key = axisKey as keyof typeof AXIS_IDENTIFICATION;
                const axisId = AXIS_IDENTIFICATION[key];
                const axisRemark = await LocalStorageService.GetIntervierwByAxis({ axisId, patientId });
        
                if (!isNaN(axisId)) {
                    interviewDetails[AxisLabel[key]] = axisRemark;
                }
            })
        );

        let data: any = defaultInterviewObservation;
        data["details"] = parseObjectToString(interviewDetails);

        setSaving(true);
        if(data?.details?.length > 1){
            addPatientsObservation(data)
            .then(() =>{
                toast.success('Observation enregistrée avec succès .');
                clearIntervierwByAxis();
                setSaving(false);
                history.push(`/patients/list/${selectedPatient?.id}/observation`);
            }).catch(() => {
                toast.error(ServerError.unknown);
                setSaving(false);
            });
        }
    };

    const getInterviewFollowedProgram = async () => {
        setLoadingProgram(true);
        try {
            const programeDataByPatient = await ProgramService.getProgramsByPatientId(selectedPatient?.id);
            const programeData = Array.from(programeDataByPatient?.data?.data, (data: any) => data?.program);
            if (Array.isArray(programeData) && programeData.length > 0){
                const lastProgram = programeData[programeData.length-1];  
                setFollowedProgram(lastProgram);
            }
            setLoadingProgram(false);
        } catch (e) {
            toast.error(ServerError.unknown);
            setLoadingProgram(false);
        }
    };

    const canValideInterview = () => {
        return interviewObservationPayload.patientId > 0 &&
        interviewObservationPayload.programId > 0 &&
        interviewObservationPayload.details.trim() !==""
    }

    const clearIntervierwByAxis = () => {
        Object.keys(AxisLabel).map(async (axisKey) => {
            const key = axisKey as keyof typeof AXIS_IDENTIFICATION;
            const axisId = AXIS_IDENTIFICATION[key];    
            if (!isNaN(axisId)) {
                sessionStorage.removeItem(`interview_${axisId.toString()}_${patientId}`);
            }
        })
    };

    useEffect(() => {
        if (selectedPatient?.id! > 0 && axisId > 0) {
            handleGetRemark();
        }
    }, [selectedPatient?.id, axisId, updateRemark]);

    useEffect(() => {
        fetchConstants();
    }, [selectedPatient?.id, axisId]);

    useEffect(()=> {
        if(isMedicalInterview){
            getInterviewFollowedProgram();
        }
    },[selectedPatient?.id, axisId])

    useEffect(()=> {
        setInterviewObservationPayload((prev:any) => ({
            ...prev,
            programId: followedProgram?.id,
        }));
    },[followedProgram])


    const axisObservations = observations?.filter((observation) => observation?.type?.id === axisId);
    const isEmpty = axisObservations?.length === 0;
    const series = chartData?.find((constant) => constant?.id === selectedConstant.id);
    const useBarChart = series?.useBarChart;
    
    const disableValideateInterview = saving || loadingProgram || fetchingRemark || observation.trim().length === 0 || !canValideInterview;

    return (
        <div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    overflowY: 'scroll',
                    height: 'calc(100vh - 520px)',
                    minHeight: '250px',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1 / 2,
                        marginRight: 40,
                    }}
                >
                    {fetchingChartData ? (
                        <div style={{ paddingTop: 55 }} className={classes.loading}>
                            <CustomCircleLoader height={50} width={50} />
                        </div>
                    ) : useBarChart ? (
                        <BarChart
                            isEvaluation
                            selectedConstant={selectedConstant}
                            setSelectedConstant={setSelectedConstant}
                            constantNames={constantNames}
                            data={series?.series ?? []}
                        />
                    ) : (
                        <LineChart
                            withoutLabel
                            isEvaluation
                            selectedConstant={selectedConstant}
                            setSelectedConstant={setSelectedConstant}
                            constantNames={constantNames}
                            data={series?.series ?? []}
                        />
                    )}
                </div>

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: 10,
                        flex: 1 / 2,
                        backgroundColor: baseColors.green.light,
                        borderRadius: 10,
                    }}
                >
                    <BreeSerifText style={{ fontSize: 18, textAlign: 'center' }}>Observations</BreeSerifText>

                    <Spacer />

                    {loading ? (
                        <div className={classes.loading}>
                            <CustomCircleLoader height={50} width={50} />
                        </div>
                    ) : isEmpty ? (
                        <CustomEmptyData height={50} width={50} />
                    ) : (
                        axisObservations?.map((observation) => (
                            <EvaluationObservationItem setUpdating={setUpdating} observation={observation} />
                        ))
                    )}
                </div>
            </div>

            <Spacer height={10} />

            {
                isInterview && 
                <div style={{}}>
                    {fetchingRemark ? (
                        <CustomCircleLoader height={25} width={25} />
                    ) : (
                        <>
                            <InputField
                                value={observation}
                                height={100}
                                label=""
                                placeholder="Taper votre texte..."
                                onChange={(value: string) => setObservation(value)}
                                width="100%"
                            />
                            <CustomLoadingButton
                                disabled={observation.trim().length === 0}
                                loading={saving}
                                width="20%"
                                handleClick={handleCreateObservation}
                                label="Valider"
                            />
                            {isMedicalInterview && 
                                <CustomLoadingButton
                                    marginLeft={10}
                                    disabled={disableValideateInterview}
                                    loading={saving || loadingProgram || fetchingRemark}
                                    width="40%"
                                    handleClick={handleCreateInterviewObservation}
                                    label="Terminer l'entretien"
                                />
                            }
                        </>
                    )}
                </div>
            }                
        </div>
    );
};

export default EvaluationAxisContent;
