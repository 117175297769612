import { FC } from 'react';
import { CustomRadio } from 'src/components/InputField/CustomRadio';
import { Spacer } from 'src/components/Spacer/Spacer';
import { CustomText } from 'src/components/Text/CustomText';
import { baseFontFamily, baseFontSize } from 'src/utils/constants/baseStyles';
import { makeStyles } from '@mui/styles';
import { baseBorderRadius, baseColors, baseScreenHeight } from 'src/utils/constants/baseStyles';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        minWidth: 660,
        backgroundColor: baseColors.white,
        ...baseScreenHeight.defaultHeight,
        ...baseBorderRadius.leftOnly,
    },
    rowSimple: {
        flexDirection: 'row',
        marginTop: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    row: {
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
    },
    wrapper: {
        padding: 20,
        justifyContent: 'center',
        overflowY: 'auto',
    },
    processLine: {
        marginTop: 20,
        flex: 12,
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'row',
    },
});

export const renderTitle = (value: string) => (
    <CustomText fontSize={baseFontSize.md} fontFamily={baseFontFamily.BreeSerif}>
        {value}
    </CustomText>
);

export const LabelWithValue: FC<{ label: string; value: string }> = (props) => {
    const { label, value } = props;
    const classes = useStyles();

    return (
        <div className={classes.row}>
            <CustomText>
                <>
                    {`${label} :  `} <span style={{ fontFamily: baseFontFamily.OpenSansSemiBold }}>{`${value}`}</span>
                </>
            </CustomText>
        </div>
    );
};

export const renderContent = (value: string) => <CustomText fontSize={14}>{value}</CustomText>;

interface QuestionWithRadioProps {
    title: string;
    content: string;
    radioValue: boolean;
    setRadioValue: Function;
    yesLabel?: string;
    noLabel?: string;
    inline?: boolean;
}
export const QuestionWithRadio: FC<QuestionWithRadioProps> = (props) => {
    const { title, content, radioValue, setRadioValue, yesLabel, noLabel, inline } = props;
    const classes = useStyles();

    return (
        <div>
            {renderTitle(title)}
            <Spacer height={20} />
            <div className={classes.rowSimple}>
                <div
                    style={
                        inline
                            ? {
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                  width: '85%',
                              }
                            : {}
                    }
                >
                    {renderContent(content)}
                    {!inline && <Spacer height={20} />}
                    <div style={inline ? { marginTop: -15 } : {}}>
                        <div className={classes.row}>
                            <div>
                                <CustomRadio
                                    fontFamily={baseFontFamily.OpenSansSemiBold}
                                    paddingTop={0}
                                    checked={radioValue}
                                    onClick={() => setRadioValue(true)}
                                    label={yesLabel ? yesLabel : 'Oui'}
                                />
                            </div>
                            <div>
                                <CustomRadio
                                    fontFamily={baseFontFamily.OpenSansSemiBold}
                                    paddingTop={0}
                                    checked={!radioValue}
                                    onClick={() => setRadioValue(false)}
                                    label={noLabel ? noLabel : 'Non'}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
