import { RootState } from 'src/stores/store';
import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import AuthorizationService from 'src/services/Authorizations';
import { AuthorizationActionType } from './types';
import { AUTHORIZATION_CODES } from 'src/utils/constants/constants';

export const retrieveAuthorizations =
    (filters?: any, setLoading?: Function): ThunkAction<void, RootState, unknown, AnyAction> =>
    async (dispatch) => {
        setLoading?.(true);
        try {
            const data = await AuthorizationService.getAuthorizations(filters);
            dispatch({
                type: AuthorizationActionType.LIST_AUTHORIZATIONS,
                payload: data,
            });
            setLoading?.(false);
        } catch (error) {
            console.log('Retrieve Authorizations Error', error);
            setLoading?.(false);
        }
    };

export const retrieveAuthorizedSidebarMenus =
    ({
        filters,
        setFetchingAuthorizedMenus,
    }: {
        filters?: any;
        setFetchingAuthorizedMenus?: Function;
    }): ThunkAction<void, RootState, unknown, AnyAction> =>
    async (dispatch) => {
        // setFetchingAuthorizedMenus?.(true);
        try {
            const data = await AuthorizationService.getAuthorizedSidebarMenus(filters);
            dispatch({
                type: AuthorizationActionType.LIST_AUTHORIZED_SIDEBAR_MENUS,
                payload: data,
            });
            setFetchingAuthorizedMenus?.(false);
        } catch (error) {
            console.log('Retrieve Authorized Sidebar Menu Error', error);
            setFetchingAuthorizedMenus?.(false);
        }
    };

export const retrieveAuthorizedContents =
    (filters?: any, setLoading?: Function, callBack?: Function): ThunkAction<void, RootState, unknown, AnyAction> =>
    async (dispatch) => {
        // console.log('====================================');
        // console.log('retrieving authorization contents');
        // console.log('====================================');
        setLoading?.(true);
        try {
            const data = await AuthorizationService.getAuthorizedContents(filters);
            callBack?.([...data, AUTHORIZATION_CODES.listUserHistory]); //hotfix (need to add AUTHORIZATION_CODES.listUserHistory on fixture)
            dispatch({
                type: AuthorizationActionType.LIST_AUTHORIZED_CONTENTS,
                payload: data,
            });
            setLoading?.(false);
        } catch (error) {
            console.log('Retrieve Authorized Contents Error', error);
            setLoading?.(false);
        }
    };
