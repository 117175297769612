import { FC } from 'react';
import { CustomText } from 'src/components/Text/CustomText';
import ReactMarkdown from 'react-markdown';
import { IconButton } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { transformDbDateTimeLocal } from 'src/utils/utils';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';

interface DiscussionMessageItemProps {
    item: any;
    discussionId: number;
    editDiscussion: any;
    beforeConfirmDelete: any;
    isCoordinator?: boolean;
    isCpts?: boolean;
}

export const DiscussionMessageItem: FC<DiscussionMessageItemProps> = (props) => {
    const { item, discussionId, editDiscussion, isCoordinator, beforeConfirmDelete } = props;

    return (
        <div
            style={{
                background: baseColors.white,
                padding: 5,
                paddingLeft: 10,
                paddingRight: 10,
                marginTop: 20,
                borderRadius: 8,
                flexDirection: 'column',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    backgroundColor: baseColors.green.primary,
                    paddingBottom: 5,
                    borderRadius: 5,
                }}
            >
                <div style={{ color: baseColors.white, fontSize: 13, marginLeft: 20, marginTop: 5 }}>
                    {transformDbDateTimeLocal(item?.dateDiscussions?.date)}
                </div>
                <div
                    style={{
                        marginRight: 20,
                        fontSize: 14,
                        fontFamily: baseFontFamily.OpenSansSemiBold,
                        color: baseColors.white,
                        marginTop: 3,
                    }}
                >{`${item?.senderFirstname} ${item?.senderLastname}`}</div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div>
                    <CustomText color={baseColors.green.primary}>
                        <>
                            <span>
                                <ReactMarkdown>{item?.discussionText}</ReactMarkdown>
                            </span>
                        </>
                    </CustomText>
                </div>
                {isCoordinator ? (
                    <></>
                ) : (
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <IconButton
                            style={{ height: 50, width: 50 }}
                            disabled={discussionId !== 0}
                            onClick={() => editDiscussion(item)}
                        >
                            <Edit htmlColor={baseColors.green.primary} fontSize={'small'} />
                        </IconButton>

                        {discussionId !== item?.id && (
                            <IconButton
                                style={{ height: 50, width: 50 }}
                                disabled={discussionId !== 0}
                                onClick={() => beforeConfirmDelete(item)}
                            >
                                <Delete htmlColor={baseColors.orange.primary} fontSize={'small'} />
                            </IconButton>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};
