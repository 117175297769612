import { FC, useContext } from 'react';
import { CustomText } from 'src/components/Text/CustomText';
import { baseColors, baseFontFamily, baseFontSize } from 'src/utils/constants/baseStyles';

import useStyles from './styles';
import { PatientContext } from 'src/providers/PatientProvider';
import { useFetchUserPrograms } from 'src/hooks/useFetchUserPrograms';
import CustomCircleLoader from 'src/components/CustomLoader/CustomCircleLoader';

interface AddedToNewProgramProps {}

const AddedToNewProgram: FC<AddedToNewProgramProps> = (props) => {
    const classes = useStyles();
    const { selectedPatient } = useContext(PatientContext);
    const patientId = selectedPatient?.id ?? 0;

    const { preSelectedPatientProgram, fetchingPreselectedProgram } = useFetchUserPrograms({
        userId: patientId,
    });

    return (
        <div className={classes.container}>
            {fetchingPreselectedProgram ? (
                <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', padding: 10 }}>
                    <CustomCircleLoader height={10} width={25} />
                </div>
            ) : (
                <CustomText
                    style={{ textAlign: 'center', marginBottom: 25, marginTop: '25%' }}
                    fontFamily={baseFontFamily.BreeSerif}
                    fontSize={baseFontSize.md}
                    color={baseColors.green.primary}
                >
                    Patient a été ajouté dans le Programme {preSelectedPatientProgram?.programTitle ?? ''} avec succès !
                </CustomText>
            )}
        </div>
    );
};

export default AddedToNewProgram;
