import { Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { baseColors } from 'src/utils/constants/baseStyles';
import CustomModal from '../CustomModal';
import BlueCard from './BlueCard';
import useStyles, { sxStyles } from './styles';
import PatientParticipantItem from './PatientParticipantItem';
import { Box } from '@mui/system';
import IntervenantItem from './IntervenantItem';
import { useDispatch, useSelector } from 'react-redux';
import { retrieveCalendarEvent } from 'src/actions/calendar/events';
import CustomCircleLoader from 'src/components/CustomLoader/CustomCircleLoader';
import ModalFooter from './ModalFooter/ModalFooter';
import { EventPlanningViewType } from 'src/interfaces/interfaces';
import { checkIntervenants, checkParticipantsForAgendaWorkshop, checkPresentPatients } from './WorkshopModalLogic';
import CustomVisioFramModal from '../CustomVisioFramModal';
import CustomVisioIframe from 'src/components/CustomVisioIframe/CustomVisioIframe';
import { defaultVisioValue } from 'src/hooks/useEducationalDiagnosis';
import { EducationalDiagnosisVisio } from 'src/interfaces/types';
import WorkshopModalHeader from './WorkshopModalHeader';
import { isConnectedUserIntervenant } from 'src/views/MyAgenda/Planning/EventUtils';

type IProps = {
    toggleModal: any;
    open: boolean;
    workShopData: any;
    isInformationDetails?: boolean;
    setUpdateList?: any;
    dataForWeeklyEvent?: any;
    viewType?: EventPlanningViewType['viewType'];
    patientForAgendaWorkShop?: boolean;
    readOnly?: boolean;
    cpts?: boolean;
    eventId?: number;
    workShopevent?: any;
    isExternalDoctor?: boolean;
    userInfos?: any;
    isCoordinator?: boolean;
};

const WorkshopModal: FC<IProps> = (props) => {
    const {
        open,
        toggleModal,
        workShopData,
        setUpdateList: updateMainEventList,
        viewType,
        dataForWeeklyEvent,
        readOnly,
        eventId: calenderEventId,
        isExternalDoctor,
        userInfos,
        isCoordinator,
    } = props;
    const classes = useStyles();
    const [loadingEvent, setLoadingEvent] = useState<boolean>(false);
    const [updateList, setUpdateList] = useState<boolean>(false);
    const selectedEventId: number = workShopData?.eventId ?? workShopData?.id ?? calenderEventId;
    const dispatch = useDispatch();
    const handleFetchData = () => dispatch(retrieveCalendarEvent(setLoadingEvent, selectedEventId));

    const calendarEvent = useSelector((state: any) => state?.calendarEvent?.data);
    const intervenants = checkIntervenants(calendarEvent);
    const presentPatients: any = checkPresentPatients(calendarEvent);
    const presences = presentPatients?.map((presence: any) => presence?.user?.id) ?? [0];
    const participantsForAgendaWorkshop: any = checkParticipantsForAgendaWorkshop(calendarEvent, presences);
    const eventId = calendarEvent?.id ?? 0;
    const modalTitle =
        workShopData?.eventType?.name ??
        workShopData?.eventType ??
        workShopData?.informations?.eventType ??
        "Details sur l'événement";
    const participantData = participantsForAgendaWorkshop;

    const [iframeContent, setIframeContent] = useState<EducationalDiagnosisVisio>(defaultVisioValue);
    const [openIframModal, setOpenIframModal] = useState<boolean>(false);
    const [creatingLink, setCreatingLink] = useState<boolean>(false);

    const toggleIframModal = () => {
        setCreatingLink(true);
        setOpenIframModal(!openIframModal);
        setTimeout(() => {
            setCreatingLink(false);
        }, 5000);
    };

    useEffect(() => {
        handleFetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, updateList]);

    useEffect(() => {
        if (calendarEvent?.visioconference?.id > 0) {
            setIframeContent(calendarEvent?.visioconference);
        }
    }, [calendarEvent]);

    const hasNoParticipant = (participantData && participantData?.length === 0) || !participantData;
    const isVisio = calendarEvent?.consultationType?.id > 1;
    const isMyEvent =
        calendarEvent?.ownerId === userInfos?.id ||
        calendarEvent?.organizerId === userInfos?.id ||
        isCoordinator ||
        isConnectedUserIntervenant({ event: calendarEvent, myId: userInfos?.id ?? 0 });

    const visioModal = (
        <CustomVisioFramModal toggleModal={toggleIframModal} open={openIframModal}>
            <CustomVisioIframe
                title="Démarrage de l'atelier en direct en cours..."
                loading={creatingLink}
                url={iframeContent?.url}
            />
        </CustomVisioFramModal>
    );

    return (
        <CustomModal
            width={800}
            open={open}
            toggleModal={toggleModal}
            title={modalTitle}
            footer={
                <ModalFooter
                    isExternalDoctor={isExternalDoctor}
                    readOnly={readOnly}
                    isMyEvent={isMyEvent}
                    updateMainEventList={updateMainEventList}
                    toggleModal={toggleModal}
                    eventData={calendarEvent}
                    viewType={viewType}
                    dataForWeeklyEvent={dataForWeeklyEvent}
                    setUpdateList={setUpdateList}
                    loadingEvent={loadingEvent}
                />
            }
        >
            {visioModal}

            {loadingEvent ? (
                <Box sx={sxStyles.loading}>
                    <CustomCircleLoader />
                </Box>
            ) : (
                <>
                    <WorkshopModalHeader
                        readOnly={readOnly || !isMyEvent}
                        isExternalDoctor={isExternalDoctor}
                        calendarEvent={calendarEvent}
                        isVisio={isVisio}
                        hasNoParticipant={hasNoParticipant}
                        toggleModal={toggleIframModal}
                    />

                    <div className={classes.content}>
                        <BlueCard title="Liste des patients participants">
                            <Box marginTop={3}>
                                {!hasNoParticipant && <PatientParticipantItem data={null} header />}
                                {hasNoParticipant ? (
                                    <Typography
                                        marginTop={10}
                                        color={baseColors.green.primary}
                                        fontSize={14}
                                        textAlign="center"
                                    >
                                        Pas encore de participants à cet atelier
                                    </Typography>
                                ) : (
                                    participantData?.length > 0 &&
                                    participantData?.map((participant: any, i: number) => (
                                        <PatientParticipantItem
                                            key={i}
                                            data={participant}
                                            eventId={eventId}
                                            setUpdateList={setUpdateList}
                                            participants={participantData}
                                            readOnly={readOnly || !isMyEvent}
                                            isExternalDoctor={isExternalDoctor}
                                        />
                                    ))
                                )}
                            </Box>
                        </BlueCard>

                        <BlueCard title="Liste des intervenants">
                            <Box marginTop={3}>
                                {intervenants &&
                                    intervenants.length > 0 &&
                                    intervenants.map((intervenant, i) => (
                                        <IntervenantItem key={i} data={intervenant} />
                                    ))}
                            </Box>
                        </BlueCard>
                    </div>
                </>
            )}
        </CustomModal>
    );
};

export default WorkshopModal;
