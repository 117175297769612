import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { baseColors } from 'src/utils/constants/baseStyles';
import ConnexionTable from './ConnexionTable';
import ConnexionService from 'src/services/Connexion';
import useStyles from './styles';
import CustomCircleLoader from 'src/components/CustomLoader/CustomCircleLoader';
import CustomEmptyData from 'src/components/CustomEmptyData';
import { CustomText } from 'src/components/Text/CustomText';

const ConnexionPanel = () => {
    const classes = useStyles();
    const [connections, setConnections] = useState<any[]>([]);
    const [dataRows, setDataRows] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    const handleFetchData = () => {
        setLoading(true);
        ConnexionService.getConnexions()
            .then((res) => {
                const data = res?.userSession;
                setConnections(data);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        handleFetchData();
    }, []);

    useEffect(() => {
        parseConnexionData(connections);
    }, [connections]);

    const tableHeaders = [
        {
            name: 'Utilisateur',
        },
        {
            name: 'Date de connexion',
        },
        {
            name: 'Date de déconnexion',
        },
    ];

    const parseConnexionData = (connections: any[]) => {
        let rows: any[] = [];

        connections.forEach((con) => {
            rows.push({
                id: con.id,
                username: con.username,
                connexion: con.lastLogin?.date,
                deconnexion: con.lastLogout?.date,
            });
            return rows;
        });
        setDataRows(rows);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                paddingX: '25px',
            }}
        >
            <Box
                sx={{
                    height: '50px',
                    marginTop: '25px',
                    marginBottom: '15px',
                    textAlign: 'center',
                }}
            >
                <CustomText isBreefSerif isTitle color={baseColors.green.primary} style={{ marginLeft: 20 }}>
                    Liste des connexions
                </CustomText>
            </Box>
            {loading ? (
                <div className={classes.loading}>
                    <CustomCircleLoader />
                </div>
            ) : dataRows?.length > 0 ? (
                <ConnexionTable tableHeaders={tableHeaders} dataRows={dataRows} />
            ) : (
                <CustomEmptyData />
            )}
        </Box>
    );
};

export default ConnexionPanel;
