import { Checkbox } from '@mui/material';
import { Dispatch, FC, SetStateAction, useContext, useEffect } from 'react';
import { CustomText } from 'src/components/Text/CustomText';
import { ConclusionPayloadType } from 'src/interfaces/types';
import { baseColors, baseFontSize } from 'src/utils/constants/baseStyles';
import useStyles from './styles';
import {
    SetCheckedGoalInPayloadWhileUpdatingArticleWorkshops,
    handleClickArticleSkill,
    handleClickGoalSkill,
    handleClickSkill,
    handleClickWorkshopSkill,
} from '../../ConclusionCheckBoxForm/ConclusionCheckBoxForm.utils';
import { ProgramSkill } from 'src/interfaces/interfaces';
import CustomTagItem from 'src/components/CustomTagItem/CustomTagItem';
import { WorkshopContext } from 'src/providers/WorkshopProvider';
import { ArticleContext } from 'src/providers/ArticleProvider';

export interface CheckBoxItemData {
    id: number;
    name: string;
    title?: string;
    goal?: any;
    programs?: { id: number; title?: string }[];
    articles?: { id: number; title?: string }[];
    workshops?: { id: number; title?: string }[];
    goals?: { id: number; name?: string }[];
}

interface CheckBoxSkillItemProps {
    keyValue: string;
    payload: ConclusionPayloadType;
    setPayload: Dispatch<SetStateAction<ConclusionPayloadType>>;
    setDisabledDefaultPrechecked?: Dispatch<SetStateAction<boolean>>;
    selected: Array<number>;
    data: CheckBoxItemData;
    skills: ProgramSkill[];
    isGoals?: boolean;
    isArticles?: boolean;
    isWorkshops?: boolean;
    defaultSkills: ProgramSkill[];
    isEditMode?: boolean;
    updateGoalIdsInSkills?: boolean;
    setUpdateGoalIdsInSkills?: Dispatch<SetStateAction<boolean>>;
}

export type ConclusionRowItem = {
    id: number;
    value: string;
};

const CheckBoxSkillItem: FC<CheckBoxSkillItemProps> = ({
    isArticles,
    isWorkshops,
    isGoals,
    skills,
    selected,
    data,
    payload,
    defaultSkills,
    setPayload,
    updateGoalIdsInSkills,
    setUpdateGoalIdsInSkills,
    setDisabledDefaultPrechecked,
}) => {
    const classes = useStyles();
    const checked = selected?.includes(data?.id);
    const { workshops } = useContext(WorkshopContext);
    const { articles } = useContext(ArticleContext);

    useEffect(() => {
        if (isGoals) {
            SetCheckedGoalInPayloadWhileUpdatingArticleWorkshops({
                goalData: data,
                payload,
                setPayload,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isGoals, payload.conclusionVideos, payload.conclusionWorkshops, data]);

    const handleClick = () => {
        if (isGoals) {
            setUpdateGoalIdsInSkills?.(true);
            handleClickGoalSkill({ value: data?.id, setPayload, skills: defaultSkills, goalData: data as any });
        } else if (isArticles) {
            setUpdateGoalIdsInSkills?.(true);
            handleClickArticleSkill({ value: data?.id, setPayload, skills: defaultSkills, articles });
        } else if (isWorkshops) {
            setUpdateGoalIdsInSkills?.(true);
            handleClickWorkshopSkill({ value: data?.id, setPayload, skills: defaultSkills, workshops });
        } else {
            setUpdateGoalIdsInSkills?.(false);
            handleClickSkill({ value: data?.id, setPayload, skills, skillData: data });
        }
    };

    return (
        <div
            style={{
                justifyContent: 'space-between',
                alignItems: 'center',
                backgroundColor: baseColors.green.light,
                padding: 5,
                borderRadius: 7,
                marginBottom: 10,
            }}
            className={classes.row}
        >
            <div
                onClick={handleClick}
                style={{
                    flex: 1,
                    cursor: 'pointer',
                }}
                className={classes.row}
            >
                <Checkbox
                    sx={{
                        color: baseColors.green.primary,
                        '&.Mui-checked': {
                            color: baseColors.green.primary,
                        },
                    }}
                    size="medium"
                    checked={checked}
                />

                <CustomText
                    style={{
                        paddingLeft: 0,
                        minWidth: 150,
                        fontSize: baseFontSize.mini,
                    }}
                >
                    {data?.name ?? data?.title ?? data?.goal ?? '-'}
                </CustomText>

                <CustomTagItem isGoals={isGoals} isArticles={isArticles} isWorkshops={isWorkshops} data={data} />
            </div>
        </div>
    );
};

export default CheckBoxSkillItem;
