import { makeStyles } from '@mui/styles';
import { baseColors } from 'src/utils/constants/baseStyles';

const useStyles = makeStyles({
    navbar: {
        display: 'flex',
        background: baseColors.green.light,
        flexDirection: 'row',
        padding: 5,
        justifyContent: 'space-around',
        paddingLeft: 40,
        paddingRight: 40,
        borderRadius: 7,
    },
    col: {
        display: 'flex',
        justifyContent: 'center',
    },
    menuLabel: { cursor: 'pointer', fontWeight: 'bold' },
});

export default useStyles;
