import { ProgramItem } from 'src/interfaces/interfaces';
import { PROGRAM_STATUS } from 'src/utils/constants/enums';

interface GetModulesParams {
    programId: number;
    programItems: Array<Object>;
    allModules: Array<Object>;
    setSelectedProgram: Function;
    setSelected: Function;
    setWorkshopsWithModulesPayload: Function;
    setProgramPayload: Function;
    setDefaultWorkshops: Function;
}

const optimizedProgramData = (programs: Array<any>) =>
    Array.from(programs, (program: ProgramItem) => ({
        id: program?.id,
        label: program?.title,
        modules: program?.modules,
        workshops: program?.workshops,
    }));

const programsWithoutDraft = (programs: Array<any>) =>
    programs?.filter((program: any) => program?.state?.id !== PROGRAM_STATUS.DRAFT);

const programsWithoutKeys = (programs: Array<any>,key : Array<any>) =>
    programs?.filter((program: any) => !key?.includes(program?.state?.id));

const handleGetModules = ({
    programId,
    programItems,
    allModules,
    setSelectedProgram,
    setSelected,
    setWorkshopsWithModulesPayload,
    setProgramPayload,
    setDefaultWorkshops,
}: GetModulesParams) => {
    const result: any = programItems?.find((program: any) => program?.id === programId) ?? [];
    const programWorkshops = result?.workshops?.map((workshop: { id: number }) => workshop.id);
    const programModules: any = result?.modules ?? [];
    const modules = allModules.filter((parentModule: any) =>
        programModules?.find((item: any) => item.id === parentModule.id),
    );
    const moduleWorkshops = modules?.map((item: any) => item.workshops);
    const moduleWorkshopIds = moduleWorkshops?.flat(1)?.map((item: any) => item.id);
    const moduleForPayload =
        modules?.length > 0 &&
        modules?.map((module: any) => ({
            id: module.id,
            workshopIds: module.workshops.map((workshop: any) => workshop.id),
        }));
    const selectedValues =
        programWorkshops?.length > 0 ? [...programWorkshops, ...moduleWorkshopIds] : moduleWorkshopIds;
    setSelectedProgram(result);
    setSelected(selectedValues);
    setDefaultWorkshops(selectedValues);
    setWorkshopsWithModulesPayload(modules);
    setProgramPayload((prev: any) => ({
        ...prev,
        modules: moduleForPayload,
    }));
};

export { programsWithoutDraft, handleGetModules, optimizedProgramData,programsWithoutKeys };
