import React, { FC } from 'react';
import { baseColors, baseFontFamily, baseFontWeight } from 'src/utils/constants/baseStyles';
import { Typography } from '@mui/material';
import useStyles from './styles';
import { Box } from '@mui/system';

interface Intervenant {
    name: string;
    phone: string;
    email: string;
}
interface IntervenantItemProps {
    data: Intervenant;
}

const IntervenantItem: FC<IntervenantItemProps> = (props) => {
    const { data } = props;
    const classes = useStyles();
    const phoneMarginTop = data?.phone ? 2 : 1;
    
    return (
        <Box className={classes.container} marginBottom={3}>
            <Typography
                fontFamily={baseFontFamily.OpenSansRegular}
                fontWeight={baseFontWeight.bold}
                fontSize={13}
                color={baseColors.black}
                marginBottom={1}
            >
                {data?.name ?? ''}
            </Typography>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                <div style={{ display: 'flex', flexDirection: 'column', marginTop: phoneMarginTop }}>
                    <div className={classes.left}>
                        <Typography fontSize={13} color={baseColors.black}>
                            {data?.phone ? data?.phone : 'Non renseigné'}
                        </Typography>
                    </div>
                </div>
                <Box className={`${classes.content} ${classes.email}`}>
                    <Typography fontSize={13} color={baseColors.black}>
                        {data?.email ?? ''}
                    </Typography>
                </Box>
            </div>
        </Box>
    );
};

export default IntervenantItem;
