import { makeStyles } from '@mui/styles';
import { baseColors } from '../../utils/constants/baseStyles';
import { FC } from 'react';

export class NotificationStyleProps {
    activated?: number = 1;
}
const useStyles = makeStyles({
    container: (notificationStyleProps: NotificationStyleProps) => ({
        display: 'flex',
        flexDirection: "row",
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 10,
        backgroundColor: notificationStyleProps.activated===1?baseColors.green.secondary:baseColors.green.light,
        borderRadius: 10,
        padding: 18,
        cursor:'pointer'
        
    }),
    right:{
        display: 'flex',
        flexDirection: "row",
        alignItems: 'center',
    },
    action:{
        backgroundColor: baseColors.white,
        display:'flex',
        flexDirection:'row',
        padding: '5px 18px 5px 18px',
        borderRadius: 10,
        marginRight: 50
    },
    label: {
        color: baseColors.black,
        
    },
    date: {
        color: 'white',
    },
    icons:{
        marginBottom: 15,
        marginLeft:20
    },
    labelWrapper:{
        flexDirection: 'row'
    }, 
    pastille: {

    }
});

export default useStyles;
