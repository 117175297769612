import { Typography } from '@mui/material';
import { FC } from 'react';
import { EventPlanningViewType } from 'src/interfaces/interfaces';
import { baseColors, baseFontFamily, baseFontSize, baseFontWeight } from 'src/utils/constants/baseStyles';
import { combineStartAndEndTime, remoVeAdditionalTimeZone } from 'src/utils/utils';

type EventContentType = {
    eventInfo: any;
    viewType: EventPlanningViewType['viewType'];
};

const EventContent: FC<EventContentType> = (props) => {
    const { eventInfo, viewType } = props;
    const alignCenter = viewType !== 'monthly' ? 'center' : undefined;
    const fontSize = viewType !== 'monthly' ? baseFontSize.sm : '0.9rem';
    const start = remoVeAdditionalTimeZone(eventInfo?.event.startStr);
    const end = remoVeAdditionalTimeZone(eventInfo?.event.endStr);
    const showTime = viewType === 'monthly';

    return (
        <div
            style={{
                backgroundColor: eventInfo?.event?.backgroundColor || baseColors.green.primary,
                height: '100%',
                width: '100%',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    justifyContent: 'space-evenly',
                }}
            >
                <Typography
                    fontFamily={baseFontFamily.OpenSansSemiBold}
                    fontSize={fontSize}
                    style={{
                        fontWeight: baseFontWeight.extraBold,
                        whiteSpace: 'pre-wrap',
                    }}
                    color={eventInfo.event.textColor || baseColors.white}
                    textAlign={alignCenter}
                    justifyContent={alignCenter}
                >
                    {eventInfo?.event?.title}
                </Typography>
                {showTime && (
                    <p style={{ fontSize: 12, color: eventInfo.event.textColor }}>
                        {combineStartAndEndTime(start, end)}
                    </p>
                )}
            </div>
        </div>
    );
};

export default EventContent;
