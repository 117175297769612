import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { baseColors, baseFontFamily, baseFontSize, baseScreenHeight } from 'src/utils/constants/baseStyles';
import { userStoryDateTime } from 'src/utils/utils';
import { tableCellText } from '../SecurityManagement/SecurityManagement';
import { CustomText } from 'src/components/Text/CustomText';

interface UserHistoryTableHeader {
    name: string;
}

interface UserHistoryDataRow {
    id: number;
    user: string;
    title: string;
    createdAt: string;
}

interface UserHistoryTableProps {
    tableHeaders: UserHistoryTableHeader[];
    dataRows: UserHistoryDataRow[];
}

const UserHistoryTable = (props: UserHistoryTableProps = { tableHeaders: [], dataRows: [] }) => {
    const tableHeaders = props.tableHeaders;
    const dataRows = props.dataRows;

    return (
        <TableContainer
            component={Paper}
            style={{ overflow: 'auto', maxHeight: baseScreenHeight.securitySubMenusHeight }}
        >
            <Table stickyHeader sx={{ minWidth: 650 }} aria-label="role table">
                <TableHead>
                    <TableRow>
                        {tableHeaders.map((header, index) => {
                            return (
                                <TableCell key={index} sx={{ color: baseColors.green.primary }}>
                                    <CustomText
                                        color={baseColors.green.primary}
                                        fontFamily={baseFontFamily.OpenSansBold}
                                        fontSize={baseFontSize.mini}
                                    >
                                        {header.name}
                                    </CustomText>
                                </TableCell>
                            );
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {dataRows.map((row) => (
                        <TableRow key={row.user} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" scope="row">
                                {tableCellText(row.user)}
                            </TableCell>
                            <TableCell>{tableCellText(row.title)}</TableCell>
                            <TableCell>{tableCellText(userStoryDateTime(row.createdAt))}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default UserHistoryTable;
