import { Grid, IconButton } from "@mui/material"
import { FC, createRef, useEffect, useState } from "react"
import { UserAxisItem } from "src/interfaces/interfaces"
import { CustomText } from "../Text/CustomText";
import { toast } from "react-toastify";
import SvgImageIcon from "../CustomIcon/ImageIcon";
import { baseColors } from "src/utils/constants/baseStyles";

interface QuestionFileProps {
    userDEResponse: UserAxisItem;
    index: any;
    setPayload : Function;
    questionTitle : JSX.Element;
}
const QuestionFile : FC<QuestionFileProps> = ({userDEResponse, index, setPayload, questionTitle}) => {
    const defaulDoctype: string = 'application/msword';

    const [docName, setDocName] = useState<string>('');
    const documentRef = createRef<HTMLInputElement>();
    const [docType, setDocType] = useState<string>(defaulDoctype);

    useEffect(()=>{
        if(userDEResponse?.file?.name){
            setDocName(userDEResponse.file.name)
        }
    },[])

    const updatePayload = (value: string) => {
        setPayload((prev: any) => {
            const updatedPayload = { ...prev };
            if (updatedPayload.responses && updatedPayload.responses.length > index) {
                updatedPayload.responses[index].filecontent = value;
            }
        
            return updatedPayload; // Return the updated state
        });
    }

    const handleDocumentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let files = event.currentTarget.files;
        const maxSizeMB = 5;
        if (files && files?.length > 0) {
            let file = files[0];
            let allowedExtension = [
                'image/png',
                'image/jpeg',
                'application/pdf',
            ];

            if (file.size > maxSizeMB * 1024 * 1024) {
                toast.error('Le fichier est trop volumineux. La taille maximale est de 5MB.');
                return;
            }

            if (!allowedExtension.includes(file.type)) {
                toast.error('Documents non supporté (veuiller importe des .jpeg, .jpg, .png, .pdf');
                return;
            }

            setDocName(file.name);
            setDocType(file.type);
            const reader = new FileReader();
            reader.onload = (readerEvt:any) => {
                const base64String = readerEvt.target.result
                updatePayload(base64String);
            };

            reader.readAsDataURL(file);
        }
    };

    return (
        <Grid
                style={{ marginTop: 20, marginBottom: 10 }}
                container
                direction="column"
                alignItems={'start'}
                justifyContent="space-between"
            >
            <Grid item>
                {questionTitle}
            </Grid>
            <Grid container direction={'column'} style={{ marginTop: 20, borderStyle: "dashed", borderWidth: 1, borderColor: baseColors.green.dark, borderRadius: 10, width: '80%', padding: '20px' }} justifyContent={'center'}>
                <Grid item alignSelf={'center'}>
                    <IconButton onClick={() => documentRef.current?.click()}>
                        <SvgImageIcon width={40} height={50} />
                    </IconButton>
                </Grid>
                <input ref={documentRef} type={'file'} hidden onChange={handleDocumentChange} />
                <CustomText style={{ textAlign: 'center' }} fontSize={11}>
                    {docName === '' ? 'Cliquer pour joindre un fichier' : docName}
                </CustomText>
            </Grid>
        </Grid>
    )
}

export default QuestionFile