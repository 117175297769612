import { UserHistoryActionType } from "src/actions/userHistory/types";

interface UserHistoryState{
    listUserHistories: any[];
}

const initialState: UserHistoryState = {
    listUserHistories: [],
}


const userHistoryReducer = (userHistoryState: UserHistoryState = initialState, action: any) => {
    
    const { type, payload } = action;

    switch (type) {
        case UserHistoryActionType.LIST_USER_HISTORY:
            let userHistories: any[] = [];
            payload.map((element: any) => {
                userHistories.push({
                    id: element.id,
                    user: element.user.firstName + ' ' + element.user.lastName,
                    title: element.title,
                    preview: element.preview,
                    current: element.current,
                    createdAt: element.createdAt,
                    action: element.action,
                });
                return userHistories;
            });

            return {
                ...userHistoryState,
                listUserHistories:  userHistories,
            }
        default:
            return userHistoryState;
    }
}

export default userHistoryReducer;