import * as React from 'react'

function DashboardActif(props: any) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={24}
			height={24}
			fill='none'
			viewBox='0 0 24 24'
			{...props}
		>
			<path
				fill='#fff'
				d='M17.143 0h3.92C22.683 0 24 1.327 24 2.964v3.953c0 1.636-1.316 2.964-2.938 2.964h-3.92c-1.622 0-2.938-1.328-2.938-2.964V2.964C14.204 1.327 15.52 0 17.143 0zM2.939 0h3.918c1.623 0 2.94 1.327 2.94 2.964v3.953c0 1.636-1.317 2.964-2.94 2.964H2.94C1.316 9.881 0 8.553 0 6.917V2.964C0 1.327 1.316 0 2.939 0zm0 14.119h3.918c1.623 0 2.94 1.327 2.94 2.965v3.952C9.796 22.673 8.48 24 6.856 24H2.94C1.316 24 0 22.673 0 21.036v-3.952c0-1.638 1.316-2.965 2.939-2.965zm14.204 0h3.92c1.621 0 2.937 1.327 2.937 2.965v3.952C24 22.673 22.684 24 21.062 24h-3.92c-1.622 0-2.938-1.327-2.938-2.964v-3.952c0-1.638 1.316-2.965 2.939-2.965z'
			/>
		</svg>
	)
}

export default DashboardActif
