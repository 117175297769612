import { Document } from 'src/interfaces/interfaces';
import axios from './AxiosInstance';

const getDocs = (searchKey?: string) => {
    let additionalUrl: string = '';

    if (searchKey) {
        additionalUrl += `&keywords=${searchKey}`;
    }

    return axios.get('api/documents?sortId=DESC' + additionalUrl);
} 

const postDocs = (document: Document) => axios.post('api/documents', { ...document })

const searchDocs = () =>  axios.get('api/documents?paginated=false');

const getHdjDocuments = (
    dayHospitalId: number,
    searchKey: string
) => {

    let additionalUrl: string = '';

    if (searchKey !== '') {
        additionalUrl += `/search?keywords=${searchKey}`
        if (dayHospitalId > 0) {
            additionalUrl += `&dayHospitalId=${dayHospitalId}`
        }
    }else{
        if (dayHospitalId > 0) {
            additionalUrl += `?dayHospitalId=${dayHospitalId}`
        }
    }

    return axios.get(`api/documents${additionalUrl}`);
};

export {
    getDocs,
    postDocs,
    searchDocs,
    getHdjDocuments
}