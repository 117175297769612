import { makeStyles } from '@mui/styles';
import { baseColors } from '../../utils/constants/baseStyles';

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: baseColors.white,
    },
    flexContentSpaceBetween: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    flexContentAround: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
    },
    userName: {
        color: baseColors.black,
    },
}));

export default useStyles;
