import { ButtonBase } from '@mui/material';
import { FC } from 'react';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import { CustomText } from '../Text/CustomText';
import SvgPdf from '../CustomIcon/SvgPdf';

interface ReportItemProps {
    type?: string;
    title?: string;
    onPress?: Function;
    handlePress?: Function;
}

export const ReportItem: FC<ReportItemProps> = (props) => {
    const { type, title, onPress, handlePress } = props;
    return (
        <ButtonBase
            style={{
                width: '98%',
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
                background: baseColors.green.dark,
                paddingTop: 15,
                paddingBottom: 15,
                overflow: 'hidden',
                borderRadius: 8,
                marginBottom: 8,
                alignItems: 'center',
                cursor: 'default',
            }}
            onClick={() => onPress?.()}
        >
            <div
                onClick={() => onPress?.()}
                style={{
                    cursor: 'pointer',
                }}
            >
                <div style={{ paddingLeft: 20 }}>
                    <CustomText color={baseColors.white} fontFamily={baseFontFamily.OpenSansSemiBold}>
                        {title}
                    </CustomText>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        position: 'relative',
                        alignItems: 'center',
                        paddingRight: type !== 'APA' ? 8 : 0,
                    }}
                >
                    <CustomText
                        style={{ marginRight: type !== 'APA' ? 50 : 58 }}
                        color={baseColors.white}
                        fontFamily={baseFontFamily.BreeSerif}
                    >
                        {type}
                    </CustomText>
                </div>
            </div>

            <div
                style={{
                    background: 'rgba(255,255,255,0.35)',
                    width: 105,
                    height: 90,
                    borderRadius: 45,
                    right: -25,
                    position: 'absolute',
                    cursor: 'pointer',
                }}
                onClick={() => handlePress?.()}
            />
            <SvgPdf />
        </ButtonBase>
    );
};
