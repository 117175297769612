import { Typography, Box, Divider, Checkbox } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { AgendaContext } from 'src/providers/AgendaProvider';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import { agendaPlanningEvents } from 'src/utils/constants/constants';
import { allOptions, checkValueExistance, handleAddAllValue, handleSearchItem } from '../FilterLogic';
import useStyles from './styles';
import { specialCharToUnderscore } from 'src/utils/utils';
import { SearchInputField } from 'src/components/InputField/SearchInputField';
import CustomEmptyData from 'src/components/CustomEmptyData';

type EventContentType = {
    selected: number[];
    setSelected: Function;
    selectedItems: number[];
};

const EventFilterContent: FC<EventContentType> = ({ selected, setSelected, selectedItems }) => {
    const { setCheckedEvents } = useContext(AgendaContext);
    const classes = useStyles();
    const [filteredItems, setFilteredItems] = useState<Array<any>>([]);
    const [searchQuery, setSearchQuery] = useState<string>('');

    const handleClick = (value: string) =>
        setSelected((prev: any) =>
            checkValueExistance({
                prev,
                programId: value,
                setCheckedPrograms: setCheckedEvents,
                allItems: agendaPlanningEvents,
            }),
        );

    const hasSearchkey = searchQuery?.trim()?.length;
    const combinedData = hasSearchkey
        ? [allOptions, ...filteredItems]
        : agendaPlanningEvents?.length
        ? [allOptions, ...agendaPlanningEvents]
        : [];

    const showSecondTitle = selected?.includes(-1);

    useEffect(() => {
        if (selected?.length <= selectedItems?.length) {
            handleAddAllValue({ setSelected, selectedItems, combinedData });
        }
    }, [selected]);

    return (
        <>
            <SearchInputField
                onChange={(value: any) => {
                    setSearchQuery(value);
                    handleSearchItem({ items: agendaPlanningEvents, searchkey: value, setItems: setFilteredItems });
                }}
                placeholder="Rechercher par nom"
                value={searchQuery}
            />
            {hasSearchkey && !filteredItems?.length ? (
                <CustomEmptyData labelFontSize={12} marginTop={10} height={40} width={40} />
            ) : (
                agendaPlanningEvents?.length > 0 &&
                combinedData.map((event: any, i: number) => (
                    <Box key={event.value}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            <Checkbox
                                onClick={() => handleClick(event.value)}
                                sx={{
                                    color: baseColors.green.primary,
                                    '&.Mui-checked': {
                                        color: baseColors.green.primary,
                                    },
                                }}
                                size="small"
                                checked={selected?.length > 0 && selected.includes(event.value)}
                                id={specialCharToUnderscore(event.title ?? '')}
                            />

                            <Typography
                                style={{
                                    fontFamily: baseFontFamily.OpenSansSemiBold,
                                }}
                                fontSize={12}
                                color={baseColors.black}
                                marginBottom={1}
                                marginTop={1}
                            >
                                {showSecondTitle && event.id === -1 ? event.secondTitle : event.title}
                            </Typography>
                        </Box>

                        {i === 0 && <Divider className={classes.divider} />}
                    </Box>
                ))
            )}
        </>
    );
};

export default EventFilterContent;
