import * as React from 'react';
import { baseColors } from 'src/utils/constants/baseStyles';

function AgendaPresentielDetail(props: any) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} fill="none" viewBox="0 0 40 40" {...props}>
            <rect
                width={40}
                height={40}
                fill={props?.isGreen ? baseColors.green.primary : baseColors.orange.primary}
                rx={6}
            />
            <path
                fill="#fff"
                d="M20 10c-4.16 0-7.544 3.384-7.544 7.544 0 5.989 6.735 12.005 7.021 12.259a.79.79 0 001.061-.016c.286-.267 7.006-6.614 7.006-12.243C27.544 13.384 24.16 10 20 10zm0 11.141a3.597 3.597 0 110-7.194 3.597 3.597 0 010 7.194z"
            />
        </svg>
    );
}

export default AgendaPresentielDetail;
