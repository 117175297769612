import { useState, FC } from 'react';
import { createContext } from 'react';
import { Time } from 'src/interfaces/interfaces';

type AgendaSettingsType = {
    time: Time;
    setTime: (val: Time) => void;
};

export const AgendaSettingsContext = createContext<AgendaSettingsType>({
    time: {
        startHour: '',
        endHour: '',
    },
    setTime: () => {},
});

const AgendaSettingsProvider: FC = (props) => {
    const { children } = props;
    const [time, setTime] = useState<Time>({
        startHour: '08:00',
        endHour: '18:00',
    });

    return (
        <AgendaSettingsContext.Provider
            value={{
                time,
                setTime,
            }}
        >
            {children}
        </AgendaSettingsContext.Provider>
    );
};

export default AgendaSettingsProvider;
