import { baseColors } from 'src/utils/constants/baseStyles';

const IconRoleUser = (props: any) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={27}
            height={27}
            fill={baseColors.green.primary}
            viewBox="0 0 27 27"
            {...props}
        >
            <path
                d="M19.71,14.9a4.75,4.75,0,1,0,4.74,4.74h0A4.76,4.76,0,0,0,19.71,14.9Zm.83,5.16a1,1,0,0,1-.37-.08l-1.79,1.79a.57.57,0,0,1-.37.16.42.42,0,0,1-.38-.16.57.57,0,0,1,0-.79l1.79-1.79a1.23,1.23,0,0,1-.08-.38,1.43,1.43,0,0,1,1.3-1.57h.11a1,1,0,0,1,.38.08c.08,0,.08.09,0,.13l-.83.79a.13.13,0,0,0-.06.16s0,0,.06.05l.54.54a.16.16,0,0,0,.22,0l0,0,.79-.79s.16,0,.16,0a1.66,1.66,0,0,1,.09.37,1.5,1.5,0,0,1-1.51,1.47Z"
                transform="translate(-0.38 -0.38)"
                fill={baseColors.orange.primary}
            />
            <circle cx="10.71" cy="7.16" r="7.16" fill={baseColors.green.primary} />
            <path
                d="M13,26.89c1.22,0,.55-.83.55-.83h0a8.57,8.57,0,0,1-1.88-5.38,8.34,8.34,0,0,1,.77-3.55.71.71,0,0,1,.11-.17c.39-.78-.38-.83-.38-.83h0a6.28,6.28,0,0,0-1.06-.06A10.92,10.92,0,0,0,.38,25.39c0,.56.17,1.55,1.89,1.55H12.86C13,26.89,13,26.89,13,26.89Z"
                transform="translate(-0.38 -0.38)"
                fill={baseColors.green.primary}
            />
            <path
                d="M22.06,18.66a1.49,1.49,0,0,1-1.51,1.47h-.11a1.24,1.24,0,0,1-.38-.09l-1.79,1.79a.52.52,0,0,1-.37.16.38.38,0,0,1-.37-.16.57.57,0,0,1,0-.79l1.78-1.79a1.61,1.61,0,0,1-.08-.38,1.44,1.44,0,0,1,1.3-1.57h.12a1.25,1.25,0,0,1,.37.09c.08,0,.08.08,0,.12l-.83.8a.11.11,0,0,0-.06.15s0,0,.06.05l.54.54a.16.16,0,0,0,.22,0l0,0,.79-.79s.16,0,.16.05A1.19,1.19,0,0,1,22.06,18.66Z"
                transform="translate(-0.38 -0.38)"
                fill="#fff"
            />
            <path
                d="M19.76,25.93A6.24,6.24,0,1,1,26,19.69,6.26,6.26,0,0,1,19.76,25.93Zm0-10.76a4.52,4.52,0,1,0,4.52,4.52A4.53,4.53,0,0,0,19.76,15.17Z"
                transform="translate(-0.38 -0.38)"
                fill="#fff"
            />
        </svg>
    );
};

export default IconRoleUser;
