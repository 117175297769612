import { ConnexionActionType } from 'src/actions/connexion/types';

interface ConnexionElement {
    id: number;
    username: string;
    connexion: Date;
    deconnexion: Date;
}

interface ConnexionState {
    listConnexions: any[];
}

const initialState: ConnexionState = {
    listConnexions: [],
};

const connexionReducer = (connexionState: ConnexionState = initialState, action: any) => {
    const { type, payload } = action;

    switch (type) {
        case ConnexionActionType.LIST_CONNEXIONS:
            let connexions: any[] = [];
            payload.map((userSession: any) => {
                connexions.push({
                    id: userSession.id,
                    username: userSession.username,
                    connexion: userSession.lastLogin.date,
                    deconnexion: userSession.lastLogout.date,
                });

                return connexions;
            });

            return {
                ...connexionState,
                listConnexions: connexions,
            };
        default:
            return connexionState;
    }
};

export default connexionReducer;
