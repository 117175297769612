import { useContext, useEffect, useState } from 'react';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { ROUTES, wrappedComponent } from './routes/routes';
import { Login } from './views/Login/Login';
import store from './stores/store';
import { Provider } from './providers';
import setupInterceptors from 'src/services/Token/setupInterceptors';
import { PasswordForgot } from './views/Login/PasswordForgot';
import { PasswordReset } from './views/Login/PasswordReset';
import Wrapper from './components/Wrapper';
import { NotFound } from './views/Error/NotFound';
import ProtectedRoute from './routes/ProtectedRoute';
import { PrivateProgramRoutes } from './routes/PrivateProgramRoute';
import UserActivation from './views/Login/UserActivation';
import { PlateformTypeContext } from './providers/PlateformTypeProvider';
import useCheckUserRole from './hooks/useCheckUserRole';
import MailVerification from './views/Login/MailVerification';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PrivateHdjRoutes } from './routes/PrivateHdjRoute';
import InactiveAccount from './views/Login/InactiveAccount';
import { ToastContainer } from 'react-toastify';
import requestForToken from './services/Firebase/firebase.service';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnReconnect: true,
        },
    },
});

const App = () => {
    const { roles } = useCheckUserRole();
    const { isCoordonatorFromPlateform } = useContext(PlateformTypeContext);
    const [allowed, setAllowed] = useState(false);
    const status = isCoordonatorFromPlateform || roles?.isCoordinator;
    useEffect(() => {
        setAllowed(status ?? false);
    }, [status]);

    useEffect(() => {
        requestForToken();
    }, []);

    return (
        <QueryClientProvider client={queryClient}>
            <ReduxProvider store={store}>
                <Provider>
                    <Router>
                        <Switch>
                            <Route exact path={ROUTES.login} component={Login} />
                            <Route exact path={ROUTES.passwordForgot} component={PasswordForgot} />
                            <Route exact path={ROUTES.inactiveAccount} component={InactiveAccount} />
                            <Route exact path={ROUTES.passwordReset} component={PasswordReset} />
                            <Route exact path={ROUTES.mailVerification} component={MailVerification} />
                            <Route exact path={ROUTES.userActivationCpts} component={UserActivation} />
                            {PrivateProgramRoutes.map((route) => (
                                <Route
                                    exact
                                    key={route.key}
                                    path={route.path}
                                    component={() => (
                                        <ProtectedRoute isProgram isAllowed={allowed}>
                                            {route.component}
                                        </ProtectedRoute>
                                    )}
                                />
                            ))}

                            {PrivateHdjRoutes.map((route) => (
                                <Route
                                    exact
                                    key={route.key}
                                    path={route.path}
                                    component={() => (
                                        <ProtectedRoute isProgram isAllowed={allowed}>
                                            {route.component}
                                        </ProtectedRoute>
                                    )}
                                />
                            ))}
                            {wrappedComponent.map((route) => (
                                <Route exact key={route.key} path={route.path} component={route.component} />
                            ))}
                            <Route path="*" component={Wrapper(NotFound)} />
                        </Switch>
                    </Router>
                </Provider>
            </ReduxProvider>

            <ReactQueryDevtools initialIsOpen />
            <ToastContainer />
        </QueryClientProvider>
    );
};

setupInterceptors(store);

export default App;
