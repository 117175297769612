import { makeStyles } from '@mui/styles';
import { baseBorderRadius, baseColors, baseScreenHeight } from 'src/utils/constants/baseStyles';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        minWidth: 660,
        backgroundColor: baseColors.white,
        ...baseScreenHeight.defaultHeight,
        ...baseBorderRadius.leftOnly,
    },
    wrapper: { padding: 20, justifyContent: 'center', overflowY: 'auto' },
    row: {
        flexDirection: 'row',
        display: 'flex',
        marginTop: 10,
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    programListContainer: { maxHeight: '66%', overflowY: 'auto', marginTop: 10, marginBottom: 20 },
    formCard: {
        // flex: 1,
        width: '28%',
        paddingLeft: 5,
        paddingRight: 5,
    },
    programNameForm: {
        //maxWidth: '40%',
        //marginRight: 10,
        flex: 3,
    },
    inlineForm: {
        flex: 2,
        //width: '20%',
        marginLeft: 10,
    },
    inlineFormItem: { display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20 },
    flexCenter: { display: 'flex', justifyContent: 'center' },
    col: { display: 'flex', flexDirection: 'column' },
    intervenant: {
        marginLeft: -10
    },
});

export default useStyles;
