import { Dispatch, SetStateAction, useState } from 'react';
import { toast } from 'react-toastify';
import { DeleteRequestResponse, SpecialityItem } from 'src/interfaces/interfaces';
import { sortByIds } from 'src/utils/utils';
import { useFetchSpecialitiesRQHooks } from './ReactQueryHooks/useEvenRQtHooks';
import SpecialityService, { PostSpecialitiesPayload } from 'src/services/Speciality.service';
import { defaultSpecialityPaylopad } from 'src/views/Settings/Specialities/Speciality.utils';

interface IProps {
    toggleModal?: Function;
    setPayload?: Dispatch<SetStateAction<PostSpecialitiesPayload>>;
    setUpdateList?: Dispatch<SetStateAction<boolean>>;
    setModalConfirmVisible?: Dispatch<SetStateAction<boolean>>;
    setSpeciality?: Dispatch<SetStateAction<SpecialityItem>>;
}

const useSpecialityHooks = ({
    toggleModal,
    setPayload,
    setUpdateList,
    setModalConfirmVisible,
    setSpeciality,
}: IProps) => {
    const [saving, setSaving] = useState<boolean>(false);
    const { data, isLoading: fetchingSpecialities, refetch } = useFetchSpecialitiesRQHooks();
    const specialities = sortByIds(data ?? []);

    const successfull = (message: string, response?: DeleteRequestResponse) => {
        if (response?.code === 409) {
            toast.error("Impossible de supprimer la spécialité car c'est déjà lié à d'autre donnée");
            setModalConfirmVisible?.((prev) => !prev);
        } else if (response?.code === 400) {
            toast.error('Spécialité existe déjà');
        } else {
            setUpdateList?.((prev) => !prev);
            toggleModal?.();
            setSpeciality?.({} as SpecialityItem);
            setModalConfirmVisible?.((prev) => !prev);
            toast.success(message, { style: { zIndex: 150 } });
            setPayload?.(defaultSpecialityPaylopad);
        }
    };

    const failed = (message: string) => {
        toast.error(message);
        setSaving(false);
    };

    const handleCreateNewSpeciality = ({ payload }: { payload: PostSpecialitiesPayload }) => {
        setSaving(true);
        const finalPayload = {
            ...payload,
        };

        SpecialityService.PostSpecialities(finalPayload)
            .then((res) => {
                setSaving(false);
                refetch();
                if (res?.data?.code === 400) toast.error('Spécialité existe déjà');
                else successfull('Création de la spécialité réussie');
            })
            .catch(() => failed('Une erreur est survenue lors du création de la spécialité'));
    };

    const handleEditSpeciality = ({
        specialityId,
        payload,
    }: {
        specialityId: number;
        payload: PostSpecialitiesPayload;
    }) => {
        setSaving(true);
        SpecialityService.PutSpecialities({ specialityId, payload })
            .then(() => {
                setSaving(false);
                refetch();
                successfull('Modification de la spécialité réussie');
            })
            .catch(() => failed('Une erreur est survenue lors du modification de la spécialité'));
    };

    const handleDeleteSpeciality = (specialityId: number) => {
        setSaving(true);
        SpecialityService.DeleteSpecialities(specialityId)
            .then((response: any) => {
                setSaving(false);
                refetch();
                successfull('Spécialité supprimé', response?.data);
            })
            .catch(() => failed('Une erreur est survenue lors de suppression de la spécialité'));
    };

    return {
        handleCreateNewSpeciality,
        saving,

        specialities,
        fetchingSpecialities,

        handleDeleteSpeciality,
        handleEditSpeciality,
    };
};

export default useSpecialityHooks;
