import React, { useEffect } from 'react';
import 'draft-js/dist/Draft.css';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { baseColors } from 'src/utils/constants/baseStyles';
import { stateFromMarkdown } from 'draft-js-import-markdown';
import { stateToMarkdown } from 'draft-js-export-markdown';

export const StateFromMarkdown = (text) => {
    return stateFromMarkdown(text);
};

export const CustomTextEditor = ({ handleChange, editorState, setEditorState, data }) => {
    const editor = React.useRef(null);
    useEffect(() => {
        handleChange(stateToMarkdown(editorState.getCurrentContent()));
    }, [editorState]);

    return (
        <div
            style={{
                border: '1px solid ' + baseColors.green.dark,
                minHeight: '25vh',
                maxHeight: '50vh',
                cursor: 'text',
                marginTop: 40,
                background: 'white',
                padding: 20,
                borderRadius: 10,
                width: '100%',
            }}
        >
            <Editor
                ref={editor}
                showSuggestions
                editorState={editorState}
                onEditorStateChange={setEditorState}
                mention={{
                    separator: ' ',
                    trigger: '@',
                    suggestions: data,
                }}
                toolbar={{
                    inline: { inDropdown: true },
                    list: { inDropdown: true },
                    textAlign: { inDropdown: true },
                    link: { inDropdown: true },
                    history: { inDropdown: true },
                }}
                editorStyle={{
                    height: '15vh',
                }}
            />
        </div>
    );
};
