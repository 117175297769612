import { baseColors } from 'src/utils/constants/baseStyles';

function SvgLegume(props) {
    return (
        <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.4566 15.9531C10.8778 15.3609 10.1376 14.952 9.32811 14.7775C8.51862 14.6029 7.67566 14.6703 6.90423 14.9714C6.13279 15.2724 5.46697 15.7938 4.98969 16.4705C4.51242 17.1472 4.24478 17.9494 4.22012 18.7771C3.75921 19.0431 3.36771 19.4142 3.07757 19.8603C2.78743 20.3063 2.60688 20.8147 2.55064 21.3438C2.4944 21.8729 2.56408 22.4079 2.75398 22.9049C2.94388 23.402 3.24862 23.8471 3.64334 24.204C4.03805 24.5609 4.51155 24.8193 5.0252 24.9583C5.53884 25.0973 6.07807 25.1129 6.59888 25.0037C7.11969 24.8946 7.60731 24.6639 8.02195 24.3304C8.4366 23.9969 8.76651 23.5701 8.98477 23.0848C9.6632 22.9959 10.3099 22.7435 10.8692 22.3494C11.3585 22.5586 11.8903 22.6491 12.4212 22.6136C12.9521 22.5781 13.4672 22.4176 13.9242 22.1451C14.3813 21.8726 14.7675 21.4959 15.0512 21.0457C15.3349 20.5956 15.5082 20.0847 15.5569 19.5548C15.6055 19.0249 15.5282 18.491 15.3312 17.9967C15.1342 17.5024 14.8232 17.0616 14.4234 16.7105C14.0236 16.3593 13.5464 16.1076 13.0308 15.976C12.5152 15.8444 11.9758 15.8365 11.4566 15.9531Z"
                fill="#1B8E95"
            />
            <path
                d="M9.97146 23.6754C9.80156 23.6755 9.6356 23.6242 9.49531 23.5284C9.35502 23.4325 9.24693 23.2966 9.18519 23.1383L7.65147 19.2081C7.61119 19.1049 7.59164 18.9947 7.59393 18.8839C7.59622 18.7732 7.62031 18.6639 7.66483 18.5624C7.70934 18.461 7.7734 18.3693 7.85336 18.2925C7.93331 18.2158 8.02759 18.1556 8.13082 18.1153C8.23405 18.0751 8.3442 18.0555 8.45498 18.0578C8.56576 18.0601 8.67501 18.0842 8.77648 18.1287C8.87795 18.1732 8.96966 18.2373 9.04638 18.3172C9.12309 18.3972 9.18331 18.4915 9.22358 18.5947L10.7576 22.5248C10.8076 22.6527 10.8255 22.7908 10.81 22.9272C10.7945 23.0636 10.7459 23.1942 10.6684 23.3075C10.591 23.4209 10.4871 23.5137 10.3657 23.5778C10.2443 23.6419 10.1091 23.6754 9.97178 23.6754H9.97146Z"
                fill="#64C7C7"
            />
            <path
                d="M11.5444 23.0621C11.5185 23.0621 11.4925 23.0609 11.4662 23.0585C11.3559 23.0484 11.2486 23.0166 11.1505 22.965C11.0525 22.9134 10.9655 22.843 10.8947 22.7578C10.8238 22.6726 10.7705 22.5742 10.7376 22.4684C10.7048 22.3626 10.6931 22.2513 10.7033 22.141C10.8347 20.7403 11.4141 19.4194 12.3554 18.3739C12.4294 18.2907 12.5192 18.2229 12.6196 18.1745C12.7199 18.1262 12.8288 18.0981 12.9401 18.0921C13.0513 18.086 13.1626 18.102 13.2676 18.1392C13.3726 18.1764 13.4692 18.234 13.5518 18.3087C13.6345 18.3834 13.7015 18.4737 13.749 18.5745C13.7966 18.6752 13.8237 18.7844 13.8288 18.8956C13.834 19.0069 13.817 19.1181 13.779 19.2228C13.7409 19.3275 13.6825 19.4236 13.6071 19.5056C12.9101 20.2798 12.4811 21.2581 12.3837 22.2953C12.3645 22.5047 12.2678 22.6994 12.1126 22.8413C11.9573 22.9831 11.7547 23.0619 11.5444 23.0621Z"
                fill="#64C7C7"
            />
            <path
                d="M8.39912 24.2895C8.19004 24.2897 7.98838 24.212 7.83349 24.0716C7.05934 23.3745 6.08114 22.9455 5.04395 22.8481C4.9336 22.838 4.82634 22.8062 4.72827 22.7546C4.6302 22.703 4.54326 22.6326 4.4724 22.5474C4.40154 22.4622 4.34816 22.3639 4.3153 22.2581C4.28244 22.1523 4.27074 22.041 4.28088 21.9307C4.29102 21.8203 4.32279 21.7131 4.37439 21.615C4.42598 21.5169 4.49639 21.43 4.58158 21.3591C4.66678 21.2883 4.7651 21.2349 4.87092 21.202C4.97675 21.1692 5.08801 21.1575 5.19835 21.1676C6.59871 21.2992 7.91942 21.8784 8.96485 22.8193C9.0915 22.9336 9.18052 23.0836 9.22018 23.2496C9.25985 23.4155 9.24828 23.5895 9.18701 23.7488C9.12574 23.908 9.01765 24.0449 8.87699 24.1414C8.73633 24.2379 8.56972 24.2896 8.39912 24.2895Z"
                fill="#64C7C7"
            />
            <path
                d="M19.8641 38.8968L13.5759 41.351L7.51535 29.2023C7.22863 28.6274 7.06486 27.9992 7.03447 27.3575C7.00408 26.7158 7.10775 26.0749 7.33885 25.4755C7.56995 24.8762 7.92342 24.3315 8.37672 23.8764C8.83002 23.4212 9.3732 23.0655 9.97161 22.832C10.5701 22.5984 11.2106 22.4922 11.8524 22.52C12.4942 22.5478 13.1232 22.709 13.6992 22.9934C14.2753 23.2779 14.7857 23.6792 15.1979 24.1719C15.6102 24.6646 15.9153 25.2378 16.0937 25.8549L19.8641 38.8968Z"
                fill={baseColors.orange.primary}
            />
            <path d="M28.6875 2.53125H32.0625V26.1562H28.6875V2.53125Z" fill="#1B8E95" />
            <path
                d="M30.2811 19.6109C30.201 19.1035 30.0673 18.6061 29.8825 18.1269L26.1563 8.4375L23.625 9.28125L27.2394 18.6785C27.6391 19.7175 27.8439 20.8212 27.8438 21.9343V24.9286L30.2811 19.6109Z"
                fill="#1B8E95"
            />
            <path
                d="M33.9935 34.3564C34.4099 34.9118 34.635 35.5871 34.635 36.2812C34.635 36.9754 34.4099 37.6507 33.9935 38.2061L33.9819 38.2219C33.6479 38.6672 33.2148 39.0287 32.7168 39.2777C32.2189 39.5266 31.6699 39.6562 31.1132 39.6562H29.6366C29.0799 39.6562 28.5309 39.5266 28.0329 39.2777C27.535 39.0287 27.1019 38.6672 26.7679 38.2219L26.7563 38.2061C26.3398 37.6507 26.1147 36.9754 26.1147 36.2812C26.1147 35.5871 26.3398 34.9118 26.7563 34.3564L27.8436 32.9062V24.9286L30.281 19.6109L30.3749 19.4062L34.5936 8.4375L37.1249 9.28125L33.5105 18.6785C33.1108 19.7175 32.9059 20.8212 32.9061 21.9343V32.9062L33.9935 34.3564Z"
                fill="#1B8E95"
            />
            <path
                d="M33.9935 38.2063L33.9819 38.2221C33.6272 38.6956 33.1604 39.0736 32.6235 39.3221L27.5789 39.0068C27.2685 38.7901 26.9947 38.5252 26.7679 38.2221L26.7563 38.2063C26.3398 37.651 26.1147 36.9756 26.1147 36.2815C26.1147 35.5874 26.3398 34.912 26.7563 34.3567L26.8311 34.2565L27.8436 33.7502L32.9062 21.9346V32.9065L33.9935 34.3567C34.41 34.912 34.635 35.5874 34.635 36.2815C34.635 36.9756 34.41 37.651 33.9935 38.2063Z"
                fill="#027E2A"
            />
            <path
                d="M8.49572 31.4976C8.29884 31.4978 8.10807 31.4292 7.95645 31.3036C7.80482 31.1781 7.70188 31.0034 7.66545 30.8099C7.62902 30.6164 7.6614 30.4163 7.75698 30.2442C7.85256 30.0721 8.00532 29.9388 8.18881 29.8674L9.76082 29.254C9.9693 29.1727 10.2016 29.1775 10.4065 29.2673C10.6114 29.3572 10.7723 29.5249 10.8536 29.7333C10.935 29.9418 10.9302 30.1741 10.8403 30.379C10.7504 30.5839 10.5828 30.7448 10.3743 30.8261L8.80285 31.4397C8.70497 31.4779 8.60081 31.4975 8.49572 31.4976Z"
                fill="#E78244"
            />
            <path
                d="M13.2119 29.6566C13.015 29.6568 12.8243 29.5882 12.6727 29.4627C12.5211 29.3371 12.4182 29.1625 12.3818 28.969C12.3453 28.7756 12.3777 28.5755 12.4732 28.4034C12.5687 28.2313 12.7214 28.098 12.9048 28.0266L14.477 27.4132C14.6854 27.3318 14.9177 27.3366 15.1226 27.4265C15.3276 27.5164 15.4884 27.6841 15.5697 27.8925C15.6511 28.101 15.6463 28.3333 15.5564 28.5382C15.4665 28.7431 15.2988 28.904 15.0904 28.9853L13.5189 29.5988C13.421 29.637 13.3169 29.6566 13.2119 29.6566Z"
                fill="#E78244"
            />
            <path
                d="M9.85217 34.5648C9.65529 34.5651 9.46452 34.4965 9.31289 34.3709C9.16126 34.2453 9.05832 34.0707 9.02189 33.8772C8.98547 33.6837 9.01785 33.4836 9.11342 33.3114C9.209 33.1393 9.36176 33.006 9.54525 32.9347L11.7738 32.0909C11.877 32.0507 11.9872 32.0311 12.098 32.0334C12.2088 32.0357 12.318 32.0598 12.4195 32.1043C12.521 32.1488 12.6127 32.2129 12.6894 32.2928C12.7662 32.3728 12.8264 32.4671 12.8667 32.5703C12.907 32.6735 12.9265 32.7837 12.9242 32.8945C12.9219 33.0053 12.8979 33.1145 12.8533 33.216C12.8088 33.3175 12.7448 33.4092 12.6648 33.4859C12.5848 33.5627 12.4906 33.6229 12.3873 33.6632L10.1587 34.5069C10.061 34.5451 9.95704 34.5647 9.85217 34.5648Z"
                fill="#E78244"
            />
            <path
                d="M15.2249 32.4945C15.0281 32.4947 14.8374 32.4261 14.6858 32.3005C14.5343 32.175 14.4314 32.0003 14.3949 31.8069C14.3585 31.6135 14.3909 31.4134 14.4864 31.2413C14.5819 31.0692 14.7346 30.9359 14.918 30.8645L15.7041 30.5577C15.9126 30.4764 16.1448 30.4812 16.3498 30.5711C16.5547 30.661 16.7156 30.8286 16.7969 31.0371C16.8782 31.2456 16.8734 31.4779 16.7835 31.6828C16.6936 31.8878 16.526 32.0486 16.3175 32.1299L15.5314 32.4366C15.4338 32.4748 15.3298 32.4945 15.2249 32.4945Z"
                fill="#E78244"
            />
            <path
                d="M19.9336 21.0938H21.9361C22.6022 21.0938 23.2551 21.2793 23.8216 21.6295C24.3882 21.9797 24.846 22.4808 25.1437 23.0766L25.8398 24.4688H23.8373C23.1714 24.4687 22.5186 24.2833 21.9521 23.9332C21.3856 23.5831 20.9278 23.0821 20.63 22.4865L19.9336 21.0938Z"
                fill="#64C7C7"
            />
            <path
                d="M24.0083 29.3203C23.7845 29.3203 23.5699 29.2315 23.4117 29.0732C23.2534 28.915 23.1646 28.7004 23.1646 28.4766C23.1676 27.0927 23.6485 25.7523 24.5258 24.6821C25.4032 23.6119 26.6232 22.8775 27.9796 22.6031L28.0617 22.5867C28.2811 22.5428 28.509 22.5879 28.6952 22.7121C28.8813 22.8362 29.0106 23.0293 29.0544 23.2487C29.0983 23.4682 29.0532 23.696 28.929 23.8822C28.8049 24.0684 28.6119 24.1976 28.3924 24.2415L28.3105 24.2578C27.3362 24.4549 26.4599 24.9824 25.8297 25.7511C25.1995 26.5198 24.8542 27.4826 24.852 28.4766C24.852 28.7004 24.7632 28.915 24.6049 29.0732C24.4467 29.2315 24.2321 29.3203 24.0083 29.3203Z"
                fill="#0D4447"
            />
            <path
                d="M33.9935 38.2063L33.9819 38.2221C33.6272 38.6956 33.1604 39.0736 32.6235 39.3221L27.5789 39.0068C27.2685 38.7901 26.9947 38.5252 26.7679 38.2221L26.7563 38.2063C26.3398 37.651 26.1147 36.9756 26.1147 36.2815C26.1147 35.5874 26.3398 34.912 26.7563 34.3567L26.8311 34.2565L27.8436 33.7502L32.9062 21.9346V32.9065L33.9935 34.3567C34.41 34.912 34.635 35.5874 34.635 36.2815C34.635 36.9756 34.41 37.651 33.9935 38.2063Z"
                fill="#E6F4F4"
            />
            <path
                d="M20.9873 26.7891C21.3975 26.7891 21.8014 26.8903 22.1631 27.0839C22.5248 27.2775 22.8331 27.5574 23.0607 27.8987C23.1647 28.0547 23.3056 28.1826 23.471 28.2711C23.6363 28.3596 23.8209 28.4059 24.0084 28.4059C24.1959 28.4059 24.3806 28.3596 24.5459 28.2711C24.7112 28.1826 24.8522 28.0547 24.9562 27.8987C25.1837 27.5574 25.492 27.2775 25.8537 27.0839C26.2154 26.8904 26.6193 26.7891 27.0295 26.7891C27.4032 26.7891 27.7721 26.8731 28.1089 27.035C28.4457 27.1969 28.7418 27.4324 28.9753 27.7243L29.1734 27.9719C29.8833 28.8592 30.3001 29.9452 30.366 31.0796C30.432 32.2141 30.1439 33.341 29.5416 34.3047L27.8953 36.9388C27.7386 37.1895 27.5208 37.3961 27.2624 37.5394C27.0039 37.6827 26.7132 37.7578 26.4177 37.7578C25.9555 37.7578 25.5123 37.5742 25.1856 37.2474C25.031 37.0929 24.8475 36.9702 24.6455 36.8866C24.4435 36.8029 24.227 36.7599 24.0084 36.7599C23.7898 36.7599 23.5733 36.8029 23.3714 36.8866C23.1694 36.9702 22.9859 37.0929 22.8313 37.2474C22.5045 37.5742 22.0613 37.7578 21.5992 37.7578C21.3037 37.7578 21.013 37.6827 20.7545 37.5394C20.496 37.3961 20.2782 37.1895 20.1216 36.9388L18.4752 34.3047C17.873 33.341 17.5849 32.2141 17.6509 31.0796C17.7168 29.9452 18.1335 28.8592 18.8434 27.9719L19.0415 27.7243C19.2749 27.4325 19.571 27.1969 19.9079 27.035C20.2447 26.8731 20.6136 26.7891 20.9873 26.7891Z"
                fill="#E82F3E"
            />
            <path
                d="M21.5788 33.7503C21.4679 33.7504 21.3582 33.7287 21.2558 33.6863C21.1534 33.6438 21.0604 33.5816 20.9821 33.5032C20.6107 33.1325 20.3654 32.6543 20.2813 32.1363C20.1972 31.6183 20.2784 31.087 20.5135 30.6179L20.8237 29.9976C20.9248 29.7993 21.1003 29.6489 21.3118 29.5794C21.5232 29.5098 21.7537 29.5266 21.9528 29.6262C22.1519 29.7258 22.3036 29.9 22.3748 30.111C22.446 30.3219 22.431 30.5524 22.333 30.7523L22.0229 31.3726C21.9466 31.5249 21.9202 31.6975 21.9475 31.8657C21.9748 32.0339 22.0544 32.1892 22.175 32.3096C22.2931 32.4275 22.3735 32.5779 22.4062 32.7416C22.4388 32.9053 22.4222 33.075 22.3583 33.2292C22.2945 33.3834 22.1864 33.5152 22.0476 33.608C21.9088 33.7008 21.7457 33.7503 21.5788 33.7503Z"
                fill="#FF6268"
            />
            <path d="M2.53125 41.3438H51.4688V46.4062H2.53125V41.3438Z" fill="#EA9D2D" />
            <path
                d="M7.59375 44.7188H5.90625C5.68247 44.7188 5.46786 44.6299 5.30963 44.4716C5.1514 44.3134 5.0625 44.0988 5.0625 43.875C5.0625 43.6512 5.1514 43.4366 5.30963 43.2784C5.46786 43.1201 5.68247 43.0312 5.90625 43.0312H7.59375C7.81753 43.0312 8.03214 43.1201 8.19037 43.2784C8.3486 43.4366 8.4375 43.6512 8.4375 43.875C8.4375 44.0988 8.3486 44.3134 8.19037 44.4716C8.03214 44.6299 7.81753 44.7188 7.59375 44.7188Z"
                fill="#C38325"
            />
            <path
                d="M48.0938 44.7188H46.4062C46.1825 44.7188 45.9679 44.6299 45.8096 44.4716C45.6514 44.3134 45.5625 44.0988 45.5625 43.875C45.5625 43.6512 45.6514 43.4366 45.8096 43.2784C45.9679 43.1201 46.1825 43.0312 46.4062 43.0312H48.0938C48.3175 43.0312 48.5321 43.1201 48.6904 43.2784C48.8486 43.4366 48.9375 43.6512 48.9375 43.875C48.9375 44.0988 48.8486 44.3134 48.6904 44.4716C48.5321 44.6299 48.3175 44.7188 48.0938 44.7188Z"
                fill="#C38325"
            />
            <path d="M2.53125 46.4062H51.4688V51.4688H2.53125V46.4062Z" fill="#FBB540" />
            <path
                d="M7.59375 49.7812H5.90625C5.68247 49.7812 5.46786 49.6924 5.30963 49.5341C5.1514 49.3759 5.0625 49.1613 5.0625 48.9375C5.0625 48.7137 5.1514 48.4991 5.30963 48.3409C5.46786 48.1826 5.68247 48.0938 5.90625 48.0938H7.59375C7.81753 48.0938 8.03214 48.1826 8.19037 48.3409C8.3486 48.4991 8.4375 48.7137 8.4375 48.9375C8.4375 49.1613 8.3486 49.3759 8.19037 49.5341C8.03214 49.6924 7.81753 49.7812 7.59375 49.7812Z"
                fill="#C38325"
            />
            <path
                d="M27.8438 44.7188H26.1562C25.9325 44.7188 25.7179 44.6299 25.5596 44.4716C25.4014 44.3134 25.3125 44.0988 25.3125 43.875C25.3125 43.6512 25.4014 43.4366 25.5596 43.2784C25.7179 43.1201 25.9325 43.0312 26.1562 43.0312H27.8438C28.0675 43.0312 28.2821 43.1201 28.4404 43.2784C28.5986 43.4366 28.6875 43.6512 28.6875 43.875C28.6875 44.0988 28.5986 44.3134 28.4404 44.4716C28.2821 44.6299 28.0675 44.7188 27.8438 44.7188Z"
                fill="#C38325"
            />
            <path
                d="M27.8438 49.7812H26.1562C25.9325 49.7812 25.7179 49.6924 25.5596 49.5341C25.4014 49.3759 25.3125 49.1613 25.3125 48.9375C25.3125 48.7137 25.4014 48.4991 25.5596 48.3409C25.7179 48.1826 25.9325 48.0938 26.1562 48.0938H27.8438C28.0675 48.0938 28.2821 48.1826 28.4404 48.3409C28.5986 48.4991 28.6875 48.7137 28.6875 48.9375C28.6875 49.1613 28.5986 49.3759 28.4404 49.5341C28.2821 49.6924 28.0675 49.7812 27.8438 49.7812Z"
                fill="#C38325"
            />
            <path
                d="M48.0938 49.7812H46.4062C46.1825 49.7812 45.9679 49.6924 45.8096 49.5341C45.6514 49.3759 45.5625 49.1613 45.5625 48.9375C45.5625 48.7137 45.6514 48.4991 45.8096 48.3409C45.9679 48.1826 46.1825 48.0938 46.4062 48.0938H48.0938C48.3175 48.0938 48.5321 48.1826 48.6904 48.3409C48.8486 48.4991 48.9375 48.7137 48.9375 48.9375C48.9375 49.1613 48.8486 49.3759 48.6904 49.5341C48.5321 49.6924 48.3175 49.7812 48.0938 49.7812Z"
                fill="#C38325"
            />
            <path
                d="M37.125 31.2188L37.8663 31.9601C38.4338 32.5275 38.884 33.2012 39.1911 33.9426C39.4982 34.6841 39.6563 35.4787 39.6562 36.2812H42.1875V35.4375C42.1875 34.635 42.0294 33.8403 41.7223 33.0989C41.4152 32.3575 40.9651 31.6838 40.3976 31.1163L39.6562 30.375L37.125 31.2188Z"
                fill="#1B8E95"
            />
            <path
                d="M47.25 31.2188L46.5087 31.9601C45.9412 32.5275 45.491 33.2012 45.1839 33.9426C44.8768 34.6841 44.7187 35.4787 44.7188 36.2812H42.1875V35.4375C42.1875 34.635 42.3456 33.8403 42.6527 33.0989C42.9598 32.3575 43.4099 31.6838 43.9774 31.1163L44.7188 30.375L47.25 31.2188Z"
                fill="#1B8E95"
            />
            <path
                d="M51.469 24.6797C51.4694 24.2153 51.3673 23.7566 51.1702 23.3362C50.973 22.9158 50.6856 22.544 50.3283 22.2474C50.4348 21.8 50.4424 21.3347 50.3507 20.884C50.2589 20.4334 50.07 20.0081 49.797 19.638C49.5241 19.2678 49.1736 18.9616 48.7702 18.7408C48.3668 18.5199 47.92 18.3897 47.4611 18.3593C46.9065 17.3952 46.1076 16.5944 45.1448 16.0376C44.182 15.4807 43.0895 15.1875 41.9773 15.1875C40.8651 15.1875 39.7725 15.4807 38.8098 16.0376C37.847 16.5944 37.0481 17.3952 36.4934 18.3593C36.0345 18.3897 35.5877 18.5198 35.1842 18.7406C34.7807 18.9614 34.4302 19.2676 34.1572 19.6378C33.8842 20.0079 33.6952 20.4332 33.6034 20.8839C33.5116 21.3346 33.5192 21.7999 33.6257 22.2474C32.9838 22.7811 32.5788 23.5469 32.4993 24.378C32.4197 25.209 32.672 26.0378 33.201 26.6836C32.7417 27.5354 32.5891 28.5193 32.769 29.4702C32.9489 30.4212 33.4503 31.2813 34.189 31.9065C34.9278 32.5317 35.8591 32.8838 36.8267 32.9039C37.7942 32.924 38.7393 32.6108 39.5034 32.0168C40.4011 31.8996 41.2532 31.552 41.9768 31.0078C42.7003 31.552 43.5524 31.8997 44.4501 32.0171C45.2142 32.6111 46.1593 32.9244 47.127 32.9043C48.0946 32.8842 49.0259 32.532 49.7647 31.9068C50.5036 31.2816 51.0049 30.4213 51.1848 29.4704C51.3646 28.5194 51.212 27.5354 50.7525 26.6836C51.2166 26.119 51.4699 25.4106 51.469 24.6797Z"
                fill="#1B8E95"
            />
            <path
                d="M44.3637 21.0939C44.1892 21.0939 44.019 21.0398 43.8765 20.939C43.734 20.8382 43.6263 20.6957 43.5682 20.5311C43.4517 20.2021 43.2361 19.9173 42.9511 19.7158C42.666 19.5144 42.3256 19.4062 41.9766 19.4062C41.6275 19.4062 41.2871 19.5144 41.002 19.7158C40.717 19.9173 40.5014 20.2021 40.3849 20.5311C40.3104 20.7421 40.155 20.9148 39.9531 21.0113C39.7512 21.1078 39.5192 21.1201 39.3082 21.0455C39.0972 20.9709 38.9245 20.8156 38.828 20.6137C38.7316 20.4117 38.7193 20.1798 38.7938 19.9688C39.0267 19.3107 39.4577 18.741 40.0277 18.3381C40.5977 17.9351 41.2785 17.7188 41.9766 17.7188C42.6746 17.7188 43.3555 17.9351 43.9254 18.3381C44.4954 18.741 44.9265 19.3107 45.1593 19.9688C45.2043 20.096 45.2181 20.2323 45.1996 20.366C45.1811 20.4997 45.1307 20.627 45.0528 20.7373C44.9748 20.8475 44.8716 20.9374 44.7517 20.9995C44.6318 21.0615 44.4987 21.0939 44.3637 21.0939Z"
                fill="#64C7C7"
            />
            <path
                d="M37.7578 27C37.0865 27 36.4427 26.7333 35.9679 26.2586C35.4932 25.7839 35.2266 25.1401 35.2266 24.4688C35.2266 23.7974 35.4932 23.1536 35.9679 22.6789C36.4427 22.2042 37.0865 21.9375 37.7578 21.9375C37.9816 21.9375 38.1962 22.0264 38.3544 22.1846C38.5127 22.3429 38.6016 22.5575 38.6016 22.7812C38.6016 23.005 38.5127 23.2196 38.3544 23.3779C38.1962 23.5361 37.9816 23.625 37.7578 23.625C37.534 23.625 37.3194 23.7139 37.1612 23.8721C37.003 24.0304 36.9141 24.245 36.9141 24.4688C36.9141 24.6925 37.003 24.9071 37.1612 25.0654C37.3194 25.2236 37.534 25.3125 37.7578 25.3125C37.9816 25.3125 38.1962 25.4014 38.3544 25.5596C38.5127 25.7179 38.6016 25.9325 38.6016 26.1562C38.6016 26.38 38.5127 26.5946 38.3544 26.7529C38.1962 26.9111 37.9816 27 37.7578 27Z"
                fill="#64C7C7"
            />
            <path d="M2.53125 36.2812H51.4688V41.3438H2.53125V36.2812Z" fill="#FBB540" />
            <path
                d="M7.59375 39.6562H5.90625C5.68247 39.6562 5.46786 39.5674 5.30963 39.4091C5.1514 39.2509 5.0625 39.0363 5.0625 38.8125C5.0625 38.5887 5.1514 38.3741 5.30963 38.2159C5.46786 38.0576 5.68247 37.9688 5.90625 37.9688H7.59375C7.81753 37.9688 8.03214 38.0576 8.19037 38.2159C8.3486 38.3741 8.4375 38.5887 8.4375 38.8125C8.4375 39.0363 8.3486 39.2509 8.19037 39.4091C8.03214 39.5674 7.81753 39.6562 7.59375 39.6562Z"
                fill="#C38325"
            />
            <path
                d="M48.0938 39.6562H46.4062C46.1825 39.6562 45.9679 39.5674 45.8096 39.4091C45.6514 39.2509 45.5625 39.0363 45.5625 38.8125C45.5625 38.5887 45.6514 38.3741 45.8096 38.2159C45.9679 38.0576 46.1825 37.9688 46.4062 37.9688H48.0938C48.3175 37.9688 48.5321 38.0576 48.6904 38.2159C48.8486 38.3741 48.9375 38.5887 48.9375 38.8125C48.9375 39.0363 48.8486 39.2509 48.6904 39.4091C48.5321 39.5674 48.3175 39.6562 48.0938 39.6562Z"
                fill="#C38325"
            />
            <path
                d="M27.8438 39.6562H26.1562C25.9325 39.6562 25.7179 39.5674 25.5596 39.4091C25.4014 39.2509 25.3125 39.0363 25.3125 38.8125C25.3125 38.5887 25.4014 38.3741 25.5596 38.2159C25.7179 38.0576 25.9325 37.9688 26.1562 37.9688H27.8438C28.0675 37.9688 28.2821 38.0576 28.4404 38.2159C28.5986 38.3741 28.6875 38.5887 28.6875 38.8125C28.6875 39.0363 28.5986 39.2509 28.4404 39.4091C28.2821 39.5674 28.0675 39.6562 27.8438 39.6562Z"
                fill="#C38325"
            />
        </svg>
    );
}

export default SvgLegume;
