import { Box } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import useStyles from './styles';
import { CustomText } from 'src/components/Text/CustomText';
import { CustomCheckBox } from 'src/components/InputField/CustomCheckBox';
import { postWorkshopsView } from 'src/services/Posts';
import { toast } from 'react-toastify';
import ListItemLoading from 'src/components/CustomLoader/ListItemLoading';

interface ReportManagementFormCardProps {
    items?: Array<any>;
    labelKey?: string;
    searchData?: Function;
    onClick?: Function;
    secondLabelKey?: string;
    loading?: boolean;
    subMenuKey?: string;
    placeholder?: string;
    handleChange?: Function;
    selectedRows?: Set<number>;
    maxItems?: number;
    contentHeight?: number;
    handleSearch?: any;
    disableCheckBox?: boolean;
    isPatients?: boolean;
    isIntervenants?: boolean;
    noSearchHeader?: boolean;
    setSelectedIntervenants?: any;
    isForProgram?: boolean;
    handleFetchWorkshopPatient?: any;
}

export const ReportManagementFormCard: FC<ReportManagementFormCardProps> = (props) => {
    const {
        items,
        loading,
        labelKey,
        selectedRows,
        maxItems,
        contentHeight,
        disableCheckBox,
        handleFetchWorkshopPatient,
    } = props;
    const classes = useStyles();
    const [selected, setSelected] = useState<any>(selectedRows);
    const max = maxItems ?? 10;
    const [saving, setSaving] = useState(false);

    const handleSetSelected = () => {
        if (Array.from(selectedRows ?? [])?.length && Array.from(selected ?? [])?.length === 0) {
            setSelected(selectedRows);
        }
    };

    const performChangePosts = (i: any) => {
        const value: any | undefined = i;
        const validation = value?.validated;
        const valuePayload: any = {
            workshopId: value?.workshopId,
            programDetailId: value?.programDetailId,
            validated: validation.includes(1) ? 0 : 1,
        };

        postWorkshopsView(valuePayload)
            .then(() => successfull('Status ateliers modifié'))
            .catch((e) => failed('Erreur de modification du status ateliers'));
    };

    const successfull = (message: string) => {
        handleFetchWorkshopPatient();
        toast.success(message);
        setSaving(false);
    };

    const failed = (message: string) => {
        toast.error(message);
        setSaving(false);
    };

    useEffect(() => {
        handleSetSelected();
    }, [selectedRows]);

    return (
        <Box className={classes.container}>
            <Box>
                {loading ? (
                    <ListItemLoading />
                ) : items && items?.length > 0 ? (
                    <Box
                        sx={{
                            overflow: 'hidden',
                            overflowY: 'auto',
                            height: contentHeight ? contentHeight : 200,
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        {items?.slice(0, items?.length > max ? max : items?.length).map((item: any, i: number) => (
                            <Box key={i} style={{ alignSelf: 'flex-start' }}>
                                <CustomCheckBox
                                    disabled={disableCheckBox}
                                    label={labelKey ? `${items[i]?.name}` : ''}
                                    checked={
                                        items[i]?.validated ? (items[i]?.validated.includes(1) ? true : false) : false
                                    }
                                    onClick={() => performChangePosts(items[i])}
                                />
                            </Box>
                        ))}
                    </Box>
                ) : (
                    <CustomText style={{ textAlign: 'center' }}> Aucun contenu trouvé.</CustomText>
                )}
            </Box>
        </Box>
    );
};
