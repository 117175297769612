
export enum PermissionActionType{
    LIST_PERMISSIONS = 'LIST_PERMISSIONS',
}

export interface PermissionList{
    type: PermissionActionType.LIST_PERMISSIONS;
    payload: any;
}

export type PermissionAction = PermissionList;