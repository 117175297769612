import { Grid } from '@mui/material';
import { FC, useEffect, useState,useContext } from 'react';
import { NumberSelector } from 'src/components/InputField/NumberSelector';
import { CustomText } from 'src/components/Text/CustomText';
import { HdjContext } from 'src/providers/HdjProvider';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';

interface GeneralInfosProps {
    fetchedHdjData: any;
    setHdj: any;
    hdj:any;
}

const GeneralInfos: FC<GeneralInfosProps> = (props) => {
    const {hdj, setHdj, fetchedHdjData } = props;
    const {hourCount, setHourCount} = useContext(HdjContext);
    const [consultationCount, setConsultationCount] = useState(1);

    useEffect(()=>{
        setConsultationCount(hdj?.consultationIds?.length)
        setHdj((old: any) => ({ ...old, consultationCount: hdj?.consultationIds?.length }));
    },[hdj?.consultationIds])
    return (
        <Grid item style={{ width: '20%',marginRight:"20px" }}>
            <CustomText fontFamily={baseFontFamily.BreeSerif} fontSize={17} style={{textAlign:'center'}}>
                Informations générales
            </CustomText>

            <Grid item style={{ marginTop: 20 }}>
                <Grid container direction="column" alignItems={'center'} justifyContent={'space-between'}>
                    <Grid item>
                        <CustomText fontSize={12}  style={{fontWeight:'bold', textAlign:'center'}}>Nombre d'heures</CustomText>
                    </Grid>
                    <Grid item flexGrow={1} xs={4}>
                        <CustomText fontSize={17} color={baseColors.green.primary} style={{fontWeight:'bold', textAlign:'center'}}>
                            {hourCount} 
                        </CustomText>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item style={{ marginTop: 20 }}>
                <Grid container direction="column" alignItems={'center'} justifyContent={'space-between'}>
                    <Grid item>
                        <CustomText fontSize={12}  style={{fontWeight:'bold', textAlign:'center'}}>Nombre de consultations</CustomText>
                    </Grid>
                    <Grid item flexGrow={1} xs={4}>
                        <CustomText fontSize={17} color={baseColors.green.primary} style={{fontWeight:'bold', textAlign:'center'}}>
                            {consultationCount} 
                        </CustomText>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default GeneralInfos;
