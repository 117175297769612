import { Consultation } from "src/interfaces/interfaces";
import { HdjPrivateRoutes } from "src/routes/PrivateHdjRoute";
import { isNotBlank } from "src/utils/helpers/InputValidator";

const reorderedData = (data: any, hdjData: any) => {
    const existIds = hdjData?.length > 0 ? Array.from(hdjData, (item: { id: number}) => item.id ) : [0];
    const arrayWithoutCurrentHdj = data?.length > 0 && data.filter((item: any) => !existIds.includes(item.id));
    const newArray = arrayWithoutCurrentHdj?.length > 0 && hdjData?.length > 0 ? [...hdjData, ...arrayWithoutCurrentHdj] : hdjData;
   
    return newArray
}

const gotoHDJForm = (history: any) =>
    history.push({
        pathname: HdjPrivateRoutes.hdjForm,
        state: { newForm: true },
    });

const checkFormValidity = (payload: Consultation) => 
 !isNotBlank(payload.name)
 || payload.duration === "" 
 || payload.duration === "00:00" 
 || payload.specialityTypeId === 0  
 || payload?.attendees?.length === 0  
 || payload.consultationTypeId === 0
 || (payload.consultationTypeId === 0  && payload.roomId === 0)
 || (payload.consultationTypeId === 1 && payload.roomId === 0)

 

    

export { reorderedData, gotoHDJForm, checkFormValidity }