import { Grid } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import CustomDatePicker from 'src/components/CustomDatePicker';
import { transformeTimeFormat } from 'src/components/CustomPlanningAgendaTimeLine/PlanningContentLogic';
import CustomTimePicker from 'src/components/CustomTimePicker';
import { setDefaultTime } from 'src/components/PatientOfDayCard/EventLogic';
import { Spacer } from 'src/components/Spacer/Spacer';
import { CustomText } from 'src/components/Text/CustomText';
import { EventHoursProps } from 'src/interfaces/interfaces';
import { AgendaContext } from 'src/providers/AgendaProvider';
import { eventDateTimeFormat, stringDateToZeroMinute, timeWithoutTimeZone } from 'src/utils/utils';
import {
    addFifteenMinutes,
    isTwoTimeEqual,
    manageEventEndingTime,
    monthlyDateValue,
    setTimeMinutes,
} from '../EventModalLogic';
import { manageEventEndingTimeParams } from 'src/interfaces/types';
import { baseColors } from 'src/utils/constants/baseStyles';
import { CustomSelect } from 'src/components/InputField/CustomSelect';
import { isDivisibleByDuration } from 'src/utils/constants/constants';
import EventService from 'src/services/Calendar/events.service';
import CustomCircleLoader from 'src/components/CustomLoader/CustomCircleLoader';

const EventHours: FC<EventHoursProps> = (props) => {
    const {
        eventPayload,
        setEventPayload,
        eventData,
        editEventData,
        createEventFromBtnAction,
        viewType,
        dataForWeeklyEvent,
        editFromDnd,
        newEventDate,
        message,
        eventDate,
        setEventDate,
        valideStartTime,
        valideEndTime,
        droppedEventId,
        droppedEventDateTime,
        selectedWorkshopData,
        newEventEndDate,
        isSlotDE,
        setCanDivisibleByDuration,
        canDivisibleByDuration
    } = props;
    const { currentDate } = useContext(AgendaContext);
    // const { selectedSWorkshopSession } = useContext(EventContext);
    const [counter, setCounter] = useState(0);

    const monthlyDate: any = monthlyDateValue({
        editEventData,
        newEventDate,
        currentDate,
        createEventFromBtnAction,
    });

    const weeklyDate: any = new Date(
        createEventFromBtnAction ? currentDate : dataForWeeklyEvent?.weeklyEventData?.dayDate,
    );

    const dateToUse =
        droppedEventId && droppedEventId > 0
            ? droppedEventDateTime?.start
            : viewType === 'monthly'
            ? monthlyDate
            : viewType === 'weekly' && dataForWeeklyEvent
            ? weeklyDate
            : editEventData?.startDate
            ? new Date(editEventData?.startDate)
            : newEventDate || currentDate;

    let defaultStart: Date = new Date(dateToUse);
    let defaultEnd: Date = new Date(dateToUse);

    if (editEventData && !editFromDnd) {
        if (editEventData.startDate) {
            setDefaultTime(transformeTimeFormat(timeWithoutTimeZone(editEventData.startDate)), defaultStart);
        }
        if (editEventData?.endDate) {
            setDefaultTime(transformeTimeFormat(timeWithoutTimeZone(editEventData?.endDate)), defaultEnd);
        }
    }

    if (eventData && !editFromDnd) {
        if (eventData?.timeStart) {
            setDefaultTime(eventData?.timeStart, defaultStart);
        }
        if (eventData?.timeEnd) {
            setDefaultTime(eventData?.timeEnd, defaultEnd);
        }
    }

    if (createEventFromBtnAction) {
        const today = new Date();
        const dateToUseForNewEvent = new Date(dateToUse);
        const currentHours = today.getHours();
        const currentMinutes = today.getMinutes();
        dateToUseForNewEvent.setHours(currentHours);
        dateToUseForNewEvent.setMinutes(currentMinutes);
        defaultStart = dateToUseForNewEvent;
        defaultEnd = dateToUseForNewEvent;
    }

    let timeToUse = {
        timeStart: createEventFromBtnAction ? addFifteenMinutes(defaultStart) : defaultStart,
        timeEnd: createEventFromBtnAction ? setTimeMinutes(defaultStart, defaultEnd) : defaultEnd,
    };

    if (isTwoTimeEqual(timeToUse?.timeStart, String(timeToUse?.timeEnd))) {
        timeToUse.timeEnd = setTimeMinutes(defaultStart, defaultEnd);
    }
    
    const [startAt, setStartAt] = useState<any>(timeToUse?.timeStart);
    const [endAt, setEndAt] = useState<any>(timeToUse?.timeEnd);
    
    const workShopDuration = Number(selectedWorkshopData?.duration?.split(':')?.join('.'));

    const [proposalEndDate, setProposalEndDate] = useState<any>([]);
    const [isLoadingFreeSlotDuration, setIsLoadingFreeSlotDuration] = useState<boolean>(false);
    const [freeSlotDuration, setfreeSlotDuration] = useState<any>([]);

    const getFreeSlotDuration = () => {
        setIsLoadingFreeSlotDuration(true);
        EventService.getFreeSlotDuration().then(res => {
            if(res?.data?.data){
                const parsedDuration = parseFreeslotDuration(res?.data?.data)
                setfreeSlotDuration(parsedDuration);
            }           
            setIsLoadingFreeSlotDuration(false);
        }).catch(err => {
            setIsLoadingFreeSlotDuration(false)
        })
    }

    const parseFreeslotDuration = (data: [{id: Number, name : string , code: string}]) => {   
        return data.map(item => ({
            id: item.code,
            name: item.name
        }));
    }

    useEffect(()=>{
        if(isSlotDE){
            getFreeSlotDuration();
        }
    },[isSlotDE])

    useEffect(() => {
        let timer = () =>
            setTimeout(() => {
                setEventDate(dateToUse);
            }, 700);

        if (dateToUse && counter === 0) {
            timer();
            setCounter((prev) => prev + 1);
        }

        return () => {
            clearTimeout(timer());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateToUse, counter]);

    useEffect(() => {
        setEventPayload((prev: any) => ({
            ...prev,
            startDate: eventDateTimeFormat(eventDate, startAt, true),
            endDate: eventDateTimeFormat(eventDate, new Date(endAt), true),
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventData, endAt, startAt]);

    useEffect(() => {
        // if (createEventFromBtnAction || eventData) {
        if (eventData) {
            setEndAt(
                manageEventEndingTime({
                    dateTime: startAt,
                    eventTypeId: eventPayload?.typeId ?? 0,
                }),
            );
        }
    }, [eventPayload?.typeId, createEventFromBtnAction, eventData, startAt]);

    useEffect(() => {
        if (!editEventData) {
            const manageEventEndingTimeParams: manageEventEndingTimeParams = {
                dateTime: startAt,
                eventTypeId: eventPayload?.typeId ?? 0,
                duration: 0,
            };

            setEndAt(!createEventFromBtnAction ? newEventEndDate : manageEventEndingTime(manageEventEndingTimeParams));
        }
    }, [eventPayload?.typeId, eventData, newEventEndDate, createEventFromBtnAction]);

    useEffect(()=>{
        if(editEventData){
            setEndAt(timeToUse?.timeEnd)
        }
    },[editEventData])

    useEffect(() => {
        if(workShopDuration && eventPayload?.typeId === 1){
            const manageEventEndingTimeParams: manageEventEndingTimeParams = {
                dateTime: startAt,
                eventTypeId: eventPayload?.typeId ?? 0,
                duration: workShopDuration,
            };
            if (eventPayload?.typeId === 1) manageEventEndingTimeParams.duration = workShopDuration;
            setEndAt(manageEventEndingTime(manageEventEndingTimeParams));
        }
    },[eventPayload?.workshopId, eventPayload?.typeId])

    const handleChange = (type: 'start' | 'end' | 'date', date: Date | null) => {
        let value = date;

        const formatedDate = value && eventDateTimeFormat(eventDate, value, true);
        const autoAddOneHourToEndHour = manageEventEndingTime({
            dateTime: formatedDate,
            eventTypeId: eventPayload?.typeId ?? 0,
        });

        if (type === 'start') {
            setEventPayload((prev: any) => ({
                ...prev,
                startDate: formatedDate,
                endDate: autoAddOneHourToEndHour,
                checkStartDate: value,
            }));
            // setEndAt(autoAddOneHourToEndHour);
        }

        if (type === 'end') {
            setEventPayload((prev: any) => ({ ...prev, endDate: formatedDate }));
        }
    };

    const handleChangeEventDate = (date: Date) => setEventDate(date);

    useEffect(() => {}, [eventDate, currentDate]);

    useEffect(() => {
        if (droppedEventId && droppedEventId > 0) {
            const start = droppedEventDateTime?.start;
            const end = droppedEventDateTime?.end;
            setStartAt(start);
            setEndAt(end);
            setEventPayload((prev: any) => ({
                ...prev,
                startDate: start,
                endDate: end,
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [droppedEventId]);


    useEffect(() => {
        if(isSlotDE && freeSlotDuration.length > 0){
            const defaultDuration = eventPayload?.duration ? eventPayload?.duration : freeSlotDuration[freeSlotDuration?.length - 1]?.id;
            if(!editEventData && !eventPayload?.duration) {
                setEventPayload((prev :any) => ({...prev, duration: defaultDuration}));
            } 

            const {isDivisible, proposition} = isDivisibleByDuration(eventPayload?.startDate, eventPayload?.endDate, defaultDuration);
            setCanDivisibleByDuration?.(isDivisible);
            setProposalEndDate(proposition.filter((item,index) => index <= 3));
        }
    },[eventPayload?.duration, eventPayload?.endDate ,eventPayload?.startDate,eventPayload?.typeId, freeSlotDuration])

    return (
        <>
            <Grid container direction="row" alignItems={'center'}>
                <Grid item>
                    <CustomText>Date de l'événement :</CustomText>
                </Grid>
                <Grid item width={150} marginLeft={12} height={50}>
                    <div style={{ marginLeft: -11 }}>
                        <CustomDatePicker
                            disablePast
                            likeSelect
                            width="100%"
                            validator={true}
                            value={eventDate}
                            setValue={(date: Date) => handleChangeEventDate(date)}
                            idpicker="date-event"
                        />
                    </div>
                </Grid>
            </Grid>

            <Spacer />

            <Grid container direction="row" alignItems={'center'}>
                <Grid item>
                    <CustomText>Heure du début :</CustomText>
                    {message}
                </Grid>
                <Grid item width={300} marginLeft={16} height={50}>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center' }}>
                        <div style={{ marginLeft: -11, width: '53%' }}>
                            <CustomTimePicker
                                type="start"
                                value={startAt}
                                setValue={setStartAt}
                                validator={valideStartTime}
                                handleChange={handleChange}
                                idtime="heure-debut-event"
                            />
                        </div>
                    </div>
                </Grid>
            </Grid>

            <Spacer />

            <Grid container direction="row" alignItems={'center'}>
                <Grid item>
                    <CustomText>Heure de fin :</CustomText>
                </Grid>
                <Grid item width={300} marginLeft={19} height={50}>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center' }}>
                        <div style={{ marginLeft: -11, width: '53%' }}>
                            <CustomTimePicker
                                type="end"
                                value={endAt}
                                setValue={setEndAt}
                                validator={valideEndTime}
                                handleChange={handleChange}
                                idtime="heure-fin-event"
                            />
                        </div>
                        {isSlotDE && !canDivisibleByDuration && !isLoadingFreeSlotDuration && (
                            <div>
                                <CustomText color={baseColors.orange.primary} fontSize={13}>
                                    {"L'heure de fin ne correspond pas à la durée du créneau."}
                                    {/*"Proposition: " + proposalEndDate.join(', ')*/}

                                </CustomText>
                            </div>
                        )}
                    </div>
                </Grid>
            </Grid>
            <Spacer />
            { isSlotDE && 
            <>
                <Grid container direction="row" alignItems={'center'} justifyContent={'space-between'}>
                    <Grid item>
                        <CustomText>Durée du créneau :</CustomText>
                    </Grid>
                    <Grid item style={{ width: '60%' }}>
                    {isLoadingFreeSlotDuration ? 
                            <CustomCircleLoader height={25} width={25} />
                        :
                            <CustomSelect
                                onItemClick={() => {}}
                                onChange={(value: string) => setEventPayload((prev:any) => ({...prev, duration: value}))}
                                label=""
                                labelKey="name"
                                items={freeSlotDuration}
                                defaultValue={eventPayload?.duration}
                                idSelect="duration-name"
                                width="100%"
                            />
                    }
                    </Grid>
                </Grid>
                <Spacer />
            </>
            }

            
        </>
    );
};

export default EventHours;
