import { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { retrieveDayHospitals, retrieveDayHospitalsWithFilters } from 'src/actions/dayHospitals/dayHospitals';
import { HdjFilters } from 'src/interfaces/interfaces';
import { HdjContext } from 'src/providers/HdjProvider';

const useFetchHdj = (setUpstairs?: Function) => {
    const dispatch = useDispatch();
    const { setLoadingHdjList, loadingHdjList, setHdjList } = useContext(HdjContext);
    const [nameFilter, setNameFilter] = useState<string>('');
    const [specialityId, setSpecialityId] = useState<number>(0);
    const dayHospitals = useSelector((state: any) => state?.dayHospitals?.data);

    const handleFetchHdj = (query?: string) => {
        const filters: HdjFilters = {
            setLoadingHdjList,
            setHdjList,
            searchQuery: query ?? '',
            nameFilter,
            specialityTypeId: specialityId ?? 0,
        };

        if (query?.trim() !== '' || nameFilter !== '' || specialityId) {
            dispatch(retrieveDayHospitalsWithFilters(filters));
        } else {
            dispatch(retrieveDayHospitals(setLoadingHdjList, setHdjList));
        }
    };

    return {
        loadingHdjList,
        handleFetchHdj,
        dayHospitals,
        setNameFilter,
        setSpecialityId,
        nameFilter,
        specialityId,
    };
};

export default useFetchHdj;
