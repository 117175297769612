import { makeStyles } from '@mui/styles';
import { baseColors, baseFontFamily, baseFontWeight } from '../../../../utils/constants/baseStyles';

const useStyles = makeStyles({
    container: {
        '& .react-calendar': {
            // width: '350px',
            // maxWidth: '100%',
            backgroundColor: 'white',
            border: 0,
        },
        '& .react-calendar__navigation__label__labelText': {
            color: baseColors.black,
            fontSize: 18,
            fontFamily: baseFontFamily.BreeSerif,
            fontWeight: baseFontWeight.medium,
        },
        '& .react-calendar__tile': {
            color: baseColors.black,
            fontSize: 14,
            fontFamily: baseFontFamily.BreeSerif,
            fontWeight: baseFontWeight.normal,
        },
        '& .react-calendar__tile--now': {
            background: '#b7ebe3',
            color: baseColors.black,
            borderRadius: 10,
        },
        '& .react-calendar__tile--now:enabled:hover': {
            background: baseColors.green.primary,
            bordeRadius: 6,
            color: baseColors.white,
        },
        '& .react-calendar__tile--active': {
            background: baseColors.green.dark,
            color: baseColors.white,
            borderRadius: 10,
        },
        '& .react-calendar__tile--active:enabled:hover': {
            background: baseColors.green.dark,
        },
        '& .react-calendar__tile--active:enabled:focus': {
            background: baseColors.green.dark,
        },
        '& .react-calendar__month-view__weekdays': {
            color: baseColors.orange.primary,
            textAlign: 'center',
            textTransform: 'uppercase',
            fontWeight: 'bold',
            fontSize: '0.75em',
        },
        '& .react-calendar__navigation__arrow': {
            color: baseColors.green.primary,
            background: 'transparent',
            backgroundColor: 'transparent',
            fontSize: 30,
            borderRadius: 50,
        },
    },
    calendar: {
        padding: '1rem',
        marginTop: 20,
        width: 'auto',
    },
});

export default useStyles;
