import { ROUTES } from 'src/routes/routes';
import IconSettingsUsers from '../CustomIcon/IconSettingsUsers';
import Agenda from '../CustomIcon/Agenda';
import { FC } from 'react';
import MenuItemContainer from './MenuItemContainer';

const DoctorSubMenus: FC = () => {
    const settingsSubMenuData = [
        {
            id: 1,
            icon: <Agenda height={20} width={20} />,
            iconActive: <IconSettingsUsers height={20} width={20} />,
            label: 'Agenda',
            path: ROUTES.agendaSettings,
            featureCode: 'front_display_parameter_angenda',
        },
    ];

    return <MenuItemContainer items={settingsSubMenuData} />;
};

export default DoctorSubMenus;
