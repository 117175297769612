import { baseColors } from 'src/utils/constants/baseStyles';

function IconHDJ(props: any) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={27} height={27} fill="none" viewBox="0 0 27 27" {...props}>
            <path
                fill={baseColors.green.primary}
                d="M13.5 27C20.956 27 27 20.956 27 13.5S20.956 0 13.5 0 0 6.044 0 13.5 6.044 27 13.5 27z"
            />
            <path
                fill="#F2F2F2"
                d="M13.5 23.625c-5.569 0-10.125-4.556-10.125-10.125S7.931 3.375 13.5 3.375 23.625 7.931 23.625 13.5 19.069 23.625 13.5 23.625z"
            />
            <path
                fill={baseColors.orange.primary}
                d="M13.5 20.25c-3.713 0-6.75-3.038-6.75-6.75 0-3.713 3.037-6.75 6.75-6.75 3.712 0 6.75 3.037 6.75 6.75 0 3.712-3.038 6.75-6.75 6.75z"
            />
            <path
                fill="#CDD6E0"
                d="M13.5 16.875a3.385 3.385 0 01-3.375-3.375 3.385 3.385 0 013.375-3.375 3.385 3.385 0 013.375 3.375 3.385 3.385 0 01-3.375 3.375z"
            />
            <path
                fill="#FFD15C"
                d="M13.5 16.875a3.385 3.385 0 01-3.375-3.375 3.385 3.385 0 013.375-3.375 3.385 3.385 0 013.375 3.375 3.385 3.385 0 01-3.375 3.375z"
            />
            <path
                fill="#334A5E"
                d="M26.888 15.187a13.7 13.7 0 01-1.52 4.725l-.73-.28-.957-2.026-10.125-3.656a.874.874 0 01-.506-1.125.874.874 0 011.125-.506L24.3 15.975l2.025-.956.563.168z"
                opacity={0.2}
            />
            <path
                fill="#40596B"
                d="M22.724 5.456l-8.268 8.269a.883.883 0 01-1.238 0 .883.883 0 010-1.238l8.325-8.212a.883.883 0 011.238 0 .87.87 0 01-.057 1.181z"
            />
            <path fill={baseColors.orange.primary} d="M23.513 3.487l-2.644 2.644V2.644L23.513 0v3.487z" />
            <path fill={baseColors.orange.primary} d="M24.357 6.131L27 3.488h-3.487L20.869 6.13h3.488z" />
        </svg>
    );
}

export default IconHDJ;
