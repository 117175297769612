import { Grid } from '@mui/material';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import CustomModal from 'src/components/CustomModal';
import { Pathology } from 'src/interfaces/interfaces';
import ProgramTypesService from 'src/services/Pathology/pathology.service';
import CustomCircleLoader from '../../../components/CustomLoader/CustomCircleLoader';
import { toast } from 'react-toastify';
import { baseScreenHeight } from 'src/utils/constants/baseStyles';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import { CustomText } from 'src/components/Text/CustomText';
import { Spacer } from 'src/components/Spacer/Spacer';
import { InputField } from 'src/components/InputField/InputField';
import { isNotBlank } from 'src/utils/helpers/InputValidator';

interface PathologyFormProps {
    forAdmin?: boolean;
    modalOpen: boolean;
    modalToggle: Function;
    setUpdateList?: any;
    pathologyData?: Pathology;
    setJustAddedPathology?: Dispatch<SetStateAction<Pathology[]>>;
}

export const defaultPathologieValue: Pathology = {
    name: ''
};

export const PathologyForm: FC<PathologyFormProps> = (props) => {
    const {
        modalOpen,
        modalToggle,
        setUpdateList,
        pathologyData,
        forAdmin,
        setJustAddedPathology
    } = props;
    const [savingData, setSavingData] = useState<boolean>(false);
    const [lockPathologyInput, setLockPathologyInput] = useState<boolean>(false);
    const [payload, setPayload] = useState<Pathology>({
        ...defaultPathologieValue
    });
    const loadingData = <CustomCircleLoader height={25} width={25} />;

    const successfull = (message: string) => {
        setSavingData(false);
        setLockPathologyInput(false);
        if(setUpdateList) setUpdateList((prev: any) => !prev);
        setPayload(defaultPathologieValue);
        modalToggle();
        toast.success(message);
    };

    const failed = (message: string) => {
        setSavingData(false);
        setLockPathologyInput(false);
        toast.error(message);
    };

    const performPathology = () => {
        setSavingData(true);
        setLockPathologyInput(true);
        if (pathologyData && pathologyData.id && pathologyData?.id > 0) {
            ProgramTypesService.updateProgramType(payload).then((res) => {
                if (res.data.code === 200) {
                    successfull('Pathologie modifiée avec succès');
                } else {
                    if (res.data.code === 400) {
                        failed('Le nom  existe déjà ');
                    }
                }
            });
        } else {
            ProgramTypesService.postProgramType(payload).then((res) => {
                if (res.data.code === 200) {
                    setJustAddedPathology?.((prev)=> [...prev,res?.data?.data]);
                    successfull('Pathologie ajoutée avec succès');
                } else {
                    if (res.data.code === 400) {
                        failed("Le nom de la pathologie existe déjà");
                    }
                }
            });
        }
    };

    useEffect(() => {
        pathologyData && forAdmin && setPayload(pathologyData);
    }, [pathologyData, forAdmin]);

    const toggleMainModal = () => {
        modalToggle(false);
        setPayload(defaultPathologieValue);
    };

    const saving = savingData;
    const isNotValidated = !pathologyData?.id
    return (
        <CustomModal
            withMinHeight
            width={550}
            title={pathologyData?.id! > 0 ? "Modifier la pathologie" : 'Nouvelle pathologie'}
            toggleModal={toggleMainModal}
            open={modalOpen}
            footer={
                <Grid container flexDirection="row" justifyContent="center" alignItems="center">
                    <CustomLoadingButton
                        loading={savingData}
                        disabled={payload.name.trim()==="" || saving}
                        handleClick={() => performPathology()}
                        width={"49%"}
                        label="Valider la pathologie"
                    />
                </Grid>
            }
        >
            <Grid
                style={{ marginTop: 20 }}
                lg={12}
                container
                direction="row"
                alignItems={'center'}
                justifyContent="space-between"
            >
                <Grid item style={{marginBottom:"16px"}}>
                    <CustomText>Nom de la pathologie :</CustomText>
                </Grid>
                <InputField
                    label=""
                    disabled={lockPathologyInput}
                    onChange={(value: any) => setPayload((old: any) => ({ ...old, name: value }))}
                    validator={isNotBlank}
                    errorMessage="Nom de la pthologie obligatoire"
                    placeholder="Entrer un nom de la pathologie ..."
                    width={360}
                    value={payload.name}
                />
            </Grid>

            <Spacer height={10} /> 
        </CustomModal>
    );
};
