import { RETRIEVE_CONNECTED_USER_INFOS } from "src/actions/user/types";

const initialState: any = [];

const userReducer = (events = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case RETRIEVE_CONNECTED_USER_INFOS:
      return payload;

    default:
      return events;
  }
};

export { userReducer };