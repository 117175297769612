import {
  RETRIEVE_CONSULTATIONS,
  RETRIEVE_PATIENTS,
  RETRIEVE_WORKSHOPS,
} from "src/actions/dayHospitals/types";

const initialState: any = [];

const managementConsultationReducer = (managementConsultations = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {

    case RETRIEVE_CONSULTATIONS : 
      return payload;

    default:
      return managementConsultations;
      
  }
};

const managementWorkshopReducer = (managementWorkshop = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {

    case RETRIEVE_WORKSHOPS : 
      return payload;

    default:
      return managementWorkshop;
      
  }
};

const managementPatientsReducer = (managementPatient = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {

    case RETRIEVE_PATIENTS : 
      return payload;

    default:
      return managementPatient;
      
  }
};

export { 
  managementConsultationReducer, 
  managementWorkshopReducer, 
  managementPatientsReducer 
};