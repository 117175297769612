import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

export function useRequestProcessor() {
    const queryClient = useQueryClient();

    function query({ queryKey, queryFn, options = {} }) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return useQuery({
            queryKey,
            queryFn,
            enabled: true,
            ...options,
        });
    }

    function mutate({ key, mutationFunction, options = {} }) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return useMutation({
            mutationKey: key,
            mutationFn: mutationFunction,
            onSettled: () => queryClient.invalidateQueries(key),
            ...options,
        });
    }

    return { query, mutate };
}
