import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Grid, IconButton } from '@mui/material';
import React, { FC, useState, useEffect } from 'react';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import { CustomText } from '../Text/CustomText';

interface NumberSelectorProps {
    value?: number;
    setValue?: Function;
    handleChange?: Function;
    type?: 'hour' | 'consultation';
    disabled?: boolean
}
export const NumberSelector: FC<NumberSelectorProps> = (props) => {
    const { value, setValue, handleChange, type, disabled } = props;
    const [currentValue, setCurrentValue] = useState(value ?? 0);
    const handlePlus = () =>
        setCurrentValue((prev: number) => {
            const val = prev + 1;
            handleChange?.(val, setValue, type);
            return val;
        });

    const handleMinus = () =>
        setCurrentValue((prev: number) => {
            const val = prev > 0 ? prev - 1 : 0;
            handleChange?.(val, setValue, type);
            return val;
        });

    useEffect(() => {
        if (value) setCurrentValue(value);
    }, [value]);

    // if (currentValue > 0) {
    //     setCurrentValue(currentValue - 1);
    //     handleChange?.(currentValue);
    // }

    return (
        <Grid
            xs={12}
            style={{
                marginLeft: 8,
                paddingLeft: 10,
                paddingRight: 5,
                borderRadius: 10,
                background: baseColors.green.light,
            }}
            container
            direction={'row'}
            justifyContent={'space-around'}
            alignItems={'center'}
        >
            <Grid item>
                <CustomText fontFamily={baseFontFamily.OpenSansBold} color={baseColors.green.primary}>
                    {currentValue.toString()}
                </CustomText>
            </Grid>
            <Grid item>
                <Grid container direction="column">
                    <Grid item>
                        <IconButton
                            disabled={disabled}
                            onClick={handlePlus}
                            size={'small'}
                        // style={{ marginTop: '-0%' }}
                        >
                            <KeyboardArrowUp htmlColor={baseColors.green.primary}></KeyboardArrowUp>
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <IconButton disabled={disabled} onClick={handleMinus} size={'small'} style={{ marginTop: '-65%' }}>
                            <KeyboardArrowDown htmlColor={baseColors.green.primary}></KeyboardArrowDown>
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
