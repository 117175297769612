import axios from './AxiosInstance';

const getRooms = () => axios.get('api/rooms');

const getUpstairs = () => axios.get('api/upstairs?paginated=false');

const getCustomLocation = () => axios.get('api/events/planning?paginated=false');

const getCustomLocationItems = () => axios.get('api/customLocations');

export { getUpstairs, getRooms, getCustomLocation, getCustomLocationItems };
