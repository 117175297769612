import { FC, useContext, useEffect, useState } from 'react';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import { InputField } from 'src/components/InputField/InputField';
import { NumberSelector } from 'src/components/InputField/NumberSelector';
import { Spacer } from 'src/components/Spacer/Spacer';
import { ProgramContext } from 'src/providers/ProgramProvider';
import { renderContent, renderTitle } from '../utils';
import _ from 'lodash';
import useStyles from './styles';
import ProgramService from 'src/services/Program/program.service';
import { exportSynthese } from 'src/services/Syntheses';
import { toast } from 'react-toastify';
import { ServerError } from 'src/utils/constants/constants';
import CustomCircleLoader from 'src/components/CustomLoader/CustomCircleLoader';
import { UserContext } from 'src/providers/UserProvider';

interface EvaluationProps {
    programId: number;
}

export const Evaluation: FC<EvaluationProps> = (props) => {
    const { programId } = props;
    const classes = useStyles();
    const defaultEvaluation = {
        id: 0,
        programId: programId,
        score: 1,
        strength: '',
        blockages: '',
        toolProgress: '',
        annualAction: '',
    };
    const { setSyntheseHasChange, setsyntheseChangeSaveCallback } = useContext(ProgramContext);
    const [evaluation, setEvaluation] = useState<any>(defaultEvaluation);
    const [oldevaluation, setOldEvaluation] = useState<any>(defaultEvaluation);
    const [loading, setLoading] = useState(false);
    const [exporting, setExporting] = useState(false);
    const { isCoordinator } = useContext(UserContext);

    useEffect(() => {
        const hasChangeMade = !_.isEqual(evaluation, oldevaluation);
        setSyntheseHasChange(hasChangeMade);
        if (hasChangeMade) {
            setsyntheseChangeSaveCallback(() => performSaveProgramsEvaluation);
        }
    }, [evaluation, oldevaluation]);

    const resetField = () => {
        setEvaluation(defaultEvaluation);
    };

    const downloadSyntheses = async () => {
        setExporting(true);
        await exportSynthese(programId)
            .then((response) => {
                if (response?.data?.data) {
                    const linkSource = `data:application/pdf;base64,${response.data.data}`;
                    const downloadLink = document.createElement('a');
                    const fileName = 'Ivehte.pdf';
                    downloadLink.href = linkSource;
                    downloadLink.download = fileName;
                    downloadLink.click();
                    resetField();
                    toast.success('Synthèse exportée avec succès.');
                } else {
                    toast.error('Pas de données à exporter.');
                }
                setExporting(false);
            })
            .catch((e) => {
                console.log(e);
                setExporting(false);
                toast.error("Une erreur est survenue lors de l'exportation");
            });
    };

    const performSaveProgramsEvaluation = () => {
        return new Promise((resolve, reject) => {
            if (evaluation.id !== 0) {
                //update
                ProgramService.putEvaluation(evaluation.id, evaluation)
                    .then((response) => {
                        const data = response.data.data;
                        if (data) {
                            setEvaluation(data);
                            setOldEvaluation(data);
                        }
                        resolve(response);
                    })
                    .catch((e) => {
                        toast.error(ServerError.unknown);
                        reject(e);
                    });
            } else {
                ProgramService.postEvaluation(evaluation)
                    .then((response) => {
                        const data = response.data.data;
                        if (data) {
                            setEvaluation(data);
                            setOldEvaluation(data);
                        }
                        resolve(response);
                    })
                    .catch((e) => {
                        toast.error(ServerError.unknown);
                        reject(e);
                    });
            }
        });
    };

    const getProgramEvaluation = () => {
        setLoading(true);
        ProgramService.getEvaluation(programId)
            .then((response) => {
                const data = response.data?.data;
                if (data) {
                    setEvaluation(data);
                    setOldEvaluation(data);
                }
                setLoading(false);
            })
            .catch(() => {
                toast.error(ServerError.unknown);
                setLoading(false);
            });
    };

    useEffect(() => {
        getProgramEvaluation();
    }, []);

    return (
        <div className={classes.container}>
            {loading ? (
                <div className={classes.row} style={{ justifyContent: 'center', marginTop: 40 }}>
                    <CustomCircleLoader />
                </div>
            ) : (
                <div className={classes.wrapper}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>{renderTitle('Évaluation')}</div>
                    <Spacer height={20} />
                    {renderContent(
                        'Pour permettre de formaliser le niveau de progression, il vous est proposé à la fin de cette évaluation de scorer votre niveau et de vous situer sur une échelle de 0 à 10 :',
                    )}
                    <div className={classes.processLine}>
                        <div className={classes.rowSimple}>
                            <div>{renderContent('Votre score :')}</div>
                            <Spacer height={20} />
                            <div style={{ maxWidth: 80 }}>
                                <NumberSelector
                                    disabled={exporting}
                                    value={evaluation?.score}
                                    handleChange={(value: any) =>
                                        setEvaluation((old: any) => ({ ...old, score: value }))
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <Spacer height={20} />
                    {renderContent(
                        'À la suite de ce score, exprimez ce qu’il vous faudrait faire pour passer au niveau supérieur :',
                    )}
                    <div className={classes.processLine}>
                        <InputField
                            width={'45%'}
                            label="Quels sont vos points forts ? :"
                            placeholder={'Taper votre texte'}
                            value={evaluation?.strength}
                            disabled={exporting}
                            onChange={(value: any) => {
                                setEvaluation((old: any) => ({ ...old, strength: value }));
                            }}
                        />
                        <InputField
                            disabled={exporting}
                            width={'45%'}
                            label="Quels sont les freins et les verrous à surmonter ? :"
                            placeholder={'Taper votre texte'}
                            value={evaluation?.blockages}
                            onChange={(value: any) => {
                                setEvaluation((old: any) => ({ ...old, blockages: value }));
                            }}
                        />
                    </div>
                    <div className={classes.processLine}>
                        <InputField
                            disabled={exporting}
                            width={'45%'}
                            label="Quels sont les leviers que vous pourriez utiliser pour progresser ? :"
                            placeholder={'Taper votre texte'}
                            value={evaluation?.toolProgress}
                            onChange={(value: any) => {
                                setEvaluation((old: any) => ({ ...old, toolProgress: value }));
                            }}
                        />
                        <InputField
                            width={'45%'}
                            disabled={exporting}
                            label="Quelles sont les actions à mettre en oeuvre durant l’année ? :"
                            placeholder={'Taper votre texte'}
                            value={evaluation?.annualAction}
                            onChange={(value: any) => {
                                setEvaluation((old: any) => ({ ...old, annualAction: value }));
                            }}
                        />
                    </div>
                    <Spacer height={20} />
                    {isCoordinator && (
                        <div style={{ justifyContent: 'center', alignSelf: 'center', display: 'flex' }}>
                            <div style={{ width: '50%' }}>
                                <div style={{ justifyContent: 'center', alignSelf: 'center', display: 'flex' }}>
                                    <CustomLoadingButton
                                        label="GÉNÉRER UNE SYNTHÈSE ANNUELLE"
                                        width={350}
                                        loading={exporting}
                                        handleClick={() => {
                                            downloadSyntheses();
                                        }}
                                    />
                                </div>
                                <CustomLoadingButton
                                    visible={'hidden'}
                                    outlined
                                    width={350}
                                    label="GÉNÉRER UNE SYNTHÈSE QUADRIENNALLE"
                                    handleClick={() => {}}
                                />
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};
