import { useState } from 'react';
import { PatientService } from 'src/services/Patients';

const useUserHooks = () => {
    const [fetchingUser, setFetchingUser] = useState<boolean>(true);
    const [user, setUser] = useState<any>({});

    const handleFetchUserById = (userId: number) => {
        setFetchingUser(true);
        PatientService.getPatient(userId)
            .then((response) => {
                const data = response?.data?.data;
                setUser(data);
                setFetchingUser(false);
            })
            .catch(() => setFetchingUser(false));
    };

    return {
        handleFetchUserById,
        fetchingUser,
        user,
    };
};

export default useUserHooks;
