import { baseColors } from 'src/utils/constants/baseStyles';

const IconSecurity = (props: any) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={27} height={27} fill="none" viewBox="0 0 27 27" {...props}>
            <path
                d="M22.27,8.69c-.07.32-.55,2.84-.56,2.84s-4.86,4.84-4.86,4.84L13.17,12.7a1.82,1.82,0,0,0-2.57,2.57l5,5a1.82,1.82,0,0,0,1.29.53c.18,0,1.33-.68,1.5-.73-.5.51-1.47,1.81-2,2.33a3.23,3.23,0,0,1-2-1l-.07-.06a4.59,4.59,0,0,1-3,1.35C7.07,22.64,3.56,14.65,3,9A1.93,1.93,0,0,1,4.23,7l6.82-2.52c1-.1.52,0,1.5.27L18.43,7a1.94,1.94,0,0,1,1.24,2,21.76,21.76,0,0,1-.48,2.81Z"
                transform="translate(-0.24 -1.47)"
                fill="#f2f2f2"
            />
            <path
                d="M14.28,11.62,16.65,14a22.7,22.7,0,0,0,1.19-5.16.11.11,0,0,0-.08-.12l-6.4-2.45h-.09L4.86,8.7a.11.11,0,0,0-.07.12A23.14,23.14,0,0,0,7.35,17.1c1.29,2.36,2.77,3.77,4,3.77A2.81,2.81,0,0,0,13.07,20l-3.61-3.6a3.41,3.41,0,0,1,4.82-4.82Z"
                transform="translate(-0.24 -1.47)"
                fill="#f2f2f2"
            />
            <path
                d="M14.36,21.33a4.61,4.61,0,0,1-3,1.35C7.05,22.68,3.54,14.69,3,9A1.93,1.93,0,0,1,4.22,7l6.4-2.45a1.92,1.92,0,0,1,1.39,0L18.41,7a1.93,1.93,0,0,1,1.24,2,21.9,21.9,0,0,1-.48,2.82l3.1-3.1c0-.27.07-.54.1-.8a2.58,2.58,0,0,0-1.55-2.65l-8.5-3.59a2.6,2.6,0,0,0-2,0L1.81,5.27A2.58,2.58,0,0,0,.26,7.92c.8,7.43,5.86,17.39,11.05,17.39,1.77,0,3.51-1.15,5.08-2.95a3.33,3.33,0,0,1-2-1Z"
                transform="translate(-0.24 -1.47)"
                fill={baseColors.green.primary}
            />
            <path
                d="M26.47,9.34a1.82,1.82,0,0,0-2.57,0l-7.07,7.07-3.67-3.67a1.82,1.82,0,0,0-2.57,2.57l4.95,5a1.84,1.84,0,0,0,1.29.53,1.82,1.82,0,0,0,1.28-.53l8.36-8.36A1.82,1.82,0,0,0,26.47,9.34Z"
                transform="translate(-0.24 -1.47)"
                fill={baseColors.orange.primary}
            />
        </svg>
    );
};

export default IconSecurity;
