import { baseColors } from 'src/utils/constants/baseStyles';

function NotificationInactif(props: any) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={26} height={26} fill="none" viewBox="0 0 26 26" {...props}>
            <path
                fill={baseColors.green.primary}
                d="M13.017 26a4.067 4.067 0 01-4.062-4.063.813.813 0 011.625 0 2.44 2.44 0 002.437 2.438 2.44 2.44 0 002.438-2.438.813.813 0 011.625 0A4.067 4.067 0 0113.017 26z"
            />
            <path
                fill={baseColors.green.primary}
                d="M21.954 22.75H4.08a1.898 1.898 0 01-1.232-3.337 7.26 7.26 0 002.587-5.56v-3.02c0-4.181 3.401-7.583 7.583-7.583S20.6 6.652 20.6 10.833v3.02c0 2.145.94 4.168 2.576 5.553a1.896 1.896 0 01-1.222 3.344zM13.017 4.875a5.965 5.965 0 00-5.958 5.958v3.02a8.88 8.88 0 01-3.153 6.793.27.27 0 00-.097.208c0 .147.123.271.27.271h17.875a.274.274 0 00.271-.27.268.268 0 00-.095-.207 8.881 8.881 0 01-3.155-6.794v-3.02a5.965 5.965 0 00-5.958-5.959z"
            />
        </svg>
    );
}

export default NotificationInactif;
