import { IProgramFilter, Time } from 'src/interfaces/interfaces';
import { QuizType } from 'src/interfaces/types';
import { addZeroBeforeNumber } from '../utils';
import { baseColors } from './baseStyles';

const dateTimeFormat = 'YYYY-MM-DD HH:mm';
const FAKE_AVATAR_URL = 'https://i.pravatar.cc/300?img=';

const getHourAndMinutes = (time: string) => {
    const splittedTime = time ? time.split(':') : [0, 0];
    const hour = +splittedTime[0];
    const minutes = +splittedTime[1];

    return {
        hour,
        minutes,
    };
};

const minuteIntervals = ({
    minuteInterval,
    hourValue,
    endHour,
}: {
    minuteInterval: number;
    hourValue: string;
    endHour: string;
}) => {
    const splitedEndHour = endHour.split(':');
    const endDateHour = +splitedEndHour[0];
    const endDateMinutes = +splitedEndHour[1];

    let fifteenIntervals = [0, 15, 30, 45];
    let thirtyIntervals = [0, 30];
    let fourtyFiveIntervals = [0, 45];

    if (endDateHour === +hourValue && endDateMinutes > 0) {
        fifteenIntervals = [...fifteenIntervals.filter((time: number) => time < endDateMinutes), endDateMinutes];
        thirtyIntervals = [...thirtyIntervals.filter((time: number) => time < endDateMinutes), endDateMinutes];
        fourtyFiveIntervals = [...fourtyFiveIntervals.filter((time: number) => time < endDateMinutes), endDateMinutes];
    }

    function itemValue(minute: number) {
        return {
            label: `${hourValue}:${addZeroBeforeNumber(minute)}`,
            value: `${hourValue}${addZeroBeforeNumber(minute)}`,
        };
    }

    switch (minuteInterval) {
        case 15:
            return fifteenIntervals.map((minute: number) => itemValue(minute));

        case 30:
            return thirtyIntervals.map((minute: number) => itemValue(minute));

        default:
            return fourtyFiveIntervals.map((minute: number) => itemValue(minute));
    }
};


/**
 * Exemple isDivisibleByDuration("2024-06-04 10:11", "2024-06-04 10:41", "02:30");
 */

const isDivisibleByDuration = (startDate: string, endDate: string, duration: string) => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    const [hours, minutes] = duration.split(':').map(Number);
    const durationInMinutes = hours * 60 + minutes;

    const diffInMinutes = (end.getTime() - start.getTime()) / (1000 * 60);

    const isDivisible = diffInMinutes % durationInMinutes === 0;

    const proposition: string[] = [];

    if (!isDivisible) {
        let tempEnd = new Date(start);
        tempEnd.setMinutes(tempEnd.getMinutes() + durationInMinutes);

        while (tempEnd <= end) {
            proposition.push(tempEnd.toTimeString().slice(0, 5));
            tempEnd.setMinutes(tempEnd.getMinutes() + durationInMinutes);
        }

        // Ajouter les propositions au-delà de endDate jusqu'à 24 heures à partir de startDate
        while (tempEnd.getDate() === start.getDate()) {
            proposition.push(tempEnd.toTimeString().slice(0, 5));
            tempEnd.setMinutes(tempEnd.getMinutes() + durationInMinutes);
        }
    }

    return {
        isDivisible,
        proposition
    };
}

const planningHours = (timeData: Time, minuteInterval?: number) => {
    const { startHour, endHour } = timeData;
    let hours: Array<{ label: string; value: string }> = [];

    for (let i = getHourAndMinutes(startHour)?.hour; i <= getHourAndMinutes(endHour)?.hour; i++) {
        const hourValue: string = i < 10 ? `0${i}` : `${i}`;
        minuteInterval && hours.push(...minuteIntervals({ minuteInterval, hourValue, endHour }));
        // hours.push(...minutesInterval({ hourValue, endHour, minutesValues: getHourAndMinutes(startHour)?.minutes }));
    }

    return hours;
};


/**
 * 
 * @param startDate 
 * @param endDate 
 * const startDate = "2024-06-15 09:00:00";
 * const endDate = "2024-06-15 10:30:00";
 * return "1h 30m" 
 */

function getDuration(startDate: string, endDate: string): string {
    const start = new Date(startDate);
    const end = new Date(endDate);

    const diffInMilliseconds = end.getTime() - start.getTime();
    const diffInMinutes = diffInMilliseconds / (1000 * 60);

    const hours = Math.floor(diffInMinutes / 60);
    const minutes = diffInMinutes % 60;
    let result = '';
    if(hours > 0){
        result = minutes > 0 ? `${hours} h ${minutes} min` : `${hours} h`;
    }else{
        result = `${minutes} min`;
    }

    return result;
}

const baseHours = Array.from(Array(20).keys()).filter((i: number) => i > 7 && i < 19);

const slots = Array.from(Array(5).keys(), (hour: number) => ({
    id: Number(`${hour}5`),
    label: `${hour}5 min`,
    value: Number(`${hour}5`),
}));

const agendaPlanningEvents = [
    {
        title: 'Rendez-vous',
        value: 'appointment',
    },
    {
        title: 'Ateliers',
        value: 'workshop',
    },
];

const PatientProgramsFilter = ['brouillon', 'termine'];
const ServerError = {
    unknown: 'Oops, une erreur est survenue lors du chargement des données depuis notre serveur .',
    dndOverlappedError: "Une erreur s'est produite lors de votre opération.",
    fetchingDataError: 'Une erreur est survenue lors du chargement des données',
};

const OperationMessage = {
    success: 'Opération réussie !',
    error: "Une erreur s'est produite lors de votre opération.",
    warning: 'Veuillez selectionné un utilisateur au moin',
    update: 'Modification réussie !',
};

const SignUpMessage = {
    success: "Un lien d'activation a été envoyé à l'adresse e-mail que vous avez indiqué pendant l'enregistrement.",
    error: "Une erreur s'est produite lors de votre opération.",
};

const EventMessage = {
    success: 'Evénement créé avec succès !',
    update: 'Evénement modifié avec succès !',
    error: "Une erreur s'est produite lors de votre opération.",
    warning: 'Veuillez selectionné un utilisateur au moin',
    overlap: "L'événement ne peut pas être créé en raison d'un chevauchement de dates (ou d'heure)",
    updateOverlap: "L'événement ne peut pas être modifié en raison d'un chevauchement de dates (ou d'heure)",
    passedDate: 'Vous pouvez pas crée un événement à une date passée.',
};

const AgendOperationMessage = {
    success: 'Mise à jour avec succès !',
    error: "Une erreur s'est produite lors de votre opération.",
    invalidTime: 'Heure invalide',
};

const notificationTypes = [
    { id: 1, label: 'Nouvel événement', value: 'ACTION_TYPE_NEW_EVENT' },
    { id: 2, label: 'Annulation', value: 'ACTION_TYPE_CANCEL_EVENT' },
    { id: 3, label: "Partage d'agenda", value: 'ACTION_TYPE_SHARE_EVENT' },
    { id: 4, label: 'Inscription', value: 'ACTION_TYPE_REGISTRATION' },
    { id: 5, label: 'Rendez-vous', value: 'ACTION_TYPE_ANNULATION' },
    { id: 6, label: 'Atelier', value: 'ACTION_TYPE_WORKSHOP' },
    { id: 7, label: 'Observation', value: 'ACTION_TYPE_OBSERVATION' },
    { id: 8, label: 'Suppression événement', value: 'ACTION_TYPE_DELETE_EVENT' },
    { id: 9, label: 'Changement rendez-vous', value: 'ACTION_TYPE_CHANGE_EVENT' },
];

const programLevelFilters = [
    { id: 0, value: -1, label: 'Tous' },
    { id: 1, value: 1, label: 'Suivi' },
    { id: 2, value: 2, label: 'Initial' },
    { id: 3, value: 3, label: 'Renforcement' },
];

const defaultProgramFilter: IProgramFilter = {
    date: { start: null, end: null },
    level: [],
    state: 0,
    type: [],
};

const medicalAxisMenus = [
    {
        label: '',
        key: 0,
    },
    {
        label: 'Réponses patient',
        key: 1,
    },
    {
        label: 'Pathologies',
        key: 2,
    },
    {
        label: '',
        key: 3,
    },
];

const AUTHORIZATION_CODES = {
    listAuthorization: 'list_authorization',
    listUserHistory: 'list_user_history',
    listPermission: 'list_permission',
    listFeature: 'list_feature',
    listRole: 'list_role',
    listAllUsers: 'list_all_users',
};

const eventVisibility = [
    {
        id: -1,
        name: 'Sélectionner la visibilité',
        value: -1,
    },
    {
        id: 0,
        name: 'Privé',
        value: 1,
    },
    {
        id: 1,
        name: 'Publique',
        value: 0,
    },
];

const defaultAgendaHours: Time = {
    startHour: '08:00',
    endHour: '18:00',
};

const weekDays = [
    {
        id: 1,
        name: 'Lundi',
    },
    {
        id: 2,
        name: 'Mardi',
    },
    {
        id: 3,
        name: 'Mercredi',
    },
    {
        id: 4,
        name: 'Jeudi',
    },
    {
        id: 5,
        name: 'Vendredi',
    },
    {
        id: 6,
        name: 'Samedi',
    },
    {
        id: 7,
        name: 'Dimanche',
    },
];

const quizTypes: QuizType[] = [
    { id: 0, label: 'Type de quiz', value: 0 },
    { id: 1, label: 'Text libre', value: 1 },
    { id: 2, label: 'Choix unique', value: 2 },
    { id: 3, label: 'Choix multiple', value: 3 },
];

const PostCategories = {
    knowledge: 'SAVOIR',
    knowHowToBe: 'SAVOIR_ETRE',
    knowHow: 'SAVOIR_FAIRE',
};

const knowledges = [
    {
        id: 1,
        value: 1,
        label: 'Savoir',
        code: PostCategories.knowledge,
    },
    {
        id: 2,
        value: 2,
        label: 'Savoir Être',
        code: PostCategories.knowHowToBe,
    },
    {
        id: 3,
        value: 3,
        label: 'Savoir Faire',
        code: PostCategories.knowHow,
    },
];

const validationTypes = [
    {
        id: 1,
        value: 1,
        label: 'Validation sur plusieurs semaine',
    },
    {
        id: 2,
        value: 2,
        label: 'Validation en une fois',
    },
];

const textThemes = [
    {
        code: 'color_primary_text',
        color: baseColors.green.pastel,
        label: 'Primary',
        isFile: 0,
    },
    {
        code: 'color_secondary_text',
        color: '#3498db',
        label: 'Secondary',
        isFile: 0,
    },
    {
        code: 'color_third_text',
        color: '#07bc0c',
        label: 'Success',
        isFile: 0,
    },
    {
        code: 'color_fourth_text',
        color: '#e74c3c',
        label: 'Danger',
        isFile: 0,
    },
];
const linkThemes = [
    {
        code: 'color_primary_link',
        color: baseColors.green.pastel,
        label: 'Primary',
        isFile: 0,
    },
    {
        code: 'color_secondary_link',
        color: '#3498db',
        label: 'Secondary',
        isFile: 0,
    },
    {
        code: 'color_third_link',
        color: '#07bc0c',
        label: 'Success',
        isFile: 0,
    },
    {
        code: 'color_fourth_link',
        color: '#e74c3c',
        label: 'Danger',
        isFile: 0,
    },
];
const buttonThemes = [
    {
        code: 'color_primary_button',
        color: baseColors.green.pastel,
        label: 'Primary',
        isFile: 0,
    },
    {
        code: 'color_secondary_button',
        color: '#3498db',
        label: 'Secondary',
        isFile: 0,
    },
    {
        code: 'color_third_button',
        color: '#07bc0c',
        label: 'Success',
        isFile: 0,
    },
    {
        code: 'color_fourth_button',
        color: '#e74c3c',
        label: 'Danger',
        isFile: 0,
    },
];

const patientTabMenus = [
    {
        id: 1,
        title: 'Profil patient',
        path: 'profile',
    },
    {
        id: 2,
        title: 'Programmes',
        path: 'program',
    },
    {
        id: 3,
        title: 'Diagnostic éducatif',
        path: 'diagnostic',
    },
    {
        id: 4,
        title: 'Agenda',
        path: 'agenda',
    },
    {
        id: 5,
        title: 'Observations',
        path: 'observation',
    },
    {
        id: 6,
        title: 'Entretien de suivi',
        path: 'interview',
    },
    {
        id: 7,
        title: 'Evaluation',
        path: 'evaluation',
    }
];

const DiagEduTabMenus = [
    // {
    //     id: 1,
    //     title: 'Compte rendu',
    //     path: 'compterendu',
    // },
    {
        id: 2,
        title: 'APA',
        path: 'apa',
    },
    {
        id: 3,
        title: 'Diététique',
        path: 'dietetique',
    },
    {
        id: 4,
        title: 'Psychologique',
        path: 'psychologique',
    },
    {
        id: 5,
        title: 'Médical',
        path: 'medical',
    },
    {
        id: 6,
        title: 'Synthèse',
        path: 'synthese',
    },
];

const durationLabel = (duration: number, whithMin?: boolean) => {
    if (!whithMin) {
        return `${duration} heure${duration === 1 ? '' : 's'}`;
    }
    return `${duration} h 30 min`;
};

const durationWithirtyMin = (duration: number, index: number) => {
    const res = [1, 0].map((item: number) => ({
        id: Number(duration + '' + item),
        name: durationLabel(duration, item === 0),
    }));
    return res;
};

const calculateDuration = (timeArray:Array<string>) => {
    const minutesArray = timeArray.map(time => {
      const [hours, minutes] = time.split(':');
      return parseInt(hours, 10) * 60 + parseInt(minutes, 10);
    });
  
    const totalMinutes = minutesArray.reduce((total, minutes) => total + minutes, 0);
  
    const totalHours = Math.floor(totalMinutes / 60);
    const remainingMinutes = totalMinutes % 60;
  
    const formattedResult = `${String(totalHours).padStart(2, '0')}:${String(remainingMinutes).padStart(2, '0')}`;
  
    return formattedResult;
  }

const durations = Array.from(Array(10).keys())
    .filter((i) => i > 0)
    .map((item, i) => durationWithirtyMin(item, i));

const thirtyMinitDuration = {
    id: 3,
    name: '30 min',
};

const defaultDuration = (workshopDuration: string) => {
    const splited = workshopDuration?.split(':');
    const hour = Number(splited?.[0]);
    const min = Number(splited?.[1]) === 30 ? 0 : 1;
    let res = hour + '' + min;
    if (hour === 0 && splited?.[1] != '00' && splited?.[1].length === 2) {
        const splitedMin = splited?.[1].split('');
        res = splitedMin[0];
    }
    return Number(res);
};

const handleSetPayload = (value: number, setPayload: any) => {
    const stringedValue = String(value);
    const hour = stringedValue.charAt(0);
    let durationValue = `0${hour}:00`;
    if (stringedValue?.includes('0')) {
        durationValue = `0${hour}:30`;
    }
    if (value === 3) {
        durationValue = `00:30`;
    }
    setPayload((old: any) => ({ ...old, duration: durationValue }));
};

const EvaluationsTabMenus = [
    {
        id: 1,
        title: 'APA',
        path: 'apa',
    },
    {
        id: 2,
        title: 'Diététique',
        path: 'dietetic',
    },
    {
        id: 3,
        title: 'Psychologique',
        path: 'psychologique',
    },
    {
        id: 4,
        title: 'Médical',
        path: 'medical',
    },
    {
        id: 5,
        title: 'Objectifs',
        path: 'goal',
    },
    {
        id: 6,
        title: 'Quizz',
        path: 'quizz',
    },
    { key: 7, title: 'Compte rendu', path: 'compterendu' },
];

const Motivations = [
    {
        id: 1,
        name: 'Pré-contemplation',
    },
    {
        id: 2,
        name: 'Contemplation',
    },
    {
        id: 3,
        name: 'Préparation',
    },
    {
        id: 4,
        name: 'Action',
    },
    {
        id: 5,
        name: 'Maintien',
    },
    {
        id: 6,
        name: 'Rechute',
    },
];


const StateProgramUserTemp:any = {
    EN_COURS : {
        code : 'EN_COURS',
        title: "En cours"
    } ,
    TERMINE : {
        code : 'TERMINE',
        title : 'Terminé'
    },
    EN_CREATION : {
        code : 'EN_CREATION',
        title : 'En création'
    }
}

const AxisLabel = {
    APA : 'APA',
    DIET : 'Diététique',
    PSYCHO : 'Psychologique',
    MEDICAL : 'Médical',
}

export {
    Motivations,
    EvaluationsTabMenus,
    validationTypes,
    DiagEduTabMenus,
    handleSetPayload,
    durationWithirtyMin,
    thirtyMinitDuration,
    defaultDuration,
    FAKE_AVATAR_URL,
    patientTabMenus,
    SignUpMessage,
    knowledges,
    planningHours,
    baseHours,
    durations,
    slots,
    agendaPlanningEvents,
    ServerError,
    notificationTypes,
    programLevelFilters,
    defaultProgramFilter,
    medicalAxisMenus,
    AUTHORIZATION_CODES,
    PatientProgramsFilter,
    AgendOperationMessage,
    getHourAndMinutes,
    eventVisibility,
    OperationMessage,
    defaultAgendaHours,
    weekDays,
    EventMessage,
    dateTimeFormat,
    quizTypes,
    textThemes,
    linkThemes,
    buttonThemes,
    StateProgramUserTemp,
    AxisLabel,
    calculateDuration,
    isDivisibleByDuration,
    getDuration
};
