import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { CustomText } from 'src/components/Text/CustomText';
import { ConclusionPayloadType } from 'src/interfaces/types';
import { baseFontSize } from 'src/utils/constants/baseStyles';
import { handleShowMore } from '../Logic';
import CustomEmptyData from 'src/components/CustomEmptyData/CustomEmptyData';
import CheckBoxSkillItem from '../SkillsFormContainer/CheckBoxSkillItem/CheckBoxSkillItem';
import {
    SetDefaultUserArticlePayload,
    SetDefaultUserGoalsPayload,
    SetDefaultUserWorkshopPayload,
} from './ConclusionCheckBoxForm.utils';
import { DraftConclusion, ProgramSkill } from 'src/interfaces/interfaces';

interface ConclusionCheckBoxFormProps {
    isGoals?: boolean;
    isWorkshops?: boolean;
    isArticles?: boolean;
    keyValue: string;
    payload: ConclusionPayloadType;
    setPayload: Dispatch<SetStateAction<ConclusionPayloadType>>;
    setDisabledDefaultPrechecked?: Dispatch<SetStateAction<boolean>>;
    dataItems: any[];
    skills: ProgramSkill[];
    isEditMode?: boolean;
    draftConclusion: DraftConclusion;
    updateGoalIdsInSkills?: boolean;
    setUpdateGoalIdsInSkills?: Dispatch<SetStateAction<boolean>>;
}

export type ConclusionRowItem = {
    id: number;
    value: string;
};

const ConclusionCheckBoxForm: FC<ConclusionCheckBoxFormProps> = ({
    isGoals,
    isWorkshops,
    isArticles,
    keyValue,
    dataItems,
    payload,
    setPayload,
    skills,
    isEditMode,
    draftConclusion,
    setDisabledDefaultPrechecked,
    updateGoalIdsInSkills,
    setUpdateGoalIdsInSkills,
}) => {
    const [showAllItems, setShowAllItems] = useState(false);
    const sliceMax = !showAllItems ? 2 : dataItems?.length;
    const emptyData = dataItems?.length === 0;
    const [existedSkillIds, setExistedSkillIds] = useState<number[]>([]);
    const [existedGoalIds, setExistedGoalIds] = useState<number[]>([]);
    const [existedWorkshopIds, setExistedWorkshopIds] = useState<number[]>([]);
    const [existedArticleIds, setExistedArticleIds] = useState<number[]>([]);

    useEffect(() => {
        const ids = payload.skills?.map((skill) => skill.id);
        setExistedSkillIds(ids);
        SetDefaultUserGoalsPayload({ setExistedGoalIds, payload });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payload?.skills]);

    useEffect(() => {
        SetDefaultUserWorkshopPayload({ setExistedWorkshopIds, skills, setPayload, draftConclusion });
        SetDefaultUserArticlePayload({ setExistedArticleIds, skills, setPayload, draftConclusion });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [skills, draftConclusion]);

    useEffect(() => {
        const ids = payload.conclusionVideos?.map((article) => article);
        setExistedArticleIds(ids);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payload?.conclusionVideos]);

    useEffect(() => {
        const ids = payload.conclusionWorkshops?.map((workshop) => workshop);
        setExistedWorkshopIds(ids);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payload?.conclusionWorkshops]);

    const selected = () => {
        if (isGoals) {
            return existedGoalIds;
        }
        if (isArticles) {
            return existedArticleIds;
        }
        if (isWorkshops) {
            return existedWorkshopIds;
        }
        return existedSkillIds;
    };

    return (
        <>
            {emptyData ? (
                <CustomEmptyData marginTop={10} label="Aucun élément trouvé." height={50} width={50} />
            ) : (
                dataItems
                    ?.slice(0, sliceMax)
                    .map((row) => (
                        <CheckBoxSkillItem
                            updateGoalIdsInSkills={updateGoalIdsInSkills}
                            setUpdateGoalIdsInSkills={setUpdateGoalIdsInSkills}
                            setDisabledDefaultPrechecked={setDisabledDefaultPrechecked}
                            isGoals={isGoals}
                            isEditMode={isEditMode}
                            isArticles={isArticles}
                            isWorkshops={isWorkshops}
                            skills={dataItems}
                            defaultSkills={skills}
                            selected={selected()}
                            data={row}
                            keyValue={keyValue}
                            payload={payload}
                            setPayload={setPayload}
                        />
                    ))
            )}

            {dataItems?.length > 2 && (
                <div onClick={handleShowMore({ setShowAllItems })}>
                    <CustomText style={{ fontSize: baseFontSize.sm, cursor: 'pointer' }}>
                        {showAllItems ? 'Voir moins' : 'Voir plus...'}
                    </CustomText>
                </div>
            )}
        </>
    );
};

export default ConclusionCheckBoxForm;
