import { FC, createRef, useState, useEffect, useContext } from 'react';
import CustomModal from 'src/components/CustomModal';
import { UserContext } from 'src/providers/UserProvider';
import { PhotoCamera } from '@mui/icons-material';
import { Grid, Avatar, IconButton, Box } from '@mui/material';
import { baseColors, baseFontFamily, baseScreenHeight } from 'src/utils/constants/baseStyles';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import { EditSoignant } from './EditSoignant';
import { putProfilePicture, addProfilePictureSoignant, getProfilePicture } from 'src/services/Patients';
import { EditPassword } from './EditPassword';
import Title from './Title';
import InfoItem from './InfoItem';
import { OperationMessage } from 'src/utils/constants/constants';
import { toast } from 'react-toastify';
import { updateETP } from 'src/services/User/user.service';
import { CustomButton } from 'src/components/Button/CustomButton';
import { InsertDriveFile } from '@mui/icons-material';
import { CustomText } from 'src/components/Text/CustomText';
import useCheckUserRole from 'src/hooks/useCheckUserRole';
import { CustomRadio } from 'src/components/InputField/CustomRadio';
import { URER_ROLES } from 'src/utils/constants/enums';
import CustomCircleLoader from 'src/components/CustomLoader/CustomCircleLoader';

interface SoignantDetailProps {
    open: boolean;
    setOpen: Function;
    setUpdate?: Function;
}

export const SoignantDetail: FC<SoignantDetailProps> = (props) => {
    const defaultValue: any = {
        hasEtp: false,
        etpCertificateFileContent: '',
        checkEtp: 0,
    };
    const { roles } = useCheckUserRole();
    const { open, setOpen, setUpdate } = props;
    const [editOpen, setEditOpen] = useState(false);
    const [editPassOpen, setEditPassOpen] = useState(false);
    const { userInfos: userInfosFromContext } = useContext(UserContext);
    const [pictureId, setPictureId] = useState<any>();
    const iconRef = createRef<HTMLInputElement>();
    const [profIcon, setProfIcon] = useState<string>('');
    const [profIcon1, setProfIcon1] = useState<any>();
    const userId = userInfosFromContext?.id;
    const soignantEdit = () => setEditOpen(true);
    const soignantEditPass = () => setEditPassOpen(true);
    const [docName, setDocName] = useState<string>('');
    const [file, setFile] = useState<string>('');
    const [professional, setProfessional] = useState<any>(defaultValue);
    let docType = '';
    const [loadingProfessionalForm, setLoadingProfessionalForm] = useState(false);
    const [isLoadingProfIcon, setIsLoadingProfIcon] = useState<boolean>(false);
    const isCoordinator = roles?.isCoordinator ?? false;
    const isAdmin = roles?.isAdmin ?? false;
    const { isETP, setIsETP } = useContext(UserContext);
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const profilImage = profIcon.includes('data:image/') ? profIcon :  baseUrl+'/'+profIcon;

    const allowedExtension = [
        'image/png',
        'image/jpeg',
        'image/jpg',
        'application/pdf',
        'application/msword',
        'text/csv',
        'text/txt',
    ];

    const handleChangeETP = () => {
        //setFetchingUsers(true);
        updateETP(userId, professional)
            .then((response) => {
                toast.success(OperationMessage.success);
                setUpdate?.((prev: any) => !prev);
            })
            .catch((error) => {
                toast.error(OperationMessage.error);
                setUpdate?.((prev: any) => !prev);
            });
    };

    const _handleReaderLoadedETP = (readerEvt: any) => {
        let binaryString = readerEvt.target.result;
        if (docType.length > 0) {
            let fileContent = `data:${docType};base64,${btoa(binaryString)}`;
            setFile(`data:${docType};base64,${btoa(binaryString)}`);
            setProfessional((old: any) => ({
                ...old,
                filecontents: fileContent,
                etpCertificateFileContent: fileContent,
            }));
        }
    };

    const validPatientETP = () => docName !== '';

    const handleChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        let files = event.currentTarget.files;
        if (files && files?.length > 0) {
            let file = files[0];
            docType = file?.type;
            setDocName(file.name);

            if (!allowedExtension?.includes(file.type)) {
                alert('fichier non supportée , veuillez importer des .word, .jpeg, .jpg, .png, .pdf, .csv, .txt');
                setDocName('');
            } else {
                const reader = new FileReader();
                reader.onload = _handleReaderLoadedETP;
                reader.readAsBinaryString(file);
            }
        }
    };

    const _handleReaderLoaded = (readerEvt: any) => {
        let binaryString = readerEvt.target.result;
        let fileContent = `data:image/jpeg;base64,${btoa(binaryString)}`;
        let userId = userInfosFromContext?.id;
        let id = pictureId;
        setProfIcon(`data:image/jpeg;base64,${btoa(binaryString)}`);
        if (pictureId) {
            putProfilePicture({ id, userId, fileContent }).then(() => {
                setUpdate?.((prev: any) => !prev);
            });
        } else {
            addProfilePictureSoignant({ userId, fileContent }).then(() => {
                setUpdate?.((prev: any) => !prev);
            });
        }
    };

    const role = userInfosFromContext?.roles?.includes(URER_ROLES.COORDINATOR)
        ? 'Coordinateur'
        : userInfosFromContext?.roles?.includes(URER_ROLES.ADMIN)
        ? 'Administrateur'
        : userInfosFromContext?.roles?.includes(URER_ROLES.DOCTOR)
        ? 'Soignant'
        : '---';

    const handleIconChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let files = event.currentTarget.files;
        if (files && files?.length > 0) {
            let file = files[0];

            let allowedExtension = ['image/png', 'image/jpeg', 'image/jpg'];
            if (!allowedExtension?.includes(file.type)) {
                alert('images non supporté (veuiller importe des .jpeg, .jpg, .png');
            } else {
                const reader = new FileReader();
                reader.onload = _handleReaderLoaded;
                reader.readAsBinaryString(file);
            }
        }
    };

    const handleGetProfilePicture = () => {
        setIsLoadingProfIcon(true);
        getProfilePicture(userId).then((res) => {
            const profilData = res?.data?.data;
            if (profilData && profilData instanceof Array && profilData.length > 0) {
                setProfIcon1(profilData);
                setProfIcon(profilData[0]?.imagepath ?? '');
                setPictureId(profilData[0]?.id);
            }
            setIsLoadingProfIcon(false)
        });
    };

    useEffect(() => {
        if (userId) {
            handleGetProfilePicture();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId]);

    return (
        <CustomModal
            width={600}
            height={baseScreenHeight.newEventModal}
            open={open}
            toggleModal={setOpen}
            title="Détails du profil"
            footer={
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <CustomLoadingButton width="48%" label="Modifier le profil" handleClick={soignantEdit} />

                    <CustomLoadingButton width="48%" label="Modifier le mot de passe" handleClick={soignantEditPass} />
                </div>
            }
        >
            <Grid container direction="column" spacing={7}>
                <Grid item>
                    <IconButton
                        onClick={() => iconRef?.current?.click()}
                        style={{
                            background: baseColors.grey,
                            padding: profIcon === '' ? 20 : 0,
                            marginLeft: '38%',
                            marginTop: '30px',
                            marginBottom: '30px',
                        }}
                    >
                        {
                            isLoadingProfIcon ? 
                                <Box height={150} width={150} style={{display : 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    <CustomCircleLoader height={25} width={25} />
                                </Box> :
                            profIcon?.length !== 0 ? (
                                <Avatar src={`${profilImage}`} sx={{ width: 150, height: 150 }} />
                            ) : (
                                <PhotoCamera sx={{ width: 150, height: 150 }} />
                            )
                        }
                        <input ref={iconRef} id="icon_input" type="file" hidden onChange={handleIconChange} />
                    </IconButton>
                </Grid>

                <Grid item>
                    <Grid
                        container
                        direction={'row'}
                        style={{ paddingLeft: 30, marginTop: 20 }}
                        justifyContent={'space-between'}
                    >
                        <Grid item flex={2}>
                            <Title title={'Informations sur le profil'} />
                            <Grid container direction={'column'}>
                                <InfoItem label={'Prenom'} value={userInfosFromContext?.lastName} />
                            </Grid>
                            <Grid container direction={'column'}>
                                <InfoItem label={'Nom'} value={userInfosFromContext?.firstName} />
                            </Grid>
                            <Grid container direction={'column'}>
                                <InfoItem label={'Rôle'} value={role ?? '---'} />
                            </Grid>
                        </Grid>

                        <Grid item flex={2}>
                            <Title title={'Contact'} />
                            <Grid container direction={'column'}>
                                <InfoItem label={'email'} value={userInfosFromContext?.email} />
                            </Grid>
                            <Grid container direction={'column'}>
                                <InfoItem label={'Téléphone'} value={userInfosFromContext?.phoneNumber ?? '---'} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {!isCoordinator &&
            !isAdmin &&
            userInfosFromContext?.hasEtp === 0 &&
            userInfosFromContext?.etpCertificate === null &&
            userInfosFromContext?.checkEtp === 0 ? (
                <Grid item flexGrow={1} justifyContent={'center'} style={{ paddingLeft: '3%', marginTop: 15 }}>
                    <Grid style={{ marginLeft: 10 }}>
                        <Title title={' Avez-vous suivi la formation ETP (Education Thérapeutique du Patient) ?'} />

                        <Grid container justifyContent={'center'}>
                            <Grid
                                style={{ marginBottom: 10, textAlign: 'center' }}
                                justifyContent={'center'}
                                alignContent={'center'}
                                alignItems={'center'}
                                container
                                direction={'row'}
                            >
                                <Grid item>
                                    <CustomRadio
                                        checked={isETP === 1}
                                        onClick={() => {
                                            setProfessional((old: any) => ({ ...old, hasEtp: false }));
                                            setProfessional((old: any) => ({ ...old, checkEtp: 1 }));
                                            setIsETP(1);
                                        }}
                                        label={'Oui'}
                                    ></CustomRadio>
                                </Grid>
                                <Grid item>
                                    <CustomRadio
                                        checked={isETP === 0}
                                        onClick={() => {
                                            setProfessional((old: any) => ({ ...old, hasEtp: false }));
                                            setProfessional((old: any) => ({ ...old, checkEtp: 0 }));
                                            setIsETP(0);
                                        }}
                                        label={'Non'}
                                    ></CustomRadio>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            ) : (
                ''
            )}

            {!isCoordinator && !isAdmin && isETP === 1 && userInfosFromContext?.checkEtp === 0 ? (
                <Grid lg={12} container direction="row" alignItems={'center'}>
                    <Grid item>
                        <CustomText
                            style={{ minWidth: '250px', marginLeft: 40 }}
                            fontFamily={baseFontFamily.OpenSansSemiBold}
                            fontSize={15}
                            color={baseColors.black}
                        >
                            Veuillez importer le fichier :
                        </CustomText>
                    </Grid>
                    <div>
                        <input
                            accept="*/*"
                            id="icon-button-file"
                            type="file"
                            style={{ display: 'none' }}
                            onChange={handleChangeFile}
                        />
                        <label htmlFor="icon-button-file">
                            <IconButton color="primary" aria-label="upload picture" component="span">
                                {file?.length !== 0 ? (
                                    <CustomText style={{ textAlign: 'center' }} fontSize={11}>
                                        {docName === '' ? 'Cliquer pour joindre un fichier' : docName}
                                    </CustomText>
                                ) : (
                                    <InsertDriveFile />
                                )}
                            </IconButton>
                        </label>
                    </div>
                    <CustomButton
                        lower
                        width={'30%'}
                        disabled={!validPatientETP()}
                        loading={loadingProfessionalForm}
                        onClick={() => handleChangeETP()}
                        label="Importer un justificatif"
                    />
                </Grid>
            ) : (
                ''
            )}

            <EditSoignant open={editOpen} setOpen={setEditOpen} />
            <EditPassword open={editPassOpen} setOpen={setEditPassOpen} />
        </CustomModal>
    );
};
