import { makeStyles } from '@mui/styles';
import { baseColors, baseScreenHeight } from 'src/utils/constants/baseStyles';

const useStyles = makeStyles({
    container: {
        backgroundColor: baseColors.green.light,
    },
});

const sxStyles: any = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        position: 'relative',
        ...baseScreenHeight.defaultHeight,
        zIndex: '100',
        overflowY: 'scroll',
        marginBottom: '2rem',
    },
};

export { sxStyles };

export default useStyles;
