import { Box } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { retrieveDailyPatients } from 'src/actions/dashboard/dashboard';
import CustomEmptyData from 'src/components/CustomEmptyData';
import CustomCircleLoader from 'src/components/CustomLoader/CustomCircleLoader';
import { AgendaContext } from 'src/providers/AgendaProvider';
import { RootState } from 'src/stores/store';
import { isOdd } from 'src/utils/utils';
import PatientOfDayCard from '../../../../components/PatientOfDayCard';
import useStyles from './styles';
import { DashboardContext } from 'src/providers/DashboardProvider';
import { CustomText } from 'src/components/Text/CustomText';
import { baseFontSize } from 'src/utils/constants/baseStyles';

const PatientsOfTheDay: FC = () => {
    const classes = useStyles();
    const [updateList, setUpdateList] = useState<boolean>(false);
    const { updateEventList } = useContext(AgendaContext);
    const { updateData } = useContext(DashboardContext);

    const [patientOfTheDayLoading, setPatientOfTheDayLoading] = useState<boolean>(false);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(retrieveDailyPatients(setPatientOfTheDayLoading));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateList, updateEventList, updateData]);

    const data = useSelector((state: RootState) => state.dashboard);
    const patientsOfDay = data.dailyPatients;
    const isEmpty = patientsOfDay?.length === 0;

    const countedData = patientsOfDay?.map((d: any) => {
        const counts = d?.itemData?.attendees?.reduce(
            (acc: any, attendee: any) => {
                if (attendee?.type === 'Participants') {
                    acc.participants += 1;
                } else if (attendee?.type === 'Intervenants') {
                    acc.intervenants += 1;
                }
                return acc;
            },
            { participants: 0, intervenants: 0 },
        );

        return { ...d, counts };
    });

    return (
        <Box className={classes.container}>
            <Box className={classes.header}>
                <CustomText fontSize={baseFontSize.md} isBreefSerif>
                    Mes patients du jour
                </CustomText>
            </Box>

            <Box className={classes.content}>
                {patientOfTheDayLoading ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 10 }}>
                        <CustomCircleLoader height={50} width={50} />
                    </Box>
                ) : isEmpty ? (
                    <CustomEmptyData marginBottom={5} marginTop={20} height={50} width={50} />
                ) : (
                    countedData?.map((patient: any, i: number) => (
                        <PatientOfDayCard key={i} item={patient} setUpdateList={setUpdateList} isGreen={isOdd(i)} />
                    ))
                )}
            </Box>
        </Box>
    );
};

export default PatientsOfTheDay;
