export const notifications = [
    {
        label: 'Le patient X a annulé son rendez-vous prévu le 05/05',
        action: 'ANNULATION',
        date: '18/01/2021 - 10:25',
    },
    {
        label: 'Le patient X a annulé son rendez-vous prévu le 05/05',
        action: 'INSCRIPTION',
        date: '18/01/2021 - 10:25',
    },
    {
        label: 'Le patient X a annulé son rendez-vous prévu le 05/05',
        action: 'ATELIER',
        date: '18/01/2021 - 10:25',
    },
    {
        label: 'Le patient X a annulé son rendez-vous prévu le 05/05',
        action: 'ANNULATION',
        date: '18/01/2021 - 10:25',
        disabled: true,
    },
    {
        label: 'Le patient X a annulé son rendez-vous prévu le 05/05',
        action: 'INSCRIPTION',
        date: '18/01/2021 - 10:25',
        disabled: true,
    },
    {
        label: 'Le patient X a annulé son rendez-vous prévu le 05/05',
        action: 'ATELIER',
        date: '18/01/2021 - 10:25',
        disabled: true,
    },
    {
        label: 'Le patient X a annulé son rendez-vous prévu le 05/05',
        action: 'ANNULATION',
        date: '18/01/2021 - 10:25',
    },
    {
        label: 'Le patient X a annulé son rendez-vous prévu le 05/05',
        action: 'INSCRIPTION',
        date: '18/01/2021 - 10:25',
    },
    {
        label: 'Le patient X a annulé son rendez-vous prévu le 05/05',
        action: 'ATELIER',
        date: '18/01/2021 - 10:25',
    },
    {
        label: 'Le patient X a annulé son rendez-vous prévu le 05/05',
        action: 'ANNULATION',
        date: '18/01/2021 - 10:25',
        disabled: true,
    },
    {
        label: 'Le patient X a annulé son rendez-vous prévu le 05/05',
        action: 'INSCRIPTION',
        date: '18/01/2021 - 10:25',
        disabled: true,
    },
    {
        label: 'Le patient X a annulé son rendez-vous prévu le 05/05',
        action: 'ATELIER',
        date: '18/01/2021 - 10:25',
        disabled: true,
    },
];

export const participants = [
    {
        participant: 'Roger Glopopopy',
        present: true,
    },
    {
        participant: 'Naruto Ozomaki',
        present: false,
    },
    {
        participant: 'Sasuke Otsiwa',
        present: true,
    },
    {
        participant: 'Kennedy',
        present: true,
    },
];

export const intervenants = [
    {
        name: 'NPM le roi',
        phone: '123 12 32 231',
        email: 'email@email.com',
    },
    {
        name: 'YARN la reine',
        phone: '456 23 63 125',
        email: 'yarn@yarn.com',
    },
    {
        name: 'ARNOLD',
        phone: '123 12 32 231',
        email: 'arnold@arnold.com',
    },
];

export const specialistes = [
    [
        {
            name: 'Équivalences alimentaire',
            type: {
                label: 'Atelier',
                typeName: 'atelier',
            },
            timeStart: '1100',
            timeEnd: '1230',
        },
        {
            name: 'Mme DUPONT',
            type: {
                label: 'Diagnostic éducatif',
                typeName: 'diagnostic',
            },
            timeStart: '1400',
            timeEnd: '1530',
        },
    ],
    [
        {
            name: 'Régime',
            type: {
                label: 'Atelier',
                typeName: 'atelier',
            },
            timeStart: '0800',
            timeEnd: '0900',
        },
        {
            name: 'Mr ROGER',
            type: {
                label: 'Diagnostic simple',
                typeName: 'diagnostic',
            },
            timeStart: '1000',
            timeEnd: '1030',
        },
    ],
    [
        {
            name: 'Régime',
            type: {
                label: 'Atelier',
                typeName: 'atelier',
            },
            timeStart: '1000',
            timeEnd: '1200',
        },
        {
            name: 'Mr JASMIN',
            type: {
                label: 'Diagnostic simple',
                typeName: 'diagnostic',
            },
            timeStart: '1700',
            timeEnd: '1730',
        },
    ],
];

export const defaultPlanningHeaders = [
    { id: 'specialist', label: 'Spécialiste', minWidth: 150 },
    { id: 'specialist1', label: 'Spécialiste 1', minWidth: 150 },
    { id: 'specialist2', label: 'Spécialiste 2', minWidth: 150 },
];

export const combineData = [
    {
        name: 'ortho',
        label: 'Ortho - Prothèse de hanche',
        checked: false,
    },
    {
        name: 'cardio',
        label: 'Cardio - Insuffisance cardiaque',
        checked: false,
    },
    {
        name: 'diabeto',
        label: 'Diabéto',
        checked: false,
    },
];

export const currentPatients = [
    { id: 1, name: 'Florent' },
    { id: 2, name: 'Dupont' },
    { id: 3, name: 'Label' },
    { id: 4, name: 'Caoutchou' },
    { id: 5, name: 'Left' },
    { id: 6, name: 'Old' },
    { id: 7, name: 'Desx' },
    { id: 8, name: 'Oldas' },
    { id: 9, name: 'Sisx' },
    { id: 10, name: 'Nine' },
    { id: 11, name: 'Blend' },
    { id: 12, name: 'Eur' },
    { id: 13, name: 'Glace' },
];

export const antecedants = [
    { id: 1, name: 'Antécédent 1' },
    { id: 2, name: 'Antécédent 2' },
    { id: 3, name: 'Antécédent 3' },
    { id: 4, name: 'Antécédent 4' },
];

export const medicaments = [
    { id: 1, name: 'Médicament 1' },
    { id: 2, name: 'Médicament 2' },
    { id: 3, name: 'Médicament 3' },
    { id: 4, name: 'Médicament 4' },
];

const fakeWorkshops = [
    {
        id: 1,
        name: 'Module SaHOS',
        workshops: [
            { id: 456, name: 'Diagnostic' },
            { id: 14, name: 'Ateliers Zanany' },
        ],
    },
    { id: 2, name: 'Obesite' },
    { id: 3, name: 'Alimentaire' },
    { id: 4, name: 'Impact psychologique' },
    { id: 5, name: 'Mange et Papote' },
    { id: 6, name: 'La Chirurgie' },
    { id: 7, name: 'Post Operatoire' },
    { id: 8, name: 'Activite physique' },
    { id: 9, name: 'Suivi post operatoire' },
];

const consultationType = [
    { id: 1, name: 'Présentiel' },
    { id: 2, name: 'Visio' },
];

const syntheseVideos = [
    {
        id: 1,
        name: 'Videos Apprentissage 1',
        seen: false,
        realized: true,
        programs: [
            {
                id: 1,
                name: 'APA',
            },
            {
                id: 2,
                name: 'Diet',
            },
            {
                id: 3,
                name: 'Vegane',
            },
        ],
    },
    {
        id: 2,
        name: 'Videos Content',
        seen: true,
        realized: false,
        programs: [
            {
                id: 1,
                name: 'Psy',
            },
            {
                id: 2,
                name: 'Minions',
            },
            {
                id: 3,
                name: 'Diet',
            },
        ],
    },
    {
        id: 3,
        name: 'Videos merite',
        seen: false,
        realized: true,
        programs: [
            {
                id: 1,
                name: 'Organique',
            },
            {
                id: 2,
                name: 'Minions',
            },
            {
                id: 3,
                name: 'RAS',
            },
        ],
    },
    {
        id: 4,
        name: 'Videos JMJ',
        seen: true,
        realized: false,
        programs: [
            {
                id: 1,
                name: 'APA',
            },
            {
                id: 2,
                name: 'Minions',
            },
            {
                id: 3,
                name: 'MahaBG',
            },
        ],
    },
];

const syntheseWorkshops = [
    {
        name: 'Ateliers de teste',
        id: 1,
        realized: true,
        programs: [
            {
                id: 1,
                name: 'APA',
            },
            {
                id: 2,
                name: 'Minions',
            },
            {
                id: 3,
                name: 'MahaBG',
            },
        ],
    },
    {
        name: 'Atelier des courageux',
        id: 2,
        realized: false,
        programs: [
            {
                id: 1,
                name: 'Organique',
            },
            {
                id: 2,
                name: 'Minions',
            },
            {
                id: 3,
                name: 'RAS',
            },
        ],
    },
    {
        name: 'Ateliers bien fait',
        id: 3,
        realized: true,
        programs: [
            {
                id: 1,
                name: 'APA',
            },
            {
                id: 2,
                name: 'Minions',
            },
            {
                id: 3,
                name: 'MahaBG',
            },
        ],
    },
    {
        name: 'Atelier sans faute',
        id: 4,
        realized: false,
        programs: [
            {
                id: 1,
                name: 'Organique',
            },
            {
                id: 2,
                name: 'Minions',
            },
            {
                id: 3,
                name: 'RAS',
            },
        ],
    },
];

export { syntheseWorkshops, syntheseVideos, fakeWorkshops, consultationType };
