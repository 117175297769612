import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FC } from 'react';
import { baseColors, baseFontFamily } from '../../utils/constants/baseStyles';

interface CustomBadgeKVProps {
    value: string;
    label: string;
}

const useStyles = makeStyles({
    container: {
        width: 150,
        paddingBottom: 10,
        paddingTop: 10,
        justifyContent: 'center',
        alignItems: 'center',
        justifyItems: 'center',
        background: baseColors.green.light,
        borderRadius: 20,
        marginBottom: 10,
    },
});

export const CustomBadgeKV: FC<CustomBadgeKVProps> = (props) => {
    const classes = useStyles();
    const { label, value } = props;
    return (
        <div className={classes.container}>
            <Grid container direction={'row'} justifyContent={'center'}>
                <Typography color={baseColors.black} fontSize={14} fontFamily={baseFontFamily.OpenSansSemiBold}>
                    {`${label} : `}
                </Typography>

                <Typography
                    style={{ marginLeft: 5 }}
                    color={baseColors.green.primary}
                    fontSize={14}
                    fontFamily={baseFontFamily.OpenSansSemiBold}
                >
                    {value}
                </Typography>
            </Grid>
        </div>
    );
};
