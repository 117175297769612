import { Dispatch, FC, SetStateAction, useContext, useEffect, useState } from 'react';
import useStyles from './styles';
import { ReportItem } from 'src/components/ReportItem/ReportItem';
import CustomLoader from 'src/components/CustomLoader/CustomLoader';
import { EvaluationReportDetails } from './EvaluationReportDetails';
import { PatientContext } from 'src/providers/PatientProvider';
import CustomEmptyData from 'src/components/CustomEmptyData';
import useEvaluationHooks from 'src/hooks/useEvaluationHooks';

interface ObservationsProps {
    setFetching: Dispatch<SetStateAction<boolean>>;
}

export const EvaluationReportList: FC<ObservationsProps> = ({ setFetching }) => {
    const classes = useStyles();
    const { selectedPatient } = useContext(PatientContext);
    const patientId = selectedPatient?.id ?? 0;
    const [open, setOpen] = useState(false);
    const [reportData, setReportData] = useState(null);
    const { updateList, handleFetchReports, reports, fetching, handleExportReportPdf } = useEvaluationHooks({
        setListFetching: setFetching,
    });

    const detail = (data: any) => {
        setReportData(data);
        setOpen(true);
    };

    useEffect(() => {
        handleFetchReports();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patientId, updateList]);

    const isEmpty = reports?.length === 0;

    return (
        <div className={classes.container}>
            <EvaluationReportDetails patientId={patientId} open={open} reportData={reportData} setOpen={setOpen} />

            <div style={{ marginTop: 20, overflow: 'auto' }}>
                {fetching ? (
                    <div style={{ width: '100%', alignContent: 'center' }}>
                        <CustomLoader IsclassicList />
                    </div>
                ) : isEmpty ? (
                    <CustomEmptyData
                        marginTop={100}
                        labelFontSize={18}
                        label="Aucun compte-rendu disponible actuellement"
                    />
                ) : (
                    reports?.map((item: any, i) => (
                        <ReportItem
                            key={i}
                            onPress={() => detail(item)}
                            handlePress={() => handleExportReportPdf(item)}
                            title={item.name}
                        />
                    ))
                )}
            </div>
        </div>
    );
};
