import moment from 'moment';
import { useContext, useState } from 'react';
import { PatientContext } from 'src/providers/PatientProvider';
import EvaluationServices from 'src/services/Evaluations';
import { ChartItem } from 'src/views/Patients/PatientsDetails/Evaluations/EvaluationAxisContent/EvaluationAxisContent';

const useConstantHooks = () => {
    const [fetchingChartData, setFetchingChartData] = useState<boolean>(false);
    const [chartData, setChartData] = useState<ChartItem[]>([]);
    const { selectedPatient } = useContext(PatientContext);
    const [selectedConstant, setSelectedConstant] = useState({ id: 0, name: 'Nom graphique' });
    const [constantNames, setConstantNames] = useState<any[]>([]);
    const getDayAndMonth = (date: string) => moment(new Date(date)).format('DD/MM');

    const fetchConstants = () => {
        setFetchingChartData(true);
        EvaluationServices.GetConstantsHistory(selectedPatient?.id ?? 0)
            .then((response) => {
                const dataRes = response?.data?.data;
                const dataOptimized = Array.from(dataRes, (constante: any) => ({
                    id: constante?.id,
                    name: constante?.name,
                    useBarChart: constante?.data?.length === 1,
                    series: {
                        xData: Array.from(constante?.categories, (date: string) => getDayAndMonth(date)),
                        yData: constante?.data,
                    },
                }));

                const names = dataRes?.map((constant: any) => ({
                    id: constant?.id,
                    name: constant?.name,
                }));

                setSelectedConstant(names?.[0]);
                setConstantNames(names);
                setChartData(dataOptimized);
                setFetchingChartData(false);
            })
            .catch((e) => setFetchingChartData(false));
    };

    return {
        fetchConstants,
        setSelectedConstant,
        fetchingChartData,
        chartData,
        selectedConstant,
        constantNames,
    };
};

export default useConstantHooks;
