export const transformFilters = (filterData: any, planningBy?: 'speciality' | 'room') => {
    const planningFiltersData =
        (filterData &&
            filterData.length > 0 &&
            filterData.map((item: any) => ({
                id: planningBy === 'room' ? item?.upstairs?.id : item.id,
                label: item.name,
            }))) ??
        [];
    return planningFiltersData;
};
