import { FC, useEffect, useMemo, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { baseColors } from 'src/utils/constants/baseStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CustomCircleLoader from 'src/components/CustomLoader/CustomCircleLoader';
import { sxStyles } from './styles';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import CustomEmptyData from 'src/components/CustomEmptyData/CustomEmptyData';
import { AddSkillProgram } from './AddSkillProgram/AddSkillProgram';
import { SkillItem } from 'src/interfaces/interfaces';
import usePrograms from 'src/hooks/usePrograms';
import { SkillItemContent } from 'src/views/Settings/Skills/SkillItem/SkillItem';

interface IProps {
    program: { id: number; modules: Array<any>; workshops: Array<any> };
    loadingProgram: boolean;
}

export const ProgramSkill: FC<IProps> = (props) => {
    const { program, loadingProgram } = props;
    const tableHeaders = [{ name: '' }, { name: 'Compétence' }, { name: '' }];
    const programId = program?.id ?? 0;
    const [updateList, setUpdateList] = useState(false);
    const [modalFormOpen, setModalFormOpen] = useState(false);
    const toggleModal = () => setModalFormOpen((prev: boolean) => !prev);
    const [skill, setSkill] = useState<SkillItem>({} as SkillItem);
    const { programSkills, fetching, handleFetchProgramSkills } = usePrograms({});
    const [preselectedSkillIds, setPreselectedSkillIds] = useState<Set<number>>(new Set());

    useEffect(() => {
        handleFetchProgramSkills({ programId });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [programId, updateList]);

    useMemo(() => {
        const ids = programSkills.map((skillItem) => skillItem.id);
        setPreselectedSkillIds(new Set(ids));
    }, [programId, programSkills]);

    const loading = fetching || loadingProgram;
    const isEmpty = programSkills.length === 0;

    return (
        <Box sx={sxStyles.container}>
            <div
                style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around' }}
            >
                <Typography sx={sxStyles.title}>Liste des compétences du programme</Typography>

                <CustomLoadingButton
                    style={{ backgroundColor: baseColors.orange.primary }}
                    loading={false}
                    handleClick={toggleModal}
                    label="Ajouter une compétence"
                />
            </div>

            {loading ? (
                <Box sx={sxStyles.loading}>
                    <CustomCircleLoader height={50} width={50} />
                    <Typography sx={sxStyles.loadingLabel}>Chargement en cours...</Typography>
                </Box>
            ) : isEmpty ? (
                <CustomEmptyData />
            ) : (
                <TableContainer style={{ overflow: 'auto', maxHeight: 'calc(100vh - 200px)', marginBottom: 150 }}>
                    <Table stickyHeader sx={{ minWidth: 300 }} aria-label="role table">
                        <TableHead>
                            <TableRow>
                                {tableHeaders.map((header, index) => (
                                    <TableCell key={index} sx={{ color: baseColors.green.primary }}>
                                        {header.name}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {programSkills.map((skill: SkillItem, index: Number) => (
                                <SkillItemContent
                                    isForProgram
                                    key={index.toString()}
                                    skill={skill}
                                    toggleModal={toggleModal}
                                    setSkill={setSkill}
                                    setUpdateProgramList={setUpdateList}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            <AddSkillProgram
                programId={programId}
                open={modalFormOpen}
                skill={skill}
                preselectedSkillIds={preselectedSkillIds}
                setSkill={setSkill}
                toggleModal={toggleModal}
                setUpdateList={setUpdateList}
            />
        </Box>
    );
};
