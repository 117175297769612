import { Box } from '@mui/system';
import { useContext, useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import SecuritySubMenuBar from 'src/components/SecuritySubMenuBar/SecuritySubMenuBar';
import { ROUTES } from 'src/routes/routes';
import RolePanel from '../Role/RolePanel';
import FeaturePanel from '../Feature/FeaturePanel';
import PermissionPanel from '../Permission/PermissionPanel';
import AuthorizationPanel from '../Authorization/AuthorizationPanel';
import { AUTHORIZATION_CODES } from 'src/utils/constants/constants';
import { PermissionContext } from 'src/providers/PermissionProvider';
import UserHistoryPanel from '../UserHistory/UserHistoryPanel';
import ConnexionPanel from './../Connexion/ConnexionPanel';
import {
    baseBorderRadius,
    baseColors,
    baseFontFamily,
    baseFontSize,
    baseScreenHeight,
} from 'src/utils/constants/baseStyles';
import { CustomText } from 'src/components/Text/CustomText';

export const tableCellText = (label: string | number) => <CustomText fontSize={baseFontSize.mini}>{label}</CustomText>;
export const tableCellHeadText = (label: string | number) => (
    <CustomText color={baseColors.green.primary} fontFamily={baseFontFamily.OpenSansBold} fontSize={baseFontSize.mini}>
        {label}
    </CustomText>
);

const SecurityManagement = () => {
    const { url } = useRouteMatch();
    const [paneIndex, setPaneIndex] = useState<number>(0);
    const { permissions } = useContext(PermissionContext);
    let history = useHistory();

    useEffect(() => {
        if (permissions) {
            if (url.includes(ROUTES.roles) && permissions.includes(AUTHORIZATION_CODES.listRole)) {
                setPaneIndex(0);
            } else if (url.includes(ROUTES.features) && permissions.includes(AUTHORIZATION_CODES.listFeature)) {
                setPaneIndex(1);
            } else if (url.includes(ROUTES.permissions) && permissions.includes(AUTHORIZATION_CODES.listPermission)) {
                setPaneIndex(2);
            } else if (
                url.includes(ROUTES.authorizations) &&
                permissions.includes(AUTHORIZATION_CODES.listAuthorization)
            ) {
                setPaneIndex(3);
            } else if (
                url.includes(ROUTES.user_history)
                //&& permissions.includes(AUTHORIZATION_CODES.listUserHistory)
            ) {
                setPaneIndex(4);
            } else if (url.includes(ROUTES.connexions) && permissions.includes(AUTHORIZATION_CODES.listRole)) {
                setPaneIndex(5);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url]);

    const displaySubMenus = () => {
        if (
            !permissions.filter((item: any) =>
                [
                    AUTHORIZATION_CODES.listAuthorization,
                    AUTHORIZATION_CODES.listFeature,
                    AUTHORIZATION_CODES.listPermission,
                    AUTHORIZATION_CODES.listRole,
                ].includes(item),
            )
        ) {
            history.push(ROUTES.notFound);
        }

        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    ...baseScreenHeight.defaultHeight,
                    ...baseBorderRadius.allSide,
                }}
            >
                <Box
                    style={{ overflow: 'scroll' }}
                    sx={{
                        minWidth: '170px',
                        backgroundColor: 'white',
                        flex: 1.5,
                        borderRadius: 2,
                        marginRight: 1,
                    }}
                >
                    <SecuritySubMenuBar />
                </Box>

                <Box
                    sx={{
                        backgroundColor: baseColors.white,
                        flex: 8,
                        borderRadius: 2,
                    }}
                >
                    {(() => {
                        let pane;
                        if (paneIndex === 0) {
                            pane = <RolePanel />;
                        } else if (paneIndex === 1) {
                            pane = <FeaturePanel />;
                        } else if (paneIndex === 2) {
                            pane = <PermissionPanel />;
                        } else if (paneIndex === 3) {
                            pane = <AuthorizationPanel />;
                        } else if (paneIndex === 4) {
                            pane = <UserHistoryPanel />;
                        } else if (paneIndex === 5) {
                            pane = <ConnexionPanel />;
                        }
                        return pane;
                    })()}
                </Box>
            </Box>
        );
    };
    return <>{displaySubMenus()}</>;
};

export default SecurityManagement;
