import { Pathology } from 'src/interfaces/interfaces';
import axios from '../AxiosInstance';

type GetPathologyParams = {
    keywords?: string;
    limit?: number;
    page?:number;
    paginated?: boolean;
};

const getProgramTypes = ({ keywords, limit,page,paginated=false }: GetPathologyParams) => {
    let additionalUrl: string = '';
    additionalUrl += `?paginated=${paginated}`;
    if (keywords) {
        additionalUrl += `&keywords=${keywords}`;
    }

    if (limit) {
        additionalUrl += `&limit=${limit}`;
    }

    if (page) {
        additionalUrl += `&page=${page}`;
    }

    return axios.get('api/programTypes' + additionalUrl);
};


const postProgramType = (data: Pathology) => axios.post('api/programTypes', { ...data });

const deleteProgramType = (programTypeId: number) => axios.delete(`api/programTypes/${programTypeId}`);

const updateProgramType = (payload: Pathology) => axios.put(`api/programTypes/${payload?.id}`, { ...payload });

const ProgramTypesService = {
    postProgramType,
    getProgramTypes,
    updateProgramType,
    deleteProgramType,
};

export default ProgramTypesService;
