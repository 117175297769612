import EmailIcon from '@mui/icons-material/Email';
import { baseColors } from 'src/utils/constants/baseStyles';
import { ROUTES } from 'src/routes/routes';
import IconSettingsUsers from '../CustomIcon/IconSettingsUsers';
import Programme from '../CustomIcon/Programme';
import Agenda from '../CustomIcon/Agenda';
import IconSettings from 'src/components/CustomIcon/IconSettings';
import QuizIcon from '@mui/icons-material/Quiz';
import ArticleIcon from '@mui/icons-material/Article';
import { FC } from 'react';
import MenuItemContainer from './MenuItemContainer';

const CoordinatorSubMenus: FC = () => {
    const settingsSubMenuData = [
        {
            id: 1,
            icon: <IconSettingsUsers height={20} width={20} />,
            iconActive: <IconSettingsUsers height={20} width={20} />,
            label: 'Utilisateurs',
            path: ROUTES.users,
            featureCode: 'front_display_parameter_users',
        },
        {
            id: 3,
            icon: <Programme height={20} width={20} />,
            iconActive: <IconSettingsUsers height={20} width={20} />,
            label: 'Modules',
            path: ROUTES.modules,
        },
        {
            id: 4,
            icon: <Programme height={20} width={20} />,
            iconActive: <IconSettingsUsers height={20} width={20} />,
            label: 'Ateliers',
            path: ROUTES.workshops,
        },
        {
            id: 5,
            icon: <Programme height={20} width={20} />,
            iconActive: <IconSettingsUsers height={20} width={20} />,
            label: 'Consultations',
            path: ROUTES.consultationSettings,
        },
        {
            id: 6,
            icon: <Agenda height={20} width={20} />,
            iconActive: <IconSettingsUsers height={20} width={20} />,
            label: 'Agenda',
            path: ROUTES.agendaSettings,
            featureCode: 'front_display_parameter_angenda',
        },
        {
            id: 7,
            icon: <IconSettings height={20} width={20} />,
            iconActive: <IconSettingsUsers height={20} width={20} />,
            label: 'Général',
            path: ROUTES.generalSettings,
        },
        {
            id: 8,
            icon: <Programme height={20} width={20} sx={{ color: baseColors.green.primary }} />,
            iconActive: <Programme height={20} width={20} />,
            label: 'Compétences',
            path: ROUTES.SkillSettings,
        },
        {
            id: 9,
            icon: <Programme height={20} width={20} sx={{ color: baseColors.green.primary }} />,
            iconActive: <Programme height={20} width={20} />,
            label: 'Objectifs',
            path: ROUTES.GoalSettings,
        },
        {
            id: 10,
            icon: <Programme height={20} width={20} sx={{ color: baseColors.green.primary }} />,
            iconActive: <Programme height={20} width={20} />,
            label: 'Spécialités',
            path: ROUTES.specialities,
        },
        {
            id: 11,
            icon: <EmailIcon sx={{ color: baseColors.green.primary }} height={20} width={20} />,
            iconActive: <IconSettingsUsers height={20} width={20} />,
            label: 'Contact',
            path: ROUTES.contactSettings,
            featureCode: 'front_display_security_contact_sidebar_menu',
        },
        {
            id: 12,
            icon: <ArticleIcon height={20} width={20} sx={{ color: baseColors.green.primary }} />,
            iconActive: <ArticleIcon height={20} width={20} />,
            label: 'Contenus',
            path: ROUTES.articleSettings,
        },
        {
            id: 13,
            icon: <Programme height={20} width={20} />,
            iconActive: <IconSettingsUsers height={20} width={20} />,
            label: 'Pathologies',
            path: ROUTES.pathologySettings,
        },
        {
            id: 14,
            icon: <QuizIcon height={20} width={20} sx={{ color: baseColors.green.primary }} />,
            iconActive: <QuizIcon height={20} width={20} />,
            label: 'Quiz',
            path: ROUTES.quizSettings,
        },
        // {
        //     id: 10,
        //     icon: <DisplaySettings height={20} width={20} sx={{ color: baseColors.green.primary }} />,
        //     iconActive: <DisplaySettings height={20} width={20} />,
        //     label: 'Équipe',
        //     path: ROUTES.ETFSettings,
        // }
    ];

    return <MenuItemContainer items={settingsSubMenuData} />;
};

export default CoordinatorSubMenus;
