import { Dispatch, FC, SetStateAction } from 'react';
import { Box } from '@mui/system';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CustomEvaluationCRInput } from './CustomEvaluationCRInput/CustomEvaluationCRInput';
import { ReportPayload } from 'src/interfaces/interfaces';

interface ReportFormProps {
    reportPayload: ReportPayload;
    setReportPayload: Dispatch<SetStateAction<ReportPayload>>;
}

export const EvaluationReportForm: FC<ReportFormProps> = ({ reportPayload, setReportPayload }) => {
    const classes = useStyles();
    return (
        <Box className={classes.container}>
            <Grid container direction={'column'} spacing={7}>
                <Grid item>
                    <Grid container direction={'row'} justifyContent={'space-between'}>
                        <Grid item flex={2}>
                            <CustomEvaluationCRInput
                                label="Quels sont les motifs d'entrée dans le programme ?"
                                keyValue="programEnteringReason"
                                payload={reportPayload}
                                errorMessage="Raison d'entrée invalide"
                                setPayload={setReportPayload}
                            />

                            <CustomEvaluationCRInput
                                label="Quels sont les antécédents médicaux ?"
                                keyValue="medicalAntecedent"
                                payload={reportPayload}
                                errorMessage="Antecidents médicaux invalide"
                                setPayload={setReportPayload}
                            />

                            <CustomEvaluationCRInput
                                label="Quels sont ses traitements habituels ?"
                                keyValue="treatments"
                                payload={reportPayload}
                                errorMessage="Traitements habituels invalide"
                                setPayload={setReportPayload}
                            />
                        </Grid>

                        <Grid item flex={2}>
                            <CustomEvaluationCRInput
                                label="Quels sont les conclusions du diagnostic éducatif ?"
                                keyValue="educationalDiagnosisConclusion"
                                payload={reportPayload}
                                errorMessage="Raison d'entrée invalide"
                                setPayload={setReportPayload}
                            />

                            <CustomEvaluationCRInput
                                label="Quel est le contenu du programme suivi ?"
                                keyValue="followedProgramContent"
                                payload={reportPayload}
                                errorMessage="Raison d'entrée invalide"
                                setPayload={setReportPayload}
                            />

                            <CustomEvaluationCRInput
                                label="Quelles sont les conclusions de l'entretien d'évaluation ?"
                                keyValue="interviewConclusion"
                                payload={reportPayload}
                                errorMessage="Raison d'entrée invalide"
                                setPayload={setReportPayload}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

const useStyles = makeStyles({
    container: {
        padding: 10,
        paddingTop: 25,
        flexGrow: 1,
        overflowY: 'scroll',
    },
});
