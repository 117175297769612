import { baseColors } from 'src/utils/constants/baseStyles';

const sxStyles : any = {
    container: {
        padding: 1,
        borderRadius: 2,
        cursor: 'pointer',
        border: `1px solid ${baseColors.green.primary}`,
    }
}

export default sxStyles;
