import { PostSkillPayload } from 'src/interfaces/interfaces';
import axios from '../AxiosInstance';

const GetSkills = () => {
    let additionalUrl: string = '';
    return axios.get('api/skills' + additionalUrl);
};

const PostNewSkill = ({ payload }: { payload: PostSkillPayload }) => {
    return axios.post('api/skills', payload);
};

const EditSkill = ({ skillId, payload }: { skillId: number; payload: PostSkillPayload }) => {
    return axios.put(`api/skills/${skillId}`, { ...payload });
};

const DeleteSkill = ({ skillId }: { skillId: number }) => {
    return axios.delete(`api/skills/${skillId}`);
};

const SkillService = {
    GetSkills,
    PostNewSkill,
    DeleteSkill,
    EditSkill,
};

export default SkillService;
