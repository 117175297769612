import { Typography, Box } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { baseColors } from '../../utils/constants/baseStyles';
import useStyles from './styles';
import AgendaVisioDetails from '../CustomIcon/AgendaVisioDetails';
import AgendaPresentielDetail from '../CustomIcon/AgendaPresentielDetail';
import EventService from 'src/services/Calendar/events.service';
import DiagnosticModal from 'src/components/CustomModal/DiagnosticModal';
import { UserContext } from 'src/providers/UserProvider';
import { EventPlanningViewType } from 'src/interfaces/interfaces';

interface PatientRdvCardProps {
    item: any;
    setUpdateList?: any;
    isGreen?: boolean;
    selectedIntervenants?: any;
    isForProgram?: boolean;
    dataForWeeklyEvent?: any;
    viewType?: EventPlanningViewType['viewType'];
}

const PatientRdvCard: FC<PatientRdvCardProps> = (props) => {
    const classes = useStyles();
    const [open, setOpen] = useState<boolean>(false);
    const toggleModal = () => setOpen(!open);
    const { item, isGreen, selectedIntervenants, setUpdateList, isForProgram, dataForWeeklyEvent, viewType } = props;
    const [eventData, setEventData] = useState<boolean>(false);
    const { isCoordinator } = useContext(UserContext);
    const { name, label, timeStart, timeEnd } = item;
    const backgroundColor = !isGreen ? baseColors.orange.light : baseColors.green.light;
    let readOnly = selectedIntervenants?.length > 0 || (isForProgram && !isCoordinator);
    const icon = item?.itemData?.isInSitu ? (
        <AgendaPresentielDetail width={30} height={30} isGreen={isGreen} />
    ) : (
        <AgendaVisioDetails width={30} height={30} isGreen={isGreen} />
    );

    const color = !isGreen ? baseColors.orange.primary : baseColors.green.primary;

    const handleFetchEventData = async () => {
        await EventService.getEvent(item?.itemData?.eventId)
            .then((response) => {
                setEventData(response?.data?.data);
            })
            .catch((err: any) => {
                console.log(err);
            });
    };

    useEffect(() => {
        handleFetchEventData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item?.itemData?.eventId]);

    return (
        <>
            <Box style={{ backgroundColor: backgroundColor }} className={classes.container} onClick={toggleModal}>
                <Box className={classes.left}>
                    <Box className={classes.time}>
                        <div>{icon}</div>
                    </Box>
                    <Box className={classes.title}>
                        <Typography style={{ marginTop: 0 }} color={baseColors.black} fontSize={12}>
                            {name}
                        </Typography>
                        <Typography color={color} fontSize={10}>{`${timeStart} - ${timeEnd}`}</Typography>
                        <Typography color={baseColors.black} fontSize={12}>
                            {label}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <DiagnosticModal
                open={open}
                toggleModal={toggleModal}
                data={eventData}
                updateEventList={setUpdateList}
                dataForWeeklyEvent={dataForWeeklyEvent}
                viewType={viewType}
                readOnly={readOnly}
            />
        </>
    );
};

export default PatientRdvCard;
