import { Box } from '@mui/material';
import { FC, useContext, useState, useEffect } from 'react';
import useStyles from './styles';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { AgendaContext } from 'src/providers/AgendaProvider';
import { useHistory } from 'react-router-dom';
import { defaultEventDate, gotoSeletedDate } from 'src/components/CustomFullCalendar/calendarUtils';
import { transformDbDate } from 'src/utils/utils';
import { spliDateFromUrl } from '../../Planning/PlanningFilters/PlanningDateLogic';


const CalendarContent: FC = (props) => {
    const classes = useStyles();
    const { currentDate, calendarRef,calendarValue , setYearAndMonth } = useContext(AgendaContext);
    const [value, setValue] = useState<Date>(currentDate);
    const history = useHistory();
    const handleChange = (newValue: any) => {
        setValue(newValue);
        setYearAndMonth(defaultEventDate(newValue));
        calendarRef && gotoSeletedDate({ calendarComponentRef: calendarRef, date: transformDbDate(newValue) });
    };

    useEffect(() => {
        const parameter = history.location.search;
        if (parameter.length) {
            const eventDate = spliDateFromUrl(history);
            setValue(new Date(eventDate));
        } else {
            setValue((prev) => {
                if (currentDate) {
                    return currentDate;
                } else {
                    return new Date();
                }
            });
        }
    }, [currentDate]);

    useEffect(() =>{
        setValue(calendarValue)
    },[calendarValue])

    return (
        <Box className={classes.container}>
            <Calendar
                className={classes.calendar}
                prev2Label={null}
                next2Label={null}
                formatShortWeekday={(locale, date) => date.toLocaleString(locale, { weekday: 'narrow' })}
                onChange={handleChange}
                value={value}
                key={value.getTime()}
                // tileContent={renderDateEventPoints}
            />
        </Box>
    );
};

export default CalendarContent;
