import { FC, useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { CustomText } from '../../../../components/Text/CustomText';
import { CustomSelect } from '../../../../components/InputField/CustomSelect';
import { Spacer } from 'src/components/Spacer/Spacer';
import { handleChange, handleFectchSpecialities } from '../UserUtiles';

interface ProfessionalFormProps {
    payload: any;
    setPayload: any;
    lockUserInput?: any;
    selectedUserData?: any;
}

export const ProfessionalForm: FC<ProfessionalFormProps> = (props) => {
    const { setPayload, lockUserInput, selectedUserData } = props;
    const [specialites, setSpecialites] = useState<Array<any>>([]);
    const [fetching, setFetching] = useState<boolean>(true);

    useEffect(() => {
        handleFectchSpecialities({ setSpecialites, setFetching });
    }, []);

    const specialisteData = [{ id: 0, label: 'Sélectionner une spécialité' }, ...specialites];
    const speciality = specialisteData?.find((item: any) => item.id === selectedUserData?.specialityType?.id);

    return (
        <>
            <Spacer />

            <Grid lg={12} container direction="row" alignItems={'center'}>
                <Grid item>
                    <CustomText style={{ minWidth: '200px' }}>Spécialité :</CustomText>
                </Grid>
                <Grid item style={{ width: '61%' }}>
                    <CustomSelect
                        isLoading={fetching}
                        idSelect="speciality-type"
                        label=""
                        labelKey={'label'}
                        disabled={lockUserInput}
                        items={specialisteData}
                        defaultValue={speciality?.id ?? 0}
                        onChange={handleChange({ setPayload, inputKey: 'specialityTypeId' })}
                        width="100%"
                    />
                </Grid>
            </Grid>
        </>
    );
};
