import { Popover } from '@mui/material';
import React, { Dispatch, SetStateAction, useState } from 'react';
import CustomCircleLoader from 'src/components/CustomLoader/CustomCircleLoader';
import { OpenSansSemiBold } from 'src/components/Text/OpenSansSemiBold';
import { baseColors } from 'src/utils/constants/baseStyles';

interface IProps {
    anchorEl: HTMLButtonElement | null;
    setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
    constantNames: { id: number; name: string }[];
    selectedConstant: { id: number; name: string };
    setselectedConstant: Dispatch<SetStateAction<{ id: number; name: string }>>;
}

const ChartNamePopover: React.FC<IProps> = ({
    anchorEl,
    setAnchorEl,
    constantNames,
    selectedConstant,
    setselectedConstant,
}) => {
    const open = Boolean(anchorEl);
    const id = open ? 'filter-popover' : undefined;
    const handleClose = () => setAnchorEl(null);
    const [loading, setLoading] = useState(false);

    const handleClick = (constant: { id: number; name: string }) => {
        setselectedConstant(constant);
        handleClose();
    };

    const noConstant = constantNames?.length === 0;

    return (
        <div
            style={{
                backgroundColor: baseColors.white,
            }}
        >
            <Popover
                id={id}
                style={{
                    padding: 10,
                }}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <div style={{ margin: '20px 10px 10px 20px', overflowY: 'scroll' }}>
                    {loading ? (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginRight: 10,
                            }}
                        >
                            <CustomCircleLoader height={25} width={25} />
                        </div>
                    ) : noConstant ? (
                        <OpenSansSemiBold style={{ fontSize: 12, top: -10 }}>
                            Pas de contenu pour l'instant
                        </OpenSansSemiBold>
                    ) : (
                        constantNames?.map((item: { id: number; name: string }) => (
                            <div
                                onClick={() => handleClick(item)}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    marginBottom: 10,
                                    cursor: 'pointer',
                                }}
                            >
                                <OpenSansSemiBold style={{ fontSize: 12, top: -10 }}>
                                    {item?.name || '---'}
                                </OpenSansSemiBold>
                            </div>
                        ))
                    )}
                </div>
            </Popover>
        </div>
    );
};

export default ChartNamePopover;
