import { PatientDiscussion } from 'src/interfaces/interfaces';
import { isValidMail } from 'src/utils/helpers/InputValidator';
import { fullName } from 'src/utils/utils';

const optimizePatientsData = (patients: any) =>
    Array.from(patients, (patient: any) => ({
        text: fullName(patient.firstName, patient.lastName),
        value: fullName(patient.firstName, patient.lastName),
        url: patient.email,
    }));

const checMentionnedUsers = (discussion: string, patientsDataToFilter: PatientDiscussion[]) => {
    const splitedDiscussion = discussion.split('@');
    const checkMentionString = splitedDiscussion.filter((item: any) => item.includes(' '));
    const filteredData = checkMentionString.map((item: any) => {
        const splitedItem = item.split(' ');
        return `${splitedItem?.[0]}-${splitedItem?.[1]}`.toLocaleLowerCase();
    });
    const mentionedUsers =
        patientsDataToFilter?.length > 0
            ? patientsDataToFilter?.filter((patient: PatientDiscussion) =>
                  filteredData.some((item: string) => patient.name === item),
              )
            : [];

    return mentionedUsers;
};

const checkMailUsers = (email: any) => {
    let data: any = [];
    const splitedEmail = email.split(' ');
    splitedEmail.map((item: any) => {
        if (isValidMail(item)) {
            data.push(item);
        }
    });
    return data;
};

export { optimizePatientsData, checMentionnedUsers, checkMailUsers };
