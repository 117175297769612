import React, { useState, useEffect } from 'react';
import { Grid } from "@mui/material";
import { CustomText } from "../Text/CustomText";
import { InputField } from "../InputField/InputField";
import { UserAxisItem } from "src/interfaces/interfaces";
import { baseColors, baseFontFamily, baseFontSize } from "src/utils/constants/baseStyles";
import useDebounceWithDelay from "src/hooks/useDebounceWithDelay";

interface QuestionPrécisionProps {
    userDEResponse: UserAxisItem;
    precisionAnswer?: any;
    index: number;
    setPayload: Function;
    toggleModal?: Function;
}

const QuestionPrecision: React.FC<QuestionPrécisionProps> = ({
    userDEResponse, precisionAnswer, index, setPayload
}) => {
    const [inputValue, setInputValue] = useState(precisionAnswer);
    const debouncedInputValue = useDebounceWithDelay(inputValue, 900); // 500 ms delay

    useEffect(() => {
        setInputValue(precisionAnswer)
    }, [precisionAnswer]);

    useEffect(() => {
        if(debouncedInputValue !== precisionAnswer)
        updatePayload(debouncedInputValue);
    }, [debouncedInputValue]);

    const updatePayload = (value: string) => {
        setPayload((prev: any) => {
            const updatedPayload = {...prev};
            if (updatedPayload.responses && updatedPayload.responses.length > index && updatedPayload.responses[index]) {
                updatedPayload.responses[index].precisionAnswer = value;
            }
            return updatedPayload;
        });
    };

    return (
        <Grid
            container
            direction="column"
            alignItems={'start'}
            justifyContent="start"
            gap='10px'
        >
            <Grid item>
                <CustomText
                    style={{ width: '100%' }}
                    fontSize={baseFontSize.mini}
                    fontFamily={baseFontFamily.OpenSansSemiBold}
                >
                    {userDEResponse.survey.precision}
                </CustomText>
            </Grid>
            <InputField
                label={''}
                multiline
                onChange={(value: any) => setInputValue(value)}
                errorMessage=''
                placeholder=''
                height='auto'
                width='100%'
                value={inputValue ?? precisionAnswer}
            />
        </Grid>
    );
};

export default QuestionPrecision;
