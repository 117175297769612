export enum ActionType {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
}

interface actionLogin {
  type: ActionType.LOGIN;
  payload: string;
}

interface actionLogout {
  type: ActionType.LOGOUT;
  payload?: string;
}

export type Action = actionLogin | actionLogout;
