import * as React from 'react'

function IconDiagnostic(props: any) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={20}
			height={20}
			fill='none'
			viewBox='0 0 20 20'
			{...props}
		>
			<path
				fill='#fff'
				d='M9.914 0C7.1 0 4.499 1.22 2.382 3.015a3.269 3.269 0 00-.904 3.749L2.77 9.863a7.847 7.847 0 0114.375.196l1.377-3.303a3.266 3.266 0 00-.89-3.737C15.483 1.177 12.813 0 9.914 0zm1.557 3.525h-.907v.95H9.298v-.95h-.952V2.257h.952v-.952h1.266v.952h.907v1.268z'
			/>
			<path
				fill='#fff'
				d='M13.396 7.156l-.002.002s-2.262 3.547-6.14 4.833a.367.367 0 01-.396-.585c.17-.201.344-.426.497-.662a.5.5 0 00-.55-.753c-1.192.32-3.12 1.218-3.758 3.712a6.894 6.894 0 1010.349-6.547z'
			/>
		</svg>
	)
}

export default IconDiagnostic
