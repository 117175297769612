import { FC } from 'react';
import useStyles from './styles';
import CustomCircleLoader from 'src/components/CustomLoader/CustomCircleLoader';

interface AxisLoaderContainerProps {
    loading: boolean;
    educationalDiagnosisId?: number;
}

const AxisLoaderContainer: FC<AxisLoaderContainerProps> = ({ educationalDiagnosisId, loading, children }) => {
    const classes = useStyles();

    if (loading) {
        return (
            <div
                style={{
                    overflowY: 'scroll',
                    height: 'calc(100vh - 486px)',
                }}
                className={classes.loading}
            >
                <CustomCircleLoader height={10} width={50} />
            </div>
        );
    }

    return (
        <div
            style={{
                overflowY: 'scroll',
                height: `calc(100vh - 486px)`,
            }}
        >
            {children}
        </div>
    );
};

export default AxisLoaderContainer;
