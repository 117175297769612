import { Typography, Box, Divider, Checkbox } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import CustomEmptyData from 'src/components/CustomEmptyData';
import { CustomSelect } from 'src/components/InputField/CustomSelect';
import { SearchInputField } from 'src/components/InputField/SearchInputField';
import useFetchUpstairs from 'src/hooks/useFetchUpstairs';
import { AgendaContext } from 'src/providers/AgendaProvider';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import { allOptions, checkValueExistance, handleAddAllValue, handleSearchItem } from '../FilterLogic';
import useStyles from './styles';

type RoomContentType = {
    selected: number[];
    setSelected: Function;
    selectedItems: number[];
};

const RoomFilterContent: FC<RoomContentType> = ({ selected, setSelected, selectedItems }) => {
    const classes = useStyles();
    const { setCheckedRooms } = useContext(AgendaContext);
    const [upstairs, setUpstairs] = useState<Array<any>>([]);
    const [rooms, setRooms] = useState<Array<any>>([]);

    const [filteredItems, setFilteredItems] = useState<Array<any>>([]);
    const [searchQuery, setSearchQuery] = useState<string>('');

    const { handleFetchUpstairs } = useFetchUpstairs(setUpstairs);
    const handleClick = (value: string) =>
        setSelected((prev: any) =>
            checkValueExistance({ prev, programId: value, setCheckedPrograms: setCheckedRooms, allItems: rooms }),
        );
    const handleChangeUpstair = (value: number) => {
        const upstairRooms = upstairs?.find((upstair: any) => +upstair.id === Number(value));
        setRooms(value === -1 ? [] : upstairRooms?.rooms);
    };

    const upstairItems = [{ id: -1, name: 'Sélectionner un étage ' }, ...upstairs];

    useEffect(() => {
        handleFetchUpstairs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const hasSearchkey = searchQuery?.trim()?.length > 0;
    const combinedData = hasSearchkey
        ? [allOptions, ...filteredItems]
        : rooms?.length > 0
        ? [allOptions, ...rooms]
        : [];

    const hasNoRoom = rooms?.length === 0;
    const showSecondTitle = selected?.includes(-1);

    useEffect(() => {
        if (selected?.length <= selectedItems?.length) {
            handleAddAllValue({ setSelected, selectedItems, combinedData });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected]);

    return (
        <>
            <Box className={classes.upstair}>
                <CustomSelect
                    onChange={(value: number) => handleChangeUpstair(value)}
                    labelKey="name"
                    label=""
                    items={upstairItems}
                    fontSize={12}
                    idSelect="room-name"
                />
            </Box>

            {!hasNoRoom && (
                <SearchInputField
                    onChange={(value: any) => {
                        setSearchQuery(value);
                        handleSearchItem({ items: rooms, searchkey: value, setItems: setFilteredItems });
                    }}
                    placeholder="Rechercher par nom"
                    value={searchQuery}
                />
            )}

            {hasNoRoom ? (
                <Box sx={{ textAlign: 'center' }}>
                    <CustomEmptyData marginTop={10} labelFontSize={12} height={25} width={25} />
                </Box>
            ) : hasSearchkey && filteredItems?.length === 0 ? (
                <CustomEmptyData labelFontSize={12} marginTop={10} height={40} width={40} />
            ) : (
                rooms?.length > 0 &&
                combinedData?.map((event: any, i: number) => (
                    <Box key={event.id}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            <Checkbox
                                onClick={() => handleClick(event.id)}
                                sx={{
                                    color: baseColors.green.primary,
                                    '&.Mui-checked': {
                                        color: baseColors.green.primary,
                                    },
                                }}
                                size="small"
                                checked={selected?.length > 0 && selected?.includes(event.id)}
                            />
                            <Typography
                                style={{
                                    fontFamily: baseFontFamily.OpenSansSemiBold,
                                }}
                                fontSize={12}
                                color={baseColors.black}
                                marginBottom={1}
                                marginTop={1}
                            >
                                {showSecondTitle && event.id === -1 ? event.secondTitle : event?.name ?? event?.title}
                            </Typography>
                        </Box>

                        {i === 0 && <Divider className={classes.divider} />}
                    </Box>
                ))
            )}
        </>
    );
};

export default RoomFilterContent;
