import { useState } from 'react';
import HdjDataService from 'src/services/Hdj/dayHospital.service';

const useFetchSpecialities = () => {
    const [fetchingSpecialities, setFetchingSpecialities] = useState(false);
    const [specialities, setSpecialities] = useState([]);

    const handleFetchSpecialities = () => {
        setFetchingSpecialities(true);
        HdjDataService.getHdjSpecialities()
            .then((response) => {
                const data = response?.data?.data?.items;
                setSpecialities(data);
                setFetchingSpecialities(false);
            })
            .catch((error) => {
                setFetchingSpecialities(false);
            });
    };

    return {
        handleFetchSpecialities,
        specialities,
        fetchingSpecialities,
    };
};

export default useFetchSpecialities;
