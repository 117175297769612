import { Grid, MenuItem, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FC, useContext, useEffect } from 'react';
import { useRouteMatch, useLocation } from 'react-router';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import { useHistory } from 'react-router-dom';
import { patientTabMenus } from 'src/utils/constants/constants';
import { EducationalDiagnosisContext } from 'src/providers/EducationalDiagnosisProvider';

const useStyles = makeStyles({
    container: {
        flexGrow: 1,
    },
});

export const PatientDetailsTab: FC = (props) => {
    const history = useHistory();
    const { url } = useRouteMatch();
    const location = useLocation();
    const classes = useStyles();
    const { setActiveDefaultTab } = useContext(EducationalDiagnosisContext);

    const onMenuSelected = (menu: any) => () => {
        setActiveDefaultTab(() => {
            if (menu.path === 'diagnostic') {
                return true;
            }
            return false;
        });
        if (!history.location.pathname.includes(menu.path)) history.push(`${url}/${menu.path}`);
    };

    const patientId: number = +window?.location?.pathname?.split('/')?.[3] || 0;

    useEffect(() => {
        history.push(window?.location?.pathname);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patientId]);

    const checkCurrentLocation = (menuPath: string) => location.pathname.startsWith(`${url}/${menuPath}`);

    return (
        <div className={classes.container}>
            <Grid
                alignItems="stretch"
                justifyContent="space-around"
                direction="row"
                container
                style={{
                    position: 'relative',
                    flexWrap: 'nowrap'
                }}
            >
                {patientTabMenus.map((menu) => (
                    <Grid item lg={2} xs={2} key={menu.id}>
                        <div
                            style={{
                                height: '100%',
                            }}
                        >
                            <MenuItem
                                style={{
                                    alignSelf: 'center',
                                    justifyContent: 'center',
                                    paddingTop: 10,
                                    paddingBottom: 10,
                                    borderBottomWidth: checkCurrentLocation(menu.path) ? 3 : 2,
                                    borderBottomStyle: 'solid',
                                    borderBottomColor: checkCurrentLocation(menu.path)
                                        ? baseColors.green.primary
                                        : baseColors.grey,
                                }}
                                onClick={onMenuSelected(menu)}
                            >
                                <Typography
                                    style={{
                                        // whiteSpace: 'normal',
                                        textAlign: 'center',
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                    }}
                                    color={
                                        checkCurrentLocation(menu.path) ? baseColors.green.primary : baseColors.black
                                    }
                                    fontSize="0.625rem"
                                    fontFamily={
                                        checkCurrentLocation(menu.path)
                                            ? baseFontFamily.OpenSansBold
                                            : baseFontFamily.OpenSansSemiBold
                                    }
                                >
                                    {menu.title.toUpperCase()}
                                </Typography>
                            </MenuItem>
                        </div>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
};
