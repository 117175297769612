import axios from '../AxiosInstance';

const getMyDayToNumber = () => {
    return axios
        .get('api/dashboards/myDayToNumber')
        .then((result: any) => {
            const response = JSON.parse(result.request.response);
            return response.data;
        })
        .catch((result: any) => {
            const response = result.request.response;
            return response;
        });
};

const getDailyPatients = () => {
    return axios
        .get('api/dashboards/dailyPatients')
        .then((result: any) => {
            const response = JSON.parse(result.request.response);
            return response.data;
        })
        .catch((result: any) => {
            const response = result.request.response;
            return response;
        });
};
const getDailyPatientsByDate = (selectedDate?: string) => {
    return axios
        .get('api/dashboards/dailyPatientsByDate', { params: { eventDate: selectedDate } })
        .then((result: any) => {
            const response = JSON.parse(result.request.response);
            return response.data;
        })
        .catch((result: any) => {
            const response = result.request.response;
            return response;
        });
};

const getProgrammedWorkshops = (threeWeeksView?: boolean) => {
    let additionalUrl: string = '';

    if (threeWeeksView) {
        additionalUrl = additionalUrl + '?period=21';
    }

    return axios
        .get(`api/dashboards/programmedWorkshops${additionalUrl}`)
        .then((result: any) => {
            const response = JSON.parse(result.request.response);
            return response.data;
        })
        .catch((result: any) => {
            const response = result.request.response;
            return response;
        });
};

const getMyScheduledWorkshops = (selectedDate?: string) => {
    const dateFilter = selectedDate;

    let additionalUrl: string = '';

    if (dateFilter) {
        additionalUrl += `?date=${dateFilter}`;
    }

    return axios
        .get('api/dashboards/myScheduledWorkshops' + additionalUrl)
        .then((result: any) => {
            const response = JSON.parse(result.request.response);
            return response.data;
        })
        .catch((result: any) => {
            const response = result.request.response;
            return response;
        });
};

const getAppointments = (selectedDate: string) => {
    return axios
        .get('api/dashboards/appointments', { params: { date: selectedDate } })
        .then((result: any) => {
            const response = JSON.parse(result.request.response);
            return response.data;
        })
        .catch((result: any) => {
            const response = result.request.response;
            return response;
        });
};

const cancelAnEvent = (eventId: number) => axios.put(`api/events/${eventId}/cancel`);

const DashboardDataService = {
    getMyDayToNumber,
    getDailyPatients,
    getDailyPatientsByDate,
    getProgrammedWorkshops,
    getMyScheduledWorkshops,
    getAppointments,
    cancelAnEvent,
};

export default DashboardDataService;
