import { FC, useContext, useEffect } from 'react';
import { Box } from '@mui/material';
import TodaysProgram from './TodaysProgram';
import IncomingRdv from './IncomingRdv';
import { Redirect, useHistory } from 'react-router-dom';
import { loadState } from 'src/reducer/Auth/AuthReducer';
import { ROUTES } from 'src/routes/routes';
import { retrieveNotifications } from 'src/actions/notification/notification';
import { useDispatch } from 'react-redux';
import { UserContext } from 'src/providers/UserProvider';
import { sxStyles } from './styles';
import { HandlePrefetchQueries } from '../App.utils';

const DashBoard: FC = (props) => {
    const isLogged = loadState()?.isLogged;
    const dispatch = useDispatch();
    const { isAdmin, userRole } = useContext(UserContext);
    const history = useHistory();

    // Prefetch Heavy Data
    HandlePrefetchQueries();

    const handleRetrieveNotifications = () => {
        if (isLogged) {
            dispatch(retrieveNotifications());
            if (isAdmin) {
                history.replace(ROUTES.security);
            }
        }
    };

    useEffect(() => {
        handleRetrieveNotifications();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userRole]);

    return isLogged ? (
        <Box sx={sxStyles.container}>
            <Box flex={10} marginRight={1}>
                <TodaysProgram />
            </Box>

            <Box flex={2}>
                <IncomingRdv />
            </Box>
        </Box>
    ) : (
        <Redirect to={ROUTES.login} />
    );
};

export default DashBoard;
