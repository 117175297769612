import { FC, useContext, useEffect, useState } from 'react';
import useStyles from './styles';
import { Box, Grid } from '@mui/material';
import { CustomText } from 'src/components/Text/CustomText';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import { SearchInputField } from 'src/components/InputField/SearchInputField';
import ProgramItemCard from 'src/components/ProgramItemCard';
import { ProgramContext } from 'src/providers/ProgramProvider';
import CustomLoader from 'src/components/CustomLoader/CustomLoader';
import { useHistory } from 'react-router-dom';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import { UserContext } from 'src/providers/UserProvider';
import { searchElementByName } from 'src/utils/utils';
import { useFetchPrograms } from 'src/hooks/ReactQueryHooks/useProgramRQHooks';

export const ProgramList: FC = () => {
    const classes = useStyles();
    const history = useHistory();
    const { filteredProgramList, programList, hasFilter, setProgramList, programId,loadingProgramList } = useContext(ProgramContext);
    const [refetchList, setRefetchList] = useState(false);
    const { isLoading: loadingProgram } = useFetchPrograms({ setProgramList, refetchList });
    const { isCoordinator } = useContext(UserContext);

    const gotoProgramForm = () => history.push('/programmes/new');
    const programData = programId > 0 ? programList : filteredProgramList;

    const [searchkey, setSearchkey] = useState<string>('');
    const [searchedItems, setSearchedItems] = useState<Array<any>>([]);
    const handleSearch = (value: string) => {
        setSearchkey(value);
    };

    useEffect(() => {
        setSearchedItems(searchElementByName(programData, searchkey, 'title'));
    }, [searchkey, hasFilter]);

    const programs = searchkey?.trim()?.length > 0 ? searchedItems : programData;

    return (
        <Box className={classes.container}>
            <Grid className={classes.wrapper}>
                <CustomText style={{ textAlign: 'center' }} isBreefSerif isTitle>
                    Programmes
                </CustomText>

                <div className={classes.row}>
                    <div style={{ flex: 2 }}>
                        <CustomText
                            style={{ textAlign: 'left' }}
                            fontSize={19}
                            fontFamily={baseFontFamily.BreeSerif}
                            color={baseColors.black}
                        >
                            Liste des programmes
                        </CustomText>
                    </div>

                    <div style={{ flex: 1 }}>
                        <SearchInputField
                            onChange={(value: string) => handleSearch(value)}
                            value={searchkey}
                            placeholder="Rechercher un programme"
                        />
                    </div>
                </div>

                <Box
                    style={{
                        height: `calc(100vh - ${isCoordinator ? 260 : 220}px)`,
                        overflowY: 'auto',
                        marginTop: 15,
                    }}
                >
                    {loadingProgram || loadingProgramList ? (
                        <CustomLoader loadingNumber={5} />
                    ) : programs?.length ? (
                        programs?.map((program, i) => <ProgramItemCard item={program} setRefetchList={setRefetchList} isGreen={i % 2 === 0} key={i} />)
                    ) : (
                        <CustomText style={{ textAlign: 'center' }} fontSize={25}>
                            Aucun programme trouvé .
                        </CustomText>
                    )}
                </Box>
            </Grid>

            {isCoordinator && (
                <div style={{ display: 'flex', justifyContent: 'center', padding: 10 }}>
                    <CustomLoadingButton
                        disabled={loadingProgram || loadingProgramList}
                        width={280}
                        label="Créer un nouveau programme"
                        handleClick={gotoProgramForm}
                    />
                </div>
            )}
        </Box>
    );
};
