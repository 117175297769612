import { FC } from 'react';
import CustomCircleLoader from 'src/components/CustomLoader/CustomCircleLoader';
import { CustomSelect } from 'src/components/InputField/CustomSelect';
import { CustomText } from 'src/components/Text/CustomText';
import { useFetchPrograms } from 'src/hooks/ReactQueryHooks/useProgramRQHooks';
import { baseFontFamily } from 'src/utils/constants/baseStyles';
import { optimizedProgramData,programsWithoutKeys } from '../AddProgramLogic';
import { PROGRAM_STATUS } from 'src/utils/constants/enums';

interface ProgramChoiceProps {
    label?: string;
    fetchingProgram: boolean;
    programItems: Array<any>;
    setProgrameId: Function;
    setAdditionnalWorkShops?: Function;
}

const ProgramChoice: FC<ProgramChoiceProps> = (props) => {
    const { label, setProgrameId } = props;
    const { data, isLoading } = useFetchPrograms({});
    const programsWithoutDraftAndDone = programsWithoutKeys(data,[PROGRAM_STATUS.DONE,PROGRAM_STATUS.DRAFT])
    const programItems = optimizedProgramData(programsWithoutDraftAndDone ?? []);

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                margin: 50,
            }}
        >
            <CustomText style={{ width: 250 }} fontSize={14} fontFamily={baseFontFamily.OpenSansSemiBold}>
                {label ?? 'Choisir le programme à ajouter'} :
            </CustomText>

            <div>
                {isLoading ? (
                    <CustomCircleLoader height={25} width={25} />
                ) : (
                    <CustomSelect
                        onChange={(value: number) => setProgrameId(value)}
                        width={250}
                        title=""
                        label=""
                        labelKey="label"
                        items={[{ id: 0, label: 'Sélectionner un programme', modules: [] }, ...programItems]}
                        idSelect="selection-programme"
                    />
                )}
            </div>
        </div>
    );
};

export default ProgramChoice;
