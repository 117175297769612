import { Checkbox, Grid, LinearProgress, linearProgressClasses } from '@mui/material';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import { FC, useEffect, useState } from 'react';
import { BackButton } from 'src/components/Button/BackButton';
import { CustomButton } from 'src/components/Button/CustomButton';
import { SearchInputField } from 'src/components/InputField/SearchInputField';
import { CustomText } from 'src/components/Text/CustomText';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import CheckIcon from 'src/components/CustomIcon/CheckIcon';
import CustomModal from 'src/components/CustomModal';

const rows = [
    {
        id: 1,
        firstname: 'alexander',
        lastname: 'Leslie',
        birthdate: '01/06/1987',
        phone: '0603259815',
    },
    {
        id: 2,
        firstname: 'Durand',
        lastname: 'Martin',
        birthdate: '19/08/1986',
        phone: '0656986302',
    },
    {
        id: 3,
        firstname: 'Martin',
        lastname: 'Pierre',
        birthdate: '23/02/1957',
        phone: '0712006341',
    },
    {
        id: 4,
        firstname: 'Martinez',
        lastname: 'Elsa',
        birthdate: '01/06/1987',
        phone: '0603259815',
    },
];

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: baseColors.green.primary,
    },
}));

export const ImportPatientsForm: FC = (props) => {
    const header = [
        {
            label: 'Nom',
        },
        {
            label: 'Prenom',
        },
        {
            label: 'Date de naissance',
        },
        {
            label: 'Téléphone',
        },
        {
            label: 'importer',
        },
    ];

    const [importModalVisible, setImportModalVisible] = useState(false);
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        if (importModalVisible && progress < 100) {
            const timer = setInterval(() => {
                setProgress((oldProgress) => {
                    const diff = Math.random() * 10;
                    return Math.min(oldProgress + diff, 100);
                });
            }, 500);

            return () => {
                clearInterval(timer);
            };
        } else {
            //setProgress(0);
        }
    }, [importModalVisible, progress]);

    return (
        <Grid style={{ padding: 20 }} justifyContent="center">
            <CustomText
                style={{ textAlign: 'center', marginBottom: 20 }}
                fontSize={24}
                fontFamily={baseFontFamily.BreeSerif}
            >
                Connexion à Expert Santé
            </CustomText>
            <Grid item lg={3}>
                <BackButton />
            </Grid>
            <CustomText
                fontSize={18}
                fontFamily={baseFontFamily.OpenSansSemiBold}
                style={{ textAlign: 'center', marginBottom: 20, marginTop: 20 }}
            >
                Entrer le nom du (ou des) patient(s) à importer :
            </CustomText>
            <Grid item lg={8} style={{ marginLeft: '18%' }}>
                <SearchInputField value={''} placeholder={'Taper un nom'} onChange={() => {}} />
            </Grid>

            <Grid container style={{ marginTop: 40 }}>
                <Grid
                    container
                    style={{ width: '100%', marginBottom: 20, marginLeft: 20 }}
                    justifyContent={'space-between'}
                >
                    {header.map((row, i) => (
                        <Grid style={{ color: baseColors.green.primary }} key={i} item>
                            {row.label.toUpperCase()}
                        </Grid>
                    ))}
                </Grid>

                {rows.map((row, i) => (
                    <Grid
                        container
                        style={{
                            width: '100%',
                            marginBottom: 10,
                            padding: 20,
                            background: baseColors.green.light,
                            borderRadius: 10,
                        }}
                        key={i}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                    >
                        <Grid style={{ width: '14%' }} item>
                            <CustomText fontSize={17}>{row.firstname.toUpperCase()}</CustomText>
                        </Grid>
                        <Grid style={{ width: '20%', color: baseColors.green.primary }} item>
                            <CustomText fontSize={17}>{row.lastname}</CustomText>
                        </Grid>
                        <Grid style={{ width: '25%' }} item>
                            <CustomText fontSize={17}>{row.birthdate}</CustomText>
                        </Grid>
                        <Grid style={{ width: '20%', color: baseColors.green.primary }} item>
                            <CustomText color={baseColors.green.primary} fontSize={17}>
                                {row.phone}
                            </CustomText>
                        </Grid>
                        <Grid style={{ width: '5%' }} item>
                            <Checkbox
                                sx={{
                                    color: baseColors.green.primary,
                                    '&.Mui-checked': {
                                        color: baseColors.green.primary,
                                    },
                                }}
                            />
                        </Grid>
                    </Grid>
                ))}
                <Grid
                    container
                    direction={'row'}
                    justifyContent="space-evenly"
                    style={{ marginTop: 20, width: '50%', marginLeft: '25%' }}
                >
                    <Grid item>
                        <CustomButton
                            variant={'outlined'}
                            background={baseColors.white}
                            onClick={() => {}}
                            width={150}
                            label={'Annuler'}
                        />
                    </Grid>
                    <Grid item>
                        <CustomButton onClick={() => setImportModalVisible(true)} width={150} label={'Valider'} />
                    </Grid>
                </Grid>
            </Grid>

            {/**Modal */}
            <CustomModal
                width={450}
                title="Importation du ou des patients"
                open={importModalVisible}
                toggleModal={() => setImportModalVisible(false)}
            >
                <Box
                    style={{
                        marginTop: 10,
                        width: 420,
                        marginBottom: 20,
                        borderRadius: 10,
                        padding: 15,
                        background: baseColors.green.light,
                    }}
                >
                    <Grid container alignItems="center" direction="row" justifyContent={'space-between'}>
                        <Grid item>
                            <CustomText>
                                {progress < 100 ? 'Importation en cours...' : 'Importation réussie'}
                            </CustomText>
                            <CustomText style={{ marginTop: 10, marginBottom: 10 }}>{`${progress.toFixed(
                                1,
                            )}%`}</CustomText>
                        </Grid>
                        <Grid item>{progress === 100 && <CheckIcon />}</Grid>
                    </Grid>

                    <BorderLinearProgress variant="determinate" value={progress} />
                </Box>
                <Grid container direction={'row'} justifyContent={'space-around'}>
                    <CustomButton
                        borderRadius={5}
                        disabled={!(progress === 0 || progress === 100)}
                        width={230}
                        size={'large'}
                        onClick={() => {
                            setImportModalVisible(false);
                        }}
                        lower
                        label={'Retour à la liste des patients'}
                    ></CustomButton>
                    <CustomButton
                        width={210}
                        disabled={!(progress === 0 || progress === 100)}
                        lower
                        borderRadius={5}
                        size={'large'}
                        onClick={() => {
                            setImportModalVisible(false);
                        }}
                        label={'Importer d’autres patients'}
                    ></CustomButton>
                </Grid>
            </CustomModal>
        </Grid>
    );
};
