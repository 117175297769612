import { makeStyles } from '@mui/styles';
import { baseBorderRadius, baseColors, baseFontFamily, baseScreenHeight } from '../../../utils/constants/baseStyles';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: baseColors.white,
        ...baseScreenHeight.defaultHeight,
        ...baseBorderRadius.leftOnly,
       
    },
    header: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    title: {
        color: baseColors.black,
        fontFamily: baseFontFamily.BreeSerif,
    },
    content: {
        // paddingRight: 10,
    },
});

export default useStyles;
