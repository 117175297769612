import { FC } from 'react';
import { CustomText } from 'src/components/Text/CustomText';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';

interface AnswerContentProps {
    label: string;
    value: string | number;
    isFile?: boolean;
    fileLink?: string;
}

const AnswerContent: FC<AnswerContentProps> = ({ label, value, isFile, fileLink }) => (
    <div
        style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        }}
    >
        <CustomText
            style={{ color: baseColors.green.primary, width: isFile ? 160 : 60 }}
            fontSize={14}
            fontFamily={baseFontFamily.OpenSansSemiBold}
        >
            {label}
        </CustomText>

        {isFile ? (
            <a
                rel="noreferrer"
                style={{ cursor: 'pointer', color: baseColors.green.primary }}
                href={fileLink}
                download="MedicaleFile"
                target="_blank"
            >
                <CustomText
                    style={{ marginLeft: 15, fontStyle: 'italic' }}
                    fontSize={14}
                    fontFamily={baseFontFamily.OpenSansSemiBold}
                    color={baseColors.green.primary}
                >
                    : {value ?? '(Aucune)'}
                </CustomText>
            </a>
        ) : (
            <CustomText
                style={{ marginLeft: 10, fontStyle: 'italic' }}
                fontSize={12}
                fontFamily={baseFontFamily.OpenSansRegular}
                color="#15254599"
            >
                : {value ?? '(Aucune)'}
            </CustomText>
        )}
    </div>
);

export default AnswerContent;
