import { FC } from 'react';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';

interface PlanningHourProps {
    hour: string;
}

const PlanningHour: FC<PlanningHourProps> = (props) => {
    const { hour } = props;

    return (
        <TableCell
            align="center"
            sx={{
                borderRight: '1px solid rgba(224, 224, 224, 1)',
                borderBottom: '2px solid rgba(224, 224, 224, 1)',
                borderBottomStyle: 'dashed',
                padding: '0 20px 20px 20px',
                width: 50,
            }}
        >
            <Typography fontFamily={baseFontFamily.OpenSansRegular} fontSize={13} color={baseColors.orange.primary}>
                {hour ?? ''}
            </Typography>
        </TableCell>
    );
};

export default PlanningHour;
