import { RootState } from 'src/stores/store';
import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import UserHistoryService from 'src/services/UserHistories';
import { UserHistoryActionType } from './types';

export const retrieveUserHistories = (filters?: any, setLoading?: Function): ThunkAction<void, RootState, unknown, AnyAction> => async dispatch => {
    setLoading?.(true)
    try {
        const data = await UserHistoryService.getUserHistories(filters);
        dispatch({
            type: UserHistoryActionType.LIST_USER_HISTORY,
            payload: data
        });
        setLoading?.(false)
    } catch (error) {
        console.log('Retrieve UserHistories Error', error);
        setLoading?.(false)
    }
}