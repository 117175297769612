import { FC } from 'react';
import useStyles from './styles';
import { Grid } from '@mui/material';
import { CustomText } from 'src/components/Text/CustomText';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';

interface ProgramNavbarProps {
    items: any[];
    selected: number;
    onSelect: Function;
    fullWidth?: boolean;
}
export const ProgramNavBar: FC<ProgramNavbarProps> = (props) => {
    const { items, selected, onSelect } = props;
    const classes = useStyles();

    return (
        <div className={classes.col}>
            <Grid style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <div className={classes.navbar}>
                    {items.map((item) => (
                        <div onClick={() => onSelect(item)} key={item.key} className={classes.menuLabel}>
                            <CustomText
                                fontFamily={
                                    selected === item.key ? baseFontFamily.OpenSansBold : baseFontFamily.OpenSansRegular
                                }
                                color={baseColors.green.primary}
                                fontSize={12}
                                style={{
                                    padding: '10px',
                                }}
                            >
                                {item.label}
                            </CustomText>
                        </div>
                    ))}
                </div>
            </Grid>
        </div>
    );
};
