import { Contact } from 'src/interfaces/interfaces';
import { isValidMail } from 'src/utils/helpers/InputValidator';

const defaultContactValue: Contact = {
    subject: '',
    content: '',
    recipients: [],
};

const tableHeaders = [
    {
        name: 'Nom',
    },
    {
        name: 'Prénom',
    },
    {
        name: 'Rôle',
    },
    {
        name: 'Catégorie',
    },
    {
        name: 'Action',
    },
];

const categoryData = [
    { id: 1, label: 'Choisir la catégorie', value: '' },
    { id: 2, label: 'ASSISTANT', value: 'ASSISTANT' },
    { id: 3, label: 'AUTRE', value: 'OTHER' },
    { id: 4, label: 'PATIENT', value: 'PATIENT' },
    { id: 5, label: 'PROFESSIONEL', value: 'PROFESSIONAL' },
];

const isUserValid = (user: any) =>
    user.firstName.trim().length !== 0 &&
    user.lastName.trim().length !== 0 &&
    isValidMail(user.email) &&
    user.password.first.trim().length !== 0 &&
    user.password.second.trim().length !== 0 &&
    user.password.second === user.password.first &&
    user.category.trim().length !== 0;

export { defaultContactValue, tableHeaders, categoryData, isUserValid };
