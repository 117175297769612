import { useState, FC } from 'react';
import { createContext } from 'react';

type VisioContentType = {
    doesVisioStarted: boolean;
    setDoesVisioStarted: (val: boolean) => void;

    visioContent: any;
    setVisionContent: (val: any) => void;

    openIframe: boolean;
    setOpenIframe: (val: boolean) => void;
};

export const VisioContentContext = createContext<VisioContentType>({
    doesVisioStarted: false,
    setDoesVisioStarted: () => {},

    visioContent: null,
    setVisionContent: () => {},

    openIframe: false,
    setOpenIframe: () => {},
});

const VisioContentProvider: FC = (props) => {
    const { children } = props;
    const [doesVisioStarted, setDoesVisioStarted] = useState(false);
    const [openIframe, setOpenIframe] = useState(false);
    const [visioContent, setVisionContent] = useState(null);

    return (
        <VisioContentContext.Provider
            value={{
                doesVisioStarted,
                setDoesVisioStarted,

                openIframe,
                setOpenIframe,

                visioContent,
                setVisionContent,
            }}
        >
            {children}
        </VisioContentContext.Provider>
    );
};

export default VisioContentProvider;
