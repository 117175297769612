import { FC, useContext, useEffect, useState } from 'react';
import { baseColors, baseFontFamily } from 'src/utils/constants/baseStyles';
import CombinedView from './CombinedView';
import useStyles from './styles';
import { Typography } from '@mui/material';
import CustomFilterContent from 'src/components/CustomFilterContent';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { handleChange } from './FiltersLogique';
import { CustomSelect } from 'src/components/InputField/CustomSelect';
import { HdjContext } from 'src/providers/HdjProvider';
import { useDispatch } from 'react-redux';
import { retrieveConsultationFilters } from 'src/actions/dayHospitals/dayHospitals';
import { ItemToCombine } from 'src/interfaces/interfaces';
import { isEven, isNull } from 'src/utils/utils';
import useWindowDimensions from 'src/components/GetWindowSize/GetWindowSize';
import HdjDataService from 'src/services/Hdj/dayHospital.service';
import { getRooms } from 'src/services/Rooms';

const Filters: FC = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isCombinedView, setIsCombinedView] = useState<boolean>(false);
    const {
        showPlanningBy,
        currentHdjInfos,
        hdjList,
        consultationFilters,
        setConsultationFilters,
        setSpecialityFilterId,
        setConsultationEventTypeId,
        setCombinedIds,
        setCheckedHdjWithCombinedView,
    } = useContext(HdjContext);
    const [checkedItem, setCheckedItem] = useState<number[]>([currentHdjInfos?.id]);
    const [specialityItems, setSpecialityItems] = useState<any>([]);
    const [roomsItems, setRoomsItems] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const labelValue = showPlanningBy === 'speciality' ? 'Spécialitée' : 'Salle';
    const specialityOrRooms = showPlanningBy === 'speciality' ? specialityItems : roomsItems;
    const specialityOrRoomsItems = [{ id: -1, name: `Choisir une ${labelValue}` }, ...specialityOrRooms];

    /*let specialityItems = [{ id: -1, label: `Choisir une ${labelValue}` }];
    if (planningFilters && planningFilters.length > 0) {
        specialityItems = [...specialityItems, ...planningFilters];
    }*/

    const handleChangeSpeciality = (id: number, setValue: any) => {
        setValue(id);
    };

    const handleGetSpecialties = () => {
        setLoading(true);
        HdjDataService.getHdjSpecialities().then((res: any) => {
            if (res) {
                setSpecialityItems(res?.data?.data?.items);
            }
        });
    };

    const handleGetRooms = async () => {
        setLoading(true);
        getRooms().then((response) => {
            const responses = response.data.data.items;
            setRoomsItems(responses);
            setLoading(false);
        });
    };

    useEffect(() => {
        dispatch(retrieveConsultationFilters(setConsultationFilters));
    }, []);

    useEffect(() => {
        setCombinedIds(checkedItem);
    }, [checkedItem]);

    useEffect(() => {
        handleGetSpecialties();
        handleGetRooms();
    }, []);

    let consultationItems = [{ id: -1, label: 'Choisir une consultation' }];

    if (consultationFilters && consultationFilters.length > 0) {
        consultationItems = [...consultationItems, ...consultationFilters];
    }

    const { height } = useWindowDimensions();
    const screenHeight = `calc(${height < 700 ? (height < 600 ? 30 : 36) : 45}vh - 10px)`;
    const filteredHdj = hdjList?.filter((hdj: any) => !isNull(hdj.date)) ?? [];
    const handleSetSelectedItems = (selectedHdj: any) => {
        const selectedItemsOnly = filteredHdj?.filter((hdj: any) => selectedHdj?.find((item: any) => item === hdj.id));
        setCheckedHdjWithCombinedView(selectedItemsOnly);
    };

    return (
        <Box className={classes.container}>
            <Typography
                className={classes.title}
                fontFamily={baseFontFamily.BreeSerif}
                fontSize={18}
                color={baseColors.black}
            >
                Filtres
            </Typography>

            <Box marginTop={4} padding={1}>
                <CustomSelect
                    onChange={(value: number) => handleChangeSpeciality(value, setSpecialityFilterId)}
                    label={labelValue}
                    title={labelValue}
                    labelKey={'name'}
                    items={specialityOrRoomsItems}
                    isPlanning
                    idSelect="filtre-label"
                    isLoading={loading}
                />
                <CustomSelect
                    onChange={(value: number) => handleChangeSpeciality(value, setConsultationEventTypeId)}
                    label="Consultation"
                    title="Consultation"
                    items={consultationItems}
                    isPlanning
                    idSelect="filtre-consultation"
                />
                <Box
                    style={{
                        height: screenHeight,
                        overflow: 'hidden',
                        overflowY: 'scroll',
                    }}
                >
                    <CombinedView isCombinedView={isCombinedView} setValue={setIsCombinedView} />
                    {isCombinedView && (
                        <>
                            <CustomFilterContent marginBottom={2} label="Éléments à combiné" filterValue={'HDJ'} />

                            <FormControl component="fieldset" variant="standard">
                                <FormGroup>
                                    {filteredHdj &&
                                        filteredHdj?.length > 0 &&
                                        filteredHdj?.map((item: ItemToCombine, i: number) => (
                                            <FormControlLabel
                                                key={i}
                                                control={
                                                    <Checkbox
                                                        sx={{
                                                            color: baseColors.grey,
                                                            '&.Mui-checked': {
                                                                color: isEven(item.id)
                                                                    ? baseColors.orange.primary
                                                                    : baseColors.green.secondary,
                                                            },
                                                        }}
                                                        value={item.id}
                                                        checked={checkedItem.includes(item.id)}
                                                        onChange={(e) => {
                                                            handleChange(e, setCheckedItem, handleSetSelectedItems);
                                                        }}
                                                        name={item.name}
                                                    />
                                                }
                                                label={
                                                    <Typography
                                                        fontFamily={baseFontFamily.OpenSansSemiBold}
                                                        fontSize={13}
                                                        color={baseColors.black}
                                                    >
                                                        {item.name}
                                                    </Typography>
                                                }
                                            />
                                        ))}
                                </FormGroup>
                            </FormControl>
                        </>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default Filters;
