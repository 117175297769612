import debounce from "lodash.debounce";
import { useEffect, useMemo } from "react";

const useDebounce = (handleFetchData: Function) => {

    const debouncedResults = useMemo(
        () => debounce((value: string | number) => handleFetchData(value), 700),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    useEffect(() => {
        return () => { 
            debouncedResults.cancel();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        debouncedResults,
    }

}

export default useDebounce