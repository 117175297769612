import { makeStyles } from '@mui/styles';
import { baseColors, baseFontSize } from 'src/utils/constants/baseStyles';

const unionStyles = {
    '& .fc .fc-toolbar.fc-header-toolbar': {
        marginBottom: '1.2em',
    },
    '& .fc .fc-button-primary': {
        backgroundColor: baseColors.green.light,
        borderColor: baseColors.green.light,
        color: baseColors.green.primary,
    },
    '& .fc .fc-button-primary:not(:disabled).fc-button-active, .fc .fc-button-primary:not(:disabled):active': {
        backgroundColor: baseColors.green.primary,
        borderColor: baseColors.green.primary,
        color: baseColors.white,
    },
    '& .fc .fc-timegrid-slot-label-cushion': {
        color: baseColors.orange.primary,
    },
    '& .fc-toolbar-title': {
        color: baseColors.orange.primary,
        fontSize: baseFontSize.md,
    },
};

const useStyles = makeStyles({
    container: {
        '& .fc': {
            maxWidth: '100%',
            height: 'calc(100vh - 255px)',
            margin: ' 0 auto',
        },
        ...unionStyles,
    },
    containerResized: {
        '& .fc': {
            maxWidth: '100%',
            height: 'calc(100vh - 285px)',
            margin: ' 0 auto',
            paddingTop: '1rem',
        },
        '& .fc-v-event': {
            backgroundColor: baseColors.green.primary,
            borderColor: baseColors.green.primary,
            display: 'block',
        },
        ...unionStyles,
    },
});

export default useStyles;
