import { Dispatch, FC, SetStateAction } from 'react';
import { useEffect, useState } from 'react';
import CustomModal from 'src/components/CustomModal';
import ListItemFormCheckBox from 'src/components/ListItemFormCheckBox';
import { searchElementByName } from 'src/utils/utils';
import { GoalItem, OptimizedItem } from 'src/interfaces/interfaces';
import { sxStyles } from './styles';
import { CustomText } from 'src/components/Text/CustomText';
import { baseColors, baseFontFamily, baseFontSize } from 'src/utils/constants/baseStyles';
import { Spacer } from 'src/components/Spacer/Spacer';
import useGoalHooks from 'src/hooks/useGoalHooks';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import { GoalModal } from 'src/views/Settings/Goals/GoalModal/GoalModal';
import { RelatedArticleGoalModalFooter } from './RelatedArticleGoalModalFooter';
import SelectedElement from 'src/components/ListItemFormCheckBox/SelectedElement';

type IProps = {
    open: boolean;
    toggleModal: any;
    payload: any;
    setPayload: any;
    setGoalNames: Dispatch<SetStateAction<OptimizedItem[]>>;
};

export const RelatedArticleGoalModal: FC<IProps> = (props) => {
    const { setGoalNames, open, toggleModal, payload, setPayload } = props;
    const { optimizedGoals, fetchingGoals } = useGoalHooks({});
    const [openGoalForm, setOpenGoalForm] = useState(false);
    const [goal, setGoal] = useState<GoalItem>({} as GoalItem);
    const [justAddedGoalIds, setJustAddedGoalIds] = useState<number[]>([]);
    const toggleGoalModal = () => setOpenGoalForm(!openGoalForm);

    const [searchkey, setSearchkey] = useState<string>('');
    const [searchedItems, setSearchedItems] = useState<Array<any>>([]);
    const handleSearch = (value: string) => setSearchkey(value);

    useEffect(() => {
        setSearchedItems(searchElementByName(optimizedGoals ?? [], searchkey, 'name'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchkey]);

    useEffect(() => {
        if(payload?.goalsIds){
            setPayload((prev:any) => ({...prev, goalsIds : [...payload.goalsIds,...justAddedGoalIds] }));
        }
    },[justAddedGoalIds])

    const finalData = searchkey?.trim()?.length > 0 ? searchedItems : optimizedGoals;

    const clearFormData = () => {
        // setPayload(defaultSkillPaylopad);
    };

    const title = 'Choisir les objectifs';

    return (
        <CustomModal
            disableBackdropClick
            title={title}
            open={open}
            toggleModal={toggleModal}
            footer={
                <RelatedArticleGoalModalFooter
                    goals={optimizedGoals}
                    payload={payload}
                    toggleModal={toggleModal}
                    setGoalNames={setGoalNames}
                />
            }
            width={700}
            clearFormData={clearFormData}
        >
            <Spacer />

            <div style={sxStyles.workshops}>
                <div
                    style={{
                        alignItems: 'center',
                        flexDirection: 'row',
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <div style={{ width: '80%' }}>
                        <CustomText fontFamily={baseFontFamily.OpenSansSemiBold} fontSize={baseFontSize.mini}>
                            Objectifs rattachés au contenu :
                        </CustomText>

                        <SelectedElement finalData={finalData} selectedIds={payload?.goalsIds} />
                    </div>

                    <CustomLoadingButton
                        style={{ backgroundColor: baseColors.orange.primary }}
                        width={280}
                        label="Ajouter un nouvel objectif"
                        handleClick={toggleGoalModal}
                    />
                </div>

                <Spacer height={10} />

                <ListItemFormCheckBox
                    selectedRows={new Set(payload?.goalsIds)}
                    handleChange={(value: any) => setPayload((old: any) => ({ ...old, goalsIds: Array.from(value) }))}
                    items={finalData}
                    loading={fetchingGoals}
                    labelKey="name"
                    handleSearch={(value: string) => handleSearch(value)}
                    maxItems={optimizedGoals.length}
                />
            </div>

            <GoalModal 
                open={openGoalForm} 
                goal={goal} 
                setGoal={setGoal} 
                toggleModal={toggleGoalModal}
                setJustAddedGoalIds={setJustAddedGoalIds}
                optionalWorkshopAndArticle
            />
        </CustomModal>
    );
};
