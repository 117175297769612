import { Box } from '@mui/system';
import React, { FC } from 'react';
import ContentLoader from 'react-content-loader';
import { baseColors } from 'src/utils/constants/baseStyles';

interface PatientListLoaderProps {
    loadingNumber?: number;
}

const PatientListLoader: FC<PatientListLoaderProps> = (props) => {
    const { loadingNumber } = props;

    const itemLoad = (key: number) => (
        <ContentLoader
            key={key}
            backgroundColor={baseColors.green.light}
            foregroundColor={baseColors.green.secondary}
            viewBox="0 0 100 50"
        >
            <rect x="3" y="3" rx="25" ry="25" width="25" height="25" />
            <rect x="30" y="10" rx="4" ry="4" width="50" height="4" />
            <rect x="30" y="18" rx="3" ry="3" width="50" height="4" />
        </ContentLoader>
    );

    const listLoader = Array.from(Array(loadingNumber ?? 10).keys());
    const itemToRender = (key: number) => itemLoad(key);

    return <Box>{listLoader.map((item: any, i: number) => itemToRender(i))}</Box>;
};

export default PatientListLoader;
