import { Box, IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import { baseColors, baseScreenHeight } from 'src/utils/constants/baseStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { toast } from 'react-toastify';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import { CustomConfirm } from 'src/components/CustomConfirm/CustomConfirm';
import WorkshopService from 'src/services/Workshop/workshop.service';
import { Workshop } from 'src/interfaces/interfaces';
import styles from './styles';
import CustomEmptyData from 'src/components/CustomEmptyData';
import { fullName, sortByIds, uniqByKeyValues } from 'src/utils/utils';
import { CustomText } from 'src/components/Text/CustomText';
import { tableCellHeadText, tableCellText } from 'src/views/Security/SecurityManagement/SecurityManagement';
import SubMenusLoader from 'src/components/SettingsSubMenuBar/SubMenusLoader/SubMenusLoader';
import { WorkshopForm, defaultWorkshopValue } from 'src/views/WorkshopForm/WorkshopForm/WorkshopForm';
import useWorkshops from 'src/hooks/useWorkshops';

export const WorkshopsPanel = () => {
    const [modalConfirmVisible, setModalConfirmVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [updateList, setUpdateList] = useState(false);
    const [loadingWorkshops, setLoadingWorkshops] = useState(false);
    const [modalFormOpen, setModalFormOpen] = useState(false);
    const [workshopIdToDelete, setWorkshopIdToDelete] = useState(-1);
    const [workshop, setWorkshop] = useState<Workshop>(defaultWorkshopValue);
    const [consultationData, setConsultationData] = useState<any>(null);
    const [workshops, setWorkshops] = useState<any[]>([]);
    const tableHeaders = [{ name: 'Nom' }, { name: 'Durée' }, { name: 'Remarque' }, { name: 'Action' }];
    const { handleDeleteWorkshop } = useWorkshops({
        setModalConfirmVisible,
        setUpdateList
    })

    const onEdit = (data: any) => {
        let cloneData = structuredClone(data);

        const optimizedAttendees = uniqByKeyValues({
            items: Array.from(cloneData?.attendees ?? [], (item: any) => ({
                id: item?.attendeeUser?.id,
                firstName: item?.attendeeUser?.firstName,
                lastName: item?.attendeeUser?.lastName,
                name: fullName(item?.attendeeUser?.firstName, item?.attendeeUser?.lastName),
                specialityId: item?.attendeeUser?.specialityType?.id,
            })),
            key: 'id',
        });

        let workshopData = {
            id: cloneData?.id,
            name: cloneData?.name,
            duration: cloneData?.duration,
            consultationTypeId: cloneData?.consultationType?.id,
            remark: cloneData.remark,
            roomId: cloneData.room?.id ?? 0,
            customLocationId: cloneData.customLocation?.id ?? 0,
            attendees: optimizedAttendees ?? [],
            specialityTypeId:
                cloneData?.attendees?.length > 0 ? cloneData?.attendees[0].attendeeUser.specialityType.id : undefined,
            dayHospitalId: cloneData?.dayHospital?.id,
            axisId: cloneData?.axis?.id ?? 0,
            goals: cloneData?.goals ?? [],
            isDraft: cloneData?.isDraft,
        };
        setConsultationData({
            remark: cloneData.remark,
            consultationTypeId: cloneData?.consultationType?.id,
            location: cloneData.customLocation,
            data: cloneData,
        });
        setWorkshop(workshopData);
        setModalFormOpen(true);
    };
    
    const fetchWorkshops = (query?: string) => {
        setLoadingWorkshops(true);
        WorkshopService.getWorkshops({ searchKey: query })
            .then((response) => {
                const data = response?.data?.data;
                if (data) {
                    setWorkshops(sortByIds(data));
                }
                setLoadingWorkshops(false);
            })
            .catch((e) => {
                setLoadingWorkshops(false);
                toast.error('Une erreur sur la récupération des ateliers');
            });
    };

    useEffect(() => {
        fetchWorkshops();
    }, [updateList]);

    const performDeleteWorkshop = () => {
        handleDeleteWorkshop(workshopIdToDelete);
    }

    const handleClick = () => {
        setConsultationData(null);
        setWorkshop(defaultWorkshopValue);
        setModalFormOpen(true);
    };

    return (
        <Box sx={styles.container}>
            <Box sx={styles.header}>
                <CustomText isBreefSerif isTitle color={baseColors.green.primary} style={{ marginLeft: 20 }}>
                    Liste des ateliers
                </CustomText>

                <CustomLoadingButton disabled={loading} label="Ajouter un nouvel atelier" handleClick={handleClick} />
            </Box>

            {loadingWorkshops ? (
                <SubMenusLoader />
            ) : workshops?.length === 0 ? (
                <div style={{ paddingBottom: 150 }}>
                    <CustomEmptyData />
                </div>
            ) : (
                <TableContainer
                    component={Paper}
                    style={{ overflow: 'auto', maxHeight: baseScreenHeight.tableContainerHeight }}
                >
                    <Table stickyHeader sx={{ minWidth: 650 }} aria-label="role table">
                        <TableHead>
                            <TableRow>
                                {tableHeaders.map((header, index) => (
                                    <TableCell key={index} sx={{ color: baseColors.green.primary }}>
                                        {tableCellHeadText(header.name)}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {workshops.map((row, index) => (
                                <>
                                    <TableRow
                                        key={index}
                                        sx={{
                                            '&:last-child td, &:last-child th': { border: 0 },
                                            '& .MuiTableCell-root': {
                                                padding: '5px 15px 5px 15px',
                                            },
                                        }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {tableCellText(row.name)}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {tableCellText(row.duration)}
                                        </TableCell>
                                        <TableCell>{tableCellText(row.remark)}</TableCell>
                                        <TableCell>
                                            <IconButton onClick={() => onEdit(row)}>
                                                <EditOutlinedIcon
                                                    sx={{
                                                        fontSize: 'medium',
                                                        color: baseColors.green.primary,
                                                        cursor: 'pointer',
                                                    }}
                                                />
                                            </IconButton>
                                            {!row?.hasLinkedRecord && (
                                                <IconButton
                                                    onClick={() => {
                                                        setWorkshopIdToDelete(+row.id);
                                                        setModalConfirmVisible(true);
                                                    }}
                                                >
                                                    <DeleteOutlineOutlinedIcon sx={styles.delete} />
                                                </IconButton>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                </>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            <WorkshopForm
                forAdmin
                consultationData={consultationData}
                workshopData={workshop}
                setUpdateList={setUpdateList}
                modalToggle={setModalFormOpen}
                modalOpen={modalFormOpen}
            />

            <CustomConfirm
                defaultLoading={loading}
                modalVisible={modalConfirmVisible}
                updateModalVisible={setModalConfirmVisible}
                message="Voulez-vous vraiment supprimer cet atelier ? "
                callBackconfirm={performDeleteWorkshop}
            />
        </Box>
    );
};
