import { Box, Button, Typography, Avatar } from '@mui/material';
import { FC, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { CustomText } from 'src/components/Text/CustomText';
import { HdjContext } from 'src/providers/HdjProvider';
import { baseFontFamily, baseFontSize } from 'src/utils/constants/baseStyles';
import { sxStyles } from './styles';
import { baseColors, baseFontWeight } from 'src/utils/constants/baseStyles';
import IconKitMedical from 'src/components/CustomIcon/IconKitMedical';
import IconCheckList from 'src/components/CustomIcon/IconCheckList';
import IconDiabeto from 'src/components/CustomIcon/IconDiabeto';
import { ROUTES } from 'src/routes/routes';
import { UserContext } from '../../../../providers/UserProvider';

const hdjIcons = [
    <IconKitMedical height={40} width={40} />,
    <IconCheckList height={40} width={40} />,
    <IconDiabeto height={40} width={40} />,
];

interface HdjManagementHeaderProps {
    fetchedHdjData?: any;
    loadingHdjData?: boolean;
}

const HdjManagementHeader: FC<HdjManagementHeaderProps> = (props) => {
    const { fetchedHdjData, loadingHdjData } = props;
    const history = useHistory();
    const { currentHdjInfos, setCurrentHdjInfos } = useContext(HdjContext);
    const iconStyle = { 
        backgroundColor: baseColors.orange.primary, 
        padding: fetchedHdjData?.icon ? '0px' : '20px 20px 17px 20px', 
        borderRadius: fetchedHdjData?.icon ? '50%' : 10 };
    const icon = hdjIcons[Math.floor(Math.random() * hdjIcons.length)];
    const hanleClickHdjList = () => history.push(ROUTES.hdj);
    const handleEdit = () => {
        setCurrentHdjInfos(fetchedHdjData);
        history.push(`/hdj/${fetchedHdjData?.id}/edit`);
    };
    const { isCoordinator } = useContext(UserContext);
    return (
        <Box padding={1}>
            <CustomText style={{ textAlign: 'center' }} isBreefSerif isTitle>
                Hospitalisation du jour
            </CustomText>

            <Box style={sxStyles.container}>
                <Box style={sxStyles.left}>
                    <Box sx={iconStyle}>
                        {
                            fetchedHdjData?.icon ? 
                            <Avatar sx={{ height: 80, width: 80 }} src={fetchedHdjData?.icon} > 
                                {icon}
                            </Avatar>
                            : icon
                        }
                    </Box>
                    <Typography
                        color={baseColors.black}
                        fontWeight={baseFontWeight.medium}
                        fontSize={16}
                        marginLeft={5}
                    >
                        {currentHdjInfos?.name ?? fetchedHdjData?.name ?? ''}
                    </Typography>
                </Box>

                <Box style={sxStyles.btnContainer}>
                    {isCoordinator && (
                        <Button
                            disabled={loadingHdjData}
                            onClick={handleEdit}
                            style={sxStyles.btnOrange}
                            variant="outlined"
                            size="large"
                            id="modifier_hdj"
                        >
                            <CustomText
                                color={baseColors.white}
                                fontFamily={baseFontFamily.OpenSansSemiBold}
                                fontSize={baseFontSize.mini}
                            >
                                Modifier
                            </CustomText>
                        </Button>
                    )}

                    <Button
                        disabled={loadingHdjData}
                        onClick={hanleClickHdjList}
                        style={sxStyles.btnGreen}
                        variant="outlined"
                        size="large"
                        id="liste_HDJ"
                    >
                        <CustomText
                            color={baseColors.white}
                            fontFamily={baseFontFamily.OpenSansSemiBold}
                            fontSize={baseFontSize.mini}
                        >
                            Liste des HDJ
                        </CustomText>
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default HdjManagementHeader;
