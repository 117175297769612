import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    container: {
        padding: 10,
    },
    flexCenter: {
        display: 'flex',
        justifyContent: 'center',
    },
    row: { display: 'flex', flexDirection: 'row', marginBottom: 10 },
});

export const sxStyles: any = {
    content: {},
    row: { display: 'flex', flexDirection: 'row', marginBottom: 10 },
};

export default useStyles;
