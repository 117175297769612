import { FC } from 'react';
import Slider from '@mui/material/Slider';
import { sxStyles } from './styles';
import { Box } from '@mui/material';

interface CustomSliderProps {
    width?: string | number | undefined;
    getAriaValueText?: any;
    valueLabelDisplay?: any;
    marks?: Array<{ label?: string, value: number }>| boolean;
    onChange?: any;
    value?: number[] | number;
    min?: number;
    max?: number;
    step?: number;
    defaultValue?: number;
    size?: any;
    sx?: any;
    footer?: JSX.Element
}

const CustomSlider: FC<CustomSliderProps> = (props) => {
    const {width, onChange, marks, value, getAriaValueText, min, max, step, valueLabelDisplay, defaultValue, size, sx, footer } = props;
    const sliderWidth = width ?? '100px'
    return (
        <Box style={{position:'relative', width: sliderWidth}}>
            <Slider
                sx={sx ?? sxStyles.slider}
                getAriaLabel={() => 'Temperature range'}
                value={value}
                onChange={onChange}
                valueLabelDisplay={valueLabelDisplay}
                marks={marks}
                getAriaValueText={getAriaValueText}
                min={min}
                max={max}
                step={step}
                defaultValue={defaultValue}
                size={size}
            />
            {footer}
        </Box>
    );
};

export default CustomSlider;
