import { Dispatch, SetStateAction } from 'react';
import { SpecialityItem } from 'src/interfaces/interfaces';
import { PostSpecialitiesPayload } from 'src/services/Speciality.service';

const defaultSpecialityPaylopad = {
    name: '',
    abbreviation: '',
};

const handleChangeName = ({
    setPayload,
    value,
}: {
    setPayload: Dispatch<SetStateAction<PostSpecialitiesPayload>>;
    value: string;
}) => {
    setPayload((prev) => ({
        ...prev,
        goal: value,
    }));
};

const checkPayloadState = (payload: PostSpecialitiesPayload) => {
    const disabled = payload?.name?.trim() === '';
    return disabled;
};

const handleSetSpecialityPayload = ({
    setPayload,
    speciality,
}: {
    setPayload: Dispatch<SetStateAction<PostSpecialitiesPayload>>;
    speciality: SpecialityItem;
}) => {
    setPayload(() => {
        return {
            name: speciality?.name ?? '',
            abbreviation: speciality?.abbreviation ?? '',
        };
    });
};

export { handleSetSpecialityPayload, defaultSpecialityPaylopad, checkPayloadState, handleChangeName };
