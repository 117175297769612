import { Box } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { baseColors, baseFontFamily, baseFontSize } from 'src/utils/constants/baseStyles';
import useStyles, { sxStyles } from './styles';
import { EventPlanningViewType } from 'src/interfaces/interfaces';
import ShareMyAgenda from '../ShareMyAgenda';
import PlanningDate from './PlanningDate';
import { UserContext } from 'src/providers/UserProvider';
import { CustomText } from 'src/components/Text/CustomText';
import SpecialityFilters from './SpecialityFilters';
import { URER_ROLES } from 'src/utils/constants/enums';
import useFetchSpecialities from 'src/hooks/useFetchSpecialities';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import UserAgendaSharers from '../UsersSelectList/UserAgendaSharers';
interface PlanningFiltersProps {
    hasEvents?: boolean;
    toggleAddEvnetModal: any;
    setViewType: any;
    setCaregiverId: any;
    setSelectedSpecialities?: any;
    setCreateEventFromBtnAction?: any;
    viewType?: EventPlanningViewType['viewType'];
    setSelectedIntervenants?: any;
    selectedIntervenants?: any;
    loadingPlanningEvent?: boolean;
}

const PlanningFilters: FC<PlanningFiltersProps> = (props) => {
    const {
        toggleAddEvnetModal,
        setSelectedSpecialities,
        setCreateEventFromBtnAction,
        viewType,
        selectedIntervenants,
        setSelectedIntervenants,
        hasEvents,
        loadingPlanningEvent,
    } = props;
    const classes = useStyles();
    const { userInfos, hasEtp } = useContext(UserContext);
    const { handleFetchSpecialities, specialities } = useFetchSpecialities();
    const [open, setOpen] = useState(false);
    const toggleModal = () => setOpen((prev) => !prev);

    useEffect(() => {
        handleFetchSpecialities();
    }, []);

    const handleClick = () => {
        setCreateEventFromBtnAction?.(true);
        toggleAddEvnetModal();
    };

    const isDoctor = userInfos?.roles?.includes(URER_ROLES.DOCTOR) && hasEtp;
    const showBtn = Boolean(userInfos?.isExternal === 0);
    const showShareBtn = isDoctor && showBtn && hasEvents;

    return (
        <Box sx={sxStyles.container}>
            <Box sx={sxStyles.titleContainer} className={classes.header}>
                <PlanningDate viewType={viewType} />
            </Box>

            <Box marginBottom={2} sx={sxStyles.specialityItems}>
                <Box
                    className={classes.filterHeader}
                    style={{
                        padding: '15px 20px 15px 20px',
                        backgroundColor: baseColors.green.light,
                        borderRadius: 5,
                        cursor: 'pointer',
                    }}
                    onClick={toggleModal}
                >
                    <CustomText
                        fontFamily={baseFontFamily.OpenSansSemiBold}
                        style={{ fontSize: baseFontSize.mini, color: baseColors.green.primary }}
                    >
                        Sélectionner un/des soignant(es)
                    </CustomText>
                </Box>

                <UserAgendaSharers
                    selectedIntervenants={selectedIntervenants}
                    setSelectedIntervenants={setSelectedIntervenants}
                />

                {showShareBtn && <ShareMyAgenda myId={userInfos?.id} />}

                <Box style={sxStyles.viewType}>
                    {showBtn && (
                        <CustomLoadingButton
                            disabled={loadingPlanningEvent}
                            label="Créer un événement"
                            handleClick={handleClick}
                        />
                    )}
                </Box>
            </Box>

            <SpecialityFilters
                title="Sélectionner un/des soignant(es)"
                open={open}
                toggleModal={toggleModal}
                specialities={specialities}
                setSelectedSpecialities={setSelectedSpecialities}
            />
        </Box>
    );
};

export default PlanningFilters;
