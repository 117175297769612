import { FC, useEffect, useState, Dispatch, SetStateAction } from 'react';
import { CustomText } from 'src/components/Text/CustomText';
import { baseColors, baseFontFamily, baseFontSize } from 'src/utils/constants/baseStyles';
import { Spacer } from 'src/components/Spacer/Spacer';
import { ProgramItem, SkillItem } from 'src/interfaces/interfaces';
import ListItemFormCheckBox from 'src/components/ListItemFormCheckBox';
import useSkillHooks from 'src/hooks/useSkillHooks';
import { searchElementByName } from 'src/utils/utils';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import { SkillModal } from 'src/views/Settings/Skills/SkillModal';
import SelectedElement from 'src/components/ListItemFormCheckBox/SelectedElement';
import {ProgramSkill} from 'src/interfaces/interfaces'
interface IProps {
    program: ProgramItem;
    setProgram: Dispatch<SetStateAction<ProgramItem>>;
}

export const ProgramSkillsForm: FC<IProps> = ({ program, setProgram }) => {
    const { optimizedSkills, fetchingSkills } = useSkillHooks({});
    const [searchkey, setSearchkey] = useState<string>('');
    const [searchedItems, setSearchedItems] = useState<Array<any>>([]);
    const [selectedFilterIds, setSelectedFilterIds] = useState<Array<number>>([]);
    const handleSearch = (value: string) => setSearchkey(value);

    const [moduleFormOpen, setModuleFormOpen] = useState(false);
    const [skill, setSkill] = useState<SkillItem>({} as SkillItem);
    const [justAddedSkills,setJustAddedSkills] = useState<ProgramSkill[]>([]);

    const toggleModal = () => setModuleFormOpen(!moduleFormOpen);

    useEffect(() => {
        setSearchedItems(searchElementByName(optimizedSkills ?? [], searchkey, 'name'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchkey]);

    useEffect(()=>{
        setProgram((prev:any) => ({
            ...prev,
            skillIds: Array.from(justAddedSkills,(item:any)=>item.id)
        }))
    },[justAddedSkills])

    const finalData = searchkey?.trim()?.length > 0 ? searchedItems : optimizedSkills;
    const dataWithFilter =
        selectedFilterIds?.length > 0 ? finalData?.filter((item) => selectedFilterIds?.includes(item.id)) : finalData;

    return (
        <div style={{}}>
            <div
                style={{ alignItems: 'center', flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}
            >
                <div style={{ width: '80%' }}>
                    <CustomText fontFamily={baseFontFamily.OpenSansSemiBold} style={{}} fontSize={baseFontSize.mini}>
                        Choisir le(s) compétence(s) pour ce programme:
                    </CustomText>

                    <SelectedElement finalData={finalData} selectedIds={program?.skillIds} />
                </div>

                <CustomLoadingButton
                    style={{ backgroundColor: baseColors.orange.primary }}
                    width={280}
                    label="Ajouter une nouvelle competence"
                    handleClick={toggleModal}
                    disabled={program?.title?.trim() === ''}
                />
            </div>

            <Spacer height={10} />

            <ListItemFormCheckBox
                withFilter
                minHeight={`calc(100vh - 655px)`}
                selectedRows={new Set(program?.skillIds)}
                handleChange={(value: any) => setProgram((old: any) => ({ ...old, skillIds: Array.from(value) }))}
                items={dataWithFilter ?? []}
                loading={fetchingSkills}
                labelKey="name"
                handleSearch={(value: string) => handleSearch(value)}
                maxItems={optimizedSkills?.length}
                selectedFilterIds={selectedFilterIds}
                setSelectedFilterIds={setSelectedFilterIds}
            />

            <SkillModal open={moduleFormOpen} skill={skill} setSkill={setSkill} toggleModal={toggleModal} setJustAddedSkills={setJustAddedSkills} />
        </div>
    );
};
