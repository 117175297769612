function SvgCollationIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={41}
      height={41}
      fill="none"
      viewBox="0 0 41 41"
      {...props}
    >
      <g clipPath="url(#clip0)"  fill={props.fill}>
        <path d="M40.677.102a.684.684 0 00-.665-.03l-1.062.53-1.06-.53a.683.683 0 00-.612 0l-1.061.53-1.061-.53a.683.683 0 00-.611 0l-1.061.53-1.061-.53a.683.683 0 00-.611 0l-1.062.53-1.06-.53a.683.683 0 00-.612 0l-1.061.53-1.061-.53a.684.684 0 00-.611 0l-1.061.53-1.061-.53a.684.684 0 00-.611 0l-1.062.53-1.06-.53a.683.683 0 00-.612 0l-1.06.53-1.062-.53a.683.683 0 00-.61 0l-1.062.53-1.061-.53a.683.683 0 00-.611 0l-1.062.53-1.06-.53a.683.683 0 00-.611 0l-1.062.53-1.06-.53a.684.684 0 00-.99.612v4.104a.683.683 0 00.07.286.517.517 0 00.02.049L12.3 9.749v17.44l-.546 1.234 1.25.554.606-1.366a.676.676 0 00.057-.278V9.567c0-.12-.03-.238-.09-.342l-2.15-3.758H39.14l-2.15 3.758a.681.681 0 00-.09.342v17.766c0 .096.02.19.06.278l2.306 5.19H36.9v1.366h2.734v2.31l-.378-.189a.683.683 0 00-.611 0l-1.061.531-.378-.189-.611 1.223.683.342a.683.683 0 00.611 0l1.061-.53 1.062.53a.684.684 0 00.988-.612v-4.1c0-.01-.005-.019-.006-.028a.673.673 0 00-.044-.22c-.003-.007-.004-.016-.007-.022v-.007l-2.676-6.017V9.749l2.643-4.624a.59.59 0 00.02-.048.687.687 0 00.07-.289V.684a.683.683 0 00-.323-.582zM39.634 4.1h-28.7V1.79l.378.189a.684.684 0 00.61 0l1.062-.531 1.061.53a.684.684 0 00.611 0l1.061-.53 1.062.53a.683.683 0 00.61 0l1.061-.53 1.062.53a.683.683 0 00.61 0l1.062-.53 1.061.53a.683.683 0 00.611 0l1.061-.53 1.061.53a.683.683 0 00.611 0l1.061-.53 1.062.53a.683.683 0 00.61 0l1.062-.53 1.061.53a.683.683 0 00.61 0l1.062-.53 1.061.53a.684.684 0 00.611 0l1.061-.53 1.062.53a.684.684 0 00.61 0l1.062-.53 1.061.53a.683.683 0 00.61 0l.379-.189V4.1z" />
        <path d="M16.4 27.334a.688.688 0 00.306-.069l2.59-1.298H28.7a6.157 6.157 0 006.15-6.15v-8.2a.683.683 0 00-.988-.612l-2.59 1.295h-9.405a6.158 6.158 0 00-6.15 6.15v8.2c0 .378.306.684.683.684zm.684-8.884a4.789 4.789 0 014.783-4.783h9.567c.106 0 .21-.024.305-.072l1.745-.872v7.094A4.789 4.789 0 0128.7 24.6h-9.566a.686.686 0 00-.306.069l-1.744.875V18.45zM.073 37.889l.53 1.061-.53 1.061a.682.682 0 00.61.99h4.1c.01 0 .019-.006.028-.006a.686.686 0 00.205-.042l.045-.016.026-.009 2.592-1.295h20.177l2.589 1.299c.006 0 .013 0 .019.004a.67.67 0 00.286.064h4.1a.684.684 0 00.615-.989l-.534-1.06.53-1.062a.682.682 0 000-.61l-.53-1.062.53-1.062a.683.683 0 000-.61l-.53-1.062.53-1.06a.683.683 0 000-.612l-.53-1.06.53-1.062a.682.682 0 00-.61-.989h-4.1c-.01 0-.018.006-.029.006a.686.686 0 00-.205.041c-.015.007-.03.01-.045.016l-.026.009-2.59 1.295H7.679l-2.59-1.298c-.008-.004-.017-.005-.025-.01l-.046-.015a.671.671 0 00-.205-.042c-.011.003-.02-.002-.03-.002h-4.1a.683.683 0 00-.61.989l.53 1.061-.53 1.061a.683.683 0 000 .611l.53 1.062-.53 1.06a.683.683 0 000 .611l.53 1.062-.53 1.061a.682.682 0 000 .61zm31.36-7.822h2.312l-.19.378a.683.683 0 000 .61l.531 1.062-.53 1.061a.683.683 0 000 .61l.53 1.062-.53 1.061a.683.683 0 000 .611l.53 1.062-.53 1.06a.683.683 0 000 .611l.189.378h-2.311v-9.566zm-25.966.422l1.745.876c.095.046.2.07.305.069h20.5a.686.686 0 00.306-.069l1.744-.876v8.722l-1.744-.872a.683.683 0 00-.306-.072h-20.5a.686.686 0 00-.305.068l-1.745.876v-8.722zM1.98 35.911l-.531-1.06.53-1.062a.683.683 0 000-.61l-.53-1.062.53-1.061a.683.683 0 000-.611l-.188-.378H4.1v9.566H1.79l.189-.378a.683.683 0 000-.61l-.531-1.062.53-1.06a.683.683 0 000-.612z" />
        <path d="M9.567 36.9h16.4a.683.683 0 00.683-.683v-2.734a.683.683 0 00-.683-.683h-16.4a.683.683 0 00-.683.683v2.734c0 .377.306.683.683.683zm.683-2.733h15.034v1.366H10.25v-1.366z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0h41v41H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgCollationIcon
