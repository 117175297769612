import { Grid } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { CustomText } from 'src/components/Text/CustomText';
import { baseFontFamily } from 'src/utils/constants/baseStyles';
import { PatientGoalCard } from 'src/components/CustomCard/PatientGoalCard';
import { PatientService } from 'src/services/Patients';
import CustomCircleLoader from 'src/components/CustomLoader/CustomCircleLoader';
import { PatientContext } from 'src/providers/PatientProvider';

interface ProgramGoalsProps {
    patientId: number;
}

const ProgramGoals: FC<ProgramGoalsProps> = (props) => {
    const { patientId } = props;
    const [goals, setGoals] = useState<any[]>([]);
    const [goalsByProgram, setGoalsByProgram] = useState<any[]>([]);
    const [fetchingGoals, setFetchingGoals] = useState<boolean>(false);
    const { selectedPatientProgramId } = useContext(PatientContext);

    const fetchPatientGoal = () => {
        setFetchingGoals(true);
        PatientService.getGoalPatients(patientId)
            .then((response) => {
                const data = response?.data?.data;
                const allGoals = data?.skills?.flatMap((skill: any) => skill?.goals);
                setGoals(allGoals);
                setFetchingGoals(false);
            })
            .catch((err) => {
                setFetchingGoals(false);
            });
    };

    useEffect(() => {
        fetchPatientGoal();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patientId]);

    useEffect(() => {
        setGoalsByProgram(() => {
            if (selectedPatientProgramId > 0 && goals?.length > 0) {
                const filteredGoal = goals?.filter((goal: any) => goal?.program?.id === selectedPatientProgramId) ?? [];
                let uniqueGoalParentIds:any = {};
                let uniqueData = filteredGoal.filter(item => {
                    if (!uniqueGoalParentIds[item.goalParent.id]) {
                        uniqueGoalParentIds[item.goalParent.id] = true;
                        return true;
                    }
                    return false;
                }) ?? [];
                return uniqueData;
            }
            return goals;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPatientProgramId, goals]);

    return (
        <Grid item xs={3} style={{ maxWidth: '50%', flexBasis: '50%' }}>
            <CustomText fontSize={16} fontFamily={baseFontFamily.BreeSerif}>
                Objectifs
            </CustomText>
            <Grid container style={{ marginTop: 20 }} direction={'column'}>
                {fetchingGoals ? (
                    <CustomCircleLoader height={25} width={25} />
                ) : !goalsByProgram ? (
                    <CustomText>Pas d'objectif réalisé.</CustomText>
                ) : (
                    <div
                        style={{
                            overflow: 'hidden',
                            height: `calc(100vh - 595px)`,
                            minHeight: 150,
                            overflowY: 'scroll',
                            paddingRight: 20,
                        }}
                    >
                        {goalsByProgram?.map((item) => (
                            <PatientGoalCard key={item.id} title={item.name} state={item.state.name} data={item} />
                        ))}
                    </div>
                )}
            </Grid>
        </Grid>
    );
};

export default ProgramGoals;
