const checkValueExistance = ({
    prev,
    programId,
    setCheckedPrograms,
    allItems,
}: {
    prev: any;
    programId: string | number;
    setCheckedPrograms: any;
    allItems?: any;
}) => {
    const itemIds = Array.from(allItems, (item: any) => item?.id || item?.value) ?? [];

    if (prev.length === 0) {
        if (programId === -1) {
            setCheckedPrograms(itemIds);
            return [-1, ...itemIds];
        } else {
            setCheckedPrograms([programId]);
            return [programId];
        }
    }
    if (prev.length > 0) {
        if (prev.includes(programId)) {
            if (programId === -1) {
                setCheckedPrograms([]);
                return [];
            } else {
                const res = prev.filter((item: number) => item !== programId && item !== -1);
                setCheckedPrograms(res);
                return res;
            }
        } else {
            let res = [...prev, programId];
            if (programId === -1) {
                res = [-1, ...itemIds];
                return res;
            }
            setCheckedPrograms(res);
            return res;
        }
    }
};

const handleSearchItem = ({ searchkey, items, setItems }: { searchkey: string; items: any; setItems: any }) => {
    const res = items.filter((item: any) =>
        JSON.stringify(item?.title ?? item?.name)
            .toLowerCase()
            .includes(searchkey.toLowerCase()),
    );
    setItems(res ?? []);
};

const allOptions = {
    id: -1,
    value: -1,
    title: 'Tout sélectionner',
    secondTitle: 'Tout désélectionner',
    name: 'Tout sélectionner',
};

const allOptionName = (specialityIds: any) =>
    specialityIds?.includes(-1) ? allOptions?.secondTitle : allOptions?.title;

const defaultTimeFilters = [
    {
        id: 1,
        value: 15,
        label: 15,
    },
    {
        id: 2,
        value: 45,
        label: 45,
    },
    {
        id: 3,
        label: 1,
        value: 60,
        isHour: true,
    },
    {
        id: 4,
        label: 2,
        value: 120,
        isHour: true,
    },
];

const handleCheckIfValidTime = ({ startTime, endTime }: { startTime: number; endTime: number }) => startTime > endTime;

const handleCheckTimeValue = ({ startTime, endTime }: { startTime: number; endTime: number }) => {
    if (startTime === 0 && endTime > 0) {
        return [endTime, endTime];
    }
    if (startTime > 0 && endTime > 0) {
        return [startTime, endTime];
    }
};

const handleAddAllValue = ({ setSelected, selectedItems, combinedData }: any) => {
    setSelected((prev: any) => {
        if (selectedItems?.length === combinedData?.length - 1) {
            return [...prev, -1];
        }
        return prev;
    });
};

export {
    handleAddAllValue,
    handleCheckTimeValue,
    defaultTimeFilters,
    allOptions,
    checkValueExistance,
    handleSearchItem,
    handleCheckIfValidTime,
    allOptionName,
};
