import { FC } from 'react';
import { WrapperComponent } from 'src/components/Wrapper/Wrapper';
import { NotFound } from 'src/views/Error/NotFound';

interface IProps {
    isAllowed?: boolean;
    redirectPath?: string;
    children?: any;
    isProgram?: boolean;
    isHdj?: boolean;
}

const ProtectedRoute: FC<IProps> = (props) => {
    const { isAllowed, children } = props;
    return <WrapperComponent>{!isAllowed ? <NotFound /> : children}</WrapperComponent>;
};

export default ProtectedRoute;
