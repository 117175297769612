import { Dispatch, FC, SetStateAction } from 'react';
import { Box, Typography } from '@mui/material';
import { baseFontFamily, baseFontWeight } from 'src/utils/constants/baseStyles';
import { CustomText } from 'src/components/Text/CustomText';
import Button from '@mui/material/Button';
import HDJManagementFormCard from 'src/components/HDJManagementFormCard';
import { reorderedData } from 'src/views/Hdj/HdjLogic';
import { handleChange } from './CustomHdjCheckListContentLogic';
import { sxStyles } from './styles';
interface CustomHdjCheckListContentProps {
    items?: any;
    label: string;
    btnLabel: string;
    setHdj?: any;
    selectedItems?: any;
    loading?: boolean;
    toggleModal?: any;
    handleSearch?: any;
    isConsultation?: boolean;
    isWorkshop?: boolean;
    isDocs?: boolean;
    disableCheckBox?: boolean;
    marginRight?: boolean;
    withoutBtn?: boolean;
    edit?: boolean;
    hdjRelation?: boolean;
    setSelectedHdj?: Dispatch<SetStateAction<number[]>>;
    defaultBtnAction?: Function;
    linking?: boolean;
}

const CustomHdjCheckListContent: FC<CustomHdjCheckListContentProps> = (props) => {
    const {
        label,
        selectedItems,
        items,
        isConsultation,
        btnLabel,
        setHdj,
        loading,
        toggleModal,
        handleSearch,
        isWorkshop,
        isDocs,
        disableCheckBox,
        marginRight,
        withoutBtn,
        edit,
        hdjRelation,
        setSelectedHdj,
        defaultBtnAction,
        linking,
    } = props;
    const reorderdItems = selectedItems?.length > 0 ? reorderedData(items, selectedItems) : items;

    const onBtnClick = () => (hdjRelation ? defaultBtnAction?.() : toggleModal?.());

    const selectedRows = new Set(
        selectedItems?.length > 0 ? Array.from(selectedItems, (item: { id: number }) => item.id) : [],
    );

    return (
        <Box sx={{ 
                width: '40%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between'
            }} 
            marginRight={marginRight ? 2 : undefined}>
            <CustomText fontSize={13} fontFamily={baseFontFamily.OpenSansSemiBold}>{`${label} : `}</CustomText>
            <Box style={{ marginTop: 20, marginBottom: 25 }}>
                <HDJManagementFormCard
                    contentHeight={225}
                    maxItems={50}
                    loading={loading}
                    labelKey={'name'}
                    items={reorderdItems}
                    selectedRows={selectedRows}
                    handleChange={(selected: Set<number>) =>
                        handleChange({
                            setSelectedHdj,
                            selected,
                            setHdj,
                            isConsultation,
                            isWorkshop,
                            isDocs,
                            hdjRelation,
                        })
                    }
                    handleSearch={handleSearch}
                    disableCheckBox={disableCheckBox}
                />
            </Box>

            {(!withoutBtn || edit) && (
                <Button disabled={linking} onClick={onBtnClick} style={sxStyles.btn} variant="outlined" size="large">
                    <Typography fontWeight={baseFontWeight.semiBold} fontSize={10}>
                        {btnLabel}
                    </Typography>
                </Button>
            )}
        </Box>
    );
};

export default CustomHdjCheckListContent;
