import Axios from 'src/services/AxiosInstance';
import { Action, ActionType } from './Actions';
import TokenService from 'src/services/Token.service';

interface AuthData {
    token: string;
    isLogged: boolean;
}

interface State {
    auth: AuthData;
}

const initialState = {
    auth: {
        isLogged: false,
        token: '',
    },
};

export const STORAGE_KEY = `authData`;

export function saveState<T = AuthData>(storeState: T): boolean {
    if (!sessionStorage) {
        return false;
    }

    try {
        const serializedState = JSON.stringify(storeState);
        sessionStorage.setItem(STORAGE_KEY, serializedState);
        return true;
    } catch (error) {
        throw new Error('store serialization failed');
    }
}

export function loadState<T = AuthData>(): T | undefined {
    if (!sessionStorage) {
        return;
    }

    try {
        const serializedState = sessionStorage.getItem(STORAGE_KEY);
        const currentToken = (serializedState && JSON.parse(serializedState)) ?? null;

        if (currentToken?.token) {
            TokenService.checkTokenExpirationMiddleware(currentToken.token);
        }

        if (serializedState === null) {
            return;
        }
        return JSON.parse(serializedState);
    } catch (error) {
        throw new Error('store deserialization failed');
    }
}

export const authReducer = (state: State = initialState, action: Action): State => {
    switch (action.type) {
        case ActionType.LOGIN:
            const auth = {
                isLogged: true,
                token: action.payload,
            };
            saveState(auth);
            Axios.defaults.headers.common['Authorization'] = 'Bearer ' + action.payload;
            return {
                auth: {
                    isLogged: true,
                    token: action.payload,
                },
            };
        case ActionType.LOGOUT:
            const authLogout = {
                isLogged: true,
                token: action.payload,
            };
            saveState({ ...authLogout, isLogged: false });
            Axios.defaults.headers.common['Authorization'] = '';
            return {
                auth: {
                    isLogged: false,
                    token: '',
                },
            };
        default:
            return state;
    }
};
