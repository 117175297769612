import { baseColors } from 'src/utils/constants/baseStyles';

function SvgPhysics(props) {
    return (
        <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2859_18129)">
                <path
                    d="M23.4009 45.869C11.9872 45.1556 4.98166 39.0645 3.78489 37.9454C3.66386 37.8322 3.5048 37.7692 3.33911 37.7692H1.80195C0.621807 37.7692 -0.241624 36.6512 0.0608539 35.5105C4.72351 17.9249 15.8096 8.25264 16.9975 7.2559C17.0666 7.19788 17.1223 7.12584 17.1623 7.04501C19.6941 1.92049 24.392 0.394083 25.7342 0.0532524C25.957 -0.0033535 26.1924 0.0618357 26.355 0.224374L33.6692 7.5386C33.9498 7.81913 33.9167 8.28306 33.5994 8.52111L27.5041 13.0926C27.2233 13.3032 26.8263 13.2537 26.6058 12.9807L25.5667 11.694C25.3673 11.4471 25.0196 11.379 24.7428 11.534C24.1448 11.8689 22.9655 12.39 21.2272 12.5378C20.9198 12.564 20.6741 12.8055 20.6387 13.1119L19.1717 25.8221C19.1151 26.313 19.6041 26.6884 20.0622 26.5029C21.38 25.9695 23.8774 25.1696 27.001 25.1696C31.5301 25.1696 34.7429 26.7705 36.0547 27.562C36.3961 27.768 36.839 27.6229 36.9924 27.2548C39.3673 21.5557 45.7516 18.166 53.3323 17.978C53.6984 17.9689 54.0003 18.2643 54.0003 18.6305V53.3167C54.0003 53.6768 53.7085 53.9686 53.3484 53.9686H49.0914C44.1822 53.9686 39.9042 49.8813 37.6636 43.8339C37.5284 43.4691 37.1088 43.299 36.7614 43.474C35.1022 44.3102 30.3468 46.3031 23.4009 45.869Z"
                    fill="#FFDAAA"
                />
                <path
                    d="M23.4009 45.869C11.9872 45.1556 4.98166 39.0645 3.78489 37.9454C3.66386 37.8322 3.5048 37.7692 3.33911 37.7692H1.80195C0.621807 37.7692 -0.241624 36.6512 0.0608539 35.5105C4.72351 17.9249 15.8096 8.25264 16.9975 7.2559C17.0666 7.19788 17.1223 7.12584 17.1623 7.04501C19.6941 1.92049 24.392 0.394083 25.7342 0.0532524C25.957 -0.0033535 26.1924 0.0618357 26.355 0.224374L33.6692 7.5386C33.9498 7.81913 33.9167 8.28306 33.5994 8.52111L27.5041 13.0926C27.2233 13.3032 26.8263 13.2537 26.6058 12.9807L25.5667 11.694C25.3673 11.4471 25.0196 11.379 24.7428 11.534C24.1448 11.8689 22.9655 12.39 21.2272 12.5378C20.9198 12.564 20.6741 12.8055 20.6387 13.1119L19.1717 25.8221C19.1151 26.313 19.6041 26.6884 20.0622 26.5029C21.38 25.9695 23.8774 25.1696 27.001 25.1696C31.5301 25.1696 34.7429 26.7705 36.0547 27.562C36.3961 27.768 36.839 27.6229 36.9924 27.2548C39.3673 21.5557 45.7516 18.166 53.3323 17.978C53.6984 17.9689 54.0003 18.2643 54.0003 18.6305V53.3167C54.0003 53.6768 53.7085 53.9686 53.3484 53.9686H49.0914C44.1822 53.9686 39.9042 49.8813 37.6636 43.8339C37.5284 43.4691 37.1088 43.299 36.7614 43.474C35.1022 44.3102 30.3468 46.3031 23.4009 45.869Z"
                    fill="#FFDAAA"
                />
                <path
                    d="M27.2119 8.17751C26.7772 8.06136 26.3319 8.3156 26.2158 8.75041C26.1824 8.86504 25.3427 11.5601 20.6669 11.755C20.2989 11.7703 19.9984 12.0276 19.9116 12.367C19.5509 12.4406 19.1572 12.5004 18.728 12.5369C18.4205 12.5631 18.1749 12.8045 18.1394 13.111L16.6725 25.8212C16.6158 26.3121 17.1049 26.6874 17.563 26.502C17.984 26.3316 18.5259 26.134 19.1698 25.9439C19.1669 25.9039 19.1665 25.8631 19.1713 25.8212L20.6074 13.3782L20.6383 13.111C20.6737 12.8045 20.9194 12.5631 21.2268 12.5369C21.3351 12.5277 21.4412 12.517 21.5451 12.5051C21.5946 12.4994 21.6418 12.4925 21.6902 12.4862C21.7436 12.4792 21.7981 12.4729 21.8504 12.4653C21.9142 12.4561 21.9755 12.4456 22.0374 12.4354C22.0721 12.4298 22.1079 12.4247 22.1421 12.4187C22.2184 12.4054 22.2923 12.3908 22.3659 12.3762C22.3838 12.3727 22.4026 12.3695 22.4203 12.3659C22.5017 12.3492 22.5808 12.3316 22.6587 12.3135C22.6675 12.3115 22.6768 12.3096 22.6855 12.3077C22.7697 12.2879 22.8516 12.2674 22.9316 12.2463C22.9335 12.2457 22.9355 12.2453 22.9375 12.2449C23.0193 12.2233 23.0988 12.201 23.1763 12.1783C23.1764 12.1783 23.1765 12.1783 23.1766 12.1782C23.8813 11.971 24.4047 11.7225 24.7425 11.5334C25.0194 11.3784 25.367 11.4465 25.5663 11.6934L25.7166 11.8795C27.3545 10.7108 27.7619 9.27736 27.788 9.17947C27.9041 8.74433 27.6469 8.29333 27.2119 8.17751Z"
                    fill="#FFBE8A"
                />
                <path
                    d="M31.1701 7.53773C31.4506 7.81826 31.4176 8.28219 31.1002 8.52024L26.058 12.3019L26.6055 12.9797C26.826 13.2528 27.2231 13.3022 27.5038 13.0916L33.5991 8.52013C33.9165 8.28208 33.9495 7.81815 33.669 7.53762L26.3548 0.223397C26.1921 0.0608591 25.9568 -0.00433006 25.7339 0.0522759C25.4004 0.137022 24.8592 0.295106 24.1958 0.563359L31.1701 7.53773Z"
                    fill="#FFBE8A"
                />
                <path
                    d="M53.3318 17.9768C52.6253 17.9943 51.9293 18.0398 51.2458 18.1123C51.4006 18.2315 51.501 18.4185 51.501 18.6294V53.3156C51.501 53.6757 51.2092 53.9675 50.8491 53.9675H53.348C53.7081 53.9675 53.9999 53.6757 53.9999 53.3156V18.6293C53.9999 18.2631 53.698 17.9676 53.3318 17.9768Z"
                    fill="#FFBE8A"
                />
                <path
                    d="M45.0005 45.7829C44.5504 45.7829 44.1857 45.4182 44.1857 44.968C44.1857 39.4624 39.7064 34.9832 34.2009 34.9832H30.601C30.1509 34.9832 29.7861 34.6185 29.7861 34.1684C29.7861 33.7182 30.1509 33.3535 30.601 33.3535H34.2009C40.6051 33.3535 45.8154 38.5638 45.8154 44.968C45.8154 45.4182 45.4506 45.7829 45.0005 45.7829Z"
                    fill="#FFBE8A"
                />
                <path
                    d="M6.21659 38.5834C6.21659 38.1333 5.85175 37.7686 5.40172 37.7686H3.33936C3.50504 37.7686 3.66411 37.8317 3.78514 37.9448C4.06143 38.2031 4.64803 38.7268 5.52623 39.3878C5.91704 39.3278 6.21659 38.9911 6.21659 38.5834Z"
                    fill="#FFBE8A"
                />
                <path
                    d="M11.834 12.6054L19.8696 19.7726L20.6384 13.1114C20.6551 12.9672 20.7195 12.8385 20.8138 12.7387L17.0921 7.15625C17.0642 7.19243 17.0327 7.22579 16.9974 7.25534C16.5204 7.6556 14.4465 9.45623 11.834 12.6054Z"
                    fill={baseColors.green.primary}
                />
                <path
                    d="M20.6894 12.9285C20.6925 12.9215 20.6945 12.914 20.6979 12.9071C20.7274 12.8447 20.7671 12.7883 20.8139 12.7387L20.4777 12.2344C19.9771 12.3691 19.394 12.4807 18.7282 12.5374C18.4207 12.5636 18.1751 12.805 18.1396 13.1115L17.604 17.7519L19.8697 19.7727L20.6385 13.1115C20.6427 13.0748 20.6502 13.0391 20.6603 13.0046C20.668 12.9782 20.6788 12.9533 20.6894 12.9285Z"
                    fill="#1B8E95"
                />
                <path
                    d="M37.8005 28.6746C38.0314 28.6746 38.2607 28.5772 38.4219 28.3875C38.7134 28.0446 38.6716 27.5303 38.3287 27.2389C38.0166 26.9737 37.6892 26.7217 37.3491 26.4814C37.2226 26.7346 37.1017 26.9911 36.992 27.2542C36.8386 27.6221 36.3957 27.7673 36.0543 27.5613C36.0071 27.5327 35.9566 27.5029 35.9043 27.4723C36.3889 27.7823 36.847 28.1185 37.2733 28.4808C37.4266 28.611 37.6141 28.6746 37.8005 28.6746Z"
                    fill="#FFBE8A"
                />
                <path
                    d="M43.3377 38.7595C42.9865 38.4784 42.4735 38.5357 42.1925 38.8872C40.6689 40.7933 38.8599 42.3197 36.7769 43.4668C37.1212 43.3043 37.5302 43.4741 37.6634 43.8335C37.7659 44.1101 37.8741 44.3812 37.985 44.6493C40.0831 43.4264 41.9194 41.8391 43.4656 39.9048C43.7465 39.5531 43.6893 39.0404 43.3377 38.7595Z"
                    fill="#FFBE8A"
                />
            </g>
            <defs>
                <clipPath id="clip0_2859_18129">
                    <rect width="54" height="54" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default SvgPhysics;
