import moment from 'moment';
import { FC, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import CustomDatePicker from 'src/components/CustomDatePicker';
import CustomCircleLoader from 'src/components/CustomLoader/CustomCircleLoader';
import CustomModal from 'src/components/CustomModal';
import { InputField } from 'src/components/InputField/InputField';
import { Spacer } from 'src/components/Spacer/Spacer';
import { CustomText } from 'src/components/Text/CustomText';
import { PatientService } from 'src/services/Patients';
import ProgramService from 'src/services/Program/program.service';
import { baseFontFamily, baseColors } from 'src/utils/constants/baseStyles';
import { ServerError } from 'src/utils/constants/constants';
import { CustomSelect } from '../../../../../components/InputField/CustomSelect';
import useStyles, { sxStyles } from './styles';
import { ObservationType } from 'src/interfaces/interfaces';
import { ObservationFormBtn } from './ObservationFormBtn';
import { fullName } from 'src/utils/utils';
import { ContentState, EditorState } from 'draft-js';
import { CustomTextEditor } from 'src/components/CustomEditor/CustomTextEditor';
import { Box } from '@mui/material';
import { optimizePatientsData } from './ObservationLogic';
import { IntervenantCardForm } from './Content/IntervenantCardForm';
import { PatientContext } from 'src/providers/PatientProvider';

interface ObservationsFormProps {
    open: boolean;
    view: string;
    setOpen: Function;
    setView: Function;
    patientId?: number;
    triggerCallback?: Function;
    observationData?: any;
    observationType?: ObservationType;
    setUpdateObservationsList: Function;
    readStatus: boolean;
}

export const ObservationForm: FC<ObservationsFormProps> = (props) => {
    const {
        open,
        view,
        setView,
        setOpen,
        patientId,
        observationData,
        triggerCallback,
        setUpdateObservationsList,
        readStatus,
    } = props;
    const defaultObservation = {
        date: moment(new Date()).format('YYYY/MM/DD'),
        title: '',
        typeId: -1,
        attendeeIds: [],
        patientId: patientId,
        programId: null,
        details: '',
        recipients: [],
        externalUsersMail: [],
    };

    const [observation, setObservation] = useState<any>(defaultObservation);

    const [programs, setPrograms] = useState<any[]>([]);
    const [typeObs, setTypeObs] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [saving, setSaving] = useState<boolean>(false);
    const [activeInput, setActiveInput] = useState<boolean>(false);
    const classes = useStyles();
    const [observationList, setObservationList] = useState<any[]>([]);
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const [patients, setPatients] = useState<any[]>([]);
    const rerender = false;

    const [observationTypes, setObservationTypes] = useState([]);
    const { setObservationId, setRead } = useContext(PatientContext);

    const parseObsevationData = (observations: any[]) => {
        const rows: any[] = Array.from(observations, (obs) => ({
            id: obs.id,
            label: obs.name,
            value: obs.name,
        }));
        setObservationList(rows);
    };

    const handleFetchPatients = () => {
        PatientService.getProfessionals()
            .then((res: any) => {
                const responses = res?.data?.data;
                setPatients(responses);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const typeObservation = () => {
        if (observationTypes) {
            PatientService.getObservationType()
                .then((res) => {
                    const type = res?.data.data.items;
                    parseObsevationData(type);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    const getData = async () => {
        setLoading(true);
        setRead(true);
        try {
            const programeDataByPatient = await ProgramService.getProgramsByPatientId(patientId);
            const programeData = Array.from(programeDataByPatient?.data?.data, (data: any) => data?.program);

            const typeObservationData = await PatientService.getObservationType();

            if (programeData) setPrograms(programeData);

            if (typeObservationData?.data?.data?.items) {
                setTypeObs(typeObservationData?.data?.data?.items);
            }

            setLoading(false);
            setRead(false);
        } catch (e) {
            toast.error(ServerError.unknown);
            setLoading(false);
        }
    };

    useEffect(() => {
        getData();
        handleFetchPatients();
        typeObservation();
        setObservation((old: any) => ({ ...old, patientId: patientId }));
    }, [observationData]);

    useEffect(() => {
        if (!observationData?.id) {
            setObservation(defaultObservation);
            setEditorState(() => EditorState.createEmpty());
        } else {
            setLoading(true);
            setObservation({
                date: moment(observationData?.date).format('YYYY/MM/DD'),
                title: observationData.title,
                typeId: observationData?.type?.id,
                attendeeIds: observationData?.attendees[0]?.attendeeUser?.id,
                patientId: patientId,
                programId: observationData?.program?.id,
                details: observationData?.details,
                creator: observationData?.creator?.id,
                recipients: [observationData?.attendees[0]?.attendeeUser?.id],
            });
            setEditorState(() => EditorState.createWithContent(ContentState.createFromText(observationData?.details)));
            setLoading(false);
        }
    }, [observationData?.id, open]);

    const emptyattendeeId = !Boolean(observationData?.attendees[0]?.attendeeUser?.id > 0);

    const optimizedPatients = optimizePatientsData(patients);

    const removeId: any = { ...observation };
    delete removeId.id;

    const title = observationData?.id
        ? view === 'details'
            ? observation?.title
            : 'Editer observation'
        : 'Nouvelle observation';

    const patientHasNoProgram = programs?.length === 0;

    return (
        <div>
            <CustomModal
                width={700}
                height={`calc(100vh - 270px)`}
                open={open}
                title={title}
                toggleModal={() => {
                    setOpen();
                    setObservationId(0);
                    // setRead(true);
                    setView('details');
                }}
                footer={
                    <div style={{ marginTop: 20 }}>
                        <ObservationFormBtn
                            view={view}
                            setView={setView}
                            triggerCallback={triggerCallback}
                            observationData={observationData}
                            observation={observation}
                            setObservation={setObservation}
                            defaultObservation={defaultObservation}
                            setOpen={setOpen}
                            saving={saving}
                            setSaving={setSaving}
                            setUpdateObservationsList={setUpdateObservationsList}
                            setActiveInput={setActiveInput}
                            setEditorState={setEditorState}
                        />
                    </div>
                }
            >
                <Box
                    maxWidth={`calc(100vh + 200px)`}
                    sx={sxStyles.scrollContainer}
                    style={{
                        overflowY: 'scroll',
                        padding: 2,
                    }}
                >
                    <Spacer height={20} />
                    {loading ? (
                        <div style={{ display: 'flex', justifyContent: 'center', padding: 20 }}>
                            <CustomCircleLoader />
                        </div>
                    ) : (
                        <div className={classes.col}>
                            <div style={{ maxWidth: '100%' }}>
                                <div className={classes.row} style={{ alignItems: 'center' }}>
                                    <CustomText style={{ width: '34%' }} fontFamily={baseFontFamily.OpenSansSemiBold}>
                                        Titre de l'observation :
                                    </CustomText>
                                    {observationData?.id ? (
                                        view === 'details' ? (
                                            <CustomText
                                                style={{ width: '34%' }}
                                                fontFamily={baseFontFamily.OpenSansSemiBold}
                                            >
                                                {observation?.title}
                                            </CustomText>
                                        ) : (
                                            <InputField
                                                label=""
                                                idSuffix="titre_observation"
                                                placeholder="Taper votre texte"
                                                disabled={saving}
                                                value={observation?.title}
                                                onChange={(value: any) => {
                                                    setObservation((old: any) => ({ ...old, title: value }));
                                                }}
                                                width="65%"
                                            />
                                        )
                                    ) : (
                                        <InputField
                                            label=""
                                            idSuffix="titre_observation"
                                            placeholder="Taper votre texte"
                                            disabled={saving}
                                            value={observation?.title}
                                            onChange={(value: any) => {
                                                setObservation((old: any) => ({ ...old, title: value }));
                                            }}
                                            width="65%"
                                        />
                                    )}
                                </div>

                                <Spacer height={20} />

                                <div className={classes.row} style={{ alignItems: 'center' }}>
                                    <CustomText style={{ width: '34%' }} fontFamily={baseFontFamily.OpenSansSemiBold}>
                                        Date de l'observation :
                                    </CustomText>

                                    <div style={{}}>
                                        {observationData?.id ? (
                                            view === 'details' ? (
                                                <CustomText
                                                    fontFamily={baseFontFamily.OpenSansSemiBold}
                                                    color={baseColors.orange.dark}
                                                    style={{ width: '34%' }}
                                                >
                                                    {moment(observation?.date).format('DD/MM/YYYY')}
                                                </CustomText>
                                            ) : (
                                                <CustomDatePicker
                                                    idpicker="date_observation"
                                                    disabled={saving}
                                                    likeSelect
                                                    validator={true}
                                                    value={observation?.date}
                                                    setValue={(newDate: any) =>
                                                        setObservation((old: any) => ({
                                                            ...old,
                                                            date: moment(newDate).format('YYYY/MM/DD'),
                                                        }))
                                                    }
                                                    width="100%"
                                                />
                                            )
                                        ) : (
                                            <CustomDatePicker
                                                idpicker="date_observation"
                                                disabled={saving}
                                                likeSelect
                                                validator={true}
                                                value={observation?.date}
                                                setValue={(newDate: any) =>
                                                    setObservation((old: any) => ({
                                                        ...old,
                                                        date: moment(newDate).format('YYYY/MM/DD'),
                                                    }))
                                                }
                                                width="100%"
                                            />
                                        )}
                                    </div>
                                </div>

                                <Spacer height={20} />

                                <div
                                    className={classes.row}
                                    style={{
                                        alignItems: 'center',
                                    }}
                                >
                                    <CustomText style={{ width: '34%' }} fontFamily={baseFontFamily.OpenSansSemiBold}>
                                        Type de l'observation:
                                    </CustomText>
                                    {observationData?.id ? (
                                        view === 'details' ? (
                                            <CustomText
                                                fontFamily={baseFontFamily.OpenSansSemiBold}
                                                color={baseColors.orange.dark}
                                            >
                                                {typeObs.map((type) => {
                                                    if (type.id === observation?.typeId) {
                                                        return type.name;
                                                    }
                                                })}
                                            </CustomText>
                                        ) : (
                                            <div style={{ width: '65%' }}>
                                                <CustomSelect
                                                    label=""
                                                    labelKey={'name'}
                                                    defaultValue={observationData?.type?.id}
                                                    onChange={(value: any) =>
                                                        setObservation((old: any) => ({
                                                            ...old,
                                                            typeId: value.toString(),
                                                        }))
                                                    }
                                                    items={[{ name: "Choisir l'observation", id: 0 }, ...typeObs]}
                                                    idSelect="choix-observation"
                                                    width="100%"
                                                />
                                            </div>
                                        )
                                    ) : (
                                        <div style={{ width: '65%' }}>
                                            <CustomSelect
                                                label=""
                                                labelKey={'name'}
                                                defaultValue={observationData?.type?.id}
                                                onChange={(value: any) =>
                                                    setObservation((old: any) => ({ ...old, typeId: value.toString() }))
                                                }
                                                items={[{ name: "Choisir l'observation", id: 0 }, ...typeObs]}
                                                idSelect="choix-observation"
                                                width="100%"
                                            />
                                        </div>
                                    )}
                                </div>

                                <Spacer height={20} />

                                <div className={classes.row} style={{ alignItems: 'center' }}>
                                    <CustomText style={{ width: '34%' }} fontFamily={baseFontFamily.OpenSansSemiBold}>
                                        Programme suivi :
                                    </CustomText>
                                    {observationData?.id ? (
                                        view === 'details' ? (
                                            <CustomText
                                                style={{ width: '34%' }}
                                                fontFamily={baseFontFamily.OpenSansSemiBold}
                                                color={baseColors.orange.dark}
                                            >
                                                {programs.map((program) => {
                                                    if (program.id === observation?.programId) {
                                                        return program.title;
                                                    }
                                                })}
                                            </CustomText>
                                        ) : (
                                            <div style={{ width: '65%' }}>
                                                <CustomSelect
                                                    label=""
                                                    labelKey={'title'}
                                                    defaultValue={observation?.programId}
                                                    onChange={(value: any) => {
                                                        setObservation((old: any) => ({
                                                            ...old,
                                                            programId: value,
                                                        }));
                                                    }}
                                                    items={[{ name: 'Sélectionner un programme', id: 0 }, ...programs]}
                                                    idSelect="choix-observation"
                                                    width="100%"
                                                />

                                                {patientHasNoProgram && (
                                                    <CustomText
                                                        style={{
                                                            textAlign: 'center',
                                                            color: baseColors.orange.primary,
                                                            fontSize: 12,
                                                        }}
                                                    >
                                                        Ce patient n'a aucun programme en cours
                                                    </CustomText>
                                                )}
                                            </div>
                                        )
                                    ) : (
                                        <div style={{ width: '65%' }}>
                                            <CustomSelect
                                                label=""
                                                labelKey={'title'}
                                                defaultValue={observation?.programId}
                                                onChange={(value: any) => {
                                                    setObservation((old: any) => ({ ...old, programId: value }));
                                                }}
                                                items={[{ title: 'Sélectionner un programme', id: 0 }, ...programs]}
                                                idSelect="choix-observation"
                                                width="100%"
                                            />

                                            {patientHasNoProgram && (
                                                <CustomText
                                                    style={{
                                                        textAlign: 'center',
                                                        color: baseColors.orange.primary,
                                                        fontSize: 12,
                                                    }}
                                                >
                                                    Ce patient n'a aucun programme en cours
                                                </CustomText>
                                            )}
                                        </div>
                                    )}
                                </div>

                                <Spacer height={20} />

                                <div className={classes.row} style={{ alignItems: 'center' }}>
                                    {view === 'details' ? (
                                        <CustomText
                                            style={{ width: '34%' }}
                                            fontFamily={baseFontFamily.OpenSansSemiBold}
                                        >
                                            Intervenant(s) mentionné(s) :
                                        </CustomText>
                                    ) : (
                                        <CustomText
                                            style={{ width: '34%' }}
                                            fontFamily={baseFontFamily.OpenSansSemiBold}
                                        >
                                            Intervenant(s) à mentionner :
                                        </CustomText>
                                    )}

                                    {observationData?.id ? (
                                        view === 'details' ? (
                                            emptyattendeeId ? (
                                                'Aucun intervenant à mentionner trouvé'
                                            ) : (
                                                <div>
                                                    {observationData?.attendees.map((item: any) => {
                                                        const firstName = item?.attendeeUser?.firstName ?? '';
                                                        const lastName = item?.attendeeUser?.lastName ?? '';
                                                        return (
                                                            <CustomText
                                                                fontFamily={baseFontFamily.OpenSansSemiBold}
                                                                color={baseColors.orange.dark}
                                                            >
                                                                {fullName(firstName, lastName)}
                                                            </CustomText>
                                                        );
                                                    })}
                                                </div>
                                            )
                                        ) : (
                                            <IntervenantCardForm
                                                patientId={patientId}
                                                rerender={rerender}
                                                defaultSelected={
                                                    observation?.attendeeIds?.length === 0
                                                        ? new Set()
                                                        : new Set(
                                                              observationData?.attendees?.map((item: any) => {
                                                                  return item?.attendeeUser?.id;
                                                              }),
                                                          )
                                                }
                                                handleChange={(value: any[]) => {
                                                    if (!value || value?.length === 0) {
                                                        setObservation((old: any) => ({
                                                            ...old,
                                                            attendeeIds: [],
                                                            recipients: [],
                                                        }));
                                                    } else {
                                                        const newValues: any[] = [];
                                                        Array.from(value).forEach((item: any) => {
                                                            if (item !== -1) {
                                                                newValues.push(item);
                                                            }
                                                        });
                                                        setObservation((old: any) => ({
                                                            ...old,
                                                            attendeeIds: newValues,
                                                            recipients: newValues,
                                                        }));
                                                    }
                                                }}
                                            />
                                        )
                                    ) : (
                                        <IntervenantCardForm
                                            rerender={rerender}
                                            programId={observation.programId}
                                            defaultSelected={
                                                new Set(
                                                    observationData?.attendees?.attendeeUser
                                                        .filter((item: any) => item.type === 2)
                                                        .map((item: any) => item?.id),
                                                )
                                            }
                                            handleChange={(value: any[]) => {
                                                if (!value || value.length === 0) {
                                                    // If 'value' is empty or undefined, reset both arrays to empty.
                                                    setObservation((old: any) => ({
                                                        ...old,
                                                        attendeeIds: [],
                                                        recipients: [],
                                                    }));
                                                } else {
                                                    const newValues: any[] = [];
                                                    Array.from(value).forEach((item: any) => {
                                                        if (item !== -1) {
                                                            // Vérifiez si la valeur est différente de -1
                                                            newValues.push(item);
                                                        }
                                                    });
                                                    setObservation((old: any) => ({
                                                        ...old,
                                                        attendeeIds: newValues,
                                                        recipients: newValues,
                                                    }));
                                                }
                                            }}
                                        />
                                    )}
                                </div>

                                <Spacer height={20} />

                                <div className={classes.row} style={{ alignItems: 'center' }}>
                                    <CustomText style={{ width: '34%' }} fontFamily={baseFontFamily.OpenSansSemiBold}>
                                        Détails de l'observation :
                                    </CustomText>
                                    {observationData?.id ? (
                                        view === 'details' ? (
                                            <CustomText
                                                fontFamily={baseFontFamily.OpenSansSemiBold}
                                                color={baseColors.orange.dark}
                                                style={{
                                                    whiteSpace: 'pre-line',
                                                }}
                                            >
                                                {observation?.details}
                                            </CustomText>
                                        ) : (
                                            <div
                                                className={classes.row}
                                                style={{ justifyContent: 'center', alignItems: 'center', width: '65%' }}
                                            >
                                                <CustomTextEditor
                                                    setEditorState={setEditorState}
                                                    editorState={editorState}
                                                    handleChange={(value: any) => {
                                                        setObservation((old: any) => ({ ...old, details: value }));
                                                    }}
                                                    data={optimizedPatients}
                                                />
                                            </div>
                                        )
                                    ) : (
                                        <div
                                            className={classes.row}
                                            style={{ justifyContent: 'center', alignItems: 'center', width: '65%' }}
                                        >
                                            <CustomTextEditor
                                                setEditorState={setEditorState}
                                                editorState={editorState}
                                                handleChange={(value: any) => {
                                                    setObservation((old: any) => ({ ...old, details: value }));
                                                }}
                                                data={optimizedPatients}
                                            />
                                        </div>
                                    )}
                                </div>

                                <Spacer height={30} />
                            </div>
                        </div>
                    )}
                </Box>
            </CustomModal>
        </div>
    );
};
