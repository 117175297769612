import { Box, Grid } from '@mui/material';
import { FC, useEffect } from 'react';
import { baseColors, baseFontFamily, baseFontSize, baseScreenHeight } from 'src/utils/constants/baseStyles';
import { CustomText } from 'src/components/Text/CustomText';
import { CustomCheckBox } from 'src/components/InputField/CustomCheckBox';
import CustomModal from 'src/components/CustomModal/CustomModal';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import { fullName, uniqArrayNumberValues, uniqByKeyValues } from 'src/utils/utils';
import useProfessionals from 'src/hooks/useProfessionals';
import { handleCheckIntervenantByItem, handleSelectAllIntervenantBySpeciality } from 'src/components/InputField/InputFieldUtils';

interface EventSpecialityWithProfessionalsProps {
    payload?:any;
    setPayload?: any;
    selectedWorkshopData?:any;
    attendeeTypeId?: number;
    toggleModal?: any;
    open: boolean;
    setAttendesName: any;
    professionalIds?: any;
    setProfessionalIds?: any;
    loadingData?: any;
    workshopData?: any;
    checkedSpecialityIds?: any;
    setCheckedSpecialityIds?: any;
}

export const EventSpecialityWithProfessionals: FC<EventSpecialityWithProfessionalsProps> = (props) => {
    const {
        selectedWorkshopData,
        loadingData,
        professionalIds,
        setProfessionalIds,
        setAttendesName,
        open,
        toggleModal,
        attendeeTypeId,
        payload,
        setPayload,
        workshopData,
        checkedSpecialityIds,
        setCheckedSpecialityIds,
    } = props;

    const { professionalsBySpecialities, fetchingProfessionalsBySpecialities } = useProfessionals();

    const professionalsBySpecialitiesWithPatients = professionalsBySpecialities.filter(speciality => speciality.items?.length !==0);

    const names = Array.from(professionalsBySpecialitiesWithPatients, (speciality) => speciality.items);

    function handleSetPayloadOnly() {
        const attendees = Array.from(uniqArrayNumberValues(professionalIds), (id) => ({
            id: id,
            type: 2,
        }));
        setPayload((old: any) => ({
            ...old,
            attendees,
        }));
    }

    const handleValidate = () => {
        const checkedProfessionalNames = names?.flat(1)?.filter((item) => professionalIds.includes(item.id));
        setAttendesName(checkedProfessionalNames);
        handleSetPayloadOnly();
        toggleModal();
    };

    const handleSetAttendees = () => {
        if (workshopData?.attendees?.length > 0 && professionalsBySpecialitiesWithPatients?.length > 0) {
            const attendees = workshopData?.attendees.filter((item:any) => item?.attendeeType?.id === 2)
            const precheckedIds = Array.from(attendees, (item: any) => item?.attendeeUser?.id);
            const precheckedNames =
                Array.from(attendees, (item: any) => ({
                    firstName: item?.attendeeUser?.firstName,
                    lastName: item?.attendeeUser?.lastName,
                    id: item?.attendeeUser?.id,
                })) ?? [];
            const precheckedSpecialityIds = Array.from(attendees, (item: any) => item?.specialityId ? item?.specialityId : item?.attendeeUser?.specialityType?.id );
            setProfessionalIds(precheckedIds);
            setAttendesName(uniqByKeyValues({ items: precheckedNames }));
            setCheckedSpecialityIds(precheckedSpecialityIds);

            const defaultAttendees = Array.from(attendees,(item:any) => ({
                id: item?.attendeeUser?.id,
                type: 2,
            }))
            
            setPayload((old: any) => ({
                ...old,
                attendees:defaultAttendees,
            }));
        }
    };

    const handleSetAttendeesFromWorkshop = () => {
        if (selectedWorkshopData?.attendees?.length > 0) {
            const attendees = selectedWorkshopData?.attendees;
            const precheckedIds = Array.from(attendees, (item: any) => item?.attendeeUser?.id);
            const precheckedNames =
                Array.from(attendees, (item: any) => ({
                    firstName: item?.attendeeUser?.firstName,
                    lastName: item?.attendeeUser?.lastName,
                    id: item?.attendeeUser?.id ,
                })) ?? [];
            const precheckedSpecialityIds = Array.from(attendees, (item: any) => item?.specialityId ? item?.specialityId : item?.attendeeUser?.specialityType?.id );
            setProfessionalIds(precheckedIds);
            setAttendesName(uniqByKeyValues({ items: precheckedNames }));
            setCheckedSpecialityIds(precheckedSpecialityIds);
            const defaultAttendees = Array.from(attendees,(item:any) => ({
                id: item?.attendeeUser?.id,
                type: 2,
            }))
            
            setPayload((old: any) => ({
                ...old,
                attendees:defaultAttendees,
            }));
        }
    }

    useEffect(() => {
        handleSetAttendees();
    }, [workshopData, professionalsBySpecialities,payload?.workshopId]);

    useEffect(() => {
        handleSetAttendeesFromWorkshop();
    },[payload?.workshopId]);

    return (
        <Grid container alignItems="center" direction="row" justifyContent="space-between">
            {!professionalIds?.length && (
                <Grid
                    onClick={fetchingProfessionalsBySpecialities ? undefined : toggleModal}
                    item
                    style={{
                        cursor: 'pointer',
                        backgroundColor: baseColors.green.light,
                        padding: 12,
                        width: '100%',
                        borderRadius: 10,
                    }}
                >
                    {fetchingProfessionalsBySpecialities ? (
                        loadingData
                    ) : (
                        <CustomText
                            color={baseColors.green.primary}
                            fontSize={baseFontSize.mini}
                            fontFamily={baseFontFamily.OpenSansSemiBold}
                        >
                            Sélectionner un ou plusieurs intervenant(s)
                        </CustomText>
                    )}
                </Grid>
            )}

            <CustomModal
                //disableBackdropClick
                width={600}
                height={baseScreenHeight.newEventModal}
                title="Sélectionner un ou plusieurs intervenant(s)"
                open={open}
                toggleModal={toggleModal}
                footer={
                    <Grid container style={{ marginTop: 30 }} justifyContent="center">
                        <CustomLoadingButton
                            width="100%"
                            /*disabled={Object.keys(professionalIds).length===0}*/
                            label="Valider"
                            handleClick={handleValidate}
                        />
                    </Grid>
                }
            >
                {professionalsBySpecialitiesWithPatients?.map((speciality: any, i: number) => (
                    
                    <Box key={speciality.id} style={{ alignSelf: 'flex-start', marginBottom: 5 }}>
                        <CustomCheckBox
                            checked={
                                /*checkedSpecialityIds?.includes(speciality.id)*/
                                speciality?.items?.some((item:any) => professionalIds.includes(item.id))
                            }
                            onClick={handleSelectAllIntervenantBySpeciality({
                                specialityId: speciality.id,
                                intervenants: speciality?.items ?? [],
                                setProfessionalIds,
                                setCheckedSpecialityIds,
                            })}
                            label={speciality?.name}
                        />
                        {speciality?.items?.length > 0 &&
                            speciality?.items?.map((professional: any) => (
                                <Box key={professional.id} style={{ marginLeft: 30 }}>
                                    <CustomCheckBox
                                        onClick={handleCheckIntervenantByItem({
                                            setProfessionalIds,
                                            professionalId: professional.id,
                                            setCheckedSpecialityIds,
                                            specialityId: speciality.id,
                                        })}
                                        checked={professionalIds?.includes(professional.id)}
                                        label={fullName(professional?.firstName, professional?.lastName)}
                                    />
                                </Box>
                            ))}
                    </Box>
                ))}
            </CustomModal>
        </Grid>
    );
};
