import { Box } from '@mui/material';
import { FC, useState } from 'react';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import CustomModal from 'src/components/CustomModal';
import ListItemFormCheckBox from 'src/components/ListItemFormCheckBox';
import { searchElementByName } from 'src/utils/utils';

interface IntervenantListProps {
    toggleModal: any;
    modalOpen: boolean;
    intervenants: any;
    setSelectedIntervenants?: any;
    selectedIntervenants?: any;
    handleFetchCaregivers: Function;
    loading?: boolean;
    isSharingAgenda?: boolean;
    title?: string;
    btnLabel?: string;
    handleShareNyAgenda?: Function;
    posting?: boolean;
}

const IntervenantList: FC<IntervenantListProps> = (props) => {
    const {
        modalOpen,
        toggleModal,
        intervenants,
        setSelectedIntervenants,
        loading,
        isSharingAgenda,
        title,
        btnLabel,
        handleShareNyAgenda,
        posting,
    } = props;
    const [intervenantIds, setIntervenantIds] = useState<Array<any>>([]);
    const [searchedItems, setSearchedItems] = useState<Array<any>>([]);
    const [searchkey, setSearchkey] = useState<string>('');
    const handleChange = (selected: any) => setIntervenantIds(Array.from(selected));
    const handleSearch = (value: string) => {
        setSearchkey(value);
        setSearchedItems(searchElementByName(intervenants, value));
    };
    const handleClick = async () => {
        const checkedUsers = intervenantIds?.filter((item) => item !== 0) ?? [];
        if (isSharingAgenda) {
            handleShareNyAgenda?.(checkedUsers);
        } else {
            handleToogleModal();
            setSelectedIntervenants?.(checkedUsers);
            setSearchkey('');
        }
    };
    const handleToogleModal = () => {
        toggleModal();
        setSearchkey('');
    }
    
    const resetIntervenantIds = () => {
        setIntervenantIds([])
    }

    const items = searchkey?.trim()?.length > 0 ? searchedItems : intervenants;
    return (
        <CustomModal width={300} title={title ?? "Choisir l'intervenant(s)"} clearFormData={resetIntervenantIds} toggleModal={handleToogleModal} open={modalOpen}>
            <Box style={{ marginTop: 20, marginBottom: 25 }}>
                <ListItemFormCheckBox
                    contentHeight={225}
                    maxItems={50}
                    loading={loading}
                    labelKey={'name'}
                    items={items}
                    selectedRows={new Set(intervenantIds)}
                    handleChange={(selected: Set<number>) => handleChange(selected)}
                    handleSearch={handleSearch}
                />
            </Box>

            <CustomLoadingButton
                disabled={intervenantIds.length === 0}
                handleClick={handleClick}
                loading={posting}
                width={300}
                label={btnLabel ?? 'Valider'}
            />
        </CustomModal>
    );
};

export default IntervenantList;
