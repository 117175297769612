import { FC } from 'react';
import { Grid } from '@mui/material';
import { CustomText } from '../../../../components/Text/CustomText';
import { InputField } from '../../../../components/InputField/InputField';
import { isValidMail } from '../../../../utils/helpers/InputValidator';
import { CustomRadio } from '../../../../components/InputField/CustomRadio';
import { changeGender, genders, handleChange } from '../UserUtiles';

interface EmailAndGenderProps {
    lockUserInput: boolean;
    payload: any;
    setPayload: any;
}

export const EmailAndGender: FC<EmailAndGenderProps> = (props) => {
    const { lockUserInput, payload, setPayload } = props;

    return (
        <>
            <Grid lg={12} container direction="row" alignItems={'center'}>
                <Grid item>
                    <CustomText style={{ minWidth: '200px' }}>Email :</CustomText>
                </Grid>
                <InputField
                    label={''}
                    defaultValue={payload?.email ?? ''}
                    value={payload?.email ?? ''}
                    disabled={lockUserInput}
                    onChange={handleChange({ setPayload, inputKey: 'email' })}
                    validator={isValidMail}
                    errorMessage="Email obligatoire"
                    placeholder={"Entrer l'email"}
                    width={360}
                    idSuffix="email-utilisateur"
                    required
                />
            </Grid>

            <Grid lg={12} container direction="row" alignItems={'center'}>
                <Grid item>
                    <CustomText style={{ minWidth: '200px' }}>Genre :</CustomText>
                </Grid>
                <Grid item>
                    <Grid container direction={'row'}>
                        {genders.map((gender) => (
                            <Grid key={gender.id} item>
                                <CustomRadio
                                    checked={payload?.sex === gender.id}
                                    onClick={changeGender({ value: gender.id, setPayload })}
                                    label={gender.name}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};
