import moment from 'moment';

export const formatDate = (date: Date | null | undefined) => {
    return moment(date).format('DD/MM/yyyy');
};

export const getAge = (from: Date | undefined | null, to: Date) => {
    const birth: any = moment(from).format('YYYY');
    const yearsNow: any = moment(to).format('YYYY');
    return (yearsNow - birth).toString();
};
export function capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function truncateString(str: string, num: number) {
    if (str.length > num) {
        return str.slice(0, num) + '...';
    } else {
        return str;
    }
}
export function containsObject(obj: any, list: any[]) {
    var i;
    for (i = 0; i < list.length; i++) {
        if (list[i]?.id === obj?.id) {
            return true;
        }
    }

    return false;
}

export function IsValidStartEndDate(start: Date, end: Date) {
    return moment(end).isAfter(start);
}

export function IsToday(date: Date) {
    return moment(date).isSame(new Date(), 'day');
}

export function parseObjectToString(Object:any){
    let resultString = "";
    for (const key in Object) {
        if (Object.hasOwnProperty(key)) {
            const value = Object[key];
            resultString += `${key} : ${value}\n`;
        }
    }
    return resultString;
}

export const message = {
    patientAddedSuccessfully: 'Patient enregistré avec succès',
    patientModifSuccessfully: 'Patient modifié avec succès',
    patientAlreadyExist: 'le patient existe déjà (utiliser une autre adresse email)',
    patientNotInserted: "Erreur lors de l'ajout d'un patient",
    professionalAddedSuccessfully: 'Professionnel enregistré avec succès',
    professionalAlreadyExist: 'le professionnel existe déjà (utiliser une autre adresse email)',
    professionalNotInserted: "Erreur lors de l'ajout d'un professionnel",
    assistantAddedSuccessfully: 'Assistant ou aidant enregistré avec succès',
    assistantAlreadyExist: 'Le proche ou aidant existe déjà (utiliser une autre adresse email)',
    assistantNotInserted: "Erreur lors de l'ajout d'un assistant ou aidant",
    soignantAddedSuccessfully: 'Soignant enregistré avec succès',
    soignantAlreadyExist: 'le soignant existe déjà (utiliser une autre adresse email)',
    soignantNotInserted: "Erreur lors de l'ajout d'un soignant",
    userAddedSuccessfully: 'Utilisateur crée avec succès',
    articleAddedSuccessfully: 'Contenu crée avec succès',
    userNotInserted: "Erreur lors de l'ajout d'un utilisateur",
    articleNotInserted: "Erreur lors de l'ajout d'un article",
    userAlreadyExist: 'l`utilisateur existe déjà (utiliser une autre adresse email)',
    articleAlreadyExist: 'l`contenu existe déjà',
};
export const title = {
    professionalList: 'Liste des professionels de santé',
    assistantsList: 'Liste des assistants ou aidants',
    professional: 'professional',
    assistant: 'assistant',
};

/* DON'T REMOVE COMMENTED CODE BELLOW */
// const nextRow = transpose(specialistsData)[i + 1];
// const nextRowEventIds = nextRow?.length > 0 && nextRow?.map((event: any) => event?.eventId);
// const currentEventId = cell?.eventId ?? 0;
// let lastEvent: boolean = false;
// if (currentEventId > 0 && !cell?.freeEvent && nextRowEventIds?.length > 0) {
// lastEvent = !nextRowEventIds.includes(currentEventId);
// if (lastEvent) {
// eventData = {
//     freeEvent: true,
//     start: cell?.timeStart,
//     end: cell?.timeStart,
//     specialityTypeId: cell?.specialityTypeId,
// };
// }
// }
// if (!spcData?.freeEvent && spcData?.name === null && lastEvent) {
//     return {
//         backgroundColor: baseRgbColors.white,
//     };
// }
// const handleSetSelectedItems = (selectedItemId: number) => {
//     setSelected((prev: Array<number>) => {
//         if (prev.length > 0) {
//             if (prev.includes(selectedItemId)) {
//                 const res = prev.filter((item: number) => item !== selectedItemId);
//                 handleSet(res);
//                 return res;
//             } else {
//                 const res = [...prev, selectedItemId];
//                 handleSet(res);
//                 return res;
//             }
//         } else {
//             const res = [selectedItemId];
//             handleSet(res);
//             return res;
//         }
//     });
// };
// const handleSet = (newSelectedItems: Array<number>) => {
//     handleChange?.(newSelectedItems);
//     setSelectedIntervenants?.(newSelectedItems);
//     setForceRerender(!forceRerender);
// };

// const checkIfChecked = (itemId: number) => {
//     if (!disableCheckBox) {
//         if (selected?.length) {
//             return selected?.includes(itemId);
//         } else {
//             return preselectedRows?.includes(itemId);
//         }
//     }
//     return true;
// };
