import { FC, useEffect, useState } from 'react';
import useStyles from './styles';
import { Typography, Box } from '@mui/material';
import { baseColors, baseFontFamily, baseFontWeight } from 'src/utils/constants/baseStyles';
import { formatDateFormat } from 'src/utils/utils';
import { useDispatch } from 'react-redux';
import { retrieveEventInformation } from 'src/actions/calendar/events';
import WorkshopModal from 'src/components/CustomModal/WorkshopModal';

interface InformationsDetailsProps {
    selectedEvent?: any;
    currentDate?: any;
    toggleEventModal: Function;
    setIsFromInfos: Function;
}

const InformationsDetails: FC<InformationsDetailsProps> = (props) => {
    const { selectedEvent, currentDate, toggleEventModal, setIsFromInfos } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const [loadingInformation, setLoadingInformation] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);
    const handleFetchData = () => {
        dispatch(retrieveEventInformation(setLoadingInformation, formatDateFormat(currentDate)));
    };

    useEffect(() => {
        handleFetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentDate]);

    const informationsDetails = [
        {
            label: selectedEvent?.eventType,
            value: selectedEvent?.name,
        },
        {
            label: 'Durée',
            value: selectedEvent?.duration ?? '',
        },
        {
            label: 'Lieu',
            value: selectedEvent?.location ?? '',
        },
        {
            label: 'Nombre de patients prévus',
            value: selectedEvent?.patientCount ?? 0,
        },
        {
            label: 'Autres intervenants',
            value: selectedEvent?.intervening,
        },
    ];

    const toggleModal = () => setOpen(!open);

    const handleClick = () => {
        if (selectedEvent?.eventType === 'Atelier') {
            toggleModal();
        } else {
            setIsFromInfos(true);
            toggleEventModal();
        }
    };

    return (
        <Box className={classes.container}>
            <Box className={classes.header}>
                <Typography
                    style={{ fontWeight: baseFontWeight.medium, fontFamily: baseFontFamily.BreeSerif }}
                    fontSize={16}
                    color={baseColors.black}
                >
                    Informations
                </Typography>
                <Typography
                    onClick={handleClick}
                    fontSize={12}
                    color={baseColors.green.primary}
                    className={classes.details}
                >
                    Voir +de détails
                </Typography>
            </Box>

            <Box className={classes.content}>
                {informationsDetails.map((info, i) => (
                    <div className={classes.item} key={i}>
                        <Typography
                            style={{ fontWeight: baseFontWeight.semiBold }}
                            fontSize={12}
                            color={baseColors.black}
                        >
                            {info.label}
                        </Typography>
                        <Typography fontSize={12} color={baseColors.black}>
                            {` : ${info.value}`}
                        </Typography>
                    </div>
                ))}
            </Box>

            <WorkshopModal
                patientForAgendaWorkShop
                open={open}
                toggleModal={toggleModal}
                workShopData={selectedEvent}
            />
        </Box>
    );
};

export default InformationsDetails;
