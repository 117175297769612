import { EventPlanningViewType } from 'src/interfaces/interfaces';
import { ATTENDEE_TYPE } from 'src/utils/constants/enums';

const optimizeEventsForDailyView = (data: any) => {
    if (data?.eventItemCount) {
        return data.eventItems;
    }
    return [];
};

const optimizeEventsForWeeklyView = (data: any) => {
    const allEvents = Array.from(data ?? [], (item: any) => {
        if (item?.eventItemCount > 0) {
            return Array.from(item?.eventItems ?? [], (eventItem: any) => eventItem);
        }
        return [];
    });

    const eventItems = allEvents?.flat(2);
    return eventItems;
};

const optimizeEventsForMonthlyView = (data: any) => {
    const allEvents = Array.from(data?.eventItems ?? [], (item: any) => {
        if (item?.dateItems?.length > 0) {
            return Array.from(item?.dateItems ?? [], (dateItem: any) => dateItem.events);
        }
        return [];
    });
    const eventItems = allEvents?.flat(2);
    return eventItems;
};

const optimizeEvents = ({ data, viewType }: { data: any; viewType: EventPlanningViewType['viewType'] }) => {
    if (viewType === 'daily') {
        return optimizeEventsForDailyView(data);
    }
    if (viewType === 'weekly') {
        return optimizeEventsForWeeklyView(data);
    }
    if (viewType === 'monthly') {
        return optimizeEventsForMonthlyView(data);
    }
    return [];
};

const isConnectedUserIntervenant = ({ event, myId }: { event: any; myId: number }) => {
    const isInterven = event?.attendees?.find(
        (attendee: any) =>
            attendee?.attendeeType?.id === ATTENDEE_TYPE.INTERVENANTS && attendee?.attendeeUser?.id === myId,
    );
    return isInterven?.id > 0 || false;
};

export {
    isConnectedUserIntervenant,
    optimizeEventsForDailyView,
    optimizeEventsForWeeklyView,
    optimizeEventsForMonthlyView,
    optimizeEvents,
};
