import { useQuery, useQueryClient } from '@tanstack/react-query';
import GoalService from 'src/services/Goals/goal.service';

function useFetchGoalsRQHooks() {
    return useQuery({
        queryKey: ['goals'],
        queryFn: async () => await GoalService.GetGoals(),
        onSuccess: () => {},
        onError: () => {},
        select: (response) => {
            const data = response?.data?.data ?? [];
            return data;
        },
    });
}

// PREFETCH
function usePreFetchGoalsRQHooks() {
    const queryClient = useQueryClient();

    return queryClient.prefetchQuery({
        queryKey: ['goals'],
        queryFn: async () => await GoalService.GetGoals(),
    });
}

export { useFetchGoalsRQHooks, usePreFetchGoalsRQHooks };
