import * as React from 'react';
import { SVGProps } from 'react';
import { baseColors } from 'src/utils/constants/baseStyles';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={40} height={41} fill="none" {...props}>
        <rect width={40} height={40} y={0.5} fill="#fff" rx={10} />
        <g clipPath="url(#a)">
            <path
                fill={baseColors.orange.primary}
                d="m27.676 24.519-5.659-10.417A2.327 2.327 0 0 0 20 12.954c-.836 0-1.59.43-2.018 1.148l-.009.016-5.64 10.384a2.32 2.32 0 0 0-.022 2.358 2.32 2.32 0 0 0 2.04 1.186h11.267a2.37 2.37 0 0 0 2.07-1.186 2.312 2.312 0 0 0-.012-2.341Zm-8.615-6.796a.938.938 0 0 1 1.877 0v3.754a.938.938 0 0 1-1.877 0v-3.754ZM20 26.17a1.41 1.41 0 0 1-1.408-1.407A1.41 1.41 0 0 1 20 23.354a1.41 1.41 0 0 1 1.407 1.408A1.41 1.41 0 0 1 20 26.169Z"
            />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M12 12.5h16v16H12z" />
            </clipPath>
        </defs>
    </svg>
);
export { SvgComponent as AlertProfilIcon };
