import { Typography, Box, Paper } from '@mui/material';
import React, { FC } from 'react';
import { baseColors, baseFontFamily, baseFontSize } from '../../utils/constants/baseStyles';
import useStyles from './styles';
import { CustomText } from '../Text/CustomText';

interface DayNumberCardProps {
    icon: any;
    number: number;
    label: string;
    color?: string;
}

const DayNumberCard: FC<DayNumberCardProps> = (props) => {
    const { icon, number, label, color } = props;
    const classes = useStyles();

    return (
        <Paper
            style={{ borderRadius: 10, backgroundColor: color ?? baseColors.green.primary }}
            className={classes.container}
        >
            <Box className={classes.flexContent}>
                <Box />
                <div className={classes.icon}>{icon}</div>
            </Box>
            <Box className={classes.flexContent}>
                <Box className={classes.content}>
                    <Typography fontFamily={baseFontFamily.OpenSansBold} fontSize={30} color={baseColors.white}>
                        {number}
                    </Typography>

                    <CustomText color={baseColors.white} fontSize={baseFontSize.mini}>
                        {label}
                    </CustomText>
                </Box>
                <Box />
            </Box>
        </Paper>
    );
};

export default DayNumberCard;
