import { Box } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { retrievePatients } from 'src/actions/dayHospitals/management';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import CustomModal from 'src/components/CustomModal';
import HDJManagementFormCard from 'src/components/HDJManagementFormCard';
import useDebounce from 'src/hooks/useDebounce';
import { SnackBarContext } from 'src/providers/SnackBarProvider';
import HdjDataService from 'src/services/Hdj/dayHospital.service';
import { reorderedData } from 'src/views/Hdj/HdjLogic';

interface ModalAddPatientsProps {
    toggleModal: any;
    modalOpen: boolean;
    hdjPatients: any;
    fetchedHdjData?: any;
    updateList?: boolean;
    setUpdateList?: any;
    setSearchValue?: any;
}

const ModalAddPatients: FC<ModalAddPatientsProps> = (props) => {
    const { modalOpen, toggleModal, hdjPatients, fetchedHdjData, updateList, setUpdateList, setSearchValue } = props;
    const dispatch = useDispatch();
    const [searchKey, setSearchKey] = useState<string>('');
    const [loadingPatients, setLoadingPatients] = useState<boolean>(false);
    const [patientsIds, setPatientsIds] = useState<Array<any>>([]);
    const handleFetchData = (query: string) => dispatch(retrievePatients(setLoadingPatients, 0, query));
    const { debouncedResults } = useDebounce(handleFetchData);

    useEffect(() => {
        handleFetchData('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateList]);

    const fetchedData = useSelector((state: any) => state?.managementPatients);
    const patients = fetchedData?.data?.length > 0 ? fetchedData?.data : [];
    const reorderdItems = hdjPatients?.length > 0 ? reorderedData(patients, hdjPatients) : patients;
    const handleChange = (selected: any) => setPatientsIds(Array.from(selected));
    const [updateHdjLoading, setUpdateHdjLoading] = useState(false);
    const { setShowSnackBar, setSnackBarMessage } = useContext(SnackBarContext);
    const handleSearch = (value: string) => {
        setSearchKey(value);
        setSearchValue(value);
        debouncedResults(value);
    };

    const showSnackBarMessage = () => {
        setSnackBarMessage('Patient rajouté avec succès');
        setShowSnackBar(true);
    };

    const patientIdValues =
        patientsIds?.length > 0
            ? patientsIds
            : fetchedHdjData?.patients && Array.from(fetchedHdjData?.patients, (c: any) => c.id);

    const newHdjValue = {
        icon: fetchedHdjData?.icon ?? '',
        name: fetchedHdjData?.name ?? '',
        consultationCount: fetchedHdjData?.consultationCount ?? 0,
        hourCount: fetchedHdjData?.hourCount ?? 0,
        workshopIds: fetchedHdjData?.workshops && Array.from(fetchedHdjData?.workshops, (c: any) => c.id),
        consultationIds: fetchedHdjData?.consultations && Array.from(fetchedHdjData?.consultations, (c: any) => c.id),
        documentIds: fetchedHdjData?.documents && Array.from(fetchedHdjData?.documents, (d: any) => d.id),
        patientIds: patientIdValues?.filter((id: number) => id > 0),
        coordinatorId: fetchedHdjData?.coordinator?.id ?? '',
    };

    const handleClick = async () => {
        setUpdateHdjLoading(true);

        if (+fetchedHdjData?.id > 0) {
            await HdjDataService.update(+fetchedHdjData?.id, newHdjValue)
                .then((response: any) => {
                    if (response) {
                        showSnackBarMessage();
                        setUpdateHdjLoading(false);
                        setUpdateList((prev: boolean) => !prev);
                        toggleModal();
                    }
                })
                .catch((e) => {
                    showSnackBarMessage();
                    setUpdateHdjLoading(false);
                    setUpdateList((prev: boolean) => !prev);
                    toggleModal();
                });
        }
    };

    return (
        <CustomModal
            width={300}
            title={'Nouveaux Patients'}
            toggleModal={toggleModal}
            open={modalOpen}
            clearFormData={() => handleFetchData('')}
        >
            <Box style={{ marginTop: 20, marginBottom: 25 }}>
                <HDJManagementFormCard
                    contentHeight={225}
                    maxItems={50}
                    loading={loadingPatients}
                    labelKey={'name'}
                    items={reorderdItems}
                    selectedRows={
                        new Set(hdjPatients?.length > 0 ? Array.from(hdjPatients, (item: any) => item.id) : [0])
                    }
                    handleChange={(selected: Set<number>) => handleChange(selected)}
                    handleSearch={handleSearch}
                    isPatients
                />
            </Box>

            <CustomLoadingButton
                disabled={updateHdjLoading}
                handleClick={handleClick}
                loading={updateHdjLoading}
                width={300}
                label={'Ajouter les patients'}
            />
        </CustomModal>
    );
};

export default ModalAddPatients;
