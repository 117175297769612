import { FC, useContext, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { baseColors, baseFontFamily, baseFontWeight } from 'src/utils/constants/baseStyles';
import Button from '@mui/material/Button';
import { HdjContext } from 'src/providers/HdjProvider';
import { fullName } from 'src/utils/utils';
import CustomEmptyData from 'src/components/CustomEmptyData';
import CustomLoader from 'src/components/CustomLoader/CustomLoader';
import ModalAddPatients from './ModalAddPatients';
import ManagementService from 'src/services/Hdj/management.service';
interface PatientsListProps {
    fetchedHdjData?: any;
    dayHospitalId?: number;
}

const PatientsList: FC<PatientsListProps> = (props) => {
    const { fetchedHdjData, dayHospitalId } = props;
    const [loadingPatients, setLoadingPatients] = useState<boolean>(false);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [updateList, setUpdateList] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState<string>('');
    const [patients, setPatietns] = useState<Array<any>>([]);
    const { currentHdjInfos } = useContext(HdjContext);
    const id = currentHdjInfos?.id ?? dayHospitalId;
    const handleFetchData = async () => {
        setLoadingPatients(true);
        await ManagementService.getPatients(id, searchValue)
            .then((res: any) => {
                setPatietns(res?.data?.data);
                setLoadingPatients(false);
            })
            .catch(() => setLoadingPatients(false));
    };

    useEffect(() => {
        handleFetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, updateList]);

    const isEmpty = Boolean(patients?.length === 0);
    const toggleModal = () => setModalOpen(!modalOpen);

    return (
        <Box sx={{ minWidth: 300, marginBottom: 0 }} marginRight={2}>
            <Box
                sx={{
                    backgroundColor: baseColors.green.light,
                    height: 270,
                    marginBottom: 5,
                    borderRadius: 2,
                    padding: 2,
                }}
            >
                <Typography
                    color={baseColors.green.primary}
                    fontWeight={baseFontWeight.bold}
                    fontSize={14}
                    textAlign="center"
                >
                    Liste des patients
                </Typography>
                <Box
                    style={{
                        overflow: 'hidden',
                        overflowY: 'scroll',
                        height: '86%',
                    }}
                    sx={{ marginTop: 2 }}
                >
                    {loadingPatients ? (
                        <CustomLoader loadingNumber={10} />
                    ) : !isEmpty ? (
                        patients?.length > 0 &&
                        patients?.map((patient: any, i: number) => (
                            <Typography
                                key={i}
                                color={baseColors.black}
                                fontFamily={baseFontFamily.OpenSansSemiBold}
                                fontSize={13}
                                marginBottom={2}
                            >
                                {fullName(patient?.firstName, patient?.lastName)}
                            </Typography>
                        ))
                    ) : (
                        <CustomEmptyData />
                    )}
                </Box>
            </Box>

            <Button
                onClick={toggleModal}
                style={{
                    width: '100%',
                    color: baseColors.white,
                    backgroundColor: baseColors.green.primary,
                    borderColor: 'transparent',
                }}
                variant="outlined"
                size="large"
            >
                <Typography fontWeight={baseFontWeight.semiBold} fontSize={10}>
                    Ajouter des patients
                </Typography>
            </Button>
            <ModalAddPatients
                fetchedHdjData={fetchedHdjData}
                hdjPatients={patients}
                toggleModal={toggleModal}
                modalOpen={modalOpen}
                updateList={updateList}
                setUpdateList={setUpdateList}
                setSearchValue={setSearchValue}
            />
        </Box>
    );
};

export default PatientsList;
