
import { fullName } from "src/utils/utils";
import { Medimails, PatientDiscussion } from 'src/interfaces/interfaces';

const optimizePatientsData = (patients: any) => Array.from(patients, (patient: any) => ({
    text: fullName(patient.firstName, patient.lastName),
    value: fullName(patient.firstName, patient.lastName),
    url: patient.email,
}));

const optimizeMedimailsData: any = (datas: any) =>
    Array.from(datas, (datas: any) => ({
        text: fullName(datas.nom, datas.prenom),
        value: fullName(datas.nom, datas.prenom),
        url: datas.mail,
    }));

    const checMentionnedUsers = (discussion: string, patientsDataToFilter: PatientDiscussion[]) => {
        const splitedDiscussion = discussion.split('@');
        const checkMentionString = splitedDiscussion.filter((item: any) => item.includes(' '));
        const filteredData = checkMentionString.map((item: any) => {
            const splitedItem = item.split(' ');
            return `${splitedItem?.[0]}-${splitedItem?.[1]}`.toLocaleLowerCase();
        });
        const mentionedUsers =
            patientsDataToFilter?.length > 0
                ? patientsDataToFilter?.filter((patient: PatientDiscussion) =>
                      filteredData.some((item: string) => patient.name === item),
                  )
                : [];
    
        return mentionedUsers;
    };

    const checkMailUsers = (discussion: string, dataToFilter: Medimails[]) => {
        const splitedDiscussion = discussion.split('@');
        const checkMentionString = splitedDiscussion.filter((item: any) => item.includes(' '));
        const filteredData = checkMentionString.map((item: any) => {
            const splitedItem = item.split(' ');
            return `${splitedItem?.[0]}-${splitedItem?.[1]}`.toLocaleLowerCase();
        });
    
        const mentioned =
            dataToFilter?.length > 0
                ? dataToFilter?.filter((pat: Medimails) => filteredData.some((item: string) => pat.name === item))
                : [];
    
        return mentioned;
    };

    const defaultDiscussion: any = (programId: number) => ({
        id: 0,
        discussionTitle: '',
        discussionText: '',
        recipients: [],
        programId: programId,
    });
    
    const defaultData: any = [
        {
            text: '',
            value: '',
            url: '',
        },
    ];
    
    const defaultSelectedDiscussion = {
        id: 0,
        name: '',
        program: {
            id: 0,
            title: '',
        },
    };

    export {
        defaultSelectedDiscussion,
        optimizePatientsData,
        checMentionnedUsers,
        checkMailUsers,
        optimizeMedimailsData,
        defaultDiscussion,
        defaultData,
    };
    