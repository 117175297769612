import { Box, Button, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { Spacer } from 'src/components/Spacer/Spacer';
import { CustomText } from 'src/components/Text/CustomText';
import { baseColors, baseFontFamily, baseFontWeight } from 'src/utils/constants/baseStyles';
import styles from './styles';
import UserThemeColorPallete from './UserThemeColorPallete';
import UserThemeButtons from './UserThemeButtons';
import UserThemeLinks from './UserThemeLinks';
import UserThemeText from './UserThemeText';
import { linkThemes, buttonThemes, textThemes } from 'src/utils/constants/constants';
import CustomCircleLoader from 'src/components/CustomLoader/CustomCircleLoader';
import { ColorElementType, ColorType } from 'src/interfaces/types';
import { handleFetchColors, handleSaveTheme } from './UserThemeLogic';
import { toast } from 'react-toastify';
import { CustomButton } from 'src/components/Button/CustomButton';

type UserThemeProps = {};

const UserTheme: FC<UserThemeProps> = () => {
    const [fetching, setFetching] = useState(false);
    const [saving, setSaving] = useState(false);
    const [selectedPrimaryTheme, setSelectedPrimaryTheme] = useState<ColorType>({} as ColorType);
    const [selectedSecondaryTheme, setSelectedSecondaryTheme] = useState<ColorType>({} as ColorType);
    const [colors, setColors] = useState<ColorType[]>([]);
    const [elementColors, setElementColors] = useState<ColorElementType>({
        linkColors: linkThemes,
        textColors: textThemes,
        btnColors: buttonThemes,
    });

    useEffect(() => {
        handleFetchColors({ setFetching, setColors });
    }, []);

    return (
        <Box style={styles.container}>
            <div style={styles.titleContainer}>
                <CustomText fontSize={24} fontFamily={baseFontFamily.OpenSansSemiBold} color={baseColors.green.primary}>
                    Paramètrages de votre thèmes s'éffectue dépuis cette section
                </CustomText>
            </div>

            <Spacer />

            {fetching ? (
                <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '15%' }}>
                    <CustomCircleLoader />
                </Box>
            ) : (
                <div style={{ overflow: 'hidden', overflowY: 'scroll', height: 'calc(75vh + 30px)' }}>
                    <div>
                        <CustomText
                            style={{ textAlign: 'center' }}
                            fontSize={18}
                            fontFamily={baseFontFamily.OpenSansSemiBold}
                            color={baseColors.green.dark}
                        >
                            Listes des couleur
                        </CustomText>

                        <Spacer />

                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-around',
                            }}
                        >
                            <UserThemeColorPallete
                                isPrimary
                                selectedTheme={selectedPrimaryTheme}
                                colors={colors}
                                label="Primary"
                                setSelectedTheme={setSelectedPrimaryTheme}
                                setElementColors={setElementColors}
                            />
                            <UserThemeColorPallete
                                selectedTheme={selectedSecondaryTheme}
                                colors={colors}
                                label="Secondary"
                                setSelectedTheme={setSelectedSecondaryTheme}
                                setElementColors={setElementColors}
                            />
                        </div>
                    </div>

                    <Spacer height={60} />

                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            paddingLeft: 70,
                            paddingRight: 70,
                        }}
                    >
                        <UserThemeLinks items={elementColors.linkColors} />

                        <UserThemeButtons items={elementColors.btnColors} />

                        <UserThemeText items={elementColors.textColors} />
                    </div>

                    <Spacer height={80} />

                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <CustomButton
                            loading={saving}
                            disabled={saving}
                            variant={'contained'}
                            onClick={() => handleSaveTheme({ setSaving, elementColors, toast })}
                            width={300}
                            label="Enregister"
                        />
                    </div>
                    <Spacer height={100} />
                </div>
            )}
        </Box>
    );
};

export default UserTheme;
