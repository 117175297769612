import { Dispatch, FC, SetStateAction } from 'react';
import { Box } from '@mui/material';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import { PostProgramSkillPayload, SkillItem } from 'src/interfaces/interfaces';
import { sxStyles } from './styles';
import { defaultProgramSkillPaylopad } from '../../ProgramSkill.utils';
import usePrograms from 'src/hooks/usePrograms';

type IProps = {
    skill: SkillItem;
    setSkill: Dispatch<SetStateAction<SkillItem>>;
    setPayload: Dispatch<SetStateAction<PostProgramSkillPayload>>;
    payload: PostProgramSkillPayload;
    toggleModal: any;
    setUpdateList: Dispatch<SetStateAction<boolean>>;
    programId: number;
};

export const AddSkillProgramFormsFooter: FC<IProps> = (props) => {
    const { programId, payload, toggleModal, setPayload, setUpdateList } = props;
    const { saving, handleAddSkillToProgram } = usePrograms({
        toggleModal,
        setPayload,
        setUpdateList,
    });

    const canSubmit = payload.skillIds?.length === 0;

    const handleClose = () => {
        // setPayload(defaultProgramSkillPaylopad);
        toggleModal();
    };

    const handleClick = () => {
        handleAddSkillToProgram({ payload, programId });
    };

    return (
        <Box style={sxStyles.footer}>
            <CustomLoadingButton
                outlined
                disabled={saving}
                width="100%"
                label="Annuler"
                handleClick={handleClose}
                style={{ flex: 0.95 / 2 }}
            />
            <CustomLoadingButton
                loading={saving}
                disabled={saving || canSubmit}
                width="100%"
                label={'Enregistrer'}
                handleClick={handleClick}
                style={{ flex: 0.95 / 2 }}
            />
        </Box>
    );
};
