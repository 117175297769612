import { makeStyles } from '@mui/styles';
import { baseColors } from 'src/utils/constants/baseStyles';

const useStyles = makeStyles({
    container: {
        padding: 10,
        paddingTop: 25,
        background: 'white',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        paddingLeft: 20,
        paddingRight: 20,
        overflowY: 'auto',
    },
    sideMenu: {
        flex: 1,
        background: baseColors.green.light,
        padding: 15,
        maxWidth: '25%',
        height: '60vh',
        borderRadius: 8,
    },
    flexCenter: { display: 'flex', justifyContent: 'center' },
    row: { display: 'flex', flexDirection: 'row' },
    loading: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 150,
    },
});

export default useStyles;
