import { DashboardActionType  } from './../../actions/dashboard/types';


interface DashboardState{
    myDayToNumber: any
    dailyPatients: any
    dailyPatientsByDate:any
    programmedWorkshops: any
    myScheduledWorkshops: any
    appointments: any
}

const initialState: DashboardState = {
    myDayToNumber: {
        workshopCount: 0,
        individualInterviewCount: 0,
        educationalDiagnosisCount: 0,
    },
    dailyPatients: [],
    dailyPatientsByDate: [],
    programmedWorkshops: [],
    myScheduledWorkshops: [],
    appointments: [],
};


const dashboardReducer = (dashboardState:DashboardState =initialState, action: any) => {
    const { type, payload } = action;

    switch (type) {
        case DashboardActionType.MY_DAY_TO_NUMBER:
            return {
                ...dashboardState,
                myDayToNumber: {
                    workshopCount: payload.workshopCount,
                    individualInterviewCount: payload.individualInterviewCount,
                    educationalDiagnosisCount: payload.educationalDiagnosisCount
                }   
            };
            
        case DashboardActionType.DAILY_PATIENTS:
            const dp: object[] = [];
            for (const item of payload) {
                let name: string = '';
                let label: string = '';
                if (item.type === "Appointment") {
                    const civility = item.attendees[0].civility;
                    const userName = item.attendees[0].name;
                    name = item.patientName ?? item.title;
                    label = item.typeName;
                } else if (item.type === 'Workshop') {
                    const patientsCount = item.patientsCount 
                    name = `${patientsCount} patients inscrits`;
                    label = 'Atelier - ' + item.title
                }
                
                dp.push({
                    name: name,
                    label: label,
                    timeStart: item.startTime,
                    eventId: item.eventId,
                    timeEnd: item.endTime,
                    type: item.type,
                    participants: item.patientsCount,
                    itemData: item
                });
            }
            return {
                ...dashboardState,
                dailyPatients: dp   
            };
            case DashboardActionType.DAILY_PATIENTS_BY_DATE:
                const dpdate: object[] = [];
                for (const item of payload) {
                    let name: string = '';
                    let label: string = '';
                    if (item.type === "Appointment") {
                        const civility = item.attendees[0].civility;
                        const userName = item.attendees[0].name;
                        name = item.patientName ?? item.title;
                        label = item.typeName;
                    } else if (item.type === 'Workshop') {
                        const patientsCount = item.patientsCount 
                        name = `${patientsCount} patients inscrits`;
                        label = 'Atelier - ' + item.title
                    }
                    
                    dpdate.push({
                        name: name,
                        label: label,
                        timeStart: item.startTime,
                        eventId: item.eventId,
                        timeEnd: item.endTime,
                        type: item.type,
                        participants: item.patientsCount,
                        itemData: item
                    });
                }
                return {
                    ...dashboardState,
                    dailyPatientsByDate: dpdate   
                };
        case DashboardActionType.PROGRAMMED_WORKSHOPS:
             const pw: object[] = [];
            for (const item of payload) {
                
                pw.push({
                    workshopId: item.eventId,
                    label: item.title,
                    programId: item.programId,
                    program: item.program,
                    date: item.eventDate
                });
            }
            return {
                ...dashboardState,
                programmedWorkshops: pw   
            };
        case DashboardActionType.MY_SCHEDULED_WORKSHOPS:
           
            const msw: object[] = [];
            for (const item of payload) {
                 const progressValue = item.progress.value;
                const progressMaxValue = item.progress.count;
                const progress = progressValue/progressMaxValue;
                const progressMiddle = (progressMaxValue/2)/progressMaxValue;
                let oranged = false;
                if(progress > progressMiddle){
                    oranged = true;
                }
                msw.push({
                    workshopId: item.workshopId,
                    label: item.workshopName,
                    // programId: item.programId,
                    // program: item.programName,
                    date: item.startDate,
                    progressCount: progressMaxValue,
                    progressValue: progressValue,
                    oranged: oranged
                });
            }
            return {
                ...dashboardState,
                myScheduledWorkshops: msw   
            };
    case DashboardActionType.APPOINTMENTS:
         const apt: object[] = [];
            for (const item of payload) {
                apt.push({
                    name: item.patientName ?? item.title,
                    label: item.typeName,
                    timeStart: item.startTime,
                    timeEnd: item.endTime,
                    type: item.type,
                    participants: item.patientsCount,
                    itemData: item
                });
            }
        return {
            ...dashboardState,
            appointments: apt   
        };
        default:
            return dashboardState;
    }
}

export default dashboardReducer;