import { FC } from 'react';
import { Box } from '@mui/material';
import Filters from './Filters';
import PlanningDetails from './PlanningDetails';

const HdjPlanning: FC = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
            }}
        >
            <Box flex={10} marginRight={1}>
                <PlanningDetails />
            </Box>

            <Box flex={2}>
                <Filters />
            </Box>
        </Box>
    );
};

export default HdjPlanning;
