import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import CustomCircleLoader from 'src/components/CustomLoader/CustomCircleLoader';
import { baseColors, baseFontFamily, baseFontSize } from 'src/utils/constants/baseStyles';

const SubMenusLoader: FC = () => {
    return (
        <Box sx={sxStyles.loading}>
            <CustomCircleLoader height={70} width={70} />
            <Typography sx={sxStyles.loadingLabel}>Chargement en cours...</Typography>
        </Box>
    );
};

const sxStyles: any = {
    loading: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 10,
    },
    loadingLabel: {
        color: baseColors.green.primary,
        fontSize: baseFontSize.sl,
        marginTop: 5,
        fontFamily: baseFontFamily.OpenSansRegular,
    },
};

export default SubMenusLoader;
