import { Dispatch, SetStateAction, useState } from 'react';
import { toast } from 'react-toastify';
import { useRequestProcessor } from 'src/api/useRequestProcessor';
import { PostProgramSkillPayload, ProgramItem, ProgramWorkshops, SkillItem } from 'src/interfaces/interfaces';
import { getLatestProgramPdf } from 'src/services/Patients';
import ProgramService from 'src/services/Program/program.service';
import { OperationMessage } from 'src/utils/constants/constants';
import { uniqByKeyValues } from 'src/utils/utils';
import { programsWithoutDraft } from 'src/views/Patients/PatientsDetails/Programs/AddProgam/AddProgramLogic';
import { defaultProgramSkillPaylopad } from 'src/views/Programme/Detail/ProgramSkill/ProgramSkill.utils';

interface IProps {
    toggleModal?: Function;
    setPayload?: Dispatch<SetStateAction<PostProgramSkillPayload>>;
    setUpdateList?: Dispatch<SetStateAction<boolean>>;
    setModalConfirmVisible?: Dispatch<SetStateAction<boolean>>;
}

const usePrograms = ({ toggleModal, setPayload, setUpdateList, setModalConfirmVisible }: IProps) => {
    const { query } = useRequestProcessor();
    const [fetching, setFetching] = useState<boolean>(true);
    const [saving, setSaving] = useState<boolean>(false);
    const [program, setProgram] = useState<ProgramItem>({} as ProgramItem);
    const [optimizedPrograms, setOptimizedPrograms] = useState<ProgramWorkshops[]>([]);
    const [programSkills, setProgramSkills] = useState<SkillItem[]>([]);
    const [fetchingPdfFile, setFetchingPdfFile] = useState<boolean>(false);

    const {
        isLoading: fetchingPrograms,
        data: programData,
        refetch: fetchPrograms,
    }: any = query({
        queryKey: ['programs'],
        queryFn: ProgramService.getPrograms(),
    });

    const programs: ProgramItem[] = programData?.data?.data?.items?.sort((a: any, b: any) => b.id - a.id) ?? [];

    const handleAddSkillToProgram = ({
        payload,
        programId,
    }: {
        payload: PostProgramSkillPayload;
        programId: number;
    }) => {
        const skillIds = payload?.skillIds?.filter((skillId) => skillId > 0);
        setSaving(true);
        ProgramService.addSkillToProgram({ payload: { skillIds }, programId })
            .then(() => {
                setSaving(false);
                setModalConfirmVisible?.((prev) => !prev);
                setUpdateList?.((prev) => !prev);
                toggleModal?.();
                setPayload?.(defaultProgramSkillPaylopad);
                toast.success(OperationMessage.success);
            })
            .catch(() => {
                setSaving(false);
                toast.error(OperationMessage.error);
            });
    };

    const handleFetchProgramSkills = ({ programId }: { programId: number }) => {
        setFetching(true);
        ProgramService.GetProgramSkills({ programId })
            .then((res) => {
                const data = res?.data?.data;
                setFetching(false);
                setProgramSkills(data);
            })
            .catch(() => {
                setFetching(false);
            });
    };

    const handleFetchPrograms = () => {
        setFetching(true);
        ProgramService.getPrograms()
            .then((response) => {
                const data = response?.data?.data?.items;
                const items = programsWithoutDraft(data);

                function allProgramWorkshops(program: ProgramItem) {
                    const withoutModule = program?.workshops ?? [];
                    const inModules = program?.modules?.map((module) => module?.workshops) ?? [];
                    const flatedInModules = inModules?.flat(1) ?? [];
                    const workshops = uniqByKeyValues({ items: [...withoutModule, ...flatedInModules], key: 'id' });
                    return workshops as any;
                }

                const optimizedPrograms = Array.from(items, (item: ProgramWorkshops) => ({
                    id: item.id,
                    title: item.title,
                    workshops: allProgramWorkshops(item as ProgramItem),
                }));

                setOptimizedPrograms(optimizedPrograms ?? []);
                // setPrograms(items);
                setFetching(false);
            })
            .catch((e) => {
                setFetching(false);
            });
    };

    const fetchProgramById = (programId: number) => {
        setFetching(true);
        if (programId > 0) {
            ProgramService.getProgramById(programId)
                .then((response) => {
                    const programItem = response.data?.data;
                    if (programItem) {
                        setProgram(programItem);
                    }
                    setFetching(false);
                })
                .catch(() => setFetching(false));
        }
    };

    const handleDownloadPdf = (link: any) => {
        const downloadLink = document.createElement('a');
        const fileName = 'application.pdf';
        downloadLink.href = link;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    const downloadProgramPdf = (patientId: number,programId: number) => {
        setFetchingPdfFile(true);
        getLatestProgramPdf(patientId, programId)
            .then((response) => {
                setFetchingPdfFile(false);
                if (response?.data?.data?.dataUri) {
                    const linkSource = `${response?.data?.data?.dataUri}`;
                    handleDownloadPdf(linkSource);
                    toast.success('Dernier programme exportée avec succès.');
                } else {
                    toast.warning('Pas de données à exporter.');
                }
            })
            .catch((e) => {
                console.log(e);
                setFetchingPdfFile(false);
            });
    };

    const educationalDiagPrograms = Array.from(programs, (item: any) => ({
        id: item?.id,
        name: item?.title,
        status: item?.state?.code,
        level: item?.level?.name,
    }));

    const programOptions = [{ id: 0, name: 'Sélectionner un programme' }, ...educationalDiagPrograms];

    return {
        fetching,
        handleFetchPrograms,
        programs,
        educationalDiagPrograms,
        programOptions,
        optimizedPrograms,

        fetchProgramById,
        program,
        setProgram,

        fetchPrograms,
        fetchingPrograms,

        handleAddSkillToProgram,
        saving,

        handleFetchProgramSkills,
        programSkills,

        fetchingPdfFile,
        downloadProgramPdf
    };
};

export default usePrograms;
