import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { baseColors } from 'src/utils/constants/baseStyles';
import UsersTable from './UsersTable';
import { ContactForm } from '../Form/ContactForm';
import UserService from 'src/services/User/user.service';
import { tableHeaders } from '../UserLogic';
import { sxStyles } from '../styles';
import CustomNewUserBtn from '../Content/CustomNewUserBtn';
import { CustomText } from 'src/components/Text/CustomText';
import SubMenusLoader from 'src/components/SettingsSubMenuBar/SubMenusLoader/SubMenusLoader';

const ContactPanel = () => {
    const [dataRows, setDataRows] = useState<any[]>([]);
    const [updateList, setUpdateList] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);
    const toggleModal = () => setOpen(!open);
    const [fetchingUsers, setFetchingUsers] = useState<boolean>(false);
    const [prevSelectedUserId, setPrevSelectedUserId] = useState<number[]>([]);

    const parseUsersData = (users: any[]) => {
        const rows: any[] = Array.from(users, (user) => ({
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            roles: user.roles[0],
            sex: user.sex,
            category: user.category,
            email: user.email,
            password: user.password,
        }));
        setDataRows(rows);
    };

    const handleFetchUsers = () => {
        setFetchingUsers(true);
        UserService.getUsersList()
            .then((response) => {
                const users = response;
                if (users.length > 0) {
                    const usersActivated = users.filter((item: any)=> item.activated === 1);
                    parseUsersData(usersActivated);
                }
                setFetchingUsers(false);
            })
            .catch((error) => console.log(error));
    };

    useEffect(() => {
        handleFetchUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateList]);

    return (
        <Box sx={sxStyles.container}>
            <Box sx={sxStyles.content}>
                <CustomText isBreefSerif isTitle color={baseColors.green.primary} style={{ marginLeft: 20 }}>
                    Liste des utilisateurs à contacter
                </CustomText>

                <CustomNewUserBtn
                    btnLabel="Envoyer un e-mail aux utilisateurs selectionnés"
                    toggleModal={toggleModal}
                    prevSelectedUserId={prevSelectedUserId}
                />

                <ContactForm
                    open={open}
                    modalToggle={setOpen}
                    setUpdateList={setUpdateList}
                    prevSelectedUserId={prevSelectedUserId}
                    setOpen={setOpen}
                    setPrevSelectedUserId={setPrevSelectedUserId}
                />
            </Box>

            {fetchingUsers ? (
                <SubMenusLoader />
            ) : (
                <UsersTable
                    tableHeaders={tableHeaders}
                    dataRows={dataRows}
                    prevSelectedUserId={prevSelectedUserId}
                    setPrevSelectedUserId={setPrevSelectedUserId}
                />
            )}
        </Box>
    );
};

export default ContactPanel;
