export type AddConsultation = {
    name: string;
    duration: string;
    consultationTypeId: number;
    attendees: Array<any>;
    roomId?: number | undefined;
    remark?: string;
    dayHospitalId: number | null;
    axisId: number;
    isDraft: number;
    goalsIds?: Array<any>;
};

const valideAddConsultationForm = (payload: AddConsultation) => {
    if (
        payload.name.trim().length >= 3 &&
        payload.duration !== '' &&
        payload.duration !== '00:00' &&
        payload.consultationTypeId > 0 &&
        payload.attendees?.length > 0 
    ) {
        if (
            (payload.consultationTypeId === 1 && payload.roomId && payload.roomId > 0) ||
            payload.consultationTypeId === 2
        ) {
            return true;
        } else {
            return false;
        }
    }

    return false;
};

export { valideAddConsultationForm };
