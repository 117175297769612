import * as React from 'react';

function SvgBrain(props) {
    return (
        <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_2859_18191)">
                <path
                    d="M32.067 0C34.8886 0 37.176 2.28984 37.176 5.11446C37.176 5.26158 37.1684 5.40685 37.1563 5.5507C37.6616 5.44042 38.1857 5.38066 38.7241 5.38066C42.7719 5.38066 46.0533 8.66564 46.0533 12.7178C46.0533 13.6254 45.8876 14.4939 45.5867 15.2963C49.3269 15.6287 52.26 18.772 52.26 22.6027C52.26 25.0339 51.0779 27.188 49.2586 28.5231C51.079 29.1255 52.4838 30.7327 52.7301 32.7643C53.0576 35.465 51.2186 37.9281 48.5801 38.4103C49.3999 39.6295 49.8785 41.0975 49.8785 42.6776C49.8785 46.9036 46.4564 50.3293 42.2351 50.3293C40.862 50.3293 39.5745 49.965 38.4607 49.3304C37.9295 51.9933 35.5821 54 32.7661 54C29.5585 54 26.9581 51.3969 26.9581 48.1857L25.8716 26.2815L26.9581 5.11457C26.9581 2.28984 29.2454 0 32.067 0V0Z"
                    fill="#EDB5B9"
                />
                <path
                    d="M21.9324 0C19.1108 0 16.8234 2.28984 16.8234 5.11446C16.8234 5.26158 16.831 5.40685 16.8431 5.5507C16.3378 5.44042 15.8135 5.38066 15.2752 5.38066C11.2273 5.38066 7.94595 8.66564 7.94595 12.7178C7.94595 13.6254 8.11164 14.4939 8.41261 15.2963C4.67238 15.6287 1.73932 18.772 1.73932 22.6027C1.73932 25.0339 2.92134 27.188 4.74072 28.5231C2.92026 29.1255 1.5155 30.7327 1.26918 32.7643C0.941707 35.465 2.78064 37.9281 5.41914 38.4103C4.59936 39.6295 4.12075 41.0975 4.12075 42.6776C4.12075 46.9036 7.54285 50.3293 11.7642 50.3293C13.1373 50.3293 14.4248 49.965 15.5384 49.3304C16.0698 51.9933 18.4173 54 21.2333 54C24.4409 54 27.0413 51.3969 27.0413 48.1857V5.11446C27.0413 2.28984 24.754 0 21.9324 0Z"
                    fill="#EDB5B9"
                />
                <path
                    d="M21.9323 0C21.3622 0 20.8145 0.0948531 20.3025 0.267392C22.3247 0.948531 23.7817 2.86059 23.7817 5.11457V48.1858C23.7817 50.8304 22.0173 53.0611 19.603 53.7665C20.1203 53.9178 20.6671 54.0001 21.2332 54.0001C24.4409 54.0001 27.0412 51.397 27.0412 48.1858V5.11446C27.0412 2.28984 24.7539 0 21.9323 0Z"
                    fill="#E68F95"
                />
                <path
                    d="M51.943 36.1672C52.5742 35.1956 52.8809 34.005 52.7305 32.7644C52.3905 29.9603 49.8443 27.9631 47.0432 28.3034C46.7004 28.345 46.3696 28.4198 46.0537 28.5241"
                    fill="#EDB5B9"
                />
                <path
                    d="M42.2354 19.1601C44.5104 17.9147 46.0535 15.4968 46.0535 12.718C46.0535 8.66584 42.7721 5.38086 38.7243 5.38086C37.2064 5.38086 35.7962 5.84285 34.6265 6.63394"
                    fill="#EDB5B9"
                />
                <path
                    d="M35.897 46.9558C37.2706 48.9912 39.5967 50.3294 42.2351 50.3294C46.4564 50.3294 49.8785 46.9036 49.8785 42.6777C49.8785 40.309 48.8034 38.1917 47.1151 36.7881"
                    fill="#EDB5B9"
                />
                <path
                    d="M52.7302 32.7644C52.4839 30.7328 51.0791 29.1256 49.2587 28.5232C51.0779 27.1881 52.2601 25.034 52.2601 22.6029C52.2601 18.7721 49.3271 15.6288 45.5868 15.2964C45.8877 14.4941 46.0534 13.6256 46.0534 12.7179C46.0534 8.66575 42.772 5.38077 38.7242 5.38077C38.1858 5.38077 37.6616 5.44053 37.1563 5.55081C37.1684 5.40685 37.176 5.26169 37.176 5.11457C37.1761 2.28984 34.8887 0 32.0671 0C31.3759 0 30.717 0.138205 30.1157 0.387235C32.3034 0.965807 33.9166 2.9599 33.9166 5.33188V5.33633C33.9164 5.55602 34.1093 5.72172 34.3264 5.68749C34.6974 5.62904 35.0774 5.59807 35.4648 5.59807C39.5126 5.59807 42.794 8.88305 42.794 12.9352C42.794 13.1818 42.7817 13.4255 42.7579 13.6657C42.6606 14.6462 43.2222 15.5813 44.1494 15.9147C46.9776 16.9314 49.0006 19.6387 49.0006 22.8201C49.0006 25.2514 47.8186 27.4054 45.9992 28.7404C47.8197 29.3428 49.2244 30.95 49.4708 32.9816C49.6926 34.8116 48.9198 36.5327 47.5774 37.6021C46.5384 38.43 46.0811 39.7713 46.3982 41.0614C46.5426 41.6489 46.6192 42.263 46.6192 42.895C46.6192 46.3821 44.2883 49.3222 41.1018 50.2444C41.4717 50.2997 41.8499 50.3294 42.2353 50.3294C46.4566 50.3294 49.8787 46.9036 49.8787 42.6777C49.8787 41.0976 49.4001 39.6295 48.5804 38.4104C51.2188 37.9282 53.0576 35.4651 52.7302 32.7644Z"
                    fill="#E68F95"
                />
                <path
                    d="M22.07 18.8198C19.9638 18.8198 17.8894 17.9981 16.3192 16.4279C15.1652 15.2738 14.3921 13.8196 14.0838 12.2225C13.9985 11.7806 14.2875 11.3532 14.7294 11.2679C15.1721 11.1824 15.5988 11.4716 15.684 11.9135C15.9304 13.1899 16.5485 14.3524 17.4716 15.2754C19.4883 17.2921 22.5446 17.7651 25.0771 16.4523C25.4761 16.2454 25.9683 16.4012 26.1754 16.8008C26.3825 17.2003 26.2266 17.6922 25.827 17.8993C24.633 18.5184 23.3456 18.8198 22.07 18.8198Z"
                    fill="#E68F95"
                />
                <path
                    d="M42.4163 34.957C42.3141 34.5187 41.8757 34.2465 41.4376 34.3486C40.118 34.6565 38.941 35.4109 38.1099 36.476C36.9547 35.9787 35.6757 35.8626 34.4278 36.1539C33.9895 36.2561 33.7171 36.6944 33.8193 37.1326C33.9216 37.5708 34.3601 37.8433 34.7981 37.7409C35.9166 37.4798 37.0696 37.6696 38.0445 38.2751C39.0193 38.8804 39.6994 39.8288 39.9598 40.9457C40.0247 41.2242 40.0619 41.5087 40.0707 41.7912C40.0843 42.2325 40.4463 42.5809 40.8848 42.5808C40.8932 42.5808 40.9018 42.5807 40.9104 42.5805C41.3602 42.5666 41.7137 42.1907 41.6996 41.7407C41.6877 41.3508 41.6362 40.9589 41.5469 40.5758C41.2453 39.2813 40.5336 38.1521 39.517 37.3285C40.1102 36.6355 40.9139 36.1444 41.8079 35.9357C42.2462 35.8334 42.5186 35.3951 42.4163 34.957Z"
                    fill="#E68F95"
                />
                <path
                    d="M13.7925 21.6351C14.0746 21.5761 14.3165 21.3691 14.4062 21.0738C14.5368 20.6432 14.2936 20.1881 13.863 20.0574C12.4471 19.6277 11.2069 18.72 10.3713 17.5015C10.1166 17.1303 9.60909 17.0359 9.23837 17.2904C8.86722 17.5449 8.77269 18.0521 9.02715 18.4233C10.0715 19.9459 11.6208 21.08 13.3897 21.6169C13.5252 21.6581 13.6631 21.6622 13.7925 21.6351Z"
                    fill="#E68F95"
                />
                <path
                    d="M19.559 30.1112C18.1406 30.9074 17.0043 32.0995 16.2729 33.5586C15.8073 34.4875 15.5335 35.4696 15.4403 36.4565C13.5974 37.2288 12.0753 38.6685 11.2157 40.4775C11.0227 40.884 11.1955 41.3701 11.6021 41.5633C11.715 41.6169 11.8341 41.6424 11.9513 41.6424C12.2561 41.6424 12.5484 41.4706 12.6879 41.177C13.2799 39.9309 14.2656 38.9043 15.4692 38.248C15.6975 40.0264 16.5157 41.7233 17.8744 43.0456C18.0328 43.1997 18.2378 43.2765 18.4426 43.2765C18.6549 43.2765 18.867 43.194 19.0267 43.03C19.3406 42.7074 19.3336 42.1915 19.011 41.8776C17.6776 40.58 16.9955 38.8312 17.0367 37.0644C17.0374 37.0473 17.0382 37.03 17.0379 37.0126C17.0668 36.0879 17.2938 35.1592 17.7301 34.289C18.3149 33.122 19.2233 32.1688 20.3568 31.5325C20.7493 31.3123 20.8888 30.8155 20.6686 30.4231C20.4482 30.0305 19.9517 29.891 19.559 30.1112Z"
                    fill="#E68F95"
                />
                <path
                    d="M35.5611 18.5875C35.5685 18.6027 35.5759 18.6179 35.5844 18.6328C36.0003 19.4594 36.2439 20.3838 36.2757 21.3569C36.3185 22.6615 35.975 23.9326 35.2824 25.0327C35.0426 25.4135 35.157 25.9167 35.5379 26.1565C35.6727 26.2414 35.8229 26.2819 35.9713 26.2819C36.2419 26.2819 36.5067 26.147 36.6615 25.901C37.5282 24.5244 37.9581 22.9346 37.9045 21.3035C37.8705 20.2649 37.6426 19.2711 37.2535 18.3595C38.5046 16.8015 39.1554 14.8101 39.0476 12.8101C39.0234 12.3607 38.6367 12.0166 38.19 12.0402C37.7406 12.0644 37.3959 12.4484 37.4201 12.8977C37.4943 14.2753 37.118 15.6479 36.3733 16.7989C35.3241 15.345 33.7954 14.244 31.9705 13.7303C31.5374 13.6084 31.0873 13.8607 30.9652 14.2939C30.8433 14.7271 31.0956 15.1771 31.5289 15.299C33.3203 15.8033 34.7543 17.0148 35.5611 18.5875Z"
                    fill="#E68F95"
                />
                <path
                    d="M35.8088 47.011C34.5893 46.4938 33.5515 45.6377 32.8074 44.5349C32.5556 44.1618 32.049 44.0635 31.6761 44.3154C31.303 44.5671 31.2046 45.0735 31.4564 45.4466C32.3772 46.8112 33.6621 47.871 35.1723 48.5115C35.2762 48.5555 35.3839 48.5764 35.4902 48.5764C35.8075 48.5764 36.109 48.3899 36.2407 48.0794C36.4165 47.6651 36.2231 47.1867 35.8088 47.011Z"
                    fill="#E68F95"
                />
            </g>
            <defs>
                <clipPath id="clip0_2859_18191">
                    <rect width="54" height="54" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default SvgBrain;
